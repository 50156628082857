import { useState, useEffect } from "react";
import { useParams } from "react-router";
import Menu from "../Layout/Menu";
import StyleForm from './Pagamentos.module.css'
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { Navigate } from "react-router";
import moment from "moment";
import urlAPIlocal from '../../global';
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { TfiReload } from "react-icons/tfi";
import { Modal } from 'react-bootstrap';
import loadingGif from "../img/icons8-spinning-circle.gif";
import check_circle from "../img/check_circle.gif";
import { ImSad2 } from 'react-icons/im';

function PagamentoSolicForm() {

    var wToken = 'Bearer ' + localStorage.getItem("token");
    var wCodigoConvenio = localStorage.getItem("CodigoProjeto");
    const { CodigoSolicitacao } = useParams();
    //console.log(CodigoSolicitacao);

    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(false);

    const [solicitacao, setSolicitacao] = useState([]);
    const [tipoContratação, setTipoContratacao] = useState('');
    const [fornecedor, setFornecedor] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [dataDocumento, setDataDocumento] = useState('');
    const [dataOrdemBancaria, setDataOrdemBancaria] = useState('');
    const [ordemBancaria, setOrdemBancaria] = useState('');
    const [valorSolic, setValorSolic] = useState('');
    const [valorPago, setValorPago] = useState('');
    const [valorLiquido, setValorLiquido] = useState('');
    const [valorInss, setValorInss] = useState('');
    const [valorIss, setValorIss] = useState('');
    const [valorIrrf, setValorIrrf] = useState('');
    const [valorBruto, setValorBruto] = useState('');


    const [observacao, setObservacao] = useState('');
    const [observacoesAdicionais, setObservacoesAdicionais] = useState('');

    const [pagamentoOk, setPagamentoOk] = useState(false);
    const [showModalOk, setShowModalOk] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wToken === undefined || wToken === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    function handleClose() {
        setTimeout(() => {
            setShowModalOk(false);
            setPagamentoOk(true);

        }, 1000);

    }
    function handleCloseErro() {
        setShowModalErro(false);
        setPagamentoOk(false);
    };


    const getSolicitacao = async () => {
        setLoading(true);

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data.objetoRetorno[0];
                //console.log(data);
                setLoading(false);

                setSolicitacao(data);
                setTipoContratacao(data.TipoLicitacao);
                //console.log(data.TipoLicitacao)
                setFornecedor(data.Cadastro);
                setValorPago(data.ValorTotal);
                setValorLiquido(data.ValorLiquido);
                setValorInss(data.ValorINSS);
                setValorIss(data.ValorISS);
                setValorIrrf(data.ValorIRRF);
                setValorBruto(data.ValorTotal);
                setObservacao(data.Justificativa);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacao();
        //eslint-disable-next-line
    }, []);

    const handleSelectTipoContratacao = (event) => {
        setTipoContratacao(event.target.value);
    };

    function converterMoedaParaDecimal(valorMoeda) {
        // Remove caracteres não numéricos e extrai apenas os dígitos e ponto
        console.log(valorMoeda);
        const valorNumerico = String(valorMoeda).replace(/[^\d,]/g, '');

        // Substitui a vírgula por ponto e converte para número
        const valorDecimal = parseFloat(valorNumerico.replace(',', '.'));

        // Verifica se o resultado é um número
        if (!isNaN(valorDecimal)) {
            return valorDecimal;
        } else {
            console.error("Não foi possível converter a moeda para decimal.");
            return null;
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        //setIsButtonDisabled(true);
        setLoading(true);


        const valorDecimalPago = converterMoedaParaDecimal(valorPago);
        const valorDecimalLiquido = converterMoedaParaDecimal(valorLiquido);
        const valorDecimalTotal = converterMoedaParaDecimal(valorBruto);
        const valorDecimalInss = converterMoedaParaDecimal(valorInss);
        const valorDecimalIss = converterMoedaParaDecimal(valorIss);
        const valorDecimalIrrf = converterMoedaParaDecimal(valorIrrf);




        var data = JSON.stringify({
            "CodigoFinanceiro": 0,
            "CodigoConvenio": wCodigoConvenio,
            "NumeroSequencia": 0,
            "CodigoSolicitacao": solicitacao.CodigoSolicitacao,
            "CodigoCadastro": fornecedor.CodigoCadastro,
            "CodigoFonte": solicitacao.CodigoFonte,
            "CodigoElemento": solicitacao.CodigoElemento,
            "CodigoMeta": solicitacao.CodigoMeta,
            "CodigoFase": solicitacao.CodigoFase,
            "NumeroNotaFiscal": numeroDocumento,
            "DataEmissao": moment(dataDocumento).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "ValorLiquido": valorDecimalLiquido,
            "ValorIss": valorDecimalIss,
            "ValorInss": valorDecimalInss,
            "ValorIrrf": valorDecimalIrrf,
            "ValorInss20": 0,
            "ValorTotal": valorDecimalTotal,
            "DataPagamento": moment(dataOrdemBancaria).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "ValorPago": valorDecimalPago,
            "NumeroDoc": ordemBancaria,
            "Tipo": false,
            "Observacao": observacoesAdicionais,
            "ValorDesconto": 0,
            "Issp": false,
            "Inssp": false,
            "Irrfp": false,
            "Inss20p": false,
            "DataISS": "1900-01-01T00:00:00.000Z",
            "DataINSS": "1900-01-01T00:00:00.000Z",
            "DataIRRF": "1900-01-01T00:00:00.000Z",
            "DataINSS20": "1900-01-01T00:00:00.000Z",
            "NomeISS": "",
            "NomeINSS": "",
            "NomeIRRF": "",
            "NomeINSS20": "",
            "Setor": 0,
            "ValorOutrosImpostos": 0,
            "OutrosImpostos": false,
            "DataOutrosImpostos": "1900-01-01T00:00:00.000Z",
            "NomeOutrosImpostos": "",
            "TipoLicitacao": tipoContratação,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Financeiro',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro

                    setTipoContratacao('0');
                    setFornecedor(0);
                    setValorPago('');
                    setValorLiquido('');
                    setValorIss('');
                    setValorInss('');
                    setValorIrrf('');
                    setValorBruto('');
                    setNumeroDocumento('');
                    setDataDocumento('');
                    setOrdemBancaria('');
                    setDataOrdemBancaria('');
                    setObservacao('');
                    GravaSolicPaga();

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
            });

    }

    function GravaSolicPaga() {

        //const valorDecimalPago = converterMoedaParaDecimal(valorPago);
        const valorDecimalLiquido = converterMoedaParaDecimal(valorLiquido);
        const valorDecimalTotal = converterMoedaParaDecimal(valorBruto);
        const valorDecimalInss = converterMoedaParaDecimal(valorInss);
        const valorDecimalIss = converterMoedaParaDecimal(valorIss);
        const valorDecimalIrrf = converterMoedaParaDecimal(valorIrrf);


        var data = JSON.stringify({
            "CodigoSolicitacao": solicitacao.CodigoSolicitacao,
            "DataSolicitacao": solicitacao.DataSolicitacao,
            "NumeroSolicitacao": 0,
            "CodigoFonte": solicitacao.CodigoFonte,
            "CodigoConvenio": wCodigoConvenio,
            "CodigoCredor": fornecedor.CodigoCadastro,
            "CodigoElemento": solicitacao.CodigoElemento,
            "CodigoMeta": solicitacao.CodigoMeta,
            "CodigoFase": solicitacao.CodigoFase,
            "ValorTotal": valorDecimalTotal,
            "IndFinalizada": false,
            "Pago": true,
            "Observacao": solicitacao.Observacao,
            "CodigoAprovacaoCampus": 0,
            "CodigoAprovacaoDARDC": 0,
            "CodigoAprovacaoConvenente": 0,
            "CodigoAprovacaoDPCC": solicitacao.CodigoAprovacaoDPCC,
            "NumeroParecer": 0,
            "DataParecer": "1900-01-01T00:00:00.000Z",
            "ValorLiquido": valorDecimalLiquido,
            "ValorISS": valorDecimalIss,
            "ValorINSS": valorDecimalInss,
            "ValorIRRF": valorDecimalIrrf,
            "ValorINSS20": 0,
            "CodigoSolicitacaoMae": 0,
            "ValorDesconto": 0,
            "DataPagamento": moment(dataOrdemBancaria).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "Issp": false,
            "Inssp": false,
            "Irrfp": false,
            "Inss20p": false,
            "DataISS": "1900-01-01T00:00:00.000Z",
            "DataINSS": "1900-01-01T00:00:00.000Z",
            "DataIRRF": "1900-01-01T00:00:00.000Z",
            "DataINSS20": "1900-01-01T00:00:00.000Z",
            "NomeISS": "",
            "NomeINSS": "",
            "NomeIRRF": "",
            "NomeINSS20": "",
            "ReciboDet": "",
            "ReciboDatDe": "",
            "ReciboDataTe": "",
            "ReciboHoras": "",
            "ReciboBaseInss": "",
            "ReciboBaseIrrf": "",
            "NumeroContrato": "",
            "Indeferida": false,
            "Regularizada": false,
            "Excluida": false,
            "Justificativa": solicitacao.Justificativa,
            "Setor": 0,
            "ValorOutrosImpostos": 0,
            "OutrosImpostos": false,
            "DataOutrosImpostos": "1900-01-01T00:00:00.000Z",
            "NomeOutrosImpostos": "",
            "DataRPA": "1900-01-01T00:00:00.000Z",
            "MeioTransporte": "",
            "TipoLicitacao": tipoContratação,
            "PerfilDestinoAtual": solicitacao.PerfilDestinoAtual,
            "CodigoProcessoInterno": solicitacao.CodProcessoInterno,

        });

        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    setShowModalOk(true);
                    setLoading(false);
                } else {
                    setShowModalOk(false);
                    setShowModalErro(true);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
            });
    }

    const handleValorPago = (e) => {

        const valorDigitado = e.target.value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
        setValorPago(formatarMoeda(valorDigitado));
    };

    const handleValorLiquido = (e) => {

        const valorDigitado = e.target.value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
        setValorLiquido(formatarMoeda(valorDigitado));
    };

    const handleValorInss = (e) => {

        const valorDigitado = e.target.value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
        setValorInss(formatarMoeda(valorDigitado));
    };

    const handleValorIss = (e) => {

        const valorDigitado = e.target.value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
        setValorIss(formatarMoeda(valorDigitado));
    };

    const handleValorIrrf = (e) => {

        const valorDigitado = e.target.value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
        setValorIrrf(formatarMoeda(valorDigitado));
    };

    const handleValorBruto = (e) => {

        const valorDigitado = e.target.value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
        setValorPago(formatarMoeda(valorDigitado));
    };

    const formatarMoeda = (valor) => {
        if (!valor) return '';

        const valorFormatado = (Number(valor) / 100).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return valorFormatado;
    };

    return (
        <div>

            <Menu />
            <div> <button className={StyleForm.voltar}>  <Link to={`/ListPagamentos/${wCodigoConvenio}`} ><IoMdArrowBack /> Voltar</Link> </button> </div>
            <div className={StyleForm.header}>
                <h1>Lançamento de Pagamentos Com Solicitação</h1>
            </div>
            {/* {error && <div align="center">{error}</div>} */}
            <div className={StyleForm.container}>
                <Form className={StyleForm.form} onSubmit={handleSubmit}>
                    <FormGroup className={StyleForm.form_group}>
                        <p style={{ color: '#386cb9', fontSize: '1.15em', fontWeight: '600' }}>Beneficiário: <span style={{ color: '#707070' }}>{fornecedor.Nome}</span></p>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Tipo de Contratação</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="tipoContratação" value={tipoContratação} onChange={handleSelectTipoContratacao} required disabled={tipoContratação !== ''}>
                                    <option value="">Selecione o Tipo de Contratação</option>
                                    <option value="Dispensa">Dispensa</option>
                                    <option value="Convite">Convite</option>
                                    <option value="Tomada de Preços">Tomada de Preços</option>
                                    <option value="Concorrência">Concorrência</option>
                                    <option value="Pregão Eletrônico">Pregão Eletrônico</option>
                                    <option value="Pregão Presencial">Pregão Presencial</option>
                                    <option value="Inexigível">Inexigível</option>
                                </Form.Select>
                            </Col>
                            {/* <Col>
                                <Form.Label className={StyleForm.form_group_label}>Fornecedor*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="fornecedor" value={fornecedor} disabled />
                            </Col> */}
                            <Col md={4}>
                                <Form.Label className={StyleForm.form_group_label}>Valor Pago*</Form.Label>
                                <Form.Control className={StyleForm.form_control_textRight} type="text" name="valorPago" value={valorPago} onChange={handleValorPago} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor Liquido</Form.Label>
                                <Form.Control className={StyleForm.form_control_textRight} type="text" name="valorLiquido" value={valorLiquido} onChange={handleValorLiquido} />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor Inss</Form.Label>
                                <Form.Control className={StyleForm.form_control_textRight} type="text" name="valorInss" value={valorInss} onChange={handleValorInss} />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor Iss</Form.Label>
                                <Form.Control className={StyleForm.form_control_textRight} type="text" name="valorIss" value={valorIss} onChange={handleValorIss} />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor Irrf</Form.Label>
                                <Form.Control className={StyleForm.form_control_textRight} type="text" name="valorIrrf" value={valorIrrf} onChange={handleValorIrrf} />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor Bruto</Form.Label>
                                <Form.Control className={StyleForm.form_control_textRight} type="text" name="valorBruto" value={valorBruto} onChange={handleValorBruto} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>N° do Documento*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="numDocumento" value={numeroDocumento} onChange={(event) => setNumeroDocumento(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data do Documento*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" name="dataDocumento" value={dataDocumento} onChange={(event) => setDataDocumento(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>N° da Ordem Bancária*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="numOrdemBancaria" value={ordemBancaria} onChange={(event) => setOrdemBancaria(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data do Movimento*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" name="dataOrdemBancaria" value={dataOrdemBancaria} onChange={(event) => setDataOrdemBancaria(event.target.value)} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Observações</Form.Label>
                                {/* Renderiza o HTML usando dangerouslySetInnerHTML */}
                                <div style={{ color: '#707070' }} dangerouslySetInnerHTML={{ __html: observacao }} />
                                <br></br>
                                <textarea className={StyleForm.form_control_textarea} name="obs" id="obs" placeholder="Observações adicionais" onChange={(event) => setObservacoesAdicionais(event.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col></Col>
                        </Row>
                        <Row>
                            <div style={{ marginTop: '2em' }}>
                                <button className={StyleForm.btn} type="submit">Confirmar Pagamento</button>
                            </div>
                        </Row>
                    </FormGroup>

                </Form>
            </div>

            <Modal show={loading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={showModalOk} onLoad={handleClose} className={StyleForm.Modal} centered>
                <Modal.Body className={StyleForm.Modal_body}>
                    <img src={check_circle}></img>
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={StyleForm.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>
            {pagamentoOk && (<Navigate to={`/PagamentosComSolic/${wCodigoConvenio}`} replace={true} />)}
            {login && (<Navigate to="/" replace={true} />)}
        </div>
    )
}
export default PagamentoSolicForm