import { useParams } from "react-router";
import { useState } from "react";
import React, { useEffect } from 'react';
import axios from "axios";
import Style from "./VerProjeto.module.css";
import Menu from "../Layout/Menu"
//import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router";
import { IoMdArrowBack } from "react-icons/io"
import { GoLocation } from "react-icons/go"
import { BsFillFileBarGraphFill } from "react-icons/bs"
import { GiStairsGoal } from "react-icons/gi"
import moment from 'moment';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Row from 'react-bootstrap/Row';
import { Col } from "react-bootstrap";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';


async function gravaProcessoEtapa(wEtapa, wCodigoConvenio) {
    const wtoken = "Bearer " + localStorage.getItem('token');

    try {
        const response = await axios.put(
            urlAPIlocal + '/api/Convenio/MudarProcesso/' + wCodigoConvenio + '/' + wEtapa,
            null, // O corpo da requisição é nulo, pois parece que você não está enviando dados no corpo
            {
                headers: {
                    'Authorization': wtoken,
                    'Content-Type': 'application/json'
                },
            }
        );

        const data = response.data;

        if (data.isOk === true) {
            //console.log('gravou etapa');
        } else {
            //console.log('não gravou etapa');
        }
    } catch (error) {
        console.log(error);
    }
}

async function temConcedente(wCodigoConvenio) {

    const wtoken = "Bearer " + localStorage.getItem('token');

    try {
        const response = await axios.get(urlAPIlocal + '/api/convenioConcedente?CodigoConvenio=' + wCodigoConvenio, {
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        });

        const data = response.data;
        //console.log(data);

        if (data.isOk === true) {
            //console.log(true);
            return true;
        } else {
            //console.log(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function temElemento(wCodigoConvenio) {

    const wtoken = "Bearer " + localStorage.getItem('token');

    try {
        const response = await axios.get(urlAPIlocal + '/api/convenioElemento?CodigoConvenio=' + wCodigoConvenio, {
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        });

        const data = response.data;
        //console.log(data);

        if (data.isOk === true) {
            //console.log(true);
            return true;
        } else {
            //console.log(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function temMeta(wCodigoConvenio) {

    const wtoken = "Bearer " + localStorage.getItem('token');

    try {
        const response = await axios.get(urlAPIlocal + '/api/Meta?CodigoConvenio=' + wCodigoConvenio, {
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        });

        const data = response.data;
        //console.log(data);

        if (data.isOk === true) {
            //console.log(true);
            return true;
        } else {
            //console.log(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function temFase(wCodigoConvenio) {

    const wtoken = "Bearer " + localStorage.getItem('token');

    try {
        const response = await axios.get(urlAPIlocal + '/api/Fase?CodigoConvenio=' + wCodigoConvenio, {
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        });

        const data = response.data;
        //console.log(data);

        if (data.isOk === true) {
            //console.log(true);
            return true;
        } else {
            //console.log(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function temFaseValor(wCodigoConvenio) {

    const wtoken = "Bearer " + localStorage.getItem('token');

    try {
        const response = await axios.get(urlAPIlocal + '/api/FaseValor?CodigoConvenio=' + wCodigoConvenio, {
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        });

        const data = response.data;
        //console.log(data);

        if (data.isOk === true) {
            //console.log(true);
            return true;
        } else {
            //console.log(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

async function temCronograma(wCodigoConvenio) {

    const wtoken = "Bearer " + localStorage.getItem('token');

    try {
        const response = await axios.get(urlAPIlocal + '/api/Financeiro?CodigoConvenio=' + wCodigoConvenio + '&Tipo=true&IsInclude=false', {
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        });

        const data = response.data;
        //console.log(data);

        if (data.isOk === true) {
            //console.log(true);
            return true;
        } else {
            //console.log(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}

function VerProjeto() {

    const { CodigoConvenio } = useParams();
    const [login, setLogin] = useState(false);
    const [projeto, setProjeto] = useState([]);
    const [processo, setProcesso] = useState('0');
    const [loading, setLoading] = useState(true);
    //const [status, setStatus] = useState([]);

    //const data = moment('YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY');

    //const navigate = useNavigate();

    // function handleClick() {
    //     navigate(-1);
    // }


    var wTokenLocal = localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    const ConfirmaProcesso = async () => {
        //faz uma consulta qual processo atual

        //controle de processos no projeto
        // 1 = terminou ficha tecnica
        // 2 = tem concedente
        // 3 = tem elemento
        // 4 = tem meta
        // 5 = tem fase
        // 6 = tem valores nas fases
        // 7 = tem cronograma

        // Tem concedente? se tiver segue, se não coloca processo igual a 1 termina
        try {
            var ResultadoConcendente = await temConcedente(CodigoConvenio);
            //console.log(Resultado);
            //Resultado = temConcedente(CodigoConvenio);
            //console.log(Resultado);
            if (ResultadoConcendente === true) {
                //console.log('tem concedente');
                //verifica se tem Elemento
                var ResultadoElemento = await temElemento(CodigoConvenio);
                if (ResultadoElemento === true) {
                    //console.log('tem elemento');
                    var ResultadoMeta = await temMeta(CodigoConvenio);
                    if (ResultadoMeta === true) {
                        //console.log('tem meta');
                        var ResultadoFase = await temFase(CodigoConvenio);
                        if (ResultadoFase === true) {
                            //console.log('tem fase ');
                            var ResultadoFaseValor = await temFaseValor(CodigoConvenio);
                            if (ResultadoFaseValor === true) {
                                // console.log('tem faseValor');
                                var ResultadoCronograma = await temCronograma(CodigoConvenio);
                                if (ResultadoCronograma === true) {
                                    //console.log('tem cronograma');
                                    gravaProcessoEtapa('7', CodigoConvenio);
                                } else {
                                    //console.log('não tem cronograma');
                                    gravaProcessoEtapa('6', CodigoConvenio);
                                }
                            } else {
                                //console.log('não tem faseValor');
                                gravaProcessoEtapa('5', CodigoConvenio);
                            }

                        } else {
                            //console.log('não tem fase');
                            gravaProcessoEtapa('4', CodigoConvenio);
                        }
                    } else {
                        //console.log('não tem meta');
                        gravaProcessoEtapa('3', CodigoConvenio);
                    }
                } else {
                    //console.log('não tem elemento');
                    gravaProcessoEtapa('2', CodigoConvenio);
                }
            } else {
                //console.log('não tem concedente');
                gravaProcessoEtapa('1', CodigoConvenio);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        ConfirmaProcesso();
        // eslint-disable-next-line
    }, []);

    const getProjeto = async (event) => {


        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + CodigoConvenio,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setProjeto(data.objetoRetorno);
                //console.log(data.objetoRetorno.Processo)

                setProcesso(data.objetoRetorno.Processo);
                setLoading(false);

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getProjeto();
        // eslint-disable-next-line
    }, []);



    return (
        <div className={Style.page}>
            <Menu />

            {/* <div> <button className={Style.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={Style.btn}><Link to={`/ListProjetos`}><IoMdArrowBack /> Voltar</Link></button> </div>

            <div className={Style.box_container}>

                {projeto && (

                    <Box className={Style.box_projeto}>
                        <Row>
                            <Col>
                                <h1>Projeto n° {projeto.NumeroConvenio}</h1>
                            </Col>
                            <Col>
                                <p align="right">Expira em: {moment(projeto.DataExpiracao).format('DD-MM-YYYY')}</p>
                            </Col>
                        </Row>
                        <h3>{projeto.Nome}</h3>
                        <span align="right"><BsFillFileBarGraphFill /></span>
                    </Box>
                )}

                {projeto && (
                    <Box className={Style.box_objetivo}>

                        <h1>Objetivo</h1>
                        <p>{projeto.Objetivo}</p>
                        <span align="right"><GiStairsGoal /></span>
                    </Box>
                )}
                {projeto && (
                    <Box className={Style.box_origem}>
                        <Row>
                            <Col>
                                <h1>Origem</h1>
                            </Col>
                            <Col>
                                <span><GoLocation /></span>
                            </Col>
                        </Row>
                        <p align="right">{projeto.Origem}</p>
                    </Box>
                )}
            </div>



            <div className={Style.container}>

                <div className={Style.btn_container}>
                    {/* <Box className={Style.box_status}>
                <h4>Status</h4>
                <p>.</p>
                <p>.</p>
                <p>.</p>
                <p>.</p>
                <p>.</p>
                <p>.</p>
                <p>.</p>


            </Box> */}

                    <Box className={Style.edit_buttons}>

                        <h1>EDIÇÃO</h1>
                        <button className={Style.edit}>
                            {/* <img className={Style.icon} src="https://img.icons8.com/cotton/64/null/goal.png" alt='Elementos'></img> */}
                            <Link to={`/EditProjeto/${CodigoConvenio}`}>
                                Ficha Técnica
                            </Link>
                        </button>
                        {processo === 1 || parseInt(processo) > 1 ? (
                            <button className={Style.edit} >
                                <Link to={`/Concedente/${CodigoConvenio}`}>
                                    Concedentes
                                </Link>

                            </button>) :
                            (
                                <button className={Style.edit2} >
                                    Concedentes
                                </button>
                            )}
                        {processo === 2 || parseInt(processo) > 2 ? (
                            <button className={Style.edit} >
                                <Link to={`/Elementos/${CodigoConvenio}`}>
                                    Elementos
                                </Link>
                            </button>) :
                            (
                                <button className={Style.edit2} >
                                    Elementos
                                </button>
                            )}
                        {processo === 3 || parseInt(processo) > 3 ? (
                            <button className={Style.edit} >
                                <Link to={`/Metas/${CodigoConvenio}`}>
                                    Metas
                                </Link>

                            </button>) :
                            (
                                <button className={Style.edit2} >
                                    Metas
                                </button>
                            )}
                        {processo === 4 || parseInt(processo) > 4 ? (
                            <button className={Style.edit} >
                                <Link to={`/Fases/${CodigoConvenio}`}>
                                    Fases
                                </Link>
                            </button>) :
                            (
                                <button className={Style.edit2} >
                                    Fases
                                </button>
                            )}
                        {processo === 5 || parseInt(processo) > 5 ? (
                            <button className={Style.edit} >
                                <Link to={`/FaseValor/${CodigoConvenio}`}>
                                    Valores das Fases
                                </Link>
                            </button>) :
                            (
                                <button className={Style.edit2} >
                                    Valores das Fases
                                </button>
                            )}

                        {processo === 6 || parseInt(processo) > 6 ? (
                            <button className={Style.edit} >
                                <Link to={`/Cronograma/${CodigoConvenio}`}>
                                    Cronograma de Desembolso
                                </Link>
                            </button>) :
                            (
                                <button className={Style.edit2} >
                                    Cronograma de Desembolso
                                </button>
                            )}
                    </Box>

                    <Box className={Style.add_buttons}>

                        <h1>GESTÃO</h1>
                        {processo > 6 ? (
                            <button className={Style.add}>
                                <Link to={`/Creditos/${CodigoConvenio}`}>
                                    Créditos
                                </Link>
                            </button>) :
                            (
                                <button className={Style.add2}>
                                    Créditos
                                </button>
                            )}
                        {processo > 6 ? (
                            <button className={Style.add}>
                                <Link to={`/OutrosVal/${CodigoConvenio}`}>
                                    Outros Valores
                                </Link>
                            </button>) :
                            (
                                <button className={Style.add2}>
                                    Outros Valores
                                </button>
                            )}
                        {processo > 6 ? (
                            <button className={Style.add}>
                                <Link to={`/Solicitacoes/${CodigoConvenio}`}>
                                    Processos Financeiros
                                </Link>
                            </button>) :
                            (
                                <button className={Style.add2}>
                                    Processos Financeiros
                                </button>
                            )}
                        {processo > 6 ? (
                            <button className={Style.add}>
                                <Link to={`/CadSolicitacao`}>
                                    Solicitar Despesas
                                </Link>
                            </button>) :
                            (
                                <button className={Style.add2}>
                                    Solicitar Despesas
                                </button>
                            )}
                        {processo > 6 ? (
                            <button className={Style.add}>
                                <Link to={`/ListPagamentos/${CodigoConvenio}`}>
                                    Pagamentos
                                </Link>
                            </button>) :
                            (
                                <button className={Style.add2}>
                                    Pagamentos
                                </button>
                            )}
                    </Box>

                    <Box className={Style.relatorio_buttons}>

                        <h1>RELATÓRIOS</h1>
                        {processo > 6 ? (
                            <button className={Style.relatorio}>
                                <Link to={`/PtFinanceiro/${CodigoConvenio}`} target="_blank">
                                    Plano de Trabalho Financeiro
                                </Link>
                            </button>) :
                            (
                                <button className={Style.relatorio2}>
                                    Plano de Trabalho Financeiro
                                </button>
                            )}
                        {processo > 6 ? (
                            <button className={Style.relatorio}>
                                <Link to={`/RelatPagtos/${CodigoConvenio}`} target="_blank">
                                    Relatório de Pagamentos
                                </Link>
                            </button>) :
                            (
                                <button className={Style.relatorio2}>
                                    Relatório de Pagamentos
                                </button>
                            )}
                    </Box>


                </div>

            </div>
            <Modal show={loading} className={Style.Modal} centered >
                <Modal.Body className={Style.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>
            {login && (<Navigate to="/" replace={true} />)}
        </div>
    )
}
export default VerProjeto