import { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router";
import React, { useEffect } from 'react';
import Menu from "../Layout/Menu"
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdPrint } from "react-icons/io"
import { MdCreate } from 'react-icons/md'
import Style from "./ProcInterno.module.css";
import { Pagination } from 'react-bootstrap';
//import moment from "moment";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';

function ProcInterno() {

    const [loading, setLoading] = useState(true);
    const [processo, setProcesso] = useState([]);
    const [busca, setBusca] = useState('');
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [login, setLogin] = useState(false);
    var wTokenLocal = localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    const getProcesso = async () => {
        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ProcessoInterno',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const data = response.data;
                setProcesso(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                setLoading(false);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getProcesso();
        // eslint-disable-next-line
    }, []);

    if (processo !== null) {
        // Calculate the total number of pages
        var totalPages = Math.ceil(processo.length / itemsPerPage);
    }
    if (itensFiltrados.length > 0 && (totalPages = Math.ceil(itensFiltrados.length / itemsPerPage)));

    // Calculate the indexes of the first and last items of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    if (processo !== null) {
        // Get the items of the current page
        var currentItems = processo.slice(indexOfFirstItem, indexOfLastItem);
    }
    if (itensFiltrados.length > 0 && (currentItems = itensFiltrados.slice(indexOfFirstItem, indexOfLastItem)));

    // Generate the list of items of pagination
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => setCurrentPage(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    const handleSearch = (event) => {
        const value = event.target.value;
        //getCadastro();
        const filteredItems = processo.filter((item) =>
            item.Titulo.toLowerCase().includes(value.toLowerCase())
        );
        setItensFiltrados(filteredItems);
        setBusca(value);
        //setCadastro(filteredItems);
    };

    const handleGravaCodigoProcesso = (codigoProcessoInterno) => {
        localStorage.setItem("CodigoProcessoInterno", codigoProcessoInterno);
    };

    const handlePrintClick = (wCodProcInterno) => {
        //console.log('entrou aqui no print: ' + wCodProcInterno);
        window.open(`/PrintProcessoInterno/${wCodProcInterno}`, '_blank');
    };

    return (
        <div className={Style.page}>

            <Menu />

            <div className={Style.btn_container}>
                <button className={Style.create_btn}>
                    <Link to='/CadProcessoInterno'>
                        Novo Processo
                        <MdCreate />
                    </Link>
                </button>
            </div>

            <div className={Style.header}>

                <div>
                    <h1>Processos Internos</h1>
                </div>

                <div className={Style.input_container}>
                    <AiOutlineSearch />
                    <input type="text" value={busca} onChange={handleSearch} placeholder="Pesquisar..." />
                </div>

            </div>

            <div className={Style.table_container}>

                <Table bordered className={Style.table}>
                    <thead>
                        <tr>
                            <th className={Style.th_numero}>Número</th>
                            <th className={Style.th}>Título</th>
                            <th className={Style.th_btn}></th>
                            <th className={Style.th_btn}></th>
                        </tr>
                    </thead>
                    <tbody>

                        {processo !== null ? (
                            (itensFiltrados.length > 0 ?
                                (
                                    currentItems.map((cadastrado) => (
                                        <tr key={cadastrado.CodigoProcessoInterno}>
                                            <td className={Style.td_numero}>{cadastrado.CodigoProcessoInterno}</td>
                                            <td>{cadastrado.Titulo}</td>
                                            <td className={Style.td_btn}>
                                                <Link to={`/verProcessoNew/${cadastrado.CodigoProcessoInterno}`}>
                                                    <button className={Style.btn_look} onClick={() => handleGravaCodigoProcesso(cadastrado.CodigoProcessoInterno)}>
                                                        <AiOutlineSearch title="Abrir" />
                                                    </button>
                                                </Link>
                                            </td>
                                            <td className={Style.td_btn}>
                                                <button className={Style.btn_print} onClick={() => handlePrintClick(cadastrado.CodigoProcessoInterno)}><IoMdPrint /></button>
                                            </td>
                                        </tr>
                                    ))
                                ) :
                                (
                                    currentItems.map((cadastrado) => (
                                        <tr key={cadastrado.CodigoProcessoInterno}>
                                            <td className={Style.td_numero}>{cadastrado.CodigoProcessoInterno}</td>
                                            <td>{cadastrado.Titulo}</td>
                                            <td className={Style.td_btn}>
                                                <Link to={`/verProcessoNew/${cadastrado.CodigoProcessoInterno}`}>
                                                    <button className={Style.btn_look} onClick={() => handleGravaCodigoProcesso(cadastrado.CodigoProcessoInterno)}>
                                                        <AiOutlineSearch title="Abrir" />
                                                    </button>
                                                </Link>
                                            </td>
                                            <td className={Style.td_btn}>
                                                <button className={Style.btn_print} onClick={() => handlePrintClick(cadastrado.CodigoProcessoInterno)}><IoMdPrint /></button>
                                            </td>
                                        </tr>
                                    ))
                                ))) : (<tr>
                                    <td>Não há processos cadastrados</td>
                                </tr>)}
                    </tbody>
                </Table>

                {/* Display the pagination */}
                <Pagination>{items}</Pagination>

                <Modal show={loading} className={Style.Modal} centered >
                    <Modal.Body className={Style.Modal_body}>
                        <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                    </Modal.Body>
                </Modal>

                {login && (<Navigate to="/" replace={true} />)}
            </div>
        </div>
    )
}
export default ProcInterno

