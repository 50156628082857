import Menu from "../Layout/Menu";
import StyleForm from './CadProcessoInterno.module.css'
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { MdCreate } from 'react-icons/md'
import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router";
import moment from "moment";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import loadingGif from "../img/icons8-spinning-circle.gif";
import check_circle from "../img/check_circle.gif";
import { ImSad2 } from 'react-icons/im';
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";


function CadProcessoInterno() {

  const [wCodigoProcessoNovo, setwCodigoProcessoNovo] = useState(0);
  const [login, setLogin] = useState(false);
  const [gravando, setGravando] = useState(false);
  const [CodigoProcesso, setCodigoProcesso] = useState('');
  const [tituloProcesso, setTituloProcesso] = useState('');
  const [interessado, setInteressado] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataUltimoMovimento, setDataUltimoMovimento] = useState('');
  const [perfilDestinoAtual, setPerfilDestinoAtual] = useState('');
  const [loading, setLoading] = useState(false);
  const [processoOk, setProcessoOk] = useState(false);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalErro, setShowModalErro] = useState(false);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState('');
  const [projetos, setProjetos] = useState([]);
  const [projetoSelecionado, setProjetoSelecionado] = useState(0);

  function handleClose() {
    setTimeout(() => {
      setShowModal(false);
      setProcessoOk(true);
    }, 5000);
  }

  function handleCloseErro() {
    setShowModalErro(false);
    setProcessoOk(false);
  };

  var wTokenLocal = localStorage.getItem("token");
  function ConsultaToken() {
    //verifica se tem o token no localstorage
    if (wTokenLocal === undefined || wTokenLocal === null) {
      setLogin(true)
    }
  }
  useEffect(() => {
    ConsultaToken();
    // eslint-disable-next-line
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    GravaProcessoNovo();
  }

  function GravaProcessoNovo() {
    setGravando(true);

    //let wAdiantamento = opcaoSelecionada === 'Adiantamentos'

    var datHoje = new Date();
    var wtokenBearer = "Bearer " + localStorage.getItem("token");
    var wUsuario = localStorage.getItem("CodigoUsuario");
    var wNomeUsuario = localStorage.getItem("NomeUsuario");
    var data = JSON.stringify({
      "CodigoProcessoInterno": 0,
      "Titulo": tituloProcesso,
      "Detalhes": '<strong>Informação inserida por: </strong>' + wNomeUsuario + '<br>' + interessado + '<br> ________________________________ ',
      "DataInicio": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataUltimoMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "PerfilDestinoAtual": wUsuario,
      "CodigoConvenio": projetoSelecionado,
    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/ProcessoInterno',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json'
      },
      data: data
    };
    //console.log(data)
    //console.log(dataAssinatura);
    //console.log(config);
    axios(config)

      .then(function (response) {

        //console.log(response.data)
        if (response.data.isOk === true) {
          // fez ao consumo sem erro
          setwCodigoProcessoNovo(response.data.objetoRetorno.CodigoProcessoInterno);

          //cria novo registro na tblStatusProcessoInterno

          var wMensagemNovaStatus = '<strong>Processo Interno Criado Por: </strong><br>' + wNomeUsuario + '<br>' + interessado;
          // var wPerfil = localStorage.getItem("CodigoPerfil");
          var dataStatusProc = JSON.stringify({
            "CodigoStatusProcessoInterno": 0,
            "CodigoProcessoInterno": response.data.objetoRetorno.CodigoProcessoInterno,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": wMensagemNovaStatus,
            "CodigoPerfil": wUsuario,
            "Doc": "",
            "PerfilDestino": wUsuario,
            "CodCadastro": localStorage.getItem("CodigoCadastro")
          });
          var configStatusProc = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/StatusProcessoInterno',
            headers: {
              'Authorization': wtokenBearer,
              'Content-Type': 'application/json',
            },
            data: dataStatusProc
          };
          //console.log(configProc);
          axios(configStatusProc)
            .then(function (responseStatusProc) {
              if (responseStatusProc.data.isOk === true) {
                setGravando(false);
                setShowModal(true);
              }
            })

            .catch(error => {
              console.error(error);
              setLoading(false);
              setShowModalErro(true);
            });

        } else {
          //deu algum erro tratar , colocar algum aviso
          setGravando(false);
          setShowModalErro(true);
          //console.log("entrou aqui 333")
          //console.log(response.data);
        }
      })
      .catch(error => {
        setGravando(false);
        console.error(error);
        setShowModalErro(true);
      });
  }

  const handleFechaGravando = (event) => {
    if (processoOk === true) { setGravando(false) };
  }

  function handleBlur() {
    if (tituloProcesso.length < 3) {
      setError('O título tem que ter pelo menos 3 caracteres');
    } else {
      setError('');
    }
  }

  const handleOpcaoChange = (event) => {
    setOpcaoSelecionada(event.target.value);
    //if (event.target.value === 'Adiantamento') { handleAddItemAdiantamento(); }
  };

  const getProjeto = async (event) => {
    var wtokenBearer = "Bearer " + localStorage.getItem("token");
    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/convenio',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json'
      },
    };
    axios(config)
      .then(function (response) {
        //console.log(response.data.objetoRetorno);
        setProjetos(response.data.objetoRetorno);
      }
      )
      .catch(function (error) {
        console.log(error);
        //console.log(JSON.stringify(response.data.mensagemRetorno));
        if (error.response.status === 401) {
          setLogin(true);
        }
      });
  }

  useEffect(() => {
    getProjeto();
    // eslint-disable-next-line
  }, []);

  const handleSelectProjeto = (event) => {
    const codigoConvenio = parseInt(event.target.value, 10); // Converte para número
    //console.log('Valor selecionado:', codigoConvenio);

    const projetoSelecionado2 = projetos.find((projeto) => projeto.CodigoConvenio === codigoConvenio);
    //console.log('Projeto encontrado nome:', projetoSelecionado.Nome);

    if (projetoSelecionado2) {
      setInteressado(projetoSelecionado2.Nome);
      setProjetoSelecionado(projetoSelecionado2.CodigoConvenio);
    } else {
      // Lida com o caso em que o projetoSelecionado não foi encontrado
      //console.log('Projeto não encontrado!');
    }
  };

  return (
    <div>
      <Menu />
      <div> <button className={StyleForm.voltar}>  <Link to="/ProcInterno" ><IoMdArrowBack /> Voltar</Link> </button> </div>
      <div className={StyleForm.header}>
        <h1>Cadastro de Processos</h1>
        <p>Preencha os campos para um novo processo</p>
      </div>
      {error && <div align="center" style={{ margin: '0 auto', width: '15vw', borderRadius: '5px', fontWeight: '600', backgroundColor: '#FDEDEC', color: '#C0392B' }}>{error}</div>}
      <div className={StyleForm.container}>
        <Form className={StyleForm.form} onSubmit={handleSubmit}>
          <FormGroup className={StyleForm.form_group}>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}> Escolha o Tipo</Form.Label>
                <Row>
                  <Col>
                    <Form.Check
                      className={StyleForm.form_control_Check}
                      type="radio"
                      id="Diversos"
                      name="Diversos"
                      label="Diversos"
                      value="Diversos"
                      checked={opcaoSelecionada === 'Diversos'}
                      onChange={handleOpcaoChange}
                    />
                  </Col>
                  <Col md={10}>
                    <Form.Check
                      className={StyleForm.form_control_Check}
                      type="radio"
                      id="Adiantamentos"
                      name="Adiantamentos"
                      label="Adiantamentos"
                      value="Adiantamentos"
                      checked={opcaoSelecionada === 'Adiantamentos'}
                      onChange={handleOpcaoChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                </Row>
              </Col>
            </Row>
            {opcaoSelecionada === 'Adiantamentos' &&
              <Row>
                <Col>
                  <Form.Label className={StyleForm.form_group_label}>Selecione o Projeto</Form.Label>
                  <Form.Select className={StyleForm.form_control} name="projeto" id="projeto" value={projetoSelecionado} onChange={handleSelectProjeto}>
                    <option value="0"></option>
                    {projetos.length > 0 &&
                      projetos.map((opcao) => (
                        <option key={opcao.CodigoConvenio} value={opcao.CodigoConvenio}>
                          {opcao.Nome}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}> Título do Processo</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="tituloprocesso" onChange={(event) => setTituloProcesso(event.target.value)} onBlur={handleBlur} required />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}> Interessado</Form.Label>
                <textarea className={StyleForm.form_control_textarea} name="interessado" id="interessado" value={interessado} placeholder="A quem interessa o processo?" onChange={(event) => setInteressado(event.target.value)} required ></textarea>
              </Col>
            </Row>

          </FormGroup>
          <div className={StyleForm.form_group_textarea}>

          </div>
          <FormGroup className={StyleForm.form_group}>
            <Row>
              <button className={StyleForm.btn} type="submit">
                <span>Gravar Processo</span><MdCreate />

              </button>
            </Row>
          </FormGroup>

        </Form>

        <Modal show={loading} className={StyleForm.Modal} centered >
          <Modal.Body className={StyleForm.Modal_body}>
            <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
          </Modal.Body>
        </Modal>

        <Modal show={gravando} onLoad={handleFechaGravando} className={StyleForm.Modal} centered>
          <Modal.Header className={StyleForm.Modal_header}>
            <h3>Gravando...</h3>
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <img src={loadingGif}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
          <Modal.Header className={StyleForm.Modal_header}>
            <img src={check_circle} alt="ok"></img>
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <p>Cadastro realizado com sucesso!</p>
            <p>Agora você pode Encaminhar ou incluir documentos no processo.</p>
          </Modal.Body>
        </Modal>

        <Modal show={showModalErro} className={StyleForm.Modal} centered >
          <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
            <h3>Ops!</h3>
            <ImSad2 />
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <p>Algo deu errado, verifique os campos e tente novamente</p>
          </Modal.Body>
        </Modal>

      </div>
      {login && (<Navigate to="/" replace={true} />)}
      {processoOk && (<Navigate to={`/ProcInterno`} replace={true} />)}
    </div>
  )
}
export default CadProcessoInterno




