import { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router";
import React, { useEffect } from 'react';
import Menu from "../Layout/Menu"
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";

import { AiOutlineSearch } from "react-icons/ai";
import { MdCreate } from 'react-icons/md'

import Style from "./ListProjetos.module.css";

import { Pagination } from 'react-bootstrap';
import moment from 'moment';
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';



function ListProjetos() {

    const [loading, setLoading] = useState(true);
    const [projeto, setProjeto] = useState([]);
    const [busca, setBusca] = useState('');
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [login, setLogin] = useState(false);

    var wTokenLocal = localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    const getProjeto = async () => {
        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setProjeto(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno)
            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getProjeto();
        // eslint-disable-next-line
    }, []);

    if (projeto !== null) {
        // Calculate the total number of pages
        var totalPages = Math.ceil(projeto.length / itemsPerPage);
    }

    if (itensFiltrados.length > 0 && (totalPages = Math.ceil(itensFiltrados.length / itemsPerPage)));



    // Calculate the indexes of the first and last items of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    if (projeto !== null) {
        // Get the items of the current page
        var currentItems = projeto.slice(indexOfFirstItem, indexOfLastItem);
    }

    if (itensFiltrados.length > 0 && (currentItems = itensFiltrados.slice(indexOfFirstItem, indexOfLastItem)));


    // Generate the list of items of pagination
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => setCurrentPage(number)}
            >
                {number}
            </Pagination.Item>
        );
    }


    const handleSearch = (event) => {
        const value = event.target.value;
        //getCadastro();
        const filteredItems = projeto.filter((item) =>
            item.Nome.toLowerCase().includes(value.toLowerCase())
        );
        setItensFiltrados(filteredItems);
        setBusca(value);
        //setCadastro(filteredItems);
    };

    const handleGravaCodigoProjeto = (codigoConvenio, dataInicio, dataExpiracao) => {
        localStorage.setItem("CodigoProjeto", codigoConvenio);
        localStorage.setItem("DataInicioProjeto", moment(dataInicio).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
        localStorage.setItem("DataExpiracaoProjeto", moment(dataExpiracao).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    };

    return (

        <div className={Style.page}>

            <Menu />

            <div className={Style.btn_container}>
                <button className={Style.create_btn}>
                    <Link to='/CadProjeto'>
                        Novo Projeto
                        <MdCreate />
                    </Link>
                </button>
            </div>

            <div className={Style.header}>

                <h1>Projetos</h1>

                <input type="text" value={busca} onChange={handleSearch} />

            </div>

            <div className={Style.table_container}>



                <Table bordered className={Style.table}>
                    <thead>
                        <tr>
                            <th className={Style.th_numero}>Número</th>
                            <th className={Style.th}>Nome</th>
                            <th className={Style.th_btn}></th>
                        </tr>
                    </thead>
                    <tbody>

                        {projeto !== null ? (
                            (itensFiltrados.length > 0 ?
                                (
                                    currentItems.map((cadastrado) => (
                                        <tr key={cadastrado.CodigoConvenio}>
                                            <td className={Style.td_numero}>{cadastrado.NumeroConvenio}</td>
                                            <td>{cadastrado.Nome}</td>
                                            <td className={Style.td_btn}>
                                                <button className={Style.btn_look}>
                                                    <Link to={`/verProjeto/${cadastrado.CodigoConvenio}`} onClick={() => handleGravaCodigoProjeto(cadastrado.CodigoConvenio, cadastrado.DataInicio, cadastrado.DataExpiracao)}>
                                                        <AiOutlineSearch title="Abrir" />
                                                    </Link>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) :
                                (
                                    currentItems.map((cadastrado) => (
                                        <tr key={cadastrado.CodigoConvenio}>
                                            <td className={Style.td_numero}>{cadastrado.NumeroConvenio}</td>
                                            <td>{cadastrado.Nome}</td>
                                            <td className={Style.td_btn}>
                                                <button className={Style.btn_look} >
                                                    <Link to={`/verProjeto/${cadastrado.CodigoConvenio}`} onClick={() => handleGravaCodigoProjeto(cadastrado.CodigoConvenio, cadastrado.DataInicio, cadastrado.DataExpiracao)}>
                                                        <AiOutlineSearch title="Abrir" />
                                                    </Link>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ))) : (<tr>
                                    <td>Não há projetos cadastrados</td>
                                </tr>)}
                    </tbody>
                </Table>

                {/* Display the pagination */}
                <Pagination>{items}</Pagination>

                <Modal show={loading} className={Style.Modal} centered >
                    <Modal.Body className={Style.Modal_body}>
                        <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                    </Modal.Body>
                </Modal>

                {login && (<Navigate to="/" replace={true} />)}
            </div>
        </div>



    )

};

export default ListProjetos