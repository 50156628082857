import Style from './SolicComparativo.module.css';
import Alert from 'react-bootstrap/Alert';
import { Navigate } from "react-router-dom";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import ReactDOMServer from 'react-dom/server';
import Menu from "../Layout/Menu";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import urlAPIlocal from '../../global';
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { ImAttachment } from "react-icons/im";
import { AiFillPlusCircle } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { BiSolidError } from 'react-icons/bi';
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
import { ImSad2 } from 'react-icons/im';
import loadingGif from '../img/icons8-spinning-circle.gif';
import moment from "moment";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";

function SolicComparativo() {
    const wtokenLocal = localStorage.getItem("token");
    //console.log(wtokenLocal);
    const { CodigoSolicitacao } = useParams();
    const [itens, setItens] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const [itemEscolhido, setItemEscolhido] = useState([]);
    const [itemEscolhidoSelect, setItemEscolhidoSelect] = useState(0);
    const [showFormCad, setShowFormCad] = useState(false);
    const [condPagto, setCondPagto] = useState('');
    const [fornecedores, setFornecedores] = useState([]);
    const [totalComparativo, setTotalComparativo] = useState([]);
    const [fornecedorEscolhido, setFornecedorEscolhido] = useState('0');
    const [prazoEntrega, setPrazoEntrega] = useState('');
    const [validadeProposta, setValidadeProposta] = useState(0);
    const [valFrete, setValFrete] = useState(0);
    const [CND, setCND] = useState(false);
    const [CNF, setCNF] = useState(false);
    const [CNDT, setCNDT] = useState(false);
    const [valorUnitario, setValorUnitario] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const [showgravado, setShowGravado] = useState(false);
    const [showAviso, setShowAviso] = useState(false);
    const [showAvisoExisteCadastro, setShowAvisoExisteCadastro] = useState(false);
    const [comparativoItemFornecedor, setComparativoItemFornecedor] = useState([]);
    const [putoupost, SetPutouPost] = useState('');
    const [codigoComparativo, setCodigoComparativo] = useState(0);

    //const [comparativoStyle, setComparativoStyle] = useState({});

    const [loading, setLoading] = useState(false);
    const [tudoOk, setTudoOk] = useState(false);

    function handleClose() {
        setTimeout(() => {
            setTudoOk(false);
        }, 2000);

    }

    const handleSelectItem = (event) => {
        setItemEscolhidoSelect(event.target.value);

        PegaItemEscolhido(event.target.value);
        //console.log(event.target.value)
        //mostra form para cadastro de preço no item
        setShowFormCad(true);
        setShowGravado(false);
        //limpar campos
        //setFornecedorEscolhido('0');
        setPrazoEntrega(0);
        setValidadeProposta(0);
        setValFrete(0);
        setCND(false);
        setCNF(false);
        setCNDT(false);
        setValorUnitario(0);
        setValorTotal(0);
        setCondPagto('')
        //setItemEscolhidoSelect(0);
        //setShowGravado(true);
        getItens();
        setFornecedorEscolhido('0');

    };

    //Requisição SOLICITAÇÃO
    const getSolicitacao = async () => {
        //setLoading(true);
        const wtoken = "Bearer " + wtokenLocal;
        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(configSolic);
        axios(configSolic)
            .then(function (responseSolic) {
                const data = responseSolic.data;
                //console.log(data.objetoRetorno[0].Convenio.Nome);
                //console.log(data.objetoRetorno[0]);
                setSolicitacao(data.objetoRetorno[0]);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    //setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacao();
        //eslint-disable-next-line
    }, []);


    //pega os itens da solicitacao
    const getItens = async () => {

        try {
            const wtokenFinal = "Bearer " + wtokenLocal;

            var configItem = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/SolicitacaoItem?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
                headers: {
                    'Authorization': wtokenFinal,
                    'Content-Type': 'application/json'
                },

            };
            // console.log(configItem);

            const response = await axios(configItem);

            const data = response.data.objetoRetorno;
            //console.log(data);

            const sortedData = data.sort((a, b) => {
                // Lógica de classificação aqui
                // Exemplo: Ordenar por propriedade "nome" em ordem crescente
                if (a.NumeroItem < b.NumeroItem) {
                    return -1;
                }
                if (a.NumeroItem > b.NumeroItem) {
                    return 1;
                }
                return 0;
            });

            setItens(sortedData);
            // Objeto para armazenar os totais por CodCadastro.
            const totaisPorCodCadastro = {};
            // Itera sobre o array objetoRetorno.
            sortedData.forEach((item) => {
                // Itera sobre os itens em ComparativoPreco para calcular o ValorTotal.
                item.ComparativoPreco.forEach((comparativo) => {
                    const CodCadastro = comparativo.CodigoCadastro;
                    const NomeCadastro = comparativo.Cadastro.Nome;
                    const ValorFrete = comparativo.Frete;
                    console.log(comparativo.Frete);
                    // Inicializa o objeto para o CodCadastro se ainda não existir.
                    if (!totaisPorCodCadastro[CodCadastro]) {
                        totaisPorCodCadastro[CodCadastro] = {
                            Total: 0,
                            Nomes: [],
                            Frete: 0
                        };
                    }
                    // Calcula o ValorTotal para cada item em ComparativoPreco.
                    totaisPorCodCadastro[CodCadastro].Total += comparativo.ValorUnitario * item.Quantidade;
                    // Adiciona o Nome ao array de Nomes.
                    totaisPorCodCadastro[CodCadastro].Nomes.push(NomeCadastro);
                    if (ValorFrete > 0) { totaisPorCodCadastro[CodCadastro].Frete = ValorFrete }// else {totaisPorCodCadastro[CodCadastro].Frete = 0}
                    //console.log(totaisPorCodCadastro[CodCadastro].Frete);
                });
            });

            // Crie um array para armazenar os resultados formatados.
            const totaisFormatados = [];
            // Itere sobre as chaves do objeto totaisPorCodCadastro.
            for (const CodCadastro in totaisPorCodCadastro) {
                if (totaisPorCodCadastro.hasOwnProperty(CodCadastro)) {
                    // Obtenha o objeto de totais e nomes para o CodCadastro atual.
                    const totaisENomes = totaisPorCodCadastro[CodCadastro];
                    console.log(totaisENomes);
                    // Crie um objeto formatado com as informações desejadas.
                    const resultadoFormatado = {
                        Total: totaisENomes.Total,
                        Nome: totaisENomes.Nomes.join(', '), // Se houver mais de um nome, eles serão separados por vírgula.
                        Frete: totaisENomes.Frete
                    };
                    //console.log(resultadoFormatado);
                    // Adicione o objeto formatado ao array de resultados.
                    totaisFormatados.push(resultadoFormatado);
                    console.log(totaisFormatados);
                }
            }


            setTotalComparativo(totaisFormatados);
            //console.log(sortedData);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getItens();
        // eslint-disable-next-line
    }, []);


    //getCompartivo
    /*  const getComparativoprecos = async () => {
 
         try {
             const wtokenFinal = "Bearer " + wtokenLocal;
 
             var configItem = {
                 method: 'GET',
                 maxBodyLength: Infinity,
                 url: urlAPIlocal + '/api/ComparativoPreco?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
                 headers: {
                     'Authorization': wtokenFinal,
                     'Content-Type': 'application/json'
                 },
 
             };
             // console.log(configItem);
 
             const response = await axios(configItem);
 
             const data = response.data.objetoRetorno;
             //console.log(data);
 
             const sortedData = data.sort((a, b) => {
                 // Lógica de classificação aqui
                 // Exemplo: Ordenar por propriedade "nome" em ordem crescente
                 if (a.NumeroItem < b.NumeroItem) {
                     return -1;
                 }
                 if (a.NumeroItem > b.NumeroItem) {
                     return 1;
                 }
                 return 0;
             });
 
             setComparativo(sortedData);
             console.log(sortedData);
         } catch (error) {
             console.log(error)
         }
     };
 
     useEffect(() => {
         getComparativoprecos();
         // eslint-disable-next-line
     }, []);
  */
    const handleSubmit = async (event) => {
        event.preventDefault();

        //formata valorunitario
        var wValorDecimal = valorUnitario.toString();
        wValorDecimal = wValorDecimal.replace(',', '.');
        //formata frete
        var wValorDecimalFrete = valFrete.toString();
        wValorDecimalFrete = wValorDecimalFrete.replace(',', '.');

        //console.log(fornecedorEscolhido);
        //so grava se escolheu um fornecedor
        if (fornecedorEscolhido !== '0') {
            // antes de gravar ver se o item já tem preço com mesmo fornecedor
            /* TemPrecoCadastrado(fornecedorEscolhido, itemEscolhido.CodigoSolicitacaoItem)
                .then((resultado) => {
                    // Aqui, você tem acesso ao resultado da função assíncrona
                    //console.log(resultado);
                    if (resultado === true) {
                        // Faça algo quando o retorno for verdadeiro
                        setShowAvisoExisteCadastro(true);
                    } else { */
            // Faça algo quando o retorno for falso
            setShowAviso(false);
            setShowAvisoExisteCadastro(false);
            //grava novo preco na tblComparativoPreco
            var wtokenBearer = "Bearer " + localStorage.getItem("token");
            var data = JSON.stringify(
                {
                    "Id": codigoComparativo,
                    "CodigoSolicitacao": itemEscolhido.CodigoSolicitacao,
                    "CodigoSolicitacaoItem": itemEscolhido.CodigoSolicitacaoItem,
                    "CodigoCadastro": fornecedorEscolhido,
                    "ValorUnitario": wValorDecimal,
                    "Validade": validadeProposta,
                    "CondicaoPagamento": condPagto,
                    "PrazoEntrega": "" + prazoEntrega + "",
                    "SituacaoCND": CND,
                    "SituacaoCRF": CNF,
                    "SituacaoCNDT": CNDT,
                    "Frete": wValorDecimalFrete
                });

            var config = {

                method: putoupost,
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/ComparativoPreco',
                headers: {
                    'Authorization': wtokenBearer,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //console.log(data)
            axios(config)

                .then(function (response) {

                    //console.log(response.data)
                    if (response.data.isOk === true) {


                        //grava valor do frete do item
                        setShowFormCad(false);
                        //limpa campos
                        setFornecedorEscolhido('0');
                        setPrazoEntrega(0);
                        setValidadeProposta(0);
                        setValFrete(0);
                        setCND(false);
                        setCNF(false);
                        setCNDT(false);
                        setValorUnitario(0);
                        setValorTotal(0);
                        setCondPagto('')
                        setItemEscolhidoSelect(0);
                        setShowGravado(true);
                        setCodigoComparativo(0);
                        getItens();

                    } else {
                        setShowFormCad(true);
                        setShowGravado(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setShowFormCad(true);
                    setShowGravado(false);
                });
            /*      }
               })
              .catch((error) => {
                  // Trate erros, se necessário
              }); */

        } else {
            setShowAviso(true);
        }
    }

    useEffect(() => {
        if (showgravado) {
            const timeoutId = setTimeout(() => {
                setShowGravado(false);
            }, 3000);

            // Limpar o timeout se o componente for desmontado antes do término do intervalo de 3 segundos
            return () => clearTimeout(timeoutId);
        }
    }, [showgravado]);

    const getFornecedores = async () => {
        try {
            const wtokenFinal = "Bearer " + wtokenLocal;
            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Cadastro?Aprovado=1&IsInclude=true",
                headers: {
                    Authorization: wtokenFinal,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios(config);
            const data = response.data.objetoRetorno;
            //filtrar de data somente cadastros do CodigoTipoCadastro = 43 ou 33
            const cadastrosFiltrados = data.filter(item => item.CodigoTipoCadastro === 33 || item.CodigoTipoCadastro === 43);
            setFornecedores(cadastrosFiltrados);
        } catch (error) {
            console.log(error)
        }
    };
    useEffect(() => {
        getFornecedores();
        // eslint-disable-next-line
    }, []);

    const handleSelectFornecedor = (event) => {
        //console.log(event.target.value);

        //console.log(event.target.value)
        setShowAviso(false);

        //verifica se ja tem cmparativo para esse item e fornecedor, se tiver preencher os inputs e mudar para put
        TemPrecoCadastrado(event.target.value, itemEscolhido.CodigoSolicitacaoItem)
            .then((resultado) => {
                // Aqui, você tem acesso ao resultado da função assíncrona
                //console.log(resultado);
                if (resultado === true) {

                } else {
                    //console.log('entrou no false do handlefornecedor');
                    SetPutouPost('POST');
                    setPrazoEntrega(0);
                    setValidadeProposta(0);
                    setValFrete(0);
                    setCND(false);
                    setCNF(false);
                    setCNDT(false);
                    setValorUnitario(0);
                    setValorTotal(0);
                    setCondPagto('')
                    setCodigoComparativo(0);
                }
            })
            .catch((error) => {
                // Trate erros, se necessário
                //console.log('entrou no false error do handlefornecedor');
            });

        setFornecedorEscolhido(event.target.value);
    };

    const handleCheckCND = (event) => {
        //const arquivoSelecionado = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // Se o checkbox foi marcado, faça algo
            setCND(true);
        } else {
            setCND(false);
        }
    };

    const handleCheckCNF = (event) => {
        //const arquivoSelecionado = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // Se o checkbox foi marcado, faça algo
            setCNF(true);
        } else {
            setCNF(false);
        }
    };

    const handleCheckCNDT = (event) => {
        //const arquivoSelecionado = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // Se o checkbox foi marcado, faça algo
            setCNDT(true);
        } else {
            setCNDT(false);
        }
    };

    const handleValorUnitario = (event) => {
        setValorUnitario(event.target.value);
        var quantidadeString = itemEscolhido.Quantidade.toString();
        var wTotal = event.target.value.replace(/,/g, '.') * quantidadeString.replace(/,/g, '.');
        setValorTotal(wTotal);
        //console.log(quantidadeString);
        //console.log(wTotal);
    };

    function PegaItemEscolhido(wCodItem) {
        const wtoken = "Bearer " + wtokenLocal;
        var configItem = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem?CodigoSolicitacaoItem=' + wCodItem + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(configItem);
        axios(configItem)
            .then(function (responseItem) {
                const data = responseItem.data;
                setItemEscolhido(data.objetoRetorno[0]);
                //console.log(data.objetoRetorno[0]);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    function TemPrecoCadastrado(wCodCadastro, wCodItem) {
        return new Promise((resolve, reject) => {
            const wtoken = "Bearer " + wtokenLocal;
            var datFiles = JSON.stringify({
                "CodigoSolicitacao": CodigoSolicitacao,
                "CodigoSolicitacaoItem": wCodItem,
                "CodigoCadastro": wCodCadastro
            });
            var config = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/ComparativoPreco?CodigoSolicitacao=' + CodigoSolicitacao + '&CodigoSolicitacaoItem=' + wCodItem + '&CodigoCadastro=' + wCodCadastro + '&IsInclude=true',
                headers: {
                    'Authorization': wtoken,
                    'Content-Type': 'application/json'
                },
                data: datFiles
            };

            axios(config)
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.isOk === true) {
                        if (response.data.codigoRetorno === '1') {
                            setComparativoItemFornecedor(response.data.objetoRetorno[0]);
                            setValorUnitario(response.data.objetoRetorno[0].ValorUnitario);
                            setCondPagto(response.data.objetoRetorno[0].CondicaoPagamento);
                            setPrazoEntrega(response.data.objetoRetorno[0].PrazoEntrega);
                            setValidadeProposta(response.data.objetoRetorno[0].Validade);
                            setValFrete(response.data.objetoRetorno[0].Frete);
                            setCND(response.data.objetoRetorno[0].SituacaoCND);
                            setCNF(response.data.objetoRetorno[0].SituacaoCNF);
                            setCNDT(response.data.objetoRetorno[0].SituacaoCNDT);
                            SetPutouPost('PUT');
                            setCodigoComparativo(response.data.objetoRetorno[0].Id);
                            resolve(true);
                        } else {
                            //console.log('entrou no false nao tem comparativo');
                            SetPutouPost('POST');
                            setPrazoEntrega(0);
                            setValidadeProposta(0);
                            setValFrete(0);
                            setCND(false);
                            setCNF(false);
                            setCNDT(false);
                            setValorUnitario(0);
                            setValorTotal(0);
                            setCondPagto('')
                            setCodigoComparativo(0);
                            resolve(false);
                            //console.log('entrou no false');
                        }
                    } else {
                        // Tratar erro, mensagem ou algo na tela quando consumo retornar falha
                        //console.log('entrou no false erro temcomparativo');
                        //console.log(response.data);
                        SetPutouPost('POST');
                        setPrazoEntrega(0);
                        setValidadeProposta(0);
                        setValFrete(0);
                        setCND(false);
                        setCNF(false);
                        setCNDT(false);
                        setValorUnitario(0);
                        setValorTotal(0);
                        setCondPagto('')
                        setCodigoComparativo(0);
                        resolve(false);
                        //console.log('entrou no false error');
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    function MontaQuadroComparativo(event) {
        event.preventDefault();

        setLoading(true);

        // Criando uma div
        const ArquivoMontado = (
            <div>

                <div style={{ textAlign: 'center' }}>
                    <img src='https://fauscs.sistemacontroll.com.br/UploadsFauscs/Cabecalho.png' style={{ width: '350px', display: 'inline-block' }} alt="Header Image" />
                </div>

                <div>
                    <h4 style={{ fontSize: '20px', color: '#222', textAlign: 'center' }}>Quadro Comparativo de Preços</h4>
                    <hr style={{ color: '#bbb' }}></hr>
                </div>
                <div style={{ textAlign: 'center', fontSize: '16px', color: '#BA4A00', marginBottom: '1em' }}><strong>Cotação - {solicitacao.length !== 0 ? (solicitacao.Convenio.Nome) : (null)}</strong></div>

                <div>

                    {(itens.map((ListItens, indexItens) => (
                        <div style={{ padding: '1em', border: '1px dashed #bbb', borderRadius: '5px', marginBottom: '2em', width: '1200px', margin: '0 auto' }}>
                            <Table key={indexItens} style={{ width: '1200px', margin: '0 auto' }}>
                                <tr>
                                    <td style={{ width: '1200px', textAlign: 'center' }}>
                                        <Table style={{ width: '1200px' }}>
                                            <tr style={{ borderBottom: '2px solid #1B4F72', borderTop: '0', borderRight: '0', borderLeft: '0', backgroundColor: '#EBF5FB' }}>
                                                <th style={{ textAlign: 'center', color: '#1B4F72', padding: '.3em', fontSize: '14px' }}><strong>Item</strong></th>
                                                <th style={{ textAlign: 'center', color: '#1B4F72', padding: '.3em', fontSize: '14px' }}><strong>Objeto</strong></th>
                                                <th style={{ textAlign: 'center', color: '#1B4F72', padding: '.3em', fontSize: '14px' }}><strong>Quantidade</strong></th>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#2E86C1', padding: '.3em', fontSize: '14px' }}>{ListItens.NumeroItem}</td>
                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#2E86C1', padding: '.3em', fontSize: '14px' }}>{ListItens.Nome}</td>
                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#2E86C1', padding: '.3em', fontSize: '14px' }}>{ListItens.Quantidade}</td>
                                            </tr>
                                        </Table>
                                    </td>
                                </tr>
                                <tr><td></td></tr>
                                <tr>
                                    <td>
                                        {ListItens.ComparativoPreco.length > 0 ? (

                                            <Table>
                                                <tr style={{ borderBottom: '1px solid #333', borderTop: '0', borderRight: '0', borderLeft: '0', backgroundColor: '#EAECEE', fontSize: '14px', padding: '.5em' }}>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Fornecedor</strong></th>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Valor Unitário</strong></th>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Valor Total</strong></th>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Validade Proposta</strong></th>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Condição Pagamento</strong></th>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Prazo Entrega</strong></th>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Situação CND</strong></th>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Situação CRF</strong></th>
                                                    <th style={{ textAlign: 'center', color: '#333' }}><strong>Situação CNDT</strong></th>

                                                </tr>
                                                {(ListItens.ComparativoPreco.map((ListComparativo, indexComparativo) => (
                                                    <tr key={indexComparativo}>
                                                        <td style={{ textAlign: 'left', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '12px', padding: '.8em' }}>{ListComparativo.Cadastro.Nome}</td>
                                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.ValorUnitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{(ListComparativo.ValorUnitario * ListItens.Quantidade).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.Validade}</td>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.CondicaoPagamento}</td>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.PrazoEntrega}</td>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.SituacaoCND === true ? ("Regular") : ("Irregular")}</td>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.SituacaoCNF === true ? ("Regular") : ("Irregular")}</td>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.SituacaoCNDT === true ? ("Regular") : ("Irregular")}</td>
                                                    </tr>
                                                )))}
                                                <tr>
                                                    <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', backgroundColor: '#EAECEE', fontSize: '14px', padding: '.8em' }}><strong>Valor Médio</strong></td>
                                                    <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', fontSize: '14px', padding: '.8em', fontWeight: '600' }}>
                                                        {" "}
                                                        {(
                                                            ListItens.ComparativoPreco.reduce(
                                                                (total, ListComparativo) => total + ListComparativo.ValorUnitario,
                                                                0
                                                            ) / ListItens.ComparativoPreco.length
                                                        ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                                    </td>
                                                    <td style={{ borderBottom: '1px solid #ccc' }} colSpan='8'></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', backgroundColor: '#E9F7EF', fontSize: '14px', padding: '.8em' }}><strong>Menor Preço</strong></td>
                                                    <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', fontSize: '14px', padding: '.8em', fontWeight: '600', color: '#1E8449' }}>
                                                        {" "}
                                                        {ListItens.ComparativoPreco.length > 0
                                                            ? ListItens.ComparativoPreco.reduce(
                                                                (min, ListComparativo) =>
                                                                    ListComparativo.ValorUnitario < min
                                                                        ? ListComparativo.ValorUnitario
                                                                        : min,
                                                                ListItens.ComparativoPreco[0].ValorUnitario
                                                            ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                                                            : "N/A"}
                                                    </td>
                                                    <td style={{ textAlign: 'left', borderBottom: '1px solid #ccc', fontSize: '14px', padding: '.8em', color: '#1E8449' }} colSpan='8'>
                                                        <strong>
                                                            {ListItens.ComparativoPreco.length > 0
                                                                ? ListItens.ComparativoPreco.reduce((result, comparativo) => {
                                                                    if (comparativo.ValorUnitario <= result.min) {
                                                                        result.min = comparativo.ValorUnitario;
                                                                        result.nomeMenor = comparativo.Cadastro.Nome;
                                                                    }
                                                                    return result;
                                                                }, { min: ListItens.ComparativoPreco[0].ValorUnitario, nomeMenor: "" }).nomeMenor
                                                                : ""}
                                                        </strong>
                                                    </td>
                                                </tr>

                                            </Table>
                                        ) :
                                            (<p style={{ fontSize: '14px', color: '#222' }}>Não há quadro comparativo de preço cadastrado para esse item.</p>)}
                                    </td>
                                </tr>
                            </Table>
                        </div>
                    )))}
                    <Table style={{ width: '100%' }}>
                        {/* <tr>
                            <td style={{ textAlign: 'center', borderBottom: '2px solid #ccc', padding: '.5em' }}><strong>Total do Orçamento por Fornecedor</strong></td>
                        </tr> */}
                        <tr>
                            <th style={{ textAlign: 'left', borderBottom: '2px solid #ccc', padding: '.5em', fontWeight: '600' }}>Total do Orçamento por Fornecedor</th>
                            <th style={{ textAlign: 'right', borderBottom: '2px solid #ccc', padding: '.5em', fontWeight: '600' }}>Valor dos Itens</th>
                            <th style={{ textAlign: 'right', borderBottom: '2px solid #ccc', padding: '.5em', fontWeight: '600' }}>Frete</th>
                            <th style={{ textAlign: 'right', borderBottom: '2px solid #ccc', padding: '.5em', fontWeight: '600' }}>Valor Total</th>
                        </tr>
                        {totalComparativo && Array.isArray(totalComparativo) && totalComparativo.map((TotalComparativo, indexTotalComparativo) => (
                            <tr key={indexTotalComparativo}>
                                <td style={{ textAlign: 'left', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '12px', padding: '.3em' }}>
                                    {TotalComparativo.Nome.split(',')[0].trim()}
                                </td>
                                <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.3em' }}>{TotalComparativo.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.3em' }}>{TotalComparativo.Frete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.3em' }}>{(TotalComparativo.Frete + TotalComparativo.Total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
        );

        // Chama a função para enviar e-mail
        AnexaQuadroAoProcesso(ArquivoMontado);

        // Retorna o HTML criado
        return ArquivoMontado;

    }

    function AnexaQuadroAoProcesso(wArquivoMontado) {

        const wHtmlArquivoMontado = ReactDOMServer.renderToStaticMarkup(wArquivoMontado);

        //var wHtmlComparativo = document.getElementById('ComparativoHTML');
        //wHtmlComparativo = wHtmlComparativo.innerHTML;
        //console.log('div puro :' + wHtmlComparativo);
        var escapedHtml = wHtmlArquivoMontado.replace(/(\r\n|\n|\r)/g, '').replace(/"/g, "'");
        //console.log(escapedHtml);

        var dataHoraAtual = moment().format('YYYYMMDD_HHmmss');
        var wNomeArquivo = `QuadroComparativoPreco_${CodigoSolicitacao}_${dataHoraAtual}.pdf`;

        const wtoken = "Bearer " + wtokenLocal;

        var dataConverter = JSON.stringify({
            "pasta": 'ArquivosProcesso',
            "arquivo": wNomeArquivo,
            "html": escapedHtml,
            "template": '',
            "Orientation": 0
        });
        //console.log(escapedHtml);
        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ApiPdf/ConverterHtmlParaPdf',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
            data: dataConverter
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const dataConverter = response.data;
                if (dataConverter.isOk === true) {
                    //GRAVA ARQUIVO NA BASE

                    var datFiles = JSON.stringify({
                        "CodigoArquivo": 0,
                        "CodigoSolicitacao": CodigoSolicitacao,
                        "descricao": 'Quadro Comparativo de Preço',
                        "arquivo": wNomeArquivo,
                        "status": 0
                    })
                    var configFiles = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/ArquivoProcesso',
                        headers: {
                            'Authorization': wtoken,
                            'Content-Type': 'application/json'
                        },
                        data: datFiles
                    };
                    //console.log(datFiles)
                    axios(configFiles)
                        .then(function (responseFiles) {

                            //REGISTRA STATUS
                            var datHoje = new Date();
                            var dataStatus = JSON.stringify({
                                "CodigoStatus": 0,
                                "CodigoSolicitacao": CodigoSolicitacao,
                                "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                                "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                "status": false,
                                "Motivo": '<strong>Arquivo Inserido Por:</strong><br> ' + localStorage.getItem("NomeUsuario") + '<br>Arquivo: ' + wNomeArquivo + '<br>Quadro Comparativo de Preços',
                                "CodigoPerfil": localStorage.getItem("CodigoPerfil"),
                                "Doc": "",
                                "PerfilDestino": localStorage.getItem("CodigoPerfil"),
                                "CodCadastro": 155,
                            });
                            var configStatus = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/Status',
                                headers: {
                                    'Authorization': wtoken,
                                    'Content-Type': 'application/json',
                                },
                                data: dataStatus
                            };
                            //console.log(config);
                            axios(configStatus)
                                .then(function (responseStatus) {
                                    setLoading(false);
                                    setTudoOk(true);
                                })
                                .catch(error => {
                                    console.error(error);
                                    setLoading(false);
                                    setTudoOk(false);
                                    //setShowModalErro(true);
                                });
                        })
                } else {
                    setLoading(false);
                    setTudoOk(false);
                    //setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                //setShowModalErro(true);
            });

    }



    return (

        <div className={Style.page}>
            <div>
                <button className={Style.voltar}>
                    <Link to={`/EditSolic/${CodigoSolicitacao}`} ><IoMdArrowBack /> Voltar</Link>
                </button>
            </div>

            <div className={Style.form_container}
                style={{
                    backgroundColor: '#ffffff',
                    borderTop: '5px solid #1B4F72',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    margin: 'auto 0',
                    marginBottom: '1em',
                    padding: '2em 5em',
                    width: '65vw',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                }}
            >
                <div id="CabecalhoHtml">
                    <h4 style={{ fontSize: '24px', color: '#222', textAlign: 'center' }}>Cadastrar Preços - itens da Solicitação - {CodigoSolicitacao}</h4>
                    <hr style={{ color: '#bbb' }}></hr>
                </div>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Form.Select className={Style.form_control} name="item" id="item" value={itemEscolhidoSelect} onChange={handleSelectItem}>
                                    <option value="0">Selecione um Item</option>
                                    {itens.length > 0 &&
                                        itens.map((opcao) => (
                                            <option key={opcao.CodigoSolicitacaoItem} value={opcao.CodigoSolicitacaoItem}>{opcao.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            {showgravado && (

                                <Alert variant="success">
                                    <p>Cadastro Realizado com sucesso!</p>
                                </Alert>
                                // <p style={{ color: 'green', padding: '.3em', backgroundColor: '#FDEDEC', textAlign: 'center', fontSize: '14px', fontWeight: '600' }}>Cadastro Realizado com sucesso</p>
                            )}
                        </Row>

                    </FormGroup>
                    {showFormCad &&
                        <>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Fornecedor:</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Select className={Style.form_control} name="credor" id="credor" value={fornecedorEscolhido} onChange={handleSelectFornecedor}>
                                            <option value="0">Selecione um Fornecedor</option>
                                            {fornecedores.length > 0 &&
                                                fornecedores.map((opcao) => (
                                                    <option key={opcao.CodigoCadastro} value={opcao.CodigoCadastro}>{opcao.Nome}</option>
                                                ))}
                                        </Form.Select>
                                        {showAviso && (<p style={{ color: 'red', padding: '.3em', backgroundColor: '#FDEDEC', textAlign: 'center', fontSize: '14px', fontWeight: '600' }}>
                                            Escolha um Fornecedor!</p>)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {showAvisoExisteCadastro && (<p style={{ color: 'red', padding: '.3em', backgroundColor: '#FDEDEC', textAlign: 'center', fontSize: '14px', fontWeight: '600' }}>
                                            Esse item já possui preço para o fornecedor escolhido!</p>)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Valor Unitário</Form.Label>
                                        <Form.Control className={Style.form_control} type="text" value={valorUnitario} onChange={handleValorUnitario} required />
                                    </Col>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Quantidade </Form.Label>
                                        <Form.Control className={Style.form_control} type="text" value={itemEscolhido.Quantidade} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Valor Total</Form.Label>
                                        <Form.Control className={Style.form_control} type="text" value={valorTotal} disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Condição de Pagamento:</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control className={Style.form_control} type="text" name="CondPagto" value={condPagto} onChange={(event) => setCondPagto(event.target.value)} required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Prazo Entrega:</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control className={Style.form_control} type="text" name="PrazoEntrega" value={prazoEntrega} onChange={(event) => setPrazoEntrega(event.target.value)} required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Validade Proposta ( dias ):</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control className={Style.form_control} type="text" name="validadeProposta" value={validadeProposta} onChange={(event) => setValidadeProposta(event.target.value)} required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Valor do Frete:</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Control className={Style.form_control} type="text" name="valFrete" value={valFrete} onChange={(event) => setValFrete(event.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Situação junto ao INSS quanto a CND:</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <input type="checkbox" name="CNDCheck" id="CNDCheck" data-nome="Regular" onChange={handleCheckCND} checked={CND} />
                                        <span style={{ color: '#626567', fontSize: '15px' }}> Regular</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Situação junto à CEF/FGTS Quanto ao CRF::</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <input type="checkbox" name="CNFCheck" id="CNFCheck" data-nome="Regular" onChange={handleCheckCNF} checked={CNF} />
                                        <span style={{ color: '#626567', fontSize: '15px' }}> Regular</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_group_label}>Situação junto à Justiça do Trabalho - CNDT:</Form.Label>
                                    </Col>
                                    <Col md={8}>
                                        <input type="checkbox" name="CNDTCheck" id="CNDTCheck" data-nome="Regular" onChange={handleCheckCNDT} checked={CNDT} />
                                        <span style={{ color: '#626567', fontSize: '15px' }}> Regular</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <button type='submit' className={Style.finalizar_btn}>Gravar</button>
                                </Row>
                            </FormGroup>
                        </>
                    }
                </Form>
            </div>

            <div className={Style.form_container}
                style={{
                    backgroundColor: '#ffffff',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    margin: 'auto 0',
                    padding: '2em',
                    width: '65vw',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                }}>
                <div style={{ textAlign: 'right' }}>
                    <button type='button' onClick={MontaQuadroComparativo} className={Style.btn_AnexarQuadro}>Anexar Quadro ao Processo <ImAttachment /></button>
                </div>

                <div id="ComparativoHTML">
                    <html><head></head><body>
                        <div>
                            <h4 style={{ fontSize: '24px', color: '#222', textAlign: 'center' }}>Quadro Comparativo de Preços</h4>
                            <hr style={{ color: '#bbb' }}></hr>
                        </div>
                        <div style={{ textAlign: 'center', fontSize: '16px', color: '#BA4A00', marginBottom: '1em' }}><strong>Cotação - {solicitacao.length !== 0 ? (solicitacao.Convenio.Nome) : (null)}</strong></div>

                        <div>

                            {(itens.map((ListItens, indexItens) => (
                                <div style={{ padding: '1em', border: '1px dashed #bbb', borderRadius: '5px', marginBottom: '2em', width: '100%' }}>
                                    <Table key={indexItens}>
                                        <tr>
                                            <td>
                                                <Table>
                                                    <tr style={{ borderBottom: '2px solid #1B4F72', borderTop: '0', borderRight: '0', borderLeft: '0', backgroundColor: '#EBF5FB' }}>
                                                        <th style={{ textAlign: 'center', color: '#1B4F72', padding: '.3em' }}><strong>Item</strong></th>
                                                        <th style={{ textAlign: 'center', color: '#1B4F72', padding: '.3em' }}><strong>Objeto</strong></th>
                                                        <th style={{ textAlign: 'center', color: '#1B4F72', padding: '.3em' }}><strong>Quantidade</strong></th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#2E86C1', padding: '.3em' }}>{ListItens.NumeroItem}</td>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#2E86C1', fontSize: '14px', padding: '.3em' }}>{ListItens.Nome}</td>
                                                        <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#2E86C1', padding: '.3em' }}>{ListItens.Quantidade}</td>
                                                    </tr>
                                                </Table>
                                            </td>
                                        </tr>
                                        <tr><td></td></tr>
                                        <tr>
                                            <td>
                                                {ListItens.ComparativoPreco.length > 0 ? (

                                                    <Table>
                                                        <tr style={{ borderBottom: '1px solid #333', borderTop: '0', borderRight: '0', borderLeft: '0', backgroundColor: '#EAECEE', fontSize: '14px', padding: '.5em' }}>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Fornecedor</strong></th>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Valor Unitário</strong></th>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Valor Total</strong></th>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Validade Proposta</strong></th>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Condição Pagamento</strong></th>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Prazo Entrega</strong></th>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Situação CND</strong></th>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Situação CRF</strong></th>
                                                            <th style={{ textAlign: 'center', color: '#333' }}><strong>Situação CNDT</strong></th>

                                                        </tr>
                                                        {(ListItens.ComparativoPreco.map((ListComparativo, indexComparativo) => (
                                                            <tr key={indexComparativo}>
                                                                <td style={{ textAlign: 'left', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.Cadastro.Nome}</td>
                                                                <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.ValorUnitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{(ListComparativo.ValorUnitario * ListItens.Quantidade).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.Validade}</td>
                                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.CondicaoPagamento}</td>
                                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.PrazoEntrega}</td>
                                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.SituacaoCND === true ? ("Regular") : ("Irregular")}</td>
                                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.SituacaoCNF === true ? ("Regular") : ("Irregular")}</td>
                                                                <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.8em' }}>{ListComparativo.SituacaoCNDT === true ? ("Regular") : ("Irregular")}</td>
                                                            </tr>
                                                        )))}
                                                        <tr>
                                                            <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', backgroundColor: '#EAECEE', fontSize: '14px', padding: '.8em' }}><strong>Valor Médio</strong></td>
                                                            <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', fontSize: '14px', padding: '.8em', fontWeight: '600' }}>
                                                                {" "}
                                                                {(
                                                                    ListItens.ComparativoPreco.reduce(
                                                                        (total, ListComparativo) => total + ListComparativo.ValorUnitario,
                                                                        0
                                                                    ) / ListItens.ComparativoPreco.length
                                                                ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                                            </td>
                                                            <td style={{ borderBottom: '1px solid #ccc' }} colSpan='8'></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ textAlign: 'center', borderBottom: '1px solid #ccc', backgroundColor: '#E9F7EF', fontSize: '14px', padding: '.8em' }}><strong>Menor Preço</strong></td>
                                                            <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', fontSize: '14px', padding: '.8em', fontWeight: '600', color: '#1E8449' }}>
                                                                {" "}
                                                                {ListItens.ComparativoPreco.length > 0
                                                                    ? ListItens.ComparativoPreco.reduce(
                                                                        (min, ListComparativo) =>
                                                                            ListComparativo.ValorUnitario < min
                                                                                ? ListComparativo.ValorUnitario
                                                                                : min,
                                                                        ListItens.ComparativoPreco[0].ValorUnitario
                                                                    ).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                                                                    : "N/A"}
                                                            </td>
                                                            <td style={{ textAlign: 'left', borderBottom: '1px solid #ccc', fontSize: '14px', padding: '.8em', color: '#1E8449' }} colSpan='8'>
                                                                <strong>
                                                                    {ListItens.ComparativoPreco.length > 0
                                                                        ? ListItens.ComparativoPreco.reduce((result, comparativo) => {
                                                                            if (comparativo.ValorUnitario <= result.min) {
                                                                                result.min = comparativo.ValorUnitario;
                                                                                result.nomeMenor = comparativo.Cadastro.Nome;
                                                                            }
                                                                            return result;
                                                                        }, { min: ListItens.ComparativoPreco[0].ValorUnitario, nomeMenor: "" }).nomeMenor
                                                                        : ""}
                                                                </strong>
                                                            </td>
                                                        </tr>

                                                    </Table>
                                                ) :
                                                    (<p style={{ fontSize: '14px', color: '#222' }}>Não há quadro comparativo de preço cadastrado para esse item.</p>)}
                                            </td>
                                        </tr>
                                    </Table>
                                </div>
                            )))}
                            <Table style={{ width: '100%' }}>
                                {/* <tr>
                            <td style={{ textAlign: 'center', borderBottom: '2px solid #ccc', padding: '.5em' }}><strong>Total do Orçamento por Fornecedor</strong></td>
                        </tr> */}
                                <tr>
                                    <th style={{ textAlign: 'left', borderBottom: '2px solid #ccc', padding: '.5em', fontWeight: '600' }}>Total do Orçamento por Fornecedor</th>
                                    <th style={{ textAlign: 'right', borderBottom: '2px solid #ccc', padding: '.5em', fontWeight: '600' }}>Valor dos Itens</th>
                                    <th style={{ textAlign: 'right', borderBottom: '2px solid #ccc', padding: '.5em', fontWeight: '600' }}>Frete</th>
                                    <th style={{ textAlign: 'right', borderBottom: '2px solid #ccc', padding: '.5em', fontWeight: '600' }}>Valor Total</th>
                                </tr>
                                {totalComparativo && Array.isArray(totalComparativo) && totalComparativo.map((TotalComparativo, indexTotalComparativo) => (
                                    <tr key={indexTotalComparativo}>
                                        <td style={{ textAlign: 'left', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.3em' }}>
                                            {TotalComparativo.Nome.split(',')[0].trim()}
                                        </td>
                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.3em' }}>{TotalComparativo.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.3em' }}>{TotalComparativo.Frete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td style={{ textAlign: 'right', borderBottom: '1px solid #ccc', color: '#626567', fontSize: '14px', padding: '.3em' }}>{(TotalComparativo.Frete + TotalComparativo.Total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                ))}
                            </Table>
                        </div>
                    </body></html>
                </div>

            </div >
            {/* <Table>
                        <tr>
                            <td style={{ textAlign: 'center', border: '1px solid #ccc' }}><strong>Total por Fornecedor (Menor Preço)</strong></td>
                        </tr>
                        <tr>
                            <th style={{ textAlign: 'center', border: '1px solid #ccc' }}>Fornecedor</th>
                            <th style={{ textAlign: 'center', border: '1px solid #ccc' }}>Valor Total</th>
                        </tr>
                        {totalComparativo && Array.isArray(totalComparativo) && totalComparativo.map((TotalComparativo, indexTotalComparativo) => (
                            <tr key={indexTotalComparativo}>
                                <td style={{ textAlign: 'left', border: '1px solid #ccc' }}>
                                    {TotalComparativo.Nome.split(',')[0].trim()}
                                </td>
                                <td style={{ textAlign: 'right', border: '1px solid #ccc' }}>{TotalComparativo.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            </tr>
                        ))}
                    </Table> */}

            <Modal show={loading} className={Style.Modal} centered >
                <Modal.Body className={Style.Modal_body}>
                    {tudoOk ? (<img style={{ width: '50px', margin: '0 auto' }} src={check_circle} alt="Tudo Certo!"></img>)
                        : (<img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>)}

                </Modal.Body>
            </Modal>

            <Modal show={tudoOk} onLoad={handleClose} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <img style={{ width: '100px', margin: '0 auto' }} src={check_circle} alt="Tudo Certo!"></img>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p><strong>Quadro Comparativo de Preço Anexado ao Processo!</strong></p>
                </Modal.Body>
            </Modal>

        </div >
    )



}
export default SolicComparativo 