import { useState } from "react";
import { useParams } from "react-router";
import React, { useEffect } from 'react';
import axios from "axios";
import urlAPIlocal from '../../global';
import Table from 'react-bootstrap/Table';
import moment from "moment";
import Cabecalho from "../img/Cabecalho.png";
import { Navigate } from "react-router-dom";


function RelatPagtos() {

    const { CodigoConvenio } = useParams();
    const [pagamentos, setPagamentos] = useState([]);
    const [totalPagamentos, setTotalPagamentos] = useState('');
    const [projeto, setProjeto] = useState([]);
    const [dataEmissao, setDataEmissao] = useState('');
    var wToken = 'Bearer ' + localStorage.getItem("token");

    const [login, setLogin] = useState(false);
    var wTokenLocal = localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     const intervalId = setInterval(() => {

    //     }, 1000);

    //     return () => clearInterval(intervalId);
    // }, []);

    const getProjeto = async (event) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + CodigoConvenio,
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjeto(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);

    const getPagamentos = async () => {
        //setLoading(true);

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Financeiro?CodigoConvenio=' + CodigoConvenio + '&Tipo=false&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setPagamentos(data.objetoRetorno);
                //setLoading(false);
                //console.log(data.objetoRetorno);

                //calcula total de pagamentos
                if (data.objetoRetorno !== null && Array.isArray(data.objetoRetorno) && data.objetoRetorno.length > 0) {
                    // Assume que ValorPago é uma propriedade válida em cada item do array
                    const totalPagamentos = data.objetoRetorno.reduce((acc, pagamento) => acc + pagamento.ValorPago, 0);
                    setTotalPagamentos(totalPagamentos);
                } else {
                    //console.log('Nenhum objeto de retorno válido encontrado');
                }

                const date = new Date();
                setDataEmissao(date);

            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                // if (error.response.status === 401) {
                //     setLogin(true);
                // }
            });
    }

    useEffect(() => {
        getPagamentos();
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{ minHeight: '120vh', backgroundColor: '#F8F9F9' }}>

            <div style={{ minHeight: '120vh', backgroundColor: '#fff', width: '900px', margin: '0 auto', padding: '0.5em', display: 'flex', flexDirection: "column" }}>

                <div style={{ textAlign: 'center', marginBottom: '2em' }}>
                    <img src={Cabecalho} style={{ width: '350px', display: 'inline-block', marginBottom: '1em' }} alt="Cabeçalho" />
                    <h5 style={{ color: '#273746', margin: '1em', fontSize: '18px' }}>Relatório de Pagamentos do Projeto</h5>
                </div>

                <p style={{ color: '#566573', paddingLeft: '1.5em', fontWeight: '600', fontSize: '16px' }}>Projeto nº {projeto.NumeroConvenio} - {projeto.Nome}</p>

                <Table striped bordered style={{ marginBottom: '.3em', borderCollapse: 'collapse', width: '95%', margin: '0 auto' }}>
                    <thead style={{ borderBottom: '2px solid #154360', borderTop: '1px solid #fff', borderRight: '1px solid #fff', borderLeft: '1px solid #fff', fontWeight: '600' }}>
                        <tr style={{ color: '#2C3E50', fontSize: '14px' }}>
                            <th >Solicitação</th>
                            <th style={{ textAlign: 'center' }}>Meta</th>
                            <th style={{ textAlign: 'center' }}>Fase</th>
                            <th >Beneficiário</th>
                            <th >Elemento</th>
                            <th style={{ textAlign: 'center' }}>Ordem Bancária</th>
                            <th style={{ textAlign: 'center' }}>N° Documento</th>
                            <th style={{ textAlign: 'center' }}>Data</th>
                            <th style={{ textAlign: 'right' }}>Valor</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#FFFFFF', color: '#2C3E50', fontSize: '12px' }}>

                        {pagamentos !== null && (
                            pagamentos.map((pagto) => (
                                <tr key={pagto.CodigoFinanceiro}>
                                    {pagto.CodigoSolicitacao !== 0 ? (
                                        <td>{pagto.CodigoSolicitacao}</td>
                                    ) : (
                                        <td>Sem Solicitação</td>
                                    )}
                                    <td align="center">{pagto.Fase.Meta.NumMeta}</td>
                                    <td align="center">{pagto.Fase.NumeroFase}</td>
                                    <td>{pagto.Cadastro.Nome}</td>
                                    <td>{pagto.ConvenioElemento.Elemento.CodigoElemento} - {pagto.ConvenioElemento.Elemento.Nome}</td>
                                    <td align="center">{pagto.NumeroDoc}</td>
                                    <td align="center">{pagto.NumeroNotaFiscal}</td>
                                    <td align="center">{moment(pagto.DataPagamento).format("DD/MM/yyyy")}</td>
                                    <td align="right">{pagto.ValorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #154360', borderTop: '1px solid #154360', color: '#fff', fontSize: '14px', width: '95%', margin: '0 auto', backgroundColor: '#566573' }}>
                    <p style={{ fontWeight: '600', margin: '0.3em' }}>TOTAL DOS PAGAMENTOS:</p>
                    <p style={{ fontWeight: '600', margin: '0.3em' }}>{totalPagamentos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                </div>

                <div style={{ margin: 'auto 0 0' }}>
                    <p style={{ color: '#566573', paddingLeft: '1.5em', fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Data de Emissão do Relatório: {moment(dataEmissao).format("DD/MM/yyyy")}</p>
                </div>
            </div>
            {login && (<Navigate to="/" replace={true} />)}
        </div>
    )
}
export default RelatPagtos