import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import axios from "axios";
import urlAPIlocal from '../../global';
import Table from 'react-bootstrap/Table';
import moment from "moment";
import Cabecalho from "../img/Cabecalho.png";
import { Navigate } from "react-router-dom";


// import { L10n, setCulture } from '@syncfusion/ej2-base';
// import { ColumnsDirective, ColumnDirective, GridComponent, Inject, Page, Filter, Toolbar, PdfExport, Sort } from '@syncfusion/ej2-react-grids';
// import '@syncfusion/ej2-base/styles/material.css'; // Importar o estilo de tema
// import dados from './Relatorios.json';
// import './Relatorios.css';

// Definir o idioma padrão para português (pt-BR)
// setCulture('pt-BR');

// L10n.load({
//     'pt-BR': {
//         Traduções personalizadas, se necessário
//         'pager': {
//             'currentPageInfo': '',
//             'totalItemsInfo': '{1} a {2} de {0}',
//         },
//         ... adicione mais traduções aqui
//     }
// });

function RelatorioRepasses() {

    const { CodigoConvenio } = useParams();
    const [repassesConcedentes, setRepassesConcedentes] = useState([]);
    const [totalRepasses, setTotalRepasses] = useState('');
    const [projeto, setProjeto] = useState([]);
    const [dataEmissao, setDataEmissao] = useState('');
    var wToken = 'Bearer ' + localStorage.getItem("token");
    //const [headerValue, setHeaderValue] = useState('');

    const [login, setLogin] = useState(false);
    var wTokenLocal = localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    const getProjeto = async (event) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + CodigoConvenio,
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjeto(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);

    const getRepasses = async () => {

        try {
            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Banco?CodigoConvenio=" + CodigoConvenio + "&IsInclude=true",
                headers: {
                    Authorization: wToken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config)
            const response = await axios(config);


            const data = response.data.objetoRetorno;
            //console.log(data);

            const filteredData = data.filter(item => item.CodigoCadastro !== 92 && item.CodigoCadastro !== 93);
            setRepassesConcedentes(filteredData);

            //calcula total de repasses
            if (filteredData !== null && Array.isArray(filteredData) && filteredData.length > 0) {
                // Assume que Valor é uma propriedade válida em cada item do array
                const totalRepasses = filteredData.reduce((acc, credito) => acc + credito.Valor, 0);
                setTotalRepasses(totalRepasses);
            } else {
                //console.log('Nenhum objeto de retorno válido encontrado');
            }

            const date = new Date();
            setDataEmissao(date);


        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getRepasses();
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     Filtrar os dados do JSON onde CodigoCadastro seja igual a 92
    //     const filteredData = dados.filter(item => item.CodigoCadastro !== 92 && item.CodigoCadastro !== 93);
    //     const formattedData = filteredData.map(item => ({
    //         ...item,
    //         ValorFormatado: item.Valor ? item.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : null
    //     }));
    //     setData(formattedData);

    //     setHeaderValue(
    //         <>
    //             <h4 style={{ textAlign: 'center', fontSize: '20px' }}>RELAÇÃO TOTAL DE CRÉDITOS RECEBIDOS - Repasses de Concedentes</h4>
    //             <br></br>
    //             <p><b>Projeto:</b> Projeto Teste</p>
    //             <p><b>Convenente:</b> Convenente Teste</p>
    //             <p><b>Período de Execução do Projeto:</b> 01/01/1900</p>
    //             <p><b>Período dessa Prestação de Contas:</b> 01/01/1900</p>
    //         </>
    //     );
    // }, []);

    // const gridRef = React.createRef();

    // const DataHojeFormatada = currentDate.toLocaleDateString().replace(/\//g, '-'); // Formatar a data para remover as barras

    // const toolbarClick = (event) => {
    //     if (gridRef.current) {
    //         if (event.item.id.includes('pdfexport')) {
    //             gridRef.current.pdfExport({
    //                 fileName: `relatorioRepasses_${DataHojeFormatada}.pdf`,
    //                 exportType: 'CurrentPage',
    //                 theme: {
    //                     header: {
    //                         bold: true,
    //                         fontColor: '#000000',
    //                         fontName: 'Calibri',
    //                         fontSize: 10
    //                     },
    //                     record: {
    //                         fontColor: '#6A6A6A',
    //                         fontName: 'Calibri',
    //                         fontSize: 8
    //                     }
    //                 },
    //                 header: {
    //                     fromTop: 0,
    //                     height: 130,
    //                     contents: [{
    //                         type: 'Text',
    //                         value: headerValue,
    //                         position: { x: 0, y: 50 },
    //                         style: { textBrushColor: '#000000', fontSize: 13 }
    //                     }]
    //                 },
    //                 footer: {
    //                     contents: [{
    //                         type: 'Text',
    //                         value: 'Thank you for your Business',
    //                         position: { x: 0, y: 50 },
    //                         style: { textBrushColor: '#000000', fontSize: 13 }
    //                     }],
    //                     fromBottom: 130,
    //                     height: 130
    //                 }
    //             });
    //         }
    //     }
    // };

    return (

        <div style={{ minHeight: '120vh', backgroundColor: '#F8F9F9' }}>

            <div style={{ minHeight: '120vh', backgroundColor: '#fff', width: '900px', margin: '0 auto', padding: '0.5em', display: 'flex', flexDirection: "column" }}>

                <div style={{ textAlign: 'center', marginBottom: '2em' }}>
                    <img src={Cabecalho} style={{ width: '350px', display: 'inline-block', marginBottom: '1em' }} alt="Cabeçalho" />
                    <h5 style={{ color: '#273746', margin: '1em', fontSize: '18px' }}>Relatório de Repasses do Projeto</h5>
                </div>

                <p style={{ color: '#566573', paddingLeft: '1.5em', fontWeight: '600', fontSize: '16px' }}>Projeto nº {projeto.NumeroConvenio} - {projeto.Nome}</p>

                <Table striped bordered style={{ marginBottom: '.3em', borderCollapse: 'collapse', width: '95%', margin: '0 auto' }}>
                    <thead style={{ borderBottom: '2px solid #154360', borderTop: '1px solid #fff', borderRight: '1px solid #fff', borderLeft: '1px solid #fff', fontWeight: '600' }}>
                        <tr style={{ color: '#2C3E50', fontSize: '14px' }}>
                            <th style={{ width: '300px' }}>Fonte do Recurso</th>
                            <th style={{ textAlign: 'center' }}>Data</th>
                            <th >Observação</th>
                            <th style={{ textAlign: 'right' }}>Valor</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#FFFFFF', color: '#2C3E50', fontSize: '12px' }}>

                        {repassesConcedentes !== null && (
                            repassesConcedentes.map((credito) => (
                                <tr key={credito.CodigoBanco}>
                                    <td align="center">{credito.Cadastro.Nome}</td>
                                    <td align="center">{moment(credito.DataBanco).format("DD/MM/yyyy")}</td>
                                    <td>{credito.Obs}</td>
                                    <td align="right">{credito.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </Table>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #154360', borderTop: '1px solid #154360', color: '#fff', fontSize: '14px', width: '95%', margin: '0 auto', backgroundColor: '#566573' }}>
                    <p style={{ fontWeight: '600', margin: '0.3em' }}>TOTAL DE REPASSES:</p>
                    <p style={{ fontWeight: '600', margin: '0.3em' }}>{totalRepasses.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                </div>

                <div style={{ margin: 'auto 0 0' }}>
                    <p style={{ color: '#566573', paddingLeft: '1.5em', fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Data de Emissão do Relatório: {moment(dataEmissao).format("DD/MM/yyyy")}</p>
                </div>
            </div>
            {login && (<Navigate to="/" replace={true} />)}
        </div>

    );
}

export default RelatorioRepasses;