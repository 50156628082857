import React from "react"
import Form from 'react-bootstrap/Form'
import StyleForm from './/Creditos.module.css'
import { AiFillPlusCircle, AiOutlineEdit } from 'react-icons/ai'
import { TiDelete } from "react-icons/ti";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Menu from "../Layout/Menu";
import { Col, FormGroup, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io"
import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import Row from 'react-bootstrap/Row';
import moment from "moment";
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
//import moment from "moment"


function OutrosVal() {

    const { wCodigoConvenioNovo } = useParams();
    const [login, setLogin] = useState(false);
    //const [Creditos, setCreditos] = useState([]);
    const [OutrosValGravados, setOutrosValGravados] = useState([]);
    var wTokenLocal = localStorage.getItem("token");
    const [tipoOutrosVal, setTipoOutrosVal] = useState([]);
    const [tipoOutrosValEscolhido, setTipoOutrosValEscolhido] = useState('');
    const [dataOutrosVal, setDataOutrosVal] = useState('');
    const [valorOutrosVal, setValorOutrosVal] = useState(0);
    const [obs, setObs] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editado, setEditado] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [exibirAviso, setExibirAviso] = useState(false);
    const [codOutrosValexcluir, setCodOutrosValExcluir] = useState(0);
    const [valorOutrosValexcluir, setValorOutrosValExcluir] = useState('');
    const [obsOutrosValexcluir, setObsOutrosValExcluir] = useState('');


    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function handleClose() {
        setTimeout(() => {
            setIsLoading(false);
            //setCadastroOk(true);

        }, 1000);

    }

    function handleCloseEdit() {
        setTimeout(() => {
            setShowModalEdit(false);
            //setCadastroOk(true);

        }, 1000);

    }

    const getTipoOutrosVal = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/TipoOutrosVal?IsInclude=false",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data);
            setTipoOutrosVal(data);
            //console.log(tipoOutrosVal);
            //remove o item codTipoOutrosVal = 2 da lista
            const tipoOutrosValCopy = [...data];
            //console.log(tipoOutrosValCopy)
            const index = 1 //tipoOutrosValCopy.findIndex(item => item.CodigoTipoOutrosValor !== 2);
            //console.log(index);
            if (index !== -1) {
                tipoOutrosValCopy.splice(index, 1);
              }
              setTipoOutrosVal(tipoOutrosValCopy);



        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getTipoOutrosVal();
        // eslint-disable-next-line
    }, []);

    const getOutrosValGravados = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/OutrosVal?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setOutrosValGravados(data);
            //console.log(data);

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getOutrosValGravados();
        // eslint-disable-next-line
    }, []);

    const handleSelectTipoOutrosVal = (event) => {
        setTipoOutrosValEscolhido(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsButtonDisabled(true);
        setIsLoading(true);

        var vTotalOutrosVal = valorOutrosVal;
        vTotalOutrosVal = vTotalOutrosVal.replace(',', '.');

        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var data = JSON.stringify({
            "CodigoOutrosValores": 0,
            "CodigoTipoOutrosValor": tipoOutrosValEscolhido,
            "DataOutrosValores": dataOutrosVal, //"2023-05-17T02:17:20.117Z",
            "CodigoConvenio": wCodigoConvenioNovo,
            "valor": vTotalOutrosVal,
            "Observacao": obs
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/OutrosVal',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro
                    getOutrosValGravados();
                    setObs('');
                    setValorOutrosVal(0);
                    setDataOutrosVal('');
                    //setOutrosValEscolhido(0);
                    setIsLoading(true);

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    //setCadastroOk(false);
                }
            })
            .catch(error => {
                console.error(error);
            });

        setIsButtonDisabled(false);

    }

    const handleOutrosValClick = (wOutrosVal, wValorOutrosVal, wObs) => {
        setValorOutrosValExcluir(wValorOutrosVal)
        setObsOutrosValExcluir(wObs)
        setCodOutrosValExcluir(wOutrosVal)
        setExibirAviso(true);
    };

    const handleConfirmarExclusao = () => {
        // Lógica para excluir o registro
        ExcluirOutrosVal(codOutrosValexcluir)
        setExibirAviso(false);
    };

    const handleCancelarExclusao = () => {
        setExibirAviso(false);
    };


    const ExcluirOutrosVal = (wOutrosVal) => {
        var configSelect = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/OutrosVal/' + wOutrosVal,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataSelect
        };
        //console.log(dataSelect)
        axios(configSelect)

            .then(function (response) {
                getOutrosValGravados();

                //setCadastroOk(true);
                //console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });

    }

    return (
        <div className={StyleForm.page}>
            <Menu />

            {/* <div> <button className={StyleForm.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={StyleForm.voltar}>  <Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            <div className={StyleForm.header}>
                <h1>Cadastro de Outros Valores</h1>
            </div>

            <div className={StyleForm.container}>

                <Form className={StyleForm.select} onSubmit={handleSubmit} >

                    <FormGroup className={StyleForm.form_group}>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Selecione uma opção</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="TipoOutrosVal" id="TipoOutrosVal" value={tipoOutrosValEscolhido} onChange={handleSelectTipoOutrosVal}>
                                    <option>Selecione uma opção</option>
                                    {tipoOutrosVal.length > 0 &&
                                        tipoOutrosVal.map((opcao) => (
                                            <option key={opcao.CodigoTipoOutrosValor} value={opcao.CodigoTipoOutrosValor}>{opcao.Nome + " - " + (opcao.Debito ? 'Débito' : 'Crédito')}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" value={dataOutrosVal} onChange={(event) => setDataOutrosVal(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="valorOutrosVal" value={valorOutrosVal} onChange={(event) => setValorOutrosVal(event.target.value)} required />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Observação</Form.Label>
                                <Form.Control className={StyleForm.form_control} name="obs" id="obs" value={obs} onChange={(event) => setObs(event.target.value)}></Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            {!editado && (
                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleSubmit}>
                                    Inserir Valor <AiFillPlusCircle />
                                </button>
                            )}
                        </Row>
                    </FormGroup>
                </Form>

                <div>
                    <div className={StyleForm.header}>
                        <p>Valores Lançados</p>
                    </div>
                    <Table hover className={StyleForm.table}>
                        <thead>

                            <tr>
                                <th>Nome</th>
                                <th>Data</th>
                                <th>Valor</th>
                                <th>Observação</th>
                                <th className={StyleForm.th_btn}></th>
                                {/* <th className={StyleForm.th_btn}></th> */}
                            </tr>
                        </thead>

                        <tbody>

                            {OutrosValGravados &&

                                (OutrosValGravados.length > 0 ?
                                    (OutrosValGravados.map((OutrosVal) => (
                                        <tr key={OutrosVal.CodigoOutrosValores}>
                                            <td>{OutrosVal.TipoOutrosValor.Nome}</td>
                                            <td>{moment(OutrosVal.DataOutrosValores).format("DD/MM/yyyy")}</td>
                                            <td>{OutrosVal.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td>{OutrosVal.Observacao}</td>
                                            <td align="right"><button type="button" className={StyleForm.btn_delete} onClick={(event) => handleOutrosValClick(OutrosVal.CodigoOutrosValores, OutrosVal.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), OutrosVal.Observacao)}>
                                                <TiDelete /></button></td>
                                            {/* <td align="center"><button type="button" className={StyleForm.btn_edit} onClick={(event) => handleEditForm(Creditos.CodigoBanco)}>
                                            <AiOutlineEdit title="Editar" /> </button></td> */}
                                        </tr>
                                    ))
                                    ) : (<tr><td>Nenhum valor lançado</td></tr>))}



                        </tbody>
                    </Table>
                    <div align="center">

                    {exibirAviso && (
                            <div className={StyleForm.aviso_delete}>
                                <h5>Deseja realmente excluir o registro?</h5>
                                <p>Valor - <strong>{valorOutrosValexcluir}</strong> | Observação - <strong>{obsOutrosValexcluir}</strong></p>
                                <button className={StyleForm.confirmar_btn} onClick={handleConfirmarExclusao}>Confirmar</button>
                                <button className={StyleForm.cancelar_btn} onClick={handleCancelarExclusao}>Cancelar</button>
                            </div>
                        )}
                    </div>
                </div>

                {/* <button className={StyleForm.edit}>
                    <Link to={`/Metas/${wCodigoConvenioNovo}`}>
                        Cadastrar Metas <IoMdArrowForward/>
                    </Link>
                </button> */}

                <Modal show={isLoading} onLoad={handleClose} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <img src={check_circle} alt="ok"></img>
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <h3>Tudo certo!</h3>
                        <p>Valor inserido com sucesso</p>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalEdit} onLoad={handleCloseEdit} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <img src={check_circle} alt="ok"></img>
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        Valor atualizados com sucesso!
                    </Modal.Body>
                </Modal>


            </div>
            {login && (<Navigate to="/" replace={true} />)}

        </div>
    )


}
export default OutrosVal