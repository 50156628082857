
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { Navigate } from "react-router";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//import Styles from "./PrintSolic.module.css";
import urlAPIlocal from '../../global';
import { Box } from '@mui/material';
import moment from "moment";
import React from 'react';
//import { PDFDocument } from 'pdf-lib';
//import { Document, Page } from '@react-pdf/renderer';

import { Modal } from 'react-bootstrap';
import loadingPdfGif from "../img/LoadingPdfGif.gif";

//import html2pdf from 'html2pdf.js';
//import { saveAs } from 'file-saver';

//var wCodCredor = 0
/* function GravaArquivo(wCodSolic, wArquivo, wNomeArquivo) {

    var datFiles = JSON.stringify({
        "CodigoArquivo": 0,
        "CodigoSolicitacao": wCodSolic,
        "descricao": '__Capa__', //DescricaoArq,
        "arquivo": wNomeArquivo, //CodCadastro + "_" + ArqCadastro.name,
        "status": 0
    })
    var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlAPIlocal + '/api/ArquivoProcesso',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        data: datFiles
    };
    //console.log(datFiles)
    axios(config)
        .then(function (response) {

            //console.log(response.data)
            if (response.data.isOk === true) {
                //faz upload do arquivo
                const file = wArquivo;
                const novoNome = wNomeArquivo;
                const novoArquivo = new File([file], novoNome, { type: file.type });
                const formData = new FormData();
                formData.append('pdf', novoArquivo, novoNome);

                var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/UploadArquivo',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-dataa',
                        'pasta': 'ArquivosProcesso'
                    },
                    data: formData
                };

                axios(config)

                    .then(response => {
                        //console.log(response.data);
                        // faça algo com a resposta da API
                        //Retorno = false;
                    })
                    .catch(error => {
                        console.error(error);
                        //Retorno = true;

                    });
            } else {
                //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                //Retorno = true

            }

        })
        .catch(function (error) {
            console.log(error);
            //Retorno = true
        }); 
    //return Retorno;
}*/


function PrintSolic() {

    const { CodigoSolicitacao } = useParams();
    const [solicitacao, setSolicitacao] = useState([]);
    const [login, setLogin] = useState(false);
    const [item, setItem] = useState([]);
    const [cadastro, setCadastro] = useState([]);
    const [status, setStatus] = useState([]);
    const [projeto, setProjeto] = useState([]);
    const [codTipoProjeto, setCodTipoProjeto] = useState(0);
    const [tipoProjeto, setTipoProjeto] = useState('');
    const [fase, setFase] = useState([]);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [semProcesso, setSemProcesso] = useState(true);
    const [mensagem, setMensagem] = useState('');

    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosProcesso/"
    const [returnFinal, setReturnFinal] = useState(false);

    const [arquivosSolic, setArquivosSolic] = useState([]);


    var wTokenLocal = localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    const getProcesso = async (event) => {

        setLoadingPDF(true);

        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/UploadArquivo/DownloadProcesso?id=' + CodigoSolicitacao,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const data = response.data;
                //let wpdfstring = data;
                console.log(data);
                if (typeof data === 'string' && data.includes('%PDF-1.4')) {
                    // Se há dados na resposta e isOk é true, trata-se de um PDF ou outra informação
                    setReturnFinal(true);
                    setLoadingPDF(false);
                    setSemProcesso(false);
                    console.log(data);
                } else if (data && data.isOk === false && data.mensagem) {
                    // Se há dados na resposta, mas isOk é false, trata-se de um erro conhecido
                    setReturnFinal(false);
                    setLoadingPDF(false);
                    setSemProcesso(true);
                    setMensagem(data.mensagem);
                } else {
                    // Se não há dados na resposta, considera-se que o processo não foi encontrado
                    setReturnFinal(false);
                    setLoadingPDF(false);
                    setSemProcesso(true);
                    setMensagem('Desculpe, mas o Processo Interno não foi encontrado.');
                }
            })
            .catch(function (error) {
                console.log(error);

                // Tratar erro de rede ou outros erros não específicos
                //setReturnFinal(false);
                setLoadingPDF(false);
                //setSemProcesso(true);
                //setMensagem('Desculpe, ocorreu um erro inesperado.');
            });

    }

    useEffect(() => {
        getProcesso();
        // eslint-disable-next-line
    }, []);


    /* function PegaProjeto(CodigoConvenio) {

        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Convenio?CodigoConvenio=' + CodigoConvenio,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;

                setProjeto(data.objetoRetorno);


            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });

    } */

    //O primeiro useEffect monitora a alteração em projeto e, quando projeto e projeto[0] existem, atualiza o valor de codTipoProjeto. 
    //Dessa forma, codTipoProjeto será atualizado corretamente antes de chamar PegaTipoProjeto().
    /* useEffect(() => {
        if (projeto && projeto[0]) {
            setCodTipoProjeto(projeto[0].Tipoconvenio);
        }
    }, [projeto]); */

    //O segundo useEffect monitora a alteração em codTipoProjeto. Quando codTipoProjeto tiver um valor válido, a função PegaTipoProjeto() será chamada.
    /*  useEffect(() => {
         if (codTipoProjeto) {
             PegaTipoProjeto();
         }
     }, [codTipoProjeto]);
     function PegaTipoProjeto() {
 
         const wtoken = "Bearer " + wTokenLocal;
 
         var configStatus = {
             method: 'GET',
             maxBodyLength: Infinity,
             url: urlAPIlocal + '/api/TipoProjeto?Id=' + codTipoProjeto,
             headers: {
                 'Authorization': wtoken,
                 'Content-Type': 'application/json'
             },
 
         };
 
         axios(configStatus)
 
             .then(function (responseStatus) {
 
                 const data = responseStatus.data;
                 setTipoProjeto(data.objetoRetorno);
                 //console.log(data.objetoRetorno);
 
             }
             )
             .catch(function (error) {
                 console.log(error);
                 //console.log(JSON.stringify(response.data.mensagemRetorno));
             });
     } */


    /* function PegaItem(CodSolic) {
        //requisição SOLICITAÇÃO-ITEM


        //const getItem = async() => {

        const wtoken = "Bearer " + wTokenLocal;

        var configItem = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem?CodigoSolicitacao=' + CodSolic,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(configItem)

            .then(function (responseItem) {

                const data = responseItem.data;
                setItem(data.objetoRetorno);
                //console.log(data.objetoRetorno)

            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });


       


    } 

    // function PegaCadastro(CodCadastro) {
    //     //Requisição CADASTRO



    //     //const getCadastro = async() => {

    //     const wtoken = "Bearer " + wTokenLocal;

    //     var configCadastro = {
    //         method: 'GET',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/Cadastro/' + CodCadastro,
    //         headers: {
    //             'Authorization': wtoken,
    //             'Content-Type': 'application/json'
    //         },

    //     };

    //     axios(configCadastro)

    //         .then(function (responseCadastro) {

    //             const data = responseCadastro.data;
    //             setCadastro(data.objetoRetorno);
    //             //console.log(data.objetoRetorno);

    //         }
    //         )
    //         .catch(function (error) {
    //             console.log(error);
    //             //console.log(JSON.stringify(response.data.mensagemRetorno));
    //         });
    // }

    }*/

    /* function PegaStatus(CodStatus) {
        //Requisição STATUS

        const wtoken = "Bearer " + wTokenLocal;

        var configStatus = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(configStatus)

            .then(function (responseStatus) {

                const data = responseStatus.data;
                setStatus(data.objetoRetorno);
                setLoadingPDF(true);
                setTimeout(() => {
                    ConvertePdf();
                }, 2000);

            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });



        //    useEffect (() => {
        //         getCadastro();
        //     }, []); 
    }
 */


    //Requisição SOLICITAÇÃO
    /*  const getSolicitacao = () => {
         return new Promise((resolve, reject) => {
             const wtoken = "Bearer " + wTokenLocal;
             const configSolic = {
                 method: 'GET',
                 maxBodyLength: Infinity,
                 url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
                 headers: {
                     'Authorization': wtoken,
                     'Content-Type': 'application/json'
                 },
             };
 
             axios(configSolic)
                 .then(function (responseSolic) {
                     const data = responseSolic.data;
                     //console.log(data.isOk);
                     if (data.isOk === true) {
                         setSolicitacao(data.objetoRetorno);
                         console.log(data.objetoRetorno);
                         // Chame suas funções de carregamento aqui, sem usar 'await'
                         PegaProjeto(data.objetoRetorno[0].CodigoConvenio);
                         PegaItem(CodigoSolicitacao);
                         PegaMetaFase(data.objetoRetorno[0].CodigoFase);
                         PegaStatus(CodigoSolicitacao);
                         PegaTipoProjeto();
 
                         // Resolve a promise com os dados carregados
                         resolve(data.objetoRetorno);
                         setSemProcesso(true);
                     } else {
 
                         setSemProcesso(false);
                         setMensagem('Desculpe, mas a Solicitação não foi encontrada.');
                     }
 
                 })
                 .catch(function (error) {
                     console.log(error);
                     if (error.response.status === 401) {
                         setLogin(true);
                     }
                     // Rejeita a promise em caso de erro
                     reject(error);
                 });
         })
     };
     useEffect(() => {
         getSolicitacao();
     }, []);
  */
    /* function ConvertePdf() {

        var wNomeArquivoNovo = 'Capa_' + CodigoSolicitacao + '.pdf'
        //console.log('div sem codificação :' + document.getElementById('ProcessoPdf'));
        var wDivHtml = document.getElementById('SolicitacaoPdf');
        wDivHtml = wDivHtml.innerHTML;
        //console.log('div puro :' + wDivHtml);
        const escapedHtml = wDivHtml.replace(/(\r\n|\n|\r)/g, '').replace(/"/g, "'");
        //console.log('div final :' + wDivHtml);
        //console.log('div com inner :' + wDivHtml);
        //const encodedHTML = encodeURIComponent(wDivHtml);
        //console.log('div com uri :' + encodedHTML);
        const wtoken = "Bearer " + wTokenLocal;
        var dataConverter = JSON.stringify({
            "pasta": 'ArquivosProcesso',
            "arquivo": wNomeArquivoNovo,
            "html": escapedHtml,
            "template": ''
        });
        //console.log(escapedHtml);
        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ApiPdf/ConverterHtmlParaPdf',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
            data: dataConverter
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const data = response.data;
                //setProcesso(data.objetoRetorno);
                console.log(data);
                //pega capa + arquivos e exibe o pdf juntado
                const wtoken = "Bearer " + wTokenLocal;
                var configProcesso = {
                    method: 'GET',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/ArquivoProcesso?CodigoSolicitacao=' + CodigoSolicitacao,
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json'
                    },

                };
                //console.log(configSolic);
                axios(configProcesso)
                    .then(function (responseProcesso) {
                        const data = responseProcesso.data;
                        console.log(data);
                        setArquivosSolic(data.objetoRetorno);

                        if (data.objetoRetorno !== null) {
                            var wArquivos = data.objetoRetorno.map((arquivo) => arquivo.Arquivo).join(';');
                            console.log(wArquivos);
                            const wtoken = "Bearer " + wTokenLocal;
                            var configArquivoJuntado = {
                                method: 'GET',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/uploadArquivo/downloadArquivosAjuntados/Capa_' + CodigoSolicitacao + '.pdf;' + wArquivos,
                                headers: {
                                    'Authorization': wtoken,
                                    'Content-Type': 'application/json',
                                    'pasta1': 'ArquivosProcessoInterno',
                                    'pasta2': 'ArquivosProcesso',
                                    'novoArquivo': 'printSolic' + CodigoSolicitacao + '.pdf'
                                },

                            };
                            console.log(configArquivoJuntado);
                            axios(configArquivoJuntado)

                                .then(function (responseArquivoJuntado) {
                                    console.log(responseArquivoJuntado);
                                    const data = responseArquivoJuntado.data;
                                    setReturnFinal(true);
                                    setLoadingPDF(false);
                                    //setItem(data.objetoRetorno);
                                    console.log(data.objetoRetorno)
                                }
                                )
                                .catch(function (error) {
                                    console.log(error);
                                    setReturnFinal(false);
                                    //console.log(JSON.stringify(response.data.mensagemRetorno));
                                });
                        } else {
                            const wtoken = "Bearer " + wTokenLocal;
                            var configArquivoJuntado = {
                                method: 'GET',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/uploadArquivo/downloadArquivosAjuntados/Capa_' + CodigoSolicitacao + '.pdf;',
                                headers: {
                                    'Authorization': wtoken,
                                    'Content-Type': 'application/json',
                                    'pasta1': 'ArquivosProcessoInterno',
                                    'pasta2': 'ArquivosProcesso',
                                    'novoArquivo': 'printSolic' + CodigoSolicitacao + '.pdf'
                                },

                            };
                            //console.log(configArquivoJuntado);
                            axios(configArquivoJuntado)

                                .then(function (responseArquivoJuntado) {

                                    const data = responseArquivoJuntado.data;
                                    setReturnFinal(true);
                                    setLoadingPDF(false);
                                    //setItem(data.objetoRetorno);
                                    console.log(data)
                                }
                                )
                                .catch(function (error) {
                                    console.log(error);
                                    setReturnFinal(false);
                                    //console.log(JSON.stringify(response.data.mensagemRetorno));
                                });
                        }
                    }
                    )
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            )
            .catch(function (error) {
                console.log(error);
                

            }); 
    } */

    return (
        // <div aling='center'>{returnFinal ? (
        <>
            {semProcesso &&
                <div align='center'>{mensagem}</div>
            } : {<div>
                {returnFinal && (
                    <div style={{ width: '850px', height: '100vh', align: 'center', width: '100%' }}>
                        <embed src={LinkArquivo + 'Solicitacao-' + CodigoSolicitacao + '.pdf'} type="application/pdf" width="100%" height="100%" />
                    </div>
                )}


                {login && (<Navigate to="/" replace={true} />)}

            </div>}
            <Modal show={loadingPDF} style={{ display: 'flex', justifyContent: 'center' }} centered >
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '0 auto', color: '#34495E' }}>
                    <img style={{ width: '50px', margin: '0 auto', marginBottom: '.5em' }} src={loadingPdfGif} alt="Carregando PDF"></img>
                    <h5>Carregando Processo de Solicitação</h5>
                </Modal.Body>
            </Modal>

            {login && (<Navigate to="/" replace={true} />)}


        </>
    )


};


export default PrintSolic;