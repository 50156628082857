import axios from 'axios';
import Styles from './Login.module.css'
import logobola from '../img/logobola.png'
import React, { useState } from "react";
import styles from '../Layout/LoginBtn.module.css'
import { FaArrowRight } from "react-icons/fa";
import { Navigate } from "react-router-dom";
import urlAPIlocal from '../../global';
import loadingGif from '../img/icons8-spinning-circle.gif';
import { Modal } from 'react-bootstrap';


//var wPerfil = ''

function LoginForm() {

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedInArea, setLoggedInArea] = useState(false);
  const [loggedInEscolhaProjeto, setLoggedInEscolhaProjeto] = useState(false);

  // const xsrfToken = document.cookie
  //   .split('; ')
  //   .find(cookie => cookie.startsWith('XSRF-TOKEN='))
  //   .split('=')[1];

  //const history = Routes();


  function BuscaCadastro(wCodCadastro) {

    var configCadastroUsuario = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Cadastro/' + wCodCadastro,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem("token"), 'Content-Type': 'application/json'
      },
    };
    //console.log(urlAPIlocal)
    axios(configCadastroUsuario)
      .then(function (responseCadastroUsuario) {

        const data = responseCadastroUsuario.data;
        //console.log(data);
        // no localstore 
        localStorage.setItem("NomeUsuario", data.objetoRetorno.Nome);
        //wCodCadastro = data.nome
      }
      )
      .catch(function (error) {
        console.log(error);
      });
    //return wCodCadastro;

  }

  function ConsultaConvenio(wCodCadastro) {

    var configConvenio = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Convenio?CodigoCoordenacaoPedagogia=' + wCodCadastro,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem("token"), 'Content-Type': 'application/json'
      },
    };
    //console.log(urlAPIlocal)
    axios(configConvenio)
      .then(function (responseConvenio) {

        const data = responseConvenio.data.objetoRetorno;
        //console.log(data);
        //Se retornar mais de um resultado
        if (data.length > 1) {
          setLoggedInEscolhaProjeto(true);
          setLoggedInArea(false);
          setLoggedIn(false);
        } else {
          setLoggedInArea(true);
          setLoggedIn(false);
          setLoggedInEscolhaProjeto(false);
        }
      }
      )
      .catch(function (error) {
        console.log(error);
      });
    //return wCodCadastro;

  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      //console.log(username)
      //console.log(password)
      setLoading(true);
      const response = await axios.post(
        urlAPIlocal + '/api/Login',
        {
          login: username,
          senha: password
        },
        {
          headers: {
            //'X-XSRF-TOKEN': xsrfToken
          }
        }
      );
      //console.log(response.data.mensagemRetorno);
      var wmensagem = response.data.mensagemRetorno;
      //console.log(wmensagem)
      if (wmensagem === 'Usuário não encontrado.') {

        setErrorMessage('Verifique os dados inseridos');
        setLoading(false);

      } else if (wmensagem.includes('autenticado com sucesso')) {

        setErrorMessage('');
        //setLoading(false);

        // grava o token do usuario para usar depois nas outras consultas api
        var wtoken = response.data.objetoRetorno.Token;
        localStorage.setItem("token", wtoken);

        //pega o codusuario que logou
        var configUsuario = {
          method: 'GET',
          maxBodyLength: Infinity,
          url: urlAPIlocal + '/api/Usuarios?login=' + username + '&senha=' + password + '&IsInclude=true',
          headers: {
            'Authorization': 'Bearer ' + wtoken, 'Content-Type': 'application/json'
          },
        };
        //console.log(configUsuario)
        axios(configUsuario)
          .then(function (responseUsuario) {

            const dataUsuario = responseUsuario.data;
            //console.log(dataUsuario);
            //console.log(dataUsuario)
            // grava no localstore codusuario + codcadastro
            setLoading(false);
            localStorage.setItem("CodigoUsuario", dataUsuario.objetoRetorno[0].CodigoUsuario)
            localStorage.setItem("CodigoCadastro", dataUsuario.objetoRetorno[0].CodigoCadastro)
            localStorage.setItem("CodigoPerfil", dataUsuario.objetoRetorno[0].CodigoPerfil)
            localStorage.setItem("NomePerfil", dataUsuario.objetoRetorno[0].Perfil.Nome)
            localStorage.setItem("CodigoProjeto", dataUsuario.objetoRetorno[0].CodigoConvenio)
            localStorage.setItem("login", username)

            const wPerfil = dataUsuario.objetoRetorno[0].CodigoPerfil;
            // seta o login como verdadeiro e direciona para pagina index se for usuario normal, se for perfil = 37 direciona para cadsolicitacao
            //console.log(wPerfil);
            //lista cadastro para pegar nome etc do usuario quando precisar
            BuscaCadastro(dataUsuario.objetoRetorno[0].CodigoCadastro)

            if (wPerfil === 37) {
              ConsultaConvenio(dataUsuario.objetoRetorno[0].CodigoCadastro);
            } else {
              setLoggedIn(true);
              setLoggedInArea(false);
              setLoggedInEscolhaProjeto(false);
            }
          }
          )
          .catch(function (error) {
            setLoading(false);
            console.log(error);
            //console.log(JSON.stringify(response.data.mensagemRetorno));
          });


      } else if (wmensagem === 'O login ou a senha não foi informado(a).') {

        // Insira o login e senha (label)
        setErrorMessage('Insira o login e senha.')
        setLoading(false);

      } else if (wmensagem === '') {

        setErrorMessage('wmensagem vazio')
        setLoading(false);
      } else {
        setLoading(false);
        setErrorMessage(wmensagem)
      }


    } catch (error) {
      console.error(error);
      setLoading(false);
      setErrorMessage('Nome do usuário ou senha incorretos. Por favor, tente novamente.');
    }
  }

  /* useEffect(() => {
    if (loggedIn) {
      history.push('/index');
    }
  }, [loggedIn, history]); */

  return (
    <div>
      {/* <header className={Styles.Login_header}>
 <img className={Styles.img_logo} src={logobola} alt='FAUSCS'/>
 <img className={Styles.img_texto} src={logotexto} alt='FAUSCS'/>
 </header> */}

      <div className={Styles.Login}>
        <div className={Styles.Login_container}>
          <img className={Styles.img_logo} src={logobola} alt='FAUSCS' />

          <h2>Login</h2>

          <form className={Styles.form_control} onSubmit={handleSubmit}>
            {errorMessage && <div className={Styles.LoginAlerta}>{errorMessage}</div>}
            <label htmlFor="usuario">Usuário:</label>
            <input type="usuario" id="username" value={username} onChange={(e) => setUsername(e.target.value)}></input>
            <label htmlFor="password">Senha:</label>
            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
            <div className={styles.btn_container}>
              <button type="submit" className={styles.btn}>Entrar<FaArrowRight className={styles.icon} /> </button>
            </div>
            {loggedIn && (<Navigate to="/index" replace={true} />)}
            {loggedInArea && (<Navigate to="/index" replace={true} />)}
            {loggedInEscolhaProjeto && (<Navigate to="/EscolhaProjeto" replace={true} />)}
          </form>
        </div>
      </div>
      <Modal show={loading} className={Styles.Modal} centered >
        <Modal.Body className={Styles.Modal_body}>
          <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LoginForm;