import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";
import urlAPIlocal from '../../global';
import StyleForm from './FaseValor.module.css'
import Menu from "../Layout/Menu"
import Form from 'react-bootstrap/Form'
import { Table, Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { MdEditNote } from "react-icons/md";
import { IoMdArrowBack } from "react-icons/io"
import { AiFillPlusCircle, AiOutlineEdit } from 'react-icons/ai'
import { TiDelete } from "react-icons/ti";
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif"
import { Link } from "react-router-dom";
import { ImSad2 } from 'react-icons/im';
import loadingGif from "../img/icons8-spinning-circle.gif";

//import InputMask from 'react-input-mask';


function FaseValor() {



    const { wCodigoConvenioNovo } = useParams();
    const [login, setLogin] = useState(false);
    const [metaFase, setMetaFase] = useState([]);
    const [metaFaseEscolhida, setMetaFaseEscolhida] = useState('0');

    // eslint-disable-next-line
    const [showForm, setShowForm] = useState(false);

    const [fonte, setFonte] = useState('0');
    const [unidade, setUnidade] = useState([]);
    const [unidadeEscolhida, setUnidadeEscolhida] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [elemento, setElemento] = useState([]);
    const [elementoEscolhido, setElementoEscolhido] = useState('');
    const [valorUnitario, setValorUnitario] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [descricao, setDescricao] = useState('');

    const [CodigoConvenioFase, setCodigoConvenioFase] = useState(0);
    const [CodigoFaseValor, setCodigoFaseValor] = useState(0);
    const [faseValor, setFaseValor] = useState([]);
    const [projetoatual, setProjetoAtual] = useState([]);

    const [editado, setEditado] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalPost, setShowModalPost] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    function handleCloseEdit() {
        setTimeout(() => {
            setShowModalEdit(false);
            //setCadastroOk(true);

        }, 1000);

    }

    function handleClosePost() {
        setTimeout(() => {
            setShowModalPost(false);
            //setCadastroOk(true);

        }, 1000);
    }

    function handleCloseErro() {

        setShowModalErro(false);

    };

    var wTokenLocal = localStorage.getItem("token");
    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    //const navigate = useNavigate();

    // function handleClick() {
    //     navigate(-1);
    // }

    const getMetaFase = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Fase?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setMetaFase(data);
            //console.log(data);

            //console.log(metaFaseEscolhida);

            //muda a ordem dos itens no array antes de mostrar na tela
            //const sortedList = data.sort((c, d) => c.Meta.NumMeta - d.Meta.NumMeta);
            // const sortedList = data.sort((c, d) => { 
            //     if (c.Meta.NumMeta === d.Meta.NumMeta) { 
            //     return c.NumeroFase.localeCompare(d.NumeroFase);      
            // } 
            //     return c.Meta.NumMeta - d.Meta.NumMeta; });
            const sortedList = data.sort((c, d) => {
                if (c.Meta.NumMeta === d.Meta.NumMeta) {
                    const numeroFaseC = parseInt(c.NumeroFase);
                    const numeroFaseD = parseInt(d.NumeroFase);
                    return numeroFaseC - numeroFaseD;
                }
                return c.Meta.NumMeta - d.Meta.NumMeta;
            });

            setIsLoading(false);


        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getMetaFase();
        // eslint-disable-next-line
    }, []);

    const handleForm = async (wFaseSelecionada, wMetaSelecionada, wCodigoConvenioFase, wNomeFase) => {
        //console.log(wFaseSelecionada, wMetaSelecionada)
        var labelMetaFase = wMetaSelecionada + "-" + wFaseSelecionada + " - " + wNomeFase;
        //console.log(labelMetaFase);
        try {

            setShowForm(true);
            setMetaFaseEscolhida(labelMetaFase);
            setCodigoConvenioFase(wCodigoConvenioFase);
            //console.log(wCodigoConvenioFase);

            setFonte('');
            setUnidadeEscolhida('');
            setQuantidade('');
            setElementoEscolhido('');
            setValorUnitario('');
            setValorTotal('');
            setDescricao('');

            setEditado(false);

            //getFaseValor();

        } catch (error) {
            console.log(error)
        }
    }

    const getUnidade = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Unid",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setUnidade(data);
            //console.log(data)



        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getUnidade();
        // eslint-disable-next-line
    }, []);

    const handleSelectUnidade = (event) => {
        setUnidadeEscolhida(event.target.value);
    };

    const handleSelectElemento = (event) => {
        setElementoEscolhido(event.target.value);
    };

    const getElemento = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url: urlAPIlocal + "/api/ConvenioElemento?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setElemento(data);
            //console.log(data)



        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getElemento();
        // eslint-disable-next-line
    }, []);

    const getProjeto = async (event) => {
        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + wCodigoConvenioNovo,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjetoAtual(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        //console.log(fonte);
        var quantidadeFormatadaPost = quantidade;
        quantidadeFormatadaPost = quantidadeFormatadaPost.replace(',', '.');

        var vUnitarioFormatadoPost = valorUnitario;
        vUnitarioFormatadoPost = vUnitarioFormatadoPost.replace(',', '.');

        var vTotalFormatadoPost = valorTotal.toString();
        vTotalFormatadoPost = vTotalFormatadoPost.replace(',', '.');


        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var data = JSON.stringify({
            "CodigoFaseValor": 0,
            "CodigoConvenioFase": CodigoConvenioFase,
            "CodigoFonte": fonte,
            "unidade": unidadeEscolhida,
            "quantidade": quantidadeFormatadaPost,
            "CodigoElemento": elementoEscolhido,
            "descricao": descricao,
            "valorPrevisto": vTotalFormatadoPost,
            "valorUtilizado": 0,
            "CodigoConvenio": wCodigoConvenioNovo,
            "ValorUnitario": vUnitarioFormatadoPost,

        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/FaseValor',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro
                    //getFasesGravadas();

                    getFaseValor();
                    setIsLoading(false);
                    setShowModalPost(true);

                    setFonte('');
                    setUnidadeEscolhida('');
                    setQuantidade('');
                    setElementoEscolhido('');
                    setValorUnitario('');
                    setValorTotal('');
                    setDescricao('');
                    setShowForm(false);

                    //verifica o processo do projeto, se for 5 marcar processo = 6
                    //console.log(projetoatual.Processo);
                    const wProcessoAtual = projetoatual.Processo
                    if (wProcessoAtual === 5) {
                        //gravar o processo
                        const wtoken = "Bearer " + localStorage.getItem("token");
                        var Retorno = false;
                        var config = {
                            method: 'put',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/Convenio/MudarProcesso/' + wCodigoConvenioNovo + '/6',
                            headers: {
                                'Authorization': wtoken,
                                'Content-Type': 'application/json'
                            },
                        };
                        axios(config)
                            .then(function (response) {
                                //console.log(response.data)
                                if (response.data.isOk === true) {
                                    Retorno = true;
                                } else {
                                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                                    Retorno = false
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    };


                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setIsLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
                setShowModalErro(true);
            });
    }

    const handleSelectFonte = (event) => {
        setFonte(event.target.value);
        //console.log(event.target.value)
    };

    //   useEffect(() => {
    //     $('#valorUnitario').maskMoney({
    //       prefix: 'R$ ',
    //       milions: '.',
    //       thousands: '.',
    //       decimal: ',',
    //       allowZero: true,
    //       precision: 2,
    //     });
    //   }, []);

    const handleSomaValor = (event) => {

        setValorUnitario(event.target.value);
        //var wQuantidadeFormatada = event.target.value.replace(/,/g, '.');
        //console.log(event.target.value);
        //console.log(wQuantidadeFormatada);
        var wTotal = event.target.value.replace(/,/g, '.') * quantidade.replace(/,/g, '.');
        setValorTotal(wTotal);

        //console.log(event.target.value)
    };

    const handleSomaValorQuantidade = (event) => {

        setQuantidade(event.target.value.replace('.', ','));
        //var wQuantidadeFormatada = event.target.value.replace(/,/g, '.');
        //console.log(wQuantidadeFormatada);

        var wTotal = valorUnitario.replace(/,/g, '.') * event.target.value.replace(/,/g, '.');
        setValorTotal(wTotal);

        //console.log(event.target.value)
    };


    const getFaseValor = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/FaseValor?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setFaseValor(data);
            //console.log(data)

            //muda a ordem dos itens no array antes de mostrar na tela
            // const FaseValorGravadas = data.sort((a, b) => { 
            // if (a.Fase.Meta.NumMeta === b.Fase.Meta.NumMeta) { 
            // return a.Fase.NumeroFase.localeCompare(b.Fase.NumeroFase); 
            //     } 
            // return a.Fase.Meta.NumMeta - b.Fase.Meta.NumMeta; });
            const FaseValorGravadas = data.sort((a, b) => {
                if (a.Meta.NumMeta === b.Meta.NumMeta) {
                    const numeroFasea = parseInt(a.NumeroFase);
                    const numeroFaseb = parseInt(b.NumeroFase);
                    return numeroFasea - numeroFaseb;
                }
                return a.Meta.NumMeta - b.Meta.NumMeta;
            });

            setFaseValor(FaseValorGravadas);
            //console.log(FaseValorGravadas);

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getFaseValor();
        // eslint-disable-next-line
    }, []);

    const handleEditForm = async (wCodigoFaseValor, wCodigoConvenioFase) => {
        try {
            setIsLoading(true);
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/FaseValor?CodigoFaseValor=" + wCodigoFaseValor + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            var quantidadeFormatada = "" + data[0].Quantidade + "";
            quantidadeFormatada = quantidadeFormatada.replace('.', ',');

            var vUnitarioFormatado = "" + data[0].ValorUnitario + "";
            vUnitarioFormatado = vUnitarioFormatado.replace('.', ',');

            var vTotalFormatado = "" + data[0].ValorPrevisto + "";
            vTotalFormatado = vTotalFormatado.replace('.', ',');

            setFonte(data[0].CodigoFonte);
            setUnidadeEscolhida(data[0].Unidade);
            setQuantidade(quantidadeFormatada);
            setElementoEscolhido(data[0].ConvenioElemento.CodigoConvenioElemento);
            setValorUnitario(vUnitarioFormatado);
            setValorTotal(vTotalFormatado);
            setDescricao(data[0].Descricao)
            setCodigoFaseValor(wCodigoFaseValor);
            setCodigoConvenioFase(wCodigoConvenioFase);

            var labelMetaFase = (data[0].Fase.Meta.NumMeta) + "-" + (data[0].Fase.NumeroFase) + " - " + (data[0].Fase.Nome);
            setMetaFaseEscolhida(labelMetaFase);
            //console.log(data);
            //console.log(wCodigoFaseValor);
            setIsLoading(false);
            setEditado(true);


        } catch (error) {
            console.log(error)
        }
    }

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(false);

        var wtokenBearer = "Bearer " + localStorage.getItem("token")

        var quantidadeFormatadaPut = quantidade;
        quantidadeFormatadaPut = quantidadeFormatadaPut.replace(',', '.');

        var vUnitarioFormatadoPut = valorUnitario;
        vUnitarioFormatadoPut = vUnitarioFormatadoPut.replace(',', '.');

        var vTotalPut = valorTotal;
        var vTotalPut = valorTotal.toString(); // Certifique-se de que valorTotal seja uma string
        if (vTotalPut.includes(',')) {
            vTotalPut = vTotalPut.replace(',', '.');
        }
        //console.log(valorTotal);
        //console.log(vTotalPut);
        //console.log(valorTotal);
        // var vTotalFormatadoPut = valorTotal;
        // vTotalFormatadoPut = vTotalFormatadoPut.replace(',','.');
        //  console.log(vTotalFormatadoPut);
        var data = JSON.stringify({
            "CodigoFaseValor": CodigoFaseValor,
            "CodigoConvenioFase": CodigoConvenioFase,
            "CodigoFonte": fonte,
            "unidade": unidadeEscolhida,
            "quantidade": quantidadeFormatadaPut,
            "CodigoElemento": elementoEscolhido,
            "descricao": descricao,
            "valorPrevisto": vTotalPut,
            "valorUtilizado": 0,
            "CodigoConvenio": wCodigoConvenioNovo,
            "ValorUnitario": vUnitarioFormatadoPut,

        });

        var config = {
            method: 'PUT',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/FaseValor',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    //console.log('Editado com Sucesso!')

                    setMetaFaseEscolhida('0');
                    getFaseValor();
                    setIsLoading(false);
                    setShowModalEdit(true);


                } else {
                    setIsLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
                setShowModalErro(true);
                //console.error(CodigoConvenioFase);

            });
    }


    const ExcluirValor = (wCodigoFaseValor) => {
        setIsLoading(true);
        var configDelete = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/FaseValor/' + wCodigoFaseValor,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataSelect
        };
        //console.log(dataSelect)
        axios(configDelete)

            .then(function (response) {
                getFaseValor();
                setIsLoading(false);
                //setCadastroOk(true);
                //console.log(response.data);
            })
            .catch(error => {
                setIsLoading(false);
                setShowModalErro(true);
                console.error(error);
            });
    }


    return (
        <div className={StyleForm.page}>
            <Menu />

            <div> <button className={StyleForm.btn}><Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link></button> </div>

            <div className={StyleForm.container}>
                <Row>
                    <Col>
                        <div className={StyleForm.table_container}>
                            <Table hover className={StyleForm.table}>
                                <thead>
                                    <tr>
                                        <th >Meta</th>
                                        <th>Fase</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {metaFase &&

                                        (metaFase.length > 0 ?
                                            (metaFase.map((opcao) => (
                                                <tr key={opcao.CodigoConvenioFase}>
                                                    <td>{opcao.Meta.NumMeta}</td>
                                                    <td>{opcao.NumeroFase}</td>
                                                    <td><button type="button" className={StyleForm.btn_abrirform} onClick={(event) => handleForm(opcao.NumeroFase, opcao.Meta.NumMeta, opcao.CodigoConvenioFase, opcao.Nome)}>
                                                        <MdEditNote /></button></td>
                                                </tr>
                                            ))
                                            ) : (<tr><td>Nenhuma Meta Fase Cadastrada</td></tr>))}

                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col>
                        <div className={StyleForm.form_container}>
                            {/* {metaFase && (
                        <h5>Meta-Fase: {metaFase.CodigoMeta}.{metaFase.NumeroFase}</h5>
                    )} */}
                            {/* {showForm &&  */}

                            {metaFaseEscolhida !== '0' ?

                                (<Form className={StyleForm.select} onSubmit={handleSubmit}>

                                    <FormGroup className={StyleForm.form_group}>
                                        <h5>Cadastrando valores na Meta-Fase: {metaFaseEscolhida}</h5>
                                        <Row>
                                            <Col>
                                                <Form.Label className={StyleForm.select_lable}>Fonte</Form.Label>
                                                <Form.Select className={StyleForm.form_control} name="fonte" id="fonte" value={fonte} onChange={handleSelectFonte} required>
                                                    <option>Selecione uma opção</option>
                                                    <option value="1">1 - Concedente</option>
                                                    <option value="2">2 - Convenente</option>
                                                    <option value="3">3 - Executor</option>
                                                    <option value="4">4 - Interveniente</option>
                                                    <option value="5">5 - Rendimentos</option>
                                                </Form.Select>
                                            </Col>

                                            <Col>
                                                <Form.Label className={StyleForm.select_lable}>Unidade</Form.Label>
                                                <Form.Select className={StyleForm.form_control} name="unidade" id="unidade" value={unidadeEscolhida} onChange={handleSelectUnidade} required>
                                                    <option>Selecione uma opção</option>
                                                    {unidade.length > 0 &&
                                                        unidade.map((opcao) => (
                                                            <option key={opcao.CodigoUnidade} value={opcao.CodigoUnidade}>{opcao.Nome}</option>
                                                        ))}
                                                </Form.Select>
                                            </Col>

                                            <Col>
                                                <Form.Label className={StyleForm.select_lable}>Tipo de Despesa (Elemento)</Form.Label>

                                                <Form.Select className={StyleForm.form_control} name="elementos" id="elementos" value={elementoEscolhido} onChange={handleSelectElemento}>
                                                    <option>Selecione uma opção</option>
                                                    {elemento.length > 0 &&
                                                        elemento
                                                            .sort((a, b) => a.CodigoElemento - b.CodigoElemento) // Ordena os elementos numericamente
                                                            .map((opcao) => (
                                                                opcao.CodigoElemento === 100 ? null : (
                                                                    <option key={opcao.CodigoElemento} value={opcao.CodigoConvenioElemento}>
                                                                        {opcao.CodigoElemento} - {opcao.Elemento.Nome}
                                                                    </option>
                                                                )
                                                            ))}
                                                </Form.Select>


                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Label className={StyleForm.form_group_label}>Quantidade</Form.Label>
                                                <Form.Control className={StyleForm.form_control} type="text" value={quantidade} onChange={handleSomaValorQuantidade} required />
                                            </Col>

                                            <Col>
                                                <Form.Label className={StyleForm.form_group_label}>Valor Unitário</Form.Label>
                                                <Form.Control className={StyleForm.form_control} type="text" value={valorUnitario} onChange={handleSomaValor} required />
                                            </Col>
                                            <Col>
                                                <Form.Label className={StyleForm.form_group_label}>Valor Total</Form.Label>
                                                <Form.Control className={StyleForm.form_control} type="text" value={valorTotal} disabled />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label className={StyleForm.form_group_label}>Descrição</Form.Label>
                                                <Form.Control className={StyleForm.form_control} type="text" value={descricao} onChange={(event) => setDescricao(event.target.value)} required />
                                            </Col>
                                        </Row>

                                        <Row>
                                            {!editado && (
                                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleSubmit}>
                                                    Inserir Valores <AiFillPlusCircle />
                                                </button>
                                            )}

                                            {/* Renderiza o botão "Editar Valores" se estiver editando */}
                                            {editado && (
                                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleEditSubmit}>
                                                    Editar Valores <AiFillPlusCircle />
                                                </button>
                                            )}
                                        </Row>

                                    </FormGroup>
                                </Form>) : (
                                    <h3>Selecione a Meta Fase para inserir novos valores</h3>
                                )
                            }
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={StyleForm.container_table_valores}>

                <Table hover className={StyleForm.table}>
                    <thead>
                        <tr>
                            <th>Meta</th>
                            <th>Fase</th>
                            <th>Fonte</th>
                            <th>Tipo de Despesa</th>
                            <th>Valor Previsto</th>
                            <th>Finalidade</th>
                            <th className={StyleForm.th_btn}></th>
                            <th className={StyleForm.th_btn}></th>
                        </tr>
                    </thead>
                    <tbody>

                        {faseValor &&

                            (faseValor.length > 0 ?
                                (faseValor.map((item) => (
                                    <tr key={item.CodigoFaseValor}>
                                        <td style={{ fontWeight: 'bold' }}>{item.Fase.Meta.NumMeta}</td>
                                        <td style={{ fontWeight: 'bold' }}>{item.Fase.NumeroFase}</td>
                                        <td>{item.CodigoFonte}</td>
                                        <td>{item.ConvenioElemento.Elemento.Nome}</td>
                                        <td>{item.ValorPrevisto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>{item.Descricao}</td>
                                        <td><button type="button" className={StyleForm.btn_edit} onClick={(event) => handleEditForm(item.CodigoFaseValor, item.CodigoConvenioFase)}>
                                            <AiOutlineEdit title="Editar" /> </button></td>
                                        <td><button type="button" className={StyleForm.btn_delete} onClick={(event) => ExcluirValor(item.CodigoFaseValor)}>
                                            <TiDelete /></button></td>
                                    </tr>
                                ))
                                ) : (<tr><td>Nenhum Valor Cadastrado</td></tr>))}

                    </tbody>
                </Table>
            </div>
            <Modal show={isLoading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={showModalPost} onLoad={handleClosePost} className={StyleForm.Modal} centered >
                <Modal.Header className={StyleForm.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    Valores inseridos com sucesso!
                </Modal.Body>
            </Modal>


            <Modal show={showModalEdit} onLoad={handleCloseEdit} className={StyleForm.Modal} centered >
                <Modal.Header className={StyleForm.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    Valores atualizados com sucesso!
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={StyleForm.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

            {login && (<Navigate to="/" replace={true} />)}
        </div>
    )
}
export default FaseValor