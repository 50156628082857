
import React from "react"
import Form from 'react-bootstrap/Form'
import StyleForm from './/Metas.module.css'
import { AiFillPlusCircle, AiOutlineEdit } from 'react-icons/ai'
import { TiDelete } from "react-icons/ti";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Menu from "../Layout/Menu";
import { Col, Table, FormGroup } from "react-bootstrap";
import { Navigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import done from "../img/done.svg";
import { ImSad2 } from 'react-icons/im';
import loadingGif from "../img/icons8-spinning-circle.gif";


function Metas() {
    const { wCodigoConvenioNovo } = useParams();
    const [login, setLogin] = useState(false);
    //const [Meta, setMeta] = useState([]);
    const [metaEscolhida, setMetaEscolhida] = useState('');
    const [nome, setNome] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [objetivo, setObjetivo] = useState('');
    const [email, setEmail] = useState('');
    const [metasGravadas, setMetasGravadas] = useState([]);
    const [excluirMeta, setExcluirMeta] = useState([]);
    const [projetoatual, setProjetoAtual] = useState([]);

    const [CodigoMeta, setCodigoMeta] = useState(0);
    const [editado, setEditado] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    var wTokenLocal = localStorage.getItem("token");

    // const navigate = useNavigate();

    // function handleClick() {
    //     navigate(-1);
    // }

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function handleClose() {
        setTimeout(() => {
            setShowModal(false);

        }, 2000);

    }

    function handleCloseEdit() {
        setTimeout(() => {
            setShowModalEdit(false);
            //setCadastroOk(true);

        }, 2000);

    }

    function handleCloseErro() {

        setShowModalErro(false);

    };

    const getProjeto = async (event) => {
        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + wCodigoConvenioNovo,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjetoAtual(response.data.objetoRetorno);
                var wDataInicio = new Date(response.data.objetoRetorno.DataInicio);
                wDataInicio = moment(wDataInicio).format("yyyy-MM-DD"); //wDataInicio.toLocaleDateString('pt-BR')
                setDataInicio(wDataInicio);
                var wDataExpiracao = new Date(response.data.objetoRetorno.DataExpiracao);
                wDataExpiracao = moment(wDataExpiracao).format("yyyy-MM-DD");
                setDataFim(wDataExpiracao);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();

        //verificar se a meta que vai ser gravada ja existe no projeto

        setIsLoading(true);

        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var data = JSON.stringify({
            "email": {
                "endereco": email
            },
            "CodigoMeta": 0,
            "nome": nome,
            "CodigoConvenio": wCodigoConvenioNovo,
            "DataInicio": dataInicio,
            "DataFim": dataFim,
            "NumMeta": metaEscolhida,
            "ValorMeta": 0,
            "objetivo": objetivo,
            "resultado": "string"

        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Meta',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro

                    getMetasGravadas();
                    setIsLoading(false);
                    setShowModal(true);
                    //limpar os campos para nova Meta
                    setNome('');
                    setDataFim('');
                    setDataInicio('');
                    setObjetivo('');
                    setEmail('');
                    //const wNumMeta = metaEscolhida + 1;
                    setMetaEscolhida(0);

                    //verifica o processo do projeto, se for 3 marcar processo = 4
                    //console.log(projetoatual.Processo);
                    const wProcessoAtual = projetoatual.Processo
                    if (wProcessoAtual === 3) {
                        //gravar o processo
                        const wtoken = "Bearer " + localStorage.getItem("token");
                        var Retorno = false;
                        var config = {
                            method: 'put',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/Convenio/MudarProcesso/' + wCodigoConvenioNovo + '/4',
                            headers: {
                                'Authorization': wtoken,
                                'Content-Type': 'application/json'
                            },
                        };
                        axios(config)
                            .then(function (response) {
                                //console.log(response.data)
                                if (response.data.isOk === true) {
                                    Retorno = true;
                                } else {
                                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                                    Retorno = false
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    };

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setIsLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
                setShowModalErro(true);
            });
    }

    const getMetasGravadas = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Meta?CodigoConvenio=" + wCodigoConvenioNovo,
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setMetasGravadas(data);
            //console.log(data)

            // Consulta para cada CodigoMeta
            const consultaFase = async () => {
                try {
                    const fasesComCodigoRetornoZero = [];

                    for (const Meta of data) {
                        const configFaseValor = {
                            method: "GET",
                            url:
                                urlAPIlocal +
                                "/api/Fase?CodigoMeta=" +
                                Meta.CodigoMeta +
                                "&IsInclude=true",
                            headers: {
                                Authorization: wtoken,
                                "Content-Type": "application/json",
                            },
                        };

                        const responseFase = await axios(configFaseValor);
                        const dataFase = responseFase.data;

                        //console.log(dataFase);

                        if (dataFase.codigoRetorno === '0') {
                            fasesComCodigoRetornoZero.push(Meta.CodigoMeta);
                        }
                    }

                    // Agora fasesComCodigoRetornoZero contém os CodigoConvenioFase com código de retorno zero
                    // Defina o estado para forçar a atualização da renderização
                    setExcluirMeta(fasesComCodigoRetornoZero);
                } catch (error) {
                    console.log(error);
                }
            };

            consultaFase(); // Chama a função de consulta

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getMetasGravadas();
        // eslint-disable-next-line
    }, []);

    const handleEditForm = async (wCodigoMeta) => {
        try {
            setIsLoading(true);
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Meta?CodigoMeta=" + wCodigoMeta + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data);

            setMetaEscolhida(data[0].NumMeta);
            setNome(data[0].Nome);

            const DataInicioFormatada = moment(data[0].DataInicio).format("YYYY-MM-DD");
            setDataInicio(DataInicioFormatada);

            const DataFimFormatada = moment(data[0].DataFim).format("YYYY-MM-DD");
            setDataFim(DataFimFormatada);

            setObjetivo(data[0].Objetivo);
            setEmail(data[0].Email.Endereco);
            setCodigoMeta(wCodigoMeta);

            setIsLoading(false);
            setEditado(true);


        } catch (error) {
            setIsLoading(false);
            console.log(error)
        }
    }

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        //console.log("entrou aqui")
        //console.log(metaEscolhida);
        var wtokenBearer = "Bearer " + localStorage.getItem("token")


        var data = JSON.stringify({
            "email": {
                "endereco": email
            },
            "CodigoMeta": CodigoMeta,
            "nome": nome,
            "CodigoConvenio": wCodigoConvenioNovo,
            "DataInicio": dataInicio,
            "DataFim": dataFim,
            "NumMeta": metaEscolhida,
            "objetivo": objetivo,
            "resultado": ""
        });

        var config = {
            method: 'PUT',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Meta',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    //console.log('Editado com Sucesso!')

                    //setMetaFaseEscolhida('0');
                    setIsLoading(false);
                    setShowModalEdit(true);
                    getMetasGravadas();

                    //limpar os campos para nova Meta
                    setNome('');
                    setDataFim('');
                    setDataInicio('');
                    setObjetivo('');
                    setEmail('');



                } else {
                    setIsLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);
                //console.error(CodigoConvenioFase);

            });
    }




    //   const ExcluirMeta = (wMeta) => {
    //     var configSelect = {
    //         method: 'delete',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/Meta/' + wMeta,
    //         headers: {
    //             'Authorization': "Bearer " + localStorage.getItem("token"),
    //             'Content-Type': 'application/json'
    //         },
    //         //data: dataSelect
    //     };
    //     //console.log(dataSelect)
    //     axios(configSelect)

    //         .then(function (response) {
    //             getMetasGravadas();

    //             //setCadastroOk(true);
    //             //console.log(response.data);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });

    // }

    const handleExcluirMeta = (wMeta) => {
        setIsLoading(true);
        var configSelect = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Meta/' + wMeta,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataSelect
        };
        //console.log(dataSelect)
        axios(configSelect)

            .then(function (response) {
                getMetasGravadas();
                setIsLoading(false);
                //setCadastroOk(true);
                //console.log(response.data);
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);
            });

    }

    return (
        <div className={StyleForm.page}>
            <Menu />

            {/* <div> <button className={StyleForm.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={StyleForm.voltar}>  <Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            <div className={StyleForm.header}>
                <h1>Cadastro de Metas (Ações)</h1>
            </div>

            <div className={StyleForm.container}>

                <Form className={StyleForm.select} onSubmit={handleSubmit} >
                    <FormGroup>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.select_lable}>Meta*</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="elementos" id="elementos" value={metaEscolhida} onChange={(event) => setMetaEscolhida(event.target.value)} disabled={editado ? true : false} required>
                                    <option value="">Selecione a Meta</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>

                                </Form.Select>
                            </Col>
                            <Col md={8}>
                                <Form.Label className={StyleForm.form_group_label} >Nome*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="nome" value={nome} onChange={(event) => setNome(event.target.value)} required />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data de Início*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" value={dataInicio} onChange={(event) => setDataInicio(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data do Fim*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" value={dataFim} onChange={(event) => setDataFim(event.target.value)} required />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Objetivo</Form.Label>
                                <Form.Control className={StyleForm.form_control} name="detalhes" id="datalhes" placeholder="Descreva o objetivo" value={objetivo} onChange={(event) => setObjetivo(event.target.value)} required></Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            <Form.Label className={StyleForm.form_group_label}>Email</Form.Label>
                            <Form.Control className={StyleForm.form_control_email} type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                        </Row>

                        <Row>
                            {!editado && (
                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleSubmit}>
                                    Inserir Meta <AiFillPlusCircle />
                                </button>
                            )}

                            {/* Renderiza o botão "Editar Valores" se estiver editando */}
                            {editado && (
                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleEditSubmit}>
                                    Editar Meta <AiFillPlusCircle />
                                </button>
                            )}
                        </Row>
                    </FormGroup>
                </Form>

                <div>
                    <Table hover className={StyleForm.table}>
                        <thead>
                            <tr>
                                <th>Meta</th>
                                <th>Nome</th>
                                <th>Valor Total</th>
                                <th>Início</th>
                                <th>Fim</th>
                                <th className={StyleForm.th_btn}></th>
                                <th className={StyleForm.th_btn}></th>
                            </tr>
                        </thead>
                        <tbody>

                            {metasGravadas &&

                                (metasGravadas.length > 0 ?
                                    (metasGravadas.map((Meta) => (
                                        <tr key={Meta.CodigoMeta}>
                                            <td>{Meta.NumMeta}</td>
                                            <td>{Meta.Nome}</td>
                                            <td>{Meta.ValorMeta.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td>{moment(Meta.DataInicio).format('DD-MM-YYYY')}</td>
                                            <td>{moment(Meta.DataFim).format('DD-MM-YYYY')}</td>
                                            <td><button type="button" className={StyleForm.btn_edit} onClick={(event) => handleEditForm(Meta.CodigoMeta)}>
                                                <AiOutlineEdit title="Editar" /> </button></td>
                                            <td>
                                                {excluirMeta.includes(Meta.CodigoMeta) ? ( // Verifica se CodigoMeta está na lista de fases com código de retorno zero
                                                    <button
                                                        type="button"
                                                        className={StyleForm.btn_delete}
                                                        onClick={() => handleExcluirMeta(Meta.CodigoMeta)}
                                                    >
                                                        <TiDelete />
                                                    </button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))
                                    ) : (<tr><td>Nenhuma meta cadastrada</td></tr>))}

                        </tbody>
                    </Table>
                </div>

                <button className={StyleForm.edit}>
                    <Link to={`/Fases/${wCodigoConvenioNovo}`}>
                        Cadastrar Fases <IoMdArrowForward />
                    </Link>
                </button>

            </div>

            <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
                <Modal.Header className={StyleForm.Modal_header}>
                    <img src={done} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    Meta inserida com sucesso!
                </Modal.Body>
            </Modal>

            <Modal show={showModalEdit} onLoad={handleCloseEdit} className={StyleForm.Modal} centered >
                <Modal.Header className={StyleForm.Modal_header}>
                    <img src={done} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    Meta atualizada com sucesso!
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={StyleForm.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

            <Modal show={isLoading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            {login && (<Navigate to="/" replace={true} />)}

        </div>
    )
}
export default Metas