//import dados from './PlanoTrabalho.json';
import React from "react"
//import Form from 'react-bootstrap/Form'
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Col, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
//import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import Row from 'react-bootstrap/Row';
import moment from "moment";
//import Menu from "../Layout/Menu";
import Style from './PtFinanceiro.module.css';
import printStyles from "./PtFinanceiroPrint.module.css";
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';



function PtFinanceiro() {

    //MUDA O CSS QUANDO IMPRIMI

    const [isPrinting, setIsPrinting] = useState(false);

    useEffect(() => {
        const mediaQueryList = window.matchMedia("print");

        const handlePrintChange = (event) => {
            setIsPrinting(event.matches);
        };

        mediaQueryList.addListener(handlePrintChange);
    }, []);


    // useEffect(() => {
    //     const handleBeforePrint = () => {
    //       document.body.classList.add(Style.printMode);
    //     };

    //     const handleAfterPrint = () => {
    //       document.body.classList.remove(Style.printMode);
    //     };

    //     window.addEventListener("beforeprint", handleBeforePrint);
    //     window.addEventListener("afterprint", handleAfterPrint);

    //     return () => {
    //       window.removeEventListener("beforeprint", handleBeforePrint);
    //       window.removeEventListener("afterprint", handleAfterPrint);
    //     };
    //   }, []);
    const [loading, setLoading] = useState(true);
    var wTokenLocal = localStorage.getItem("token");
    const [login, setLogin] = useState(false);
    const { wCodigoConvenioNovo } = useParams();
    const [pt, setPt] = useState([]);
    const [ptConsolidadoDespesas, setPtConsolidadoDespesas] = useState([]);
    const [ptConsolidadoElementos, setPtConsolidadoElementos] = useState([]);
    const [ptMetas, setPtMetas] = useState([]);
    const [concedentesGravados, setConcedentesGravados] = useState([]);
    //const [ptMetasFases, setPtMetasFases] = useState([]);
    //const [ptMetasElementos, setPtMetasElementos] = useState([]);
    //const [ptMetasElementosItens, setPtMetasElementosItens] = useState([]);
    //const [ptMetasFasesPrevistos, setPtMetasFasesPrevistos] = useState([]);
    //console.log(dados);
    //console.log(dados.Metas[0].Elementos.Itens);

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);



    function AlimentaPT() {

        //console.log(dados);
        const wtoken = "Bearer " + wTokenLocal;

        const config = {
            method: "GET",
            url:
                urlAPIlocal + "/api/ApiPlanoTrabalho/" + wCodigoConvenioNovo,
            headers: {
                Authorization: wtoken,
                "Content-Type": "application/json",
            },
        };

        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    var dados = response.data.objetoRetorno;
                    //console.log(dados);
                    setPt(dados);
                    setPtConsolidadoDespesas(dados.ConsolidadoDespesas);

                    setPtConsolidadoElementos(dados.ConsolidadoDespesas.Elementos);
                    setPtMetas(dados.Metas);
                    setLoading(false);
                    //setPtMetasElementos(dados.Metas[0].Elementos);
                    //setPtMetasElementosItens(dados.Metas[0].Elementos.Itens);
                    //setPtMetasFases(dados.Metas[0].MetaFases);
                    //console.log(dados.Metas);
                    //setPtMetasFasesPrevistos(dados.Metas[0].MetaFases[0].MetaFasePrevistos);
                    //console.log(dados.Metas[0].MetaFases[0].MetaFasePrevistos);
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //Retorno = true
                }

            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
        //return Retorno;
    }
    useEffect(() => {
        AlimentaPT();
        // eslint-disable-next-line
    }, []);


    // pega o concedente do projeto
    const getConcedentesGravados = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/ConvenioConcedente?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config)
            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setConcedentesGravados(data);
            //console.log(data)

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getConcedentesGravados();
        // eslint-disable-next-line
    }, []);


    return (
        <div className={Style.page}>
            {/* <Menu /> */}
            <div className={Style.container}>

                <div className={Style.content}>

                    <div className={`${Style.header} ${isPrinting ? printStyles.printheader : ""}`}>
                        {(concedentesGravados.map((Concedentes) => (
                            <span key={Concedentes.CodigoConvenioConcedente}>
                                <h3>{Concedentes.Cadastro.Nome}</h3>
                            </span>
                        )))}
                    </div>


                    <div className={`${Style.header} ${isPrinting ? printStyles.printheader : ""}`}>
                        <Row>
                            <Col>
                                <p align="center">UNIVERSIDADE</p>
                            </Col>
                            <Col>
                                <p align='center'>DIRETORIA ADMINISTRATIVA DE CONTRATOS E CONVÊNIOS</p>
                            </Col>
                            <Col>
                                <p align="center">SUPERVISÃO DE PRESTAÇÃO DE CONTAS DE CONVÊNIOS</p>
                            </Col>
                        </Row>
                    </div>



                    <div className={`${Style.projeto} ${isPrinting ? printStyles.printprojeto : ""}`}>
                        <p align="center">{pt.Nome}</p>
                    </div>


                    <div className={Style.Tipo_Despesa}>
                        <Table>
                            <thead>
                                <tr>
                                    <th className={`${Style.table_consolidado} ${isPrinting ? printStyles.printTable_consolidado : ""}`}>
                                        Consolidado por Tipo de Despesa
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th className={`${Style.table_consolidado_head} ${isPrinting ? printStyles.printTable_consolidado_head : ""}`} align="center">Elemento</th>
                                                    <th className={`${Style.table_consolidado_head} ${isPrinting ? printStyles.printTable_consolidado_head : ""}`} align="center">Tipo de Despesa</th>
                                                    <th className={`${Style.table_consolidado_head} ${isPrinting ? printStyles.printTable_consolidado_head : ""}`} align="center">Concedente</th>
                                                    <th className={`${Style.table_consolidado_head} ${isPrinting ? printStyles.printTable_consolidado_head : ""}`} align="center">Convenente</th>
                                                    <th className={`${Style.table_consolidado_head} ${isPrinting ? printStyles.printTable_consolidado_head : ""}`} align="center">Executor</th>
                                                    <th className={`${Style.table_consolidado_head} ${isPrinting ? printStyles.printTable_consolidado_head : ""}`} align="center">Interveniente</th>
                                                    <th className={`${Style.table_consolidado_head} ${isPrinting ? printStyles.printTable_consolidado_head : ""}`} align="center">Rendimentos</th>
                                                    <th className={`${Style.table_consolidado_head} ${isPrinting ? printStyles.printTable_consolidado_head : ""}`} align="right">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {(ptConsolidadoElementos.map((ConsolidadoElementos, indexConsolidado) => (
                                                    <tr key={indexConsolidado}>
                                                        <td className={`${Style.table_consolidado_body} ${isPrinting ? printStyles.printTable_consolidado_body : ""}`} align="center">{ConsolidadoElementos.CodigoElemento}</td>
                                                        <td className={`${Style.table_consolidado_body} ${isPrinting ? printStyles.printTable_consolidado_body : ""}`} align="left">{ConsolidadoElementos.Nome}</td>
                                                        <td className={`${Style.table_consolidado_body} ${isPrinting ? printStyles.printTable_consolidado_body : ""}`} align="right">{ConsolidadoElementos.TotalFonte1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td className={`${Style.table_consolidado_body} ${isPrinting ? printStyles.printTable_consolidado_body : ""}`} align="right">{ConsolidadoElementos.TotalFonte2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td className={`${Style.table_consolidado_body} ${isPrinting ? printStyles.printTable_consolidado_body : ""}`} align="right">{ConsolidadoElementos.TotalFonte3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td className={`${Style.table_consolidado_body} ${isPrinting ? printStyles.printTable_consolidado_body : ""}`} align="right">{ConsolidadoElementos.TotalFonte4.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td className={`${Style.table_consolidado_body} ${isPrinting ? printStyles.printTable_consolidado_body : ""}`} align="right">{ConsolidadoElementos.TotalFonte5.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                        <td className={`${Style.table_consolidado_body} ${isPrinting ? printStyles.printTable_consolidado_body : ""}`} align="right">{ConsolidadoElementos.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    </tr>
                                                )))}
                                                <tr>
                                                    <td className={`${Style.table_consolidado_total} ${isPrinting ? printStyles.printTable_consolidado_total : ""}`}>&nbsp;</td>
                                                    <td className={`${Style.table_consolidado_total} ${isPrinting ? printStyles.printTable_consolidado_total : ""}`} align="right">Total : </td>
                                                    <td className={`${Style.table_consolidado_total} ${isPrinting ? printStyles.printTable_consolidado_total : ""}`} align="right">{ptConsolidadoDespesas.TotalFonte1 ? ptConsolidadoDespesas.TotalFonte1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                    <td className={`${Style.table_consolidado_total} ${isPrinting ? printStyles.printTable_consolidado_total : ""}`} align="right">{ptConsolidadoDespesas.TotalFonte2 ? ptConsolidadoDespesas.TotalFonte2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                    <td className={`${Style.table_consolidado_total} ${isPrinting ? printStyles.printTable_consolidado_total : ""}`} align="right">{ptConsolidadoDespesas.TotalFonte3 ? ptConsolidadoDespesas.TotalFonte3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                    <td className={`${Style.table_consolidado_total} ${isPrinting ? printStyles.printTable_consolidado_total : ""}`} align="right">{ptConsolidadoDespesas.TotalFonte4 ? ptConsolidadoDespesas.TotalFonte4.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                    <td className={`${Style.table_consolidado_total} ${isPrinting ? printStyles.printTable_consolidado_total : ""}`} align="right">{ptConsolidadoDespesas.TotalFonte5 ? ptConsolidadoDespesas.TotalFonte5.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</td>
                                                    <td className={`${Style.table_consolidado_total} ${isPrinting ? printStyles.printTable_consolidado_total : ""}`} align="right">{ptConsolidadoDespesas.Total ? ptConsolidadoDespesas.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ' R$ 0,00'}</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </td>
                                </tr>
                            </tbody>

                        </Table>
                    </div>


                    <p className={`${Style.table_consolidado} ${isPrinting ? printStyles.printTable_consolidado : ""}`}> Consolidados por Metas</p>


                    <div>
                        {/* primeiro map na metafase */}

                        {(ptMetas.map((Metas, indexMetas) => (

                            <div className={`${Style.Metas} ${isPrinting ? printStyles.printMetas : ""}`}>
                                {/* {console.log(indexMetas)} */}
                                {/* <Table><thead><tr><td></td></tr></thead></Table> */}
                                <Table bordered >
                                    <thead>
                                        <tr key={indexMetas}>
                                            <td colSpan="5" style={{ backgroundColor: isPrinting ? '#fff' : "#85929E" }}>
                                                <Row>
                                                    <Col>
                                                        <h5>Meta {Metas.NumeroMeta} - {Metas.Nome}</h5>
                                                        <h5>Objetivos: {Metas.Objetivo}</h5>
                                                    </Col>
                                                    <Col>
                                                        <h5 align="right">Início : {moment(Metas.DatInicio).format('DD-MM-YYYY')}</h5>
                                                        <h5 align="right">Término : {moment(Metas.DatFim).format('DD-MM-YYYY')}</h5>
                                                    </Col>
                                                </Row>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', textAlign: "center" }}>&nbsp;</th>
                                            <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF' }}>Tipo de despesa</th>
                                            <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', textAlign: "center" }}>Concedente</th>
                                            <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', textAlign: "center" }}>Convenente</th>
                                            <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', textAlign: "center" }}>Total : </th>
                                        </tr>
                                    </thead>
                                    {/* {console.log(Metas.Elementos.Itens)} */}
                                    <tbody>
                                        {(Metas.Elementos.Itens.map((MetasElementos, indexElementos) => {
                                            MetasElementos.indexElementos = indexMetas;
                                            return (
                                                <tr key={indexElementos}>
                                                    <td style={{ padding: '5px', fontSize: '12px' }} align="center">{MetasElementos.NumeroElemento}</td>
                                                    <td style={{ padding: '5px', fontSize: '12px' }} align="left">{MetasElementos.NomeElemento}</td>
                                                    <td style={{ padding: '5px', fontSize: '12px' }} align="right">{MetasElementos.ValorPrevisto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td style={{ padding: '5px', fontSize: '12px' }} align="right">{MetasElementos.ValorPrevistoConvenente.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td style={{ padding: '5px', fontSize: '12px' }} align="right">{MetasElementos.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                </tr>
                                            )
                                        }))}
                                        <tr align="right">
                                            {/* {console.log(Metas.Elementos)} */}
                                            <td style={{ padding: '5px', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF' }}>&nbsp;</td>
                                            <td style={{ padding: '5px', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', textAlign: "right" }}>Total : </td>
                                            <td style={{ padding: '5px', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', textAlign: "right" }}>{Metas.Elementos.TotalElementos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', textAlign: "right" }}>R$ 0,00</td>
                                            <td style={{ padding: '5px', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', textAlign: "right" }}>{Metas.Elementos.TotalElementos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        </tr>
                                    </tbody>

                                </Table>
                                <div>
                                    {(Metas.MetaFases.map((MetasFases, indexMetasFases) => {
                                        MetasFases.indexMetasFases = indexMetas;
                                        return (
                                            <div className={`${Style.Metas} ${isPrinting ? printStyles.printMetas : ""}`}>
                                                <Table bordered>
                                                    <thead>
                                                        {/* {console.log(indexMetasFases)} */}
                                                        <tr key={indexMetasFases}>
                                                            <td colSpan="12" style={{ backgroundColor: isPrinting ? '#fff' : "#D6DBDF" }}>
                                                                <Row>
                                                                    <Col>
                                                                        <h6>Meta: {Metas.NumeroMeta} - Fase: {MetasFases.NumeroFase} - {MetasFases.NomeFase}</h6>
                                                                        <h6>Objetivos: {MetasFases.Objetivo}</h6>
                                                                    </Col>
                                                                    <Col>
                                                                        <h6 align="right">Início : {moment(MetasFases.DataInicio).format('DD-MM-YYYY')}</h6>
                                                                        <h6 align="right">Término : {moment(MetasFases.DataFim).format('DD-MM-YYYY')}</h6>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }} >Meta</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }} >Fase</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }} >Tipo de despesas</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }} >Fonte</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }} >UN.</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }} >Qtd</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }} >Elem</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }} >Valor</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }} >Total</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }}>Descri&#231;&#227;o</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }}>Valor Gasto</th>
                                                            <th style={{ padding: '.3em', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "center" }}>Gasto %</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {console.log(MetasFases.MetaFasePrevistos)} */}
                                                        {(MetasFases.MetaFasePrevistos.map((MetasFasesPrevistos, indexPrevistos) => {
                                                            MetasFasesPrevistos.indexPrevistos = indexMetasFases;
                                                            return (

                                                                <tr key={indexPrevistos}>


                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "center" }}>{Metas.NumeroMeta}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "center" }}>{MetasFases.NumeroFase}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px' }} align="left">{MetasFasesPrevistos.NomeElemento}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "center" }}>{MetasFasesPrevistos.CodigoFonte}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "center" }}>{MetasFasesPrevistos.Unidade}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "center" }}>{MetasFasesPrevistos.Quantidade}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "center" }}>{MetasFasesPrevistos.CodigoElemento}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "right" }}>{MetasFasesPrevistos.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "right" }}>{MetasFasesPrevistos.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "left" }}>{MetasFasesPrevistos.Descricao}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "right" }}>{MetasFasesPrevistos.ValGasto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                                    <td style={{ padding: '5px', fontSize: '12px', textAlign: "center" }}>{MetasFasesPrevistos.PercentagemGasto * 100} %</td>
                                                                </tr>

                                                            )
                                                        }))}
                                                        <tr align="right">
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }}>&nbsp;</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }}>&nbsp;</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }}>&nbsp;</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }}>&nbsp;</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }}>&nbsp;</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }}>&nbsp;</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }}>&nbsp;</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF' }}>Total :</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "right" }}>{MetasFases.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "right" }}></td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "right" }}>{MetasFases.TotalGasto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                            <td style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold', backgroundColor: '#AEB6BF', textAlign: "right" }} ></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>


                                            </div>
                                        )
                                    }))}

                                </div>

                            </div>

                        )))}
                    </div>


                </div>

            </div>
            <Modal show={loading} className={Style.Modal} centered >
                <Modal.Body className={Style.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>
            {login && (<Navigate to="/" replace={true} />)}
        </div>
    )
}
export default PtFinanceiro