import Menu from "../Layout/Menu";
import StyleForm from './CadProjeto.module.css'
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { MdCreate } from 'react-icons/md'
import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router";
import moment from "moment";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import loadingGif from "../img/icons8-spinning-circle.gif";
import check_circle from "../img/check_circle.gif";
import { ImSad2 } from 'react-icons/im';
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
//import { set } from "date-fns";


function CadProjeto() {


  const [wCodigoCadastroNovo, setwCodigoConvenioNovo] = useState(0);
  const [login, setLogin] = useState(false);
  const [gravando, setGravando] = useState(false);
  const [numeroProjeto, setNumeroProjeto] = useState('');
  const [nomeProjeto, setNomeProjeto] = useState('');
  const [cidade, setCidade] = useState('');
  const [isModalTipoProjetoOpen, setIsModalTipoProjetoOpen] = useState(false);
  const [novoTipoProjeto, setNovoTipoProjeto] = useState('');
  const [tipoProjeto, setTipoProjeto] = useState([]);
  const [tipoProjetoEscolhido, setTipoProjetoEscolhido] = useState('0');
  const [isModalInstrumentoOpen, setIsModalInstrumentoOpen] = useState(false);
  const [instrumentos, setInstrumentos] = useState([]);
  const [novoInstrumento, setNovoInstrumento] = useState('');
  const [tipoInstrumentoEscolhido, setTipoInstrumentoEscolhido] = useState('0');
  const [leiPC, setLeiPC] = useState('');
  const [periocidade, setPeriodicidade] = useState('');
  const [prazoFinal, setPrazoFinal] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataExpiracao, setDataExpiracao] = useState('');
  const [dataAssinatura, setDataAssinatura] = useState('01/01/1900');
  const [dataPubli, setDataPubli] = useState('01/01/1900');
  const [detalhes, setDetalhes] = useState('');
  const [objetivo, setObjetivo] = useState('');

  const [coordenador, setCoordenador] = useState([]);
  //const [acessoServidor, setAcessoServidor] = useState([]);
  //const [convenente, setConvenente] = useState([]);
  //const [ordenador, setOrdenador] = useState([]);
  //const [executor, setExecutor] = useState([]);
  //const [interveniente, setInterveniente] = useState([]);
  const [coordenadorEscolhido, setCoordenadorEscolhido] = useState(0);
  // eslint-disable-next-line
  const [nomeCadastroArea, setNomeCadastroArea] = useState('');
  //const [emailArea, setEmailArea] = useState('');
  // const [acessoServidorEscolhido, setAcessoServidorEscolhido] = useState('');
  // const [convenenteEscolhido, setConvenenteEscolhido] = useState('');
  // const [ordenadorEscolhido, setOrdenadorEscolhido] = useState('');
  // const [executorEscolhido, setExecutorEscolhido] = useState('');
  // const [intervenienteEscolhido, setIntervenienteEscolhido] = useState('');

  const [nomeBanco, setNomeBanco] = useState('');
  const [numeroBanco, setNumeroBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [numeroConta, setNumeroConta] = useState('');
  const [nomeConta, setNomeConta] = useState('');

  const [loading, setLoading] = useState(false);
  const [convenioOk, setConvenioOk] = useState(false);
  const [error, setError] = useState(false);
  const selectTipoConvenio = useRef(null);
  const selectInstrumento = useRef(null);
  const [erroCadInstrumento, setErroCadInstrumento] = useState(false);
  const [erroCadTipoProjeto, setErroCadTipoProjeto] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalErro, setShowModalErro] = useState(false);


  function handleClose() {
    setTimeout(() => {
      setShowModal(false);
      setConvenioOk(true);

    }, 5000);

  }

  function handleCloseErro() {

    setShowModalErro(false);
    setConvenioOk(false);

  };

  var wTokenLocal = localStorage.getItem("token");
  function ConsultaToken() {
    //verifica se tem o token no localstorage
    if (wTokenLocal === undefined || wTokenLocal === null) {
      setLogin(true)
    }
  }
  useEffect(() => {
    ConsultaToken();
    // eslint-disable-next-line
  }, []);


  const getCadastroPF = async () => {
    try {
      const wtoken = "Bearer " + wTokenLocal;

      const config = {
        method: "GET",
        url:
          urlAPIlocal + "/api/cadastro?CodigoTipoCadastro=90",
        headers: {
          Authorization: wtoken,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);

      const data = response.data.objetoRetorno;

      setCoordenador(data);
      //setAcessoServidor(data);
      //setConvenente(data);
      //setOrdenador(data);
      //setExecutor(data);
      //setInterveniente(data);
      //console.log(data)

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCadastroPF();
    // eslint-disable-next-line
  }, []);


  const handleSelectCoordenador = (event) => {
    setCoordenadorEscolhido(event.target.value);
  };
  // const handleSelectServidor = (event) => {
  //   setAcessoServidorEscolhido(event.target.value);
  // };
  // const handleSelectConvenente = (event) => {
  //   setConvenenteEscolhido(event.target.value);;
  // };
  // const handleSelectOrdenador = (event) => {
  //   setOrdenadorEscolhido(event.target.value);
  // };
  // const handleSelectExecutor = (event) => {
  //   setExecutorEscolhido(event.target.value);
  // };
  // const handleSelectInterveniente = (event) => {
  //   setIntervenienteEscolhido(event.target.value);
  // };

  const getInstrumentos = async () => {
    try {
      const wtoken = "Bearer " + wTokenLocal;

      const config = {
        method: "GET",
        url:
          urlAPIlocal + "/api/Instrumento",
        headers: {
          Authorization: wtoken,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);

      const data = response.data.objetoRetorno;

      setInstrumentos(data);
      //console.log(data)

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInstrumentos();
    // eslint-disable-next-line
  }, []);

  const handleSelectTipoInstrumento = (event) => {
    setTipoInstrumentoEscolhido(event.target.value);
    //console.log(event.target.value);

    if (event.target.value === '999') {
      setIsModalInstrumentoOpen(true);
    } else {
      setIsModalInstrumentoOpen(false);
    }
  };

  const getTipoProjeto = async () => {
    try {
      const wtoken = "Bearer " + wTokenLocal;

      const config = {
        method: "GET",
        url:
          urlAPIlocal + "/api/TipoProjeto",
        headers: {
          Authorization: wtoken,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);

      const data = response.data.objetoRetorno;

      setTipoProjeto(data);
      //console.log(data)

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTipoProjeto();
    // eslint-disable-next-line
  }, []);

  const handleSelectTipoProjeto = (event) => {
    setTipoProjetoEscolhido(event.target.value);
    //console.log(tipoProjetoEscolhido)
    if (event.target.value === '999') {
      setIsModalTipoProjetoOpen(true);
    } else {
      setIsModalTipoProjetoOpen(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    //faz as validações dos inputs especificos antes de gravar
    //dataassinatura e datPublicação não obrigatoria, mas precisa enviar '01/01/1900'
    //if (!isValidDate(dataAssinatura)) {
    //  setDataAssinatura('01/01/1900');
    //console.log(dataAssinatura);
    // } else {
    // Aqui você pode fazer algo com a data válida
    //console.log(inputValue);
    // }
    //console.log(dataPubli);

    // if (!isValidDate(dataPubli)) {
    //   //setDataPubli('01/01/1900');
    //   console.log(dataPubli);
    //   console.log("entrou cima 219");
    //   //const dataPubliPadrao = dataPubli || '01/01/1900';

    // } else {
    //   // Aqui você pode fazer algo com a data válida
    //   //console.log(inputValue);
    //   console.log(dataPubli);
    //   console.log("entrou embaixo 224");
    //   //const dataPubliPadrao = dataPubli

    // }

    //valida se selecionou o tipo de projeto
    if (tipoProjetoEscolhido === "0") {
      //setError('Selecione o tipo do projeto');
      selectTipoConvenio.current.focus();
      return false;
    }

    //valida se selecionou o tipo de instrumento
    if (tipoInstrumentoEscolhido === "0") {
      //setError('Selecione o tipo do projeto');
      selectInstrumento.current.focus();
      return false;
    }


    GravaProjetoNovo();
  }

  function GravaProjetoNovo() {
    setGravando(true);
    var datHoje = new Date();
    var wtokenBearer = "Bearer " + localStorage.getItem("token");

    var data = JSON.stringify({
      "CodigoConvenio": 0,
      "NumeroConvenio": numeroProjeto,
      "Nome": nomeProjeto,
      "DataInicio": moment(dataInicio).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataExpiracao": moment(dataExpiracao).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "CodigoConvenente": 147, //convenenteEscolhido,
      "CodigoInterveniente": 0, //intervenienteEscolhido,
      "CodigoCoordenacaoPedagogia": coordenadorEscolhido,
      "CodigoExecutor": 147, //executorEscolhido,
      "CodigoCampus": 0,
      "CodigoDpcc": 0,
      "CodigoOrdenador": 147, //ordenadorEscolhido,
      "CodigoFinanceiro": 0,
      "Objetivo": objetivo,
      "Justificativa": detalhes,
      "DataAssinatura": moment(dataAssinatura).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataPublicacao": moment(dataPubli).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "Pasta": "",
      "Armario": "",
      "Gaveta": "",
      "NomeBanco": nomeBanco,
      "NumeroBanco": numeroBanco,
      "Pix": "0",
      "NumeroAgencia": agencia,
      "NumeroConta": numeroConta,
      "NomeConta": nomeConta,
      "DataCadastro": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataTramitacao": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataVigente": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataExtintoVencido": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "Status": 0,
      "Processo": 1,
      "DataPrestacaoContas": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataRecebimentoPrestacaoContas": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "TermoAditivo": 0,
      "DataAssinaturaTermoAditivo": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataProrrogacao": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "Tipoconvenio": tipoProjetoEscolhido,
      "Origem": cidade,
      "CodigoTecnico": 0, //acessoServidorEscolhido
      "Lei": leiPC,
      "Pc": periocidade,
      "Doa": true,
      "Prazofinal": prazoFinal,
      "Instrumento": tipoInstrumentoEscolhido

    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Convenio',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json'
      },
      data: data
    };
    //console.log(data)
    //console.log(dataAssinatura);
    //console.log(dataPubli);
    axios(config)

      .then(function (response) {

        //console.log(response.data)
        if (response.data.isOk === true) {
          // fez ao consumo sem erro
          // pega o codigo cadastradoprocess

          setGravando(false);
          setShowModal(true);
          //setConvenioOk(true);
          setwCodigoConvenioNovo(response.data.objetoRetorno.CodigoConvenio);
          //console.log(coordenadorEscolhido);
          //criar usuário area para o projeto, caso tenha selecionado o coordenador;
          if (coordenadorEscolhido !== 0) {
            GetCadastroCordenadorArea(response.data.objetoRetorno.CodigoCoordenacaoPedagogia, response.data.objetoRetorno.CodigoConvenio);
            // CriaNovoUsuarioArea();
          }


        } else {
          //deu algum erro tratar , colocar algum aviso
          setGravando(false);
          setShowModalErro(true);
          //console.log("entrou aqui 333")
          //console.log(response.data);
        }
      })
      .catch(error => {
        setGravando(false);
        console.error(error);
        setShowModalErro(true);
      });
  }

  function handleBlur() {
    if (nomeProjeto.length < 3) {
      setError('O nome tem que ter pelo menos 3 caracteres');
    } else {
      setError('');
    }
  }


  function isValidDate(dateString) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false; // Formato inválido
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // Data inválida (por exemplo, "fevereiro 30")
    return d.toISOString().slice(0, 10) === dateString;
  }

  function GetCadastroCordenadorArea(wCodigoCadastroArea, wCodigoConvenio) {

    //console.log('GetCadastroCordenadorArea chamada')

    const wtoken = "Bearer " + wTokenLocal;

    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Cadastro?CodigoCadastro=' + wCodigoCadastroArea + '&IsInclude=false',
      headers: {
        'Authorization': wtoken,
        'Content-Type': 'application/json'
      },

    };

    axios(config)

      .then(function (response) {
        //console.log(response);
        const data = response.data;
        if (data.isOk === true) {

          setNomeCadastroArea(data.objetoRetorno[0].Nome);
          //console.log(wCodigoConvenio);

          //consulta se já tem um usuário para aquele cadastro
          var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios?CodigoCadastro=' + wCodigoCadastroArea + '&IsInclude=false',
            headers: {
              'Authorization': wtoken,
              'Content-Type': 'application/json'
            },

          };

          axios(config)
            .then(function (response) {
              const dataUsuario = response.data.objetoRetorno;

              //Se sim, envia email 
              if (dataUsuario !== null) {
                var Assunto = "Bem vindo(a) ao Projeto '" + nomeProjeto + "'!"
                var Mensagem = "Bem vindo(a) ao Projeto <strong>'" + nomeProjeto + "'</strong>, " + data.objetoRetorno[0].Nome + ".<br></br>Agora você pode acessar o sistema com seu login e senha para fazer solicitações e gerenciar esse projeto.<br></br><br></br><strong>Link para acessar o sistema:</strong> https://sistemacontroll.app.br/";
                EnviaEmailArea(Assunto, Mensagem, wtoken, data.objetoRetorno[0].Email.Endereco);
              } else {
                //Se não, cria usuário
                CriaNovoUsuarioArea(wCodigoConvenio, data.objetoRetorno[0].Nome, data.objetoRetorno[0].Email.Endereco);
              }
            })
        } else {
          setConvenioOk(false);
        }
      }
      )
      .catch(function (error) {
        console.log(error);
        // se token inválido redir para login
        // if (error.response.status === 401) {
        //     setLogin(true);
        // }

      });
  }

  function CriaNovoUsuarioArea(wCodigoConvenio, nomeCadastroArea, EmailArea) {
    //console.log('CriaNovoUsuarioArea chamada')
    // Função para gerar a senha randomicamente
    function gerarSenhaRandomica() {
      const caracteresEspeciais = '@';
      const letrasMaiusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const letrasMinusculas = 'abcdefghijklmnopqrstuvwxyz';
      const numeros = '0123456789';

      let senha = '';
      let temNumero = false;
      let temCaracterEspecial = false;
      let temLetraMaiuscula = false;
      let temLetraMinuscula = false;

      while (senha.length < 8 || !(temNumero && temCaracterEspecial && temLetraMaiuscula && temLetraMinuscula)) {
        const tipoCaracter = Math.floor(Math.random() * 4);

        if (tipoCaracter === 0 && !temNumero) {
          senha += numeros[Math.floor(Math.random() * numeros.length)];
          temNumero = true;
        } else if (tipoCaracter === 1 && !temCaracterEspecial) {
          senha += caracteresEspeciais[Math.floor(Math.random() * caracteresEspeciais.length)];
          temCaracterEspecial = true;
        } else if (tipoCaracter === 2 && !temLetraMaiuscula) {
          senha += letrasMaiusculas[Math.floor(Math.random() * letrasMaiusculas.length)];
          temLetraMaiuscula = true;
        } else if (tipoCaracter === 3 && !temLetraMinuscula) {
          senha += letrasMinusculas[Math.floor(Math.random() * letrasMinusculas.length)];
          temLetraMinuscula = true;
        } else {
          const caracteres = caracteresEspeciais + letrasMaiusculas + letrasMinusculas + numeros;
          senha += caracteres[Math.floor(Math.random() * caracteres.length)];
        }
      }

      return senha;
    }

    // Gerar a senha randomicamente
    const senhaAcesso = gerarSenhaRandomica();
    const senhaAssinatura = gerarSenhaRandomica();

    const nomeCompleto = nomeCadastroArea;
    const primeiroNome = nomeCompleto.split(' ')[0];

    var wtokenBearer = "Bearer " + localStorage.getItem("token");
    var data = JSON.stringify({
      "CodigoUsuario": 0,
      "Login": primeiroNome + coordenadorEscolhido,
      "Senha": senhaAcesso,
      "CodigoCadastro": coordenadorEscolhido,
      "DataExpira": dataExpiracao,
      "CodigoConvenio": wCodigoConvenio,
      "CodigoPerfil": 37,
      "NumeroAcesso": 0,
      "DataUltimoAcesso": "1900-01-01T00:00:00.000Z",
      "Nome": nomeCadastroArea,
      "Ativo": true,
      "Logado": false,
      "IgnorarRateLimit": true,
      "SenhaAssinatura": senhaAssinatura
    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Usuarios',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json'
      },
      data: data
    };
    //console.log(data)
    axios(config)

      .then(function (response) {

        if (response.data.isOk === true) {
          //enviar email para fundação
          var Assunto = "Bem vindo(a) ao Sistema Fauscs!"
          var Mensagem = "Bem vindo(a) ao Sistema Fauscs!: " + nomeCadastroArea + "<br></br>Agora você pode acessar ao sistema para fazer solicitações e gerenciar seu projeto. <br></br>Aqui estão as informações necessárias para efetuar o acesso: <br></br><strong>Login:</strong> " + primeiroNome + wCodigoConvenio + "<br></br><strong>Senha de Acesso:</strong> " + senhaAcesso + "<br></br><strong>Senha de Assinatura: </strong>" + senhaAssinatura + "<br></br><br></br><strong>Link para acessar o sistema:</strong> https://sistemacontroll.app.br/";
          EnviaEmailArea(Assunto, Mensagem, wtokenBearer, EmailArea);
        } else {
          setConvenioOk(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function EnviaEmailArea(wAssunto, wMensagem, wtokenBearer, EmailArea) {
    //console.log(EmailArea);
    var data = JSON.stringify({
      "RemetenteEmail": "",
      "RemetenteNome": "",
      "CopiaOculta": ['fauscs@fauscs.org.br'],
      "destinatario": [EmailArea],
      "assunto": wAssunto,
      "corpo": wMensagem,
    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Email',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json',
      },
      data: data
    };
    //console.log(data)
    //console.log(config)
    axios(config)
      .then(response => {
        //console.log(response.data);
        // faça algo com a resposta da API
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleFechaGravando = (event) => {
    if (convenioOk === true) { setGravando(false) };
  }

  const closeAndResetModalInstrumento = () => {
    setIsModalInstrumentoOpen(false);
    setErroCadInstrumento(false); // Redefina o estado de erroCadInstrumento para false
    setNovoInstrumento(''); // Limpe o valor de novoInstrumento
    // Altere a chave do modal para recriá-lo ao ser reaberto
    //setModalInstrumentoKey(modalInstrumentoKey + 1);
  };

  const CadInstrumento = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await axios.get(
        urlAPIlocal + `/api/Instrumento?Nome=${novoInstrumento}&IsInclude=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.codigoRetorno > 0) {
        setLoading(false);
        setErroCadInstrumento(true);
        //console.log(response)
      } else {
        // Redefina o estado de erroCadInstrumento para false aqui
        setErroCadInstrumento(false);
        //console.log("erro pescado false");

        var wtokenBearer = "Bearer " + localStorage.getItem("token");
        var data = JSON.stringify({
          "CodInstrumento": 0,
          "Nome": novoInstrumento
        });

        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: urlAPIlocal + "/api/Instrumento",
          headers: {
            Authorization: wtokenBearer,
            "Content-Type": "application/json",
          },
          data: data,
        };

        try {
          const response = await axios(config);
          //console.log(response.data);

          if (response.data.isOk === true) {
            setLoading(false);
            closeAndResetModalInstrumento(); // Chame a função para fechar o modal e redefinir os valores
            getInstrumentos();
            setTipoInstrumentoEscolhido(response.data.objetoRetorno.CodInstrumento);
          } else {
            setLoading(false);
            setShowModalErro(true);
          }

        } catch (error) {
          console.error(error);
          setLoading(false);
          setShowModalErro(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setShowModalErro(true);
      console.log(error);
    }
  };

  const closeAndResetModalTipoProjeto = () => {
    setIsModalTipoProjetoOpen(false);
    setErroCadTipoProjeto(false); // Redefina o estado de erroCadTipoProjeto para false
    setNovoTipoProjeto(''); // Limpe o valor de novoInstrumento
    // Altere a chave do modal para recriá-lo ao ser reaberto
    //setModalInstrumentoKey(modalInstrumentoKey + 1);
  };

  const CadTipoProjeto = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await axios.get(
        urlAPIlocal + `/api/TipoProjeto?Nome=${novoTipoProjeto}&IsInclude=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.codigoRetorno > 0) {
        setLoading(false);
        setErroCadTipoProjeto(true);
        //console.log(response)
      } else {
        // Redefina o estado de erroCadTipoProjeto para false aqui
        setErroCadTipoProjeto(false);
        //console.log("erro pescado false");

        var wtokenBearer = "Bearer " + localStorage.getItem("token");
        var data = JSON.stringify({
          "CodTipoProjeto": 0,
          "Nome": novoTipoProjeto
        });

        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: urlAPIlocal + "/api/TipoProjeto",
          headers: {
            Authorization: wtokenBearer,
            "Content-Type": "application/json",
          },
          data: data,
        };

        try {
          const response = await axios(config);
          //console.log(response.data);

          if (response.data.isOk === true) {
            setLoading(false);
            closeAndResetModalTipoProjeto(); // Chame a função para fechar o modal e redefinir os valores
            getTipoProjeto();
            setTipoProjetoEscolhido(response.data.objetoRetorno.CodTipoProjeto);
          } else {
            setLoading(false);
            setShowModalErro(true);
          }

        } catch (error) {
          console.error(error);
          setLoading(false);
          setShowModalErro(true);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShowModalErro(true);
    }
  };

  return (
    <div>

      <Menu />
      <div> <button className={StyleForm.voltar}>  <Link to="/ListProjetos" ><IoMdArrowBack /> Voltar</Link> </button> </div>
      <div className={StyleForm.header}>
        <h1>Cadastro de Projetos</h1>
        <p>Preencha o formulário para cadastrar um novo projeto</p>
      </div>
      {error && <div align="center">{error}</div>}
      <div className={StyleForm.container}>
        <Form className={StyleForm.form} onSubmit={handleSubmit}>
          <FormGroup className={StyleForm.form_group}>
            <Row>
              <Col md={3}>
                <Form.Label className={StyleForm.form_group_label}>Numero do Convênio</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="numconvenio" onChange={(event) => setNumeroProjeto(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Título do Convênio</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="nomeconvenio" onChange={(event) => setNomeProjeto(event.target.value)} onBlur={handleBlur} required />

              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Cidade de Execução</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="cidade" onChange={(event) => setCidade(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Tipo do Projeto</Form.Label>
                <Form.Select ref={selectTipoConvenio} className={StyleForm.form_control} name="tipoProjeto" id="tipoProjeto" value={tipoProjetoEscolhido} onChange={handleSelectTipoProjeto} required >
                  <option value="0">Selecione uma opção</option>
                  {tipoProjeto.length > 0 &&
                    tipoProjeto.map((opcao) => (
                      <option key={opcao.CodTipoProjeto} value={opcao.CodTipoProjeto}>{opcao.Nome}</option>
                    ))}
                  <option value='999'>Outro</option>
                </Form.Select>

              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Tipo de Instrumento</Form.Label>
                <Form.Select ref={selectInstrumento} className={StyleForm.form_control} name="tipointrumento" id="tipointrumento" value={tipoInstrumentoEscolhido} onChange={handleSelectTipoInstrumento} required>
                  <option value="0">Selecione uma opção</option>
                  {instrumentos.length > 0 &&
                    instrumentos.map((opcao) => (
                      <option key={opcao.CodInstrumento} value={opcao.CodInstrumento}>{opcao.Nome}</option>
                    ))}
                  <option value='999'>Outro</option>
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Lei para Prestação de Contas</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="leiPC" onChange={(event) => setLeiPC(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Periocidade da Prestação de Contas</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="periodoPC" onChange={(event) => setPeriodicidade(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Prazo Final em Dias(PC) - apenas números</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="prazofinal" onChange={(event) => setPrazoFinal(event.target.value)} required />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Data Início*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="date" name="dataInicio" onChange={(event) => setDataInicio(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Data de Expiração*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="date" name="dataExpiracao" onChange={(event) => setDataExpiracao(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Data da Assinatura</Form.Label>
                <Form.Control className={StyleForm.form_control} type="date" name="assinatura" onChange={(event) => setDataAssinatura(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Data da Publicação</Form.Label>
                <Form.Control className={StyleForm.form_control} type="date" name="publicacao" onChange={(event) => setDataPubli(event.target.value)} />
              </Col>
            </Row>
          </FormGroup>

          <div className={StyleForm.form_group_textarea}>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Detalhes do Projeto</Form.Label>
                <textarea className={StyleForm.form_control_textarea} name="detalhes" id="detalhes" placeholder="Descreva o projeto" onChange={(event) => setDetalhes(event.target.value)} required ></textarea>
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Objetivo</Form.Label>
                <textarea className={StyleForm.form_control_textarea} name="objetivo" id="objetivo" placeholder="Descreva o objetivo do projeto" onChange={(event) => setObjetivo(event.target.value)} required ></textarea>
              </Col>
            </Row>
          </div>

          <FormGroup className={StyleForm.form_group}>
            <h1 style={{ fontSize: '14px', color: '#CD6155', marginTop: '-1em' }}>Caso necessário, o Coordenador Área pode ser selecionado após a criação do projeto. Porém para seleciona-lo na Ficha Técnica é preciso que o mesmo tenha cadastro no sistema como Pessoa Física - Usuário (você pode fazer isso em "Cadastro Geral").</h1>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Coordenador Área</Form.Label>
                <Form.Select className={StyleForm.form_control} name="coordenador" id="coordenador" value={coordenadorEscolhido} onChange={handleSelectCoordenador}>
                  <option value="0">Selecione um coordenador</option>

                  {coordenador.length > 0 &&
                    coordenador.map((opcao) => (
                      <option key={opcao.CodigoCadastro} value={opcao.CodigoCadastro}>
                        {opcao.Nome}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              {/* <Col>
                <Form.Label className={StyleForm.form_group_label}>Acesso ao Servidor Técnico</Form.Label>
                <Form.Select className={StyleForm.form_control} name="servidor" id="servidor" value={acessoServidorEscolhido} onChange={handleSelectServidor}>

                  <option value="">Selecione um servidor</option>

                  {acessoServidor.length > 0 &&
                    acessoServidor.map((opcao) => (
                      <option key={opcao.Nome} value={opcao.CodigoCadastro}>
                        {opcao.Nome}
                      </option>
                    ))}
                </Form.Select>
              </Col> */}
              {/* <Col>
                    <Form.Label className={StyleForm.form_group_label}>Convenente</Form.Label>
                <Form.Select className={StyleForm.form_control} name="convenente" id="convenente" value={convenenteEscolhido} onChange={handleSelectConvenente}>
                
                <option value="">Selecione um convenente</option>
               
                {convenente.length > 0 &&
          convenente.map((opcao) => (
            <option key={opcao.Nome} value={opcao.CodigoCadastro}>
              {opcao.Nome}
            </option>
          ))}
                </Form.Select>
                    </Col> */}
            </Row>
            <Row>
              {/* <Col>
                    <Form.Label className={StyleForm.form_group_label}>Ordenador de Despesas</Form.Label>
                <Form.Select className={StyleForm.form_control} name="ordenador" id="ordenador" value={ordenadorEscolhido} onChange={handleSelectOrdenador}>
                
                <option value="">Selecione um ordenador</option>
                
                {ordenador.length > 0 &&
          ordenador.map((opcao) => (
            <option key={opcao.Nome} value={opcao.CodigoCadastro}>
              {opcao.Nome}
            </option>
          ))}
                </Form.Select>
                    </Col> */}
              {/* <Col>
                    <Form.Label className={StyleForm.form_group_label}>Executor</Form.Label>
                <Form.Select className={StyleForm.form_control} name="executor" id="executor" value={executorEscolhido} onChange={handleSelectExecutor}>
                
                <option value="">Selecione um executor</option>
                
                {executor.length > 0 &&
          executor.map((opcao) => (
            <option key={opcao.Nome} value={opcao.CodigoCadastro}>
              {opcao.Nome}
            </option>
          ))}
                </Form.Select>
                    </Col> */}
              {/* <Col>
                    <Form.Label className={StyleForm.form_group_label}>Interveniente</Form.Label>
                <Form.Select className={StyleForm.form_control} name="interveniente" id="interveniente" value={intervenienteEscolhido} onChange={handleSelectInterveniente}>
                
                <option value="">Selecione um interveniente</option>
                
                {interveniente.length > 0 &&
          interveniente.map((opcao) => (
            <option key={opcao.Nome} value={opcao.CodigoCadastro}>
              {opcao.Nome}
            </option>
          ))}
                </Form.Select>
                    </Col> */}
            </Row>

          </FormGroup>

          <FormGroup className={StyleForm.form_group}>

            <h5>Informações Bancárias</h5>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Nome do Banco</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="banco" onChange={(event) => setNomeBanco(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Nome da Conta</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="nomeConta" onChange={(event) => setNomeConta(event.target.value)} />
              </Col>

            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Agencia</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="agencia" onChange={(event) => setAgencia(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Numero da Conta e Dígito</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="numConta" onChange={(event) => setNumeroConta(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Numero do Banco</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="numbanco" onChange={(event) => setNumeroBanco(event.target.value)} />
              </Col>
            </Row>
            <Row>
              <button className={StyleForm.btn} type="submit">
                <span>Criar Projeto</span>
                <MdCreate />
              </button>
            </Row>
          </FormGroup>

        </Form>

        {isModalInstrumentoOpen &&
          <Modal show={isModalInstrumentoOpen} className={StyleForm.Modal} centered>
            <Modal.Header className={StyleForm.Modal_header}>
              <h3>Cadastro de Instrumento</h3>
            </Modal.Header>
            <Modal.Body className={StyleForm.Modal_body}>

              {erroCadInstrumento && <h6>Esse tipo de instrumento já foi cadastrado, verifique a lista.</h6>}
              {showModalErro && <h6>Ops! Algo deu errado, por favor tente novamente.</h6>}

              <Form onSubmit={CadInstrumento}>
                <Row>
                  <Col>
                    <Form.Label className={StyleForm.label_modal}>Tipo de Instrumento:</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control className={StyleForm.form_control} type="text" name="instrumento" value={novoInstrumento} onChange={(event) => setNovoInstrumento(event.target.value)} />
                  </Col>
                </Row>

                <button type="submit" className={StyleForm.btn_salvar}> Salvar </button>
                <button type="button" className={StyleForm.btn_cancelar} onClick={closeAndResetModalInstrumento}> Cancelar </button>
              </Form>
            </Modal.Body>
          </Modal>
        }

        {isModalTipoProjetoOpen &&
          <Modal show={isModalTipoProjetoOpen} className={StyleForm.Modal} centered>
            <Modal.Header className={StyleForm.Modal_header}>
              <h3>Cadastro de Tipos Projetos</h3>
            </Modal.Header>
            <Modal.Body className={StyleForm.Modal_body}>

              {erroCadTipoProjeto && <h6>Esse tipo de projeto já foi cadastrado, verifique a lista.</h6>}
              {showModalErro && <h6>Ops! Algo deu errado, por favor tente novamente.</h6>}

              <Form onSubmit={CadTipoProjeto}>
                <Row>
                  <Col>
                    <Form.Label className={StyleForm.label_modal}>Tipo de Projeto:</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control className={StyleForm.form_control} type="text" name="tipoProjeto" value={novoTipoProjeto} onChange={(event) => setNovoTipoProjeto(event.target.value)} />
                  </Col>
                </Row>

                <button type="submit" className={StyleForm.btn_salvar}> Salvar </button>
                <button type="button" className={StyleForm.btn_cancelar} onClick={closeAndResetModalTipoProjeto}> Cancelar </button>
              </Form>
            </Modal.Body>
          </Modal>
        }

        <Modal show={loading} className={StyleForm.Modal} centered >
          <Modal.Body className={StyleForm.Modal_body}>
            <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
          </Modal.Body>
        </Modal>


        <Modal show={gravando} onLoad={handleFechaGravando} className={StyleForm.Modal} centered>
          <Modal.Header className={StyleForm.Modal_header}>
            <h3>Gravando...</h3>
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <img src={loadingGif}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
          <Modal.Header className={StyleForm.Modal_header}>
            <img src={check_circle} alt="ok"></img>
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <p>Cadastro realizado com sucesso!</p>
            <p>Agora você pode cadastrar os Concedentes desse projeto.</p>
          </Modal.Body>
        </Modal>

        <Modal show={showModalErro} className={StyleForm.Modal} centered >
          <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
            <h3>Ops!</h3>
            <ImSad2 />
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <p>Algo deu errado, cheque os campos e tente novamente</p>
          </Modal.Body>
        </Modal>

      </div>
      {login && (<Navigate to="/" replace={true} />)}
      {convenioOk && (<Navigate to={`/Concedente/${wCodigoCadastroNovo}`} replace={true} />)}

    </div>
  )
}
export default CadProjeto