import Style from "./Usuarios.module.css";
import Menu from "../Layout/Menu";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import urlAPIlocal from '../../global';
import { Link } from "react-router-dom";
import { AiOutlineUserAdd } from "react-icons/ai";
//import { RiEditLine } from "react-icons/ri";
import { Pagination } from 'react-bootstrap';
import { Navigate } from "react-router";
import { FaSearch } from "react-icons/fa";
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';

function Usuarios() {

    var wTokenLocal = localStorage.getItem("token");
    const wtoken = "Bearer " + wTokenLocal;

    const [loading, setLoading] = useState(true);
    const [usuarios, setUsuarios] = useState([]);
    const [login, setLogin] = useState(false);

    // const [emailUsuario, setEmailUsuario] = useState('');
    // const [codigoCadastro, setCodigoCadastro] = useState(null);

    const [busca, setBusca] = useState('');
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line
    const [itemsPerPage, setItemsPerPage] = useState(8);

    const [showModalNovaSenhaAcesso, setShowModalNovaSenhaAcesso] = useState(false);
    //const [showModalErroPT, setShowModalErroPT] = useState(false);


    useEffect(() => {
        const handleCloseModal = () => {
            setTimeout(() => {
                setShowModalNovaSenhaAcesso(false);
            }, 3000);
        };

        if (showModalNovaSenhaAcesso) {
            handleCloseModal();
        }
    }, [showModalNovaSenhaAcesso]);

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    // Calculate the total number of pages
    const items = [];
    if (usuarios && usuarios !== null) {

        var totalPages = 0;
        if (usuarios.length > 0 && (totalPages = Math.ceil(usuarios.length / itemsPerPage)));

        if (itensFiltrados.length > 0 && (totalPages = Math.ceil(itensFiltrados.length / itemsPerPage)));



        // Calculate the indexes of the first and last items of the current page
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Get the items of the current page
        var currentItems = usuarios.slice(indexOfFirstItem, indexOfLastItem);


        if (itensFiltrados.length > 0 && (currentItems = itensFiltrados.slice(indexOfFirstItem, indexOfLastItem)));


        // Generate the list of items of pagination
        //const items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => setCurrentPage(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
    }


    const handleSearch = (event) => {
        const value = event.target.value;
        //getCadastro();
        const filteredItems = usuarios.filter((item) =>
            item.Nome.toLowerCase().includes(value.toLowerCase())
        );
        setItensFiltrados(filteredItems);
        setBusca(value);
        //console.log(filteredItems);
    };

    const getUsuarios = async (event) => {

        //const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios?IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setUsuarios(data.objetoRetorno);
                setLoading(false);
                //console.log(data);
            }
            )
            .catch(function (error) {
                console.log(error);
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getUsuarios();
        // eslint-disable-next-line
    }, []);

    function GerarSenhaAcesso(CodigoUsuario, Login, CodCadastro) {
        setLoading(true);

        var senhaAcesso; // Mova a declaração para fora do escopo da função then
        var emailUsuario; // Declare emailUsuario fora dos blocos then

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/cadastro/' + CodCadastro,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };

        axios(config)
            .then(function (response) {
                //setEmailUsuario(response.data.objetoRetorno.Email.Endereco);
                //console.log(response.data.objetoRetorno.Email.Endereco);
                emailUsuario = response.data.objetoRetorno.Email.Endereco;

                // Chama a função gerarSenhaRandomica após a obtenção do e-mail
                senhaAcesso = gerarSenhaRandomica();

                var data = JSON.stringify({
                    "Senha": senhaAcesso
                });

                var config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Usuarios/AlterarSenhaAcesso/' + CodigoUsuario,
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                return axios(config);
            })
            .then(function (response) {
                if (response.data.isOk === true) {
                    var Assunto = "Senha de Acesso Alterada - Sistema FAUSCS"
                    var Mensagem = "Prezado Usuário, <br></br>Sua senha de acesso ao sistema FAUSCS foi alterada por um perfil gestor. <br></br>Aqui estão as informações necessárias para efetuar o acesso: <br></br><strong>Login:</strong> " + Login + "<br></br><strong>Nova Senha de Acesso:</strong> " + senhaAcesso + "<br></br><br></br><strong>Link para acessar o sistema:</strong> https://sistemacontroll.app.br/";

                    //console.log(emailUsuario)
                    EnviaEmailUsuario(Assunto, Mensagem, emailUsuario);

                    setLoading(false);

                } else {
                    // Tratar erro, colocar algum aviso
                    // setCadastroOk(false);
                }
            })
            .catch(function (error) {
                console.error(error);
            });

        function gerarSenhaRandomica() {
            const caracteresEspeciais = '@';
            const letrasMaiusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const letrasMinusculas = 'abcdefghijklmnopqrstuvwxyz';
            const numeros = '0123456789';

            let senha = '';
            let temNumero = false;
            let temCaracterEspecial = false;
            let temLetraMaiuscula = false;
            let temLetraMinuscula = false;

            while (senha.length < 8 || !(temNumero && temCaracterEspecial && temLetraMaiuscula && temLetraMinuscula)) {
                // Verifica se a senha atingiu o tamanho desejado antes de adicionar mais caracteres
                if (senha.length < 8) {
                    const tipoCaracter = Math.floor(Math.random() * 4);

                    if (tipoCaracter === 0 && !temNumero) {
                        senha += numeros[Math.floor(Math.random() * numeros.length)];
                        temNumero = true;
                    } else if (tipoCaracter === 1 && !temCaracterEspecial) {
                        senha += caracteresEspeciais[Math.floor(Math.random() * caracteresEspeciais.length)];
                        temCaracterEspecial = true;
                    } else if (tipoCaracter === 2 && !temLetraMaiuscula) {
                        senha += letrasMaiusculas[Math.floor(Math.random() * letrasMaiusculas.length)];
                        temLetraMaiuscula = true;
                    } else if (tipoCaracter === 3 && !temLetraMinuscula) {
                        senha += letrasMinusculas[Math.floor(Math.random() * letrasMinusculas.length)];
                        temLetraMinuscula = true;
                    } else {
                        const caracteres = caracteresEspeciais + letrasMaiusculas + letrasMinusculas + numeros;
                        senha += caracteres[Math.floor(Math.random() * caracteres.length)];
                    }
                } else {
                    // Se a senha atingiu o tamanho desejado, saia do loop
                    break;
                }
            }

            return senha;
        }
    }

    function GerarSenhaAssinatura(CodigoUsuario, Login, CodCadastro) {
        setLoading(true);

        var senhaAssinatura; // Mova a declaração para fora do escopo da função then
        var emailUsuario; // Declare emailUsuario fora dos blocos then

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/cadastro/' + CodCadastro,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };

        axios(config)
            .then(function (response) {
                //setEmailUsuario(response.data.objetoRetorno.Email.Endereco);
                //console.log(response.data.objetoRetorno.Email.Endereco);
                emailUsuario = response.data.objetoRetorno.Email.Endereco;

                // Chama a função gerarSenhaRandomica após a obtenção do e-mail
                senhaAssinatura = gerarSenhaAssinaturaRandomica();

                var data = JSON.stringify({
                    "SenhaAssinatura": senhaAssinatura
                });

                var config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Usuarios/AlterarSenhaAssinatura/' + CodigoUsuario,
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                return axios(config);
            })
            .then(function (response) {
                if (response.data.isOk === true) {
                    var Assunto = "Senha de Acesso Alterada - Sistema FAUSCS"
                    var Mensagem = "Prezado Usuário, <br></br>Sua senha de assinatura no sistema FAUSCS foi alterada por um perfil gestor. <br></br>Aqui estão as informações necessárias para efetuar o acesso: <br></br><strong>Login:</strong> " + Login + "<br></br><strong>Nova Senha de Assinatura:</strong> " + senhaAssinatura + "<br></br><br></br><strong>Link para acessar o sistema:</strong> https://sistemacontroll.app.br/";

                    console.log(emailUsuario)
                    EnviaEmailUsuario(Assunto, Mensagem, emailUsuario);
                } else {
                    // Tratar erro, colocar algum aviso
                    // setCadastroOk(false);
                }
            })
            .catch(function (error) {
                console.error(error);
            });

        function gerarSenhaAssinaturaRandomica() {
            const caracteresEspeciais = '@';
            const letrasMaiusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const letrasMinusculas = 'abcdefghijklmnopqrstuvwxyz';
            const numeros = '0123456789';

            let senha = '';
            let temNumero = false;
            let temCaracterEspecial = false;
            let temLetraMaiuscula = false;
            let temLetraMinuscula = false;

            while (senha.length < 8 || !(temNumero && temCaracterEspecial && temLetraMaiuscula && temLetraMinuscula)) {
                // Verifica se a senha atingiu o tamanho desejado antes de adicionar mais caracteres
                if (senha.length < 8) {
                    const tipoCaracter = Math.floor(Math.random() * 4);

                    if (tipoCaracter === 0 && !temNumero) {
                        senha += numeros[Math.floor(Math.random() * numeros.length)];
                        temNumero = true;
                    } else if (tipoCaracter === 1 && !temCaracterEspecial) {
                        senha += caracteresEspeciais[Math.floor(Math.random() * caracteresEspeciais.length)];
                        temCaracterEspecial = true;
                    } else if (tipoCaracter === 2 && !temLetraMaiuscula) {
                        senha += letrasMaiusculas[Math.floor(Math.random() * letrasMaiusculas.length)];
                        temLetraMaiuscula = true;
                    } else if (tipoCaracter === 3 && !temLetraMinuscula) {
                        senha += letrasMinusculas[Math.floor(Math.random() * letrasMinusculas.length)];
                        temLetraMinuscula = true;
                    } else {
                        const caracteres = caracteresEspeciais + letrasMaiusculas + letrasMinusculas + numeros;
                        senha += caracteres[Math.floor(Math.random() * caracteres.length)];
                    }
                } else {
                    // Se a senha atingiu o tamanho desejado, saia do loop
                    break;
                }
            }

            return senha;
        }
    }

    function EnviaEmailUsuario(wAssunto, wMensagem, emailUsuarioParam) {
        //console.log(emailUsuarioParam);
        var data = JSON.stringify({
            "RemetenteEmail": "",
            "RemetenteNome": "",
            "CopiaOculta": ['fauscs@fauscs.org.br'],
            "destinatario": [emailUsuarioParam],
            "assunto": wAssunto,
            "corpo": wMensagem,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Email',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response);
                setLoading(false);
                setShowModalNovaSenhaAcesso(true);
            })
            .catch(error => {
                console.error(error);
            });
    }


    return (
        <>
            <Menu />
            <div className={Style.page}>
                <div className={Style.container}>
                    <div className={Style.header}>

                        <h4>USUÁRIOS</h4>

                        <div style={{ width: '20%' }}></div>
                        <label><FaSearch /></label>
                        <input type="text" value={busca} onChange={handleSearch} />
                        <div style={{ width: '20%' }}></div>

                        <button className={Style.novo_usuario}>
                            <Link to="/CadUsuario">Novo Usuário <AiOutlineUserAdd /></Link>
                        </button>
                    </div>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ fontWeight: '600', color: '#34495E', fontSize: '16px', width: '40%' }}>NOME</th>
                                <th style={{ fontWeight: '600', color: '#34495E', fontSize: '16px', width: '20%' }}>LOGIN</th>
                                {/* <th style={{ fontWeight: '600', color: '#34495E', fontSize: '16px' }}>SENHA</th> */}
                                <th style={{ fontWeight: '600', color: '#34495E', fontSize: '16px' }}>PERFIL</th>
                                <th style={{ fontWeight: '600', color: '#34495E', fontSize: '16px' }}>ATIVO</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>


                            {usuarios &&
                                (itensFiltrados.length > 0 ?
                                    (
                                        currentItems.map((user) => (
                                            <tr key={user.CodigoUsuario}>
                                                <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Nome}</td>
                                                <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Login}</td>
                                                {/* <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Senha}</td> */}
                                                <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Perfil.Nome}</td>
                                                <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Ativo ? <p className={Style.ativo}>Sim</p> : <p className={Style.nao_ativo}>Não</p>}</td>
                                                {/* <td><button type="button" className={Style.btn_edit}>
                                                    <Link to={`/editUsuario/${user.CodigoUsuario}`}> <RiEditLine title="Editar" /> </Link></button></td> */}
                                                <td style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <button type="button" onClick={() => GerarSenhaAcesso(user.CodigoUsuario, user.Login, user.CodigoCadastro)} className={Style.btn_senhaAcesso}>Gerar Nova Senha de Acesso</button>
                                                    <button type="button" onClick={() => GerarSenhaAssinatura(user.CodigoUsuario, user.Login, user.CodigoCadastro)} className={Style.btn_senhaAcesso}>Gerar Nova Senha de Assinatura</button>
                                                </td>
                                            </tr>
                                        ))
                                    ) :
                                    (
                                        currentItems.map((user) => (
                                            <tr key={user.CodigoUsuario}>
                                                <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Nome}</td>
                                                <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Login}</td>
                                                {/* <td style={{ color: '#5D6D7E', fontSize: '14px' }}>**********</td> */}
                                                <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Perfil.Nome}</td>
                                                <td style={{ color: '#5D6D7E', fontSize: '14px' }}>{user.Ativo ? <p className={Style.ativo}>Sim</p> : <p className={Style.nao_ativo}>Não</p>}</td>
                                                {/* <td><button type="button" className={Style.btn_edit}>
                                                    <Link to={`/editUsuario/${user.CodigoUsuario}`}> <RiEditLine title="Editar" /> </Link></button></td> */}
                                                <td style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <button type="button" onClick={() => GerarSenhaAcesso(user.CodigoUsuario, user.Login, user.CodigoCadastro)} className={Style.btn_senhaAcesso}>Gerar Nova Senha de Acesso</button>
                                                    <button type="button" onClick={() => GerarSenhaAssinatura(user.CodigoUsuario, user.Login, user.CodigoCadastro)} className={Style.btn_senhaAcesso}>Gerar Nova Senha de Assinatura</button>
                                                </td>
                                            </tr>
                                        ))
                                    ))

                            }



                            {/* {usuarios.map((user) => (
                                <tr key={user.CodigoUsuario}>
                                    <td style={{ color: '#5D6D7E', fontSize:'14px'}}>{user.Nome}</td>
                                    <td style={{ color: '#5D6D7E', fontSize:'14px'}}>{user.Login}</td>
                                    <td style={{ color: '#5D6D7E', fontSize:'14px'}}>{user.Senha}</td>
                                    <td style={{ color: '#5D6D7E', fontSize:'14px'}}>{user.Perfil.Nome}</td>
                                    <td style={{ color: '#5D6D7E', fontSize:'14px'}}>{user.Ativo ? <p className={Style.ativo}>Sim</p> : <p className={Style.nao_ativo}>Não</p>}</td>
                                    <td><button type="button" className={Style.btn_edit}>
                                <Link to={`/editUsuario/${user.CodigoUsuario}`}> <RiEditLine title="Editar" /> </Link></button></td>
                                </tr>
                            ))} */}
                        </tbody>
                    </Table>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 auto' }}>
                        {/* Display the pagination */}
                        <Pagination>{items}</Pagination>
                    </div>
                </div>

                <Modal show={loading} className={Style.Modal} centered >
                    <Modal.Body className={Style.Modal_body}>
                        <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalNovaSenhaAcesso} onHide={() => setShowModalNovaSenhaAcesso(false)} className={Style.Modal} centered >
                    <Modal.Body className={Style.Modal_body}>
                        Uma nova senha de acesso foi gerada e enviada via email ao usuário!
                    </Modal.Body>
                </Modal>

                {login && (<Navigate to="/" replace={true} />)}
            </div>
        </>
    )
}
export default Usuarios