import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import axios from "axios";
import urlAPIlocal from '../../global';
import Table from 'react-bootstrap/Table';
import moment from "moment";
import Cabecalho from "../img/Cabecalho.png";
import { Navigate } from "react-router-dom";

function FluxoDoa() {

    const [projetos, setProjetos] = useState([]);
    var wToken = 'Bearer ' + localStorage.getItem("token");
    //const [valoresProjetos, setValoresProjetos] = useState([]);

    const [login, setLogin] = useState(false);
    var wTokenLocal = localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    const getProjeto = async (event) => {

        var data = JSON.stringify({
            "CodigoConvenio": 0,
            "Datainicial": "2023-11-06T13:02:53.648Z",
            "Datafinal": "2023-11-06T13:02:53.648Z"
        });
        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Convenio/FinanceiroTodos?IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(config);
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjetos(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);

    /* function PegaValoresProjeto(wCodConvenio) */



    return (
        <div style={{ minHeight: '120vh', backgroundColor: '#F8F9F9' }}>

            <div style={{ minHeight: '120vh', backgroundColor: '#fff', margin: '0 auto', padding: '0.5em', display: 'flex', flexDirection: "column" }}>

                <div style={{ textAlign: 'center', marginBottom: '2em' }}>
                    <img src={Cabecalho} style={{ width: '350px', display: 'inline-block', marginBottom: '1em' }} alt="Cabeçalho" />
                    <h5 style={{ color: '#273746', margin: '1em', fontSize: '18px' }}>Relatório Fluxo Doa Projetos</h5>
                </div>

                {/* <p style={{ color: '#566573', paddingLeft: '1.5em', fontWeight: '600', fontSize: '16px' }}>Projeto nº {projeto.NumeroConvenio} - {projeto.Nome}</p> */}

                <Table striped bordered style={{ marginBottom: '.3em', borderCollapse: 'collapse', width: '95%', margin: '0 auto' }}>
                    <thead style={{ borderBottom: '2px solid #154360', borderTop: '1px solid #fff', borderRight: '1px solid #fff', borderLeft: '1px solid #fff', fontWeight: '600' }}>
                        <tr style={{ color: '#fff', fontSize: '14px', backgroundColor: '#566573' }}>
                            <th style={{ textAlign: 'center' }}>Código</th>
                            <th style={{ width: '300px' }}>Projeto</th>
                            <th style={{ textAlign: 'center' }}>Total programado Doa</th>
                            <th style={{ textAlign: 'center' }}>Repasses</th>
                            <th style={{ textAlign: 'center' }}>Doa Sobre Repasses</th>
                            <th style={{ textAlign: 'center' }}>Valor Pago Doa</th>
                            <th style={{ textAlign: 'center' }}>Saldo Financeiro</th>
                            <th style={{ textAlign: 'center' }}>Saldo Doa</th>
                        </tr>
                    </thead>


                    <tbody style={{ backgroundColor: '#FFFFFF', color: '#2C3E50', fontSize: '12px' }}>

                        {projetos !== null && (
                            projetos.map((projeto) => (
                                <tr key={projeto.CodigoBanco}>
                                    <td align="center">{projeto.CodigoConvenio}</td>
                                    <td align="left">{projeto.Nome}</td>
                                    <td align="right">{projeto.Financeiro.TotDoaProgramado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td align="right">{projeto.Financeiro.TotRepasses.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td align="right">{(projeto.Financeiro.TotRepasses * 0.10).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td align="right">{projeto.Financeiro.TotDoaPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td align="right">{projeto.Financeiro.SaldoFinanceiro.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td align="right">{(projeto.Financeiro.TotDoaProgramado - projeto.Financeiro.TotDoaPago).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            ))
                        )}
                    </tbody>



                </Table>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #154360', borderTop: '1px solid #154360', color: '#fff', fontSize: '14px', width: '95%', margin: '0 auto', backgroundColor: '#566573' }}>
                    {/* <p style={{ fontWeight: '600', margin: '0.3em' }}>TOTAL DE REPASSES:</p> */}
                    {/* <p style={{ fontWeight: '600', margin: '0.3em' }}>{totalRepasses.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p> */}
                </div>

                <div style={{ margin: 'auto 0 0' }}>
                    {/*  <p style={{ color: '#566573', paddingLeft: '1.5em', fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Data de Emissão do Relatório: {moment(dataEmissao).format("DD/MM/yyyy")}</p> */}
                </div>
            </div>

            {login && (<Navigate to="/" replace={true} />)}
        </div>

    );
}

export default FluxoDoa;