import React, { useState, useEffect } from 'react';
import { Col, Form, FormGroup, Row } from "react-bootstrap"
import { FormLabel, FormControl, FormSelect } from 'react-bootstrap';
import Menu from "../Layout/Menu"
import StyleForm from './CadUsuario.module.css'
import axios from 'axios';
import { BiSolidError } from 'react-icons/bi';
import { MdDone } from 'react-icons/md';
import { Modal } from 'react-bootstrap';
import loadingGif from "../img/icons8-spinning-circle.gif";
import check_circle from "../img/check_circle.gif";
import { Navigate } from "react-router-dom";
import moment from 'moment';
import urlAPIlocal from '../../global';

function CadUsuario() {

    var wtokenBearer = "Bearer " + localStorage.getItem("token")

    const [cadastro, setCadastro] = useState([]);
    const [nome, setNome] = useState('');
    const [emailUsuario, setEmailUsuario] = useState('');
    const [loginUsuario, setLoginUsuario] = useState('');
    const [senhaUsuario, setSenhaUsuario] = useState('');
    const [codigoCadastro, setCodigoCadastro] = useState(0);
    const [perfil, setPerfil] = useState([]);
    const [perfilSelecionado, setPerfilSelecionado] = useState(0);
    const [usuarioExistente, setUsuarioExistente] = useState(false);
    const [projetos, setProjetos] = useState([]);
    const [projetoSelecionado, setProjetoSelecionado] = useState(0);
    const [validadeUsuario, setValidadeUsuario] = useState('');
    const [validadeUsuarioArea, setValidadeUsuarioArea] = useState('');

    // const [isValidLength, setValidLength] = useState(false);
    // const [hasUppercase, setHasUppercase] = useState(false);
    // const [hasLowercase, setHasLowercase] = useState(false);
    // const [hasDigit, setHasDigit] = useState(false);
    // const [hasSpecialChar, setHasSpecialChar] = useState(false);


    const [showModal, setShowModal] = useState(false);
    const [redir, setRedir] = useState(false);

    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(false);

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        var wTokenLocal = localStorage.getItem("token");
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    function handleClose() {
        setTimeout(() => {
            setShowModal(false);
            setRedir(true);
        }, 2000);

    }

    const getCadastro = async (CodCadastro) => {

        // Se CodCadastro for fornecido, inclua na URL; caso contrário, deixe como está
        const urlComCodCadastro = CodCadastro
            ? `${urlAPIlocal}/api/Cadastro?CodigoTipoCadastro=90&CodigoCadastro=${CodCadastro}&IsInclude=false`
            : `${urlAPIlocal}/api/Cadastro?CodigoTipoCadastro=90&IsInclude=false`;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlComCodCadastro,
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setCadastro(data.objetoRetorno);
                console.log(data);
                setEmailUsuario(data.objetoRetorno[0].Email.Endereco);
            }
            )
            .catch(function (error) {
                console.log(error);
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getCadastro();
        // eslint-disable-next-line
    }, []);

    const handleCadastroEscolhido = (event) => {
        setNome(event.target.value);
        setCodigoCadastro(event.target.selectedOptions[0].getAttribute("data-key"));
        getCadastro(event.target.selectedOptions[0].getAttribute("data-key"));
    }

    const getPerfil = async (event) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Perfil',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setPerfil(data.objetoRetorno);
                //console.log(response);
            }
            )
            .catch(function (error) {
                console.log(error);
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getPerfil();
        // eslint-disable-next-line
    }, []);

    const handleSelectPerfil = (event) => {
        setPerfilSelecionado(event.target.value);

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios?CodigoCadastro=' + codigoCadastro + '&CodigoPerfil=' + event.target.value,
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },

        };
        //console.log(config)
        axios(config)
            .then(function (response) {

                const data = response.data;
                //console.log(data);

                if (data.codigoRetorno > 0) {
                    setUsuarioExistente(true);
                } else {
                    setUsuarioExistente(false);
                }

            }
            )
            .catch(function (error) {
                console.log(error);
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });

    }


    const getProjeto = async (event) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                //console.log(response.data.objetoRetorno)

                setProjetos(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    useEffect(() => {
        getProjeto();
        // eslint-disable-next-line
    }, []);

    const handleProjetoSelecionado = (event) => {

        setProjetoSelecionado(event.target.value);

        var wCodigoConvenioSelecionado = event.target.selectedOptions[0].getAttribute("data-key");

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio?CodigoConvenio=' + wCodigoConvenioSelecionado,
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                //console.log(response.data.objetoRetorno);

                setValidadeUsuarioArea(response.data.objetoRetorno[0].DataExpiracao);

            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    // const handlePasswordChange = (event) => {
    //     const novaSenha = event.target.value;
    //     setSenhaUsuario(novaSenha);
    //     const { isValidLength, hasUppercase, hasLowercase, hasDigit, hasSpecialChar } = validatePassword(novaSenha);
    //     setValidLength(isValidLength);
    //     setHasUppercase(hasUppercase);
    //     setHasLowercase(hasLowercase);
    //     setHasDigit(hasDigit);
    //     setHasSpecialChar(hasSpecialChar);
    // };

    // const validatePassword = (senhaUsuario) => {
    //     const lengthRegex = /.{8,}/;
    //     const uppercaseRegex = /[A-Z]/;
    //     const lowercaseRegex = /[a-z]/;
    //     const digitRegex = /\d/;
    //     const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    //     const isValidLength = lengthRegex.test(senhaUsuario);
    //     const hasUppercase = uppercaseRegex.test(senhaUsuario);
    //     const hasLowercase = lowercaseRegex.test(senhaUsuario);
    //     const hasDigit = digitRegex.test(senhaUsuario);
    //     const hasSpecialChar = specialCharRegex.test(senhaUsuario);

    //     return { isValidLength, hasUppercase, hasLowercase, hasDigit, hasSpecialChar };
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        function gerarSenhaRandomica() {
            const caracteresEspeciais = '@';
            const letrasMaiusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const letrasMinusculas = 'abcdefghijklmnopqrstuvwxyz';
            const numeros = '0123456789';

            let senha = '';
            let temNumero = false;
            let temCaracterEspecial = false;
            let temLetraMaiuscula = false;
            let temLetraMinuscula = false;

            while (senha.length < 8 || !(temNumero && temCaracterEspecial && temLetraMaiuscula && temLetraMinuscula)) {
                if (senha.length < 8) {
                    const tipoCaracter = Math.floor(Math.random() * 4);

                    if (tipoCaracter === 0 && !temNumero) {
                        senha += numeros[Math.floor(Math.random() * numeros.length)];
                        temNumero = true;
                    } else if (tipoCaracter === 1 && !temCaracterEspecial) {
                        senha += caracteresEspeciais[Math.floor(Math.random() * caracteresEspeciais.length)];
                        temCaracterEspecial = true;
                    } else if (tipoCaracter === 2 && !temLetraMaiuscula) {
                        senha += letrasMaiusculas[Math.floor(Math.random() * letrasMaiusculas.length)];
                        temLetraMaiuscula = true;
                    } else if (tipoCaracter === 3 && !temLetraMinuscula) {
                        senha += letrasMinusculas[Math.floor(Math.random() * letrasMinusculas.length)];
                        temLetraMinuscula = true;
                    } else {
                        const caracteres = caracteresEspeciais + letrasMaiusculas + letrasMinusculas + numeros;
                        senha += caracteres[Math.floor(Math.random() * caracteres.length)];
                    }
                } else {
                    break;
                }
            }

            return senha;
        }

        const senhaAssinatura = gerarSenhaRandomica();

        var data = JSON.stringify({
            "CodigoUsuario": 0,
            "Nome": nome,
            "Login": loginUsuario,
            "CodigoCadastro": codigoCadastro,
            "CodigoPerfil": perfilSelecionado,
            "DataExpira": perfilSelecionado !== '37' ? moment(validadeUsuario).format("YYYY-MM-DDTHH:mm:ss.SSSZ") : validadeUsuarioArea,
            "CodigoConvenio": perfilSelecionado !== '37' ? 999 : projetoSelecionado,
            "Ativo": true,
            "Logado": false,
            "IgnorarRateLimit": true,
            "SenhaAssinatura": senhaAssinatura
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };

        try {
            const response = await axios(config);

            if (response.data.isOk === true) {

                const senhaAcesso = gerarSenhaRandomica();

                var data = JSON.stringify({
                    "Senha": senhaAcesso
                });

                var config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Usuarios/AlterarSenhaAcesso/' + response.data.objetoRetorno.CodigoUsuario,
                    headers: {
                        'Authorization': wtokenBearer,
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                const responseAcesso = await axios(config);
                console.log(responseAcesso);

                if (responseAcesso.data.isOk === true) {
                    var Assunto = "Bem vindo(a) ao Sistema Fauscs!"
                    var Mensagem = "Bem vindo(a) ao Sistema Fauscs <strong>" + nome + "</strong><br></br>Agora você já pode acessar o sistema de controle interno da fundação. <br></br>Aqui estão as informações necessárias para efetuar o acesso: <br></br><strong>Login:</strong> " + loginUsuario + "<br></br><strong>Senha de Acesso:</strong> " + senhaAcesso + "<br></br><strong>Senha de Assinatura: </strong>" + senhaAssinatura + "<br></br><br></br><strong>Link para acessar o sistema:</strong> https://sistemacontroll.app.br/";
                    EnviaEmailUsuario(Assunto, Mensagem, wtokenBearer);

                    setLoading(false);
                    setShowModal(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    function EnviaEmailUsuario(wAssunto, wMensagem, wtokenBearer) {
        //console.log(EmailArea);
        var data = JSON.stringify({
            "RemetenteEmail": "",
            "RemetenteNome": "",
            "CopiaOculta": ['fauscs@fauscs.org.br'],
            "destinatario": [emailUsuario],
            "assunto": wAssunto,
            "corpo": wMensagem,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Email',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response);
                // faça algo com a resposta da API
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className={StyleForm.page}>
            <Menu />

            <div className={StyleForm.container}>

                <div className={StyleForm.form}>

                    <div className={StyleForm.header}>
                        <h1>Cadastro de Usuário</h1>
                    </div>

                    <Form onSubmit={handleSubmit}>
                        <FormGroup className={StyleForm.form_group}>

                            {usuarioExistente ?
                                (<div style={{ display: 'flex', justifyContent: 'center', margin: '0 auto', width: '100%', borderRadius: '8px', backgroundColor: '#FDEDEC', color: '#B03A2E', fontSize: '14px', fontWeight: '600', padding: '.3em' }}>
                                    <p style={{ margin: '.3em' }}><BiSolidError style={{ fontSize: '18px', marginBottom: '.2em' }} /> O cadastro selecionado já possui um usuário para o tipo de perfil escolhido</p>
                                </div>) : (null)}

                            <Form.Label className={StyleForm.form_group_label}>Nome</Form.Label>
                            <Form.Select className={StyleForm.form_control} value={nome} onChange={handleCadastroEscolhido} required>
                                <option>Selecione um cadastro para anexar ao novo usuário</option>
                                {cadastro.length > 0 &&
                                    cadastro.map((opcao) => (
                                        <option key={opcao.CodigoCadastro} value={opcao.Nome} data-key={opcao.CodigoCadastro}>
                                            {opcao.Nome}
                                        </option>
                                    ))}
                            </Form.Select>

                            <FormLabel className={StyleForm.form_group_label}>Perfil</FormLabel>
                            <FormSelect className={StyleForm.form_control} value={perfilSelecionado} onChange={handleSelectPerfil} required>
                                <option value="">Selecione o tipo de perfil</option>
                                {perfil.length > 0 &&
                                    perfil.map((opcao) => (
                                        <option key={opcao.CodigoPerfil} value={opcao.CodigoPerfil}>{opcao.Nome}</option>
                                    ))}
                            </FormSelect>

                            {/* <Row>
                                <Col> */}
                            {perfilSelecionado === '37' && (
                                <>
                                    <FormLabel className={StyleForm.form_group_label}>Projeto</FormLabel>
                                    <FormSelect className={StyleForm.form_control} value={projetoSelecionado} onChange={handleProjetoSelecionado} required>
                                        <option value="">Selecione o Projeto</option>
                                        {projetos.length > 0 &&
                                            projetos.map((opcao) => (
                                                <option key={opcao.CodigoConvenio} value={opcao.CodigoConvenio} data-key={opcao.CodigoConvenio}>{opcao.Nome}</option>
                                            ))}
                                    </FormSelect>
                                </>
                            )}
                            {/* </Col>
                                <Col> */}
                            {perfilSelecionado !== '37' && (
                                <>
                                    <FormLabel className={StyleForm.form_group_label}>Data de Validade do Usuário</FormLabel>
                                    <FormControl className={StyleForm.form_control} type="date" name="validade" value={validadeUsuario} onChange={(event) => setValidadeUsuario(event.target.value)} required disabled={!perfilSelecionado} />
                                </>
                            )}
                            {/* </Col>
                            </Row> */}

                            <FormLabel className={StyleForm.form_group_label}>Login</FormLabel>
                            <FormControl className={StyleForm.form_control} type="text" name="loginUsuario" autoComplete="new-password" value={loginUsuario} onChange={(event) => setLoginUsuario(event.target.value)} required />

                            <p style={{ color: 'red' }}>As senhas de acesso e assinatura serão enviados via e-mail ao novo usuário</p>
                            {/* <FormLabel className={StyleForm.form_group_label}>Senha</FormLabel>
                            <FormControl className={StyleForm.form_control} type="password" name="senhaUsuario" autoComplete="new-password" value={senhaUsuario} onChange={handlePasswordChange} required />

                            <ul className={StyleForm.validacao_senha}>
                                {isValidLength ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos 8 caracteres</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos 8 caracteres</li>)}
                                {hasUppercase ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos uma letra maiúscula</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos uma letra maiúscula</li>)}
                                {hasLowercase ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos uma letra minúscula</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos uma letra minúscula</li>)}
                                {hasDigit ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos um dígito</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos um dígito</li>)}
                                {hasSpecialChar ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos um caractere especial</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos um caractere especial</li>)}
                            </ul> */}

                            <div>

                                <button className={StyleForm.btn} type="submit" disabled={usuarioExistente}> Cadastrar </button>
                            </div>
                        </FormGroup>

                    </Form>
                </div>
            </div>

            <Modal show={loading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
                <Modal.Header className={StyleForm.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <h3>Tudo certo!</h3>
                    <p>Usuário cadastrado com sucesso.</p>
                </Modal.Body>
            </Modal>
            {redir && (<Navigate to="/Usuarios" replace={true} />)}
            {login && (<Navigate to="/login" replace={true} />)}
        </div>
    )
}
export default CadUsuario