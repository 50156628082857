import { useState } from "react";
import { useParams } from "react-router";
import React, { useEffect } from 'react';
import axios from "axios";
import urlAPIlocal from '../../global';
import Table from 'react-bootstrap/Table';
import moment from "moment";
import Cabecalho from "../img/Cabecalho.png";


function RelatRestosAPagar() {

    const { CodigoConvenio } = useParams();
    const [solicsAPagar, setSolicsAPagar] = useState([]);
    const [fase, setFase] = useState([]);
    const [totalAPagar, setTotalAPagar] = useState('');
    //const [projeto, setProjeto] = useState([]);
    const [dataEmissao, setDataEmissao] = useState('');
    var wToken = 'Bearer ' + localStorage.getItem("token");

    function PegaMetaFase(CodFase) {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Fase?CodigoConvenioFase=' + CodFase + '&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                //console.log(response)
                const data = response.data;
                if (response.data.isOk === true) {
                    setFase(data.objetoRetorno);
                    //console.log(data.objetoRetorno)
                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setFase('');
                }
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    const getSolicsAPagar = async () => {

        //setLoading(true);

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoConvenio=' + CodigoConvenio + '&Pago=false&Indeferida=false&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setSolicsAPagar(data.objetoRetorno);
                //setLoading(false);
                //console.log(data.objetoRetorno);

                //calcula total de pagamentos
                if (data.objetoRetorno !== null && Array.isArray(data.objetoRetorno) && data.objetoRetorno.length > 0) {

                    // Assume que ValorPago é uma propriedade válida em cada item do array
                    const totalAPagar = data.objetoRetorno.reduce((acc, solic) => acc + solic.ValorTotal, 0);
                    setTotalAPagar(totalAPagar);

                    // Chamar PegaMetaFase apenas para os resultados filtrados
                    data.objetoRetorno.forEach((item) => {
                        PegaMetaFase(item.CodigoFase);
                    });

                } else {
                    //console.log('Nenhum objeto de retorno válido encontrado');
                }

                const date = new Date();
                setDataEmissao(date);

            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                // if (error.response.status === 401) {
                //     setLogin(true);
                // }
            });
    }

    useEffect(() => {
        getSolicsAPagar();
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{ minHeight: '120vh', backgroundColor: '#F8F9F9' }}>

            <div style={{ minHeight: '120vh', backgroundColor: '#fff', width: '900px', margin: '0 auto', padding: '0.5em', display: 'flex', flexDirection: "column" }}>

                <div style={{ textAlign: 'center', marginBottom: '2em' }}>
                    <img src={Cabecalho} style={{ width: '350px', display: 'inline-block', marginBottom: '1em' }} alt="Cabeçalho" />
                    <h5 style={{ color: '#273746', margin: '1em', fontSize: '18px' }}>Relatório de Solicitações A Pagar</h5>
                </div>

                <p style={{ color: '#566573', paddingLeft: '1.5em', fontWeight: '600', fontSize: '16px' }}>Projeto nº {solicsAPagar[0].Convenio.NumeroConvenio} - {solicsAPagar[0].Convenio.Nome}</p>

                <Table striped bordered style={{ marginBottom: '.3em', borderCollapse: 'collapse', width: '95%', margin: '0 auto' }}>
                    <thead style={{ borderBottom: '2px solid #154360', borderTop: '1px solid #fff', borderRight: '1px solid #fff', borderLeft: '1px solid #fff', fontWeight: '600' }}>
                        <tr style={{ color: '#2C3E50', fontSize: '14px' }}>
                            <th style={{ textAlign: 'center' }}>Número</th>
                            <th style={{ textAlign: 'center' }}>Meta</th>
                            <th style={{ textAlign: 'center' }}>Fase</th>
                            <th >Elemento</th>
                            <th >Beneficiário</th>
                            <th style={{ textAlign: 'center' }}>Data</th>
                            <th style={{ textAlign: 'right' }}>Valor</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#FFFFFF', color: '#2C3E50', fontSize: '12px' }}>

                        {solicsAPagar !== null && (
                            solicsAPagar.map((solic) => {

                                const faseInfo = fase.find((f) => f.CodigoConvenioFase === solic.CodigoFase);

                                return (
                                    <tr key={solic.CodigoSolicitacao}>
                                        <td align="center">{solic.CodigoSolicitacao}</td>
                                        <td align="center">{faseInfo ? faseInfo.Meta.NumMeta : ''}</td>
                                        <td align="center">{faseInfo ? faseInfo.NumeroFase : ''}</td>
                                        <td >{solic.ConvenioElemento.Elemento.CodigoElemento} - {solic.ConvenioElemento.Elemento.Nome}</td>
                                        <td>{solic.Cadastro.Nome}</td>
                                        <td align="center">{moment(solic.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                        <td align="right">{solic.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                );
                            })
                        )}

                    </tbody>
                </Table>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #154360', borderTop: '1px solid #154360', color: '#fff', fontSize: '14px', width: '95%', margin: '0 auto', backgroundColor: '#566573' }}>
                    <p style={{ fontWeight: '600', margin: '0.3em' }}>TOTAL DE SOLICITAÇÕES RESTANTES:</p>
                    <p style={{ fontWeight: '600', margin: '0.3em' }}>{totalAPagar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                </div>

                <div style={{ margin: 'auto 0 0' }}>
                    <p style={{ color: '#566573', paddingLeft: '1.5em', fontWeight: '600', fontSize: '16px', marginBottom: '0' }}>Data de Emissão do Relatório: {moment(dataEmissao).format("DD/MM/yyyy")}</p>
                </div>
            </div>
        </div>
    )
}
export default RelatRestosAPagar