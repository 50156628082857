import Style from './EditSolic.module.css'
import { Navigate } from "react-router-dom";
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import Menu from "../Layout/Menu";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import urlAPIlocal from '../../global';
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { MdEditNote, MdOutlineFileUpload } from "react-icons/md";
import { AiFillPlusCircle } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { BiSolidError } from 'react-icons/bi';
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
import { ImSad2 } from 'react-icons/im';
import loadingGif from '../img/icons8-spinning-circle.gif';
import moment from "moment";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BsArrowRight } from 'react-icons/bs'


function EditSolic() {

    var wPerfil = localStorage.getItem("CodigoPerfil");
    var wUsuario = localStorage.getItem("CodigoUsuario");
    var wNomeUsuario = localStorage.getItem("NomeUsuario");
    const { CodigoSolicitacao } = useParams();
    const [loading, setLoading] = useState(true);
    const [codigoDPCC, setCodigoDPCC] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const [codigoConvenio, setCodigoConvenio] = useState(0);
    const [dataSolicitacao, setDataSolicitacao] = useState('');
    const [dataParecer, setDataParecer] = useState('');
    const [dataPagamento, setDataPagamento] = useState('');
    const [fornecedores, setFornecedores] = useState([]);
    const [fornecedoresItem, setFornecedoresItem] = useState([]);
    const [fornecedorEscolhido, setFornecedorEscolhido] = useState(0);
    const [fornecedorEscolhidoItem, setFornecedorEscolhidoItem] = useState(0);
    const [fornecedorAtual, setFornecedorAtual] = useState('');
    const [observacao, setObservacao] = useState('');
    const [observacaoAdicional, setObservacaoAdicional] = useState('');
    const [justificativa, setJustificativa] = useState('');
    const [justificativaAdicional, setJustificativaAdicional] = useState('');
    const [item, setItem] = useState([]);
    const [itemSelecionado, setItemSelecionado] = useState('');
    const [codigoItemSelecionado, setCodigoItemSelecionado] = useState('');
    const [NumeroSolic, setNumeroSolic] = useState('');
    const [fonte, setFonte] = useState(0);
    const [elementos, setElementos] = useState([]);
    const [elementoEscolhido, setElementoEscolhido] = useState(0);
    const [nomeElemento, setNomeElemento] = useState('');
    const [tipoContratacao, setTipoContratacao] = useState('');
    //const [temMeta, setTemMeta] = useState(false);
    //const [temFase, setTemFase] = useState(false);
    const [metasProjeto, setMetasProjeto] = useState([]);
    //const [meta, setMeta] = useState([]);
    const [metaEscolhida, setMetaEscolhida] = useState(0);
    const [fasesProjeto, setFasesProjeto] = useState([]);
    //const [fase, setFase] = useState([]);
    const [faseEscolhida, setFaseEscolhida] = useState(0);
    const [valorUnitario, setValorUnitario] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [unidade, setUnidade] = useState([]);
    const [unidadeEscolhida, setUnidadeEscolhida] = useState('');
    const [quantidade, setQuantidade] = useState([]);
    const [nome, setNome] = useState('');
    const [pago, setPago] = useState(false);
    const [frete, setFrete] = useState('');
    const [codCadastroItem, setCodCadastroItem] = useState(0);
    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(0);
    const [alertaSaldo, setAlertaSaldo] = useState(false);
    const [avisoSaldo, setAvisoSaldo] = useState('');
    const [showAviso, setShowAviso] = useState(false);
    const [status, setStatus] = useState([]);
    const [numElemento, setNumElemento] = useState('');


    const [totalSolic, setTotalSolic] = useState(0);
    // eslint-disable-next-line
    const [showFormEdititem, setShowFormEditItem] = useState(false);
    const [showFormListaItem, setShowFormListaItem] = useState(true);
    const [fornecedorAtualNome, setFornecedorAtualNome] = useState('');
    const [fornecedorItemAtualNome, setFornecedorItemAtualNome] = useState('');
    const [novoItem, setNovoItem] = useState(false);
    const [setorRegularizacao, setSetorRegularizacao] = useState('');

    const fileInputRef = useRef(null);
    const [novoFile, setNovoFile] = useState(null);
    const [descricaoArquivo, setDescricaoArquivo] = useState('');
    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosProcesso/"
    const [arquivosInseridos, setArquivosInseridos] = useState([]);

    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);
    const [showModalErroElemento, setShowModalErroElemento] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [perfilDestinoAtual, setPerfilDestinoAtual] = useState(0);
    const [anoSolic, setAnoSolic] = useState('');
    const [pt, setPt] = useState([]);
    const [numFaseSelecionada, setNumFaseSelecionada] = useState('');
    const [btAtualiza, setBtAtualiza] = useState(false);

    const [redirIndex, setRedirIndex] = useState(false);

    var wTokenLocal = localStorage.getItem("token");
    const [login, setLogin] = useState(false);

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function handleClose() {
        setTimeout(() => {
            setShowModal(false);
        }, 2000);

    }

    function handleCloseEdit() {
        setTimeout(() => {
            setShowModalEdit(false);
        }, 2000);

    }

    function handleCloseDelete() {
        setTimeout(() => {
            setShowModalDelete(false);
        }, 2000);

    }

    function handleCloseErro() {
        setShowModalErro(false);
    };

    function handleCloseErroElemento() {
        setShowModalErroElemento(false);
    };

    //Requisição SOLICITAÇÃO
    const getSolicitacao = async () => {
        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                //console.log(data.objetoRetorno);
                setNumeroSolic(data.objetoRetorno[0].NumeroSolicitacao)
                setSolicitacao(data.objetoRetorno);
                setCodigoDPCC(data.objetoRetorno[0].CodigoAprovacaoDPCC)
                PegaItem();
                PegaStatus();
                setCodigoConvenio(data.objetoRetorno[0].CodigoConvenio);
                setDataSolicitacao(data.objetoRetorno[0].DataSolicitacao);
                setFornecedorEscolhido(data.objetoRetorno[0].CodigoCadastro);
                setFornecedorAtualNome(data.objetoRetorno[0].Cadastro.Nome);
                setDataPagamento(data.objetoRetorno[0].DataPagamento);
                setDataParecer(data.objetoRetorno[0].DataParecer);
                setObservacao(data.objetoRetorno[0].Observacao);
                setJustificativa(data.objetoRetorno[0].Justificativa);
                setFornecedorAtual(data.objetoRetorno[0].CodigoCredor);
                setPerfilDestinoAtual(data.objetoRetorno[0].PerfilDestinoAtual);
                setFonte(data.objetoRetorno[0].CodigoFonte);
                setTipoContratacao(data.objetoRetorno[0].TipoLicitacao);


                setElementoEscolhido(data.objetoRetorno[0].CodigoElemento);
                //console.log(data.objetoRetorno[0].ConvenioElemento);
                if (data.objetoRetorno[0].ConvenioElemento !== null) {
                    setNomeElemento(data.objetoRetorno[0].ConvenioElemento.Elemento.Nome);
                } else {
                    setNomeElemento('');
                    setElementoEscolhido(0);
                }

                /* const elemento = data.objetoRetorno[0]?.ConvenioElemento?.Elemento || {};
                setNomeElemento(elemento.Nome || ''); */


                PegaNumElemento(data.objetoRetorno[0].CodigoElemento)
                //AlimentaPT()

                setMetaEscolhida(data.objetoRetorno[0].CodigoMeta);

                carregaSelectFase(data.objetoRetorno[0].CodigoMeta);

                setFaseEscolhida(data.objetoRetorno[0].CodigoFase);
                setLoading(false);

                const dataCompleta = new Date(data.objetoRetorno[0].DataSolicitacao);
                const ano = dataCompleta.getFullYear();
                setAnoSolic(ano);

                //getMeta(data.objetoRetorno[0].CodigoMeta);
                //getFase(data.objetoRetorno[0].CodigoFase);

                // if (data.objetoRetorno[0].CodigoMeta !== 0) {
                //     setTemMeta(true);
                // } else {
                //     setTemMeta(false);
                // }

                // if (data.objetoRetorno[0].CodigoFase !== 0) {
                //     setTemFase(true);
                // } else {
                //     setTemFase(false);
                // }
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacao();
        //eslint-disable-next-line
    }, []);

    // function getFaseSolicitacao(wCodigoFase) { 


    // }


    function PegaItem() {
        //requisição SOLICITAÇÃO-ITEM
        //setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;

        var configItem = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configItem);
        axios(configItem)

            .then(function (responseItem) {

                const data = responseItem.data;
                // Ordenar o array antes de atribuí-lo ao estado
                //console.log(data.objetoRetorno);
                const sortedData = data.objetoRetorno.sort((a, b) => {
                    // Lógica de classificação aqui
                    // Exemplo: Ordenar por propriedade "nome" em ordem crescente
                    if (a.NumeroItem < b.NumeroItem) {
                        return -1;
                    }
                    if (a.NumeroItem > b.NumeroItem) {
                        return 1;
                    }
                    return 0;
                });

                setItem(sortedData);
                calcularValorTotalSolicitacao(sortedData)
                //console.log(sortedData);
                //setLoading(false);


                //setItem(data.objetoRetorno);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    const getFornecedores = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Cadastro?Aprovado=1&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //filtrar de data somente cadastros do CodigoTipoCadastro = 43 ou 33
            const cadastrosFiltrados = data.filter(item => item.CodigoTipoCadastro === 33 || item.CodigoTipoCadastro === 43);
            setFornecedores(cadastrosFiltrados);
            setFornecedoresItem(cadastrosFiltrados);

            //console.log(data);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getFornecedores();
        // eslint-disable-next-line
    }, []);

    const handleSelectFornecedor = (event) => {
        setFornecedorEscolhido(event.target.value);
        console.log(event.target.value);
    };

    const handleSelectFornecedorItem = (event) => {
        setFornecedorEscolhidoItem(event.target.value);
        //console.log(event.target.value)
    };

    const handleSelectFonte = (event) => {
        setFonte(event.target.value);
        //console.log(event.target.value)
    };

    const getElementos = async () => {

        try {
            if (codigoConvenio) {
                const wtoken = "Bearer " + wTokenLocal;

                const config = {
                    method: "GET",

                    //elementos do convenio
                    url: urlAPIlocal + "/api/ConvenioElemento?CodigoConvenio=" + codigoConvenio + "&IsInclude=true",
                    headers: {
                        Authorization: wtoken,
                        "Content-Type": "application/json",
                    },
                };

                const response = await axios(config);

                const data = response.data.objetoRetorno;

                setElementos(data);
                //console.log(data)
                //muda a ordem dos itens no array antes de mostrar na tela
                const sortedList = data.sort((a, b) => a.CodigoElemento - b.CodigoElemento);
                setElementos(sortedList);
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (codigoConvenio) {
            getElementos();
        }
    }, [codigoConvenio]);

    const handleSelectElemento = (event) => {
        setElementoEscolhido(event.target.value);
        PegaNumElemento(event.target.value)
        //setElementoDefinir(event.target.value)
        //console.log(event.target.value);
    };

    const getMetasProjeto = async () => {

        try {
            if (codigoConvenio) {
                const wtoken = "Bearer " + wTokenLocal;

                const config = {
                    method: "GET",
                    url: urlAPIlocal + "/api/Meta?CodigoConvenio=" + codigoConvenio,
                    headers: {
                        Authorization: wtoken,
                        "Content-Type": "application/json",
                    },
                };

                const response = await axios(config);
                //console.log(config);
                const data = response.data.objetoRetorno;
                //console.log(data);
                setMetasProjeto(data);

                // //Chame getFases para cada meta
                // data.forEach((meta) => {
                //    console.log(meta.CodigoMeta);
                //    getFase(meta.CodigoMeta);
                // });
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (codigoConvenio) {
            getMetasProjeto();
        }
    }, [codigoConvenio]);

    // const getMeta = async (wCodigoMeta) => {

    //     try {

    //             const wtoken = "Bearer " + wTokenLocal;

    //             const config = {
    //                 method: "GET",
    //                 url: urlAPIlocal + "/api/Meta?CodigoMeta=" + wCodigoMeta,
    //                 headers: {
    //                     Authorization: wtoken,
    //                     "Content-Type": "application/json",
    //                 },
    //             };

    //             const response = await axios(config);
    //             console.log(config);
    //             const data = response.data.objetoRetorno;
    //             console.log(data);
    //             setMeta(data[0]);

    //             //Chame getFases para cada meta
    //             data.forEach((meta) => {
    //                console.log(meta.CodigoMeta);
    //                getFase(meta.CodigoMeta);
    //             });

    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    // useEffect(() => {
    //     if (codigoConvenio) {
    //         getMeta();
    //     }
    // }, [codigoConvenio, metaEscolhida]);

    const carregaSelectFase = (wCodigoMetaAtual) => {
        //setMetaEscolhida(event.target.value);
        getFasesProjeto(wCodigoMetaAtual);
    };

    const handleSelectMeta = (event) => {
        //console.log(event.target.value)
        if (event.target.value !== '0') {
            setMetaEscolhida(event.target.value);
            getFasesProjeto(event.target.value);
        } else {
            //console.log("entrou aqui")
            setMetaEscolhida(0);
            setFaseEscolhida(0);
        }
    };

    const getFasesProjeto = async (wCodigoMeta) => {
        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url: urlAPIlocal + "/api/Fase?CodigoMeta=" + wCodigoMeta + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config);
            const response = await axios(config);
            const data = response.data.objetoRetorno;
            //console.log(data);
            if (data !== null) {
                setFasesProjeto(data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectFase = (event) => {
        setFaseEscolhida(event.target.value);
        AlimentaPT();
        const fasesArray = Object.values(fasesProjeto);
        const wNumfase = fasesArray.find(fase => fase.CodigoConvenioFase === parseInt(event.target.value));
        setNumFaseSelecionada(wNumfase.NumeroFase);

        // console.log(event.target.value)
    };

    function calcularValorTotalSolicitacao(wItensSolic) {
        let totalSolic = 0;
        //console.log(wItensSolic);
        wItensSolic.forEach((wtotItens) => {
            totalSolic += wtotItens.ValorTotal;
            //console.log(wtotItens.ValorTotal);
        });
        // console.log(totalSolic);
        setTotalSolic(totalSolic);

        setSolicitacao((prevSolicitacao) => [
            {
                ...prevSolicitacao[0],
                ValorTotal: totalSolic,
            },
        ]);
        //console.log(prevSolicitacao);
        GravaTotalSolic(totalSolic);
    };

    // Função de cálculo sempre que o array de item for atualizado
    //useEffect(() => {
    //    calcularValorTotalSolicitacao();
    // }, []); //estava item dentro Alison, tirei por causa de cors muitas requisicoes

    const handleSelectTipoContratacao = (event) => {
        setTipoContratacao(event.target.value);
    };

    const handleObservacaoChange = (value) => {
        setObservacao(value);
    };

    const handleJustificativaChange = (value) => {
        setJustificativa(value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        var wtokenBearer = "Bearer " + localStorage.getItem("token");

        var wCodigoCredor = 0
        if (fornecedorEscolhido === 0) {
            wCodigoCredor = fornecedorAtual;
        } else {
            wCodigoCredor = fornecedorEscolhido;
        }
        //console.log(elementoEscolhido);
        let indFinalizada = false;
        if (elementoEscolhido !== 0) {
            indFinalizada = true;
        }
        let wPerfilDestinoAtual = 0
        if (perfilDestinoAtual !== 0) {
            wPerfilDestinoAtual = perfilDestinoAtual
        }
        //console.log(solicitacao);
        if (elementoEscolhido !== 0) {

            var data = JSON.stringify({

                "CodigoSolicitacao": CodigoSolicitacao,
                "DataSolicitacao": dataSolicitacao,
                "NumeroSolicitacao": NumeroSolic,
                "CodigoFonte": fonte,
                "CodigoConvenio": codigoConvenio,
                "CodigoCredor": wCodigoCredor,
                "CodigoElemento": elementoEscolhido,
                "CodigoMeta": metaEscolhida,
                "CodigoFase": faseEscolhida,
                "ValorTotal": totalSolic,
                "IndFinalizada": indFinalizada,
                "Pago": false,
                "Observacao": observacao,
                "CodigoAprovacaoCampus": 0,
                "CodigoAprovacaoDARDC": 0,
                "CodigoAprovacaoConvenente": 0,
                "CodigoAprovacaoDPCC": 0, //codigoConvenio, //solicitacao[0].CodigoAprovacaoDPCC,
                "NumeroParecer": 0,
                "DataParecer": dataParecer,
                "ValorLiquido": totalSolic,
                "ValorISS": 0,
                "ValorINSS": 0,
                "ValorIRRF": 0,
                "ValorINSS20": 0,
                "CodigoSolicitacaoMae": 0,
                "ValorDesconto": 0,
                "DataPagamento": dataPagamento,
                "Issp": false,
                "Inssp": false,
                "Irrfp": false,
                "Inss20p": false,
                "DataISS": dataPagamento,
                "DataINSS": dataPagamento,
                "DataIRRF": dataPagamento,
                "DataINSS20": dataPagamento,
                "NomeISS": "",
                "NomeINSS": "",
                "NomeIRRF": "",
                "NomeINSS20": "",
                "ReciboDet": "",
                "ReciboDatDe": "",
                "ReciboDataTe": "",
                "ReciboHoras": "",
                "ReciboBaseInss": "",
                "ReciboBaseIrrf": "",
                "NumeroContrato": "",
                "Indeferida": false,
                "Regularizada": false,
                "Excluida": false,
                "Justificativa": justificativa + '<br>' + justificativaAdicional,
                "Setor": 0,
                "ValorOutrosImpostos": 0,
                "OutrosImpostos": false,
                "DataOutrosImpostos": dataParecer,
                "NomeOutrosImpostos": "",
                "DataRPA": dataParecer,
                "MeioTransporte": "",
                "TipoLicitacao": tipoContratacao,
                "PerfilDestinoAtual": wPerfilDestinoAtual,
                "CodigoProcessoInterno": solicitacao[0].CodigoProcessoInterno,

            });

            var config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Solicitacao',
                headers: {
                    'Authorization': wtokenBearer,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //console.log(data)
            axios(config)

                .then(function (response) {

                    //console.log(response.data)
                    if (response.data.isOk === true) {

                        setShowModal(true);

                        getSolicitacaoEditada();

                    } else {
                        setShowModalErro(true);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setShowModalErro(true);
                });
        } else {
            setShowModalErroElemento(true);
        }
    }

    function getSolicitacaoEditada() {
        //setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data.objetoRetorno[0];
                //console.log(data);

                //Grava Registro na tblStatus

                var wCodigoAprovador = localStorage.getItem("CodigoUsuario");
                //var wCodigoPerfil = localStorage.getItem("CodigoPerfil");
                var datHoje = new Date();
                var wMensagemNovaStatus = '<strong>Solicitação trabalhada pelo colaborador ' + wNomeUsuario + '</strong><br><strong>Valor da Solicitação:</strong> ' + totalSolic.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) +
                    '<br><strong>Tipo de Despesa:</strong> ' + data.ConvenioElemento.Elemento.Nome + '<br><strong>Fornecedor:</strong> ' + data.Cadastro.Nome + '<br><strong>Tipo de Contratação:</strong> ' + data.TipoLicitacao;

                var dataStatus = JSON.stringify({
                    "CodigoStatus": 0,
                    "CodigoSolicitacao": CodigoSolicitacao,
                    "CodigoAprovador": wCodigoAprovador,
                    "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    "status": true,
                    "Motivo": wMensagemNovaStatus,
                    "CodigoPerfil": wCodigoAprovador,
                    "Doc": "",
                    "PerfilDestino": wCodigoAprovador,
                    "CodCadastro": localStorage.getItem("CodigoCadastro"),
                });

                var configStatus = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Status',
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json'
                    },
                    data: dataStatus
                };
                //console.log(data)
                axios(configStatus)

                    .then(function (responseStatus) {
                        //console.log(responseStatus.data);

                        setRedirIndex(true);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    const ExcluirItem = (wItem) => {

        var configSelect = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem/' + wItem,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataSelect
        };
        //console.log(dataSelect)
        axios(configSelect)

            .then(function (response) {
                PegaItem();
                setShowModalDelete(true);

            })
            .catch(error => {
                console.error(error);
                setShowModalErro(true);
            });
    };

    const handleForm = async (wCodigoSolicitacaoItem) => {

        try {

            const wtoken = "Bearer " + wTokenLocal;

            var config = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/SolicitacaoItem?CodigoSolicitacaoItem=' + wCodigoSolicitacaoItem + '&IsInclude=true',
                headers: {
                    'Authorization': wtoken,
                    'Content-Type': 'application/json'
                },

            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            //console.log(data);
            setShowFormEditItem(true);
            setShowFormListaItem(false);
            setCodigoItemSelecionado(data[0].CodigoSolicitacaoItem);
            setItemSelecionado(data[0].NumeroItem);
            setNome(data[0].Nome);
            setUnidadeEscolhida(data[0].Unidade);
            setQuantidade(data[0].Quantidade);
            setValorUnitario(data[0].ValorUnitario);
            setValorTotal(data[0].ValorTotal);
            setPago(data[0].Pago);
            setFrete(data[0].Frete);
            setCodCadastroItem(data[0].CodCadastro);
            setNovoItem(false);
            //console.log(data[0].CodCadastro);
            //alimenta o select fornecedorItem
            setFornecedorEscolhidoItem(data[0].CodCadastro);
            setFornecedorItemAtualNome(data[0].Cadastro.Nome);
            // setEditado(false);

        } catch (error) {
            console.log(error)
        }
    }

    const getUnidade = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Unid",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setUnidade(data);
            //console.log(data)



        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getUnidade();
        // eslint-disable-next-line
    }, []);

    const handleSelectUnidade = (event) => {
        setUnidadeEscolhida(event.target.value);
    };

    const handleSomaValor = (event) => {
        setValorUnitario(event.target.value);

        var quantidadeString = quantidade.toString();
        var wTotal = event.target.value.replace(/,/g, '.') * quantidadeString.replace(/,/g, '.');
        setValorTotal(wTotal);
    };

    function handleNovoItem() {
        setShowFormEditItem(true);
        setShowFormListaItem(false);
        setNome('');
        setQuantidade('');
        setValorUnitario('');
        setNovoItem(true);
        setValorTotal('');
        // Pegar o último número do item da solicitação
        setItemSelecionado(item.length + 1);
    }

    function handleEditSubmit() {
        //event.preventDefault();

        var wtokenBearer = "Bearer " + localStorage.getItem("token")

        var quantidadeFormatadaPut = quantidade.toString();
        quantidadeFormatadaPut = quantidadeFormatadaPut.replace(',', '.');

        var vUnitarioFormatadoPut = valorUnitario.toString();
        vUnitarioFormatadoPut = vUnitarioFormatadoPut.replace(',', '.');

        //verifica se selecionou um novo fornecedor
        var wCodigoCredor = 0
        if (fornecedorEscolhidoItem === 0) {
            wCodigoCredor = codCadastroItem;
        } else {
            wCodigoCredor = fornecedorEscolhidoItem;
        }

        var vTotalPut = valorTotal.toString();
        vTotalPut = vTotalPut.replace(',', '.');

        var wCodSolicitacaoItem = 0
        var wTipoSubmit = '';

        if (novoItem) {
            wCodSolicitacaoItem = 0;
            wTipoSubmit = 'POST';
        } else {
            wCodSolicitacaoItem = codigoItemSelecionado;
            wTipoSubmit = 'PUT';
        }

        //console.log(wCodSolicitacaoItem);
        var data = JSON.stringify({
            "CodigoSolicitacaoItem": wCodSolicitacaoItem,
            "CodigoSolicitacao": CodigoSolicitacao,
            "NumeroItem": itemSelecionado,
            "Nome": nome,
            "Unidade": unidadeEscolhida,
            "Quantidade": quantidade,
            "ValorUnitario": vUnitarioFormatadoPut,
            "ValorTotal": valorTotal,
            "CodigoFinanceiro": 0,
            "Patrimonio": "",
            "Localizacao": "",
            "CodCadastro": wCodigoCredor,
            "Pago": pago,
            "Frete": frete

        });

        var config = {
            method: wTipoSubmit,
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    setShowFormEditItem(false);
                    setShowFormListaItem(true);
                    PegaItem();
                } else {
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                setShowModalErro(true);
            });
    }

    const handleNovoFile = (event) => {
        if (event.target.files) {
            setNovoFile(event.target.files[0]);
        }
    };

    function GravaTotalSolic(ValorTotalSolic) {

        var wtokenBearer = "Bearer " + localStorage.getItem("token");

        var data = JSON.stringify({
            "ValorTotal": ValorTotalSolic,
            "ValorLiquido": ValorTotalSolic
        });

        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao/GravaTotalSolicitacao/' + CodigoSolicitacao,
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {

                    //setNovoItem(false);
                    //PegaItem();
                    //setShowModalEdit(true);
                } else {
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                //setShowModalErro(true);
            });

    }


    function GravaArquivo(wDatSituacao) {

        setLoading(true);

        const wtoken = "Bearer " + wTokenLocal;

        //trata nome arquivo, tira acentos, espaços
        let wNomeArquivoLimpo = novoFile.name
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
        wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        wNomeArquivoLimpo = CodigoSolicitacao + "_" + wNomeArquivoLimpo

        wDatSituacao = moment(wDatSituacao).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        var datFiles = JSON.stringify({
            "CodigoArquivo": 0,
            "CodigoSolicitacao": CodigoSolicitacao,
            "descricao": descricaoArquivo,
            "arquivo": wNomeArquivoLimpo, //CodCadastro + "_" + ArqCadastro.name,
            "status": 0
        })
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcesso',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
            data: datFiles
        };
        //console.log(datFiles)
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    //faz upload do arquivo
                    const file = novoFile;
                    const novoNome = wNomeArquivoLimpo;
                    const novoArquivo = new File([file], novoNome, { type: file.type });
                    const formData = new FormData();
                    formData.append('arquivo', novoArquivo);

                    var config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/UploadArquivo',
                        headers: {
                            'Authorization': wtoken,
                            'Content-Type': 'multipart/form-dataa',
                            'pasta': 'ArquivosProcesso'
                        },
                        data: formData
                    };

                    axios(config)

                        .then(response => {

                            var wCodigoAprovador = localStorage.getItem("CodigoUsuario");
                            //var wCodigoPerfil = localStorage.getItem("CodigoPerfil");
                            var datHoje = new Date();
                            var wMensagemNovaStatus = '<strong>Arquivo Inserido Por:</strong><br> ' + wNomeUsuario + '<br>Arquivo: ' + wNomeArquivoLimpo + '<br>' + descricaoArquivo;

                            var data = JSON.stringify({
                                "CodigoStatus": 0,
                                "CodigoSolicitacao": CodigoSolicitacao,
                                "CodigoAprovador": wCodigoAprovador,
                                "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                "status": true,
                                "Motivo": wMensagemNovaStatus,
                                "CodigoPerfil": wUsuario,
                                "Doc": "",
                                "PerfilDestino": wUsuario,
                                "CodCadastro": localStorage.getItem("CodigoCadastro"),
                            });

                            var config = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/Status',
                                headers: {
                                    'Authorization': wtoken,
                                    'Content-Type': 'application/json'
                                },
                                data: data
                            };
                            //console.log(data)
                            axios(config)

                                .then(function (response) {
                                    //console.log(response.data);
                                    setDescricaoArquivo('');
                                    setNovoFile(null);
                                    fileInputRef.current.value = '';
                                    //console.log(response.data);
                                    ListaArquivos();
                                    setLoading(false);
                                })
                                .catch(error => {
                                    console.error(error);
                                });

                        })
                        .catch(error => {
                            console.error(error);
                        });
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                }

            })
            .catch(function (error) {
                console.log(error);

            });
    }

    const ListaArquivos = async () => {

        const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcesso?CodigoSolicitacao=' + CodigoSolicitacao,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                //console.log(data.objetoRetorno);
                setArquivosInseridos(data.objetoRetorno);

            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        ListaArquivos();
        // eslint-disable-next-line
    }, []);


    const ConsultaSaldo = () => {
        //pega as variaveis para consultar o saldo
        //CodMeta | CodFonte | CodFase | Valor total Solic
        //moment(financeiroConvenio.DataInicio).format("DD/MM/yyyy")
        // so consultar saldo se fonte meta fase elemento tipo de contratacao e fornecedor estiver selecionado
        if (fonte !== 0 && metaEscolhida !== 0 && faseEscolhida !== 0 && elementoEscolhido !== 0 && tipoContratacao !== 0 && fornecedorEscolhido !== 0) {
            //console.log(codigoConvenio);
            setShowAviso(false);
            var wtokenBearer = "Bearer " + localStorage.getItem("token")
            var data = JSON.stringify({
                "CodigoConvenio": codigoConvenio,
                "Data inicial": localStorage.getItem('DataInicioProjeto'),
                "Data final": localStorage.getItem('DataExpiracaoProjeto')
            });

            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Convenio/Financeiro?IsInclude=true',
                headers: {
                    'Authorization': wtokenBearer,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)

                .then(function (response) {

                    //console.log(response.data)
                    if (response.data.isOk === true) {
                        // fez ao consumo sem erro
                        const wTotalCreditosProjeto = response.data.objetoRetorno.Financeiro.TotAportes + response.data.objetoRetorno.Financeiro.TotRepasses + response.data.objetoRetorno.Financeiro.TotRendimentos;
                        const wTotalDebitosProjeto = response.data.objetoRetorno.Financeiro.TotPagamentosBruto + response.data.objetoRetorno.Financeiro.SolicAPagar + response.data.objetoRetorno.Financeiro.TotDevAportes;
                        const SaldoProjeto = wTotalCreditosProjeto - wTotalDebitosProjeto;
                        //se o saldoprojeto for maior que o toal da solic, continua a consulta com as variaveis meta fase fonte valor total
                        //console.log(SaldoProjeto);
                        //console.log(solicitacao[0].ValorTotal);
                        if (SaldoProjeto < solicitacao[0].ValorTotal) {
                            // nao tem saldo financeiro no projeto para essa solicitação
                            setAvisoSaldo('Projeto sem Saldo financeiro para essa Solicitação');
                            setAlertaSaldo(true);
                        } else {
                            // tem saldo, agora enviar as variaveis e consultar se tem saldo na meta fase fonte elemento
                            const wFonte = parseInt(fonte);
                            const wElemento = parseInt(numElemento);
                            const wMeta = parseInt(metaEscolhida);
                            const wFase = numFaseSelecionada;
                            //console.log(wFase);
                            //console.log('Fonte: ' + wFonte + '| CodMeta: ' + wMeta + '| NumFase: ' + wFase + '| CodFase: ' + faseEscolhida + '| NumElemento: ' + wElemento + '| CodElemento: ' + elementoEscolhido);
                            //console.log(pt);
                            //busca a meta que foi escolhida, para depois buscar as fases elemento e valroes gastos e previstos
                            const metasArray = Object.values(pt.Metas);
                            //console.log(metasArray);
                            const metaEncontrada = metasArray.find(meta => meta.CodigoMeta === wMeta);

                            // agora busca a fase dentro da meta
                            const faseArray = Object.values(metaEncontrada.MetaFases);
                            //console.log(faseArray);
                            const faseEncontrada = faseArray.find(fase => fase.NumeroFase === wFase);

                            //agora verifica se tem o elemento solicitado na meta fase fonte
                            const MetaFaseElementoArray = Object.values(faseEncontrada.MetaFasePrevistos);
                            //console.log(MetaFaseElementoArray);
                            const ElementoEncontrado = MetaFaseElementoArray.find(Elemento => Elemento.CodigoElemento === wElemento && Elemento.CodigoFonte === wFonte);

                            //console.log(ElementoEncontrado); // Verifique se o objeto é encontrado corretamente.

                            if (ElementoEncontrado) {
                                //tem elemento, verifica se tem saldo na meta fase elemento fonte
                                const wValorSolic = solicitacao.ValorTotal;
                                const wVprevisto = ElementoEncontrado.Total;
                                const wGasto = ElementoEncontrado.ValGasto + wValorSolic;
                                //console.log(wGasto);
                                //console.log(wVprevisto);
                                if (wGasto > wVprevisto) {
                                    //sem saldo para essa solicitação
                                    setAvisoSaldo('Sem saldo para essa solicitação nessa Fonte | Meta | Fase | Elemento.');
                                    setAlertaSaldo(true);
                                    setBtAtualiza(false);
                                } else {
                                    //tem saldo prossegue gravando a meta fase e fonte na solicitação
                                    setAvisoSaldo('Saldo disponível para esse esse pedido, para confirmar a reserva financeira dessa solicitação, clicar em Confirmar Reserva.');
                                    setAlertaSaldo(true);
                                    setBtAtualiza(true);
                                }
                            } else {
                                //não tem previsao do elemento e fonte na meta fase
                                setAvisoSaldo('Não existe no planejamento da Meta Fase esse Elemento de despesa.');
                                setAlertaSaldo(true);
                                setBtAtualiza(false);
                                //console.log('entrou aqui');
                            }
                            //setAlertaSaldo(false);
                            //setBtAtualiza(false);
                        }

                    } else {
                        //deu algum erro tratar , colocar algum aviso
                        //setShowModalErro(true);
                        console.log('erro');
                    }
                })
                .catch(error => {
                    console.error(error);
                    if (error.response.status === 401) {
                        setLogin(true);
                    }
                    //setShowModalErro(true);
                });

        } else { setShowAviso(true) }

    }



    const getFases = async (wCodigoMeta) => {
        try {
            setLoading(true);
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url: urlAPIlocal + "/api/Fase?CodigoMeta=" + wCodigoMeta + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);
            const data = response.data.objetoRetorno;
            //console.log(data);
            setFasesProjeto(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    function ConfirmaSolic() {

        setLoading(true);

        //primeiro busca solicitação para pegar todos os dados atuais dela
        const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {
                const data = responseSolic.data;
                //console.log(data.objetoRetorno);

                //console.log("TipoLicitacao:", data.objetoRetorno[0].TipoLicitacao);
                /* if (data.objetoRetorno[0].TipoLicitacao.trim() === '') {
                    setLoading(false);
                    setPrecisaTipoContratacao(true);
                } else { */

                //grava na solicitação - Fonte, Meta e Fase que acabou de ser definida e marca ela como finalizada
                var dataEditSolic = JSON.stringify({

                    "CodigoSolicitacao": CodigoSolicitacao,
                    "DataSolicitacao": data.objetoRetorno[0].DataSolicitacao,
                    "NumeroSolicitacao": data.objetoRetorno[0].NumeroSolic,
                    "CodigoFonte": fonte,
                    "CodigoConvenio": data.objetoRetorno[0].CodigoConvenio,
                    "CodigoCredor": fornecedorEscolhido, //data.objetoRetorno[0].CodigoCredor,
                    "CodigoElemento": elementoEscolhido, //data.objetoRetorno[0].CodigoElemento,
                    "CodigoMeta": metaEscolhida,
                    "CodigoFase": faseEscolhida,
                    "ValorTotal": data.objetoRetorno[0].ValorTotal,
                    "IndFinalizada": true, //data.objetoRetorno[0].IndFinalizada,
                    "Pago": data.objetoRetorno[0].Pago,
                    "Observacao": data.objetoRetorno[0].Observacao,
                    "CodigoAprovacaoCampus": data.objetoRetorno[0].CodigoAprovacaoCampus,
                    "CodigoAprovacaoDARDC": data.objetoRetorno[0].CodigoAprovacaoDARDC,
                    "CodigoAprovacaoConvenente": 1, //data.objetoRetorno[0].CodigoAprovacaoConvenente,
                    "CodigoAprovacaoDPCC": data.objetoRetorno[0].CodigoAprovacaoDPCC,
                    "NumeroParecer": data.objetoRetorno[0].NumeroParecer,
                    "DataParecer": data.objetoRetorno[0].DataParecer,
                    "ValorLiquido": data.objetoRetorno[0].ValorLiquido,
                    "ValorISS": data.objetoRetorno[0].ValorISS,
                    "ValorINSS": data.objetoRetorno[0].ValorINSS,
                    "ValorIRRF": data.objetoRetorno[0].ValorIRRF,
                    "ValorINSS20": data.objetoRetorno[0].ValorINSS20,
                    "CodigoSolicitacaoMae": data.objetoRetorno[0].CodigoSolicitacaoMae,
                    "ValorDesconto": data.objetoRetorno[0].ValorDesconto,
                    "DataPagamento": data.objetoRetorno[0].DataPagamento,
                    "Issp": data.objetoRetorno[0].Issp,
                    "Inssp": data.objetoRetorno[0].Inssp,
                    "Irrfp": data.objetoRetorno[0].Irrfp,
                    "Inss20p": data.objetoRetorno[0].Inss20p,
                    "DataISS": data.objetoRetorno[0].DataISS,
                    "DataINSS": data.objetoRetorno[0].DataINSS,
                    "DataIRRF": data.objetoRetorno[0].DataIRRF,
                    "DataINSS20": data.objetoRetorno[0].DataINSS20,
                    "NomeISS": data.objetoRetorno[0].NomeISS,
                    "NomeINSS": data.objetoRetorno[0].NomeINSS,
                    "NomeIRRF": data.objetoRetorno[0].NomeIRRF,
                    "NomeINSS20": data.objetoRetorno[0].NomeINSS20,
                    "ReciboDet": data.objetoRetorno[0].ReciboDet,
                    "ReciboDatDe": data.objetoRetorno[0].ReciboDatDe,
                    "ReciboDataTe": data.objetoRetorno[0].ReciboDataTe,
                    "ReciboHoras": data.objetoRetorno[0].ReciboHoras,
                    "ReciboBaseInss": data.objetoRetorno[0].ReciboBaseInss,
                    "ReciboBaseIrrf": data.objetoRetorno[0].ReciboBaseIrrf,
                    "NumeroContrato": data.objetoRetorno[0].NumeroContrato,
                    "Indeferida": data.objetoRetorno[0].Indeferida,
                    "Regularizada": data.objetoRetorno[0].Regularizada,
                    "Excluida": data.objetoRetorno[0].Excluida,
                    "Justificativa": data.objetoRetorno[0].Justificativa,
                    "Setor": data.objetoRetorno[0].setor,
                    "ValorOutrosImpostos": data.objetoRetorno[0].ValorOutrosImpostos,
                    "OutrosImpostos": data.objetoRetorno[0].OutrosImpostos,
                    "DataOutrosImpostos": data.objetoRetorno[0].DataOutrosImpostos,
                    "NomeOutrosImpostos": data.objetoRetorno[0].NomeOutrosImpostos,
                    "DataRPA": data.objetoRetorno[0].DataRPA,
                    "MeioTransporte": data.objetoRetorno[0].MeioTransporte,
                    "TipoLicitacao": tipoContratacao, //data.objetoRetorno[0].TipoLicitacao,
                    "PerfilDestinoAtual": data.objetoRetorno[0].PerfilDestinoAtual,
                    "CodigoProcessoInterno": data.objetoRetorno[0].CodProcessoInterno,

                });

                var config = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Solicitacao',
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json'
                    },
                    data: dataEditSolic
                };
                //console.log(data)
                axios(config)

                    .then(function (response) {

                        //console.log(response.data)
                        if (response.data.isOk === true) {
                            //PegaStatus()
                            //grava status da solicitacao no tblstatus
                            gravaRegistroStatus(response.data.objetoRetorno.CodigoSolicitacao, '');

                            setAvisoSaldo('Solicitação Atualizada com Sucesso! - Encaminhe agora para o setor responsável dar andamento.');
                            setBtAtualiza(false);
                            setAlertaSaldo(true);
                            getSolicitacao();
                            setLoading(false);


                        } else {
                            //setShowModalErro(true);

                            setLoading(false);
                            setAvisoSaldo('Algo deu errado, tente atualizar novamente.');
                            setAlertaSaldo(true);
                        }
                    })
                    .catch(error => {
                        console.error(error);

                        setLoading(false);
                        setShowModalErro(true);


                    });
            }
                //}
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    function gravaRegistroStatus(wCodigoSolicitacao, wMensagemRegularizacao) {

        //antes de gravar novo status, pega o ultimo status da solicitacao

        var wCodigoAprovador = localStorage.getItem("CodigoUsuario");
        var wCodigoUsuario = localStorage.getItem("CodigoUsuario");

        var wDataSolicitacao;
        wDataSolicitacao = new Date();
        wDataSolicitacao = moment(wDataSolicitacao).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        //pega ultimo status
        var wUltimoStatus = status.length - 1
        var wMotivo;
        if (wMensagemRegularizacao) {
            wMotivo = wMensagemRegularizacao;
        } else {
            wMotivo = "<br><br><br><strong>Processo Aprovado Financeiramente por:</strong> " + localStorage.getItem("NomeUsuario");
        }
        var wCodigoUsuario = status[wUltimoStatus].CodigoPerfil
        var wCodPerfilDestino;
        if (wMensagemRegularizacao) {
            wCodPerfilDestino = setorRegularizacao;
        } else {
            wCodPerfilDestino = status[wUltimoStatus].PerfilDestino;
        }
        //var wCodPerfilDestino = status[wUltimoStatus].PerfilDestino

        var data = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": wCodigoSolicitacao,
            "CodigoAprovador": wCodigoAprovador,
            "DataAprovacao": wDataSolicitacao,
            "status": true,
            "Motivo": wMotivo,
            "CodigoPerfil": wCodigoAprovador,
            "Doc": "",
            "PerfilDestino": wCodPerfilDestino,
            "CodCadastro": 155,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': 'Bearer ' + wTokenLocal,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {
                //console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    function PegaStatus() {
        const wtoken = "Bearer " + wTokenLocal;

        var configStatus = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };

        axios(configStatus)
            .then(function (responseStatus) {
                const data = responseStatus.data;
                setStatus(data.objetoRetorno);
                //console.log(data.objetoRetorno);

                getIconColor(data.objetoRetorno[0].Perfil_Destino.Nome);

            })
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    function getIconColor(nomePerfil) {
        if (nomePerfil === 'Compras') {
            return '#2980B9';
        } else if (nomePerfil === 'Financeiro') {
            return '#27AE60';
        } else if (nomePerfil === 'Assessoria Jurídica') {
            return '#CB4335';
        } else if (nomePerfil === 'Diretoria Administrativa') {
            return '#F4D03F';
        } else if (nomePerfil === 'Recursos Humanos') {
            return '#8E44AD';
        } else if (nomePerfil === 'Secretário Executivo') {
            return '#0B5345';
        } else if (nomePerfil === 'Consultor') {
            return '#626567';
        } else if (nomePerfil === 'Gestão de Projetos') {
            return '#E67E22';
        } else if (nomePerfil === 'Equipe Area') {
            return '#85C1E9';
        } else {
            return '#34495E';
        }
    };

    function AlimentaPT() {
        //console.log(solicitacao);
        //console.log(dados);
        const wtoken = "Bearer " + wTokenLocal;

        const config = {
            method: "GET",
            url:
                urlAPIlocal + "/api/ApiPlanoTrabalho/" + codigoConvenio,
            headers: {
                Authorization: wtoken,
                "Content-Type": "application/json",
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    var dados = response.data.objetoRetorno;
                    //console.log(dados);
                    setPt(dados);

                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //Retorno = true
                }

            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
        //return Retorno;
    }
    useEffect(() => {
        if (solicitacao && solicitacao.length > 0 && codigoConvenio) {
            AlimentaPT();
        }
        // eslint-disable-next-line
    }, [solicitacao]);

    function PegaNumElemento(CodElemento) {

        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ConvenioElemento?CodigoConvenioElemento=' + CodElemento + '&IsInclude=false',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                //console.log(response)
                const data = response.data;
                if (response.data.isOk === true) {
                    setNumElemento(data.objetoRetorno[0].CodigoElemento);
                    //console.log(data.objetoRetorno[0].CodigoElemento)
                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setNumElemento('');
                }
            }
            )
            .catch(function (error) {
                console.log(error);

            });

    }



    return (
        <div className={Style.page}>
            <Menu />
            <div> <button className={Style.voltar}><Link to={`/index`}><IoMdArrowBack /> Voltar</Link></button> </div>
            <div className={Style.form_container}>
                <div className={Style.header}>
                    <header>Solicitação N° {CodigoSolicitacao} / {anoSolic}</header>
                    {wPerfil === '38' && <button className={Style.pt_btn}><Link to={`/PtFinanceiro/${codigoConvenio}`} target="_blank">Ver Plano de Trabalho <BsArrowRight /></Link></button>}
                    {wPerfil === '17' &&
                        <div>
                            <button type='button' className={Style.orcamento_btn}><Link to={`/SolicOrcamento/${CodigoSolicitacao}`} target='_blank' >Solicitar Orçamento</Link></button>
                            <button type='button' className={Style.comparativo_btn}><Link to={`/SolicComparativo/${CodigoSolicitacao}`}>Comparativo Preços</Link></button>
                            <button type='button' className={Style.enviaaf_btn}><Link to={`/EnviaAF/${CodigoSolicitacao}`} >Enviar A. Fornecimento</Link></button>
                            {/* style={{ backgroundColor: '#ccc' }} */}
                        </div>
                    }
                </div>

                <div>
                    <p className={Style.form_group_label2}><strong>Credor: {fornecedorAtualNome}</strong></p>
                </div>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>

                        <Row>
                            <Col>
                                <Form.Label className={Style.form_group_label}>Novo Fornecedor</Form.Label>
                                <Form.Select className={Style.form_control} name="credor" id="credor" value={fornecedorEscolhido} onChange={handleSelectFornecedor}>
                                    <option value="0">Selecione um Fornecedor</option>
                                    {fornecedores.length > 0 &&
                                        fornecedores.map((opcao) => (
                                            <option key={opcao.CodigoCadastro} value={opcao.CodigoCadastro}>{opcao.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label className={Style.form_group_label}>Fonte do Recurso</Form.Label>
                                <Form.Select className={Style.form_control} name="fonte" id="fonte" value={fonte} onChange={handleSelectFonte} disabled={!wPerfil === '38'}>
                                    <option value="0">Selecione a Fonte</option>
                                    <option value="1">1 - Concedente</option>
                                    <option value="2">2 - Convenente</option>
                                    <option value="3">3 - Executor</option>
                                    <option value="4">4 - Interveniente</option>
                                    <option value="5">5 - Rendimentos</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label className={Style.form_group_label}>Elemento (Tipo de Despesa)</Form.Label>
                                <Form.Select className={Style.form_control} name="elementos" id="elementos" value={elementoEscolhido}
                                    onChange={handleSelectElemento}>
                                    <option value="0">Selecione o Tipo de Despesa</option>
                                    {elementos.length > 0 &&
                                        elementos.map((opcao) => (
                                            <option key={opcao.CodigoConvenioElemento} value={opcao.CodigoConvenioElemento}>{opcao.CodigoElemento} - {opcao.Elemento.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={Style.form_group_label}>Tipo de Contratação</Form.Label>
                                <Form.Select className={Style.form_control} name="tipoContratação" value={tipoContratacao} onChange={handleSelectTipoContratacao} required >
                                    <option value="">Selecione o Tipo de Contratação</option>
                                    <option value="Dispensa">Dispensa</option>
                                    <option value="Convite">Convite</option>
                                    <option value="Tomada de Preços">Tomada de Preços</option>
                                    <option value="Concorrência">Concorrência</option>
                                    <option value="Pregão Eletrônico">Pregão Eletrônico</option>
                                    <option value="Pregão Presencial">Pregão Presencial</option>
                                    <option value="Inexigível">Inexigível</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label className={Style.form_group_label}>Meta</Form.Label>
                                <Form.Select
                                    className={Style.form_control}
                                    name="Meta"
                                    id="Meta"
                                    //value={!temMeta ? 'A Definir' : (meta.NumeroMeta + ' - ' + meta.Nome)}
                                    value={metaEscolhida}
                                    onChange={handleSelectMeta} // Se necessário no futuro, remova 'disabled' para habilitar a interação
                                    disabled={wPerfil !== '38'}
                                >
                                    <option value="0">Selecione a Meta</option>
                                    {metasProjeto.length > 0 &&
                                        metasProjeto.map((opcao) => (
                                            <option key={opcao.CodigoMeta} value={opcao.CodigoMeta}>
                                                {opcao.NumMeta} - {opcao.Nome}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label className={Style.form_group_label}>Fase</Form.Label>
                                <Form.Select
                                    className={Style.form_control}
                                    name="Fase"
                                    id="Fase"
                                    //value={!temFase ? 'A Definir' : (fase.NumeroFase + ' - ' + fase.Nome)}
                                    value={faseEscolhida}
                                    onChange={handleSelectFase} // Se necessário no futuro, remova 'disabled' para habilitar a interação
                                    required={metaEscolhida !== 0}
                                    disabled={wPerfil !== '38'}
                                >
                                    <option value="0">Selecione a Fase</option>
                                    {fasesProjeto.length > 0 &&
                                        fasesProjeto.map((opcao) => (
                                            <option key={opcao.CodigoConvenioFase} value={opcao.CodigoConvenioFase}>
                                                {opcao.NumeroFase} - {opcao.Nome}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <br></br>
                                {wPerfil === '38' && <button className={Style.saldo_btn} type="button" onClick={(event) => ConsultaSaldo()}>Consultar Saldo</button>}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {/* {console.log(alertaSaldo)}
                                                {console.log(avisoSaldo)} */}
                                {showAviso && (
                                    <div className={Style.avisoSaldo}>
                                        <p>Selecione Fornecedor, Elemento, Fonte, Meta e Fase</p>
                                    </div>
                                )}
                                {alertaSaldo && (
                                    <div className={Style.avisoSaldo}>
                                        <p>{avisoSaldo}</p>
                                    </div>
                                )}
                                {btAtualiza && (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '0 auto' }}>
                                        <p></p>
                                        <p align="center"><button className={Style.VerAquivo_btn} type="button" onClick={(event) => ConfirmaSolic()}>Confirmar Reserva</button></p>
                                    </div>
                                )}

                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={Style.form_group_label}>Observações</Form.Label>
                                <ReactQuill
                                    theme="snow"
                                    value={observacao}
                                    onChange={handleObservacaoChange}
                                    className={Style.textarea}
                                />
                                {/* <div style={{ color: '#707070', marginBottom: '.5em' }} dangerouslySetInnerHTML={{ __html: observacao }} />
                                <Form.Control className={Style.form_control_textarea} as="textarea" name="obs" placeholder="Observações adicionais" onChange={(event) => setObservacaoAdicional(event.target.value)} /> */}
                            </Col>
                        </Row>
                        <br></br>
                        <br></br>
                        <Row>
                            <Col>
                                <Form.Label className={Style.form_group_label}>Justificativa</Form.Label>
                                <ReactQuill
                                    theme="snow"
                                    value={justificativa}
                                    onChange={handleJustificativaChange}
                                    className={Style.textarea}
                                />
                                {/* <div style={{ color: '#707070', marginBottom: '.5em' }} dangerouslySetInnerHTML={{ __html: justificativa }} />
                                <Form.Control className={Style.form_control_textarea} as="textarea" placeholder="Motivos adicionais" onChange={(event) => setJustificativaAdicional(event.target.value)}></Form.Control> */}
                            </Col>
                        </Row>
                        <br></br>
                        <br></br>

                        {codigoDPCC > 0 &&
                            <div style={{ display: 'flex', justifyContent: 'center', margin: '0 auto', borderRadius: '8px', backgroundColor: '#FEF9E7', color: '#F39C12', fontSize: '14px', fontWeight: '600', padding: '.3em', marginBottom: '1em' }}>
                                <p style={{ margin: '.3em' }}><BiSolidError style={{ fontSize: '16px', marginBottom: '.2em' }} /> Essa solicitação já foi aprovada pelo financeiro, por isso não é possível alterar o valor dela.</p>
                            </div>}
                        {showFormListaItem &&
                            <Table className={Style.table}>
                                <thead>
                                    <tr>
                                        <th>Pago?</th>
                                        <th>Fornecedor/Credor</th>
                                        <th>Descrição</th>
                                        <th>Unidade</th>
                                        <th>Quantidade</th>
                                        <th style={{ width: '10em', textAlign: 'right' }}>Valor Unitário</th>
                                        <th style={{ width: '10em', textAlign: 'right' }}>Valor Total</th>
                                        <th align='center' style={{ backgroundColor: '#ffffff', verticalAlign: 'middle', width: '10em' }}>
                                            <button
                                                className={Style.novoItem_btn}
                                                style={codigoDPCC > 0 ? { color: 'gray', cursor: 'not-allowed', backgroundColor: '#F8F9F9', border: 'none' } : {}}
                                                type="button"
                                                onClick={() => handleNovoItem()}
                                                disabled={codigoDPCC > 0}
                                            >
                                                Novo item <AiFillPlusCircle />
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item.map((itens) => {
                                        return (
                                            <tr key={itens.CodigoSolicitacaoItem}>
                                                <td align='center'>{itens.Pago ? 'Sim' : 'Não'}</td>
                                                <td style={{ fontSize: '14px' }}>
                                                    {itens.CodCadastro === 0 ? 'Definir' : itens.Cadastro.Nome}
                                                </td>
                                                <td>{itens.Nome}</td>
                                                <td align='center'>{itens.Unidade}</td>
                                                <td align='center'>{itens.Quantidade}</td>
                                                <td align='right'>{itens.ValorUnitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td align='right'>{itens.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td align='center'>
                                                    <button
                                                        type="button"
                                                        className={Style.btn_edit}
                                                        onClick={(event) => handleForm(itens.CodigoSolicitacaoItem)}
                                                        disabled={codigoDPCC > 0}
                                                        style={codigoDPCC > 0 ? { color: 'gray', cursor: 'not-allowed', backgroundColor: '#F8F9F9', border: 'none' } : {}}
                                                    >
                                                        <MdEditNote />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={Style.btn_delete}
                                                        onClick={(event) => ExcluirItem(itens.CodigoSolicitacaoItem)}
                                                        disabled={codigoDPCC > 0}
                                                        style={codigoDPCC > 0 ? { color: 'gray', cursor: 'not-allowed', backgroundColor: '#F8F9F9', border: 'none' } : {}}
                                                    >
                                                        <TiDelete />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {/* {solicitacao && solicitacao[0] && (
                                    <tr>
                                        <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                        <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                        <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                        
                                        <td colSpan={2} style={{ backgroundColor: '#F2F3F4' }}>Total Líquido</td>
                                        <td align='center' style={{ backgroundColor: '#F2F3F4' }}>
                                            {solicitacao[0].ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                        <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                    </tr>
                                )} */}

                                    {solicitacao && solicitacao[0] && (
                                        <tr>
                                            <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                            <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                            <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                            <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                            <td style={{ backgroundColor: '#F2F3F4' }}></td>
                                            <td colSpan={2} style={{ backgroundColor: '#F2F3F4', textAlign: 'right' }}><strong>Total da Solicitação: {solicitacao[0].ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong></td>
                                            <td align='center' style={{ backgroundColor: '#F2F3F4', fontWeight: '600' }}>
                                                {/* Bruto: {solicitacao[0].ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} */}
                                            </td>
                                            <td align='center' style={{ backgroundColor: '#F2F3F4', fontWeight: '600' }}>

                                                {/* Líquido: {solicitacao[0].ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} */}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        }
                        {showFormEdititem &&
                            <div className={Style.editForm_container}>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <Form.Label className={Style.form_group_label}>Fornecedor/Credor</Form.Label>
                                            {/* {console.log(fornecedorEscolhidoItem)} */}
                                            <Form.Select className={Style.form_control} name="credorItem" id="credorItem" value={fornecedorEscolhidoItem} onChange={handleSelectFornecedorItem}>
                                                <option value="0">Selecione um novo Fornecedor</option>
                                                {fornecedoresItem.length > 0 &&
                                                    fornecedoresItem.map((opcao) => (
                                                        <option key={opcao.CodigoCadastro} value={opcao.CodigoCadastro}>{opcao.Nome}</option>
                                                    ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={1}>
                                            <Form.Label className={Style.form_group_label}>Item</Form.Label>
                                            <Form.Control className={Style.form_control} type="text" name="NumeroItem" value={itemSelecionado} disabled></Form.Control>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label className={Style.form_group_label}>Nome</Form.Label>
                                            <Form.Control className={Style.form_control} type="text" name="Nome" value={nome} onChange={(event) => setNome(event.target.value)} required></Form.Control>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Label className={Style.form_group_label}>Unidade</Form.Label>
                                            <Form.Select className={Style.form_control} name="unidade" id="unidade" value={unidadeEscolhida} onChange={handleSelectUnidade} required>
                                                <option>Selecione uma opção</option>
                                                {unidade.length > 0 &&
                                                    unidade.map((opcao) => (
                                                        <option key={opcao.CodigoUnidade} value={opcao.CodigoUnidade}>{opcao.Nome}</option>
                                                    ))}
                                            </Form.Select>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Label className={Style.form_group_label}>Quantidade</Form.Label>
                                            <Form.Control className={Style.form_control} type="text" name="Nome" value={quantidade} onChange={(event) => setQuantidade(event.target.value)} required></Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Label className={Style.form_group_label}>Valor Unitário</Form.Label>
                                            <Form.Control className={Style.form_control} type="text" value={valorUnitario} onChange={handleSomaValor} required />
                                        </Col>
                                        <Col>
                                            <Form.Label className={Style.form_group_label}>Valor Total</Form.Label>
                                            <Form.Control className={Style.form_control} type="text" value={valorTotal} onChange={(event) => setValorTotal(event.target.value)} disabled />
                                        </Col>
                                    </Row>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', maxWidth: '300px', margin: '0 auto' }}>

                                        <button className={Style.cancelar_btn} type="button" onClick={() => { setShowFormEditItem(false); setShowFormListaItem(true); }}>
                                            Cancelar
                                        </button>

                                        <button className={Style.salvar_btn} type="button" onClick={() => handleEditSubmit()}>
                                            Salvar
                                        </button>

                                    </div>
                                </FormGroup>

                            </div>
                        }

                        <Row>
                            <Col>
                                <h4 style={{ textAlign: 'center', fontSize: '18px', color: '#626567', padding: '1em' }}>Anexar Arquivos</h4>
                                {/* <Form.Label className={Style.form_label_encaminhamento}>Anexar Arquivo</Form.Label> */}
                            </Col>
                        </Row>

                        <Row style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', marginLeft: '2em' }}>
                            <Col md={5}>
                                <Form.Label className={Style.form_label_encaminhamento}>Descrição:</Form.Label>
                                <Form.Control name="descricaoarquivo" value={descricaoArquivo} onChange={(event) => setDescricaoArquivo(event.target.value)} style={{ color: '#707070' }} />
                            </Col>
                            <Col md={5}>
                                <Form.Label style={{ color: '#fff', fontSize: '18px' }}>.</Form.Label>
                                <Form.Control name="NovoFile" type="file" accept="application/pdf" onChange={handleNovoFile} ref={fileInputRef} />
                            </Col>
                            <Col>

                                <button className={Style.gravarDoc_btn} type="button" onClick={GravaArquivo}>
                                    Inserir
                                    <span><MdOutlineFileUpload /></span>
                                </button>
                            </Col>
                        </Row>

                        <div style={{ padding: '1em', paddingTop: '2em' }}>
                            {arquivosInseridos !== null &&
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ padding: '.3em', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Descrição</th>
                                            <th style={{ padding: '.3em', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Arquivo</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arquivosInseridos
                                            .filter((arquivo) => arquivo.Status === 0 && !arquivo.Arquivo.startsWith('SolicitacaoOrcamento_'))
                                            .map((arquivo) => (
                                                <tr key={arquivo.CodigoArquivo}>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', verticalAlign: 'middle' }}>
                                                        {arquivo.Descricao}
                                                    </td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', verticalAlign: 'middle' }}>
                                                        <a href={LinkArquivo + arquivo.Arquivo} target="_blank" rel="noreferrer">
                                                            {arquivo.Arquivo}
                                                        </a>
                                                    </td>
                                                    <td align="center">
                                                        <iframe src={LinkArquivo + arquivo.Arquivo} type="application/pdf" width="70%" height="100%"></iframe>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            }
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '0 auto' }}>
                            <button className={Style.finalizar_btn} type="submit">
                                Atualizar Solicitação
                            </button>
                        </div>
                    </FormGroup>
                </Form>
            </div>

            <Modal show={loading} className={Style.Modal} centered >
                <Modal.Body className={Style.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onLoad={handleClose} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>A Solicitação foi Atualizada!</p>
                </Modal.Body>
            </Modal>

            <Modal show={showModalEdit} onLoad={handleCloseEdit} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    Item editado com sucesso!
                </Modal.Body>
            </Modal>

            <Modal show={showModalDelete} onLoad={handleCloseDelete} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    O Item foi Excluído
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={Style.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={Style.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

            <Modal show={showModalErroElemento} className={Style.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErroElemento} className={Style.Modal_header}>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Selecione um Elemento</p>
                </Modal.Body>
            </Modal>

            {login && (<Navigate to="/" replace={true} />)}
            {redirIndex && (<Navigate to="/index" replace={true} />)}
        </div >
    )
}
export default EditSolic