
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { Navigate } from "react-router";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Cabecalho from "../img/Cabecalho.png"
import Styles from "./PrintSolic.module.css";
import urlAPIlocal from '../../global';
import { Box } from '@mui/material';
import moment from "moment";
import React from 'react';
import { PDFDocument } from 'pdf-lib';
//import { Document, Page } from '@react-pdf/renderer';

import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
import { ImSad2 } from 'react-icons/im';
import loadingGif from '../img/icons8-spinning-circle.gif';

//var wCodCredor = 0



function PrintProcesso() {

    const { CodigoSolicitacao } = useParams();
    const [arquivosInseridos, setArquivosInseridos] = useState([]);
    const [PdfMesclado, setPdfMesclado] = useState([]);
    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosProcesso/"

    var wTokenLocal = localStorage.getItem("token");

    const ListaArquivos = async () => {
        const wtoken = "Bearer " + wTokenLocal;

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcesso?CodigoSolicitacao=' + CodigoSolicitacao,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };

        try {
            const responseSolic = await axios(configSolic);
            const data = responseSolic.data;
            //console.log(data.objetoRetorno);

            // Configura o estado com os documentos
            setArquivosInseridos(data.objetoRetorno);

            // Crie um novo documento PDF vazio
            const mergedPdfDoc = await PDFDocument.create();

            for (const documento of data.objetoRetorno) {
                // Crie o URL completo para o documento
                const documentoUrl = LinkArquivo + documento.Arquivo;

                // Carregue o documento diretamente pelo URL
                const response = await fetch(documentoUrl);
                const documentoBytes = await response.arrayBuffer();
                const pdfDoc = await PDFDocument.load(documentoBytes);

                // Copie as páginas para o novo documento
                const pages = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
                pages.forEach((page) => mergedPdfDoc.addPage(page));
            }

            // Serialize o novo documento PDF para bytes
            const mergedPdfBytes = await mergedPdfDoc.save();

            // Crie um URL de Blob para exibição ou impressão
            const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
            const mergedPdfUrl = URL.createObjectURL(mergedPdfBlob);
            // Agora, 'mergedPdfUrl' contém o URL do PDF mesclado que você pode usar para exibir no iframe

            setPdfMesclado(mergedPdfUrl);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        ListaArquivos();
        // eslint-disable-next-line
    }, []);


    return (
        <>
            {/* Documentos do Processo */}
            <div style={{ margin: '0 auto', display: 'flex', flexDirection: 'column' }}>
                <iframe src={PdfMesclado} width="595" height="842" title="PDF Mesclado"></iframe>
            </div>
        </>
    )

};


export default PrintProcesso;