import Form from 'react-bootstrap/Form'
import { FormGroup, Col } from "react-bootstrap"
import StyleForm from './CadFornecedor.module.css'
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { AiOutlineSearch } from 'react-icons/ai';
import InputMask from 'react-input-mask';
import { cnpj } from 'cpf-cnpj-validator';
import axios from 'axios';
import urlAPIlocal from '../../../global';




const ConsultaCNPJ = () => {

    const [NumCnpj, setNumCnpj] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [ConsultaCnpj, setConsultaCnpj] = useState([])


    const handleSubmit = async (event) => {
        event.preventDefault();

        setNumCnpj(NumCnpj);
        setIsValid(true);

        try {

            if (!cnpj.isValid(NumCnpj)) {
                setIsValid(false);
                return;
            } else {

                // se for valido , consultar se ja tem cadastro
                //pagina publica tem que autorizar a consulta api antes
                const responsetoken = await axios.post(urlAPIlocal + '/api/Login', {
                    login: "ronaldo",
                    senha: "rachel@78"
                });
                // grava o token do usuario para usar depois nas outras consultas api
                var wtoken = responsetoken.data.objetoRetorno.Token;
                localStorage.setItem("token", wtoken);

                setConsultaCnpj(NumCnpj)
                const response = await axios.get(urlAPIlocal + "/api/Cadastro?NumeroCnpj=" + ConsultaCnpj);

                const data = response.data;
                setConsultaCnpj(data.objetoRetorno);
                //console.log(data.objetoRetorno)
            }


        } catch (error) {
            console.error(error);
            //setErrorMessage('Nome do usuário ou senha incorretos. Por favor, tente novamente.');
        }
    }



    return (
        <div className={StyleForm.page}>
            <div className={StyleForm.header_cnpj}>
                <h1>Consulta CNPJ</h1>
                <p>Insira o CNPJ para verificar se já é cadastrado</p>
            </div>


            <div className={StyleForm.container}>
                <Form className={StyleForm.form} onSubmit={handleSubmit}>


                    <FormGroup className={StyleForm.form_group_cnpj}>

                        <Row>

                            <Form.Label className={StyleForm.form_group_label}>CNPJ</Form.Label>
                            <InputMask className={StyleForm.form_control} mask="99.999.999/9999-99" value={NumCnpj} onChange={(e) => setNumCnpj(e.target.value)} />
                            {!isValid && <p className={StyleForm.alert}>CNPJ inválido</p>}
                            {/* <Form.Control className={StyleForm.form_control} type="text" name="numCNPJ"/> */}

                        </Row>
                        <div class="row justify-content-end" >
                            <Row>
                                <Col class="col-sm-4">
                                    <button className={StyleForm.btn} type="submit"><spam>Consultar</spam> <AiOutlineSearch /></button>
                                </Col>
                            </Row>
                        </div>

                    </FormGroup>

                </Form>
            </div>

        </div>

    )
}
export default ConsultaCNPJ