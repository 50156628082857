import { useState, useEffect } from "react";
import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import urlAPIlocal from "../../global";
import Select from "react-select";
import Style from './SolicOrcamento.module.css'
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import moment from "moment";
import ReactDOMServer from 'react-dom/server';
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
import loadingGif from '../img/icons8-spinning-circle.gif';
import { ImSad2 } from 'react-icons/im';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importe o estilo do editor

function SolicOrcamento() {

    const wtoken = "Bearer " + localStorage.getItem("token");
    const { CodigoSolicitacao } = useParams();
    const [fornecedores, setFornecedores] = useState([]);
    const [fornecedoresSelecionados, setFornecedoresSelecionados] = useState([]);
    const [nomeFornecedoresSelecionados, setNomeFornecedoresSelecionados] = useState('');
    const [solicitacao, setSolicitacao] = useState([]);
    const [item, setItem] = useState([]);
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [assunto, setAssunto] = useState('');
    const mensagemInicial =
        'Prezados,<br><br>Solicitamos de V.Sᵃ. que apresente estimativa de preços para aquisição dos itens anexo especificados em anexo.<br><br><strong>Informações para envio de orçamento:</strong>' +
        '<br>1) Enviar sua proposta em papel timbrado, contendo o CNPJ, endereço e telefone;<br>2) Validade da proposta nunca inferior a 30 (trinta) dias a partir de sua emissão, para que sejam realizados os trâmites internos da FAUSCS para a compra;' +
        '<br><br>Pedimos gentilmente que nos envie sua melhor proposta <strong> até o prazo máximo de 5 dias </strong>';

    const [mensagem, setMensagem] = useState(mensagemInicial);
    const [arquivo, setArquivo] = useState('');
    //var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosProcesso/";
    //const [arquivoSelecionado, setArquivoSelecionado] = useState(null);
    const [arquivosSelecionados, setArquivosSelecionados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);
    const [enviado, setEnviado] = useState(false);
    //const [botaoAtivo, setBotaoAtivo] = useState(false);


    function handleClose() {
        setTimeout(() => {
            setEnviado(false);
        }, 3000);

    }

    function handleCloseErro() {
        setShowModalErro(false);
    };

    const getFornecedores = async () => {

        try {
            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Cadastro?Aprovado=1&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            //filtrar de data somente cadastros do CodigoTipoCadastro = 43 ou 33
            const cadastrosFiltrados = data.filter(item => item.CodigoTipoCadastro === 33 || item.CodigoTipoCadastro === 43);
            //console.log(cadastrosFiltrados);
            setFornecedores(cadastrosFiltrados);

            // var MensagemPadrao = 'Prezados,<br>Solicitamos de V.Sᵃ. que apresente estimativa de preços para aquisição dos itens anexo especificados em anexo.<br><strong>Informações para envio de orçamento:</strong>' +
            //     '<br>1) Enviar sua proposta em papel timbrado, contendo o CNPJ, endereço e telefone;<br>2) Validade da proposta nunca inferior a 30 (trinta) dias a partir de sua emissão, para que sejam realizados os trâmites internos da FAUSCS para a compra;' +
            //     '<br>Pedimos gentilmente que nos envie sua melhor proposta <strong> até o prazo máximo de 5 dias </strong>';
            // const MensagemDecodificada = { __html: MensagemPadrao };
            // setMensagem(MensagemDecodificada);
            //console.log(data);

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getFornecedores();
        // eslint-disable-next-line
    }, []);

    const getSolicitacao = async () => {

        setLoading(true);

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                //console.log(data.objetoRetorno);
                setSolicitacao(data.objetoRetorno[0]);
                PegaItem();
                getArquivo();
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getSolicitacao();
        //eslint-disable-next-line
    }, []);

    function PegaItem() {

        var configItem = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem?CodigoSolicitacao=' + CodigoSolicitacao,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configItem);
        axios(configItem)

            .then(function (responseItem) {

                const data = responseItem.data;
                // Ordenar o array antes de atribuí-lo ao estado
                //console.log(data.objetoRetorno);
                const sortedData = data.objetoRetorno.sort((a, b) => {
                    if (a.NumeroItem < b.NumeroItem) {
                        return -1;
                    }
                    if (a.NumeroItem > b.NumeroItem) {
                        return 1;
                    }
                    return 0;
                });

                setItem(sortedData);
                //setLoading(false);


                //setItem(data.objetoRetorno);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    function getArquivo() {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcesso?CodigoSolicitacao=' + CodigoSolicitacao,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };

        axios(config)

            .then(function (response) {
                const data = response.data;
                setArquivo(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSelectFornecedores = (selectedOptions) => {
        // Mapeie os valores selecionados para um array
        const selectedFornecedores = selectedOptions.map(option => option.email);

        const NomeFornedoresSelecionados = selectedOptions.map(option => option.label);
        const nomesSeparadosPorPontoEVirgula = NomeFornedoresSelecionados.join(', ');
        setNomeFornecedoresSelecionados(nomesSeparadosPorPontoEVirgula);

        // Use o método join para unir os emails com ';'
        const emailsSeparadosPorPontoEVirgula = selectedFornecedores.join(', ');
        //console.log(emailsSeparadosPorPontoEVirgula);
        setFornecedoresSelecionados(emailsSeparadosPorPontoEVirgula);
        //setSelecioneAtividade(false);
    };

    const handleMensagemChange = (value) => {
        setMensagem(value);
    };

    const handleCheckChange = (event) => {

        //const arquivoSelecionado = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // Se o checkbox foi marcado, faça algo
            setArquivosSelecionados([...arquivosSelecionados, event.target.value]);
            //console.log("entrou checado - " + event.target.value);
            //console.log(arquivosSelecionados);
            //'console.log(`Arquivo ${arquivoSelecionado} foi selecionado.`);
            // Adicione a lógica para adicionar o arquivo à lista de arquivos selecionados
        } else {
            //console.log("entrou nao checado - " + event.target.value);
            const novoArray = arquivosSelecionados.filter(arquivo => arquivo !== event.target.value);
            // Atualize o estado com o novo array
            setArquivosSelecionados(novoArray);
            //console.log(arquivosSelecionados);

        }

        //console.log(arquivosSelecionados);
        // // Inverte o estado anterior de botaoAtivo
        // setBotaoAtivo((prevState) => !prevState);
    };

    // function gerarCodigoUnico() {

    //     let codigo = '';
    //     for (let i = 0; i < 4; i++) {
    //         codigo += Math.floor(Math.random() * 10); // Adiciona um número aleatório de 0 a 9 ao código
    //     }
    //     return codigo;
    // }

    const handleCheckItem = (event) => {

        const codigo = event.target.id;
        const nome = event.target.dataset.nome;
        const quantidade = event.target.dataset.quantidade;

        //const arquivoSelecionado = event.target.value;
        const isCheckedItem = event.target.checked;

        if (isCheckedItem) {
            // Se o checkbox foi marcado, faça algo

            // Criar objeto com os valores
            const itemSelecionado = {
                codigo: codigo,
                nome: nome,
                quantidade: quantidade,
            };
            setItensSelecionados((itensAnteriores) => [...itensAnteriores, itemSelecionado]);
            //setItensSelecionados([...itensSelecionados, itemSelecionado]);
            //console.log("entrou checado - " + event.target.value);
            //console.log(arquivosSelecionados);
            //'console.log(`Arquivo ${arquivoSelecionado} foi selecionado.`);
            // Adicione a lógica para adicionar o arquivo à lista de arquivos selecionados
        } else {
            //console.log("entrou nao checado - " + event.target.value);
            const novoArray = itensSelecionados.filter(item => item !== event.target.id);
            // Atualize o estado com o novo array
            setItensSelecionados(novoArray);
            //console.log(arquivosSelecionados);

        }

        //console.log(arquivosSelecionados);
        // // Inverte o estado anterior de botaoAtivo
        // setBotaoAtivo((prevState) => !prevState);
    };

    function MontaSolicOrcamento(event) {
        event.preventDefault();

        setLoading(true);

        // Criando uma div
        const ArquivoMontado = (
            <div>

                <div style={{ textAlign: 'center' }}>
                    <img src='https://fauscs.sistemacontroll.com.br/UploadsFauscs/Cabecalho.png' style={{ width: '350px', display: 'inline-block' }} alt="Header Image" />
                </div>

                <h4 style={{ fontSize: '24px', color: '#222', textAlign: 'center' }}>Solicitação Para Pedido de Compra</h4>
                <hr style={{ color: '#bbb' }}></hr>

                <p style={{ fontSize: '14px', color: '#767676', padding: '1em' }} dangerouslySetInnerHTML={{ __html: solicitacao.Observacao }}></p>

                <h6 style={{ textAlign: 'center', marginTop: '1em', color: '#222', fontSize: '14px' }}>ITENS</h6>

                <table style={{ marginBottom: '.3em', borderCollapse: 'collapse', width: '90%', margin: '0 auto' }}>
                    <thead style={{ borderBottom: '2px solid #154360', borderTop: '0', borderRight: '0', borderLeft: '0', fontWeight: '600', fontSize: '16px' }}>
                        <tr style={{ color: '#2C3E50' }}>
                            <th style={{ padding: '0.3em', width: '70%' }}>Descrição</th>
                            <th style={{ padding: '0.3em', textAlign: 'center' }}>Quantidade</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: '14px' }}>
                        {console.log(itensSelecionados)}
                        {itensSelecionados.map((item) => {

                            return (
                                <tr key={item.codigo} style={{ color: '#566573', borderBottom: '1px solid #EBEDEF', borderTop: '0', borderRight: '1px solid #EBEDEF', borderLeft: '1px solid #EBEDEF', padding: '.3em' }}>
                                    <td style={{ padding: '.3em' }}>{item.nome}</td>
                                    <td style={{ padding: '.3em', textAlign: 'center' }}>{item.quantidade}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <h6 style={{ textAlign: 'center', marginTop: '2em', color: '#222', fontSize: '16px' }}>JUSTIFICATIVA</h6>
                <p style={{ fontSize: '16px', color: '#767676', padding: '1em' }} dangerouslySetInnerHTML={{ __html: solicitacao.Justificativa }}></p>

                <p>Anexos</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    {/* {console.log(arquivosSelecionados)} */}
                    {arquivosSelecionados &&
                        arquivosSelecionados.length > 0 &&
                        arquivosSelecionados
                            .map((ArquivoInserido) => (
                                <div
                                    key={ArquivoInserido}
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                >

                                    <p style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '10px', textAlign: 'left', width: 'fit-content' }}>{ArquivoInserido}</p>

                                </div>
                            ))}
                </div>
            </div>
        );

        // Chama a função para enviar e-mail
        EnviaEmailOrcamento(ArquivoMontado);

        // Retorna o HTML criado
        return ArquivoMontado;

    }

    function EnviaEmailOrcamento(wArquivoMontado) {
        //console.log(itensSelecionados);
        //const Anexos = arquivosSelecionados

        const wHtmlArquivoMontado = ReactDOMServer.renderToStaticMarkup(wArquivoMontado);

        // Agora você pode usar a string HTML conforme necessário
        //console.log(wHtmlArquivoMontado);

        // Remove quebras de linha e substitui aspas
        const escapedHtml = wHtmlArquivoMontado.replace(/(\r\n|\n|\r)/g, '').replace(/"/g, "'");

        const dataHoraAtual = moment().format('YYYYMMDD_HHmmss');
        // Nome do arquivo
        var wNomeArquivoNovo = `SolicitacaoOrcamento_${CodigoSolicitacao}_${dataHoraAtual}.pdf`;

        var dataConverter = JSON.stringify({
            "pasta": 'ArquivosProcesso',
            "arquivo": wNomeArquivoNovo,
            "html": escapedHtml,
            "template": ''
        });
        //console.log(escapedHtml);
        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ApiPdf/ConverterHtmlParaPdf',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
            data: dataConverter
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const dataConverter = response.data;
                if (dataConverter.isOk === true) {
                    //GRAVA ARQUIVO NA BASE

                    var datFiles = JSON.stringify({
                        "CodigoArquivo": 0,
                        "CodigoSolicitacao": CodigoSolicitacao,
                        "descricao": 'Solicitação de Orçamento para Compra',
                        "arquivo": wNomeArquivoNovo,
                        "status": 0
                    })
                    var configFiles = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/ArquivoProcesso',
                        headers: {
                            'Authorization': wtoken,
                            'Content-Type': 'application/json'
                        },
                        data: datFiles
                    };
                    //console.log(datFiles)
                    axios(configFiles)
                        .then(function (responseFiles) {

                            if (responseFiles.data.isOk === true) {
                                //REGISTRA STATUS
                                var datHoje = new Date();
                                var dataStatus = JSON.stringify({
                                    "CodigoStatus": 0,
                                    "CodigoSolicitacao": CodigoSolicitacao,
                                    "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                                    "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                    "status": false,
                                    "Motivo": '<strong>Solicitação de orçamento para compra feita por:</strong> ' + localStorage.getItem("NomeUsuario") + '<br>Enviado para: ' + nomeFornecedoresSelecionados + '<br>' + '<strong>Mensagem: </strong>' + mensagem,
                                    "CodigoPerfil": localStorage.getItem("CodigoPerfil"),
                                    "Doc": "",
                                    "PerfilDestino": localStorage.getItem("CodigoPerfil"),
                                    "CodCadastro": 155,
                                });
                                var configStatus = {
                                    method: 'post',
                                    maxBodyLength: Infinity,
                                    url: urlAPIlocal + '/api/Status',
                                    headers: {
                                        'Authorization': wtoken,
                                        'Content-Type': 'application/json',
                                    },
                                    data: dataStatus
                                };
                                //console.log(config);
                                axios(configStatus)
                                    .then(function (responseStatus) {
                                        if (responseStatus.data.isOk === true) {

                                            // ENVIA EMAIL AOS FORNECEDORES 
                                            //console.log(fornecedoresSelecionados);
                                            const wArquivos = arquivosSelecionados.join(';');
                                            //console.log(wArquivos);

                                            var dataEmail = JSON.stringify({
                                                "RemetenteEmail": "",
                                                "RemetenteNome": "",
                                                "CopiaOculta": [fornecedoresSelecionados],
                                                "destinatario": ['compras@fauscs.org.br'],
                                                "assunto": assunto,
                                                "corpo": mensagem,
                                                "Anexos": [{
                                                    "Pasta": "ArquivosProcesso",
                                                    "Arquivos": wNomeArquivoNovo + ";" + wArquivos,
                                                }]
                                            });

                                            var configEmail = {
                                                method: 'post',
                                                maxBodyLength: Infinity,
                                                url: urlAPIlocal + '/api/Email',
                                                headers: {
                                                    'Authorization': wtoken,
                                                    'Content-Type': 'application/json',
                                                },
                                                data: dataEmail
                                            };

                                            //console.log(dataEmail);
                                            //console.log(configEmail);

                                            axios(configEmail)
                                                .then(response => {
                                                    setLoading(false);
                                                    setEnviado(true);
                                                    window.location.reload();
                                                })
                                        }
                                    })
                                    .catch(error => {
                                        console.error(error);
                                        setLoading(false);
                                        setShowModalErro(true);
                                    });
                            } else {
                                setLoading(false);
                                setShowModalErro(true);
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            setLoading(false);
                            setShowModalErro(true);
                        });
                } else {
                    setLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });

    }


    return (
        <div className={Style.page}>
            <div className={Style.form_container}
                style={{
                    backgroundColor: '#ffffff',
                    borderTop: '5px solid #1B4F72',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    margin: 'auto 0',
                    padding: '2em',
                    width: '60vw',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                }}>
                <div id="CabecalhoHtml">
                    <h4 style={{ fontSize: '24px', color: '#222', textAlign: 'center' }}>Solicitação Para Pedido de Compra</h4>
                    <hr style={{ color: '#bbb' }}></hr>
                </div>

                <Form.Label className={Style.form_label}>Selecione os Fornecedores</Form.Label>
                <Select
                    isMulti={true}
                    isClearable={true}
                    isSearchable={true}
                    closeMenuOnSelect={false}
                    options={fornecedores.map((fornecedor) => ({
                        label: fornecedor.Nome,
                        value: fornecedor.CodigoCadastro,
                        email: fornecedor.Email ? fornecedor.Email.Endereco : ''
                    }))}
                    // value={fornecedoresSelecionados}
                    onChange={handleSelectFornecedores}
                />

                <div id='SolicOrcamentoHtml'>
                    <p style={{ fontSize: '14px', color: '#767676', padding: '1em' }} dangerouslySetInnerHTML={{ __html: solicitacao.Observacao }}></p>

                    <h6 style={{ textAlign: 'center', marginTop: '1em', color: '#222', fontSize: '14px' }}>ITENS</h6>
                    <table style={{ marginBottom: '.3em', borderCollapse: 'collapse', width: '90%', margin: '0 auto' }}>
                        <thead style={{ borderBottom: '2px solid #154360', borderTop: '0', borderRight: '0', borderLeft: '0', fontWeight: '600', fontSize: '16px' }}>
                            <tr style={{ color: '#2C3E50' }}>
                                <th></th>
                                <th style={{ padding: '0.3em' }}>Item</th>
                                <th style={{ padding: '0.3em', width: '70%' }}>Descrição</th>
                                <th style={{ padding: '0.3em', textAlign: 'center' }}>Quantidade</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '14px' }}>
                            {item.map((item) => {

                                return (
                                    <tr key={item.CodigoSolicitacaoItem} style={{ color: '#566573', borderBottom: '1px solid #EBEDEF', borderTop: '0', borderRight: '1px solid #EBEDEF', borderLeft: '1px solid #EBEDEF', padding: '.3em' }}>
                                        <td style={{ padding: '.3em', textAlign: 'center' }}>
                                            <input
                                                type="checkbox"
                                                name="arquivoCheck"
                                                id={item.CodigoSolicitacaoItem}
                                                data-nome={item.Nome}
                                                data-quantidade={item.Quantidade}
                                                //checked={itensSelecionados.some((itemSelecionado) => itemSelecionado.codigo === item.CodigoSolicitacaoItem)}
                                                onChange={handleCheckItem}
                                            />
                                        </td>
                                        <td style={{ padding: '.3em' }}>{item.NumeroItem}</td>
                                        <td style={{ padding: '.3em' }}>{item.Nome}</td>
                                        <td style={{ padding: '.3em', textAlign: 'center' }}>{item.Quantidade}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>


                    <h6 style={{ textAlign: 'center', marginTop: '2em', color: '#222', fontSize: '16px' }}>JUSTIFICATIVA</h6>
                    <p style={{ fontSize: '16px', color: '#767676', padding: '1em' }} dangerouslySetInnerHTML={{ __html: solicitacao.Justificativa }}></p>

                </div>

                <div className={Style.div_email}>
                    <Form onSubmit={MontaSolicOrcamento}>
                        <Row style={{ marginBottom: '.8em' }}>
                            <Col>
                                <Form.Label className={Style.form_label}>Assunto:</Form.Label>
                            </Col>
                            <Col md={11}>
                                <Form.Control className={Style.form_control} type="text" name="Assunto" value={assunto} onChange={(event) => setAssunto(event.target.value)} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={Style.form_label}>Mensagem:</Form.Label>
                            </Col>
                            <Col md={11}>
                                {/* <Form.Control as='textarea' className={Style.textarea} type="text" name="Mensagem" value={mensagem} onChange={(event) => setMensagem(event.target.value)} /> */}
                                <ReactQuill
                                    theme="snow"
                                    value={mensagem}
                                    onChange={handleMensagemChange}
                                    className={Style.textarea}
                                />
                            </Col>
                        </Row>

                        <hr style={{ marginTop: '2em', color: '#bbb' }}></hr>

                        <div>
                            <p>Anexos</p>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                {arquivo &&
                                    arquivo.length > 0 &&
                                    arquivo
                                        .filter((ArquivoProcesso) => ArquivoProcesso.Status === 0 && !ArquivoProcesso.Arquivo.startsWith('SolicitacaoOrcamento_'))
                                        .map((ArquivoProcesso) => (
                                            <div
                                                key={ArquivoProcesso.CodigoArquivo}
                                                // className={botaoAtivo ? `${Style.anexos} ${Style.anexos_ativo}` : Style.anexos}
                                                className={arquivosSelecionados.includes(ArquivoProcesso.Arquivo) ? `${Style.anexos} ${Style.anexos_ativo}` : Style.anexos}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        name="arquivoCheck"
                                                        id={ArquivoProcesso.CodigoArquivo}
                                                        value={ArquivoProcesso.Arquivo}
                                                        onChange={handleCheckChange}
                                                    />
                                                    <label htmlFor={ArquivoProcesso.CodigoArquivo} style={{ margin: '10px', fontSize: '12px' }}>
                                                        {(() => {
                                                            const nomeArquivoGrande = ArquivoProcesso.Arquivo;
                                                            const maxLength = 30;
                                                            const truncatedNome = nomeArquivoGrande.length > maxLength ? `${nomeArquivoGrande.substring(0, maxLength)}...` : nomeArquivoGrande;
                                                            return truncatedNome;
                                                        })()}
                                                    </label>
                                                </div>

                                                {/* <embed
                                                    title={ArquivoProcesso.Arquivo}
                                                    style={{ width: '10vw', height: '30vh', margin: '0 auto' }}
                                                    src={LinkArquivo + ArquivoProcesso.Arquivo}
                                                /> */}
                                            </div>
                                        ))}
                            </div>
                        </div>
                        <hr style={{ color: '#bbb' }}></hr>
                        <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <button type='submit' className={Style.enviar_btn}>Enviar Orçamento</button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
            <Modal show={loading} className={Style.Modal} centered >
                <Modal.Body className={Style.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={enviado} onLoad={handleClose} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p><strong>A Solicitação de Pedido de Compra foi Enviada aos Fornecedores!</strong></p>
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={Style.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={Style.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default SolicOrcamento 