import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import urlAPIlocal from '../../global';
import axios from "axios";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { AiFillCheckCircle, AiFillFilePdf, AiFillEdit } from "react-icons/ai";
import { Card, Col, FormGroup, Modal } from "react-bootstrap";
import { Navigate } from "react-router";

function PrintCredenciamento() {
    const { CodigoCadastro } = useParams();
    const [login, setLogin] = useState(false);
    const [cadastro, setCadastro] = useState([]);
    const [email, setEmail] = useState('');
    const [servico, setServico] = useState([]);
    const [produto, setProduto] = useState([]);
    const [status, setStatus] = useState([]);
    const [arquivos, setArquivos] = useState([]);

    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosCadastro/";
    var wPerfil = localStorage.getItem("CodigoPerfil");

    const [loadingPDF, setLoadingPDF] = useState(false);
    const [returnFinal, setReturnFinal] = useState(false);

    var wToken = 'Bearer ' + localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wToken === undefined || wToken === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    const getCadastro = async (event) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/cadastro/' + CodigoCadastro,
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setCadastro(data.objetoRetorno);
                //console.log(data.objetoRetorno);
                //console.log(data.objetoRetorno.observacao);
                setEmail(data.objetoRetorno.Email.Endereco);
                //console.log(email)
                PegaStatus();

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getCadastro();
        // eslint-disable-next-line
    }, []);

    const getServico = async (event) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/TipoAtividadeCadastro?CodigoCadastro=' + CodigoCadastro + '&servico=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setServico(data.objetoRetorno);
                //console.log(data.objetoRetorno);
                //console.log(atividade);
                //console.log(atividade[1].nome);

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getServico();
        // eslint-disable-next-line
    }, []);

    const getProduto = async (event) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/TipoAtividadeCadastro?CodigoCadastro=' + CodigoCadastro + '&servico=false',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setProduto(data.objetoRetorno);
                //console.log(data.objetoRetorno);
                //console.log(atividade);
                //console.log(atividade[1].nome);

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getProduto();
        // eslint-disable-next-line
    }, []);

    function PegaStatus() {

        var configStatus = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status?CodigoCadastro=' + CodigoCadastro + '&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(configStatus);
        axios(configStatus)
            .then(function (responseStatus) {
                const data = responseStatus.data;
                setStatus(data.objetoRetorno);
                //console.log(data.objetoRetorno);

                //getIconColor(data.objetoRetorno[0].Perfil_Destino.Nome);

            })
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }


    return (
        <div>
            <div id="CredenciamentoPdf">
                <html><head></head><body>
                    <div style={{ backgroundColor: '#FFF', minHeight: '100vh' }}>

                        <div style={{ width: '900px', margin: '0 auto', padding: '0.5em', backgroundColor: '#FFF', minHeight: '100vh', fontFamily: "Source Sans Pro, Helvetica, sans-serif" }}>

                            <div style={{ textAlign: 'center' }}>
                                <img src='https://fauscs.sistemacontroll.com.br/UploadsFauscs/Cabecalho.png' style={{ width: '350px', display: 'inline-block' }} alt="Header Image" />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', marginBottom: '1em', padding: '1em', backgroundColor: '#F8F9F9' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    <h5 style={{ fontSize: '18px', fontWeight: '600', color: '#2C3E50', textAlign: 'center' }}>Processo de Credenciamento nº: {cadastro.CodigoCadastro}</h5>
                                    <h5 style={{ fontSize: '18px', fontWeight: '600', color: '#2C3E50', textAlign: 'center' }}>Data de Emissão: {moment(cadastro.DataEmissao).format("DD/MM/yyyy")}</h5>
                                </div>
                                <br></br>
                                <div>
                                    <table style={{ fontSize: '14px', color: '#566573', width: '100%', border: '1px dashed #ccc', borderRadius: '10px' }}>
                                        <thead>
                                            <th></th>
                                            <th></th>
                                            {/* <th></th> */}
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: '0.3em' }}>
                                                    <p style={{ marginBottom: '0' }}><strong>CNPJ:</strong> {cadastro.NumeroCnpj}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Nome Fantasia:</strong> {cadastro.NomeFantasia}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Nome para Contato:</strong> {cadastro.NomeContato}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>1° Telefone: </strong>{cadastro.NumeroDdd} - {cadastro.NumeroTelefone}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>2° Telefone: </strong>{cadastro.Ddd2} - {cadastro.Telefone2}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>E-mail: </strong> {email}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Website:</strong> {cadastro.Website}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Status:</strong> {
                                                        cadastro.Aprovado === 0 ? 'Em processo de Aprovação' :
                                                            cadastro.Aprovado === 1 ? 'Aprovado' :
                                                                cadastro.Aprovado === 2 ? 'Reprovado' :
                                                                    cadastro.Aprovado === 3 ? 'Enviado para Aprovação' :
                                                                        ''
                                                    }</p>
                                                    <br></br>
                                                    <p style={{ marginBottom: '0' }}><strong>Inscrição Estadual:</strong> {cadastro.NumeroInscricaoEstadual}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Inscrição Municipal:</strong> {cadastro.NumeroInscricaoMunicipal}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Tipo de Atividade:</strong> {cadastro.Atividade}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>CNAE:</strong> {cadastro.Cnae}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Simples Nacional:</strong> {cadastro.SimplesNacional === true ? ("Optante") : ("Não Optante")}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Observações:</strong> {cadastro.observacao}</p>
                                                </td>
                                                <td style={{ padding: '0.3em' }}>
                                                    <p style={{ marginBottom: '0' }}><strong>Logradouro:</strong> {cadastro.Endereco}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Bairro:</strong> {cadastro.Bairro}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Complemento:</strong> {cadastro.ComplementoEnd}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Cidade:</strong> {cadastro.NomeCidade}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Estado:</strong> {cadastro.NomeEstado}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>CEP:</strong> {cadastro.NumeroCep}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>País:</strong> {cadastro.Pais}</p>
                                                    <br></br>
                                                    <p style={{ marginBottom: '0' }}><strong>Banco:</strong> {cadastro.NomeBanco}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Agência:</strong> {cadastro.NumeroAgencia}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Conta:</strong> {cadastro.NumeroConta}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>CNPJ do Titular:</strong> {cadastro.CnpjCpfConta}</p>
                                                    <p style={{ marginBottom: '0' }}><strong>Titular:</strong> {cadastro.TitularConta}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div>

                                <h4 style={{ textAlign: 'center' }}>MOVIMENTOS DO PROCESSO</h4>
                                <div>
                                    <VerticalTimeline layout="1-column">
                                        {status
                                            ? status
                                                //.sort((a, b) => new Date(b.DataAprovacao) - new Date(a.DataAprovacao)) // Ordena por DataAprovacao de forma decrescente
                                                .map((detalhes) => {
                                                    // const nomeArquivo = detalhes.Motivo.match(/arquivo: <strong>(.*?)</)?.[1];
                                                    // const arquivoEncontrado = encontrarArquivo(nomeArquivo);
                                                    // console.log(arquivoEncontrado);
                                                    return (
                                                        <VerticalTimelineElement
                                                            key={status.CodigoStatus}
                                                            iconStyle={{ background: '#F1F5F9', color: '#1b4f72' }}
                                                            icon={<AiFillEdit />}
                                                            style={{ margin: '0', borderBottom: '2px solid #fff' }}
                                                        >
                                                            {detalhes.Motivo.includes('Arquivo inserido ') && (
                                                                <>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                        <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }}></span>
                                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                            <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                            {/* <button onClick={() => openModalArquivo(detalhes.Motivo.match(/Arquivo: <strong>(.*?)</)?.[1])} className={Style.VerAquivo_btn}>
                                                                            Ver Arquivo Inserido<RiPhoneFindLine />
                                                                        </button> */}
                                                                        </div>

                                                                    </div>
                                                                    <Card style={{ width: '100%', height: '100%' }}>
                                                                        <strong>
                                                                            <AiFillFilePdf />
                                                                        </strong>
                                                                        {/* {detalhes.Motivo.match(/Arquivo: <strong>(.*?)</)[1] && (
                                                                            <a
                                                                                href={LinkArquivo + detalhes.Motivo.match(/Arquivo: (.*?)<br>/)[1]}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {detalhes.Motivo.match(/Arquivo: (.*?)<br>/)[1]}
                                                                            </a>
                                                                        )} */}
                                                                        <iframe
                                                                            title={detalhes.Motivo.match(/Arquivo: <strong>(.*?)</)?.[1]}
                                                                            style={{ width: '100%', height: '100%' }}
                                                                            src={LinkArquivo + detalhes.Motivo.match(/Arquivo: <strong>(.*?)</)?.[1]}
                                                                        />
                                                                    </Card>
                                                                </>
                                                            )}
                                                            {!detalhes.Motivo.includes('Arquivo inserido ') && (
                                                                !detalhes.Motivo.includes('Solicitação de exclusão do arquivo:') && (
                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                        <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }}></span>
                                                                        <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                    </div>
                                                                )
                                                            )}
                                                        </VerticalTimelineElement>
                                                    )
                                                })
                                            : ''}
                                    </VerticalTimeline>
                                </div>
                            </div>


                        </div>
                    </div>
                </body></html>
            </div>
        </div>
    )

}
export default PrintCredenciamento