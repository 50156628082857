import React from "react"
import Form from 'react-bootstrap/Form'
import StyleForm from './Cronograma.module.css'
import { AiFillPlusCircle, AiOutlineEdit } from 'react-icons/ai';
import { TiDelete } from "react-icons/ti";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Menu from "../Layout/Menu";
import { Col, FormGroup, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { BiSolidError } from 'react-icons/bi';
import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import Row from 'react-bootstrap/Row';
import moment from "moment";
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';
import done from "../img/done.svg";
import { ImSad2 } from 'react-icons/im';

function Cronograma() {
    const { wCodigoConvenioNovo } = useParams();
    const [codigoFinanceiro, setCodigoFinanceiro] = useState(0);
    const [concedentesGravados, setConcedentesGravados] = useState([]);
    const [concedenteEscolhido, setConcedenteEscolhido] = useState('');
    //const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataVencimento, setDataVencimento] = useState('');
    const [avisoData, setAvisoData] = useState(false);
    const [valorParcela, setValorParcela] = useState(0);
    const [login, setLogin] = useState(false);
    const [editado, setEditado] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [ParcelasGravadas, setParcelasGravadas] = useState([]);
    var wTokenLocal = localStorage.getItem("token");

    const [ptConsolidadoElementos, setPtConsolidadoElementos] = useState([]);
    const [pt, setPt] = useState([]);
    const [projetoatual, setProjetoAtual] = useState([]);

    const [showModalErro, setShowModalErro] = useState(false);
    //const [showModalErroPT, setShowModalErroPT] = useState(false);

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function handleClose() {
        setTimeout(() => {
            setIsLoading(false);
            //setCadastroOk(true);

        }, 1000);

    }

    function handleCloseEdit() {
        setTimeout(() => {
            setShowModalEdit(false);
            //setCadastroOk(true);

        }, 1000);

    }

    function handleCloseErro() {
        setShowModalErro(false);
    };

    // function handleCloseErroPT() {
    //     setShowModalErroPT(false);
    // };


    function AlimentaConsolidadoElementos() {

        //console.log(wCodConvenio.CodigoConvenio);
        const wtoken = "Bearer " + wTokenLocal;

        const config = {
            method: "GET",
            url:
                urlAPIlocal + "/api/ApiPlanoTrabalho/" + wCodigoConvenioNovo,
            headers: {
                Authorization: wtoken,
                "Content-Type": "application/json",
            },
        };
        //console.group(config);
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    var dados = response.data.objetoRetorno;
                    //console.log(dados);
                    setPt(dados.ConsolidadoDespesas);
                    setPtConsolidadoElementos(dados.ConsolidadoDespesas.Elementos);
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //setShowModalErroPT(true);
                }
            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
    }
    useEffect(() => {
        AlimentaConsolidadoElementos();
        // eslint-disable-next-line
    }, []);


    const getConcedentesGravados = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/ConvenioConcedente?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data);
            setConcedentesGravados(data);

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getConcedentesGravados();
        // eslint-disable-next-line
    }, []);

    const getParcelasGravadas = async () => {

        try {
            setLoading(true);
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Financeiro?CodigoConvenio=" + wCodigoConvenioNovo + "&Tipo=true&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config)
            const response = await axios(config);


            const data = response.data.objetoRetorno;
            //console.log(data)
            setParcelasGravadas(data);
            setLoading(false);
            //console.log(data)

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getParcelasGravadas();
        // eslint-disable-next-line
    }, []);


    const handleSelectConcedente = (event) => {
        setConcedenteEscolhido(event.target.value);
    };

    const getProjeto = async (event) => {
        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + wCodigoConvenioNovo,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjetoAtual(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        //setIsButtonDisabled(true);
        setLoading(true);

        var vTotalParcela = valorParcela;
        vTotalParcela = vTotalParcela.replace(',', '.');

        var dataVencimentoFormatada = moment(dataVencimento).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        if (dataVencimentoFormatada < projetoatual.DataExpiracao) {

            setAvisoData(false);

            var wtokenBearer = "Bearer " + localStorage.getItem("token")
            var data = JSON.stringify({
                "CodigoFinanceiro": 0,
                "CodigoConvenio": wCodigoConvenioNovo,
                "NumeroSequencia": 0,
                "CodigoSolicitacao": 0,
                "CodigoCadastro": concedenteEscolhido,
                "CodigoFonte": 1,
                "CodigoElemento": 0,
                "CodigoMeta": 0,
                "CodigoFase": 0,
                "NumeroNotaFiscal": "",
                "DataEmissao": dataVencimentoFormatada,
                "ValorLiquido": vTotalParcela,
                "ValorIss": 0,
                "ValorInss": 0,
                "ValorIrrf": 0,
                "ValorInss20": 0,
                "ValorTotal": vTotalParcela,
                "DataPagamento": "1900-01-01T00:00:00.000Z",
                "ValorPago": 0,
                "NumeroDoc": "",
                "Tipo": true,
                "Observacao": "",
                "ValorDesconto": 0,
                "Issp": false,
                "Inssp": false,
                "Irrfp": false,
                "Inss20p": false,
                "DataISS": "1900-01-01T00:00:00.000Z",
                "DataINSS": "1900-01-01T00:00:00.000Z",
                "DataIRRF": "1900-01-01T00:00:00.000Z",
                "DataINSS20": "1900-01-01T00:00:00.000Z",
                "NomeISS": "",
                "NomeINSS": "",
                "NomeIRRF": "",
                "NomeINSS20": "",
                "Setor": 0,
                "ValorOutrosImpostos": 0,
                "OutrosImpostos": false,
                "DataOutrosImpostos": "1900-01-01T00:00:00.000Z",
                "NomeOutrosImpostos": "",
                "TipoLicitacao": "Dispensa"
            });

            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Financeiro',
                headers: {
                    'Authorization': wtokenBearer,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //console.log(data)
            axios(config)

                .then(function (response) {

                    //console.log(response.data)
                    if (response.data.isOk === true) {
                        // fez ao consumo sem erro
                        setLoading(false);
                        getParcelasGravadas();
                        setValorParcela(0);
                        setDataVencimento('');
                        setConcedenteEscolhido(0);

                        //verifica o processo do projeto, se for 6 marcar processo = 7
                        //console.log(projetoatual.Processo);
                        const wProcessoAtual = projetoatual.Processo
                        if (wProcessoAtual === 6) {
                            //gravar o processo
                            const wtoken = "Bearer " + localStorage.getItem("token");
                            var Retorno = false;
                            var config = {
                                method: 'put',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/Convenio/MudarProcesso/' + wCodigoConvenioNovo + '/7',
                                headers: {
                                    'Authorization': wtoken,
                                    'Content-Type': 'application/json'
                                },
                            };
                            axios(config)
                                .then(function (response) {
                                    //console.log(response.data)
                                    if (response.data.isOk === true) {
                                        Retorno = true;
                                    } else {
                                        //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                                        Retorno = false
                                    }
                                })
                                .catch(error => {
                                    //setShowModalErro(true);
                                    console.error(error);
                                });
                        };

                    } else {
                        //deu algum erro tratar , colocar algum aviso
                        setLoading(false);
                        setShowModalErro(true);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.error(error);
                });
        } else {
            setAvisoData(true);
            setLoading(false);
        }

        //setIsButtonDisabled(false);

    }
    //console.log(ParcelasGravadas);

    let somaValoresParcelas = 0;

    if (ParcelasGravadas && ParcelasGravadas.length > 0) {
        somaValoresParcelas = ParcelasGravadas.reduce((acumulador, parcela) => {
            //console.log(parcela.ValorTotal);
            const valorParcela = parcela.ValorTotal; //parseFloat(parcela.ValorTotal.replace(',', '.')); // Converte o valor para um número
            return acumulador + valorParcela;
        }, 0);
    }

    const handleEditForm = async (wCodigoFinanceiro) => {

        try {
            setLoading(true);
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Financeiro?CodigoFinanceiro=" + wCodigoFinanceiro + "&Tipo=true&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            const dataVencimentoFormatada = moment(data[0].DataEmissao).format("YYYY-MM-DD");

            var vTotalFormatado = "" + data[0].ValorTotal + "";
            vTotalFormatado = vTotalFormatado.replace('.', ',');

            setValorParcela(vTotalFormatado);
            setDataVencimento(dataVencimentoFormatada);
            setConcedenteEscolhido(data[0].Cadastro.CodigoCadastro);
            setCodigoFinanceiro(data[0].CodigoFinanceiro);
            //console.log(data);
            //console.log(wCodigoFaseValor);

            setLoading(false);
            //setIsLoading(true);
            setEditado(true);


        } catch (error) {
            console.log(error)
        }
    }

    const handleEditSubmit = async (event) => {
        event.preventDefault();

        //setIsButtonDisabled(true);
        setLoading(true);

        const dataVencimentoFormatada = moment(dataVencimento).format("YYYY-MM-DD");

        var vTotalParcela = valorParcela;
        vTotalParcela = vTotalParcela.replace(',', '.');

        if (dataVencimentoFormatada < projetoatual.DataExpiracao) {

            setAvisoData(false);

            var wtokenBearer = "Bearer " + localStorage.getItem("token")
            var data = JSON.stringify({
                "CodigoFinanceiro": codigoFinanceiro,
                "CodigoConvenio": wCodigoConvenioNovo,
                "NumeroSequencia": 0,
                "CodigoSolicitacao": 0,
                "CodigoCadastro": concedenteEscolhido,
                "CodigoFonte": 1,
                "CodigoElemento": 0,
                "CodigoMeta": 0,
                "CodigoFase": 0,
                "NumeroNotaFiscal": "",
                "DataEmissao": moment(dataVencimento).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                "ValorLiquido": vTotalParcela,
                "ValorIss": 0,
                "ValorInss": 0,
                "ValorIrrf": 0,
                "ValorInss20": 0,
                "ValorTotal": vTotalParcela,
                "DataPagamento": "1900-01-01T00:00:00.000Z",
                "ValorPago": 0,
                "NumeroDoc": "",
                "Tipo": true,
                "Observacao": "",
                "ValorDesconto": 0,
                "Issp": false,
                "Inssp": false,
                "Irrfp": false,
                "Inss20p": false,
                "DataISS": "1900-01-01T00:00:00.000Z",
                "DataINSS": "1900-01-01T00:00:00.000Z",
                "DataIRRF": "1900-01-01T00:00:00.000Z",
                "DataINSS20": "1900-01-01T00:00:00.000Z",
                "NomeISS": "",
                "NomeINSS": "",
                "NomeIRRF": "",
                "NomeINSS20": "",
                "Setor": 0,
                "ValorOutrosImpostos": 0,
                "OutrosImpostos": false,
                "DataOutrosImpostos": "1900-01-01T00:00:00.000Z",
                "NomeOutrosImpostos": "",
                "TipoLicitacao": "Dispensa"
            });

            var config = {
                method: 'PUT',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Financeiro',
                headers: {
                    'Authorization': wtokenBearer,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //console.log(data)
            axios(config)

                .then(function (response) {

                    //console.log(response.data)
                    if (response.data.isOk === true) {
                        // fez ao consumo sem erro
                        getParcelasGravadas();
                        setValorParcela(0);
                        setDataVencimento('');
                        setConcedenteEscolhido(0);
                        setLoading(false);
                        setShowModalEdit(true);

                    } else {
                        //deu algum erro tratar , colocar algum aviso
                        setShowModalErro(true);
                    }
                })
                .catch(error => {
                    setShowModalErro(true);
                    console.error(error);
                });
        } else {
            setLoading(false);
            setAvisoData(true);
            setShowModalEdit(false);
        }

        //setIsButtonDisabled(false);

    }

    const handleDeleteParcela = (wCodigoFinanceiro) => {
        setLoading(true)
        var configDelete = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Financeiro/' + wCodigoFinanceiro,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataDelete
        };
        //console.log(dataDelete)
        axios(configDelete)

            .then(function (response) {
                getParcelasGravadas();
                setLoading(false);

            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className={StyleForm.page}>
            <Menu />

            {/* <div> <button className={StyleForm.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={StyleForm.voltar}>  <Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            <div className={StyleForm.header}>
                <h1>Cronograma de Desembolso</h1>
            </div>

            <div className={StyleForm.container}>

                <div align="center" className={StyleForm.table_container}>

                    <h4>RECURSOS CONSOLIDADOS POR ELEMENTO E FONTE DE RECURSOS</h4>

                    <Table align="center">

                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D4E6F1', color: '#154360', textAlign: "left" }}>Tipo de Despesa</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D4E6F1', color: '#154360', textAlign: "right" }}>Valor Previsto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(ptConsolidadoElementos.map((ConsolidadoElementos, indexConsolidado) => (
                                <tr key={indexConsolidado}>
                                    <td style={{ padding: '5px', fontSize: '14px' }} align="left">{ConsolidadoElementos.Nome}</td>
                                    <td style={{ padding: '5px', fontSize: '14px' }} align="right">{ConsolidadoElementos.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            )))}
                            <tr>
                                <td><strong>Total Programado do Projeto:</strong></td>

                                <td align="right"><strong>{pt.Total ? pt.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</strong></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                {avisoData ?
                    (<div style={{ display: 'flex', justifyContent: 'center', margin: '0 auto', borderRadius: '8px', backgroundColor: '#FDEDEC', color: '#B03A2E', fontSize: '14px', fontWeight: '600', padding: '.3em' }}>
                        <p style={{ margin: '.3em' }}><BiSolidError style={{ fontSize: '18px', marginBottom: '.2em' }} /> A data de vencimento da parcela não pode ser superior a data de vencimento do projeto</p>
                    </div>) :
                    (null)}

                <Form className={StyleForm.select} onSubmit={handleSubmit} >

                    <FormGroup className={StyleForm.form_group}>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Selecione o Partícipe</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="ConcendentesGravados" id="concedentesGravados" value={concedenteEscolhido} onChange={handleSelectConcedente}>
                                    <option>Selecione quem vai fazer o repasse</option>
                                    {concedentesGravados !== null ?
                                        (concedentesGravados.length > 0 &&
                                            concedentesGravados.map((opcao) => (
                                                <option key={opcao.CodigoConcedente} value={opcao.CodigoConcedente}>{opcao.Cadastro.Nome}</option>
                                            ))) :
                                        (<option>Não há Concedentes Gravados</option>)
                                    }
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data Vencimento</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" value={dataVencimento} onChange={(event) => setDataVencimento(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor Parcela</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="valorParcela" value={valorParcela} onChange={(event) => setValorParcela(event.target.value)} required />
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Observação</Form.Label>
                                <Form.Control className={StyleForm.form_control} name="obs" id="obs" value={obs} onChange={(event) => setObs(event.target.value)}></Form.Control>
                            </Col>
                        </Row> */}

                        <Row>
                            {!editado && (
                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleSubmit}>
                                    Inserir Parcela <AiFillPlusCircle />
                                </button>
                            )}

                            {/* Renderiza o botão "Editar Valores" se estiver editando */}
                            {editado && (
                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleEditSubmit}>
                                    Editar Parcela <AiFillPlusCircle />
                                </button>
                            )}
                        </Row>
                    </FormGroup>
                </Form>

                <div>
                    <div className={StyleForm.header}>
                        <p>PARCELAS LANÇADAS</p>
                    </div>

                    {ParcelasGravadas && ParcelasGravadas.length > 0 ? (
                        <Table hover className={StyleForm.table}>
                            <thead>

                                <tr>
                                    <th>Vencimentos</th>
                                    <th style={{ textAlign: 'right' }}>Valor Parcela</th>
                                    <th style={{ textAlign: 'right' }}>Valor Pago</th>
                                    <th>Partícipe</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>

                                {ParcelasGravadas.map((Parcelas) => (
                                    <tr key={Parcelas.CodigoFinanceiro}>
                                        <td>{moment(Parcelas.DataEmissao).format("DD/MM/yyyy")}</td>
                                        <td align="right">{Parcelas.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td align="right">{Parcelas.ValorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td>{Parcelas.Cadastro.Nome}</td>
                                        {Parcelas.ValorPago > 0 ?
                                            (<td></td>) : (
                                                <td>
                                                    <button type="button" className={StyleForm.btn_edit} onClick={(event) => handleEditForm(Parcelas.CodigoFinanceiro, Parcelas.Cadastro.Nome)}>
                                                        <AiOutlineEdit title="Editar" /> </button>

                                                    <button type="button" className={StyleForm.btn_delete} onClick={(event) => handleDeleteParcela(Parcelas.CodigoFinanceiro)}>
                                                        <TiDelete title="Excluir" /> </button>
                                                </td>
                                            )}
                                    </tr>
                                ))}


                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td align="right"><strong>Valor Total Parcelas:</strong></td>
                                    <td align="right">
                                        <strong>{somaValoresParcelas.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>

                        </Table>
                    ) : (<div><p style={{ textAlign: 'center' }}>Não há parcelas lançadas</p></div>)}
                    {/* <div align="center">
                        {exibirAviso && (
                            <div>
                                <p>Deseja realmente excluir o registro?</p>
                                <p>Valor - {valorcreditoexcluir} | Observação - {obscreditoexcluir}</p>
                                <button onClick={handleConfirmarExclusao}>Confirmar</button>
                                <button onClick={handleCancelarExclusao}>Cancelar</button>
                            </div>
                        )}
                    </div> */}
                </div>

                <Modal show={loading} className={StyleForm.Modal} centered >
                    <Modal.Body className={StyleForm.Modal_body}>
                        <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                    </Modal.Body>
                </Modal>

                <Modal show={isLoading} onLoad={handleClose} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <img src={done} alt="ok"></img>
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <h3>Tudo certo!</h3>
                        <p>Parcela inserida com sucesso</p>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalEdit} onLoad={handleCloseEdit} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <img src={done} alt="ok"></img>
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <p>Parcela atualizada com sucesso!</p>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalErro} className={StyleForm.Modal} centered >
                    <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                        <h3>Ops!</h3>
                        <ImSad2 />
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <p>Algo deu errado, cheque os campos e tente novamente</p>
                    </Modal.Body>
                </Modal>
                {/* 
                <Modal show={showModalErro} className={StyleForm.Modal} centered >
                    <Modal.Header closeButton onClick={handleCloseErroPT} className={StyleForm.Modal_header}>
                        <h3>Ops!</h3>
                        <ImSad2/> 
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <p>Esse Projeto Ainda não tem um plano de trabalho</p>
                    </Modal.Body>
                </Modal> */}

            </div>
            {login && (<Navigate to="/" replace={true} />)}

        </div>
    )

}
export default Cronograma