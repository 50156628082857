import { useParams } from "react-router";
import { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router";
//import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import Menu from "../Layout/Menu"
import { Link } from "react-router-dom";
import { IoMdArrowBack, IoIosSend } from "react-icons/io"
import { BiCommentDetail } from "react-icons/bi"
import { AiFillFilePdf, AiFillCheckCircle, AiFillEdit } from "react-icons/ai"
import { FiSend } from "react-icons/fi";
//import { SlEnvolope } from "react-icons/sl";
import loadingGif from '../img/icons8-spinning-circle.gif';
import Style from "./VerCadastro.module.css"
import Box from '@mui/material/Box';
import { Card, Col } from "react-bootstrap";
import CadastroImg from "../img/CadastroImg.png";
//import "react-pdf/dist/esm/Page/TextLayer.css";
//import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { Modal } from 'react-bootstrap';
import { ImSad2 } from 'react-icons/im';
import urlAPIlocal from '../../global';
//import Select from "react-select";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FormGroup, Table } from "react-bootstrap";
import moment from "moment";
import { GrStatusGoodSmall } from 'react-icons/gr';
import { PiIdentificationBadge } from 'react-icons/pi';
import { RiPhoneFindLine } from "react-icons/ri";
import { IoMdPrint } from "react-icons/io"
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// function GravaPerfilAtual(wCodCadastro, wCodPerfil) {

//     const wtoken = "Bearer " + localStorage.getItem("token");
//     var Retorno = false;
//     var config = {
//         method: 'put',
//         maxBodyLength: Infinity,
//         url: urlAPIlocal + '/api/Cadastro/PerfilDestino/' + wCodCadastro + '/' + wCodPerfil,
//         headers: {
//             'Authorization': wtoken,
//             'Content-Type': 'application/json'
//         },
//     };
//     //console.log(config);
//     axios(config)
//         .then(function (response) {

//             //console.log(response.data)
//             if (response.data.isOk === true) {
//                 Retorno = true;
//             } else {
//                 //tratar erro , mensagem ou algo na tela quando consumo retornar falha
//                 Retorno = false
//             }
//         })
//         .catch(error => {
//             console.error(error);
//         });
// }

function GravaArquivo(CodCadastro, ArqCadastro, DescricaoArq, tokenBear, wDatSituacao) {

    var Retorno = false;
    //trata nome arquivo, tira acentos, espaços
    let wNomeArquivoLimpo = ArqCadastro.name
    wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
    wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
    wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    wNomeArquivoLimpo = CodCadastro + "_" + wNomeArquivoLimpo

    wDatSituacao = moment(wDatSituacao).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    var datFiles = JSON.stringify({
        "CodigoArquivoCadastro": 0,
        "CodigoCadastro": CodCadastro,
        "descricao": DescricaoArq,
        "arquivo": wNomeArquivoLimpo, //CodCadastro + "_" + ArqCadastro.name,
        "status": 0,
        "DatSituacao": wDatSituacao
    })
    var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlAPIlocal + '/api/ArquivoCadastro',
        headers: {
            'Authorization': tokenBear,
            'Content-Type': 'application/json'
        },
        data: datFiles
    };
    //console.log(datFiles)
    axios(config)
        .then(function (response) {

            //console.log(response.data)
            if (response.data.isOk === true) {
                //faz upload do arquivo
                const file = ArqCadastro;
                const novoNome = wNomeArquivoLimpo;
                const novoArquivo = new File([file], novoNome, { type: file.type });
                const formData = new FormData();
                formData.append('arquivo', novoArquivo);

                var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/UploadArquivo',
                    headers: {
                        'Authorization': tokenBear,
                        'Content-Type': 'multipart/form-dataa',
                        'pasta': 'ArquivosCadastro'
                    },
                    data: formData
                };

                axios(config)

                    .then(response => {
                        //console.log(response.data);
                        // faça algo com a resposta da API
                        Retorno = false;
                    })
                    .catch(error => {
                        console.error(error);
                        Retorno = true;

                    });
            } else {
                //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                Retorno = true

            }

        })
        .catch(function (error) {
            console.log(error);
            Retorno = true
        });
    return Retorno;
}

function VerCadastro() {

    const [loading, setLoading] = useState(true);
    const [login, setLogin] = useState(false);

    const wPerfil = localStorage.getItem("CodigoPerfil");
    const wUsuario = localStorage.getItem("CodigoUsuario");
    var wNomePerfil = localStorage.getItem("NomePerfil");
    var wTokenLocal = localStorage.getItem("token");
    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosCadastro/";

    const { CodigoCadastro } = useParams();


    const [cadastro, setCadastro] = useState([]);
    const [email, setEmail] = useState('');
    const [servico, setServico] = useState([]);
    const [produto, setProduto] = useState([]);
    const [status, setStatus] = useState([]);

    const [usuarios, setUsuarios] = useState([]);
    const [setor, setSetor] = useState('');
    const [nomeSetor, setNomeSetor] = useState('');
    const [selecioneSetor, setSelecioneSetor] = useState(false);
    const [mensagemremetente, setMensagemRemetente] = useState('');
    const [mensagemencaminhamento, setMensagemEncaminhamento] = useState('');
    const [encaminharProcesso, setEncaminharProcesso] = useState(false);
    const [encaminhado, setEncaminhado] = useState(false);

    const [arquivo, setArquivo] = useState([]);
    const [enviaArquivo, setEnviaArquivo] = useState(false);
    const [arquivoEnviado, setArquivoEnviado] = useState(false);
    const [NovoFile, setNovoFile] = useState(null);
    const fileInputRef = useRef(null);
    const [descricaoarquivo, setDescricaoArquivo] = useState('');

    const [enviaTexto, setEnviaTexto] = useState(false);
    const [textoRequired, setTextoRequired] = useState(false);
    const [textoEnviado, setTextoEnviado] = useState(false);

    const [selecioneArquivo, setSelecioneArquivo] = useState(false);
    const [solicitarExclusao, setSolicitarExclusao] = useState(false);
    const [encaminhadoExclusao, setEncaminhadoExclusao] = useState(false);
    const [motivoExclusao, setMotivoExclusao] = useState('');
    const [arquivoSelecionado, setArquivoSelecionado] = useState(null);
    const [nomeArquivoSelecionado, setNomeArquivoSelecionado] = useState('');
    const [motivoRequired, setMotivoRequired] = useState(false);
    const [arquivoAnalise, setArquivoAnalise] = useState('');
    const [perfilDestinoFinal, setPerfilDestinoFinal] = useState('');
    const [descricao, setDescricao] = useState('');
    const [arquivoExclusao, setArquivoExclusao] = useState([]);

    const [modalDuploCheck, setModalDuploCheck] = useState(false);
    const [senha, setSenha] = useState('');
    const [avisoDuploCheck, setAvisoDuploCheck] = useState(false);
    const [avisoSenhaOk, setAvisoSenhaOk] = useState(false);
    const [senhaVerificada, setSenhaVerificada] = useState(false);
    const [modalExcluirArquivo, setModalExcluirArquivo] = useState(false);
    const [formConfirmarExclusao, setFormConfirmarExclusao] = useState(false);
    const [formNaoAutorizarExclusao, setFormNaoAutorizarExclusao] = useState(false);
    const [motivoNaoExclusao, setMotivoNaoExclusao] = useState('');

    const [botaoAtivo, setBotaoAtivo] = useState(null);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);

    const [botaoDesabilitadoEnviar, setbotaoDesabilitadoEnviar] = useState(false);
    const [botaoDesabilitadoReprovar, setbotaoDesabilitadoReprovar] = useState(false);
    const [botaoDesabilitadoAprovar, setbotaoDesabilitadoAprovar] = useState(false);
    //const date = new Date();
    //const dataAprovacaoFormatada = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    const [redirEnviado, setRedirEnviado] = useState(false);
    const [redirAprovado, setRedirAprovado] = useState(false);
    const [redirReprovado, setRedirReprovado] = useState(false);
    const [showModalMensagem, setShowModalMensagem] = useState(false);
    const [Mensagem, setMensagem] = useState('');
    //const [MensagemReprovação, setMensagemReprovação] = useState('');
    const [EmailEnviado, setEmailEnviado] = useState(false);
    const [showModalMotivoReprovado, setshowModalMotivoReprovado] = useState(false)
    const [MotivoReprovação, setMotivoReprovação] = useState(null);

    const [showModalErro, setShowModalErro] = useState(false);

    const [showModalArquivo, setShowModalArquivo] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const [fullscreen, setFullscreen] = useState(true);

    //const navigate = useNavigate();

    //const [fileBase64, setFileBase64] = useState(null);
    //var fileURL = ''

    // function handleClick() {
    //     navigate(-1);
    // }

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    function handleCloseErro() {
        setShowModalErro(false);
    };


    function showModalExcluirArquivo(wNomeArquivo, wCodigoArquivo, wPerfilRemetente, wDescricao) {
        setModalExcluirArquivo(true);
        setArquivoAnalise(wNomeArquivo);
        setArquivoExclusao(wCodigoArquivo)
        setPerfilDestinoFinal(wPerfilRemetente);
        setDescricao(wDescricao);
    }
    function handleCloseExcluirArquivo() {
        setModalExcluirArquivo(false);
    };

    const ConfirmarExclusao = async (event) => {
        event.preventDefault();

        if (senhaVerificada) {
            ConfirmarExclusaoComSenha(
                nomeArquivoSelecionado,
                arquivoSelecionado,
                descricao
            );
        } else {
            setModalDuploCheck(true);

        }
    }

    function ConfirmarExclusaoComSenha(wNomeArquivo, wCodigoArquivo, wDescricao) {
        setLoading(true);

        const wtoken = "Bearer " + wTokenLocal;

        var datHoje = new Date();

        var data = JSON.stringify({
            "CodigoStatu": 0,
            "CodigoSolicitacao": 1,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": true,
            "Motivo": 'Arquivo <strong>' + wNomeArquivo + '</strong> excluído do processo',
            "CodigoPerfil": wUsuario,
            "Doc": "",
            "PerfilDestino": wUsuario,
            "CodCadastro": CodigoCadastro
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json',
            },
            data: data
        };
        console.log(config);

        axios(config)

            .then(function (response) {
                const data = response.data;

                //grava status do arquivo como true
                var dataPUT = JSON.stringify({
                    "CodigoArquivoCadastro": wCodigoArquivo,
                    "CodigoCadastro": CodigoCadastro,
                    "Descricao": wDescricao + " (excluído do processo)",
                    "Arquivo": wNomeArquivo,
                    "Status": 1
                });

                var configPUT = {
                    method: 'PUT',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/ArquivoCadastro',
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json',
                    },
                    data: dataPUT
                };
                //console.log(configPUT);
                axios(configPUT)

                    .then(function (response) {

                        var configGET = {
                            method: 'GET',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/UploadArquivo/ArquivoExcluido',
                            headers: {
                                'pasta': 'ArquivosCadastro',
                                'arquivo': wNomeArquivo,
                                'Authorization': wtoken,
                                'Content-Type': 'application/json'
                            },
                        };
                        //console.log(configGET);
                        axios(configGET)
                            .then(function (response) {
                                const dataGet = response.data;
                                //console.log(dataGet);
                                if (dataGet.isOk === true) {
                                    //PegaStatus();
                                    setSenhaVerificada(false); // Reinicia o estado para futuras verificações
                                    setLoading(false);
                                    setModalExcluirArquivo(false);
                                    // Recarrega a página
                                    //window.location.reload();
                                } else {
                                    setShowModalErro(true);
                                    setModalExcluirArquivo(true);
                                }
                            }
                            )
                            .catch(function (error) {
                                setLoading(false);
                                console.log(error);
                                if (error.response.status === 401) {
                                    setLogin(true);
                                }
                                //console.log(JSON.stringify(response.data.mensagemRetorno));
                            });

                    })
                    .catch(function (error) {
                        setLoading(false);
                        console.log(error);
                        //console.log(JSON.stringify(response.data.mensagemRetorno));
                    });


            }
            )
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    // const NaoAutorizarExclusao = async (event) => {
    //     event.preventDefault();
    //     setFormNaoAutorizarExclusao(true);
    // }

    // const handleNaoAutorizarExclusao = (event) => {
    //     event.preventDefault();

    //     if (senhaVerificada) {
    //         handleNaoAutorizarExclusaoComSenha();
    //     } else {
    //         setModalDuploCheck(true);
    //     }
    // }

    // function handleNaoAutorizarExclusaoComSenha() {
    //     setLoading(true);
    //     const wtoken = "Bearer " + wTokenLocal;

    //     var datHoje = new Date();

    //     var data = JSON.stringify({
    //         "CodigoStatus": 0,
    //         "CodigoSolicitacao": 1,
    //         "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
    //         "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    //         "status": false,
    //         "Motivo": 'Exclusão do arquivo <strong>' + arquivoAnalise + '</strong> não autorizada.<br><strong>Motivo:</strong> ' + motivoNaoExclusao,
    //         "CodigoPerfil": wUsuario,
    //         "Doc": "",
    //         "PerfilDestino": perfilDestinoFinal,
    //         "CodCadastro": CodigoCadastro
    //     });

    //     var config = {
    //         method: 'post',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/Status',
    //         headers: {
    //             'Authorization': wtoken,
    //             'Content-Type': 'application/json',
    //         },
    //         data: data
    //     };

    //     axios(config)

    //         .then(function (response) {
    //             const data = response.data;
    //             //grava status do arquivo como true
    //             var dataPUT = JSON.stringify({
    //                 "CodigoArquivoCadastro": arquivoExclusao,
    //                 "CodigoCadastro": CodigoCadastro,
    //                 "Descricao": descricao,
    //                 "Arquivo": arquivoAnalise,
    //                 "Status": 1
    //             });

    //             var configPUT = {
    //                 method: 'PUT',
    //                 maxBodyLength: Infinity,
    //                 url: urlAPIlocal + '/api/ArquivoCadastro',
    //                 headers: {
    //                     'Authorization': wtoken,
    //                     'Content-Type': 'application/json',
    //                 },
    //                 data: dataPUT
    //             };
    //             //console.log(configPUT);
    //             axios(configPUT)

    //                 .then(function (response) {
    //                     //console.log(response.data);
    //                     if (response.data.isOk === true) {
    //                         //PegaStatus();
    //                         setSenhaVerificada(false); // Reinicia o estado para futuras verificações
    //                         setLoading(false);
    //                         setModalExcluirArquivo(false);
    //                         // Recarrega a página
    //                         window.location.reload();
    //                     } else {
    //                         setShowModalErro(true);
    //                         setModalExcluirArquivo(true);
    //                     }

    //                 })
    //                 .catch(function (error) {
    //                     setLoading(false);
    //                     console.log(error);
    //                     //console.log(JSON.stringify(response.data.mensagemRetorno));
    //                 });


    //         }
    //         )
    //         .catch(function (error) {
    //             setLoading(false);
    //             console.log(error);
    //             //console.log(JSON.stringify(response.data.mensagemRetorno));
    //         });
    // }

    function EnviaEmail(wAssunto, wMensagem, wEmail, wtoken, wStatus) {

        var data = JSON.stringify({
            "RemetenteEmail": "",
            "RemetenteNome": "",
            "CopiaOculta": [],
            "destinatario": [wEmail],
            "assunto": wAssunto,
            "corpo": wMensagem,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Email',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
            .then(response => {

                var datHoje = new Date();
                var dataStatus = JSON.stringify({
                    "CodigoStatus": 0,
                    "CodigoSolicitacao": 1,
                    "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                    "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    "status": false,
                    "Motivo": wStatus,
                    "CodigoPerfil": wUsuario,
                    "Doc": "",
                    "PerfilDestino": wUsuario,
                    "CodCadastro": CodigoCadastro
                });
                var configStatus = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Status',
                    headers: {
                        'Authorization': 'Bearer ' + wTokenLocal,
                        'Content-Type': 'application/json',
                    },
                    data: dataStatus
                };
                //console.log(configProc);
                axios(configStatus)
                    .then(function (responseStatus) {
                        setEmailEnviado(true);
                        PegaStatus();
                    })
                    .catch(error => {
                        //setGravando(false);
                        console.error(error);
                        setLoading(false);
                        //setShowModalErro(true);
                    });

            })
            .catch(error => {
                console.error(error);
            });
    }
    useEffect(() => {
        //getArquivo();
        // eslint-disable-next-line
    }, [EmailEnviado]);


    const getCadastro = async (event) => {


        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/cadastro/' + CodigoCadastro,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setCadastro(data.objetoRetorno);
                //console.log(data.objetoRetorno);
                //console.log(data.objetoRetorno.observacao);
                setEmail(data.objetoRetorno.Email.Endereco);
                //console.log(email)
                PegaStatus();

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getCadastro();
        // eslint-disable-next-line
    }, []);

    const getServico = async (event) => {


        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/TipoAtividadeCadastro?CodigoCadastro=' + CodigoCadastro + '&servico=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setServico(data.objetoRetorno);
                //console.log(data.objetoRetorno);
                //console.log(atividade);
                //console.log(atividade[1].nome);

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getServico();
        // eslint-disable-next-line
    }, []);

    const getProduto = async (event) => {


        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/TipoAtividadeCadastro?CodigoCadastro=' + CodigoCadastro + '&servico=false',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setProduto(data.objetoRetorno);
                //console.log(data.objetoRetorno);
                //console.log(atividade);
                //console.log(atividade[1].nome);

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getProduto();
        // eslint-disable-next-line
    }, []);

    const getArquivo = async (event) => {

        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoCadastro?CodigoCadastro=' + CodigoCadastro,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setArquivo(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno);
                // var configArquivoDow = {
                //     method: 'GET',
                //     maxBodyLength: Infinity,
                //     url: urlAPIlocal + '/api/uploadArquivo/' + data.objetoRetorno[0].CodigoArquivoCadastro,
                //     headers: {
                //         'Authorization': "Bearer " + localStorage.getItem("token"),
                //         'pasta': 'ArquivosCadastro',
                //         //responseType: 'blob',
                //     },
                //     //data: data
                // };

                // axios(configArquivoDow)
                //     .then(function (response) {

                //     })
                //     .catch(function (error) {
                //         console.log(error);
                //     });

            }
            )
            .catch(function (error) {
                //console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getArquivo();
        // eslint-disable-next-line
    }, []);




    // const handleSubmitProcesso = async (event) => {

    //     setLoading(true);
    //     event.preventDefault();

    //     if (setor !== '0' && mensagemremetente !== '') {
    //         setLoading(true);

    //         var wtokenBearer = "Bearer " + localStorage.getItem("token")
    //         var datHoje = new Date()

    //         var data = JSON.stringify({
    //             "CodigoStatus": 0,
    //             "CodigoSolicitacao": 1,
    //             "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
    //             "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    //             "status": false,
    //             "Motivo": mensagemremetente,
    //             "CodigoPerfil": wPerfil,
    //             "Doc": "",
    //             "PerfilDestino": setor,
    //             "CodCadastro": CodigoCadastro,
    //         });
    //         console.log(data);
    //         var config = {
    //             method: 'post',
    //             maxBodyLength: Infinity,
    //             url: urlAPIlocal + '/api/Status',
    //             headers: {
    //                 'Authorization': wtokenBearer,
    //                 'Content-Type': 'application/json',
    //             },
    //             data: data
    //         };
    //         axios(config)
    //             .then(response => {
    //                 //console.log(response);
    //                 // faça algo com a resposta da API
    //                 //grava arquivo se tiver
    //                 if (NovoFile !== null) {
    //                     var Resultado = false
    //                     Resultado = GravaArquivo(CodigoCadastro, NovoFile, descricaoarquivo, wtokenBearer, moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
    //                     if (Resultado === true) { setArquivoEnviado(true); }
    //                     getArquivo()
    //                 }

    //                 //grava o PerfilDestinoAtual na tblCadastro
    //                 GravaPerfilAtual(CodigoCadastro, setor);

    //                 setEncaminhado(true);
    //                 setSetor('0');
    //                 setMensagemRemetente('');
    //                 setNovoFile(null);
    //                 setSelecioneSetor(false);
    //                 setDescricaoArquivo('');
    //                 fileInputRef.current.value = '';
    //                 getArquivo();
    //                 setLoading(false);

    //             })
    //             .catch(error => {
    //                 console.error(error);
    //                 setLoading(false);
    //                 setShowModalErro(true);
    //             });
    //     } else {
    //         setLoading(false);
    //         setSelecioneSetor(true);
    //         setShowModalErro(true);
    //     };
    // }


    const handleSubmitAprovar = async (event) => {
        event.preventDefault();
        var wtokenBearer = "Bearer " + localStorage.getItem("token")

        setbotaoDesabilitadoAprovar(true)

        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/cadastro/Aprovar/' + CodigoCadastro + '/1',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            //data: data
        };
        //console.log(data);
        axios(config)

            .then(function (response) {

                GravaPerfilAtual(CodigoCadastro, '42');

                //envia email para aprovador - Diretor Administrativo
                var Assunto = "Credenciamento de Fornecedores - Fundação Fauscs"
                var Mensagem = "Prezados(a)<br><br>Informamos que o procedimento técnico administrativo, referente ao cadastramento de sua empresa, foi finalizado com parecer pela sua validação por parte do diretor administrativo financeiro da Fundação.<br>Lembramos que as regras que regulam essa condição estão previstas no regulamento de compras e contratações da Fundação, acessível pela aba - Transparência/Fauscs.<br><br>Seja bem vindo(a) ao time de parceiros / Forncedores da Fauscs."
                var Status = "<strong>Cadastro aprovado pela fundação.</strong>"

                EnviaEmail(Assunto, Mensagem, email, wtokenBearer, Status) // colocar email do marcelo diretor administrativo
                console.log(response);
                setRedirAprovado(true)

            })
            .catch(error => {
                console.error(error);
            });

    }


    // const handleSubmitEnviar = async (event) => {
    //     event.preventDefault();
    //     var wtokenBearer = "Bearer " + localStorage.getItem("token")

    //     setbotaoDesabilitadoEnviar(true)

    //     /* var data = JSON.stringify({
    //         "CodigoCadatro": CodigoCadastro,
    //         "Aprovado": true,
    //     }); */

    //     var config = {
    //         method: 'put',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/cadastro/Aprovar/' + CodigoCadastro + '/3',
    //         headers: {
    //             'Authorization': wtokenBearer,
    //             'Content-Type': 'application/json'
    //         },
    //         //data: data
    //     };
    //     //console.log(data);
    //     axios(config)

    //         .then(function (response) {
    //             //envia email para aprovador - Diretor Administrativo
    //             var Assunto = "Credenciamento de Fornecedores - Fundação Fauscs"
    //             var Mensagem = "Prezados(a)<br><br>Informamos que o cadastro da empresa " + cadastro.Nome + " foi auditado pelo setor de compras e está pronto para ser aprovado após sua análise.<br><br>Caso tenha algum motivo para não aprovação, por favor clicar em encaminhar para Setor de Compras."
    //             EnviaEmail(Assunto, Mensagem, email, wtokenBearer) // colocar email do marcelo diretor administrativo
    //             //console.log(cadastro);
    //             setRedirEnviado(true)

    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });


    // }

    const handleCloseModalMotivoReprovado = async (event) => {
        setshowModalMotivoReprovado(true);
    }

    const handleSubmitModalMotivoReprovado = async (event) => {
        event.preventDefault();
        setbotaoDesabilitadoReprovar(true);
        //setshowModalMotivoReprovado(true);

        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/cadastro/Aprovar/' + CodigoCadastro + '/2',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            //data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {
                //envia email avisando fornecedor que cadatro foi reprovado
                var Assunto = "Credenciamento na Fundação Fauscs"
                var Mensagem = "Prezados(a)<br><br>Informamos que o procedimento técnico administrativo, referente ao cadastramento de sua empresa, foi finalizado com parecer pela sua <strong>não validação</strong> por parte do diretor administrativo financeiro da Fundação, conforme se depreende do teor da decisão abaixo.<br><br><strong>Motivo:</strong> " + MotivoReprovação + ""
                var Status = "<strong>Cadastro reprovado pela fundação.</strong>"

                EnviaEmail(Assunto, Mensagem, email, wtokenBearer, Status);
                setRedirReprovado(true);
                //console.log(response);

            })
            .catch(error => {
                console.error(error);
            });


    }

    const handleCloseModal = async (event) => {
        setShowModalMensagem(true);
    }

    const handleSubmitMensagem = (event) => {
        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        //enviar email com a mensagem para email do cadastro
        event.preventDefault();
        var Assunto = "Mensagem da Fundação Fauscs - Credenciamento"
        var Status = "<strong>Mensagem enviada ao fornecedor</strong><br></br>Mensagem: " + Mensagem

        EnviaEmail(Assunto, Mensagem, email, wtokenBearer, Status);
        setTimeout(() => {
            setEmailEnviado(true);
            setShowModalMensagem(false);
            setMensagem('');
            setEmailEnviado(false);
        }, 3000);

        //setEmailEnviado(false);

    };

    const fechaModal = (event) => {
        setShowModalMensagem(false);
        setshowModalMotivoReprovado(false);
    }

    const handleNovoFile = (event) => {
        if (event.target.files) {
            setNovoFile(event.target.files[0]);
        }
    };

    function PegaStatus() {
        const wtoken = "Bearer " + wTokenLocal;

        var configStatus = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status?CodigoCadastro=' + CodigoCadastro + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(configStatus);
        axios(configStatus)
            .then(function (responseStatus) {
                const data = responseStatus.data;
                setStatus(data.objetoRetorno);
                //console.log(data.objetoRetorno);

                //getIconColor(data.objetoRetorno[0].Perfil_Destino.Nome);

            })
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    const encontrarArquivo = (motivo) => {
        // Certifica-se de que arquivo existe e é uma array
        if (arquivo && Array.isArray(arquivo)) {
            //console.log(motivo)
            const nomeArquivo = motivo;
            return arquivo.find(item => item.Arquivo === nomeArquivo);
        }
        return null; // Retorna null se arquivo não existe ou não é uma array
    };

    const openModalArquivo = (nomeArquivo) => {
        setFullscreen(true);
        setModalContent(nomeArquivo);
        setShowModalArquivo(true);
    };

    const closeModal = () => {
        setShowModalArquivo(false);
    };

    const handleSubmitInfo = async (event) => {
        event.preventDefault();
        setEnviaTexto(true);
        setBotaoDesabilitado(false);
        setTextoEnviado(false);
        setEnviaArquivo(false);
        setEncaminharProcesso(false);
        setArquivoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
    }

    const handleSubmitArquivo = async (event) => {
        event.preventDefault();
        setEnviaArquivo(true);
        setBotaoDesabilitado(false);
        setArquivoEnviado(false);
        setEnviaTexto(false);
        setEncaminharProcesso(false);
        setTextoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
    }

    const handleSubmitEncaminhar = async (event) => {
        event.preventDefault();
        setEncaminharProcesso(true);
        setBotaoDesabilitado(false);
        setEnviaArquivo(false);
        setEnviaTexto(false);
        setTextoEnviado(false);
        setArquivoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);

    }

    const handleExcluirArquivo = async (event) => {
        event.preventDefault();
        setSolicitarExclusao(true);
        setBotaoDesabilitado(false);
        setEncaminhadoExclusao(false);
        setEnviaArquivo(false);
        setEnviaTexto(false);
        setTextoEnviado(false);
        setArquivoEnviado(false);
        setEncaminharProcesso(false);
        setEncaminhado(false);
    }

    const DuploCheck = async (event) => {
        event.preventDefault();
        setLoading(true);

        var CodigoUsuario = localStorage.getItem('CodigoUsuario');
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + `/api/Usuarios?CodigoUsuario=${CodigoUsuario}&IsInclude=false`,
            headers: {
                'Authorization': "Bearer " + wTokenLocal,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data.objetoRetorno;

                if (senha === data[0].SenhaAssinatura) {
                    setSenhaVerificada(true);
                    setModalDuploCheck(false);
                    setAvisoDuploCheck(false);
                    setAvisoSenhaOk(true);
                    setSenha('');
                    if (enviaTexto) {
                        GravaTextoComSenha();
                    } else if (enviaArquivo) {
                        GravaNovoArquivoComSenha();
                    } else if (encaminharProcesso) {
                        GravaEncaminhamentoComSenha();
                    }
                    // else if (solicitarExclusao) {
                    //     handleSubmitEncaminharExclusaoComSenha();
                    // } else if (formNaoAutorizarExclusao) {
                    //     handleNaoAutorizarExclusaoComSenha();
                    // } 
                    else {
                        ConfirmarExclusaoComSenha();
                    }


                } else {
                    setModalDuploCheck(true);
                    setAvisoDuploCheck(true);
                    setAvisoSenhaOk(false);
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);

            });
    };

    const closeAndResetModalDuploCheck = () => {
        setModalDuploCheck(false);
        setAvisoDuploCheck(false);
        setAvisoSenhaOk(false);
        setSenha('');
        setSenhaVerificada(false);
        setBotaoDesabilitado(false);
    };

    function GravaTextoComSenha() {

        var datHoje = new Date();
        var wNomeUsuario = localStorage.getItem("NomeUsuario");
        var wNomeSetor = localStorage.getItem("NomePerfil");
        //var wMensagemNova = textoProcesso + '<br><strong>Informação inserida por: </strong>' + wNomeUsuario + ' <strong>Setor:</strong> ' + wNomeSetor + '<br>' + mensagemremetente + '<br> ________________________________ ';
        var wMensagemNovaStatus = '<strong>Informação inserida por: </strong>' + wNomeUsuario + ' <strong>Setor:</strong> ' + wNomeSetor + '<br>' + mensagemremetente;
        var dataStatusProc = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": 1,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": wMensagemNovaStatus,
            "CodigoPerfil": wUsuario,
            "Doc": "",
            "PerfilDestino": wUsuario,
            "CodCadastro": CodigoCadastro
        });
        var configStatusProc = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': 'Bearer ' + wTokenLocal,
                'Content-Type': 'application/json',
            },
            data: dataStatusProc
        };
        //console.log(configProc);
        axios(configStatusProc)
            .then(function (responseStatusProc) {
                if (responseStatusProc.data.isOk === true) {

                    // fez ao consumo sem erro
                    setLoading(false);
                    setEnviaTexto(false);
                    setBotaoDesabilitado(false);
                    setMensagemRemetente('');
                    setLoading(false);
                    getCadastro();
                    setTextoEnviado(true);
                    setSenha('');
                    setAvisoSenhaOk(false);

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setLoading(false);
                }
            })
            .catch(error => {
                //setGravando(false);
                console.error(error);
                setLoading(false);
                //setShowModalErro(true);
            });

    }
    const handleSubmitTexto = async (event) => {
        event.preventDefault();
        setLoading(true);

        setBotaoDesabilitado(true);
        //console.log(mensagemremetente)

        //verifica senha antes de prosseguir
        if (mensagemremetente !== '') {
            setLoading(false);
            setTextoRequired(false);

            if (avisoSenhaOk === true) {
                GravaTextoComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setLoading(false);
            setTextoRequired(true);
        }
    }

    function gerarCodigoUnico() {

        let codigo = '';
        for (let i = 0; i < 4; i++) {
            codigo += Math.floor(Math.random() * 10); // Adiciona um número aleatório de 0 a 9 ao código
        }
        return codigo;
    }


    function GravaNovoArquivoComSenha() {
        setLoading(true);

        let wNomeArquivoLimpo = NovoFile.name
        let wCodigoUnico = gerarCodigoUnico();
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
        wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        wNomeArquivoLimpo = CodigoCadastro + "_" + wCodigoUnico + "_" + wNomeArquivoLimpo

        var datFiles = JSON.stringify({
            "CodigoArquivoCadastro": 0,
            "CodigoCadastro": CodigoCadastro,
            "descricao": descricaoarquivo,
            "arquivo": wNomeArquivoLimpo,
            "status": 0
        })
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoCadastro',
            headers: {
                'Authorization': "Bearer " + wTokenLocal,
                'Content-Type': 'application/json'
            },
            data: datFiles
        };
        //console.log(datFiles)
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    //faz upload do arquivo
                    const file = NovoFile;
                    const novoNome = wNomeArquivoLimpo;
                    const novoArquivo = new File([file], novoNome, { type: file.type });
                    const formData = new FormData();
                    formData.append('arquivo', novoArquivo);

                    var config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/UploadArquivo',
                        headers: {
                            'Authorization': 'Bearer ' + wTokenLocal,
                            'Content-Type': 'multipart/form-dataa',
                            'pasta': 'ArquivosCadastro'
                        },
                        data: formData
                    };

                    axios(config)

                        .then(response => {
                            // console.log(response.data);
                            //Grava registro na tblStatusCadastro
                            var datHoje = new Date();
                            var dataStatusProc = JSON.stringify({
                                "CodigoStatus": 0,
                                "CodigoSolicitacao": 1,
                                "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                                "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                "status": false,
                                "Motivo": '<strong>Arquivo inserido por:</strong><br> ' + localStorage.getItem("NomeUsuario") + '<br>Arquivo: <strong>' + wNomeArquivoLimpo + '</strong><br>' + descricaoarquivo,
                                "CodigoPerfil": wUsuario,
                                "Doc": "",
                                "PerfilDestino": wUsuario,
                                "CodCadastro": CodigoCadastro
                            });
                            var configStatusProc = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/Status',
                                headers: {
                                    'Authorization': "Bearer " + wTokenLocal,
                                    'Content-Type': 'application/json',
                                },
                                data: dataStatusProc
                            };
                            //console.log(configProc);
                            axios(configStatusProc)
                                .then(function (responseStatusProc) {
                                    if (responseStatusProc.data.isOk === true) {
                                        // faça algo com a resposta da API
                                        //Retorno = false;
                                        setEnviaArquivo(false);
                                        setEnviaArquivo(false);
                                        setBotaoDesabilitado(false);
                                        getCadastro();
                                        setNovoFile(null);
                                        setDescricaoArquivo('');
                                        fileInputRef.current.value = '';
                                        setAvisoSenhaOk(false);
                                        setSenha('');
                                        setLoading(false);
                                        setArquivoEnviado(true);
                                        getArquivo();
                                    }
                                })

                                .catch(error => {
                                    console.error(error);
                                    setLoading(false);
                                    setShowModalErro(true);
                                });




                        })
                        .catch(error => {
                            console.error(error);
                            setLoading(false);
                            //Retorno = true;

                        });
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //Retorno = true

                }

            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
    }
    const handleSubmitEnviaArquivo = async (event) => {
        event.preventDefault();
        setLoading(true);
        setBotaoDesabilitado(true);
        //console.log(NovoFile);
        //setSelecioneArquivo(false);

        //if (NovoFile !== null && avisoSenhaOk === true) {

        if (NovoFile !== null) {
            setLoading(false);
            setSelecioneArquivo(false);

            if (avisoSenhaOk === true) {

                GravaNovoArquivoComSenha();

                //return Retorno;
            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setSelecioneArquivo(true);
        }
    }

    const getUsuarios = async (event) => {

        //const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios?IsInclude=true',
            headers: {
                'Authorization': 'Bearer' + wTokenLocal,
                'Content-Type': 'application/json'
            },
        };

        axios(config)

            .then(function (response) {
                const data = response.data;
                const resultadosFiltrados = data.objetoRetorno.filter((user) => user.CodigoPerfil !== 1 && user.CodigoPerfil !== 37);
                setUsuarios(resultadosFiltrados);
                //setLoading(false);
                //console.log(resultadosFiltrados);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        getUsuarios();
        // eslint-disable-next-line
    }, []);


    const handleSelectSetor = (event) => {
        setSetor(event.target.value);

        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedOptionText = selectedOption.text;
        setNomeSetor(selectedOptionText);
    }

    function GravaEncaminhamentoComSenha() {
        setLoading(true);
        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var datHoje = new Date()
        var data = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": 1,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": 'Processo encaminhado de: <strong>' + wNomePerfil + '</strong> para: <strong>' + nomeSetor + '</strong><br>Mensagem: ' + mensagemencaminhamento,
            "CodigoPerfil": wUsuario,
            "Doc": "",
            "PerfilDestino": setor,
            "CodCadastro": CodigoCadastro
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json',
            },
            data: data
        };
        //console.log(config);
        axios(config)
            .then(response => {
                //console.log(response);
                //grava novo detalhes no processo
                //GravaDetalhesProcesso();
                GravaPerfilAtual(CodigoCadastro, setor);
                getCadastro();
                setEncaminharProcesso(false);
                setBotaoDesabilitado(false);
                setEncaminhado(true);
                setSetor(0);
                setMensagemEncaminhamento('');
                setSelecioneSetor(false);
                setSenha('');
                setAvisoSenhaOk(false);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }
    const handleSubmitEncaminhamento = async (event) => {
        event.preventDefault();
        setLoading(true);
        setBotaoDesabilitado(true);

        //se nao selecionou setor nao deixa encaminhar
        //console.log(setor);

        if (setor !== 0) {
            setLoading(false);
            setSelecioneSetor(false);

            if (avisoSenhaOk === true) {
                GravaEncaminhamentoComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setSelecioneSetor(true);
        }
    };

    function GravaPerfilAtual(wCodigoCadastro, wCodigoPerfil) {

        const wtoken = "Bearer " + localStorage.getItem("token");
        var Retorno = false;
        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Cadastro/PerfilDestino/' + wCodigoCadastro + '/' + wCodigoPerfil,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    Retorno = true;
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    Retorno = false
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleRadioChange = (event) => {
        setArquivoSelecionado(event.target.value);
        //console.log(event.target.value);

        const codigoArquivoExclusao = parseInt(event.target.value, 10);
        const arquivoSelecionadoInfo = arquivo.find((arq) => arq.CodigoArquivoCadastro === codigoArquivoExclusao);
        //console.log(arquivoSelecionadoInfo);

        if (arquivoSelecionadoInfo) {
            setNomeArquivoSelecionado(arquivoSelecionadoInfo.Arquivo);
        }
    };


    // const handleSubmitEncaminharExclusao = async (event) => {
    //     event.preventDefault();
    //     setLoading(true);

    //     if (motivoExclusao !== '') {
    //         setLoading(false);
    //         setMotivoRequired(false);

    //         if (avisoSenhaOk === true) {

    //             handleSubmitEncaminharExclusaoComSenha();

    //         } else {
    //             setLoading(false);
    //             setModalDuploCheck(true);
    //         }
    //     } else {
    //         setMotivoRequired(false);
    //     }
    // };

    // function handleSubmitEncaminharExclusaoComSenha() {
    //     setLoading(true);
    //     var wtokenBearer = "Bearer " + localStorage.getItem("token")
    //     var datHoje = new Date()
    //     var data = JSON.stringify({
    //         "CodigoStatus": 0,
    //         "CodigoSolicitacao": 1,
    //         "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
    //         "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    //         "status": false,
    //         "Motivo": 'Solicitação de exclusão do arquivo: <strong>' + nomeArquivoSelecionado + '<br></strong>Motivo: <strong>' + motivoExclusao,
    //         "CodigoPerfil": wUsuario,
    //         "Doc": "",
    //         "PerfilDestino": '33',
    //         "CodCadastro": CodigoCadastro
    //     });

    //     var config = {
    //         method: 'post',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/Status',
    //         headers: {
    //             'Authorization': wtokenBearer,
    //             'Content-Type': 'application/json',
    //         },
    //         data: data
    //     };
    //     //console.log(config);
    //     axios(config)
    //         .then(response => {
    //             // console.log(response);
    //             //grava novo detalhes no processo
    //             GravaPerfilAtual(CodigoCadastro, '33')
    //             getCadastro();
    //             setSolicitarExclusao(false);
    //             setBotaoDesabilitado(false);
    //             setEncaminhadoExclusao(true);
    //             setArquivoSelecionado(null);
    //             setMotivoExclusao('');
    //             setSenha('');
    //             setAvisoSenhaOk(false);
    //             setSolicitarExclusao(false);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             setLoading(false);
    //             setShowModalErro(true);
    //         });
    // }

    const handlePrintClick = () => {
        window.open(`/PrintCredenciamento/${CodigoCadastro}`, '_blank');
    };


    // function getIconColor(nomePerfil) {
    //     if (nomePerfil === 'Compras') {
    //         return '#2980B9';
    //     } else if (nomePerfil === 'Financeiro') {
    //         return '#27AE60';
    //     } else if (nomePerfil === 'Assessoria Jurídica') {
    //         return '#CB4335';
    //     } else if (nomePerfil === 'Diretoria Administrativa') {
    //         return '#F4D03F';
    //     } else if (nomePerfil === 'Recursos Humanos') {
    //         return '#8E44AD';
    //     } else if (nomePerfil === 'Secretário Executivo') {
    //         return '#0B5345';
    //     } else if (nomePerfil === 'Consultor') {
    //         return '#626567';
    //     } else if (nomePerfil === 'Gestão de Projetos') {
    //         return '#E67E22';
    //     } else if (nomePerfil === 'Equipe Area') {
    //         return '#85C1E9';
    //     } else {
    //         return '#34495E';
    //     }
    // };

    return (

        <div className={Style.page}>

            <Menu />

            {/* <div> <button className={Style.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={Style.btn}><Link to={`/ListCadastro`}><IoMdArrowBack /> Voltar</Link></button> </div>
            <div className={Style.header}>

                <h1>{cadastro.Nome}</h1>

            </div>

            <div className={Style.gerenciar}>
                <Box className={Style.box_gerenciar}>
                    {/* <Form> */}
                    <FormGroup>
                        <div><p></p></div>
                        <div>
                            <h4 align="center">GERENCIAR PROCESSO DE CREDENCIAMENTO</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2em' }}>
                                <button
                                    className={botaoAtivo === 'info' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                    onClick={(e) => { handleSubmitInfo(e); setBotaoAtivo('info'); }}
                                >
                                    Inserir Novas Informações (texto)
                                </button>
                                <button
                                    className={botaoAtivo === 'arquivo' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                    onClick={(e) => { handleSubmitArquivo(e); setBotaoAtivo('arquivo'); }}
                                >
                                    Inserir Arquivo(s)
                                </button>
                                <button
                                    className={botaoAtivo === 'encaminhar' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                    onClick={(e) => { handleSubmitEncaminhar(e); setBotaoAtivo('encaminhar'); }}
                                >
                                    Encaminhar Processo <IoIosSend />
                                </button>
                                <button
                                    className={botaoAtivo === 'excluir' ? `${Style.btn_excluir} ${Style.btn_ativoExcluir}` : Style.btn_excluir}
                                    onClick={(e) => { handleExcluirArquivo(e); setBotaoAtivo('excluir'); }}
                                >
                                    Excluir Arquivo
                                </button>
                                {/* <button className={Style.btn_send} onClick={handleSubmitInfo}>Inserir Novas Informações ( texto )</button>
                                        <button className={Style.btn_send} onClick={handleSubmitArquivo}>Inserir Arquivo(s)</button>
                                        <button className={Style.btn_send} onClick={handleSubmitEncaminhar}>Encaminhar Processo<IoIosSend /></button> */}

                                {/* <button className={Style.btn_print} onClick={handlePrintClick}><IoMdPrint /></button> */}

                            </div>
                        </div>
                        <p><br></br></p>
                        {enviaTexto && (
                            <div>
                                <p></p>
                                <p></p>
                                <Form.Label>Novas Informações no Processo: </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="mensagemRementente"
                                    value={mensagemremetente}
                                    onChange={(event) => setMensagemRemetente(event.target.value)}
                                    required
                                    style={{ height: '100px', color: '#707070' }} />
                                <p></p>
                                {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitTexto} disabled={botaoDesabilitado} >Atualizar Processo <IoIosSend /></button></p>
                                {textoRequired && (
                                    <p align="center">
                                        <strong>Digite um texto para enviar!</strong>
                                    </p>
                                )}
                            </div>
                        )}
                        {enviaArquivo && (
                            <div>
                                <p></p>
                                <p></p>
                                <Form.Label>Descrição Arquivo: </Form.Label>
                                <Form.Control name="descricaoarquivo" value={descricaoarquivo} onChange={(event) => setDescricaoArquivo(event.target.value)} style={{ color: '#707070' }} />

                                <Form.Label style={{ color: '#fff' }}>.</Form.Label>
                                <Form.Control name="NovoFile" type="file" accept="application/pdf" onChange={handleNovoFile} ref={fileInputRef} />
                                <p></p>
                                {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitEnviaArquivo} disabled={botaoDesabilitado} >Enviar Arquivo<IoIosSend /></button></p>
                                {selecioneArquivo && (
                                    <p align="center">
                                        <strong>escolha um arquivo!</strong>
                                    </p>
                                )}
                            </div>
                        )}
                        {encaminharProcesso && (
                            <div>
                                <p></p>
                                <p></p>
                                <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Para:</Form.Label>
                                <Form.Select name="setor" id="setor" value={setor} onChange={handleSelectSetor} style={{ width: '300px', color: '#707070' }} required>
                                    <option value="0">Selecione um Destinatário</option>
                                    {usuarios && (
                                        usuarios.map((user) => (
                                            <option key={user.CodigoUsuario} value={user.CodigoUsuario}>
                                                {user.Nome} - {user.Perfil.Nome}
                                            </option>
                                        )))}
                                </Form.Select>
                                {selecioneSetor && (
                                    <p align="center">
                                        <strong>Selecione um Setor para encaminhar!</strong>
                                    </p>
                                )}
                                <p><br></br></p>
                                <Form.Label>Mensagem de Encaminhamento: </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="mensagemEncaminhamento"
                                    value={mensagemencaminhamento}
                                    onChange={(event) => setMensagemEncaminhamento(event.target.value)}
                                    style={{ height: '100px', color: '#707070' }} />
                                <p></p>
                                {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitEncaminhamento} disabled={botaoDesabilitado} >Encaminhar Processo <IoIosSend /></button></p>
                            </div>
                        )}

                        {solicitarExclusao && (
                            <div>
                                <p></p>
                                <p></p>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '65vw', flexWrap: 'wrap', margin: '0 auto' }}>
                                    {arquivo &&
                                        arquivo.length > 0 &&
                                        arquivo
                                            .filter((ArquivoProcesso) => ArquivoProcesso.Status === 0)
                                            .map((ArquivoProcesso) => (
                                                <div
                                                    key={ArquivoProcesso.CodigoArquivoProcessoInterno}
                                                    style={{
                                                        border: '1px solid #ccc',
                                                        borderRadius: '8px',
                                                        padding: '10px',
                                                        textAlign: 'left',
                                                        margin: '10px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="arquivoRadio"
                                                            id={ArquivoProcesso.CodigoArquivoCadastro}
                                                            value={ArquivoProcesso.CodigoArquivoCadastro}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <label htmlFor={ArquivoProcesso.CodigoArquivoCadastro} style={{ margin: '10px', fontSize: '12px' }}>
                                                            {(() => {
                                                                const nomeArquivoGrande = ArquivoProcesso.Arquivo;
                                                                const maxLength = 30;
                                                                const truncatedNome = nomeArquivoGrande.length > maxLength ? `${nomeArquivoGrande.substring(0, maxLength)}...` : nomeArquivoGrande;
                                                                return truncatedNome;
                                                            })()}
                                                        </label>
                                                    </div>

                                                    <embed
                                                        title={ArquivoProcesso.Arquivo}
                                                        style={{ width: '10vw', height: '30vh', margin: '0 auto' }}
                                                        src={LinkArquivo + ArquivoProcesso.Arquivo}
                                                    />
                                                </div>
                                            ))}
                                </div>

                                <Form.Label style={{ fontWeight: '600' }}>Motivo da exclusão: </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="motivoExclusao"
                                    value={motivoExclusao}
                                    onChange={(event) => setMotivoExclusao(event.target.value)}
                                    style={{ height: '100px', color: '#707070' }}
                                    required />
                                <p></p>
                                {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída, clique no botão abaixo para concluir a ação.</p><p></p></>) : (<p></p>)}
                                <p align='center'><button className={Style.btn_atualizar} onClick={ConfirmarExclusao}>Excluir Arquivo</button></p>
                            </div>
                        )}


                        <div>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            <p></p>
                            {textoEnviado && (
                                <p align="center">
                                    <AiFillCheckCircle /><strong>Processo Atualizado com Sucesso!</strong>
                                </p>
                            )}
                            {arquivoEnviado && (
                                <p align="center">
                                    <AiFillCheckCircle /><strong>Arquivo Enviados com Sucesso!</strong>
                                </p>
                            )}
                            {encaminhado && (
                                <p align="center">
                                    <AiFillCheckCircle /><strong>Processo Encaminhado com Sucesso!</strong>
                                </p>
                            )}
                            {encaminhadoExclusao && (
                                <p align="center">
                                    <AiFillCheckCircle /><strong>Solicitação de Exclusão de Arquivo Feita Com Sucesso!</strong>
                                </p>
                            )}
                        </div>
                    </FormGroup>
                    {/* </Form> */}


                    <div className={Style.btn_container}>
                        {(wPerfil === '17') && (
                            <div>
                                {/* <button className={Style.btn_aprovar} onClick={handleSubmitEnviar}>
                            {botaoDesabilitadoEnviar ? 'Aguarde...' : 'Enviar para Aprovação'}
                        </button> */}

                                <button className={Style.btn_aprovar} onClick={handleSubmitAprovar}>
                                    {botaoDesabilitadoAprovar ? 'Aguarde...' : 'Aprovar Cadastro'}
                                </button>

                                <button className={Style.btn_mensagem} onClick={handleCloseModal}>
                                    Enviar Mensagem ao Fornecedor
                                </button>

                                <button className={Style.btn_reprovar} onClick={handleCloseModalMotivoReprovado}>
                                    {botaoDesabilitadoReprovar ? 'Aguarde...' : 'Reprovar Cadastro'}
                                </button>

                            </div>
                        )}

                        {(wPerfil === '24') && (
                            <div>

                            </div>
                        )}

                        {/* {(wPerfil !== '17' && wPerfil !== '24') && <div></div>} */}

                    </div>
                </Box>
            </div>

            {cadastro && (
                <div className={Style.Box_container}>

                    <Box className={Style.Box}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <img src={CadastroImg} alt="" className={Style.img}></img>
                        </div>
                        <div>
                            <p><strong>CNPJ:</strong> {cadastro.NumeroCnpj}</p>
                            <p><strong>Nome Fantasia:</strong> {cadastro.NomeFantasia}</p>
                            <p><strong>Nome para Contato:</strong> {cadastro.NomeContato}</p>
                            <p><strong>1° Telefone: </strong>{cadastro.NumeroDdd} - {cadastro.NumeroTelefone}</p>
                            <p><strong>2° Telefone: </strong>{cadastro.Ddd2} - {cadastro.Telefone2}</p>
                            <p><strong>E-mail: </strong> {email}</p>
                            <p><strong>Website:</strong> {cadastro.Website}</p>
                            <p><strong>Status:</strong> {
                                cadastro.Aprovado === 0 ? 'Em processo de Aprovação' :
                                    cadastro.Aprovado === 1 ? 'Aprovado' :
                                        cadastro.Aprovado === 2 ? 'Reprovado' :
                                            cadastro.Aprovado === 3 ? 'Enviado para Aprovação' :
                                                ''
                            }</p>
                        </div>
                    </Box>
                    <Col>
                        <Box className={Style.Box_Endereco}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <div className={Style.title}><h5>Endereço</h5></div>
                            </div>
                            <div>
                                <p><strong>Logradouro:</strong> {cadastro.Endereco}</p>
                                <p><strong>Bairro:</strong> {cadastro.Bairro}</p>
                                <p><strong>Complemento:</strong> {cadastro.ComplementoEnd}</p>
                                <p><strong>Cidade:</strong> {cadastro.NomeCidade}</p>
                                <p><strong>Estado:</strong> {cadastro.NomeEstado}</p>
                                <p><strong>CEP:</strong> {cadastro.NumeroCep}</p>
                                <p><strong>País:</strong> {cadastro.Pais}</p>
                            </div>
                        </Box>

                        <Box className={Style.Box_Banco}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <div className={Style.title_banco}><h5>Informações Bancárias</h5></div>
                            </div>
                            <div>
                                <p><strong>Banco:</strong> {cadastro.NomeBanco}</p>
                                <p><strong>Agência:</strong> {cadastro.NumeroAgencia}</p>
                                <p><strong>Conta:</strong> {cadastro.NumeroConta}</p>
                                <p><strong>CNPJ do Titular:</strong> {cadastro.CnpjCpfConta}</p>
                                <p><strong>Titular:</strong> {cadastro.TitularConta}</p>
                            </div>
                        </Box>
                    </Col>
                    <Col>
                        <Box className={Style.Box_Empresa}>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '0' }}>
                                <div className={Style.title_empresa}><h5>Informações da Empresa</h5></div>
                            </div>
                            <div>
                                <p><strong>Inscrição Estadual:</strong> {cadastro.NumeroInscricaoEstadual}</p>
                                <p><strong>Inscrição Municipal:</strong> {cadastro.NumeroInscricaoMunicipal}</p>
                                <p><strong>Tipo de Atividade:</strong> {cadastro.Atividade}</p>
                                <p><strong>CNAE:</strong> {cadastro.Cnae}</p>
                                <p><strong>Simples Nacional:</strong> {cadastro.SimplesNacional === true ? ("Optante") : ("Não Optante")}</p>
                                <br></br>
                            </div>
                        </Box>

                        <Box className={Style.Box_Obs}>
                            <div className={Style.title_Obs}><BiCommentDetail /><h5> Observações da empresa</h5></div>
                            <p>{cadastro.observacao}</p>
                        </Box>
                    </Col>

                </div>

            )}
            <div className={Style.atividade_container}>


                <Box className={Style.card_atividade}>
                    <div className={Style.title_atividade}><h5>Serviços Oferecidos</h5></div>

                    {servico &&
                        (servico.length > 0 ?
                            (
                                servico.map((servicoCadastro) => (
                                    <div key={servicoCadastro.CodigoTipoAtividadeCadastro}>
                                        <ul>
                                            <li> - {servicoCadastro.Nome}</li>
                                        </ul>

                                    </div>

                                ))
                            ) :
                            (<p>Nenhum Serviço cadastrado</p>)

                        )}
                </Box>

                <Box className={Style.card_atividade}>
                    <div className={Style.title_atividade}><h5>Produtos Oferecidos</h5></div>
                    {produto &&
                        (produto.length > 0 ?
                            (
                                produto.map((produtoCadastro) => (
                                    <div key={produtoCadastro.CodigoTipoAtividadeCadastro}>
                                        <ul>
                                            <li> - {produtoCadastro.Nome}</li>
                                        </ul>

                                    </div>

                                ))
                            ) :
                            (<p>Nenhum produto cadastrado</p>)

                        )}

                </Box>

                <br></br>
                <br></br>

                <div id="htmlCredenciamento" className={Style.box_container2}>
                    <FormGroup className={Style.form_group}>
                        <Table>
                            <Row>
                                <Col >
                                    <Box>
                                        <h4 style={{ textAlign: 'center' }}>MOVIMENTOS DO PROCESSO</h4>
                                        <div className={Style.boxlista}>
                                            <VerticalTimeline layout="1-column">
                                                {status
                                                    ? status
                                                        //.sort((a, b) => new Date(b.DataAprovacao) - new Date(a.DataAprovacao)) // Ordena por DataAprovacao de forma decrescente
                                                        .map((detalhes) => {
                                                            const nomeArquivo = detalhes.Motivo.match(/arquivo: <strong>(.*?)</)?.[1];
                                                            const arquivoEncontrado = encontrarArquivo(nomeArquivo);
                                                            const estiloTexto = detalhes.Motivo.includes('excluído do processo') ? { color: 'red' } : {};
                                                            return (
                                                                <VerticalTimelineElement
                                                                    key={status.CodigoStatus}
                                                                    iconStyle={{ background: '#F1F5F9', color: '#1b4f72' }}
                                                                    icon={<AiFillEdit />}
                                                                    style={{ margin: '0', borderBottom: '2px solid #fff' }}
                                                                >
                                                                    {detalhes.Motivo.includes('Arquivo inserido ') && (
                                                                        !detalhes.Motivo.includes('Solicitação de exclusão do arquivo:') && (
                                                                            <>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                                    <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }}></span>
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                                        <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                                        <button onClick={() => openModalArquivo(detalhes.Motivo.match(/Arquivo: <strong>(.*?)</)?.[1])} className={Style.VerAquivo_btn}>
                                                                                            Ver Arquivo Inserido<RiPhoneFindLine />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    )}
                                                                    {!detalhes.Motivo.includes('Arquivo inserido ') && (
                                                                        !detalhes.Motivo.includes('Solicitação de exclusão do arquivo:') && (
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                                <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }} style={estiloTexto}></span>
                                                                                <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                    {/* {detalhes.Motivo.includes('Solicitação de exclusão do arquivo:') && (
                                                                        <>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                                <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }} style={estiloTexto}></span>
                                                                                <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                            </div>

                                                                            {wPerfil === '33' && arquivoEncontrado && arquivoEncontrado.Status === 0 && (
                                                                                <div style={{ textAlign: 'center', marginTop: '1em' }}>
                                                                                    <button
                                                                                        onClick={() => showModalExcluirArquivo(arquivoEncontrado.Arquivo, arquivoEncontrado.CodigoArquivoCadastro, detalhes.CodigoPerfil, arquivoEncontrado.Descricao)}
                                                                                        className={Style.VerAquivo_btn}>
                                                                                        Ver Arquivo <RiPhoneFindLine />
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )} */}
                                                                </VerticalTimelineElement>
                                                            )
                                                        })
                                                    : ''}
                                            </VerticalTimeline>
                                        </div>
                                    </Box>
                                </Col>
                                <Col>

                                    <Box id='boxarquivo'>
                                        <h4 style={{ textAlign: 'center' }}>ARQUIVOS DO PROCESSO</h4>
                                        <div className={Style.boxarquivo}>

                                            {showModalArquivo && modalContent && (
                                                <iframe
                                                    title="Visualização do Arquivo"
                                                    //src={LinkArquivo + modalContent}
                                                    src={`${LinkArquivo}${modalContent}#zoom=80`}
                                                    width="100%"
                                                    height="750px"
                                                ></iframe>
                                            )}
                                        </div>
                                    </Box>

                                </Col>
                            </Row>
                        </Table>
                    </FormGroup>
                </div>

            </div>

            {/* {wPerfil !== "37" &&
                <div className={Style.mapList}>

                    {arquivo &&
                        (arquivo.length > 0 ?
                            (
                                arquivo.map((ArquivoCadastro) => (
                                    <Card className={Style.Card} key={ArquivoCadastro.CodigoArquivoCadastro}>
                                        <strong><AiFillFilePdf /></strong>
                                        <a href={LinkArquivo + ArquivoCadastro.Arquivo} target="_blank" rel="noreferrer">{ArquivoCadastro.Descricao}</a>
                                        <iframe title={ArquivoCadastro.Descricao} style={{ width: '100%', height: '100%' }} src={LinkArquivo + ArquivoCadastro.Arquivo}
                                        />
                                    </Card>

                                ))
                            ) :
                            (<p>Nenhum arquivo adicionado</p>)

                        )}
                </div>
            }

            <div><br /></div> */}
            {/* <div>
                <Box className={Style.box_historico}>
                    <h4 style={{ textAlign: 'center' }}>HISTÓRICO</h4>
                    <Table className={Style.table}>
                        <thead>
                            <tr>
                                <th style={{ backgroundColor: '#D4E6F1', color: '#154360' }}></th>
                                <th style={{ backgroundColor: '#D4E6F1', color: '#154360' }}>Setor/Nome</th>
                                <th style={{ backgroundColor: '#D4E6F1', color: '#154360' }}>Destino</th>
                                <th style={{ textAlign: 'center', backgroundColor: '#D4E6F1', color: '#154360' }}>Data do Encaminhamento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {status &&
                                status.map((solic) => (
                                    <tr key={solic.CodigoStatus}>
                                        <td style={{ color: getIconColor(solic.PerfilRemetente.Nome) }}><GrStatusGoodSmall /></td>
                                        <td>{solic.PerfilRemetente.Nome}</td>
                                        <td>{solic.Perfil_Destino.Nome}</td>
                                        <td align="center">{moment(solic.DataAprovacao).format("DD/MM/yyyy")}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </Box>
            </div> */}


            {/* 
            <div><br></br></div>

            {wPerfil !== "37" &&
                <div className={Style.encaminhamento}>
                    <Box className={Style.box_encaminhamento}>
                        <Form>
                            <FormGroup>
                                <div className={Style.title_encaminhamento}>

                                    <h5>Encaminhar Processo</h5>
                                    <div style={{ width: '40%' }}></div>

                                    <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Para:</Form.Label>
                                    <Form.Select name="setor" id="setor" value={setor} onChange={(event) => setSetor(event.target.value)} style={{ width: '300px', color: '#707070' }}>
                                        <option value="0">Selecione um Setor</option>
                                        <option value="26">Recepção</option>
                                        <option value="17">Compras</option>
                                        <option value="23">Prestação de Contas</option>
                                        <option value="38">Financeiro</option>
                                        <option value="31">Assessoria Jurídica</option>
                                        <option value="25">Assessoria Administrativa</option>
                                        <option value="24">Diretoria Administrativa</option>
                                        <option value="28">Diretor Presidente</option>
                                        <option value="32">Recursos Humanos</option>
                                        <option value="33">Secretário Executivo</option>
                                        <option value="34">Consultor</option>
                                        <option value="35">TI</option>
                                        <option value="36">Gestao de Projetos</option>
                                    </Form.Select>
                                </div>

                     
                                <Form.Label className={Style.form_label_encaminhamento}>Mensagem</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="mensagemRementente"
                                    value={mensagemremetente}
                                    onChange={(event) => setMensagemRemetente(event.target.value)}
                                    required
                                    style={{ height: '100px', color: '#707070' }} />
                    
                                <br></br>
                                <Row>
                                    <Col>
                                        <Form.Label className={Style.form_label_encaminhamento}>Anexar Arquivo</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Form.Label>Descrição Arquivo: </Form.Label>
                                        <Form.Control name="descricaoarquivo" value={descricaoarquivo} onChange={(event) => setDescricaoArquivo(event.target.value)} style={{ color: '#707070' }} />
                                    </Col>
                                    <Col>
                                        <Form.Label style={{ color: '#fff' }}>.</Form.Label>
                                        <Form.Control name="NovoFile" type="file" accept="application/pdf" onChange={handleNovoFile} ref={fileInputRef} />
                                    </Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col>
                                        <button className={Style.btn_send} onClick={handleSubmitProcesso}>Encaminhar Processo <IoIosSend /></button>
                                        {encaminhado && (<p align="center"> <AiFillCheckCircle /><strong>Processo Encaminhado com Sucesso!</strong></p>)}
                                        

                                    </Col>
                                </Row>
                            </FormGroup>


                        </Form>
                    </Box>
                </div>
            } */}




            {login && (<Navigate to="/" replace={true} />)}
            {redirEnviado && (<Navigate to="/listCadastro" replace={true} />)}
            {redirAprovado && (<Navigate to="/listCadastro" replace={true} />)}
            {redirReprovado && (<Navigate to="/listCadastro" replace={true} />)}

            {
                showModalMensagem ? (

                    <Modal show={showModalMensagem} onHide={fechaModal} className={Style.Modal} centered >
                        <Modal.Header closeButton className={Style.Modal_header}>
                            {/* <img src={check_circle} alt="ok"></img> */}
                            <h2>Mensagem para Fornecedor</h2>
                        </Modal.Header>
                        <Modal.Body className={Style.Modal_body}>
                            {/* Cadastro atualizado com sucesso! */}
                            <form>
                                <p>Escreva aqui a  mensagem que deseja enviar para o fornecedor</p>
                                <textarea value={Mensagem} onChange={(event) => setMensagem(event.target.value)} required />
                                <br /><p></p>
                                <button onClick={handleSubmitMensagem}>Enviar <FiSend /></button>
                                <br />
                                {EmailEnviado && (<p> <AiFillCheckCircle />Mensagem Enviada com Sucesso!</p>)}
                            </form>

                        </Modal.Body>
                    </Modal>
                ) : (<p></p>)
            }

            <Modal show={loading} className={Style.Modal} centered >
                <Modal.Body className={Style.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={showModalMotivoReprovado} onHide={fechaModal} className={Style.Modal} centered>
                <Modal.Header closeButton className={Style.Modal_header}>
                    <h2>Motivo da Reprovação</h2>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <form>
                        <p>Escreva aqui o motivo da reprovação para o fornecedor</p>
                        <textarea value={MotivoReprovação} onChange={(event) => setMotivoReprovação(event.target.value)} required />
                        <br /><p></p>
                        <button className={Style.btn_confirmar} onClick={handleSubmitModalMotivoReprovado}>Enviar <FiSend /></button>
                        <br />
                        {EmailEnviado && (<p> <AiFillCheckCircle />Mensagem Enviada com Sucesso!</p>)}
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={Style.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={Style.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

            <Modal show={modalDuploCheck} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <h3>Confirmação de Identidade </h3><PiIdentificationBadge />
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Por favor, confirme sua senha para prosseguir</p>
                    <Form onSubmit={DuploCheck}>
                        {avisoDuploCheck ? (<p style={{ color: '#E74C3C' }}>A senha digitada não confere com a senha de usuário</p>) : (null)}
                        <Row>
                            <Col>
                                <Form.Label className={Style.label_modal}>Sua senha:</Form.Label>
                            </Col>
                            <Col md={9}>
                                <Form.Control className={Style.form_control} type="password" name="senha" value={senha} onChange={(event) => setSenha(event.target.value)} />
                            </Col>
                        </Row>

                        <button type="submit" className={Style.btn_salvar}> Salvar </button>
                        <button type="button" className={Style.btn_cancelar} onClick={closeAndResetModalDuploCheck}> Cancelar </button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* <Modal show={showModalArquivo} onHide={closeModal} fullscreen={fullscreen}>
                <Modal.Header closeButton className={Style.Modal_header}>
                    <Modal.Title >Visualização do Arquivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent && <iframe title="Visualização do Arquivo" src={LinkArquivo + modalContent} width="100%" height="850px"></iframe>}
                </Modal.Body>
            </Modal> */}

            {/* <Modal show={modalExcluirArquivo} className={Style.Modal} centered>
                <Modal.Header closeButton onClick={handleCloseExcluirArquivo} className={Style.Modal_header}>
                    <h3>Confirmação de Exclusão de Arquivo</h3>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <div>
                        <embed
                            title='Documento em Análise'
                            style={{ width: '15vw', height: '50vh', margin: '0 auto' }}
                            src={LinkArquivo + arquivoAnalise}
                        />
                    </div>
                    <Form>
                        {formNaoAutorizarExclusao ? (
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '.5em' }}>

                                <Form.Label className={Style.label_modal} style={{ textAlign: 'left', marginLeft: '1em', marginTop: '1em' }}>Motivo da recusa:</Form.Label>
                                <textarea className={Style.form_control_textarea} style={{ margin: '0 auto' }} name="motivo" value={motivoNaoExclusao} onChange={(event) => setMotivoNaoExclusao(event.target.value)} />

                                <Row>
                                    <Col>
                                        <button
                                            type="button"
                                            onClick={handleNaoAutorizarExclusao}
                                            style={{
                                                backgroundColor: '#CB4335',
                                                color: '#ffffff',
                                                fontWeight: '600',
                                                borderRadius: '5px',
                                                border: '0',
                                                fontSize: '14px',
                                                marginTop: '1em',
                                                transition: 'box-shadow 0.3s ease',  // Adiciona uma transição suave
                                                boxShadow: 'none',  // Sombra inicial
                                            }}
                                            onMouseOver={(e) => (e.currentTarget.style.boxShadow = 'rgba(0, 0, 0, 0.24) 0px 3px 8px')}  // Adiciona sombra no hover
                                            onMouseOut={(e) => (e.currentTarget.style.boxShadow = 'none')}  // Remove sombra quando o mouse sai
                                        >
                                            Não Autorizar Exclusão
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <FormGroup>
                                <button className={Style.btn_salvar} style={{ width: 'fit-content', fontWeight: '600' }} onClick={ConfirmarExclusao}>
                                    Confirmar Exclusão
                                </button>
                                <button
                                    className={Style.btn_cancelar}
                                    style={{ width: 'fit-content', fontWeight: '600', backgroundColor: 'red' }}
                                    onClick={NaoAutorizarExclusao}
                                >
                                    Não Autorizar Exclusão
                                </button>
                            </FormGroup>
                        )}
                    </Form>
                </Modal.Body>
            </Modal> */}

        </div >



    )
}
export default VerCadastro;