
import { useParams } from "react-router";
import { useState } from "react";
import React, { useEffect } from 'react';
//import axios from "axios";
import Style from "./VerProjeto.module.css";
import Menu from "../Layout/Menu"
import { Navigate } from "react-router";
import { IoMdArrowBack } from "react-icons/io"
//import moment from 'moment';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { Col } from "react-bootstrap";
//import urlAPIlocal from '../../global';
import { useNavigate } from "react-router-dom";


function Relatorios() {

    const { CodigoConvenio } = useParams();
    const [login, setLogin] = useState(false);
    //const navigate = useNavigate();

    // function handleClick() {
    //     navigate(-1);
    // }


    return (
        <div className={Style.page}>
            <Menu />
            {/* <div> <button className={Style.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={Style.voltar}>  <Link to={`/VerProjeto/${CodigoConvenio}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            <div className={Style.container}>
                <div className={Style.box_container}>
                    <Box className={Style.box_btn}>

                        <Col>
                            <div></div>
                        </Col>
                        <Col>
                            <button className={Style.edit}>
                                {/* <img className={Style.icon} src="https://img.icons8.com/cotton/64/null/goal.png" alt='Elementos'></img> */}
                                <Link to={`/PtFinanceiro/${CodigoConvenio}`} target="_blank">
                                    Plano de Trabalho Financeiro
                                </Link>
                            </button>
                        </Col>
                    </Box>
                </div>
            </div>
            {login && (<Navigate to="/" replace={true} />)}
        </div>

    )
}
export default Relatorios