import React from "react"
import Form from 'react-bootstrap/Form'
import StyleForm from './/Creditos.module.css'
import { AiFillPlusCircle, AiOutlineEdit } from 'react-icons/ai'
import { TiDelete } from "react-icons/ti";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Menu from "../Layout/Menu";
import { Col, FormGroup, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io"
import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import Row from 'react-bootstrap/Row';
import moment from "moment";
import { Modal } from 'react-bootstrap';
import loading from "../img/icons8-spinning-circle.gif"
import check_circle from "../img/check_circle.gif";
import { BiSolidError } from 'react-icons/bi';


function Creditos() {
    const { wCodigoConvenioNovo } = useParams();
    const [login, setLogin] = useState(false);
    //const [Creditos, setCreditos] = useState([]);
    const [CreditosGravados, setCreditosGravados] = useState([]);
    var wTokenLocal = localStorage.getItem("token");
    const [concedentesGravados, setConcedentesGravados] = useState([]);
    const [concedenteEscolhido, setConcedenteEscolhido] = useState('');
    const [dataCredito, setDataCredito] = useState('');
    const [valorCredito, setValorCredito] = useState(0);
    const [obs, setObs] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [creditoOk, setCreditoOk] = useState(false);
    const [creditoMaiorQueSaldo, setCreditoMaiorQueSaldo] = useState(false);


    const [editado, setEditado] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [exibirAviso, setExibirAviso] = useState(false);
    const [codcreditoexcluir, setCodCreditoExcluir] = useState(0);
    const [valorcreditoexcluir, setValorCreditoExcluir] = useState('');
    const [obscreditoexcluir, setObsCreditoExcluir] = useState('');


    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function handleClose() {
        setTimeout(() => {
            setCreditoOk(false);
            //setCadastroOk(true);

        }, 2000);

    }

    function handleCloseEdit() {
        setTimeout(() => {
            setShowModalEdit(false);
            //setCadastroOk(true);

        }, 1000);

    }

    const getConcedentesGravados = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/ConvenioConcedente?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data);
            setConcedentesGravados(data);

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getConcedentesGravados();
        // eslint-disable-next-line
    }, []);


    const getCreditosGravados = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Banco?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config)
            const response = await axios(config);


            const data = response.data.objetoRetorno;
            //console.log(data)
            setCreditosGravados(data);
            //console.log(data)


        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getCreditosGravados();
        // eslint-disable-next-line
    }, []);

    const handleSelectConcedente = (event) => {
        setConcedenteEscolhido(event.target.value);
        //console.log(event.target.value)
    };

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setIsButtonDisabled(true);
            setIsLoading(true);

            const wtokenBearer = "Bearer " + localStorage.getItem("token");
            // se o concedenteEscolhido for diferente de 92 ou 93 entra
            //console.log(concedenteEscolhido);
            if (concedenteEscolhido !== '92' && concedenteEscolhido !== '93') {
                // Saldo de Repasses a Pagar não pode ser menor que o valor digitado
                const dataVerificacao = JSON.stringify({
                    "CodigoConvenio": wCodigoConvenioNovo,
                    "Data inicial": concedentesGravados[0].Convenio.DataInicio,
                    "Data final": concedentesGravados[0].Convenio.DataExpiracao //moment(dataCredito).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                });

                const postVerificacaoConfig = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Convenio/Financeiro?IsInclude=false',
                    headers: {
                        'Authorization': wtokenBearer,
                        'Content-Type': 'application/json'
                    },
                    data: dataVerificacao
                };
                //console.log(postVerificacaoConfig);

                try {
                    const response = await axios(postVerificacaoConfig);
                    const data = response.data;
                    //console.log(data);

                    if (data.objetoRetorno.Financeiro.SaldoRepassesApagar >= valorCredito) {
                        var vTotalCredito = valorCredito;
                        vTotalCredito = vTotalCredito.replace(',', '.');

                        const postData = JSON.stringify({
                            "CodigoBanco": 0,
                            "DataBanco": moment(dataCredito).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                            "CodigoConvenio": wCodigoConvenioNovo,
                            "CodigoCadastro": concedenteEscolhido,
                            "Valor": vTotalCredito,
                            "Obs": obs
                        });

                        const postConfig = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/Banco',
                            headers: {
                                'Authorization': wtokenBearer,
                                'Content-Type': 'application/json'
                            },
                            data: postData
                        };

                        try {
                            const postResponse = await axios(postConfig);
                            const postDataResponse = postResponse.data;
                            //console.log(postDataResponse);

                            if (postDataResponse.isOk === true) {

                                // fez ao consumo sem erro
                                //agora da baixa na parcela no tblfinanceiro
                                // verifica se o recurso vem do concedente
                                //console.log(concedenteEscolhido);
                                /* if (concedenteEscolhido === "92") {
                                    console.log('entrou no 92');
                                    getCreditosGravados();
                                    setObs('');
                                    setValorCredito(0);
                                    setDataCredito('');
                                    setConcedenteEscolhido(0);
                                    setIsLoading(false);
                                } else if (concedenteEscolhido === "93") {
                                    console.log('entrou no 93');
                                    getCreditosGravados();
                                    setObs('');
                                    setValorCredito(0);
                                    setDataCredito('');
                                    setConcedenteEscolhido(0);
                                    setIsLoading(false);
                                } else { */
                                // console.log('entrou no baixa parcela');
                                BaixaParcela(wCodigoConvenioNovo, dataCredito, vTotalCredito, concedenteEscolhido);
                                // }
                            } else {
                                // deu algum erro tratar, colocar algum aviso
                                setIsLoading(false);
                            }
                        } catch (postError) {
                            console.error(postError);
                            setIsLoading(false);
                        }
                    } else {
                        //console.log('valor maior que o saldo a pagar');
                        setIsLoading(false);
                        setCreditoMaiorQueSaldo(true);
                    }
                } catch (error) {
                    console.error(error);
                    setIsLoading(false);
                }
            }
            //se não, entra aqui
            else {
                var vTotalCredito = valorCredito;
                vTotalCredito = vTotalCredito.replace(',', '.');

                const postData = JSON.stringify({
                    "CodigoBanco": 0,
                    "DataBanco": moment(dataCredito).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                    "CodigoConvenio": wCodigoConvenioNovo,
                    "CodigoCadastro": concedenteEscolhido,
                    "Valor": vTotalCredito,
                    "Obs": obs
                });

                const postConfig = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Banco',
                    headers: {
                        'Authorization': wtokenBearer,
                        'Content-Type': 'application/json'
                    },
                    data: postData
                };

                try {
                    const postResponse = await axios(postConfig);
                    const postDataResponse = postResponse.data;
                    //console.log(postDataResponse);

                    if (postDataResponse.isOk === true) {

                        getCreditosGravados();
                        setObs('');
                        setValorCredito(0);
                        setDataCredito('');
                        setConcedenteEscolhido(0);
                        setIsLoading(false);
                        setCreditoOk(true);

                    } else {
                        // deu algum erro tratar, colocar algum aviso
                        setIsLoading(false);
                    }
                } catch (postError) {
                    console.error(postError);
                    setIsLoading(false);
                }
            }

        } finally {
            setIsButtonDisabled(false);
            //setIsLoading(false);
        }
    };


    function BaixaParcela(wCodConvenio, wDataCredito, wValorParcela, wCodConcedente) {
        var datFiles = JSON.stringify({
            "CodigoConvenio": wCodConvenio,
            "CodigoCadastro": wCodConcedente,
            "Valor": wValorParcela,
            "DataBaixa": moment(wDataCredito).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        })
        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Financeiro/BaixarRepasse',
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            data: datFiles
        };
        console.log(datFiles)
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    getCreditosGravados();
                    setObs('');
                    setValorCredito(0);
                    setDataCredito('');
                    setConcedenteEscolhido(0);
                    setIsLoading(false);
                    setCreditoOk(true);

                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleExcluirClick = (wCredito, wValor, wObs) => {
        setValorCreditoExcluir(wValor)
        setObsCreditoExcluir(wObs)
        setCodCreditoExcluir(wCredito)
        setExibirAviso(true);
    };

    const handleConfirmarExclusao = () => {
        // Lógica para excluir o registro
        ExcluirCreditos(codcreditoexcluir)
        setExibirAviso(false);
    };

    const handleCancelarExclusao = () => {
        setExibirAviso(false);
    };


    const ExcluirCreditos = (wCredito) => {
        var configSelect = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Banco/' + wCredito,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataSelect
        };
        //console.log(dataSelect)
        axios(configSelect)

            .then(function (response) {
                getCreditosGravados();

                //setCadastroOk(true);
                //console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });

    }

    return (
        <div className={StyleForm.page}>
            <Menu />

            {/* <div> <button className={StyleForm.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={StyleForm.voltar}>  <Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            <div className={StyleForm.header}>
                <h1>Cadastro de Créditos</h1>
            </div>

            <div className={StyleForm.container}>

                {creditoMaiorQueSaldo ?
                    (<div style={{ display: 'flex', justifyContent: 'center', margin: '0 auto', width: '100%', borderRadius: '8px', backgroundColor: '#FDEDEC', color: '#B03A2E', fontSize: '14px', fontWeight: '600', padding: '.3em' }}>
                        <p style={{ margin: '.3em' }}><BiSolidError style={{ fontSize: '18px', marginBottom: '.2em' }} /> O valor inserido como crédito é superior ao saldo de repasses programado no cronograma</p>
                    </div>) :
                    (null)}

                <Form className={StyleForm.select} onSubmit={handleSubmit} >

                    <FormGroup className={StyleForm.form_group}>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Selecione a Fonte do Recurso</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="ConcendentesGravados" id="concedentesGravados" value={concedenteEscolhido} onChange={handleSelectConcedente}>
                                    <option>Selecione de onde vem o crédito</option>
                                    <option value="92">RENDIMENTOS FINANCEIROS</option>
                                    <option value="93">RESSARCIMENTO</option>
                                    {concedentesGravados.length > 0 &&
                                        concedentesGravados.map((opcao) => (
                                            <option key={opcao.CodigoConcedente} value={opcao.CodigoConcedente}>{opcao.Cadastro.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data do Crédito</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" value={dataCredito} onChange={(event) => setDataCredito(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor Crédito</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="valorCredito" value={valorCredito} onChange={(event) => setValorCredito(event.target.value)} required />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Observação</Form.Label>
                                <Form.Control className={StyleForm.form_control} name="obs" id="obs" value={obs} onChange={(event) => setObs(event.target.value)}></Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            {!editado && (
                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleSubmit}>
                                    Inserir Valores <AiFillPlusCircle />
                                </button>
                            )}
                        </Row>
                    </FormGroup>
                </Form>

                <div>
                    <div className={StyleForm.header}>
                        <p>Créditos Lançados</p>
                    </div>
                    <Table hover className={StyleForm.table}>
                        <thead>

                            <tr>
                                <th>Fonte do Recurso</th>
                                <th>Data</th>
                                <th>Valor</th>
                                <th>Observação</th>
                                {/* <th className={StyleForm.th_btn}></th>
                                 <th className={StyleForm.th_btn}></th> */}
                            </tr>
                        </thead>

                        <tbody>

                            {CreditosGravados &&

                                (CreditosGravados.length > 0 ?
                                    (CreditosGravados.map((Creditos) => (
                                        <tr key={Creditos.CodigoBanco}>
                                            <td>{Creditos.Cadastro.Nome}</td>
                                            <td>{moment(Creditos.DataBanco).format("DD/MM/yyyy")}</td>
                                            <td>{Creditos.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td>{Creditos.Obs}</td>
                                            {/* <td align="right"><button type="button" className={StyleForm.btn_delete} onClick={(event) => handleExcluirClick(Creditos.CodigoBanco, Creditos.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }), Creditos.Obs)}>
                                                <TiDelete /></button></td> */}
                                            {/* <td align="center"><button type="button" className={StyleForm.btn_edit} onClick={(event) => handleEditForm(Creditos.CodigoBanco)}>
                                            <AiOutlineEdit title="Editar" /> </button></td> */}
                                        </tr>
                                    ))
                                    ) : (<tr><td>Nenhum crédito lançado</td></tr>))}



                        </tbody>
                    </Table>
                    <div align="center">
                        {exibirAviso && (
                            <div className={StyleForm.aviso_delete}>
                                <h5>Deseja realmente excluir o registro?</h5>
                                <p>Valor - <strong>{valorcreditoexcluir}</strong> | Observação - <strong>{obscreditoexcluir}</strong></p>
                                <button className={StyleForm.confirmar_btn} onClick={handleConfirmarExclusao}>Confirmar</button>
                                <button className={StyleForm.cancelar_btn} onClick={handleCancelarExclusao}>Cancelar</button>
                            </div>
                        )}
                    </div>
                </div>

                {/* <button className={StyleForm.edit}>
                    <Link to={`/Metas/${wCodigoConvenioNovo}`}>
                        Cadastrar Metas <IoMdArrowForward/>
                    </Link>
                </button> */}

                <Modal show={isLoading} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <img style={{ width: '50px' }} src={loading} alt="ok"></img>
                    </Modal.Header>
                </Modal>

                <Modal show={creditoOk} onLoad={handleClose} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <img src={check_circle} alt="ok"></img>
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <h3>Tudo certo!</h3>
                        <p>Crédito inserido com sucesso</p>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalEdit} onLoad={handleCloseEdit} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <img src={check_circle} alt="ok"></img>
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        Créditos atualizados com sucesso!
                    </Modal.Body>
                </Modal>


            </div>
            {login && (<Navigate to="/" replace={true} />)}

        </div>
    )


}
export default Creditos