import React from "react";
import StyleForm from './PCAdiantamento.module.css'
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { useState, useEffect, useRef } from "react";
import InputMask from 'react-input-mask';
import { cnpj } from 'cpf-cnpj-validator';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Navigate } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import { Modal } from 'react-bootstrap';
//import check_circle from "../../img/check_circle.gif"
import urlAPIlocal from "../../global";
import loadingGif from '../img/icons8-spinning-circle.gif';
import { Table } from 'react-bootstrap';
import { TiDelete } from "react-icons/ti";
import MenuArea from "../Layout/MenuArea"
import Menu from "../Layout/Menu";
import { useParams } from "react-router";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoMdArrowBack, IoIosSend } from "react-icons/io";
import { ImSad2 } from 'react-icons/im';

function CadSolicitacao() {

    const [login, setLogin] = useState(false);
    var wTokenLocal = localStorage.getItem("token");
    var wTokenBearer = 'Bearer ' + localStorage.getItem("token");
    var wPerfil = localStorage.getItem("CodigoPerfil");
    var wUsuario = localStorage.getItem("CodigoUsuario");
    var wNomeUsuario = localStorage.getItem("NomeUsuario");
    const { CodigoSolicitacao } = useParams();
    const [anoSolic, setAnoSolic] = useState('');
    const [solicitacao, setSolicitacao] = useState([]);
    const [loading, setLoading] = useState(true);
    const [descricaoarquivo, setDescricaoArquivo] = useState('');
    const [NovoFile, setNovoFile] = useState(null);
    const fileInputRef = useRef(null);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [selecioneArquivo, setSelecioneArquivo] = useState(false);
    const [arquivo, setArquivo] = useState([]);
    const [arquivoEnviado, setArquivoEnviado] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);
    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosProcesso/"

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    //Requisição SOLICITAÇÃO
    const getSolicitacao = async () => {
        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                //console.log(data.objetoRetorno);
                setSolicitacao(data.objetoRetorno);
                setLoading(false);

                const dataCompleta = new Date(data.objetoRetorno[0].DataSolicitacao);
                const ano = dataCompleta.getFullYear();
                setAnoSolic(ano);


            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacao();
        //eslint-disable-next-line
    }, []);


    const getArquivo = async (event) => {

        // const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcesso?CodigoSolicitacao=' + CodigoSolicitacao,
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                const data = response.data;
                setArquivo(data.objetoRetorno);
                console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                //console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getArquivo();
        // eslint-disable-next-line
    }, []);

    const handleNovoFile = (event) => {
        if (event.target.files) {
            setNovoFile(event.target.files[0]);
        }
    };

    const handleSubmitEnviaArquivo = async (event) => {
        event.preventDefault();
        setLoading(true);
        setBotaoDesabilitado(true);
        console.log(NovoFile);
        if (NovoFile !== null) {
            setLoading(false);
            setSelecioneArquivo(false);
            GravaNovoArquivoComSenha();
        } else {
            setSelecioneArquivo(true);
        }
    }


    function gerarCodigoUnico() {

        let codigo = '';
        for (let i = 0; i < 4; i++) {
            codigo += Math.floor(Math.random() * 10); // Adiciona um número aleatório de 0 a 9 ao código
        }
        return codigo;
    }

    function GravaNovoArquivoComSenha() {
        setLoading(true);

        //trata nome arquivo, tira acentos, espaços
        let wNomeArquivoLimpo = NovoFile.name
        let wCodigoUnico = gerarCodigoUnico();
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
        wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        wNomeArquivoLimpo = "PC_" + CodigoSolicitacao + "_" + wCodigoUnico + "_" + wNomeArquivoLimpo

        var datFiles = JSON.stringify({
            "CodigoArquivo": 0,
            "CodigoSolicitacao": CodigoSolicitacao,
            "descricao": descricaoarquivo,
            "arquivo": wNomeArquivoLimpo, //CodCadastro + "_" + ArqCadastro.name,
            "status": 0
        })
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcesso',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },
            data: datFiles
        };
        //console.log(datFiles)
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    //faz upload do arquivo
                    const file = NovoFile;
                    const novoNome = wNomeArquivoLimpo;
                    const novoArquivo = new File([file], novoNome, { type: file.type });
                    const formData = new FormData();
                    formData.append('arquivo', novoArquivo);

                    var config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/UploadArquivo',
                        headers: {
                            'Authorization': wTokenBearer,
                            'Content-Type': 'multipart/form-dataa',
                            'pasta': 'ArquivosProcesso'
                        },
                        data: formData
                    };

                    axios(config)

                        .then(response => {
                            //console.log(response.data);

                            //Grava registro na tblStatus
                            var datHoje = new Date();
                            var dataStatus = JSON.stringify({
                                "CodigoStatus": 0,
                                "CodigoSolicitacao": CodigoSolicitacao,
                                "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                                "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                "status": false,
                                "Motivo": '<strong>Arquivo Inserido Por:</strong><br> ' + localStorage.getItem("NomeUsuario") + '<br>Arquivo: ' + wNomeArquivoLimpo + '<br>' + descricaoarquivo,
                                "CodigoPerfil": wUsuario,
                                "Doc": "",
                                "PerfilDestino": 53, // vai para Financeiro que faz a prestacao de contas
                                "CodCadastro": 155 //localStorage.getItem("CodigoCadastro")
                            });
                            var configStatus = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/Status',
                                headers: {
                                    'Authorization': wTokenBearer,
                                    'Content-Type': 'application/json',
                                },
                                data: dataStatus
                            };
                            //console.log(config);
                            axios(configStatus)
                                .then(function (responseStatus) {
                                    if (responseStatus.data.isOk === true) {

                                        //setEnviaArquivo(false);
                                        getSolicitacao();
                                        setNovoFile(null);
                                        setDescricaoArquivo('');
                                        fileInputRef.current.value = '';
                                        setBotaoDesabilitado(false);
                                        setLoading(false);
                                        setArquivoEnviado(true);
                                        //setAvisoSenhaOk(false);
                                        //setSenha('');
                                        getArquivo();
                                    }
                                })

                                .catch(error => {
                                    console.error(error);
                                    setLoading(false);
                                    setShowModalErro(true);
                                });




                        })
                        .catch(error => {
                            console.error(error);
                            setLoading(false);
                            //Retorno = true;

                        });
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //Retorno = true

                }

            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
    }

    function handleCloseErro() {
        setShowModalErro(false);
    };


    return (
        <>
            {wPerfil !== '37' ? <Menu /> : <MenuArea />}
            <div className={StyleForm.page}>
                <div className={StyleForm.letreiro}>
                    <h1>Prestação de Contas - Solicitação N° {CodigoSolicitacao} / {anoSolic}</h1>
                </div>
                <div>
                    <p></p>
                    <p></p>
                    <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Descrição Arquivo: </Form.Label>
                    <Form.Control name="descricaoarquivo" value={descricaoarquivo} onChange={(event) => setDescricaoArquivo(event.target.value)} style={{ color: '#707070' }} />

                    <Form.Label style={{ color: '#fff' }}>.</Form.Label>
                    <Form.Control name="NovoFile" type="file" accept="application/pdf" onChange={handleNovoFile} ref={fileInputRef} />
                    <p></p>
                    {/* <p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)} */}
                    <p align='center'><button className={StyleForm.btn_atualizar} onClick={handleSubmitEnviaArquivo} disabled={botaoDesabilitado}>Enviar Arquivo<IoIosSend /></button></p>
                    {selecioneArquivo && (
                        <p align="center">
                            <AiFillCheckCircle /><strong>escolha um arquivo!</strong>
                        </p>
                    )}
                </div>



            </div>
            {arquivoEnviado && (
                <p align="center">
                    <AiFillCheckCircle /><strong>Arquivo Enviado com Sucesso!</strong>
                </p>
            )}

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '600px', flexWrap: 'wrap', margin: '0 auto' }}>
                {arquivo &&
                    arquivo.length > 0 &&
                    arquivo
                        .filter((ArquivoProcesso) => ArquivoProcesso.Status === 0 && ArquivoProcesso.Arquivo.startsWith('PC_'))
                        .map((ArquivoProcesso) => (
                            <div
                                key={ArquivoProcesso.CodigoArquivo}
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '8px',
                                    padding: '10px',
                                    textAlign: 'left',

                                    margin: '10px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >

                                    <label htmlFor={ArquivoProcesso.CodigoArquivo} style={{ margin: '10px' }}>
                                        {ArquivoProcesso.Arquivo}
                                    </label>
                                </div>

                                <embed
                                    title={ArquivoProcesso.Arquivo}
                                    style={{ width: '600px', height: '100vh', margin: '0 auto' }}
                                    src={LinkArquivo + ArquivoProcesso.Arquivo}
                                />
                            </div>
                        ))}
            </div>


            {login && (<Navigate to="/" replace={true} />)}
            <Modal show={loading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>
            <Modal show={showModalErro} className={StyleForm.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

        </>

    )



}
export default CadSolicitacao