import { useEffect, useState } from "react";
import axios from "axios";
import Menu from "../Layout/Menu"
import Table from 'react-bootstrap/Table';
import { Link, useParams } from "react-router-dom";

import { IoMdArrowBack } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { HiPencil } from 'react-icons/hi2'

import Style from "./ListPagamentos.module.css";

import { Pagination } from 'react-bootstrap';
import moment from "moment";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';

function PagamentosComSolic() {

    const { CodigoConvenio } = useParams();

    const [loading, setLoading] = useState(true);
    const [solicAPagar, setSolicAPagar] = useState([]);
    const [fase, setFase] = useState([]);
    const [busca, setBusca] = useState('');
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [login, setLogin] = useState(false);

    var wToken = 'Bearer ' + localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wToken === undefined || wToken === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function PegaMetaFase(CodFase) {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Fase?CodigoConvenioFase=' + CodFase + '&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                //console.log(response)
                const data = response.data;
                if (response.data.isOk === true) {
                    setFase(data.objetoRetorno);
                    //console.log(data.objetoRetorno)
                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setFase('');
                }
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }


    const getSolicitacoesAPagar = async () => {

        setLoading(true);

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?Pago=false&Indeferida=false&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                //console.log(data);

                // Filtrar os resultados com CodigoAprovDpcc > 0
                if (data.objetoRetorno !== null) {
                    const resultadosFiltrados = data.objetoRetorno.filter((item) => item.CodigoAprovacaoDPCC > 0);

                    setSolicAPagar(resultadosFiltrados);
                    //console.log(resultadosFiltrados);

                    // Chamar PegaMetaFase apenas para os resultados filtrados
                    resultadosFiltrados.forEach((item) => {
                        PegaMetaFase(item.CodigoFase);
                    });
                }

                setLoading(false);

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });

    }

    useEffect(() => {
        getSolicitacoesAPagar();
        //eslint-disable-next-line
    }, []);

    if (solicAPagar !== null && Array.isArray(solicAPagar)) {
        // Calculate the total number of pages
        var totalPages = Math.ceil(solicAPagar.length / itemsPerPage);
    }

    if (itensFiltrados.length > 0 && (totalPages = Math.ceil(itensFiltrados.length / itemsPerPage)));

    // Calculate the indexes of the first and last items of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    if (solicAPagar !== null) {
        // Get the items of the current page
        var currentItems = solicAPagar.slice(indexOfFirstItem, indexOfLastItem);
    }

    if (itensFiltrados.length > 0 && (currentItems = itensFiltrados.slice(indexOfFirstItem, indexOfLastItem)));

    // Generate the list of items of pagination
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => setCurrentPage(number)}
            >
                {number}
            </Pagination.Item>
        );
    }

    const handleSearch = (event) => {
        const value = event.target.value;
        //getCadastro();
        const filteredItems = solicAPagar.filter((item) =>
            item.Cadastro.Nome.toLowerCase().includes(value.toLowerCase())
        );
        setItensFiltrados(filteredItems);
        setBusca(value);
        //setCadastro(filteredItems);
    };

    return (
        <div className={Style.page}>
            <Menu />
            <div> <button className={Style.voltar}>  <Link to={`/ListPagamentos/${CodigoConvenio}`} ><IoMdArrowBack /> Voltar</Link> </button> </div>
            <div className={Style.header}>

                <div >
                    <h1>Solicitações a Pagar</h1>
                </div>
                <div className={Style.input_container}>
                    <AiOutlineSearch />
                    <input type="text" value={busca} onChange={handleSearch} placeholder="Pesquisar..." />
                </div>
            </div>

            <div className={Style.table_container}>

                <Table bordered className={Style.table}>
                    <thead>
                        <tr>
                            <th className={Style.th_numero}>N° Solicitação</th>
                            <th className={Style.th}>Data</th>
                            <th className={Style.th}>Beneficiário</th>
                            <th className={Style.th}>Valor Total</th>
                            <th className={Style.th}>Meta</th>
                            <th className={Style.th}>Fase</th>
                            <th className={Style.th_btn}></th>
                        </tr>
                    </thead>
                    <tbody>

                        {solicAPagar !== null ? (
                            (itensFiltrados.length > 0 ?

                                (
                                    currentItems.map((solic) => {

                                        const faseInfo = fase.find((f) => f.CodigoConvenioFase === solic.CodigoFase);
                                        //console.log(faseInfo)
                                        return (
                                            <tr key={solic.CodigoSolicitacao}>
                                                <td>{solic.CodigoSolicitacao}</td>
                                                <td>{moment(solic.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                                <td>{solic.Cadastro.Nome}</td>
                                                <td>{solic.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td>{faseInfo ? faseInfo.Meta.NumMeta : ''}</td>
                                                <td>{faseInfo ? faseInfo.NumeroFase : ''}</td>
                                                <td className={Style.td_btn}><button><Link to={`/PagamentoSolicForm/${solic.CodigoSolicitacao}`}><HiPencil /></Link></button></td>
                                            </tr>
                                        );
                                    })
                                ) :
                                (
                                    currentItems.map((solic) => {

                                        const faseInfo = fase.find((f) => f.CodigoConvenioFase === solic.CodigoFase);
                                        //console.log(faseInfo)
                                        return (
                                            <tr key={solic.CodigoSolicitacao}>
                                                <td>{solic.CodigoSolicitacao}</td>
                                                <td>{moment(solic.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                                <td>{solic.Cadastro.Nome}</td>
                                                <td>{solic.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td>{faseInfo ? faseInfo.Meta.NumMeta : ''}</td>
                                                <td>{faseInfo ? faseInfo.NumeroFase : ''}</td>
                                                <td className={Style.td_edit}><button><Link to={`/PagamentoSolicForm/${solic.CodigoSolicitacao}`}><HiPencil /></Link></button></td>
                                            </tr>
                                        );
                                    })
                                ))) : (<tr>
                                    <td>Não há solicitações a pagar nesse projeto</td>
                                </tr>)}
                    </tbody>
                </Table>


                <Pagination>{items}</Pagination>

                <Modal show={loading} className={Style.Modal} centered >
                    <Modal.Body className={Style.Modal_body}>
                        <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                    </Modal.Body>
                </Modal>

                {/* {login && (<Navigate to="/" replace={true} />)} */}
            </div>

        </div>
    )
}
export default PagamentosComSolic