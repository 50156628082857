import { Link } from "react-router-dom";
import Menu from "../Layout/Menu";
import MenuArea from "../Layout/MenuArea";
import Styles from './Index.module.css';
import { useState, useEffect } from "react";
import { VscOpenPreview } from "react-icons/vsc";
import { PiClockCountdownDuotone, PiHandshakeDuotone } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { BiSearchAlt, BiArrowFromLeft } from "react-icons/bi";
import { TbReportMoney } from "react-icons/tb";
import { Navigate } from "react-router";
import { Box, FormGroup } from "@mui/material";
import gestor from "./../img/gestor.png";
import compras from "./../img/compras.png";
import financeiro from "./../img/financeiro.png";
import diretorPresidente from "./../img/DiretorPresidente.png";
import prestacaoContas from "./../img/PrestacaoContas.png";
import juridico from "./../img/juridico.png";
import assessoriaAdmin from "./../img/AssessoriaAdmin.png";
import diretorAdmin from "./../img/diretor-admin.png";
import consultor from "./../img/consultor.png";
import rh from "./../img/rh.png";
import pc from "./../img/pc.png";
import secExecutivo from "./../img/sec-executivo.png";
import ti from "./../img/ti.png";
import projetos from "./../img/projetos.png";
import area from "./../img/area.png";
import { FormLabel, FormControl, Table, Col } from "react-bootstrap";
import axios from "axios";
import urlAPIlocal from '../../global';
import moment from "moment";
import { GrStatusGoodSmall } from 'react-icons/gr';
import loadingGif from '../img/icons8-spinning-circle.gif';
import { Modal } from 'react-bootstrap';
import OutraPagina from './Solicitacoes.js';
import { BsFileEarmarkCheck, BsFileEarmarkExcel } from 'react-icons/bs';
import { Pagination } from 'react-bootstrap';
//import { Tooltip } from 'react-tooltip';
//import '../react-tooltip/dist/index.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';



function Index() {

    var RemetenteProcesso = "";
    var DataEncaminhamentoStatusSolic = "";
    var DataEncaminhamentoStatusProcessoInterno = "";

    //const [name, setName] = useState("");
    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(true);
    var wTokenLocal = localStorage.getItem("token");
    var wPerfil = localStorage.getItem("CodigoPerfil");
    var wUsuario = localStorage.getItem("CodigoUsuario");
    var wNomeUsuario = localStorage.getItem("NomeUsuario");

    const [solicitacoesCompras, setSolicitacoesCompras] = useState([]);
    const [solicitacoesFinanceiro, setSolicitacoesFinanceiro] = useState([]);
    const [solicitacoesAPagar, setSolicitacoesAPagar] = useState([]);
    const [solicitacoesEncaminhadas, setSolicitacoesEncaminhadas] = useState([]);
    const [credenciamentosEncaminhadas, setCredenciamentosEncaminhadas] = useState([]);
    const [credenciamentosNovos, setCredenciamentosNovos] = useState([]);
    const [solicitacoesArea, setSolicitacoesArea] = useState([]);
    const [solicitacoesAreaEncaminhadas, setSolicitacoesAreaEncaminhadas] = useState([]);
    const [processosInternos, setProcessosInternos] = useState([]);
    const [numProcessoInterno, setNumProcessoInterno] = useState('');
    const [numSolicitacao, setNumSolicitacao] = useState('');
    const [projetoArea, setProjetoArea] = useState([]);
    const [remetente, setRemetente] = useState('');
    const [credenciamentosData, setCredenciamentosData] = useState([]);
    const [resultOFX, setResultOFX] = useState([]);
    const [NovoFile, setNovoFile] = useState(null);
    const [nomeFinalOFX, setNomeFinalOFX] = useState(null);

    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosOFX/"

    //const [busca, setBusca] = useState('');
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    //console.log(wNomeUsuario);



    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    const items = [];
    if (solicitacoesArea && solicitacoesArea !== null) {

        var totalPages = 0;
        if (solicitacoesArea.length > 0 && (totalPages = Math.ceil(solicitacoesArea.length / itemsPerPage)));

        if (itensFiltrados.length > 0 && (totalPages = Math.ceil(itensFiltrados.length / itemsPerPage)));

        // Calculate the indexes of the first and last items of the current page
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Get the items of the current page
        var currentItems = solicitacoesArea.slice(indexOfFirstItem, indexOfLastItem);

        if (itensFiltrados.length > 0 && (currentItems = itensFiltrados.slice(indexOfFirstItem, indexOfLastItem)));

        // Generate the list of items of pagination
        //const items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => setCurrentPage(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
    }


    // const handleSearch = (event) => {
    //     const value = event.target.value;
    //     //getsolicitacoesArea();
    //     const filteredItems = solicitacoesArea.filter((item) =>
    //         item.Nome.toLowerCase().includes(value.toLowerCase())
    //     );
    //     setItensFiltrados(filteredItems);
    //     setBusca(value);
    //     //console.log(filteredItems);
    // };

    const getSolicitacoesCompras = async () => {
        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        //console.log(wCodConvenio)

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?IndFinalizada=false&PerfilDestinoAtual=' + wUsuario + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                setSolicitacoesCompras(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                    setLoading(false);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacoesCompras();
        //eslint-disable-next-line
    }, []);

    const getSolicitacoesFinanceiro = async () => {
        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        //console.log(wCodConvenio)

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?IndFinalizada=false&PerfilDestinoAtual=' + wUsuario + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                setSolicitacoesFinanceiro(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                    setLoading(false);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacoesFinanceiro();
        //eslint-disable-next-line
    }, []);

    const getSolicitacoesAPagar = async () => {
        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        //console.log(wCodConvenio)

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?IndFinalizada=true&Pago=false&CodigoAprovacaoDpcc=1&PerfilDestinoAtual=' + wUsuario + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                setSolicitacoesAPagar(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                    setLoading(false);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacoesAPagar();
        //eslint-disable-next-line
    }, []);



    useEffect(() => {
        // if (wUsuario === '17') {
        const getCredenciamentosEncaminhadas = async () => {
            setLoading(true);
            const wtoken = "Bearer " + wTokenLocal;
            //console.log(wCodConvenio)

            var configSolic = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Cadastro?IsInclude=true',
                headers: {
                    'Authorization': wtoken,
                    'Content-Type': 'application/json'
                },

            };

            axios(configSolic)

                .then(function (responseSolic) {

                    const data = responseSolic.data.objetoRetorno;
                    //console.log(data);

                    const usuarioDestino = parseInt(wUsuario, 10);
                    const cadastrosEncaminhados = data.filter(opcao => opcao.PerfilDestinoAtual === usuarioDestino);
                    setCredenciamentosEncaminhadas(cadastrosEncaminhados);
                    console.log(cadastrosEncaminhados);

                    const cadastrosNovos = data.filter(opcao => opcao.PerfilDestinoAtual === 42 && opcao.Aprovado === 0);
                    setCredenciamentosNovos(cadastrosNovos);

                    setLoading(false);

                    //listar somente as solicitacoes ou credenciamentos que o ultimo status tem PerfilDeDestino =17
                    //BuscaUltimoStatus(data.objetoRetorno.CodigoSolicitacao);   

                    //console.log(cadastrosEncaminhados);
                    //console.log(cadastrosNovos);
                }
                )
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 401) {
                        setLogin(true);
                        setLoading(false);
                    }
                    //console.log(JSON.stringify(response.data.mensagemRetorno));
                });
        }
        getCredenciamentosEncaminhadas();
    }
        //eslint-disable-next-line
        , []);

    useEffect(() => {
        let wConsulta = ''
        if (wUsuario === '38') {
            wConsulta = '/api/Solicitacao?CodigoAprovacaoDpcc=0&IndFinalizada=true&PerfilDestinoAtual=' + wUsuario + '&IsInclude=true'
        } else {
            wConsulta = '/api/Solicitacao?IndFinalizada=true&PerfilDestinoAtual=' + wUsuario + '&IsInclude=true'
        }

        const getSolicitacoesEncaminhadas = async () => {
            setLoading(true);
            const wtoken = "Bearer " + wTokenLocal;
            //console.log(wPerfil)

            var configSolic = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + wConsulta,
                headers: {
                    'Authorization': wtoken,
                    'Content-Type': 'application/json'
                },

            };
            //console.log(configSolic);
            axios(configSolic)

                .then(function (responseSolic) {

                    const data = responseSolic.data;
                    setSolicitacoesEncaminhadas(data.objetoRetorno);
                    console.log(data.objetoRetorno);
                    // se for perfil =38 nao exibe os registros que o codProcessoInterno for <> 0 
                    /*  const listaOriginal = data.objetoRetorno;
 
                     // Filtra os registros com CodigoProcessoInterno diferente de zero
                     const novaLista = listaOriginal.filter(registro => registro.CodigoProcessoInterno !== 0);
                     //console.log(novaLista);
                     //console.log(wPerfil);
                     if (wPerfil !== '38') {
                         // Atualiza o estado com a nova lista filtrada
 
                         setSolicitacoesEncaminhadas(data.objetoRetorno);
 
                     } else {
                         // Atualiza o estado com a nova lista filtrada
                         setSolicitacoesEncaminhadas(novaLista);
                     } */


                    setLoading(false);
                    //console.log(data.objetoRetorno);
                    //console.log(data.objetoRetorno[0].Status);
                }
                )
                .catch(function (error) {
                    console.log(error);
                    if (error.response.status === 401) {
                        setLogin(true);
                        setLoading(false);
                    }
                    //console.log(JSON.stringify(response.data.mensagemRetorno));
                });
        }
        getSolicitacoesEncaminhadas();
    }
        //eslint-disable-next-line
        , []);

    useEffect(() => {
        console.log(wPerfil)
        if (wPerfil === '37') {
            const getSolicitacoesArea = async () => {
                setLoading(true);
                var wCodigoConvenio = localStorage.getItem("CodigoProjeto");

                const wtoken = "Bearer " + wTokenLocal;
                //console.log(wCodConvenio)

                var configSolic = {
                    method: 'GET',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Solicitacao?CodigoConvenio=' + wCodigoConvenio + '&IsInclude=true',
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json'
                    },

                };

                axios(configSolic)

                    .then(function (responseSolic) {

                        const data = responseSolic.data;
                        //console.log(data.objetoRetorno);

                        //filtrar somente solicitacões que o PerfilDestino !== wUsuario
                        const solicArea = data.objetoRetorno.filter(solic => solic.PerfilDestinoAtual !== wUsuario);
                        setSolicitacoesArea(solicArea);

                        //filtrar somente solicitacões que o PerfilDestino === wUsuario
                        const solicAreaEncaminhadas = data.objetoRetorno.filter(solic => solic.PerfilDestinoAtual === wUsuario);
                        setSolicitacoesAreaEncaminhadas(solicAreaEncaminhadas);

                        setLoading(false);

                    }
                    )
                    .catch(function (error) {
                        console.log(error);
                        if (error.response.status === 401) {
                            setLogin(true);
                            setLoading(false);
                        }
                        //console.log(JSON.stringify(response.data.mensagemRetorno));
                    });
            }
            getSolicitacoesArea();
        }
        //eslint-disable-next-line
    }, []);

    function ListaStatusSolic(wListaStatusSolic) {

        //console.log(wListaStatusSolic);


        if (wListaStatusSolic.length >= 1) {
            // Obtém o penúltimo elemento da lista
            const penultimoStatus = wListaStatusSolic[wListaStatusSolic.length - 1];

            // Acesse o nome do usuário no penúltimo status
            const penultimoNomeUsuario = penultimoStatus.Usuarios.Nome;

            // Agora, você pode usar penultimoNomeUsuario conforme necessário
            //console.log(penultimoNomeUsuario);
            return penultimoNomeUsuario;
        } else {
            // Se a lista não tiver pelo menos dois elementos, lide com isso conforme necessário
            console.log("A lista não tem elementos suficientes.");
        }
    }



    // function ListaStatusSolic2(wListaStatusSolic) {
    //     console.log(wListaStatusSolic);
    //     if (wListaStatusSolic.length !== 0) {
    //         const wUltimoStatus = wListaStatusSolic[wListaStatusSolic.length - 1];
    //         console.log(wUltimoStatus);
    //         const wNomeRemetente = wUltimoStatus.Usuarios.Nome;
    //         //console.log(wUltimoStatus.PerfilRemetente.Nome);
    //         //const wNomeDestino = wUltimoStatus.Perfil_Destino.Nome;
    //         return wNomeRemetente;
    //     } else {
    //         return "";
    //     }
    // }

    function ListaStatusSolic2(wListaStatusSolic) {
        //console.log(wListaStatusSolic);
        // Verifica se há pelo menos dois elementos na lista
        if (wListaStatusSolic.length >= 2) {


            // Obtém o penúltimo elemento da lista
            const penultimoStatus = wListaStatusSolic[wListaStatusSolic.length - 2];
            //console.log(penultimoStatus);
            // Acesse o nome do usuário no penúltimo status
            const penultimoNomeUsuario = penultimoStatus.Usuarios.Nome;
            const penultimoSetor = penultimoStatus.Usuarios.Perfil.Nome;

            // Agora, você pode usar penultimoNomeUsuario conforme necessário
            //console.log(penultimoNomeUsuario);
            return penultimoNomeUsuario + ' - ' + penultimoSetor;
        } else {
            // Se a lista não tiver pelo menos dois elementos, lide com isso conforme necessário
            console.log("A lista não tem elementos suficientes.");
        }
    }



    function ListaDataEncaminhamento(wListaStatusSolic) {
        if (wListaStatusSolic.length !== 0) {
            const wUltimoStatus = wListaStatusSolic[wListaStatusSolic.length - 1];
            const wData = wUltimoStatus.DataAprovacao;
            return moment(wData).format("DD/MM/yyyy");
        } else {
            return "";
        }
    }

    const calcularPrazo = (dataEncaminhamento) => {
        const prazoDias = 5;
        const dataAtual = moment();
        const dataEncaminhamentoMoment = moment(dataEncaminhamento, 'DD/MM/YYYY');
        const prazoLimite = dataEncaminhamentoMoment.add(prazoDias, 'days');

        const diasFaltando = prazoLimite.diff(dataAtual, 'days');

        return {
            diasFaltando,
            atrasado: dataAtual.isAfter(prazoLimite, 'day'),
        };
    };

    async function PegaRemetente(wCodUsuario) {
        try {
            const wtoken = "Bearer " + wTokenLocal;
            var config = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Usuarios?CodigoUsuario=' + wCodUsuario + '&IsInclude=false',
                headers: {
                    'Authorization': wtoken,
                    'Content-Type': 'application/json'
                },

            };

            const response = await axios(config);


            const data = response.data.objetoRetorno[0];
            //console.log(data.Nome)
            return data.Nome;

        } catch (error) {
            console.log(error);
            return '';
        }
    }

    async function PegaStatus(wCodCadastro) {
        try {
            const wtoken = "Bearer " + wTokenLocal;
            var config = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Status?CodigoCadastro=' + wCodCadastro + '&IsInclude=true',
                headers: {
                    'Authorization': wtoken,
                    'Content-Type': 'application/json'
                },

            };

            const response = await axios(config);


            const data = response.data.objetoRetorno;
            //console.log(data)
            data.sort((a, b) => new Date(b.DataAprovacao) - new Date(a.DataAprovacao));

            // Obtenha o penúltimo elemento
            const penultimoStatus = data[1];

            return penultimoStatus.Usuarios.Nome;

        } catch (error) {
            console.log(error);
            return '';
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            const data = await Promise.all(
                credenciamentosEncaminhadas.map(async (item) => {
                    const usuarioNome = await PegaRemetente(item.PerfilDestinoAtual);
                    const dataEmissao = moment(item.DataEmissao);
                    const dataMais15Dias = dataEmissao.add(15, 'days');
                    const prazoAtendimento = moment(dataMais15Dias).format('DD/MM/yyyy');
                    const status = await PegaStatus(item.CodigoCadastro);

                    return {
                        CodigoCadastro: item.CodigoCadastro,
                        Nome: item.Nome,
                        PerfilDestinoAtual: item.PerfilDestinoAtual,
                        usuarioNome: usuarioNome,
                        prazoAtendimento: prazoAtendimento,
                        status: status,
                    };
                })
            );
            setCredenciamentosData(data);
            //console.log(data);
        };

        fetchData();
    }, [credenciamentosEncaminhadas]);

    // function getIconColor(nomePerfil) {
    //     if (nomePerfil === 'Compras') {
    //         return '#2980B9';
    //     } else if (nomePerfil === 'Financeiro') {
    //         return '#27AE60';
    //     } else if (nomePerfil === 'Assessoria Jurídica') {
    //         return '#CB4335';
    //     } else if (nomePerfil === 'Diretoria Administrativa') {
    //         return '#F4D03F';
    //     } else if (nomePerfil === 'Recursos Humanos') {
    //         return '#8E44AD';
    //     } else if (nomePerfil === 'Secretário Executivo') {
    //         return '#0B5345';
    //     } else if (nomePerfil === 'Consultor') {
    //         return '#626567';
    //     } else if (nomePerfil === 'Gestão de Projetos') {
    //         return '#E67E22';
    //     } else if (nomePerfil === 'Diretor Presidente') {
    //         return '#E67E22';
    //     } else if (nomePerfil === 'Equipe Area') {
    //         return '#85C1E9';
    //     } else {
    //         return '#34495E';
    //     }
    // };

    function ListaDataEncaminhamentoProcesso(wListaStatusProcessoInterno) {
        if (wListaStatusProcessoInterno.length !== 0) {
            const wUltimoStatus = wListaStatusProcessoInterno[wListaStatusProcessoInterno.length - 1];
            const wData = wUltimoStatus.DataMovimento;
            //console.log(wData)
            return moment(wData).format("DD/MM/yyyy");
        } else {
            return "";
        }
    }

    const calcularPrazoProcesso = (dataEncaminhamentoProcessoInterno) => {
        const prazoDias = 5;
        const dataAtual = moment();
        const dataEncaminhamentoProcessoMoment = moment(dataEncaminhamentoProcessoInterno, 'DD/MM/YYYY');
        const prazoLimite = dataEncaminhamentoProcessoMoment.add(prazoDias, 'days');

        const diasFaltando = prazoLimite.diff(dataAtual, 'days');

        return {
            diasFaltando,
            atrasado: dataAtual.isAfter(prazoLimite, 'day'),
        };
    };



    const getProcessosInternos = async () => {
        //setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ProcessoInterno?PerfilDestinoAtual=' + wUsuario + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const data = response.data;
                setProcessosInternos(data.objetoRetorno);
                //setLoading(false);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                //setLoading(false);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getProcessosInternos();
        // eslint-disable-next-line
    }, []);

    const handlePrint = () => {
        if (numProcessoInterno) {
            window.open(`/PrintProcessoInterno/${numProcessoInterno}`, '_blank');
            setNumProcessoInterno('');
        }
        if (numSolicitacao) {
            window.open(`/PrintSolic/${numSolicitacao}`, '_blank');
            setNumSolicitacao('');
        }
    }

    const getProjetoArea = async (event) => {
        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + localStorage.getItem('CodigoProjeto'),
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                setProjetoArea(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    useEffect(() => {
        getProjetoArea();
    }, []);

    /* const handleFileRead = async (event) => {
        event.preventDefault();

        setNovoFile(event.target.files[0]);
        GravaOFX();

        const ofxData = readFileSync(LinkArquivo + nomeFinalOFX, 'utf8');
        console.log(ofxData);
        const options = {
            compact: true,
            ignoreComment: true,
            spaces: 4,
        };

        let resultOFX = xml2js(ofxData, options);

        console.log(resultOFX);
        setResultOFX(resultOFX);

    } */

    /*     function gerarCodigoUnico() {
    
            let codigo = '';
            for (let i = 0; i < 4; i++) {
                codigo += Math.floor(Math.random() * 10); // Adiciona um número aleatório de 0 a 9 ao código
            }
            return codigo;
        } */

    /*  function GravaOFX() {
         //setLoading(true);
 
         //trata nome arquivo, tira acentos, espaços
         let wNomeArquivoLimpo = NovoFile.name
         let wCodigoUnico = gerarCodigoUnico();
         wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
         wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
         wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
         wNomeArquivoLimpo = "OFX_" + wCodigoUnico + "_" + wNomeArquivoLimpo
 
         //faz upload do arquivo
         const file = NovoFile;
         const novoNome = wNomeArquivoLimpo;
         setNomeFinalOFX(wNomeArquivoLimpo);
         const novoArquivo = new File([file], novoNome, { type: file.type });
         const formData = new FormData();
         formData.append('arquivo', novoArquivo);
 
         var config = {
             method: 'post',
             maxBodyLength: Infinity,
             url: urlAPIlocal + '/api/UploadArquivo',
             headers: {
                 'Authorization': "Bearer " + wTokenLocal,
                 'Content-Type': 'multipart/form-dataa',
                 'pasta': 'ArquivosOFX'
             },
             data: formData
         };
 
         axios(config)
 
             .then(response => {
                 console.log(response.data);
 
             })
             .catch(error => {
                 console.error(error);
                 //setLoading(false);
                 //Retorno = true;
 
             });
 
     } */


    return (
        <div className={Styles.index}>
            {wPerfil !== '37' ? <Menu /> : <MenuArea />}

            <div className={Styles.box_container}>
                <Box className={Styles.box_welcome}>

                    <div className={Styles.col1}>
                        <h5>Olá,</h5>
                        <h3>{wNomeUsuario}</h3>
                    </div>

                    <div className={Styles.col2}>
                        {wPerfil === '1' && <img src={gestor} alt="" />}
                        {wPerfil === '17' && <img src={compras} alt="" />}
                        {wPerfil === '38' && <img src={financeiro} alt="" />}
                        {wPerfil === '23' && <img src={prestacaoContas} alt="" />}
                        {wPerfil === '31' && <img src={juridico} alt="" />}
                        {wPerfil === '24' && <img src={diretorAdmin} alt="" style={{ width: '90px' }} />}
                        {wPerfil === '25' && <img src={assessoriaAdmin} alt="" />}
                        {wPerfil === '28' && <img src={diretorPresidente} alt="" />}
                        {wPerfil === '32' && <img src={rh} alt="" />}
                        {wPerfil === '33' && <img src={secExecutivo} alt="" />}
                        {wPerfil === '34' && <img src={consultor} alt="" />}
                        {wPerfil === '35' && <img src={ti} alt="" />}
                        {wPerfil === '36' && <img src={projetos} alt="" />}
                        {wPerfil === '37' && <img src={area} alt="" />}
                    </div>
                </Box>

                <Box className={Styles.box_tbd}>
                    {wPerfil === '1' && <h2>Gestor</h2>}
                    {wPerfil === '17' && <h2>Setor de Compras</h2>}
                    {wPerfil === '23' && <h2>Prestação de Contas</h2>}
                    {wPerfil === '25' && <h2>Assessoria Administrativa</h2>}
                    {wPerfil === '28' && <h2>Diretor Presidente</h2>}
                    {wPerfil === '38' && <h2>Setor Financeiro</h2>}
                    {wPerfil === '31' && <h2>Assessoria Jurídica</h2>}
                    {wPerfil === '24' && <h2>Direção Administrativa</h2>}
                    {wPerfil === '32' && <h2>Setor de Recursos Humanos</h2>}
                    {wPerfil === '33' && <h2>Secretário Executivo</h2>}
                    {wPerfil === '34' && <h2>Consultor</h2>}
                    {wPerfil === '35' && <h2>Setor de Tecnologia da Informação</h2>}
                    {wPerfil === '36' && <h2>Gestão de Projetos</h2>}
                    {wPerfil === '37' && <h2>Área</h2>}
                </Box>

                {wPerfil !== '37' ?
                    (<Box className={Styles.box_print}>
                        <h4>Buscar Processos No Sistema</h4>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div style={{ textAlign: 'center', borderRadius: '10px', backgroundColor: '#5499C7', padding: '1em', marginRight: '1em' }}>
                                <FormLabel className={Styles.form_group_label}>Buscar Processo Interno</FormLabel>
                                <input className={Styles.form_control} type="text" name="NumProcessoInterno" placeholder="Numero do Processo" value={numProcessoInterno} onChange={(event) => setNumProcessoInterno(event.target.value)} />
                            </div>
                            <div style={{ textAlign: 'center', borderRadius: '10px', backgroundColor: '#5499C7', padding: '1em', marginLeft: '1em' }}>
                                <FormLabel className={Styles.form_group_label}>Buscar Solicitação</FormLabel>
                                <input className={Styles.form_control} type="text" name="NumSolicitacao" placeholder="Numero da Solicitação" value={numSolicitacao} onChange={(event) => setNumSolicitacao(event.target.value)} />
                            </div>
                        </div>
                        <button type="button" onClick={handlePrint} className={Styles.btn_print}>Buscar <BiSearchAlt /></button>
                    </Box>) : (
                        <Box className={Styles.box_area}>
                            <Col md={10}>
                                <h4>Projeto: {projetoArea.Nome}</h4>
                                <p>Expira em: {moment(projetoArea.DataExpiracao).format('DD-MM-YYYY')}</p>
                                <Link to={`/Solicitacoes/${localStorage.getItem('CodigoProjeto')}`}>Consultar Execução Financeira do Projeto <BiArrowFromLeft /></Link>
                            </Col>
                            <Col style={{ position: 'absolute', bottom: '1em', right: '.8em' }}>
                                <span style={{ padding: '.3em .5em', fontSize: '25px', borderRadius: '5px', backgroundColor: 'rgb(21, 58, 84)', color: '#EBF5FB' }}><TbReportMoney /></span>
                            </Col>
                        </Box>
                    )
                }
            </div>


            {wPerfil === '17' &&
                <div className={Styles.table_container}>

                    <h3><span><PiClockCountdownDuotone /></span>Novas Solicitações (Processos nos Projetos)</h3>
                    <Table hover>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>N° Solicitação</th>
                                {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Elemento</th> */}
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Projeto</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Data</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "right" }}>Valor</th>
                                {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "center" }}>Status</th> */}
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#ffffff', color: '#154360', textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {solicitacoesCompras &&
                                (solicitacoesCompras.length > 0 ?
                                    (solicitacoesCompras.map((item) => (
                                        <tr key={item.CodigoSolicitacao}>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.CodigoSolicitacao}</td>
                                            {/* <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.ConvenioElemento.Elemento.Nome}</td> */}
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.Convenio.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{moment(item.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "right" }}>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td align="center">

                                                <button className={Styles.btn_solic} title="Editar Solicitação">
                                                    <Link to={`/EditSolic/${item.CodigoSolicitacao}`}><FiEdit /></Link>
                                                </button>

                                            </td>
                                            {/* <td style={{ padding: '5px', fontSize: '14px', textAlign: "center" }}>status</td> */}
                                        </tr>
                                    ))
                                    ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não Há Processos Novos</td></tr>))}
                        </tbody>
                    </Table>

                </div>
            }

            {wPerfil === '38' &&
                <div className={Styles.table_container}>

                    <h3><span><PiClockCountdownDuotone /></span>Novas Solicitações (Processos nos Projetos)</h3>
                    <Table hover>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>N° Solicitação</th>
                                {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Elemento</th> */}
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Projeto</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Data</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "right" }}>Valor</th>
                                {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "center" }}>Status</th> */}
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#ffffff', color: '#154360', textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {solicitacoesFinanceiro &&
                                (solicitacoesFinanceiro.length > 0 ?
                                    (solicitacoesFinanceiro.map((item) => (
                                        <tr key={item.CodigoSolicitacao}>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.CodigoSolicitacao}</td>
                                            {/* <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.ConvenioElemento.Elemento.Nome}</td> */}
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.Convenio.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{moment(item.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "right" }}>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td align="center">

                                                <button className={Styles.btn_solic} title="Editar Solicitação">
                                                    <Link to={`/EditSolic/${item.CodigoSolicitacao}`}><FiEdit /></Link>
                                                </button>

                                            </td>
                                            {/* <td style={{ padding: '5px', fontSize: '14px', textAlign: "center" }}>status</td> */}
                                        </tr>
                                    ))
                                    ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não Há Solicitações Novas</td></tr>))}
                        </tbody>
                    </Table>

                </div>
            }



            {wPerfil === '17' &&
                <div className={Styles.table_container}>

                    <h3><span><PiClockCountdownDuotone /></span>Novos Credenciamentos</h3>
                    <Table hover>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFF', color: '#154360', textAlign: "left", width: '50%' }}>Nome</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFF', color: '#154360', textAlign: "left", width: '20%' }}>Remetente</th>
                                {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFF', color: '#154360', textAlign: "Center" }}>Histórico Encaminhamento</th> */}
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFF', color: '#154360', textAlign: "center", width: '20%' }}>Prazo para Atendimento</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFF', color: '#154360', textAlign: "center", width: '10%' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {credenciamentosNovos !== null ? (
                                credenciamentosNovos.map((item) => {
                                    const dataEmissao = moment(item.DataEmissao);
                                    const dataMais15Dias = dataEmissao.add(15, 'days');
                                    const prazoAtendimento = moment(dataMais15Dias).format('DD/MM/yyyy');

                                    return (
                                        <tr key={item.CodigoCadastro}>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF' }}>{item.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'left' }}>
                                                <span style={{ color: '#F1C40F', fontSize: '12px', position: 'relative', paddingBottom: '10px' }}>
                                                    <GrStatusGoodSmall />
                                                </span>{' '}
                                                Credenciamento
                                            </td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'center' }}>{prazoAtendimento}</td>
                                            <td align="center">
                                                <button className={`${Styles.tooltip} ${Styles.btn_solic}`} title="Ver Processo">
                                                    <Link to={`/VerCadastro/${item.CodigoCadastro}`}>
                                                        <VscOpenPreview />
                                                    </Link>
                                                </button>

                                                {wPerfil === '17' && (
                                                    <button className={Styles.btn_solic} title="Editar Processo">
                                                        <Link to={`/EditCadastro/${item.CodigoCadastro}`}>
                                                            <FiEdit />
                                                        </Link>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="4" style={{ padding: '5px', fontSize: '14px', color: "#707070" }}>Não há processos de credenciamento pendentes no momento</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                </div>
            }

            {wPerfil !== '37' && wPerfil !== '1' && (

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '80%', margin: '0 auto' }}>
                    <div className={Styles.table_container} style={{ width: '50%', marginRight: '1em' }}>

                        <h3><span><PiHandshakeDuotone /></span>Credenciamentos Pendentes</h3>

                        <Table hover style={{ marginTop: '1em', marginBottom: '2em' }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FAE5D3', color: '#154360', textAlign: "left", width: '50%' }}>Nome</th>
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FAE5D3', color: '#154360', textAlign: "left", width: '15%' }}>Remetente</th>
                                    {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FAE5D3', color: '#154360', textAlign: "Center" }}>Histórico Encaminhamento</th> */}
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FAE5D3', color: '#154360', textAlign: "center", width: '20%' }}>Prazo para Atendimento</th>
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FAE5D3', color: '#154360', textAlign: "center", width: '10%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {credenciamentosData.length > 0 ? (
                                    credenciamentosData.map((item) => (
                                        <tr key={item.CodigoCadastro}>
                                            <td style={{ padding: '5px', fontSize: '12px', backgroundColor: '#FFFFFF' }}>{item.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '12px', backgroundColor: '#FFFFFF', textAlign: 'left' }}>
                                                {/* <span style={{ color: getIconColor(item.PerfilDestinoAtual), fontSize: '12px', position: 'relative', paddingBottom: '10px' }}>
                                                    <GrStatusGoodSmall />
                                                </span>{' '} */}
                                                {item.status}
                                            </td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'center' }}>{item.prazoAtendimento}</td>
                                            <td align="center">
                                                <button className={`${Styles.tooltip} ${Styles.btn_solic}`} title="Ver Processo">
                                                    <Link to={`/VerCadastro/${item.CodigoCadastro}`}>
                                                        <VscOpenPreview />
                                                    </Link>
                                                </button>

                                                {wPerfil === '17' && (
                                                    <button className={Styles.btn_solic} title="Editar Processo">
                                                        <Link to={`/EditCadastro/${item.CodigoCadastro}`}>
                                                            <FiEdit />
                                                        </Link>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" style={{ padding: '5px', fontSize: '14px', color: "#707070" }}>Não há processos de credenciamento pendentes no momento</td>
                                    </tr>
                                )}
                                {/* {credenciamentosEncaminhadas !== null ? (
                                    Promise.all(
                                        credenciamentosEncaminhadas.map(async (item) => {
                                            const dataEmissao = moment(item.DataEmissao);
                                            const dataMais15Dias = dataEmissao.add(15, 'days');
                                            const prazoAtendimento = moment(dataMais15Dias).format('DD/MM/yyyy');

                                            // const usuarioNome = PegaRemetente(item.PerfilDestinoAtual);
                                            const usuarioNome = await PegaRemetente(item.PerfilDestinoAtual);

                                            return (
                                                <tr key={item.CodigoCadastro}>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF' }}>{item.Nome}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'left' }}>
                                                        <span style={{ color: getIconColor(item.PerfilDestinoAtual), fontSize: '12px', position: 'relative', paddingBottom: '10px' }}>
                                                            <GrStatusGoodSmall />
                                                        </span>{' '}
                                                        {remetente}
                                                    </td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'center' }}>{prazoAtendimento}</td>
                                                    <td align="center">
                                                        <button className={`${Styles.tooltip} ${Styles.btn_solic}`} title="Ver Processo">
                                                            <Link to={`/VerCadastro/${item.CodigoCadastro}`}>
                                                                <VscOpenPreview />
                                                            </Link>
                                                        </button>

                                                        {wPerfil === '17' && (
                                                            <button className={Styles.btn_solic} title="Editar Processo">
                                                                <Link to={`/EditCadastro/${item.CodigoCadastro}`}>
                                                                    <FiEdit />
                                                                </Link>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )
                                ) : (
                                    <tr>
                                        <td colSpan="4" style={{ padding: '5px', fontSize: '14px', color: "#707070" }}>Não há processos de credenciamento pendentes no momento</td>
                                    </tr>
                                )} */}
                            </tbody>
                        </Table>

                    </div>


                    <div className={Styles.table_container} style={{ width: '50%', marginLeft: '1em' }}>

                        <h3><span><PiHandshakeDuotone /></span>Solicitações de Projetos Pendentes</h3>

                        <Table hover style={{ marginTop: '1em', marginBottom: '2em' }}>
                            <thead>
                                <tr>
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#EBF5FB', color: '#154360', textAlign: "left", width: '35%' }}>Solicitações de Projetos</th>
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#EBF5FB', color: '#154360', textAlign: "left", width: '25%' }}>Remetente</th>
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#EBF5FB', color: '#154360', textAlign: "center", width: '20%' }}>Data Encaminhamento</th>
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#EBF5FB', color: '#154360', textAlign: "center", width: '20%' }}>Prazo de Atendimento</th>
                                    {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "center" }}>Status</th> */}
                                    <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#EBF5FB', color: '#154360', textAlign: "center", width: '10%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {solicitacoesEncaminhadas !== null ? (
                                    solicitacoesEncaminhadas.map((itemSolic) => (
                                        <tr key={itemSolic.CodigoSolicitacao}>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF' }}>
                                                {itemSolic.CodigoSolicitacao} - {itemSolic.Convenio.Nome}
                                            </td>
                                            <td style={{ padding: '5px', fontSize: '12px', backgroundColor: '#FFFFFF', textAlign: 'left' }}>
                                                {/* <span style={{ color: getIconColor(RemetenteProcesso = ListaStatusSolic2(itemSolic.Status)), fontSize: '12px', position: 'relative' }}>
                                                    <GrStatusGoodSmall />
                                                </span>{' '} */}
                                                {RemetenteProcesso = ListaStatusSolic2(itemSolic.Status)}
                                                {/* {itemSolic.CodigoSolicitacao} */}
                                            </td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'center' }}>
                                                {DataEncaminhamentoStatusSolic = ListaDataEncaminhamento(itemSolic.Status)}
                                            </td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'center', fontWeight: '600' }}>
                                                {(() => {
                                                    const { diasFaltando, atrasado } = calcularPrazo(DataEncaminhamentoStatusSolic);
                                                    return (
                                                        <>
                                                            {diasFaltando > 0 ? <p>{diasFaltando} dias restantes</p> : atrasado ? <p style={{ color: 'red' }}>Atrasado</p> : <p style={{ color: 'orange' }}>Hoje é o último dia!</p>}
                                                            {/* {atrasado && <span style={{ color: 'red' }}> (Atrasado)</span>} */}
                                                        </>
                                                    );
                                                })()}
                                            </td>
                                            <td align="center">
                                                <button className={`${Styles.tooltip} ${Styles.btn_solic}`} title="ver Solicitação">
                                                    <Link to={`/VerSolicitacaoNew/${itemSolic.CodigoSolicitacao}`}>
                                                        <VscOpenPreview />
                                                    </Link>
                                                    {/* <span className={Styles.tooltip}>ver Solicitação</span> */}
                                                </button>
                                                {wPerfil === '17' || wPerfil === '38' ? (
                                                    <button className={Styles.btn_solic} title="Editar Solicitação">
                                                        <Link to={`/EditSolic/${itemSolic.CodigoSolicitacao}`} style={{ marginBottom: '10px' }}>
                                                            <FiEdit />
                                                        </Link>
                                                    </button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" style={{ padding: '5px', fontSize: '14px', color: "#707070" }}>Não há solicitações pendentes no momento</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
            <br></br>

            {wPerfil === '38' &&
                <div className={Styles.table_container}>

                    <h3><span><PiClockCountdownDuotone /></span>Solicitações liberadas para Pagamento</h3>
                    <Table hover>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>N° Solicitação</th>
                                {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Elemento</th> */}
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Projeto</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Data</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "right" }}>Valor</th>
                                {/* <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "center" }}>Status</th> */}
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#ffffff', color: '#154360', textAlign: "right" }}></th>

                            </tr>
                        </thead>
                        <tbody>
                            {solicitacoesAPagar &&
                                (solicitacoesAPagar.length > 0 ?
                                    (solicitacoesAPagar.map((item) => (
                                        <tr key={item.CodigoSolicitacao}>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.CodigoSolicitacao}</td>
                                            {/* <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.ConvenioElemento.Elemento.Nome}</td> */}
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.Convenio.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{moment(item.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "right" }}>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td align="center">
                                                <button className={Styles.btn_solic} title="Ver Solicitação">
                                                    <Link to={`/VerSolicitacaoNew/${item.CodigoSolicitacao}`}><VscOpenPreview /></Link>
                                                </button>
                                                <button className={Styles.btn_solic} title="Lançar Pagamento">
                                                    <Link to={`/PagamentoSolicForm/${item.CodigoSolicitacao}`}><BsFileEarmarkCheck /></Link>
                                                </button>
                                            </td>

                                            {/* <td style={{ padding: '5px', fontSize: '14px', textAlign: "center" }}>status</td> */}
                                        </tr>
                                    ))
                                    ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não Há Solicitações a Pagar</td></tr>))}

                        </tbody>
                    </Table>
                    {/*  <h3><span><PiClockCountdownDuotone /></span>Solicitações liberadas para Pagamento</h3>
                    <Table hover>
                        <Form>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Form.Label style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Leitura de Arquivo OFX</Form.Label>
                                        <Form.input type="file" accept=".ofx" onChange={handleFileRead} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {resultOFX && (
                                            <div>
                                                <h2>Dados OFX</h2>
                                                <pre>{JSON.stringify(resultOFX, null, 2)}</pre>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </Table> */}


                </div>
            }


            {wPerfil !== '37' && wPerfil !== '1' &&

                <div className={Styles.table_container}>

                    <h3><span><PiClockCountdownDuotone /></span>Processos Administrativos Internos</h3>
                    <Table hover>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FEF9E7', color: '#154360', textAlign: "left" }}>N° Processo</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FEF9E7', color: '#154360', textAlign: "left" }}>Título</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FEF9E7', color: '#154360', textAlign: "center" }}>Data de Encaminhamento</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FEF9E7', color: '#154360', textAlign: "center" }}>Prazo de Atendimento</th>
                                <th style={{ padding: '.3em', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#FEF9E7', color: '#154360', textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {processosInternos &&
                                (processosInternos.length > 0 ?
                                    (processosInternos.map((processo) => (
                                        <tr key={processo.CodigoProcessoInterno}>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{processo.CodigoProcessoInterno}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{processo.Titulo}</td>

                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'center' }}>
                                                {DataEncaminhamentoStatusProcessoInterno = ListaDataEncaminhamentoProcesso(processo.StatusProcessoInterno)}
                                            </td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '#FFFFFF', textAlign: 'center', fontWeight: '600' }}>
                                                {(() => {
                                                    const { diasFaltando, atrasado } = calcularPrazoProcesso(DataEncaminhamentoStatusProcessoInterno);
                                                    return (
                                                        <>
                                                            {diasFaltando > 0 ? <p>{diasFaltando} dias restantes</p> : atrasado ? <p style={{ color: 'red' }}>Atrasado</p> : <p style={{ color: 'orange' }}>Hoje é o último dia!</p>}
                                                            {/* {atrasado && <span style={{ color: 'red' }}> (Atrasado)</span>} */}
                                                        </>
                                                    );
                                                })()}
                                            </td>
                                            <td align="center">
                                                <button className={Styles.btn_solic} title="Ver Processo">
                                                    <Link to={`/VerProcessoNew/${processo.CodigoProcessoInterno}`}><VscOpenPreview /></Link>
                                                </button>

                                            </td>
                                        </tr>
                                    ))
                                    ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não Há Processos Internos Pendentes</td></tr>))}
                        </tbody>
                    </Table>
                    <br></br>
                </div>



            }


            <br></br>

            {wPerfil === '37' &&
                <>
                    {/* <div className={Styles.table_container}>
                        <h3>Processos Financeiros</h3>

                    </div> */}
                    <div className={Styles.table_container}>

                        <h3>Solicitações do Projeto</h3>
                        <Table hover>
                            <thead className={Styles.table_header}>
                                <tr>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>N° Solicitação</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Data</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Elemento</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Credor</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "right" }}>Valor</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "center" }}>Status</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#ffffff', color: '#154360', textAlign: "center" }}></th>
                                </tr>
                            </thead>
                            <tbody>

                                {solicitacoesArea &&
                                    (solicitacoesArea.length > 0 ?
                                        (solicitacoesArea
                                            .sort((a, b) => new Date(b.DataSolicitacao) - new Date(a.DataSolicitacao))
                                            .map((item) => (

                                                <tr key={item.CodigoSolicitacao}>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.CodigoSolicitacao}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{moment(item.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.ConvenioElemento.Elemento.Nome}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.Cadastro.Nome}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "right" }}>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "center" }}>
                                                        {
                                                            // aqui mostra o nome do status Remetente e Destino
                                                            // pega a lista dos status da solicitacao, coloca em ordem data DESC e pega o primeiro resultado que é o ultimo status
                                                            RemetenteProcesso = ListaStatusSolic(item.Status)
                                                        }
                                                    </td>
                                                    <td align="center">
                                                        <button className={`${Styles.tooltip} ${Styles.btn_solic}`} title="ver Solicitação">
                                                            <Link to={`/VerSolicitacaoNew/${item.CodigoSolicitacao}`}><VscOpenPreview /></Link>
                                                        </button>
                                                        <button className={`${Styles.tooltip} ${Styles.btn_solic}`} title="Enviar Prestação de Contas">
                                                            <Link to={`/PcAdiantamento/${item.CodigoSolicitacao}`}>
                                                                <img src={pc} alt="Enviar Prestação de Contas" width="25px" />
                                                            </Link>
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))
                                        ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não Há Processos Pendentes</td></tr>))}
                            </tbody>
                        </Table>
                        <Pagination>{items}</Pagination>
                    </div>

                    <div className={Styles.table_container}>

                        <h3>Solicitações Encaminhadas a Você</h3>
                        <Table hover>
                            <thead className={Styles.table_header}>
                                <tr>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>N° Solicitação</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Data</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Elemento</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Credor</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "right" }}>Valor</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "center" }}>Status</th>
                                    <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#ffffff', color: '#154360', textAlign: "center" }}></th>
                                </tr>
                            </thead>
                            <tbody>

                                {solicitacoesAreaEncaminhadas &&
                                    (solicitacoesAreaEncaminhadas.length > 0 ?
                                        (solicitacoesAreaEncaminhadas
                                            .map((item) => (

                                                <tr key={item.CodigoSolicitacao}>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.CodigoSolicitacao}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{moment(item.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.ConvenioElemento.Elemento.Nome}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.Cadastro.Nome}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "right" }}>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "center" }}>
                                                        {
                                                            // aqui mostra o nome do status Remetente e Destino
                                                            // pega a lista dos status da solicitacao, coloca em ordem data DESC e pega o primeiro resultado que é o ultimo status
                                                            RemetenteProcesso = ListaStatusSolic(item.Status)
                                                        }
                                                    </td>
                                                    <td align="center">
                                                        <button className={`${Styles.tooltip} ${Styles.btn_solic}`} title="ver Solicitação">
                                                            <Link to={`/VerSolicitacaoNew/${item.CodigoSolicitacao}`}><VscOpenPreview /></Link>
                                                        </button>
                                                        <button className={`${Styles.tooltip} ${Styles.btn_solic}`} title="Enviar Prestação de Contas">
                                                            <Link to={`/PcAdiantamento/${item.CodigoSolicitacao}`}>
                                                                <img src={pc} alt="Enviar Prestação de Contas" width="25px" />
                                                            </Link>
                                                        </button>
                                                    </td>

                                                </tr>
                                            ))
                                        ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não Há Solicitações Encaminhadas a você neste momento</td></tr>))}
                            </tbody>
                        </Table>
                    </div>
                </>
            }

            {(wPerfil === '28' || wPerfil === '24') && wPerfil !== '1' && (
                <div className={Styles.table_container}>

                    <h3><span><PiClockCountdownDuotone /></span>Relatórios Gerenciais</h3>
                    <div className={Styles.relatorio_buttons}>

                        <button className={Styles.relatorio}>
                            <Link to={"/FluxoDoa/"} target="_blank">
                                Fluxo Projetos DOA
                            </Link>
                        </button>

                    </div>
                </div>
            )}


            <Modal show={loading} className={Styles.Modal} centered >
                <Modal.Body className={Styles.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>
            {login && (<Navigate to="/" replace={true} />)}

        </div>
    )
};

export default Index