import React, { useEffect, useState } from 'react';
//import ReactDataGrid from 'react-data-grid';
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-grids';
import dados from './Relatorios.json';
import './Relatorios.css';
//import ReportBuilder from 'react-report-builder';
//import { margin } from '@mui/system';

function TesteRelatorio() {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Carregar os dados do JSON local quando o componente montar
    setData(dados);
  }, []);

  // const reportFields = [
  //   // Defina os campos do relatório com os mesmos nomes dos campos do seu JSON
  //   { name: 'CodigoBanco', displayName: 'Código Banco' },
  //   { name: 'DataBanco', displayName: 'Data Banco' },
  //   { name: 'CodigoConvenio', displayName: 'Código Convênio' },
  //   { name: 'CodigoCadastro', displayName: 'Código Cadastro' },
  //   { name: 'Valor', displayName: 'Valor' },
  //   { name: 'Obs', displayName: 'Observação' },
  // ];

  // const columns = [
  //   // Defina aqui as colunas que você deseja exibir no relatório
  //   // Por exemplo:
  //   { key: 'CodigoConvenio', name: 'Convenio' },
  //   { key: 'Valor', name: 'Valor' },
  //   { key: 'Obs', name: 'Obs' },
  //   // ... outras colunas ...
  // ];

  return (
    <div>
      <h1>Meu Relatório de Dados</h1>
      <h6>Meu Cabeçalho</h6>
      <div style={{width:'80%', margin: '0 auto', marginTop: '5%'}}>
      {/* Renderizar o relatório aqui */}
      <GridComponent  dataSource={data} allowPaging={true}>
      <ColumnsDirective>
          <ColumnDirective field="CodigoBanco" headerText="Código Banco" width='100'/>
          <ColumnDirective field="DataBanco" headerText="Data Banco" width='50'/>
          <ColumnDirective field="CodigoConvenio" headerText="Código Convênio" width='50' />
          <ColumnDirective field="CodigoCadastro" headerText="Código Cadastro" width='50' />
          <ColumnDirective field="Valor" headerText="Valor" width='50'/>
          <ColumnDirective field="Obs" headerText="Observação" width='50'/>
        </ColumnsDirective>
      </GridComponent>
    </div>
    </div>
  );
}

export default TesteRelatorio;