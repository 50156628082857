import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Menu from "../Layout/Menu";
import StyleForm from "./CadastroGeral.module.css"
import Style from "./ListCadastro.module.css"
import { IoMdArrowBack } from "react-icons/io"
import { AiOutlineSearch, AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from 'react';
import axios from "axios";
import { Navigate } from "react-router";
//import { useNavigate } from "react-router-dom";
import urlAPIlocal from '../../global';

//import {AiOutlinePlusCircle, AiOutlineSearch} from 'react-icons/ai'

function CadastroGeral() {

    const [cadastro, setCadastro] = useState([]);
    const [busca, setBusca] = useState('');
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [login, setLogin] = useState(false);
    const [redirNovoCadastro, setRedirNovoCadastro] = useState('');
    const [redirNew, setRedirNew] = useState(false);
    //const [nome, setNome] = useState('');

    // const navigate = useNavigate();
    // function handleClick() {
    //     navigate(-1);
    // }

    var wTokenLocal = localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    // function handleChange(event) {
    //     setNome(event.target.value);
    // }

    const getCadastro = async (event) => {


        /* const responsetoken = await axios.post(urlAPIlocal + '/api/Login', {
            login: "ronaldo",
            senha: "rachel@78"
        }); */
        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Cadastro?Aprovado=1&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setCadastro(data.objetoRetorno);
                //console.log(response);
            }
            )
            .catch(function (error) {
                console.log(error);
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getCadastro();
        // eslint-disable-next-line
    }, []);

    const handleSearch = (event) => {
        const value = event.target.value;
        //getCadastro();
        const filteredItems = cadastro.filter((item) =>
            item.Nome.toLowerCase().includes(value.toLowerCase())
        );
        setItensFiltrados(filteredItems);
        setBusca(value);
        //setCadastro(filteredItems);
    };

    const handleNovoCadastro = (event) => {
        setRedirNew(true);

    }


    return (
        <div className={StyleForm.page}>
            <Menu />
            <div> <button className={StyleForm.btn_voltar}> <Link to="/index" ><IoMdArrowBack /> Voltar</Link></button> </div>

            <div className={StyleForm.form_container}>
                <header>Cadastro de Credores</header>
                <Form onSubmit={handleNovoCadastro}>


                    <FormGroup className={StyleForm.FormGroup}>

                        <Form.Label className={StyleForm.label}>Nome</Form.Label>
                        <Form.Control type="text" name="nome" value={busca} onChange={handleSearch} />


                        {cadastro &&
                            (busca.length > 0 ?
                                (
                                    (itensFiltrados.length > 0 ?
                                        (

                                            itensFiltrados.map((cadastrado) => (
                                                <tr key={cadastrado.CodigoCadastro}>
                                                    <td >{cadastrado.Nome}</td>
                                                    <td className={StyleForm.espaco}>----------------</td>
                                                    <td>{cadastrado.NumeroCnpj}</td>
                                                    {/* <td align="center" >{cadastrado.aprovado ? <p className={Style.aprovado}>Aprovado</p> : <p className={Style.nao_aprovado}>Não Aprovado</p>}</td> */}
                                                    <td className={StyleForm.espaco}>--------------------------------------------</td>
                                                    <td>
                                                        <button className={Style.btn_look}>
                                                            <Link to={`/VerCadastro/${cadastrado.CodigoCadastro}`}>
                                                                <AiOutlineSearch title="Abrir" />
                                                            </Link>
                                                        </button>
                                                        <button className={Style.btn_edit}>
                                                            <Link to={`/editCadastro/${cadastrado.CodigoCadastro}`}>
                                                                <AiOutlineEdit title="Editar" />
                                                            </Link>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (<p>Nenhum cadastro encontrado</p>))


                                ) :
                                (
                                    <p>Digite o nome do cadastro para busca-lo</p>
                                )
                            )
                        }



                        <Row>
                            <Col md={10}>
                                <Form.Label className={StyleForm.label}>Tipo de Cadastro</Form.Label>
                                <Form.Select value={redirNovoCadastro} onChange={(event) => setRedirNovoCadastro(event.target.value)}>
                                    <option>Selecione o tipo de cadastro</option>
                                    <option value="/cadPF/43">Pessoa Física - Credor</option>
                                    <option value="/cadPF/90">Pessoa Física - Usuário</option>
                                    <option value="/cadPJ/33">Pessoa Jurídica</option>
                                    <option value="/cadPJ/32">Concedente</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <button type="submit" className={StyleForm.btn_novo} >Novo Cadastro</button>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>

                {login && (<Navigate to="/" replace={true} />)}
                {redirNew && (<Navigate to={redirNovoCadastro} replace={true} />)}
            </div>
        </div>
    )
}
export default CadastroGeral