import React from "react"
import Form from 'react-bootstrap/Form'
import StyleForm from './/Fases.module.css'
import { AiFillPlusCircle, AiOutlineEdit } from 'react-icons/ai'
import { TiDelete } from "react-icons/ti";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Menu from "../Layout/Menu";
import { Col, FormGroup, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import done from "../img/done.svg";
import { ImSad2 } from 'react-icons/im';
import loadingGif from '../img/icons8-spinning-circle.gif';
import { da } from "date-fns/locale";

function Fases() {
    const { wCodigoConvenioNovo } = useParams();
    const [login, setLogin] = useState(false);
    //const [meta, setMeta] = useState([]);
    const [FaseEscolhida, setFaseEscolhida] = useState('');
    const [nome, setNome] = useState('');
    const [valorTotal, setValorTotal] = useState(0);
    const [saldoLimite, setSaldoLimite] = useState(0);
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [objetivo, setObjetivo] = useState('');
    const [FasesGravadas, setFasesGravadas] = useState([]);
    const [MetasGravadas, setMetasGravadas] = useState([]);
    const [MetaEscolhida, setMetaEscolhida] = useState([]);
    const [projetoatual, setProjetoAtual] = useState([]);

    const [excluirFase, setExcluirFase] = useState([]);

    const [loading, setLoading] = useState(true);
    const [CodigoConvenioFase, setCodigoConvenioFase] = useState(0);
    const [editado, setEditado] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);

    var wTokenLocal = localStorage.getItem("token");

    // const navigate = useNavigate();

    // function handleClick() {
    //     navigate(-1");
    // }

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function handleClose() {
        setTimeout(() => {
            setShowModal(false);

        }, 2000);

    }

    function handleCloseEdit() {
        setTimeout(() => {
            setShowModalEdit(false);
            //setCadastroOk(true);

        }, 1000);

    }

    function handleCloseErro() {

        setShowModalErro(false);

    };

    const getMetasGravadas = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Meta?CodigoConvenio=" + wCodigoConvenioNovo,
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setMetasGravadas(data);
            //console.log(data)

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getMetasGravadas();
        // eslint-disable-next-line
    }, []);

    const getProjeto = async (event) => {
        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + wCodigoConvenioNovo,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjetoAtual(response.data.objetoRetorno);

                var wDataInicio = new Date(response.data.objetoRetorno.DataInicio);
                wDataInicio = moment(wDataInicio).format("yyyy-MM-DD"); //wDataInicio.toLocaleDateString('pt-BR')
                setDataInicio(wDataInicio);
                var wDataExpiracao = new Date(response.data.objetoRetorno.DataExpiracao);
                wDataExpiracao = moment(wDataExpiracao).format("yyyy-MM-DD");
                setDataFim(wDataExpiracao);

            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        var wtokenBearer = "Bearer " + localStorage.getItem("token")

        // var vTotal = valorTotal;
        // vTotal = vTotal.replace(',', '.');

        var data = JSON.stringify({
            "CodigoConvenioFase": 0,
            "NumeroFase": FaseEscolhida,
            "nome": nome,
            "ValorTotal": 0,
            "DataInicio": dataInicio,
            "DataFim": dataFim,
            "CodigoConvenio": wCodigoConvenioNovo,
            "CodigoMeta": MetaEscolhida,
            "objetivo": objetivo,
            "saldoLimite": 0,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Fase',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro
                    getFasesGravadas();
                    setLoading(false);
                    setShowModal(true);
                    //limpa campos
                    setFaseEscolhida('');
                    setMetaEscolhida('');
                    setNome('');
                    setDataFim('');
                    setDataInicio('');
                    setObjetivo('');
                    setSaldoLimite('');
                    setEditado(false);

                    //verifica o processo do projeto, se for 4 marcar processo = 5
                    //console.log(projetoatual.Processo);
                    const wProcessoAtual = projetoatual.Processo
                    if (wProcessoAtual === 4) {
                        //gravar o processo
                        const wtoken = "Bearer " + localStorage.getItem("token");
                        var Retorno = false;
                        var config = {
                            method: 'put',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/Convenio/MudarProcesso/' + wCodigoConvenioNovo + '/5',
                            headers: {
                                'Authorization': wtoken,
                                'Content-Type': 'application/json'
                            },
                        };
                        axios(config)
                            .then(function (response) {
                                //console.log(response.data)
                                if (response.data.isOk === true) {
                                    Retorno = true;
                                } else {
                                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                                    Retorno = false
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    };

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }

    const getFasesGravadas = async () => {

        try {
            setLoading(true);
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Fase?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setFasesGravadas(data);
            setLoading(false);
            //console.log(data);
            //muda a ordem dos itens no array antes de mostrar na tela
            // const FaseValorGravadas = data.sort((a, b) => { 
            //     if (a.Meta.NumMeta === b.Meta.NumMeta) { 
            //     return a.NumeroFase.localeCompare(b.NumeroFase); 
            //         } 
            //     return a.Meta.NumMeta - b.Meta.NumMeta; });

            if (data !== null) {
                const FaseValorGravadas = data.sort((a, b) => {
                    if (a.Meta.NumMeta === b.Meta.NumMeta) {
                        return a.NumeroFase - b.NumeroFase;
                    }
                    return a.Meta.NumMeta - b.Meta.NumMeta;
                });

                setFasesGravadas(FaseValorGravadas);
            }

            // Consulta para cada CodigoConvenioFase
            const consultaFaseValor = async () => {
                try {
                    const fasesComCodigoRetornoZero = [];

                    for (const fase of data) {
                        const configFaseValor = {
                            method: "GET",
                            url:
                                urlAPIlocal +
                                "/api/FaseValor?CodigoConvenioFase=" +
                                fase.CodigoConvenioFase +
                                "&IsInclude=true",
                            headers: {
                                Authorization: wtoken,
                                "Content-Type": "application/json",
                            },
                        };

                        const responseFaseValor = await axios(configFaseValor);
                        const dataFaseValor = responseFaseValor.data;

                        //console.log(dataFaseValor);

                        if (dataFaseValor.codigoRetorno === '0') {
                            fasesComCodigoRetornoZero.push(fase.CodigoConvenioFase);
                        }
                    }

                    // Agora fasesComCodigoRetornoZero contém os CodigoConvenioFase com código de retorno zero
                    // Defina o estado para forçar a atualização da renderização
                    setExcluirFase(fasesComCodigoRetornoZero);
                } catch (error) {
                    console.log(error);
                }
            };

            consultaFaseValor(); // Chama a função de consulta
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectMeta = (event) => {
        setMetaEscolhida(event.target.value);
    };


    useEffect(() => {
        getFasesGravadas();
        // eslint-disable-next-line
    }, []);

    const handleEditForm = async (wCodigoConvenioFase) => {
        //console.log(wCodigoConvenioFase);
        try {
            setLoading(true);
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Fase?CodigoConvenioFase=" + wCodigoConvenioFase + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data);
            //console.log(data[0].Meta.NumMeta)

            setMetaEscolhida(data[0].Meta.CodigoMeta);
            setFaseEscolhida(data[0].NumeroFase);
            setNome(data[0].Nome);

            const DataInicioFormatada = moment(data[0].DataInicio).format("YYYY-MM-DD");
            setDataInicio(DataInicioFormatada);

            const DataFimFormatada = moment(data[0].DataFim).format("YYYY-MM-DD");
            setDataFim(DataFimFormatada);

            setObjetivo(data[0].Objetivo);
            setCodigoConvenioFase(wCodigoConvenioFase);
            setLoading(false);
            setEditado(true);


        } catch (error) {
            console.log(error)
        }
    }

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        //console.log("entrou aqui")
        //console.log(metaEscolhida);
        var wtokenBearer = "Bearer " + localStorage.getItem("token")


        var data = JSON.stringify({
            "CodigoConvenioFase": CodigoConvenioFase,
            "NumeroFase": FaseEscolhida,
            "Nome": nome,
            "ValorTotal": 0,
            "DataInicio": dataInicio,
            "DataFim": dataFim,
            "CodigoConvenio": wCodigoConvenioNovo,
            "CodigoMeta": MetaEscolhida,
            "Objetivo": objetivo,
            "SaldoLimite": 0
        });

        var config = {
            method: 'PUT',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Fase',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    //console.log(response)

                    //setMetaFaseEscolhida('0');

                    setShowModalEdit(true);
                    getFasesGravadas();

                    //limpa campos
                    setFaseEscolhida('');
                    setMetaEscolhida('');
                    setNome('');
                    setDataFim('');
                    setDataInicio('');
                    setObjetivo('');
                    setSaldoLimite('0');
                    setEditado(false);


                } else {
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                //console.error(CodigoConvenioFase);

            });
    }


    const handleExcluirFase = (wFase) => {
        var configSelect = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Fase/' + wFase,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataSelect
        };
        //console.log(dataSelect)
        axios(configSelect)

            .then(function (response) {
                getFasesGravadas();
                //setCadastroOk(true);
                //console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });

    }



    return (
        <div className={StyleForm.page}>
            <Menu />

            {/* <div> <button className={StyleForm.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={StyleForm.voltar}>  <Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            <div className={StyleForm.header}>
                <h1>Cadastro de Fases</h1>
            </div>

            <div className={StyleForm.container}>

                <Form className={StyleForm.select} onSubmit={handleSubmit} >

                    <FormGroup className={StyleForm.form_group}>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.select_lable}>Meta*</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="elementos" id="elementos" value={MetaEscolhida} onChange={handleSelectMeta} disabled={editado ? true : false} required>
                                    <option>Selecione a meta para cadastrar as fases</option>
                                    {MetasGravadas.length > 0 &&
                                        MetasGravadas.map((opcao) => (
                                            <option key={opcao.CodigoMeta} value={opcao.CodigoMeta}>{opcao.NumMeta} - {opcao.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>

                            <Col>
                                <Form.Label className={StyleForm.form_group_label} >Fase*</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="elementos" id="elementos" value={FaseEscolhida} onChange={(event) => setFaseEscolhida(event.target.value)} disabled={editado ? true : false} required>
                                    <option value="">Selecione a fase</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="21">21</option>
                                    <option value="22">22</option>
                                    <option value="23">23</option>
                                    <option value="24">24</option>
                                    <option value="25">25</option>
                                    <option value="26">26</option>
                                    <option value="27">27</option>
                                    <option value="28">28</option>
                                    <option value="29">29</option>
                                    <option value="30">30</option>
                                    <option value="31">31</option>
                                    <option value="32">32</option>
                                    <option value="33">33</option>
                                    <option value="34">34</option>
                                    <option value="35">35</option>
                                    <option value="36">36</option>
                                    <option value="37">37</option>
                                    <option value="38">38</option>
                                    <option value="39">39</option>
                                    <option value="40">40</option>


                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label} >Nome*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="nome" value={nome} onChange={(event) => setNome(event.target.value)} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data de Início*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" value={dataInicio} onChange={(event) => setDataInicio(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data do Fim*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" value={dataFim} onChange={(event) => setDataFim(event.target.value)} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Objetivo</Form.Label>
                                <Form.Control className={StyleForm.form_control} name="detalhes" id="datalhes" placeholder="Descreva o objetivo" value={objetivo} onChange={(event) => setObjetivo(event.target.value)} required></Form.Control>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Saldo Limite</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="saldo" value={saldoLimite} onChange={(event) => setSaldoLimite(event.target.value)} disabled />
                            </Col>
                        </Row> */}

                        <Row>
                            {!editado && (
                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleSubmit}>
                                    Inserir Fase <AiFillPlusCircle />
                                </button>
                            )}

                            {/* Renderiza o botão "Editar Valores" se estiver editando */}
                            {editado && (
                                <button className={StyleForm.inserir_btn} type="submit" onClick={handleEditSubmit}>
                                    Editar Fase <AiFillPlusCircle />
                                </button>
                            )}
                        </Row>
                    </FormGroup>
                </Form>

                <div>
                    <Table hover className={StyleForm.table}>
                        <thead>
                            <tr>
                                <th>Meta</th>
                                <th>Fase</th>
                                <th>Nome</th>
                                <th className={StyleForm.th_btn}></th>
                                <th className={StyleForm.th_btn}></th>
                            </tr>
                        </thead>
                        <tbody>

                            {FasesGravadas &&

                                (FasesGravadas.length > 0 ?
                                    (FasesGravadas.map((Fase) => (
                                        <tr key={Fase.CodigoFase}>
                                            <td>{Fase.Meta.NumMeta}</td>
                                            <td>{Fase.NumeroFase}</td>
                                            <td>{Fase.Nome}</td>
                                            <td><button type="button" className={StyleForm.btn_edit} onClick={(event) => handleEditForm(Fase.CodigoConvenioFase)}>
                                                <AiOutlineEdit title="Editar" /> </button></td>
                                            {/* <td>{Fase.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td> */}


                                            <td>
                                                {excluirFase.includes(Fase.CodigoConvenioFase) ? ( // Verifica se CodigoConvenioFase está na lista de fases com código de retorno zero
                                                    <button
                                                        type="button"
                                                        className={StyleForm.btn_delete}
                                                        onClick={() => handleExcluirFase(Fase.CodigoConvenioFase)}
                                                    >
                                                        <TiDelete />
                                                    </button>
                                                ) : null}
                                            </td>
                                        </tr>
                                    ))
                                    ) : (<tr><td>Nenhuma meta cadastrada</td></tr>))}

                        </tbody>
                    </Table>
                    <button className={StyleForm.edit}>
                        <Link to={`/FaseValor/${wCodigoConvenioNovo}`}>
                            Lançar Valores nas Fases <IoMdArrowForward />
                        </Link>
                    </button>
                </div>



            </div>

            <Modal show={loading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
                <Modal.Header className={StyleForm.Modal_header}>
                    <img src={done} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    Fase inserida com sucesso!
                </Modal.Body>
            </Modal>

            <Modal show={showModalEdit} onLoad={handleCloseEdit} className={StyleForm.Modal} centered >
                <Modal.Header className={StyleForm.Modal_header}>
                    <img src={done} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    Meta atualizada com sucesso!
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={StyleForm.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

            {login && (<Navigate to="/" replace={true} />)}

        </div>
    )
}
export default Fases