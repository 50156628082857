import Menu from "../Layout/Menu";
import Styles from './Index.module.css';
import {useState, useEffect} from "react";
import axios from "axios";
import urlAPIlocal from '../../global';
import Table from 'react-bootstrap/Table';
import moment from "moment";
import { Link } from "react-router-dom";
import { FiEdit } from "react-icons/fi";


function SolicFinanceiro () {

    var wTokenLocal = localStorage.getItem("token");
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [cadastros, setCadastros] = useState([]);

    const getSolicitacoesAprovadas = async () => {
        //setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao/?IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                //console.log(data.objetoRetorno);
                setSolicitacoes(data.objetoRetorno);

               
               //setLoading(false);
            }
            )
            .catch(function (error) {
                console.log(error);
              
            });
    }

    useEffect(() => {
        getSolicitacoesAprovadas();
        //eslint-disable-next-line
    }, []);

    const getCadastrosAprovadas = async () => {
        //setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Cadastro?Aprovado=1&IsInclude=false',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                //console.log(data.objetoRetorno);
                setCadastros(data.objetoRetorno);

               
               //setLoading(false);
            }
            )
            .catch(function (error) {
                console.log(error);
              
            });
    }

    useEffect(() => {
        getCadastrosAprovadas();
        //eslint-disable-next-line
    }, []);


    return (
        <div className={Styles.index}>
            <Menu/>
            <br></br>
            <br></br>
            <div className={Styles.table_container}>
            <h3>Solicitações de Compras</h3>
             <Table hover>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>N° Solicitação</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Projeto</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Data</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "right" }}>Valor</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#ffffff', color: '#154360', textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {solicitacoes &&
                                (solicitacoes.length > 0 ?
                                    (solicitacoes.map((item) => (
                                        <tr key={item.CodigoSolicitacao}>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.CodigoSolicitacao}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.Convenio.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{moment(item.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "right" }}>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td align="center">

                                                <button className={Styles.btn_solic} title="Editar Solicitação">
                                                    <Link to={`/EditSolic/${item.CodigoSolicitacao}`}><FiEdit /></Link>
                                                </button>

                                            </td>
                                            {/* <td style={{ padding: '5px', fontSize: '14px', textAlign: "center" }}>status</td> */}
                                        </tr>
                                    ))
                                    ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não Há Processos Pendentes</td></tr>))}
                        </tbody>
                    </Table>
        </div>

        {/* <br></br>
            <br></br>
            <div className={Styles.table_container}>
            <h3>Solicitações de Serviços</h3>
             <Table hover>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Nome</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Projeto</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Data</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "right" }}>Valor</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#ffffff', color: '#154360', textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cadastros &&
                                (cadastros.length > 0 ?
                                    (cadastros.map((item) => (
                                        <tr key={item.CodigoSolicitacao}>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.CodigoSolicitacao}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{item.Convenio.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4' }}>{moment(item.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', backgroundColor: '##F2F3F4', textAlign: "right" }}>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td align="center">

                                                <button className={Styles.btn_solic} title="Editar Solicitação">
                                                    <Link to={`/EditSolic/${item.CodigoSolicitacao}`}><FiEdit /></Link>
                                                </button>

                                            </td>
                                           
                                        </tr>
                                    ))
                                    ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não Há Processos Pendentes</td></tr>))}
                        </tbody>
                    </Table>
        </div> */}
        </div>
    )
}
export default SolicFinanceiro;