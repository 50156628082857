import StyleForm from './EditProjeto.module.css'
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { MdCreate } from 'react-icons/md'
import { IoMdArrowBack } from "react-icons/io"
import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router";
import { Link } from 'react-router-dom'
import moment from "moment";
import urlAPIlocal from '../../global';
import Menu from "../Layout/Menu"
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
import { ImSad2 } from 'react-icons/im';
import loadingGif from '../img/icons8-spinning-circle.gif';

function EditProjeto() {

  const { wCodigoConvenioNovo } = useParams();
  const [CodigoProjeto, setCodigoProjeto] = useState(0);

  const [login, setLogin] = useState(false);

  const [numeroProjeto, setNumeroProjeto] = useState('');
  const [nomeProjeto, setNomeProjeto] = useState('');
  const [cidade, setCidade] = useState('');

  const [isModalTipoProjetoOpen, setIsModalTipoProjetoOpen] = useState(false);
  const [novoTipoProjeto, setNovoTipoProjeto] = useState('');
  const [tipoProjeto, setTipoProjeto] = useState([]);
  const [tipoProjetoEscolhido, setTipoProjetoEscolhido] = useState('0');


  const [isModalInstrumentoOpen, setIsModalInstrumentoOpen] = useState(false);
  const [instrumentos, setInstrumentos] = useState([]);
  const [novoInstrumento, setNovoInstrumento] = useState('');
  const [tipoInstrumentoEscolhido, setTipoInstrumentoEscolhido] = useState('0');
  const [leiPC, setLeiPC] = useState('');
  const [periocidade, setPeriodicidade] = useState('');
  const [prazoFinal, setPrazoFinal] = useState('');
  const [DataCadastro, setDataCadastro] = useState('');
  const [DataTramitacao, setDataTramitacao] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataExpiracao, setDataExpiracao] = useState('');
  const [dataAssinatura, setDataAssinatura] = useState('');
  const [dataPubli, setDataPubli] = useState('');
  const [detalhes, setDetalhes] = useState('');
  const [objetivo, setObjetivo] = useState('');

  const [coordenador, setCoordenador] = useState([]);
  //const [acessoServidor, setAcessoServidor] = useState([]);
  // const [convenente, setConvenente] = useState([]);
  // const [ordenador, setOrdenador] = useState([]);
  // const [executor, setExecutor] = useState([]);
  // const [interveniente, setInterveniente] = useState([]);
  const [coordenadorEscolhido, setCoordenadorEscolhido] = useState(0);
  const [coordenadorAtual, setCoordenadorAtual] = useState('');
  //const [emailArea, setEmailArea] = useState('');
  // eslint-disable-next-line
  const [nomeCadastroArea, setNomeCadastroArea] = useState('');
  //const [acessoServidorEscolhido, setAcessoServidorEscolhido] = useState('');
  // const [convenenteEscolhido, setConvenenteEscolhido] = useState('');
  // const [ordenadorEscolhido, setOrdenadorEscolhido] = useState('');
  // const [executorEscolhido, setExecutorEscolhido] = useState('');
  // const [intervenienteEscolhido, setIntervenienteEscolhido] = useState('');

  const [nomeBanco, setNomeBanco] = useState('');
  const [numeroBanco, setNumeroBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [numeroConta, setNumeroConta] = useState('');
  const [nomeConta, setNomeConta] = useState('');

  const [convenioOk, setConvenioOk] = useState(false);
  const [projetoatual, setProjetoAtual] = useState([]);

  // eslint-disable-next-line
  const [error, setError] = useState(false);
  const selectTipoConvenio = useRef(null);
  const selectInstrumento = useRef(null);

  const [loading, setLoading] = useState(false);
  const [erroCadInstrumento, setErroCadInstrumento] = useState(false);
  const [erroCadTipoProjeto, setErroCadTipoProjeto] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalErro, setShowModalErro] = useState(false);

  function handleClose() {
    setTimeout(() => {
      setShowModal(false);
      setConvenioOk(true);

    }, 3000);

  }

  function handleCloseErro() {

    setShowModalErro(false);
    setConvenioOk(false);

  };

  var wTokenLocal = localStorage.getItem("token");
  function ConsultaToken() {
    //verifica se tem o token no localstorage
    if (wTokenLocal === undefined || wTokenLocal === null) {
      setLogin(true)
    }
  }

  useEffect(() => {
    ConsultaToken();
    // eslint-disable-next-line
  }, []);

  const getProjeto = async (event) => {

    const wtoken = "Bearer " + wTokenLocal;

    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/convenio/' + wCodigoConvenioNovo,
      headers: {
        'Authorization': wtoken,
        'Content-Type': 'application/json'
      },

    };

    axios(config)

      .then(function (response) {

        //console.log(response.data.objetoRetorno)
        setProjetoAtual(response.data.objetoRetorno);
        setCodigoProjeto(response.data.objetoRetorno.CodigoConvenio);

        setNumeroProjeto(response.data.objetoRetorno.NumeroConvenio);
        setNomeProjeto(response.data.objetoRetorno.Nome);
        setCidade(response.data.objetoRetorno.Origem);
        setTipoProjetoEscolhido(response.data.objetoRetorno.Tipoconvenio);
        setTipoInstrumentoEscolhido(response.data.objetoRetorno.Instrumento);
        setLeiPC(response.data.objetoRetorno.Lei);
        setPeriodicidade(response.data.objetoRetorno.Pc);
        setPrazoFinal(response.data.objetoRetorno.Prazofinal);
        setDataCadastro(response.data.objetoRetorno.DataCadastro);
        setDataTramitacao(response.data.objetoRetorno.DataTramitacao);
        setCoordenadorAtual(response.data.objetoRetorno.CodigoCoordenacaoPedagogia);


        var wDataInicio = new Date(response.data.objetoRetorno.DataInicio);
        wDataInicio = moment(wDataInicio).format("yyyy-MM-DD"); //wDataInicio.toLocaleDateString('pt-BR')
        //moment(response.data.objetoRetorno.DataInicio).format("yyyy-MM-dd");
        //console.log(wDataInicio);
        setDataInicio(wDataInicio);

        var wDataExpiracao = new Date(response.data.objetoRetorno.DataExpiracao);
        wDataExpiracao = moment(wDataExpiracao).format("yyyy-MM-DD");
        setDataExpiracao(wDataExpiracao);

        var wDataAssinatura = new Date(response.data.objetoRetorno.DataAssinatura);
        wDataAssinatura = moment(wDataAssinatura).format("yyyy-MM-DD");
        setDataAssinatura(wDataAssinatura);

        var wDataPubli = new Date(response.data.objetoRetorno.DataPublicacao);
        wDataPubli = moment(wDataPubli).format("yyyy-MM-DD");
        setDataPubli(wDataPubli);

        setDetalhes(response.data.objetoRetorno.Justificativa);
        setObjetivo(response.data.objetoRetorno.Objetivo);
        setCoordenadorEscolhido(response.data.objetoRetorno.CodigoCoordenacaoPedagogia);
        //setAcessoServidorEscolhido(response.data.objetoRetorno.CodigoTecnico);
        setNomeBanco(response.data.objetoRetorno.NomeBanco);
        setNomeConta(response.data.objetoRetorno.NomeConta);
        setAgencia(response.data.objetoRetorno.NumeroAgencia);
        setNumeroConta(response.data.objetoRetorno.NumeroConta);
        setNumeroBanco(response.data.objetoRetorno.NumeroBanco);

      }
      )
      .catch(function (error) {
        console.log(error);
        //console.log(JSON.stringify(response.data.mensagemRetorno));
        if (error.response.status === 401) {
          setLogin(true);
        }
      });
  }

  useEffect(() => {
    getProjeto();
    // eslint-disable-next-line
  }, []);

  const getCadastroPF = async () => {
    try {
      const wtoken = "Bearer " + wTokenLocal;

      const config = {
        method: "GET",
        url:
          urlAPIlocal + "/api/cadastro?CodigoTipoCadastro=90",
        headers: {
          Authorization: wtoken,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);

      const data = response.data.objetoRetorno;

      setCoordenador(data);
      //setAcessoServidor(data);
      // setConvenente(data);
      // setOrdenador(data);
      // setExecutor(data);
      // setInterveniente(data);
      //console.log(data)

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCadastroPF();
    // eslint-disable-next-line
  }, []);


  const handleSelectCoordenador = (event) => {
    setCoordenadorEscolhido(event.target.value);
  };
  // const handleSelectServidor = (event) => {
  //   setAcessoServidorEscolhido(event.target.value);
  // };
  // const handleSelectConvenente = (event) => {
  // setConvenenteEscolhido(event.target.value);;
  // };
  // const handleSelectOrdenador = (event) => {
  // setOrdenadorEscolhido(event.target.value);
  // };
  // const handleSelectExecutor = (event) => {
  // setExecutorEscolhido(event.target.value);
  // };
  // const handleSelectInterveniente = (event) => {
  // setIntervenienteEscolhido(event.target.value);
  // };


  const getInstrumentos = async () => {
    try {
      const wtoken = "Bearer " + wTokenLocal;

      const config = {
        method: "GET",
        url:
          urlAPIlocal + "/api/Instrumento",
        headers: {
          Authorization: wtoken,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);

      const data = response.data.objetoRetorno;

      setInstrumentos(data);
      //console.log(data)

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInstrumentos();
    // eslint-disable-next-line
  }, []);

  const handleSelectTipoInstrumento = (event) => {
    setTipoInstrumentoEscolhido(event.target.value);
    //console.log(event.target.value);

    if (event.target.value === '999') {
      setIsModalInstrumentoOpen(true);
    } else {
      setIsModalInstrumentoOpen(false);
    }
  };

  const getTipoProjeto = async () => {
    try {
      const wtoken = "Bearer " + wTokenLocal;

      const config = {
        method: "GET",
        url:
          urlAPIlocal + "/api/TipoProjeto",
        headers: {
          Authorization: wtoken,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);

      const data = response.data.objetoRetorno;

      setTipoProjeto(data);
      //console.log(data)

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTipoProjeto();
    // eslint-disable-next-line
  }, []);

  const handleSelectTipoProjeto = (event) => {
    setTipoProjetoEscolhido(event.target.value);
    //console.log(tipoProjetoEscolhido)
    if (event.target.value === '999') {
      setIsModalTipoProjetoOpen(true);
    } else {
      setIsModalTipoProjetoOpen(false);
    }
  };




  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    // //faz as validações dos inputs especificos antes de gravar
    // //dataassinatura e datPublicação não obrigatoria, mas precisa enviar '01/01/1900'
    // if (!isValidDate(dataAssinatura)) {
    //     setDataAssinatura('01/01/1900');
    //     //console.log(dataAssinatura);
    //   } else {
    //     // Aqui você pode fazer algo com a data válida
    //     //console.log(inputValue);
    //   }
    //   if (!isValidDate(dataPubli)) {
    //     setDataPubli('01/01/1900');
    //     //console.log(dataAssinatura);
    //   } else {
    //     // Aqui você pode fazer algo com a data válida
    //     //console.log(inputValue);
    //   }

    // //valida se selecionou o tipo de projeto
    // if (tipoProjetoEscolhido === "0") {
    //     //setError('Selecione o tipo do projeto');
    //     selectTipoConvenio.current.focus();
    //     return false;
    // }

    // //valida se selecionou o tipo de instrumento
    // if (tipoInstrumentoEscolhido === "0") {
    //     //setError('Selecione o tipo do projeto');
    //     selectInstrumento.current.focus();
    //     return false;
    // }

    // GravaProjetoNovo();

    //var datHoje = new Date().toISOString();
    //console.log(datHoje)

    //verifica o processo atual do projeto, sempre deixar o processo atual

    const wProcesso = projetoatual.Processo

    var wtokenBearer = "Bearer " + localStorage.getItem("token")
    var data = JSON.stringify({
      "CodigoConvenio": wCodigoConvenioNovo,
      "NumeroConvenio": numeroProjeto,
      "nome": nomeProjeto,
      "DataInicio": moment(dataInicio).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataExpiracao": moment(dataExpiracao).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "CodigoConvenente": 147, //convenenteEscolhido,
      "CodigoInterveniente": 0, //intervenienteEscolhido,
      "CodigoCoordenacaoPedagogia": coordenadorEscolhido,
      "CodigoExecutor": 147, //executorEscolhido,
      "CodigoCampus": 0,
      "CodigoDpcc": 0,
      "CodigoOrdenador": 147, //ordenadorEscolhido,
      "CodigoFinanceiro": 0,
      "objetivo": objetivo,
      "justificativa": detalhes,
      "DataAssinatura": moment(dataAssinatura).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataPublicacao": moment(dataPubli).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "pasta": "string",
      "armario": "string",
      "gaveta": "string",
      "nomeBanco": nomeBanco,
      "NumeroBanco": numeroBanco,
      "Pix": "string",
      "NumeroAgencia": agencia,
      "NumeroConta": numeroConta,
      "nomeConta": nomeConta,
      "DataCadastro": DataCadastro,
      "DataTramitacao": DataTramitacao,
      "DataVigente": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataExtintoVencido": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "status": 0,
      "processo": wProcesso,
      "DataPrestacaoContas": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataRecebimentoPrestacaoContas": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "TermoAditivo": 0,
      "DataAssinaturaTermoAditivo": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "DataProrrogacao": moment('01/01/1900').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      "tipoconvenio": tipoProjetoEscolhido,
      "origem": cidade,
      "CodigoTecnico": 0,
      "lei": leiPC,
      "pc": periocidade,
      "doa": true,
      "prazofinal": prazoFinal,
      "instrumento": tipoInstrumentoEscolhido

    });
    //console.log(moment(dataInicio).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Convenio',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json'
      },
      data: data
    };
    //console.log(data)
    //console.log(dataAssinatura);
    //console.log(dataPubli);
    axios(config)

      .then(function (response) {



        //console.log(response.data)
        if (response.data.isOk === true) {
          // fez ao consumo sem erro
          // pega o codigo cadastrado
          //console.log(response.data);
          setLoading(false);
          setShowModal(true);

          // Criar usuário area para o projeto e desabilitar o usuário antigo se o coordenador for diferente
          if (coordenadorAtual !== response.data.objetoRetorno.CodigoCoordenacaoPedagogia) {
            GetCadastroCordenadorArea(response.data.objetoRetorno.CodigoCoordenacaoPedagogia, response.data.objetoRetorno.CodigoConvenio);
            BuscaUsuarioArea(response.data.objetoRetorno.CodigoConvenio);
          }

          // Criar usuário area para o projeto se o coordenador atual for zero
          if (coordenadorAtual === 0 && response.data.objetoRetorno.CodigoCoordenacaoPedagogia !== 0) {
            GetCadastroCordenadorArea(response.data.objetoRetorno.CodigoCoordenacaoPedagogia, response.data.objetoRetorno.CodigoConvenio);
          }

        } else {
          //deu algum erro tratar , colocar algum aviso
          //setConvenioOk(false);
          setLoading(false);
          setShowModalErro(true);
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setShowModalErro(true);
      });
  }

  function GetCadastroCordenadorArea(wCodigoCadastroArea, wCodigoConvenio) {
    const wtoken = "Bearer " + wTokenLocal;

    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Cadastro?CodigoCadastro=' + wCodigoCadastroArea,
      headers: {
        'Authorization': wtoken,
        'Content-Type': 'application/json'
      },

    };

    axios(config)

      .then(function (response) {

        const data = response.data;
        if (data.isOk === true) {

          setNomeCadastroArea(data.objetoRetorno[0].Nome);
          //console.log(wCodigoConvenio);

          //consulta se já tem um usuário para aquele cadastro
          var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios?CodigoCadastro=' + wCodigoCadastroArea + '&IsInclude=false',
            headers: {
              'Authorization': wtoken,
              'Content-Type': 'application/json'
            },

          };

          axios(config)
            .then(function (response) {
              const dataUsuario = response.data.objetoRetorno;

              //Se sim, envia email 
              if (dataUsuario !== null) {
                var Assunto = "Bem vindo(a) ao Projeto '" + nomeProjeto + "'!"
                var Mensagem = "Bem vindo(a) ao Projeto <strong>'" + nomeProjeto + "'</strong>, " + data.objetoRetorno[0].Nome + ".<br></br>Agora você pode acessar o sistema com seu login e senha para fazer solicitações e gerenciar esse projeto.<br></br><br></br><strong>Link para acessar o sistema:</strong> https://sistemacontroll.app.br/";
                EnviaEmailArea(Assunto, Mensagem, wtoken, data.objetoRetorno[0].Email.Endereco);
              } else {
                //Se não, cria usuário
                CriaNovoUsuarioArea(wCodigoConvenio, data.objetoRetorno[0].Nome, data.objetoRetorno[0].Email.Endereco);
              }

            })

          // CriaNovoUsuarioArea(wCodigoConvenio, data.objetoRetorno[0].Nome, data.objetoRetorno[0].Email.Endereco);
          //setEmailArea(data.objetoRetorno[0].Email.Endereco);
          //console.log(response);

        } else {
          setConvenioOk(false);
        }

      }
      )
      .catch(function (error) {
        console.log(error);
        // se token inválido redir para login
        // if (error.response.status === 401) {
        //     setLogin(true);
        // }

      });
  }

  function CriaNovoUsuarioArea(wCodigoConvenio, nomeCadastroArea, EmailArea) {

    // Função para gerar a senha randomicamente
    function gerarSenhaRandomica() {
      const caracteresEspeciais = '!@#$%^&*';
      const letrasMaiusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const letrasMinusculas = 'abcdefghijklmnopqrstuvwxyz';
      const numeros = '0123456789';

      let senha = '';
      let temNumero = false;
      let temCaracterEspecial = false;
      let temLetraMaiuscula = false;
      let temLetraMinuscula = false;

      while (senha.length < 9 || !(temNumero && temCaracterEspecial && temLetraMaiuscula && temLetraMinuscula)) {
        const tipoCaracter = Math.floor(Math.random() * 4);

        if (tipoCaracter === 0 && !temNumero) {
          senha += numeros[Math.floor(Math.random() * numeros.length)];
          temNumero = true;
        } else if (tipoCaracter === 1 && !temCaracterEspecial) {
          senha += caracteresEspeciais[Math.floor(Math.random() * caracteresEspeciais.length)];
          temCaracterEspecial = true;
        } else if (tipoCaracter === 2 && !temLetraMaiuscula) {
          senha += letrasMaiusculas[Math.floor(Math.random() * letrasMaiusculas.length)];
          temLetraMaiuscula = true;
        } else if (tipoCaracter === 3 && !temLetraMinuscula) {
          senha += letrasMinusculas[Math.floor(Math.random() * letrasMinusculas.length)];
          temLetraMinuscula = true;
        } else {
          const caracteres = caracteresEspeciais + letrasMaiusculas + letrasMinusculas + numeros;
          senha += caracteres[Math.floor(Math.random() * caracteres.length)];
        }
      }

      return senha;
    }

    // Gerar a senha randomicamente
    const senhaAcesso = gerarSenhaRandomica();
    const senhaAssinatura = gerarSenhaRandomica();


    const nomeCompleto = nomeCadastroArea;
    const primeiroNome = nomeCompleto.split(' ')[0];

    var wtokenBearer = "Bearer " + localStorage.getItem("token");
    var data = JSON.stringify({
      "CodigoUsuario": 0,
      "Login": primeiroNome + wCodigoConvenio,
      "Senha": senhaAcesso,
      "CodigoCadastro": coordenadorEscolhido,
      "DataExpira": dataExpiracao,
      "CodigoConvenio": wCodigoConvenio,
      "CodigoPerfil": 37,
      "NumeroAcesso": 0,
      "DataUltimoAcesso": "1900-01-01T00:00:00.000Z",
      "Nome": nomeCadastroArea,
      "Ativo": true,
      "Logado": false,
      "IgnorarRateLimit": true,
      "SenhaAssinatura": senhaAssinatura

    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Usuarios',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json'
      },
      data: data
    };
    //console.log(data)
    axios(config)

      .then(function (response) {

        if (response.data.isOk === true) {
          //enviar email para fundação
          var Assunto = "Bem vindo(a) ao Sistema Controll!"
          var Mensagem = "Bem vindo(a) ao Sistema Controll!: " + nomeCadastroArea + "<br></br>Agora você pode acessar ao sistema para fazer solicitações e gerenciar seu projeto. Aqui estão as informações necessárias para efetuar o acesso: <br></br><strong>Login:</strong> " + primeiroNome + wCodigoConvenio + "<br></br><strong>Senha de Acesso:</strong> " + senhaAcesso + "<br></br><strong>Senha de Assinatura: </strong>" + senhaAssinatura + "<br></br><br></br><strong>Link para acessar o sistema:</strong> https://sistemacontroll.app.br/";
          EnviaEmailArea(Assunto, Mensagem, wtokenBearer, EmailArea);
        } else {
          setConvenioOk(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function EnviaEmailArea(wAssunto, wMensagem, wtokenBearer, EmailArea) {

    var data = JSON.stringify({
      "RemetenteEmail": "",
      "RemetenteNome": "",
      "CopiaOculta": [],
      "destinatario": [EmailArea],
      "assunto": wAssunto,
      "corpo": wMensagem,
    });

    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Email',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json',
      },
      data: data
    };
    //console.log(data)
    //console.log(config)
    axios(config)
      .then(response => {
        //console.log(response.data);
        // faça algo com a resposta da API
      })
      .catch(error => {
        console.error(error);
      });
  }

  function BuscaUsuarioArea(wCodigoConvenio) {
    const wtoken = "Bearer " + wTokenLocal;

    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Usuarios?CodigoCadastro=' + coordenadorAtual + '&CodigoConvenio=' + wCodigoConvenio + '&IsInclude=true',
      headers: {
        'Authorization': wtoken,
        'Content-Type': 'application/json'
      },

    };

    axios(config)

      .then(function (response) {

        const data = response.data;
        if (data.isOk === true) {

          //setCodigoUsuarioAreaAntigo(data.objetoRetorno[0].CodigoUsuario);
          //console.log(wCodigoConvenio);

          DesabilitaUsuarioAreaAntigo(wCodigoConvenio, data.objetoRetorno[0].CodigoUsuario, data.objetoRetorno[0].Nome, data.objetoRetorno[0].Login, data.objetoRetorno[0].CodigoCadastro, data.objetoRetorno[0].Senha, data.objetoRetorno[0].SenhaAssinatura);
          //console.log(response);

        } else {
          setConvenioOk(false);
        }

      }
      )
      .catch(function (error) {
        console.log(error);
        // se token inválido redir para login
        // if (error.response.status === 401) {
        //     setLogin(true);
        // }

      });
  }

  function DesabilitaUsuarioAreaAntigo(wCodigoConvenio, wCodigoUsuarioAntigo, wNomeUsuarioAntigo, wLoginAntigo, wCodigoCadastroAntigo, wSenha, wSenhaAssinatura) {
    // const nomeCompleto = wNomeUsuarioAntigo;
    // const primeiroNome = nomeCompleto.split(' ')[0];

    var wtokenBearer = "Bearer " + localStorage.getItem("token");
    var data = JSON.stringify({
      "CodigoUsuario": wCodigoUsuarioAntigo,
      "Login": wLoginAntigo,
      "Senha": wSenha,
      "CodigoCadastro": wCodigoCadastroAntigo,
      "DataExpira": dataExpiracao,
      "CodigoConvenio": wCodigoConvenio,
      "CodigoPerfil": 37,
      "NumeroAcesso": 0,
      "DataUltimoAcesso": "1900-01-01T00:00:00.000Z",
      "Nome": wNomeUsuarioAntigo,
      "Ativo": false,
      "Logado": false,
      "IgnorarRateLimit": false,
      "SenhaAssinatura": wSenhaAssinatura
    });

    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/Usuarios',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json'
      },
      data: data
    };
    //console.log(data)
    axios(config)

      .then(function (response) {

        if (response.data.isOk === true) {
          //console.log(response.data)
        } else {
          setConvenioOk(false);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  function handleBlur() {
    if (nomeProjeto.length < 3) {
      setError('O nome tem que ter pelo menos 3 caracteres');
    } else {
      setError('');
    }
  }

  // eslint-disable-next-line
  function isValidDate(dateString) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false; // Formato inválido
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) return false; // Data inválida (por exemplo, "fevereiro 30")
    return d.toISOString().slice(0, 10) === dateString;
  }

  const closeAndResetModalInstrumento = () => {
    setIsModalInstrumentoOpen(false);
    setErroCadInstrumento(false); // Redefina o estado de erroCadInstrumento para false
    setNovoInstrumento(''); // Limpe o valor de novoInstrumento
    // Altere a chave do modal para recriá-lo ao ser reaberto
    //setModalInstrumentoKey(modalInstrumentoKey + 1);
  };

  const CadInstrumento = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await axios.get(
        urlAPIlocal + `/api/Instrumento?Nome=${novoInstrumento}&IsInclude=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.codigoRetorno > 0) {
        setLoading(false);
        setErroCadInstrumento(true);
        //console.log(response)
      } else {
        // Redefina o estado de erroCadInstrumento para false aqui
        setErroCadInstrumento(false);
        //console.log("erro pescado false");

        var wtokenBearer = "Bearer " + localStorage.getItem("token");
        var data = JSON.stringify({
          "CodInstrumento": 0,
          "Nome": novoInstrumento
        });

        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: urlAPIlocal + "/api/Instrumento",
          headers: {
            Authorization: wtokenBearer,
            "Content-Type": "application/json",
          },
          data: data,
        };

        try {
          const response = await axios(config);
          //console.log(response.data);

          if (response.data.isOk === true) {
            setLoading(false);
            closeAndResetModalInstrumento(); // Chame a função para fechar o modal e redefinir os valores
            getInstrumentos();
            setTipoInstrumentoEscolhido(response.data.objetoRetorno.CodInstrumento);
          } else {
            setLoading(false);
            setShowModalErro(true);
          }

        } catch (error) {
          console.error(error);
          setLoading(false);
          setShowModalErro(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setShowModalErro(true);
      console.log(error);
    }
  };

  const closeAndResetModalTipoProjeto = () => {
    setIsModalTipoProjetoOpen(false);
    setErroCadTipoProjeto(false); // Redefina o estado de erroCadTipoProjeto para false
    setNovoTipoProjeto(''); // Limpe o valor de novoInstrumento
    // Altere a chave do modal para recriá-lo ao ser reaberto
    //setModalInstrumentoKey(modalInstrumentoKey + 1);
  };

  const CadTipoProjeto = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const response = await axios.get(
        urlAPIlocal + `/api/TipoProjeto?Nome=${novoTipoProjeto}&IsInclude=false`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.codigoRetorno > 0) {
        setLoading(false);
        setErroCadTipoProjeto(true);
        //console.log(response)
      } else {
        // Redefina o estado de erroCadTipoProjeto para false aqui
        setErroCadTipoProjeto(false);
        //console.log("erro pescado false");

        var wtokenBearer = "Bearer " + localStorage.getItem("token");
        var data = JSON.stringify({
          "CodTipoProjeto": 0,
          "Nome": novoTipoProjeto
        });

        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: urlAPIlocal + "/api/TipoProjeto",
          headers: {
            Authorization: wtokenBearer,
            "Content-Type": "application/json",
          },
          data: data,
        };

        try {
          const response = await axios(config);
          //console.log(response.data);

          if (response.data.isOk === true) {
            setLoading(false);
            closeAndResetModalTipoProjeto(); // Chame a função para fechar o modal e redefinir os valores
            getTipoProjeto();
            setTipoProjetoEscolhido(response.data.objetoRetorno.CodTipoProjeto);
          } else {
            setLoading(false);
            setShowModalErro(true);
          }

        } catch (error) {
          console.error(error);
          setLoading(false);
          setShowModalErro(true);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShowModalErro(true);
    }
  };


  return (
    <div>
      <Menu />

      <div> <button className={StyleForm.voltar}>  <Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

      <div className={StyleForm.header}>
        <h1>Edição de Projeto</h1>
        {/* <p>Preencha o formulário para cadastrar um novo projeto</p> */}
      </div>
      {/* {error && <div align="center">{error}</div>} */}
      <div className={StyleForm.container}>
        <Form className={StyleForm.form} onSubmit={handleSubmit}>
          <FormGroup className={StyleForm.form_group}>
            <Row>
              <Col md={3}>
                <Form.Label className={StyleForm.form_group_label}>Numero do Projeto</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="numconvenio" value={numeroProjeto} onChange={(event) => setNumeroProjeto(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Título do Projeto</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="nomeconvenio" value={nomeProjeto} onChange={(event) => setNomeProjeto(event.target.value)} onBlur={handleBlur} required />

              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Cidade de Execução</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="cidade" value={cidade} onChange={(event) => setCidade(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Tipo do Projeto</Form.Label>
                <Form.Select ref={selectTipoConvenio} className={StyleForm.form_control} name="tipoProjeto" id="tipoProjeto" value={tipoProjetoEscolhido} onChange={handleSelectTipoProjeto} required >
                  <option value="0">Selecione uma opção</option>
                  {tipoProjeto.length > 0 &&
                    tipoProjeto.map((opcao) => (
                      <option key={opcao.CodTipoProjeto} value={opcao.CodTipoProjeto}>{opcao.Nome}</option>
                    ))}
                  <option value='999'>Outro</option>
                </Form.Select>


              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Tipo de Instrumento</Form.Label>
                <Form.Select ref={selectInstrumento} className={StyleForm.form_control} name="tipointrumento" id="tipointrumento" value={tipoInstrumentoEscolhido} onChange={handleSelectTipoInstrumento} required>
                  <option value="0">Selecione uma opção</option>
                  {instrumentos.length > 0 &&
                    instrumentos.map((opcao) => (
                      <option key={opcao.CodInstrumento} value={opcao.CodInstrumento}>{opcao.Nome}</option>
                    ))}
                  <option value='999'>Outro</option>
                </Form.Select>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Lei para Prestação de Contas</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="leiPC" value={leiPC} onChange={(event) => setLeiPC(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Periocidade da Prestação de Contas</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="periodoPC" value={periocidade} onChange={(event) => setPeriodicidade(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Prazo Final em Dias(PC)</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="prazofinal" value={prazoFinal} onChange={(event) => setPrazoFinal(event.target.value)} required />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Data Início</Form.Label>
                <Form.Control className={StyleForm.form_control} type="date" name="dataInicio" value={dataInicio} onChange={(event) => setDataInicio(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Data de Expiração</Form.Label>
                <Form.Control className={StyleForm.form_control} type="date" name="dataExpiracao" value={dataExpiracao} onChange={(event) => setDataExpiracao(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Data da Assinatura</Form.Label>
                <Form.Control className={StyleForm.form_control} type="date" name="assinatura" value={dataAssinatura} onChange={(event) => setDataAssinatura(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Data da Publicação</Form.Label>
                <Form.Control className={StyleForm.form_control} type="date" name="publicacao" value={dataPubli} onChange={(event) => setDataPubli(event.target.value)} />
              </Col>
            </Row>
          </FormGroup>

          <div className={StyleForm.form_group}>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Detalhes do Projeto</Form.Label>
                <Form.Control className={StyleForm.form_control} name="detalhes" id="datalhes" value={detalhes} onChange={(event) => setDetalhes(event.target.value)} required ></Form.Control>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Objetivo</Form.Label>
                <Form.Control className={StyleForm.form_control} name="objetivo" id="objetivo" value={objetivo} onChange={(event) => setObjetivo(event.target.value)} required ></Form.Control>
              </Col>
            </Row>
          </div>

          <FormGroup className={StyleForm.form_group}>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Coordenador Área</Form.Label>
                <Form.Select className={StyleForm.form_control} name="coordenador" id="coordenador" value={coordenadorEscolhido} onChange={handleSelectCoordenador}>
                  <option value="0">Selecione um coordenador</option>

                  {coordenador.length > 0 &&
                    coordenador.map((opcao) => (
                      <option key={opcao.Nome} value={opcao.CodigoCadastro}>
                        {opcao.Nome}
                      </option>
                    ))}
                </Form.Select>
              </Col>
              {/* <Col>
                <Form.Label className={StyleForm.form_group_label}>Acesso ao Servidor Técnico</Form.Label>
                <Form.Select className={StyleForm.form_control} name="servidor" id="servidor" value={acessoServidorEscolhido} onChange={handleSelectServidor}>

                  <option value="">Selecione um servidor</option>

                  {acessoServidor.length > 0 &&
                    acessoServidor.map((opcao) => (
                      <option key={opcao.Nome} value={opcao.CodigoCadastro}>
                        {opcao.Nome}
                      </option>
                    ))}
                </Form.Select>
              </Col> */}
              {/* <Col>
                    <Form.Label className={StyleForm.form_group_label}>Convenente</Form.Label>
                <Form.Select className={StyleForm.form_control} name="convenente" id="convenente" value={convenenteEscolhido} onChange={handleSelectConvenente}>
                
                <option value="">Selecione um convenente</option>
               
                {convenente.length > 0 &&
          convenente.map((opcao) => (
            <option key={opcao.nome} value={opcao.CodigoCadastro}>
              {opcao.nome}
            </option>
          ))}
                </Form.Select>
                    </Col>
                </Row>
                <Row>
                <Col>
                    <Form.Label className={StyleForm.form_group_label}>Ordenador de Despesas</Form.Label>
                <Form.Select className={StyleForm.form_control} name="ordenador" id="ordenador" value={ordenadorEscolhido} onChange={handleSelectOrdenador}>
                
                <option value="">Selecione um ordenador</option>
                
                {ordenador.length > 0 &&
          ordenador.map((opcao) => (
            <option key={opcao.nome} value={opcao.CodigoCadastro}>
              {opcao.nome}
            </option>
          ))}
                </Form.Select>
                    </Col>
                    <Col>
                    <Form.Label className={StyleForm.form_group_label}>Executor</Form.Label>
                <Form.Select className={StyleForm.form_control} name="executor" id="executor" value={executorEscolhido} onChange={handleSelectExecutor}>
                
                <option value="">Selecione um executor</option>
                
                {executor.length > 0 &&
          executor.map((opcao) => (
            <option key={opcao.nome} value={opcao.CodigoCadastro}>
              {opcao.nome}
            </option>
          ))}
                </Form.Select>
                    </Col>
                    <Col>
                    <Form.Label className={StyleForm.form_group_label}>Interveniente</Form.Label>
                <Form.Select className={StyleForm.form_control} name="interveniente" id="interveniente" value={intervenienteEscolhido} onChange={handleSelectInterveniente}>
                
                <option value="">Selecione um interveniente</option>
                
                {interveniente.length > 0 &&
          interveniente.map((opcao) => (
            <option key={opcao.nome} value={opcao.CodigoCadastro}>
              {opcao.nome}
            </option>
          ))}
                </Form.Select>
                    </Col> */}
            </Row>

          </FormGroup>

          <FormGroup className={StyleForm.form_group}>

            <h5>Informações Bancárias</h5>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Nome do Banco</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="banco" value={nomeBanco} onChange={(event) => setNomeBanco(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Nome da Conta</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="nomeConta" value={nomeConta} onChange={(event) => setNomeConta(event.target.value)} />
              </Col>

            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Agencia</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="agencia" value={agencia} onChange={(event) => setAgencia(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Numero da Conta e Dígito</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="numConta" value={numeroConta} onChange={(event) => setNumeroConta(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Numero do Banco</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="numbanco" value={numeroBanco} onChange={(event) => setNumeroBanco(event.target.value)} />
              </Col>
            </Row>
            <Row>
              <button className={StyleForm.btn} type="submit">
                <span>Salvar</span>
                <MdCreate />
              </button>
            </Row>
          </FormGroup>

        </Form>

        {isModalInstrumentoOpen &&
          <Modal show={isModalInstrumentoOpen} className={StyleForm.Modal} centered>
            <Modal.Header className={StyleForm.Modal_header}>
              <h3>Cadastro de Instrumento</h3>
            </Modal.Header>
            <Modal.Body className={StyleForm.Modal_body}>

              {erroCadInstrumento && <h6>Esse tipo de instrumento já foi cadastrado, verifique a lista.</h6>}
              {showModalErro && <h6>Ops! Algo deu errado, por favor tente novamente.</h6>}

              <Form onSubmit={CadInstrumento}>
                <Row>
                  <Col>
                    <Form.Label className={StyleForm.label_modal}>Tipo de Instrumento:</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control className={StyleForm.form_control} type="text" name="instrumento" value={novoInstrumento} onChange={(event) => setNovoInstrumento(event.target.value)} />
                  </Col>
                </Row>

                <button type="submit" className={StyleForm.btn_salvar}> Salvar </button>
                <button type="button" className={StyleForm.btn_cancelar} onClick={closeAndResetModalInstrumento}> Cancelar </button>
              </Form>
            </Modal.Body>
          </Modal>
        }

        {isModalTipoProjetoOpen &&
          <Modal show={isModalTipoProjetoOpen} className={StyleForm.Modal} centered>
            <Modal.Header className={StyleForm.Modal_header}>
              <h3>Cadastro de Tipos Projetos</h3>
            </Modal.Header>
            <Modal.Body className={StyleForm.Modal_body}>

              {erroCadTipoProjeto && <h6>Esse tipo de projeto já foi cadastrado, verifique a lista.</h6>}
              {showModalErro && <h6>Ops! Algo deu errado, por favor tente novamente.</h6>}

              <Form onSubmit={CadTipoProjeto}>
                <Row>
                  <Col>
                    <Form.Label className={StyleForm.label_modal}>Tipo de Projeto:</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Control className={StyleForm.form_control} type="text" name="tipoProjeto" value={novoTipoProjeto} onChange={(event) => setNovoTipoProjeto(event.target.value)} />
                  </Col>
                </Row>

                <button type="submit" className={StyleForm.btn_salvar}> Salvar </button>
                <button type="button" className={StyleForm.btn_cancelar} onClick={closeAndResetModalTipoProjeto}> Cancelar </button>
              </Form>
            </Modal.Body>
          </Modal>
        }

        <Modal show={loading} className={StyleForm.Modal} centered >
          <Modal.Body className={StyleForm.Modal_body}>
            <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
          <Modal.Header className={StyleForm.Modal_header}>
            <img src={check_circle} alt="ok"></img>
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <p>Editado com sucesso!</p>
          </Modal.Body>
        </Modal>

        <Modal show={showModalErro} className={StyleForm.Modal} centered >
          <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
            <h3>Ops!</h3>
            <ImSad2 />
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <p>Algo deu errado, cheque os campos e tente novamente</p>
          </Modal.Body>
        </Modal>

      </div>

      {login && (<Navigate to="/" replace={true} />)}

      {convenioOk && (<Navigate to={`/VerProjeto/${CodigoProjeto}`} replace={true} />)}
    </div>

  )
}
export default EditProjeto



