import { useState, useEffect } from "react";
import Menu from "../Layout/Menu";
import StyleForm from './Pagamentos.module.css'
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { Navigate } from "react-router";
import moment from "moment";
import urlAPIlocal from '../../global';
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { TfiReload } from "react-icons/tfi";
import { Modal } from 'react-bootstrap';
import loadingGif from "../img/icons8-spinning-circle.gif";
import check_circle from "../img/check_circle.gif";
import { ImSad2 } from 'react-icons/im';
import { BiSearchAlt, BiArrowFromLeft } from "react-icons/bi";

function PagamentosSemSolic() {

    var wToken = 'Bearer ' + localStorage.getItem("token");
    var wCodigoConvenio = localStorage.getItem("CodigoProjeto");

    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(false);

    const [tipoContratacao, setTipoContratacao] = useState('');
    //const [errotipoContratação, setErroTipoContratacao] = useState(false);
    const [fornecedores, setFornecedores] = useState([]);
    const [fornecedorEscolhido, setFornecedorEscolhido] = useState(0);
    const [valorPago, setValorPago] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [dataDocumento, setDataDocumento] = useState('');
    const [dataOrdemBancaria, setDataOrdemBancaria] = useState('');
    const [ordemBancaria, setOrdemBancaria] = useState('');
    const [fonte, setFonte] = useState(0);
    const [fonteDisabled, setFonteDisabled] = useState(false);
    const [elementos, setElementos] = useState([]);
    const [elementoEscolhido, setElementoEscolhido] = useState(0);
    const [elementoDisabled, setElementoDisabled] = useState(false);
    const [metas, setMetas] = useState([]);
    const [metaEscolhida, setMetaEscolhida] = useState(0);
    const [metaDisabled, setMetaDisabled] = useState(false);
    const [fases, setFases] = useState([]);
    const [faseEscolhida, setFaseEscolhida] = useState(0);
    const [faseDisabled, setFaseDisabled] = useState(false);
    const [observacao, setObservacao] = useState('');
    const [alertaSaldo, setAlertaSaldo] = useState(false);
    const [avisoSaldo, setAvisoSaldo] = useState('');
    const [pt, setPt] = useState([]);
    const [numFaseSelecionada, setNumFaseSelecionada] = useState('');
    const [numElemento, setNumElemento] = useState('');
    const [btGravar, setBtGravar] = useState(false);
    const [pagamentoOk, setPagamentoOk] = useState(false);
    const [showModalOk, setShowModalOk] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);


    function handleClose() {
        setTimeout(() => {
            setShowModalOk(false);
            setPagamentoOk(true);

        }, 1000);

    }
    function handleCloseErro() {
        setShowModalErro(false);
        setPagamentoOk(false);
    };

    // function handleCloseErroTipoContratação() {
    //     setErroTipoContratacao(false);
    // };

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wToken === undefined || wToken === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function AlimentaPT() {


        const config = {
            method: "GET",
            url:
                urlAPIlocal + "/api/ApiPlanoTrabalho/" + wCodigoConvenio,
            headers: {
                Authorization: wToken,
                "Content-Type": "application/json",
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    var dados = response.data.objetoRetorno;
                    //console.log(dados);
                    setPt(dados);

                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //Retorno = true
                }

            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
        //return Retorno;
    }
    useEffect(() => {
        AlimentaPT();
        // eslint-disable-next-line
    }, []);

    function PegaNumElemento(CodElemento) {


        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ConvenioElemento?CodigoConvenioElemento=' + CodElemento + '&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                //console.log(response)
                const data = response.data;
                if (response.data.isOk === true) {
                    setNumElemento(data.objetoRetorno[0].CodigoElemento);
                    //console.log(data.objetoRetorno[0].CodigoElemento);
                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setNumElemento('');
                }
            }
            )
            .catch(function (error) {
                console.log(error);

            });

    }

    const getCadastroFornecedores = async () => {

        try {
            //const wtoken = "Bearer " + localStorage.getItem("token");

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Cadastro?Aprovado=1&IsInclude=true",
                headers: {
                    Authorization: wToken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config);
            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data);

            const cadastrosFiltrados = data.filter(item => item.CodigoTipoCadastro === 33 || item.CodigoTipoCadastro === 43);

            // Encontrando um registro específico com CodigoCadastro igual a 200 na lista filtrada
            const registroEspecifico = data.find(item => item.CodigoCadastro === 200);
            //console.log(registroEspecifico);
            // Adicionando o registro específico à lista final se ele existir
            const listaFinal = registroEspecifico ? [...cadastrosFiltrados, registroEspecifico] : cadastrosFiltrados;

            setFornecedores(listaFinal);
            //console.log(listaFinal);


        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getCadastroFornecedores();
        // eslint-disable-next-line
    }, []);

    const handleSelectFornecedor = (event) => {
        setFornecedorEscolhido(event.target.value);
    };

    const handleSelectFonte = (event) => {
        setFonte(event.target.value);
        setFonteDisabled(true);
        //setAvisoSaldo(false);
        // if (event.target.value !== '0') {
        //     document.getElementById('fonte').disabled = true;
        // }
    };

    const getElementosProjeto = async () => {

        try {

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/ConvenioElemento?CodigoConvenio=" + wCodigoConvenio + "&IsInclude=true",
                headers: {
                    Authorization: wToken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config)
            const response = await axios(config);


            const data = response.data.objetoRetorno;

            setElementos(data);
            //console.log(data);
            //muda a ordem dos itens no array antes de mostrar na tela
            const sortedList = data.sort((c, d) => c.CodigoElemento - d.CodigoElemento);
            setElementos(sortedList);

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getElementosProjeto();
        // eslint-disable-next-line
    }, []);

    const handleSelectElemento = (event) => {
        setElementoEscolhido(event.target.value);
        PegaNumElemento(event.target.value);
        setElementoDisabled(true);
        // if (event.target.value !== '0') {
        //     document.getElementById('elemento').disabled = true;
        // }
    };

    const getMetas = async () => {
        try {

            const config = {
                method: "GET",
                url: urlAPIlocal + "/api/Meta?CodigoConvenio=" + wCodigoConvenio,
                headers: {
                    Authorization: wToken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);
            const data = response.data.objetoRetorno;
            setMetas(data);
            //console.log(data);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {

        getMetas();

    }, []);

    const handleSelectMeta = (event) => {
        setMetaEscolhida(event.target.value);
        getFases(event.target.value);
        setMetaDisabled(true);
        //setAlertaSaldo(false);
        // if (event.target.value !== '0') {
        //     document.getElementById('Meta').disabled = true;
        // }
    };

    const getFases = async (wCodigoMeta) => {
        try {
            //setLoading(true);
            const config = {
                method: "GET",
                url: urlAPIlocal + "/api/Fase?CodigoMeta=" + wCodigoMeta + "&IsInclude=true",
                headers: {
                    Authorization: wToken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);
            const data = response.data.objetoRetorno;
            //console.log(data);
            setFases(data);
            //setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectFase = (event) => {
        setFaseEscolhida(event.target.value);
        setFaseDisabled(true);
        // if (event.target.value !== '0') {
        //     document.getElementById('Fase').disabled = true;
        // }

        AlimentaPT();
        //console.log(fases);
        const fasesArray = Object.values(fases);
        const wNumfase = fasesArray.find(fase => fase.CodigoConvenioFase === parseInt(event.target.value));
        setNumFaseSelecionada(wNumfase.NumeroFase);

        //console.log(wNumfase.NumeroFase);
        ConsultaSaldo(wNumfase.NumeroFase);
    };

    const handleSelectTipoContratacao = (event) => {
        setTipoContratacao(event.target.value);
    };

    const ConsultaSaldo = (wNumFaseSelecionada) => {
        //pega as variaveis para consultar o saldo
        //CodMeta | CodFonte | CodFase | Valor total Solic
        setLoading(true)
        var data = JSON.stringify({
            "CodigoConvenio": wCodigoConvenio,
            "Data inicial": localStorage.getItem('DataInicioProjeto'),
            "Data final": localStorage.getItem('DataExpiracaoProjeto')
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Convenio/Financeiro?IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro
                    const wTotalCreditosProjeto = response.data.objetoRetorno.Financeiro.TotAportes + response.data.objetoRetorno.Financeiro.TotRepasses + response.data.objetoRetorno.Financeiro.TotRendimentos;
                    const wTotalDebitosProjeto = response.data.objetoRetorno.Financeiro.TotPagamentosBruto + response.data.objetoRetorno.Financeiro.SolicAPagar + response.data.objetoRetorno.Financeiro.TotDevAportes;
                    const SaldoProjeto = wTotalCreditosProjeto - wTotalDebitosProjeto;
                    //se o saldoprojeto for maior que o toal da solic, continua a consulta com as variaveis meta fase fonte valor total
                    //console.log(valorPago);
                    //console.log(valorTotalSolic);
                    if (SaldoProjeto < valorPago) {
                        // nao tem saldo financeiro no projeto para essa solicitação
                        setLoading(false);
                        setAvisoSaldo('Projeto sem saldo financeiro para esse pagamento');
                        setAlertaSaldo(true);
                    } else {
                        // tem saldo, agora enviar as variaveis e consultar se tem saldo na meta fase fonte elemento
                        const wFonte = parseInt(fonte);
                        const wElemento = parseInt(numElemento);
                        const wMeta = parseInt(metaEscolhida);
                        const wFase = wNumFaseSelecionada;
                        //console.log(wNumFaseSelecionada);
                        //console.log('Fonte: ' + wFonte + '| CodMeta: ' + wMeta + '| NumFase: ' + wFase + '| CodFase: ' + faseEscolhida + '| NumElemento: ' + wElemento + '| CodElemento: ' + solicitacao.CodigoElemento);
                        //console.log(pt);
                        //busca a meta que foi escolhida, para depois buscar as fases elemento e valroes gastos e previstos
                        const metasArray = Object.values(pt.Metas);
                        //console.log(metasArray);
                        const metaEncontrada = metasArray.find(meta => meta.CodigoMeta === wMeta);

                        // agora busca a fase dentro da meta
                        const faseArray = Object.values(metaEncontrada.MetaFases);
                        //console.log(faseArray)
                        const faseEncontrada = faseArray.find(fase => fase.NumeroFase === wFase);
                        //console.log(faseEncontrada);

                        //agora verifica se tem o elemento solicitado na meta fase fonte
                        const MetaFaseElementoArray = Object.values(faseEncontrada.MetaFasePrevistos);
                        // console.log(MetaFaseElementoArray);
                        // console.log(numElemento);
                        // console.log(fonte);
                        const ElementoEncontrado = MetaFaseElementoArray.find(Elemento => Elemento.CodigoElemento === wElemento && Elemento.CodigoFonte === wFonte);

                        //console.log(ElementoEncontrado); // Verifique se o objeto é encontrado corretamente.

                        if (ElementoEncontrado) {
                            //tem elemento, verifica se tem saldo na meta fase elemento fonte
                            const wValorPagamento = valorPago;
                            const wVprevisto = ElementoEncontrado.Total;
                            const wGasto = ElementoEncontrado.ValGasto + wValorPagamento;
                            //console.log(wGasto);
                            //console.log(wVprevisto);
                            if (wGasto > wVprevisto) {
                                //sem saldo para essa solicitação
                                setLoading(false);
                                setAvisoSaldo('Sem saldo para esse pagamento nessa Fonte | Meta | Fase | Elemento.');
                                setAlertaSaldo(true);
                                setBtGravar(false);
                            } else {
                                //tem saldo prossegue gravando a meta fase e fonte na solicitação
                                //setAvisoSaldo('Saldo disponível para esse esse pagamento, para confirmar a Meta, Fase e Fonte dessa solicitação, clicar em Atualizar Processo.');
                                setLoading(false);
                                setAlertaSaldo(false);
                                setBtGravar(true);
                            }
                        } else {
                            //não tem previsao do elemento e fonte na meta fase
                            setLoading(false);
                            setAvisoSaldo('Não existe no planejamento da Meta Fase esse Elemento de despesa.');
                            setAlertaSaldo(true);
                            setBtGravar(false);
                            //console.log('entrou aqui');
                        }
                        //setAlertaSaldo(false);
                        //setBtAtualiza(false);
                    }

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    //setShowModalErro(true);
                    setLoading(false);
                    //console.log('erro');
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //setShowModalErro(true);
            });

    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        //setIsButtonDisabled(true);
        setLoading(true);

        // if (tipoContratação === '') {
        //     // Define o valor do tipo de contratação se for diferente de 0
        //     setErroTipoContratacao(true);
        // } else {

        var vTotalPago = valorPago;
        vTotalPago = vTotalPago.replace(',', '.');

        var data = JSON.stringify({
            "CodigoFinanceiro": 0,
            "CodigoConvenio": wCodigoConvenio,
            "NumeroSequencia": 0,
            "CodigoSolicitacao": 0,
            "CodigoCadastro": fornecedorEscolhido,
            "CodigoFonte": fonte,
            "CodigoElemento": elementoEscolhido,
            "CodigoMeta": metaEscolhida,
            "CodigoFase": faseEscolhida,
            "NumeroNotaFiscal": numeroDocumento,
            "DataEmissao": moment(dataDocumento).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "ValorLiquido": vTotalPago,
            "ValorIss": 0,
            "ValorInss": 0,
            "ValorIrrf": 0,
            "ValorInss20": 0,
            "ValorTotal": vTotalPago,
            "DataPagamento": moment(dataOrdemBancaria).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "ValorPago": vTotalPago,
            "NumeroDoc": ordemBancaria,
            "Tipo": false,
            "Observacao": observacao,
            "ValorDesconto": 0,
            "Issp": false,
            "Inssp": false,
            "Irrfp": false,
            "Inss20p": false,
            "DataISS": "1900-01-01T00:00:00.000Z",
            "DataINSS": "1900-01-01T00:00:00.000Z",
            "DataIRRF": "1900-01-01T00:00:00.000Z",
            "DataINSS20": "1900-01-01T00:00:00.000Z",
            "NomeISS": "",
            "NomeINSS": "",
            "NomeIRRF": "",
            "NomeINSS20": "",
            "Setor": 0,
            "ValorOutrosImpostos": 0,
            "OutrosImpostos": false,
            "DataOutrosImpostos": "1900-01-01T00:00:00.000Z",
            "NomeOutrosImpostos": "",
            "TipoLicitacao": tipoContratacao,
        });
        //console.log(data)
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Financeiro',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro

                    setTipoContratacao('0');
                    setFornecedorEscolhido(0);
                    setValorPago('');
                    setNumeroDocumento('');
                    setDataDocumento('');
                    setOrdemBancaria('');
                    setDataOrdemBancaria('');
                    setObservacao('');
                    ReiniciarConsultaSaldo();
                    setShowModalOk(true);
                    setLoading(false);

                    //console.log('fez o consumo sem erro');

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setLoading(false);
                    //setShowModalErro(true);
                }
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
            });
        //}
    }

    const ReiniciarConsultaSaldo = () => {
        setFonte('0');
        setElementoEscolhido('0');
        setMetaEscolhida('0');
        setFaseEscolhida('0');
        setValorPago('');
        setFonteDisabled(false);
        setElementoDisabled(false);
        setMetaDisabled(false);
        setFaseDisabled(false);
        setBtGravar(false);
    };


    return (
        <div>

            <Menu />
            <div> <button className={StyleForm.voltar}>  <Link to={`/ListPagamentos/${wCodigoConvenio}`} ><IoMdArrowBack /> Voltar</Link> </button> </div>
            <div className={StyleForm.header}>
                <h1>Lançamento de Pagamentos Sem Solicitação</h1>
                <Link to={`/PtFinanceiro/${wCodigoConvenio}`} target="_blank">
                    ver Plano de Trabalho Financeiro
                </Link>
            </div>
            {/* {error && <div align="center">{error}</div>} */}
            <div className={StyleForm.container}>
                <Form className={StyleForm.form} onSubmit={handleSubmit}>
                    <FormGroup className={StyleForm.form_group}>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Tipo de Contratação</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="tipoContratacao" value={tipoContratacao} onChange={handleSelectTipoContratacao} required >
                                    <option value="">Selecione o Tipo de Contratação</option>
                                    <option value="Dispensa">Dispensa</option>
                                    <option value="Convite">Convite</option>
                                    <option value="Tomada de Preços">Tomada de Preços</option>
                                    <option value="Concorrência">Concorrência</option>
                                    <option value="Pregão Eletrônico">Pregão Eletrônico</option>
                                    <option value="Pregão Presencial">Pregão Presencial</option>
                                    <option value="Inexigível">Inexigível</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Fornecedor*</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="Fornecedor" id="Fornecedor" value={fornecedorEscolhido} onChange={handleSelectFornecedor} required >
                                    <option value="0">Selecione uma opção</option>
                                    {fornecedores.length > 0 &&
                                        fornecedores.map((opcao) => (
                                            <option key={opcao.CodigoCadastro} value={opcao.CodigoCadastro}>{opcao.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Valor Pago*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="valorPago" value={valorPago} onChange={(event) => setValorPago(event.target.value)} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>N° do Documento*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="numDocumento" value={numeroDocumento} onChange={(event) => setNumeroDocumento(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data do Documento*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" name="dataDocumento" value={dataDocumento} onChange={(event) => setDataDocumento(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>N° da Ordem Bancária*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="numOrdemBancaria" value={ordemBancaria} onChange={(event) => setOrdemBancaria(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data do Movimento*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" name="dataOrdemBancaria" value={dataOrdemBancaria} onChange={(event) => setDataOrdemBancaria(event.target.value)} required />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Fonte do Recurso</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="fonte" id="fonte" value={fonte} onChange={handleSelectFonte} disabled={fonteDisabled}>
                                    <option value="0">Selecione a Fonte</option>
                                    <option value="1">1 - Concedente</option>
                                    <option value="2">2 - Convenente</option>
                                    <option value="3">3 - Executor</option>
                                    <option value="4">4 - Interveniente</option>
                                    <option value="5">5 - Rendimentos</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Elemento de Despesa</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="elemento" id="elemento" value={elementoEscolhido} onChange={handleSelectElemento} disabled={elementoDisabled}>
                                    <option value="">Selecione a Meta</option>
                                    {elementos.length > 0 &&
                                        elementos.map((opcao) => (
                                            <option key={opcao.CodigoConvenioElemento} value={opcao.CodigoConvenioElemento}>{opcao.CodigoElemento} - {opcao.Elemento.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>

                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Meta</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="Meta" id="Meta" value={metaEscolhida} onChange={handleSelectMeta} disabled={metaDisabled}>
                                    <option value="">Selecione a Meta</option>
                                    {metas.length > 0 &&
                                        metas.map((opcao) => (
                                            <option key={opcao.CodigoMeta} value={opcao.CodigoMeta}>{opcao.NumMeta} - {opcao.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Fase</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="Fase" id="Fase" value={faseEscolhida} onChange={handleSelectFase}
                                    disabled={!metaEscolhida} // Desabilita o select se metaEscolhida for null
                                >
                                    <option value="">Selecione a Fase</option>
                                    {fases.length > 0 &&
                                        fases.map((opcao) => (
                                            <option key={opcao.CodigoConvenioFase} value={opcao.CodigoConvenioFase}>{opcao.NumeroFase} - {opcao.Nome}</option>
                                        ))}
                                </Form.Select>
                            </Col>
                            <Col md={1}>
                                {/* <Form.Label style={{ color: 'transparent' }}>.</Form.Label> */}
                                <br></br>
                                <button type="button" className={StyleForm.btn_reiniciar} onClick={ReiniciarConsultaSaldo}><TfiReload /></button>
                            </Col>
                            {/* <Col>

                                <br></br>
                                <button className={StyleForm.saldo_btn} type="button" onClick={(event) => ConsultaSaldo()}>Consultar Saldo</button>
                            </Col> */}

                        </Row>
                        <Row>
                            <Col>
                                {/* {console.log(alertaSaldo)}
                                                {console.log(avisoSaldo)} */}
                                {alertaSaldo && (
                                    <div className={StyleForm.avisoSaldo}>
                                        <p>{avisoSaldo}</p>
                                    </div>
                                )}

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Observações</Form.Label>
                                <Form.Control className={StyleForm.form_control} name="obs" id="obs" value={observacao} onChange={(event) => setObservacao(event.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            {btGravar === true ? (<button className={StyleForm.btn} type="submit">Confirmar Pagamento</button>) : (null)}
                        </Row>
                    </FormGroup>

                </Form>
            </div>

            <Modal show={loading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={showModalOk} onLoad={handleClose} className={StyleForm.Modal} centered>
                <Modal.Body className={StyleForm.Modal_body}>
                    <img src={check_circle}></img>
                    <p>Gravado com Sucesso!</p>
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={StyleForm.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

            {/* <Modal show={errotipoContratação} className={StyleForm.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErroTipoContratação} className={StyleForm.Modal_header}>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <p>Selecione um tipo de contratação</p>
                </Modal.Body>
            </Modal> */}

            {login && (<Navigate to="/" replace={true} />)}
        </div>
    )
}
export default PagamentosSemSolic