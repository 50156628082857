import React from "react";
import StyleForm from '../Paginas/FormCadastroFornecedor/CadFornecedor.module.css';
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from "react";
import InputMask from 'react-input-mask';
import { cnpj } from 'cpf-cnpj-validator';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Navigate } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import { Modal } from 'react-bootstrap';
//import check_circle from "../../img/check_circle.gif"
import urlAPIlocal from "../../global";
import loadingGif from '../img/icons8-spinning-circle.gif';
import { Table } from 'react-bootstrap';
import { AiFillPlusCircle } from 'react-icons/ai';
import { TiDelete } from "react-icons/ti";
import MenuArea from "../Layout/MenuArea"
import Menu from "../Layout/Menu";
import { FaLayerGroup } from "react-icons/fa";
import { PiIdentificationBadge } from 'react-icons/pi';


function CadSolicitacao() {

    const [login, setLogin] = useState(false);
    var wTokenLocal = localStorage.getItem("token");
    var wtokenBearer = "Bearer " + localStorage.getItem("token");
    var wPerfil = localStorage.getItem("CodigoPerfil");
    var wNomeUsuario = localStorage.getItem("NomeUsuario");


    const [credores, setCredores] = useState([]);
    const [credorEscolhido, setCredorEscolhido] = useState(0);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [CadastroOK, setCadastroOK] = useState(false);
    const [cadastroNovo, setCadastroNovo] = useState(false);
    const [gravando, setGravando] = useState(false);
    const [opcaoSelecionada, setOpcaoSelecionada] = useState('');
    const [processoEscolhido, setProcessoEscolhido] = useState(0);
    const [processos, setProcessos] = useState([]);
    const [responsavel, setResponsavel] = useState('');
    const [contato, setContato] = useState('');
    const [enderecoEntrega, setEnderecoEntrega] = useState('');
    const [quemrecebe, setQuemRecebe] = useState('');
    const [especificacao, setEspecificacao] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [descricao, setDescricao] = useState('');
    const [avisoItens, setAvisoItens] = useState(false);
    const [itens, setItens] = useState([]);
    const [justificativa, setJustificativa] = useState('');
    const [obs, setObs] = useState('');
    const [semItem, SetSemItem] = useState(false);
    const [semProjetoOuSemTipo, SetSemProjetoOuSemTipo] = useState(false);
    const [codConvenioElemento, setCodConvenioElemento] = useState(0);


    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [valorAdiantamento, setValorAdiantamento] = useState('');

    const [credoresAdiantamento, setCredoresAdiantamento] = useState([]);
    const [isModalNovoCredorOpen, setIsModalNovoCredorOpen] = useState(false);
    const [NumeroCpf, setNumeroCpf] = useState('');
    const [nome, setNome] = useState('');
    const [ddd, setDdd] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [nomeBanco, setNomeBanco] = useState('');
    const [agencia, setAgencia] = useState('');
    const [NumeroConta, setNumeroConta] = useState('');
    const [tipoConta, setTipoConta] = useState('');
    const [avisoTipoConta, setAvisoTipoConta] = useState(false);
    const [pix, setPix] = useState('');
    const [avisoCPF, setAvisoCPF] = useState(false);
    const [setor, setSetor] = useState(0);


    const getProcessos = async () => {
        //setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ProcessoInterno',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const data = response.data;
                setProcessos(data.objetoRetorno);
                //setLoading(false);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                //setLoading(false);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getProcessos();
        // eslint-disable-next-line
    }, []);

    const [modalDuploCheck, setModalDuploCheck] = useState(false);
    const [senha, setSenha] = useState('');
    const [avisoDuploCheck, setAvisoDuploCheck] = useState(false);
    const [avisoSenhaOk, setAvisoSenhaOk] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 800 || window.innerHeight < 900) {
                setIsMobile(true);
                setIsOpen(false);
            } else {
                setIsMobile(false);
            }
        };

        const handleOrientationChange = () => {
            if (window.innerWidth < 800 || window.innerHeight < 800) {
                setIsMobile(true);
                setIsOpen(false);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    const handleMenu = () => {

        setIsOpen(!isOpen);

    };

    useEffect(() => {
        //console.log(itens);
    }, [itens]);

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);



    const getCadastrosAprovados = async () => {

        try {
            const wtoken = "Bearer " + localStorage.getItem("token");

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Cadastro?Aprovado=1&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config);
            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data);

            const cadastrosFiltrados = data.filter(item => item.CodigoTipoCadastro === 33 || item.CodigoTipoCadastro === 43);
            //console.log(cadastrosFiltrados);
            setCredores(cadastrosFiltrados);

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getCadastrosAprovados();
        // eslint-disable-next-line
    }, []);


    function GravaItens(wCodSolicitacao, wNome, wQuantidade, wNumitem, wValor) {
        //console.log(wValor);
        /* let wValGravar = wValor.toString()
        wValGravar = wValGravar.replace('R$', '')
        //console.log(wValGravar);

        var vUnitarioFormatadoPut = 0;
        var wValTotal = 0;
        let wUnitarioDecimal = 0
        if (wValGravar !== '0') {
            vUnitarioFormatadoPut = wValGravar.toString();
            vUnitarioFormatadoPut = vUnitarioFormatadoPut.replace(',', '.');
            wUnitarioDecimal = vUnitarioFormatadoPut;
            var wValTotal = vUnitarioFormatadoPut * wQuantidade
            //wValTotal = wValTotal.toString()
        } else {
            vUnitarioFormatadoPut = 0;
            wValTotal = 0;
        } */

        const valorMoeda = wValor;
        const valorDecimal = converterMoedaParaDecimal(valorMoeda);
        //console.log(valorDecimal);

        if (valorDecimal !== null) {
            wValor = valorDecimal;
        } else {
            wValor = 0;
        }

        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var data = JSON.stringify({
            "CodigoSolicitacaoItem": 0,
            "CodigoSolicitacao": wCodSolicitacao,
            "NumeroItem": wNumitem,
            "Nome": wNome,
            "Unidade": "UN.",
            "Quantidade": wQuantidade,
            "ValorUnitario": wValor,
            "ValorTotal": wValor,
            "CodigoFinanceiro": 0,
            "Patrimonio": "",
            "Localizacao": "",
            "CodCadastro": 0,
            "Pago": false,
            "Frete": 0

        });
        //console.log(data)
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setCadastroOK(false);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    async function BuscaProcessoAdiantamento(wCodConvenio) {
        let wCodProcesso = 0;
        console.log(wCodConvenio);
        const wtoken = "Bearer " + wTokenLocal;

        try {
            const response = await axios({
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/ProcessoInterno?CodigoConvenio=' + wCodConvenio + '&IsInclude=false',
                headers: {
                    'Authorization': wtoken,
                    'Content-Type': 'application/json'
                },
            });




            const data = response.data;
            //console.log(data.objetoRetorno);
            //busca nos registros o titulo que tem Adiantamento
            const registrosFiltrados = data.filter(registro => registro.titulo.toLowerCase().includes('adiantamento'));
            console.log(registrosFiltrados);


            wCodProcesso = registrosFiltrados[0].CodigoProcessoInterno;
        } catch (error) {
            console.log(error);
            // setLoading(false);
            // console.log(JSON.stringify(response.data.mensagemRetorno));
            // Se o token for inválido, redirecione para o login
            if (error.response && error.response.status === 401) {
                setLogin(true);
            }
        }
        //console.log(wCodProcesso);

        return wCodProcesso;
    }

    function converterMoedaParaDecimal(valorMoeda) {
        // Remove caracteres não numéricos e extrai apenas os dígitos e ponto
        const valorNumerico = valorMoeda.replace(/[^\d,]/g, '');

        // Substitui a vírgula por ponto e converte para número
        const valorDecimal = parseFloat(valorNumerico.replace(',', '.'));

        // Verifica se o resultado é um número
        if (!isNaN(valorDecimal)) {
            return valorDecimal;
        } else {
            console.error("Não foi possível converter a moeda para decimal.");
            return null;
        }
    }

    const DuploCheck = async (event) => {
        event.preventDefault();
        setLoading(true);

        var CodigoUsuario = localStorage.getItem('CodigoUsuario');
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + `/api/Usuarios?CodigoUsuario=${CodigoUsuario}&IsInclude=false`,
            headers: {
                'Authorization': "Bearer " + wTokenLocal,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data.objetoRetorno;

                if (senha === data[0].SenhaAssinatura) {
                    setModalDuploCheck(false);
                    setAvisoDuploCheck(false);
                    setAvisoSenhaOk(true);
                    setSenha('');
                    handleSubmitComSenha();

                } else {
                    setModalDuploCheck(true);
                    setAvisoDuploCheck(true);
                    setAvisoSenhaOk(false);
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);

            });
    };

    const closeAndResetModalDuploCheck = () => {
        setModalDuploCheck(false);
        setAvisoDuploCheck(false);
        setAvisoSenhaOk(false);
        setSenha('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        setBotaoDesabilitado(true);
        //console.log(mensagemremetente)

        //verifica senha antes de prosseguir
        if (opcaoSelecionada !== '') {
            setLoading(false);
            SetSemProjetoOuSemTipo(false);

            if (avisoSenhaOk === true) {
                handleSubmitComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setLoading(false);
            SetSemProjetoOuSemTipo(true);
        }
    }

    async function handleSubmitComSenha() {

        setLoading(true);

        let wAdiantamento = opcaoSelecionada === 'Adiantamento';
        let wRh = opcaoSelecionada === 'Rh';
        let wCompras = opcaoSelecionada === 'Compra'
        let wServicosPF = opcaoSelecionada === 'Servicos'
        //console.log(wAdiantamento);
        /* if (wAdiantamento) {
            handleAddItemAdiantamento();
        }; */

        //console.log('entrouaqui');
        if (itens.length > 0) { //|| opcaoSelecionada === 'Adiantamento'
            SetSemItem(false);
            //setBotaoDesabilitado(true);

            const date = new Date();
            const dataSolicitacao = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

            let wObs = ""
            let wValorAdiantamento = 0
            console.log(valorAdiantamento);
            if (wAdiantamento) {
                wObs = "<strong>Tipo de Despesa:</strong> " + opcaoSelecionada + "<br><strong>Responsável:</strong> " + responsavel +
                    "<br><strong>Contato:</strong> " + contato + "<br><strong>Quem vai receber o adiantamento:</strong> " +
                    quemrecebe + "<br><br><strong>Objetivo:</strong> <br> " + especificacao + "<br>";

                const valorMoeda = valorAdiantamento;
                const valorDecimal = converterMoedaParaDecimal(valorMoeda);
                //console.log(valorDecimal);

                if (valorDecimal !== null) {
                    wValorAdiantamento = valorDecimal;
                } else {
                    wValorAdiantamento = 0;
                }

            } else {
                wObs = "<strong>Tipo de Despesa:</strong> " + opcaoSelecionada + "<br><strong>Responsável:</strong> " + responsavel +
                    "<br><strong>Contato:</strong> " + contato + "<br><strong>Endereço de Entrega:</strong> " + enderecoEntrega + "<br><strong>Quem vai receber:</strong> " +
                    quemrecebe + "<br><br><strong>Detalhes e Especificações:</strong> <br> " + especificacao + "<br>";
                wValorAdiantamento = 0;
            }

            const wJustificativa = justificativa + "<br><br><strong>Observações Adicionais:</strong> " + obs;
            //console.log(codConvenioElemento);
            const wCodConvenioElemento = codConvenioElemento;
            let wPerfilAtual = 0;
            console.log(processoEscolhido)
            let wCodProcesso = processoEscolhido;
            if (wAdiantamento) {
                wPerfilAtual = 53; // Financeiro
                console.log(localStorage.getItem("CodigoProjeto"));
                wCodProcesso = await BuscaProcessoAdiantamento(localStorage.getItem("CodigoProjeto"));
                //console.log('uma linha apos busca processo ' + wCodProcesso);
            } else if (wRh) {
                if (setor === 0) { wPerfilAtual = 52 } else { wPerfilAtual = setor };; //Rh
            } else if (wCompras) {
                if (setor === 0) { wPerfilAtual = 8 } else { wPerfilAtual = setor };  // Compras
            } else if (wServicosPF) {
                if (setor === 0) { wPerfilAtual = 54 } else { wPerfilAtual = setor };
            }

            var wtokenBearer = "Bearer " + localStorage.getItem("token")
            var data = JSON.stringify({
                "CodigoSolicitacao": 0,
                "DataSolicitacao": dataSolicitacao,
                "NumeroSolicitacao": 0,
                "CodigoFonte": 0,
                "CodigoConvenio": localStorage.getItem("CodigoProjeto"), //projetoEscolhido,
                "CodigoCredor": wAdiantamento ? credorEscolhido : 151,
                "CodigoElemento": wCodConvenioElemento,
                "CodigoMeta": 0,
                "CodigoFase": 0,
                "ValorTotal": wValorAdiantamento,
                "IndFinalizada": false,
                "Pago": false,
                "Observacao": wObs,
                "CodigoAprovacaoCampus": 0,
                "CodigoAprovacaoDARDC": 0,
                "CodigoAprovacaoConvenente": 0,
                "CodigoAprovacaoDPCC": 0,
                "NumeroParecer": 0,
                "DataParecer": "1900-01-01T00:00:00.000Z",
                "ValorLiquido": wValorAdiantamento,
                "ValorISS": 0,
                "ValorINSS": 0,
                "ValorIRRF": 0,
                "ValorINSS20": 0,
                "CodigoSolicitacaoMae": 0,
                "ValorDesconto": 0,
                "DataPagamento": "1900-01-01T00:00:00.000Z",
                "Issp": false,
                "Inssp": false,
                "Irrfp": false,
                "Inss20p": false,
                "DataISS": "1900-01-01T00:00:00.000Z",
                "DataINSS": "1900-01-01T00:00:00.000Z",
                "DataIRRF": "1900-01-01T00:00:00.000Z",
                "DataINSS20": "1900-01-01T00:00:00.000Z",
                "NomeISS": "",
                "NomeINSS": "",
                "NomeIRRF": "",
                "NomeINSS20": "",
                "ReciboDet": "",
                "ReciboDatDe": "",
                "ReciboDataTe": "",
                "ReciboHoras": "",
                "ReciboBaseInss": "",
                "ReciboBaseIrrf": "",
                "NumeroContrato": "",
                "Indeferida": false,
                "Regularizada": false,
                "Excluida": false,
                "Justificativa": wJustificativa,
                "Setor": 0,
                "ValorOutrosImpostos": 0,
                "OutrosImpostos": false,
                "DataOutrosImpostos": "1900-01-01T00:00:00.000Z",
                "NomeOutrosImpostos": "",
                "DataRPA": "1900-01-01T00:00:00.000Z",
                "MeioTransporte": "",
                "TipoLicitacao": "",
                "PerfilDestinoAtual": wPerfilAtual,
                "CodigoProcessoInterno": wCodProcesso
            });

            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Solicitacao',
                headers: {
                    'Authorization': wtokenBearer,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //console.log(data)
            axios(config)

                .then(function (response) {

                    //console.log(response.data)
                    if (response.data.isOk === true) {
                        itens.forEach((cadaItem, index) => {
                            const numeroItem = index + 1;
                            GravaItens(response.data.objetoRetorno.CodigoSolicitacao, cadaItem.descricao, cadaItem.quantidade, numeroItem, valorAdiantamento);
                        });
                        gravaRegistroStatus(response.data.objetoRetorno.CodigoSolicitacao, response.data.objetoRetorno.DataSolicitacao);
                        setBotaoDesabilitado(false);
                        //limpa campos
                        //setProjetoEscolhido('');
                        setOpcaoSelecionada('');
                        setResponsavel('');
                        setContato('');
                        setEnderecoEntrega('');
                        setQuemRecebe('');
                        setEspecificacao('');
                        setJustificativa('');
                        setItens([]);
                        setObs('');
                        setCadastroOK(true);
                        setGravando(false);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                    setLoading(false);
                });
        } else {
            SetSemItem(true);
        }
    }


    const handleFechaGravando = (event) => {
        if (CadastroOK === true) { setGravando(false) };
    }

    const handleOpcaoChange = (event) => {
        setOpcaoSelecionada(event.target.value);
        if (event.target.value === 'Adiantamento') { handleAddItemAdiantamento(); } else { setItens([]); }
    };

    const handleSelectProcesso = (event) => {
        setProcessoEscolhido(event.target.value);
    };

    const handleItem = () => {
        //console.log(descricao);
        //console.log(quantidade);

        if (descricao !== '' && quantidade !== '') {
            setAvisoItens(false);
            handleAddItem();
        } else { setAvisoItens(true); }
    }

    const handleAddItem = () => {
        const novoItem = {
            quantidade: quantidade,
            descricao: descricao
        };

        setItens([...itens, novoItem]);
        setQuantidade('');
        setDescricao('');
        SetSemItem(false);
    }

    const handleAddItemAdiantamento = () => {
        const novoItem = {
            quantidade: 1,
            descricao: 'Adiantamento para despesas em geral'
        };

        setItens([...itens, novoItem]);
        //setQuantidade('');
        //setDescricao('');
        SetSemItem(false);
    }

    const ExcluirItem = (wIndex) => {
        const novaListaItens = itens.filter((item, index) => index !== wIndex);
        setItens(novaListaItens);
    };

    /* const ExcluirTodosItens = () => {
        var wContaitens = itens.length;
        const novaListaItens = []
        for (var i = 0; i < wContaitens; i++) {
            var itemEncontrado = itens[i];
            // Faça algo com 'item' aqui
            novaListaItens = itens.filter((itemEncontrado, index) => index !== itemEncontrado);
        }
        setItens(novaListaItens);
    };
 */

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) { // Verifica se a tecla pressionada é o "Enter"
            setDescricao(descricao + '\n'); // Adiciona uma quebra de linha ao texto
            event.preventDefault(); // Previne a inserção de uma nova linha no textarea
        }
    };

    const [nomeProjeto, setNomeProjeto] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                const wtoken = "Bearer " + localStorage.getItem("token");

                const config = {
                    method: "GET",
                    url: urlAPIlocal + "/api/Convenio?CodigoConvenio=" + localStorage.getItem("CodigoProjeto") + "&IsInclude=true",
                    headers: {
                        Authorization: wtoken,
                        "Content-Type": "application/json",
                    },
                };

                const response = await axios(config);
                const data = response.data.objetoRetorno;
                //console.log(data);
                const Retorno = data[0].Nome;

                setNomeProjeto(Retorno);

                PegaCodConvenioElemento();
                //setLoading(false);

            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, []);

    const PegaCodConvenioElemento = async () => {

        try {
            const wtoken = "Bearer " + localStorage.getItem("token");
            const codigoConvenio = localStorage.getItem("CodigoProjeto");

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/ConvenioElemento?CodigoConvenio=" + codigoConvenio + "&CodigoElemento=100&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config);
            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data[0].CodigoConvenioElemento);
            setCodConvenioElemento(data[0].CodigoConvenioElemento);


        } catch (error) {
            console.log(error)
            if (error.response.status === 401) {
                setLogin(true);
            }
        }
    };

    function gravaRegistroStatus(wCodigoSolicitacao, wDataSolicitacao) {

        let wAdiantamento = opcaoSelecionada === 'Adiantamento';
        var wCodigoAprovador = localStorage.getItem("CodigoUsuario");
        var wCodigoUsuario = localStorage.getItem("CodigoUsuario");

        var wMensagemNovaStatus = '<br><br><br><strong>Solicitação Criada Por: </strong>' + wNomeUsuario;

        var data = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": wCodigoSolicitacao,
            "CodigoAprovador": wCodigoAprovador,
            "DataAprovacao": wDataSolicitacao,
            "status": true,
            "Motivo": wMensagemNovaStatus,
            "CodigoPerfil": wCodigoUsuario,
            "Doc": "",
            "PerfilDestino": wAdiantamento ? 53 : 8,
            "CodCadastro": 155,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(data)
        axios(config)

            .then(function (response) {
                //console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }


    /*  const handleValorChange = (event) => {
         //const { formattedValue, value } = values;
         setValorAdiantamento(event.target.value);
         // Você também pode usar o valor não formatado (value) se necessário.
     }; */

    const handleValorChange = (e) => {

        const valorDigitado = e.target.value.replace(/[^\d]/g, ''); // Remove caracteres não numéricos
        setValorAdiantamento(formatarMoeda(valorDigitado));


    };

    const formatarMoeda = (valor) => {
        if (!valor) return '';

        const valorFormatado = (Number(valor) / 100).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return valorFormatado;
    };

    const handleSelectCredor = (event) => {
        setCredorEscolhido(event.target.value);
        if (event.target.value === '99999') {
            setIsModalNovoCredorOpen(true);
        } else {
            setIsModalNovoCredorOpen(false);
        }
    };
    const handleCloseModalNovoCredor = () => {
        setIsModalNovoCredorOpen(false);
        setNumeroCpf('');
        setNome('');
        setDdd('');
        setTelefone('');
        setEmail('');
        setNomeBanco('');
        setTipoConta('');
        setAgencia('');
        setNumeroConta('');
        setPix('');
    };

    const getCadastroCredor = async () => {

        try {
            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/Cadastro?Aprovado=1&IsInclude=true",
                headers: {
                    Authorization: wtokenBearer,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config);
            const response = await axios(config);
            const data = response.data.objetoRetorno;
            const cadastrosFiltrados = data.filter(item => item.CodigoTipoCadastro === 43 && item.CodigoCadastro !== 94);
            //console.log(cadastrosFiltrados);
            // Encontrando um registro específico com CodigoCadastro igual a 200 na lista filtrada
            //const registroEspecifico = data.find(item => item.CodigoCadastro === 200);
            //console.log(registroEspecifico);
            // Adicionando o registro específico à lista final se ele existir
            //const listaFinal = registroEspecifico ? [...cadastrosFiltrados, registroEspecifico] : cadastrosFiltrados;
            setCredoresAdiantamento(cadastrosFiltrados);
            //console.log(listaFinal);
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getCadastroCredor();
        // eslint-disable-next-line
    }, []);

    // function handleCadastrarCredor() {
    //     setCadastroNovo(true);
    //     handleGravaCredor();
    // };

    const handleGravaCredor = (event) => {
        event.preventDefault();

        //verifica se selecionar tipo da conta que é obrigatorio
        if (tipoConta === "") {
            setAvisoTipoConta(true);
        } else {
            setAvisoTipoConta(false);
            setBotaoDesabilitado(true);
            setGravando(true);

            const date = new Date();
            const dataFormatada = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

            var wtokenBearer = "Bearer " + localStorage.getItem("token")
            var data = JSON.stringify({
                "CodigoCadastro": 0,
                "NumeroCnpj": NumeroCpf,
                "Nome": nome,
                "TipoPessoa": "F",
                "Sigla": "",
                "Esfera": "",
                "NomeFantasia": "",
                "NomeContato": "",
                "Endereco": "", //endereco + ', ' + numero,
                "Bairro": "", //bairro,
                "NomeCidade": "", //cidade,
                "NomeEstado": "", //estado,
                "NumeroCep": "", //cep,
                "ComplementoEnd": "", //ComplementoEnd,
                "Pais": "", //pais,
                "NumeroDdd": ddd,
                "NumeroTelefone": telefone,
                "Ddd2": "",
                "Telefone2": "",
                "NumeroCelular": "",
                "Email": {
                    "Endereco": email
                },
                "Website": "",
                "Fax": "",
                "NumeroRg": "", //NumeroRg,
                "OrgaoExpedidor": "",
                "DataExpedicao": "1900-01-01T00:00:00.000Z",
                "Cargo": "",
                "Funcao": "",
                "NumeroMatricula": "",
                "CodigoDirigente": 0,
                "CodigoTipoCadastro": 43,
                "DataEmissao": dataFormatada,
                "NomeBanco": nomeBanco,
                "NumeroBanco": "",
                "Pix": pix,
                "NumeroAgencia": agencia,
                "NumeroConta": NumeroConta,
                "NomeConta": "",
                "Imagem": "",
                "NumeroPis": "", //NumeroPis,
                "Nacionalidade": "", //nacionalidade,
                "Aprovado": 1,
                "TipoConta": tipoConta,
                "EstadoCivil": "", //estadoCivil,
                "Servidor": false,
                "DataNascimento": "1900-01-01T00:00:00.000Z", //moment(dataNascimento).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                "CnpjCpfConta": "",
                "TitularConta": "",
                "NumeroInscricaoEstadual": "",
                "NumeroInscricaoMunicipal": "",
                "Atividade": "",
                "cnae": "",
                "SimplesNacional": false,
                "observacao": "", //observacao,
                "PerfilDestinoAtual": 0,

            });

            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/cadastro',
                headers: {
                    'Authorization': wtokenBearer,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //console.log(config)
            axios(config)

                .then(function (response) {

                    //console.log(response.data)
                    if (response.data.isOk === true) {
                        // fez ao consumo sem erro
                        // pega o codigo cadastrado
                        //var wCodCadastroNovo = response.data.objetoRetorno.CodigoCadastro

                        setNumeroCpf('');
                        setNome('');
                        setDdd('');
                        setTelefone('');
                        setEmail('');
                        setNomeBanco('');
                        setTipoConta('');
                        setAgencia('');
                        setNumeroConta('');
                        setPix('');

                        setGravando(false);
                        getCadastroCredor();
                        setCredorEscolhido(response.data.objetoRetorno.CodigoCadastro);
                        setBotaoDesabilitado(false);
                        setIsModalNovoCredorOpen(false);
                        setAvisoCPF(false);


                    } else {
                        //deu algum erro tratar , colocar algum aviso
                        setCadastroOK(false);
                        setGravando(false);
                        setAvisoCPF(true);
                        setBotaoDesabilitado(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    // const handleFechaGravando = (event) => {
    //     if (CadastrOk === true) { setGravando(false) };

    //     //const [login, setLogin] = useState(false);

    // }

    const handleSelectSetor = (event) => {
        setSetor(event.target.value);

        //const selectedOption = event.target.options[event.target.selectedIndex];
        //const selectedOptionText = selectedOption.text;
        //setNomeSetor(selectedOptionText);
    }



    return (
        <>
            {wPerfil !== '37' ? <Menu /> : <MenuArea />}
            <div className={StyleForm.page}>
                <div className={StyleForm.letreiro}>
                    <h3>Pedido de Compras, Contratações e Adiantamentos</h3>
                </div>

                <Form className={StyleForm.container} onSubmit={handleSubmit}>
                    <header className={StyleForm.container_header}>Projeto</header>
                    <FormGroup className={StyleForm.form_group_cnpj}>
                        <Row>
                            <Col>
                                {/* <Form.Label className={StyleForm.form_group_label}> Escolha o Projeto</Form.Label> */}
                                <div className={StyleForm.form_group_label}>{nomeProjeto}</div>
                                {/* <Form.Select className={StyleForm.form_control} name="projetoescolhidos" id="projetoescolhido" value={projetoEscolhido || ''} onChange={handleSelectProjeto}>
                                            <option value="">Selecione um projeto</option>
                                            {projetos !== null &&
                                                projetos.map((opcao) => (
                                                    <option key={opcao.Nome} value={opcao.CodigoConvenio}>
                                                        {opcao.Nome}
                                                    </option>
                                                ))}
                                        </Form.Select> */}
                            </Col>
                        </Row>
                    </FormGroup>
                    <header className={StyleForm.container_header}>Tipo de Despesa</header>
                    <FormGroup className={StyleForm.form_group_cnpj}>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}> Escolha o Tipo de Despesa</Form.Label>
                                <Row>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            id="Compra"
                                            name="Compra"
                                            label="Pedido de Compra"
                                            value="Compra"
                                            checked={opcaoSelecionada === 'Compra'}
                                            onChange={handleOpcaoChange}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            id="Servicos"
                                            name="Servicos"
                                            label="Contratação de Serviços PJ"
                                            value="Servicos"
                                            checked={opcaoSelecionada === 'Servicos'}
                                            onChange={handleOpcaoChange}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="radio"
                                            id="Adiantamento"
                                            name="Adiantamento"
                                            label="Pedido de Adiantamento"
                                            value="Adiantamento"
                                            checked={opcaoSelecionada === 'Adiantamento'}
                                            onChange={handleOpcaoChange}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Form.Check
                                            type="radio"
                                            id="Rh"
                                            name="Rh"
                                            label="Contratação de Pessoal"
                                            value="Rh"
                                            checked={opcaoSelecionada === 'Rh'}
                                            onChange={handleOpcaoChange}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </FormGroup>
                    {/* {console.log('perfil ' + wPerfil + ' opcaoselecionada ' + opcaoSelecionada)} */}
                    {wPerfil !== 37 && opcaoSelecionada !== 'Adiantamento' ? (
                        <>
                            <header className={StyleForm.container_header}>Processo Interno</header>
                            <FormGroup className={StyleForm.form_group_cnpj}>
                                <Row>
                                    <Col>
                                        <Form.Label className={StyleForm.form_group_label}>Caso a solicitação seja a execução de um Processo Interno, selecione o processo</Form.Label>
                                        <Form.Select className={StyleForm.form_control} name="processoescolhido" id="processoescolhido" value={processoEscolhido} onChange={handleSelectProcesso}>
                                            <option value="">Selecione um processo</option>
                                            {processos !== null &&
                                                processos.map((opcao) => (
                                                    <option key={opcao.CodigoProcessoInterno} value={opcao.CodigoProcessoInterno}>
                                                        {opcao.Titulo}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={StyleForm.form_group_label}>Selecione quem vai receber essa Carga (Solicitação)</Form.Label>
                                        <Form.Select name="setor" id="setor" value={setor} onChange={handleSelectSetor} style={{ width: '300px', color: '#707070' }} required>
                                            <option value="0">Selecione um Setor</option>
                                            <option value="26">Recepção</option>
                                            <option value="17">Compras</option>
                                            <option value="23">Prestação de Contas</option>
                                            <option value="38">Financeiro</option>
                                            <option value="31">Assessoria Jurídica</option>
                                            <option value="25">Assessoria Administrativa</option>
                                            <option value="24">Diretoria Administrativa</option>
                                            <option value="28">Diretor Presidente</option>
                                            <option value="32">Recursos Humanos</option>
                                            <option value="33">Secretário Executivo</option>
                                            <option value="34">Consultor</option>
                                            <option value="35">TI</option>
                                            <option value="36">Gestao de Projetos</option>
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </>
                    ) : null}



                    <header className={StyleForm.container_header}>Dados do Solicitante</header>
                    <FormGroup className={StyleForm.form_group_cnpj}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Form.Label className={StyleForm.form_group_label}>Responsável</Form.Label>
                                        <Form.Control className={StyleForm.form_control} type="text" name="Responsavel" value={responsavel} onChange={(event) => setResponsavel(event.target.value)} required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={StyleForm.form_group_label}>Contato (E-mail ou Telefone)</Form.Label>
                                        <Form.Control className={StyleForm.form_control} type="text" name="Contato" value={contato} onChange={(event) => setContato(event.target.value)} required />
                                    </Col>
                                </Row>
                                {opcaoSelecionada !== 'Adiantamento' ?
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Label className={StyleForm.form_group_label}>Endereço de Entrega</Form.Label>
                                                <Form.Control className={StyleForm.form_control} as="textarea" name="EnderecoEntrega" value={enderecoEntrega} onChange={(event) => setEnderecoEntrega(event.target.value)} required />
                                            </Col>
                                        </Row>
                                    </> : null
                                }
                                <Row>
                                    <Col>
                                        {opcaoSelecionada !== 'Adiantamento' ?
                                            <>
                                                <Form.Label className={StyleForm.form_group_label}>Quem vai Receber</Form.Label>
                                                <Form.Control className={StyleForm.form_control} as="textarea" name="QuemRecebe" value={quemrecebe} onChange={(event) => setQuemRecebe(event.target.value)} required />
                                            </> :
                                            <>
                                                <Form.Label className={StyleForm.form_group_label}>Quem vai Receber o Adiantamento</Form.Label>
                                                <Form.Select className={StyleForm.form_control} name="Credor" id="Credor" value={credorEscolhido} onChange={handleSelectCredor} required >
                                                    <option value="0">Selecione uma credor</option>
                                                    {credoresAdiantamento.length > 0 &&
                                                        credoresAdiantamento.map((opcao) => (
                                                            <option key={opcao.CodigoCadastro} value={opcao.CodigoCadastro}>{opcao.Nome}</option>
                                                        ))}
                                                    <option value="99999">OUTRO</option>
                                                </Form.Select>

                                            </>
                                        }

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </FormGroup>
                    <header className={StyleForm.container_header}>
                        {opcaoSelecionada === 'Compra'
                            ? 'Especificação da Compra'
                            : opcaoSelecionada === 'Servicos'
                                ? 'Especificação da Contratação do Serviço'
                                : opcaoSelecionada === 'Adiantamento'
                                    ? 'Objetivo e Justificativa do Pedido'
                                    : ''}</header>
                    <FormGroup className={StyleForm.form_group_cnpj}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                {opcaoSelecionada !== 'Adiantamento' ?
                                                    <>
                                                        <Form.Label className={StyleForm.form_group_label}>Detalhes e Especificações</Form.Label>
                                                    </> :
                                                    <>
                                                        <Form.Label className={StyleForm.form_group_label}>Objetivo</Form.Label>
                                                    </>
                                                }
                                                <Form.Control placeholder="(INFORMAR DETALHES DAS DESPESAS QUE SERÃO EFETUADAS COM O ADIANTAMENTO.)"
                                                    className={StyleForm.form_control_textarea}
                                                    as="textarea"
                                                    name="especificacao"
                                                    value={especificacao}
                                                    onChange={(event) => setEspecificacao(event.target.value)}
                                                    required />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label className={StyleForm.form_group_label}>Justificativa</Form.Label>
                                                <Form.Control placeholder="O MOTIVO DAS DESPESAS, PARA QUAL AÇÃO É NECESSARIA, ETC."
                                                    className={StyleForm.form_control_textarea}
                                                    as="textarea"
                                                    name="justificativa"
                                                    value={justificativa}
                                                    onChange={(event) => setJustificativa(event.target.value)}
                                                    required />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </FormGroup>
                    {opcaoSelecionada !== 'Adiantamento' ?
                        <>
                            <header className={StyleForm.container_header}>Cadastro dos itens</header>
                            <FormGroup className={StyleForm.form_group_cnpj}>
                                <Row>
                                    <Col md={1}>
                                        <Form.Label className={StyleForm.form_group_label}>Quantidade</Form.Label>
                                        <Form.Control className={StyleForm.form_control_textCenter} type="text" value={quantidade} onChange={(event) => setQuantidade(event.target.value)} />
                                    </Col>
                                    <Col md={10}>
                                        <Form.Label className={StyleForm.form_group_label}>Descrição</Form.Label>
                                        <Form.Control className={StyleForm.form_control} as="textarea" value={descricao} onChange={(event) => setDescricao(event.target.value)} onKeyDown={handleKeyDown}></Form.Control>
                                    </Col>
                                    <Col>
                                        <button type='button' className={StyleForm.inserir_btn_Item} onClick={handleItem}>Inserir <AiFillPlusCircle /></button>
                                    </Col>
                                    <Row>
                                        <Col md={1}></Col>
                                        <Col md={9}></Col>
                                        <Col>
                                            {avisoItens && <p className={StyleForm.alert}>Insira pelo menos 1 item.</p>}
                                        </Col>
                                    </Row>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label className={StyleForm.form_group_label}></Form.Label>
                                        <Row>

                                            {itens && itens.length > 0 && (
                                                <Table style={{ width: '90%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '10%', textAlign: 'center' }}>Quantidade</th>
                                                            <th style={{ width: '80%' }}>Descrição</th>
                                                            <th style={{ width: '10%' }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {itens.map((item, index) => (
                                                            <tr key={index}>
                                                                <td style={{ width: '10%', textAlign: 'center' }}>{item.quantidade}</td>
                                                                <td style={{ width: '80%', whiteSpace: 'pre-line' }}>{item.descricao}</td>
                                                                <td style={{ width: '10%', textAlign: 'center' }}>
                                                                    <button
                                                                        type="button"
                                                                        className={StyleForm.btn_delete}
                                                                        onClick={(event) => ExcluirItem(index)}>
                                                                        <TiDelete />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            )}

                                        </Row>
                                    </Col>
                                </Row>

                            </FormGroup>
                        </> :
                        <>
                            <header className={StyleForm.container_header}>Valor do Adiantamento</header>
                            <FormGroup className={StyleForm.form_group_cnpj}>
                                <Row>
                                    <Col md={1}>
                                        <Form.Label className={StyleForm.form_group_label}>Valor:</Form.Label>
                                    </Col>
                                    <Col md={2}>

                                        <Form.Control
                                            className={StyleForm.form_control_textRight}
                                            type="text"
                                            value={valorAdiantamento}
                                            onChange={handleValorChange}
                                        />

                                    </Col>
                                </Row>
                            </FormGroup>
                        </>
                    }

                    <header className={StyleForm.container_header}>Observações e Informações adicionais</header>
                    <FormGroup className={StyleForm.form_group_cnpj}>
                        <Row>
                            <Col>
                                {/* <Form.Label className={StyleForm.form_group_label}>Observações Adicionais</Form.Label> */}
                                <Form.Control placeholder="Caso tenha mais alguma informação ou observação, use esse campo para enviar antes de Enviar Pedido."
                                    className={StyleForm.form_control_textarea}
                                    as="textarea"
                                    name="obs"
                                    value={obs}
                                    onChange={(event) => setObs(event.target.value)} />
                            </Col>
                        </Row>
                    </FormGroup>

                    {<div align="center">{semItem && <span className={StyleForm.alert}>Para enviar o pedido, insira pelo menos um item.</span>}</div>}
                    {<div align="center">{semProjetoOuSemTipo && <span className={StyleForm.alert}>Escolha um Tipo do pedido.</span>}</div>}

                    <Row>
                        <Col xs={8}>
                            <div className={StyleForm.container_termos}>
                            </div>
                        </Col>

                        <Col >
                            <button className={StyleForm.btn} type="submit" disabled={botaoDesabilitado ? true : false}>
                                Enviar pedido
                                {CadastroOK && (<Navigate to="/index" replace={true} />)}
                            </button>
                        </Col>

                    </Row>

                </Form >

                {isModalNovoCredorOpen &&
                    <Modal show={isModalNovoCredorOpen} className={StyleForm.Modal}
                        centered aria-labelledby="example-modal-sizes-title-lg" size="lg">
                        <Modal.Header className={StyleForm.Modal_header} closeButton onClick={handleCloseModalNovoCredor}>
                            <h3>Cadastro de Credor</h3>
                        </Modal.Header>
                        <Modal.Body className={StyleForm.Modal_body}>

                            {avisoCPF && <h6 style={{ color: 'red' }}>CPF inválido.</h6>}

                            <Form onSubmit={handleGravaCredor}>
                                <FormGroup className={StyleForm.form_group_cnpj}>
                                    <Row>
                                        <Col md={3}>
                                            <Form.Label className={StyleForm.form_group_label} >CPF*</Form.Label>
                                            <InputMask className={StyleForm.form_control_cnpj} mask="999.999.999-99" value={NumeroCpf} onChange={(event) => setNumeroCpf(event.target.value)} required />
                                        </Col>
                                        <Col>
                                            <Form.Label className={StyleForm.form_group_label} >Nome*</Form.Label>
                                            <Form.Control className={StyleForm.form_control} type="text" name="nome" value={nome} onChange={(event) => setNome(event.target.value)} required />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Form.Label className={StyleForm.form_group_label}>DDD*</Form.Label>
                                            <Form.Control className={StyleForm.form_control} type="text" name="ddd" value={ddd} onChange={(event) => setDdd(event.target.value)} required />
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label className={StyleForm.form_group_label}>Telefone*</Form.Label>
                                            <Form.Control className={StyleForm.form_control} type="tel" name="telefone" value={telefone} onChange={(event) => setTelefone(event.target.value)} required />
                                        </Col>
                                        <Col>
                                            <Form.Label className={StyleForm.form_group_label}>Email*</Form.Label>
                                            <Form.Control className={StyleForm.form_control} type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} required />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Label className={StyleForm.form_group_label}>Nome do Banco*</Form.Label>
                                            <Form.Control className={StyleForm.form_control} type="text" name="NomeBanco" value={nomeBanco} onChange={(event) => setNomeBanco(event.target.value)} required />
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label className={StyleForm.form_group_label}>Tipo da Conta* {avisoTipoConta && <span style={{ color: '#ff0303d0', fontSize: '14px' }}>Selecione o tipo da conta</span>}</Form.Label>
                                            <Form.Select className={StyleForm.form_control} name="tipoConta" value={tipoConta}
                                                onChange={(event) => {
                                                    setTipoConta(event.target.value);
                                                    if (event.target.value === "") { setAvisoTipoConta(true); } else { setAvisoTipoConta(false); };
                                                }} required>
                                                <option>Selecione uma opção</option>
                                                <option>Conta Corrente</option>
                                                <option>Poupança</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <Form.Label className={StyleForm.form_group_label}>Agencia*</Form.Label>
                                            <Form.Control className={StyleForm.form_control} type="text" name="NumAgencia" value={agencia} onChange={(event) => setAgencia(event.target.value)} required />
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label className={StyleForm.form_group_label}>N° da Conta e Dígito*</Form.Label>
                                            <Form.Control className={StyleForm.form_control} type="text" name="NumeroConta" value={NumeroConta} onChange={(event) => setNumeroConta(event.target.value)} required />
                                        </Col>
                                        <Col>
                                            <Form.Label className={StyleForm.form_group_label}>Chave PIX</Form.Label>
                                            <Form.Control className={StyleForm.form_control} type="text" name="pix" value={pix} onChange={(event) => setPix(event.target.value)} />
                                        </Col>

                                    </Row>

                                    <div align="right">
                                        <button
                                            className={StyleForm.btn}
                                            type="submit"
                                            disabled={botaoDesabilitado ? true : false}
                                        >
                                            Gravar
                                            <AiFillPlusCircle />
                                        </button>
                                    </div>
                                </FormGroup>
                            </Form>
                        </Modal.Body>
                    </Modal>
                }

                <Modal show={modalDuploCheck} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <h3>Confirmação de Identidade </h3><PiIdentificationBadge />
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <p>Por favor, confirme sua senha para prosseguir</p>
                        <Form onSubmit={DuploCheck}>
                            {avisoDuploCheck ? (<p StyleForm={{ color: '#E74C3C' }}>A senha digitada não confere com a senha de usuário</p>) : (null)}
                            <Row>
                                <Col>
                                    <Form.Label className={StyleForm.label_modal}>Sua senha de assinatura:</Form.Label>
                                </Col>
                                <Col md={9}>
                                    <Form.Control className={StyleForm.form_control} type="password" name="senha" value={senha} onChange={(event) => setSenha(event.target.value)} />
                                </Col>
                            </Row>

                            <button type="submit" className={StyleForm.btn_salvar}> Salvar </button>
                            <button type="button" className={StyleForm.btn_cancelar} onClick={closeAndResetModalDuploCheck}> Cancelar </button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={gravando} onLoad={handleFechaGravando} className={StyleForm.Modal} centered>
                    <Modal.Header className={StyleForm.Modal_header}>
                        Carregando...
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <img src={loadingGif}></img>
                    </Modal.Body>
                </Modal>

                <Modal show={loading} className={StyleForm.Modal} centered >
                    <Modal.Body className={StyleForm.Modal_body}>
                        <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                    </Modal.Body>
                </Modal>




            </div >
            {login && (<Navigate to="/" replace={true} />)}
        </>
    )



}
export default CadSolicitacao