import { useParams } from "react-router";
import { useState, useRef } from "react";
import React, { useEffect } from 'react';
import axios from "axios";
import Style from "./VerProcessoNew.module.css";
//import StyleForm from "./VerProcesso.module.css";
import Menu from "../Layout/Menu"
import { Navigate } from "react-router";
import { IoMdArrowBack, IoIosSend } from "react-icons/io"
import { GoLocation } from "react-icons/go"
import { RiPhoneFindLine } from "react-icons/ri";
import { FaFileInvoice } from "react-icons/fa"
import moment from 'moment';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import urlAPIlocal from '../../global';
import Form from 'react-bootstrap/Form';
import { Card, Col, FormGroup, Modal } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { PiIdentificationBadge } from 'react-icons/pi';
import { AiFillCheckCircle, AiFillFilePdf, AiFillEdit } from "react-icons/ai"
import Table from 'react-bootstrap/Table';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { ImSad2 } from 'react-icons/im';
import loadingGif from '../img/icons8-spinning-circle.gif';
import { IoMdPrint } from "react-icons/io"

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ReactQuill from 'react-quill';



function VerProcessoNew() {

    const { CodigoProcessoInterno } = useParams();
    var wPerfil = localStorage.getItem("CodigoPerfil");
    var wUsuario = localStorage.getItem("CodigoUsuario");
    var wNomePerfil = localStorage.getItem("NomePerfil");
    const [login, setLogin] = useState(false);
    const [processo, setProcesso] = useState([]);
    //const [descricaoProcesso, setDescricaoProcesso] = useState('');
    var wTokenLocal = localStorage.getItem("token");
    const [encaminhado, setEncaminhado] = useState(false);
    const fileInputRef = useRef(null);
    const [NovoFile, setNovoFile] = useState(null);
    const [descricaoarquivo, setDescricaoArquivo] = useState('');
    const [mensagemremetente, setMensagemRemetente] = useState('');
    const [mensagemencaminhamento, setMensagemEncaminhamento] = useState('');
    const [setor, setSetor] = useState(0);
    const [nomeSetor, setNomeSetor] = useState('');
    const [selecioneSetor, setSelecioneSetor] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModalErro, setShowModalErro] = useState(false);
    const [arquivo, setArquivo] = useState([]);
    const [encaminharProcesso, setEncaminharProcesso] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [status, setStatus] = useState([]);
    const [enviaTexto, setEnviaTexto] = useState(false);
    const [textoRequired, setTextoRequired] = useState(false);
    const [textoEnviado, setTextoEnviado] = useState(false);
    const [enviaArquivo, setEnviaArquivo] = useState(false);
    const [arquivoEnviado, setArquivoEnviado] = useState(false);
    const [textoProcesso, setTextoProcesso] = useState('');
    const [selecioneArquivo, setSelecioneArquivo] = useState(false);
    const [solicitarExclusao, setSolicitarExclusao] = useState(false);
    const [encaminhadoExclusao, setEncaminhadoExclusao] = useState(false);
    const [motivoExclusao, setMotivoExclusao] = useState('');
    const [arquivoSelecionado, setArquivoSelecionado] = useState(null);
    const [nomeArquivoSelecionado, setNomeArquivoSelecionado] = useState('');
    const [motivoRequired, setMotivoRequired] = useState(false);
    const [arquivoAnalise, setArquivoAnalise] = useState('');
    const [perfilDestinoFinal, setPerfilDestinoFinal] = useState('');
    const [descricao, setDescricao] = useState('');
    const [arquivoExclusao, setArquivoExclusao] = useState([]);

    const [modalDuploCheck, setModalDuploCheck] = useState(false);
    const [senha, setSenha] = useState('');
    const [avisoDuploCheck, setAvisoDuploCheck] = useState(false);
    const [avisoSenhaOk, setAvisoSenhaOk] = useState(false);
    const [senhaVerificada, setSenhaVerificada] = useState(false);
    const [modalExcluirArquivo, setModalExcluirArquivo] = useState(false);
    const [formConfirmarExclusao, setFormConfirmarExclusao] = useState(false);
    const [formNaoAutorizarExclusao, setFormNaoAutorizarExclusao] = useState(false);
    const [motivoNaoExclusao, setMotivoNaoExclusao] = useState('');
    const [mensagem, setMensagem] = useState('');

    const [botaoAtivo, setBotaoAtivo] = useState(null);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);

    const [fullscreen, setFullscreen] = useState(true);

    const [aprovar, setAprovar] = useState(false);
    const [aprovado, setAprovado] = useState(false);


    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosProcessoInterno/"

    var wStatusArquivo
    var wArquivoEncontrado

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    // function showModalExcluirArquivo(wNomeArquivo, wCodigoArquivo, wPerfilRemetente, wDescricao) {
    //     setModalExcluirArquivo(true);
    //     setArquivoAnalise(wNomeArquivo);
    //     setArquivoExclusao(wCodigoArquivo)
    //     setPerfilDestinoFinal(wPerfilRemetente);
    //     setDescricao(wDescricao);
    // }
    // function handleCloseExcluirArquivo() {
    //     setModalExcluirArquivo(false);
    // };

    const ConfirmarExclusao = async (event) => {
        event.preventDefault();

        //console.log(nomeArquivoSelecionado, arquivoSelecionado, descricao)

        if (avisoSenhaOk) {
            ConfirmarExclusaoComSenha(
                nomeArquivoSelecionado,
                arquivoSelecionado,
                descricao
            );
        } else {
            setModalDuploCheck(true);
        }
    };

    function ConfirmarExclusaoComSenha(wNomeArquivo, wCodigoArquivo, wDescricao) {
        setLoading(true);

        const wtoken = "Bearer " + wTokenLocal;

        var datHoje = new Date();

        var data = JSON.stringify({
            "CodigoStatusProcessoInterno": 0,
            "CodigoProcessoInterno": CodigoProcessoInterno,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": true,
            "Motivo": 'Arquivo <strong>' + wNomeArquivo + '</strong> excluído do processo',
            "CodigoPerfil": wUsuario,
            "Doc": "",
            "PerfilDestino": wUsuario,
            "CodCadastro": localStorage.getItem("CodigoCadastro")
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/StatusProcessoInterno',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json',
            },
            data: data
        };
        console.log(config);

        axios(config)

            .then(function (response) {
                const data = response.data;

                //grava status do arquivo como true
                var dataPUT = JSON.stringify({
                    "CodigoArquivoProcessoInterno": wCodigoArquivo,
                    "CodigoProcessoInterno": CodigoProcessoInterno,
                    "Descricao": wDescricao + " (excluído do processo)",
                    "Arquivo": wNomeArquivo,
                    "Status": 1
                });

                var configPUT = {
                    method: 'PUT',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/ArquivoProcessoInterno',
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json',
                    },
                    data: dataPUT
                };
                //console.log(configPUT);
                axios(configPUT)

                    .then(function (response) {

                        var configGET = {
                            method: 'GET',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/UploadArquivo/ArquivoExcluido',
                            headers: {
                                'pasta': 'ArquivosProcessoInterno',
                                'arquivo': wNomeArquivo,
                                'Authorization': wtoken,
                                'Content-Type': 'application/json'
                            },
                        };
                        //console.log(configGET);
                        axios(configGET)
                            .then(function (response) {
                                const dataGet = response.data;
                                // console.log(dataGet);
                                if (dataGet.isOk === true) {
                                    PegaStatus();
                                    setAvisoSenhaOk(false);
                                    //setSenhaVerificada(false); // Reinicia o estado para futuras verificações
                                    setLoading(false);
                                    setModalExcluirArquivo(false);
                                    // Recarrega a página
                                    window.location.reload();
                                } else {
                                    setShowModalErro(true);
                                    setModalExcluirArquivo(true);
                                }
                            }
                            )
                            .catch(function (error) {
                                setLoading(false);
                                console.log(error);
                                if (error.response.status === 401) {
                                    setLogin(true);
                                }
                                //console.log(JSON.stringify(response.data.mensagemRetorno));
                            });

                    })
                    .catch(function (error) {
                        setLoading(false);
                        console.log(error);
                        //console.log(JSON.stringify(response.data.mensagemRetorno));
                    });


            }
            )
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    // const NaoAutorizarExclusao = async (event) => {
    //     event.preventDefault();
    //     setFormNaoAutorizarExclusao(true);
    // }

    // const handleNaoAutorizarExclusao = (event) => {
    //     event.preventDefault();

    //     if (senhaVerificada) {
    //         handleNaoAutorizarExclusaoComSenha();
    //     } else {
    //         setModalDuploCheck(true);
    //     }
    // }

    // function handleNaoAutorizarExclusaoComSenha() {
    //     setLoading(true);
    //     const wtoken = "Bearer " + wTokenLocal;

    //     var datHoje = new Date();

    //     var data = JSON.stringify({
    //         "CodigoStatusProcessoInterno": 0,
    //         "CodigoProcessoInterno": CodigoProcessoInterno,
    //         "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
    //         "DataMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    //         "status": false,
    //         "Motivo": 'Exclusão do arquivo <strong>' + arquivoAnalise + '</strong> não autorizada.<br><strong>Motivo:</strong> ' + motivoNaoExclusao,
    //         "CodigoPerfil": wPerfil,
    //         "Doc": "",
    //         "PerfilDestino": perfilDestinoFinal,
    //         "CodCadastro": localStorage.getItem("CodigoCadastro")
    //     });

    //     var config = {
    //         method: 'post',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/StatusProcessoInterno',
    //         headers: {
    //             'Authorization': wtoken,
    //             'Content-Type': 'application/json',
    //         },
    //         data: data
    //     };

    //     axios(config)

    //         .then(function (response) {
    //             const data = response.data;
    //             //grava status do arquivo como true
    //             var dataPUT = JSON.stringify({
    //                 "CodigoArquivoProcessoInterno": arquivoExclusao,
    //                 "CodigoProcessoInterno": CodigoProcessoInterno,
    //                 "Descricao": descricao,
    //                 "Arquivo": arquivoAnalise,
    //                 "Status": 1
    //             });

    //             var configPUT = {
    //                 method: 'PUT',
    //                 maxBodyLength: Infinity,
    //                 url: urlAPIlocal + '/api/ArquivoProcessoInterno',
    //                 headers: {
    //                     'Authorization': wtoken,
    //                     'Content-Type': 'application/json',
    //                 },
    //                 data: dataPUT
    //             };
    //             //console.log(configPUT);
    //             axios(configPUT)

    //                 .then(function (response) {
    //                     //console.log(response.data);
    //                     if (response.data.isOk === true) {
    //                         PegaStatus();
    //                         setSenhaVerificada(false); // Reinicia o estado para futuras verificações
    //                         setLoading(false);
    //                         setModalExcluirArquivo(false);
    //                         // Recarrega a página
    //                         window.location.reload();
    //                     } else {
    //                         setShowModalErro(true);
    //                         setModalExcluirArquivo(true);
    //                     }

    //                 })
    //                 .catch(function (error) {
    //                     setLoading(false);
    //                     console.log(error);
    //                     //console.log(JSON.stringify(response.data.mensagemRetorno));
    //                 });


    //         }
    //         )
    //         .catch(function (error) {
    //             setLoading(false);
    //             console.log(error);
    //             //console.log(JSON.stringify(response.data.mensagemRetorno));
    //         });
    // }


    const getProcesso = async (event) => {

        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ProcessoInterno/' + CodigoProcessoInterno,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const data = response.data;
                //console.log(data.objetoRetorno);
                setProcesso(data.objetoRetorno);
                setTextoProcesso(data.objetoRetorno.Detalhes);

                //console.log(data.objetoRetorno.Detalhes)
                PegaStatus();

            }
            )
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getProcesso();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        //GravaProcessoNovo();
    }

    const handleNovoFile = (event) => {
        if (event.target.files) {
            setNovoFile(event.target.files[0]);
        }
    };


    const getArquivo = async (event) => {

        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcessoInterno?CodigoProcessoInterno=' + CodigoProcessoInterno,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                const data = response.data;
                setArquivo(data.objetoRetorno);
                //console.log(data);
            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getArquivo();
        // eslint-disable-next-line
    }, []);

    // const [detalhesAtual, setDetalhesAtual] = useState(null);

    // useEffect(() => {
    //     if (detalhesAtual && detalhesAtual.Motivo.includes('Solicitação de exclusão do arquivo:')) {
    //         const arquivoNome = detalhesAtual.Motivo.match(/arquivo: <strong>(.*?)</)?.[1];
    //         getStatusArquivo(arquivoNome);
    //     }
    // }, [wPerfil, statusArquivo, detalheAtual]);

    // useEffect(() => {
    //     if (detalhes && detalhes.Motivo.includes('Solicitação de exclusão do arquivo:')) {
    //         const arquivoNome = detalhes.Motivo.match(/arquivo: <strong>(.*?)</)?.[1];
    //         getStatusArquivo(arquivoNome);
    //     }
    // }, [detalhes]);

    //useEffect(() => {


    /* function getStatusArquivo(wNomeArquivo) {
        //console.log(wNomeArquivo);
        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcessoInterno?Arquivo=' + wNomeArquivo,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                const data = response.data;
                console.log(data.objetoRetorno[0].Status);
                if (data.objetoRetorno[0].Status === 1) {
                    console.log("entrou no true");
                    return true;

                } else {
                    console.log("entrou no false");
                    return false;

                }

                //setStatusArquivo(data.objetoRetorno[0].Status);
                //setStatArquivo(data.objetoRetorno[0].CodigoArquivoProcessoInterno);
                //setDescricao(data.objetoRetorno[0].Descricao);
                //setArquivoExclusao(data.objetoRetorno[0]);

            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    } */


    //}, [status]);
    // useEffect(() => {
    //     getStatusArquivo();
    //     // eslint-disable-next-line
    // }, []);

    function GravaPerfilAtual(wCodProcInterno, wCodUsuario) {

        const wtoken = "Bearer " + localStorage.getItem("token");
        var Retorno = false;
        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ProcessoInterno/PerfilDestino/' + wCodProcInterno + '/' + wCodUsuario,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    Retorno = true;
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    Retorno = false
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    /* function GravaDetalhesProcesso() {
        setLoading(true);

        //trata novo detalhe no processo
        var wNovotexto = processo.Detalhes + mensagemremetente + '**********'

        var datHoje = new Date();
        var wtokenBearer = "Bearer " + localStorage.getItem("token");
        var data = JSON.stringify({
            "CodigoProcessoInterno": processo.CodigoProcessoInterno,
            "Titulo": processo.Titulo,
            "Detalhes": wNovotexto,
            "DataInicio": moment(processo.DataInicio).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "DataUltimoMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "PerfilDestinoAtual": wPerfil
        });
        //console.log(data)
        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ProcessoInterno',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(dataAssinatura);
        console.log(config);
        axios(config)
            .then(function (response) {
                console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro
                    setLoading(false);
                    //console.log(processo.Detalhes + '<br><br>' + mensagemremetente);
                    //setTextoProcesso(processo.Detalhes + '<br><br>' + mensagemremetente);
                    getProcesso();

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setLoading(false);
                }
            })
            .catch(error => {
                //setGravando(false);
                console.error(error);
                setLoading(false);
                //setShowModalErro(true);
            });
    } */

    function PegaStatus() {
        const wtoken = "Bearer " + wTokenLocal;

        var configStatus = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/StatusProcessoInterno?CodigoProcessoInterno=' + CodigoProcessoInterno + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(configStatus);
        axios(configStatus)
            .then(function (responseStatus) {
                const data = responseStatus.data;
                setStatus(data.objetoRetorno);
                //console.log(data.objetoRetorno);
                if (data.objetoRetorno !== null) {
                    getIconColor(data.objetoRetorno[0].Cadastro.Nome);
                }

                //var wDivHtml = document.getElementById('htmlProcessoNovo');
                //console.log('div puro :' + wDivHtml);
                //wDivHtml = wDivHtml.innerHTML;
                //const escapedHtml = wDivHtml.replace(/(\r\n|\n|\r)/g, '').replace(/"/g, "'");
                //console.log('div final :' + escapedHtml);

                setLoading(false);

            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    function getIconColor(nomePerfil) {
        if (nomePerfil === 'Compras') {
            return '#2980B9';
        } else if (nomePerfil === 'Financeiro') {
            return '#27AE60';
        } else if (nomePerfil === 'Assessoria Jurídica') {
            return '#CB4335';
        } else if (nomePerfil === 'Diretoria Administrativa') {
            return '#F4D03F';
        } else if (nomePerfil === 'Recursos Humanos') {
            return '#8E44AD';
        } else if (nomePerfil === 'Secretário Executivo') {
            return '#0B5345';
        } else if (nomePerfil === 'Consultor') {
            return '#626567';
        } else if (nomePerfil === 'Gestão de Projetos') {
            return '#E67E22';
        } else if (nomePerfil === 'Equipe Area') {
            return '#85C1E9';
        } else if (nomePerfil === 'Prestação de Contas') {
            return '#2ECC71';
        } else if (nomePerfil === 'Assessoria Administrativa') {
            return '#A569BD';
        } else if (nomePerfil === 'Diretor Presidente') {
            return '#C0392B';
        } else if (nomePerfil === 'TI') {
            return '#A6ACAF';
        } else {
            return '#17202A';
        }
    };

    const handleSubmitInfo = async (event) => {
        event.preventDefault();
        setEnviaTexto(true);
        setBotaoDesabilitado(false);
        setTextoEnviado(false);
        setEnviaArquivo(false);
        setEncaminharProcesso(false);
        setArquivoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
        setAprovar(false);
        setAprovado(false);
    }

    const handleSubmitArquivo = async (event) => {
        event.preventDefault();
        setEnviaArquivo(true);
        setBotaoDesabilitado(false);
        setArquivoEnviado(false);
        setEnviaTexto(false);
        setEncaminharProcesso(false);
        setTextoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
        setAprovar(false);
        setAprovado(false);
    }

    const handleSubmitEncaminhar = async (event) => {
        event.preventDefault();
        setEncaminharProcesso(true);
        setBotaoDesabilitado(false);
        setEnviaArquivo(false);
        setEnviaTexto(false);
        setTextoEnviado(false);
        setArquivoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
        setAprovar(false);
        setAprovado(false);

    }

    const handleExcluirArquivo = async (event) => {
        event.preventDefault();
        setSolicitarExclusao(true);
        setBotaoDesabilitado(false);
        setEncaminhadoExclusao(false);
        setEnviaArquivo(false);
        setEnviaTexto(false);
        setTextoEnviado(false);
        setArquivoEnviado(false);
        setEncaminharProcesso(false);
        setEncaminhado(false);
        setAprovar(false);
        setAprovado(false);
    }


    const DuploCheck = async (event) => {
        event.preventDefault();
        setLoading(true);

        var CodigoUsuario = localStorage.getItem('CodigoUsuario');
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + `/api/Usuarios?CodigoUsuario=${CodigoUsuario}&IsInclude=false`,
            headers: {
                'Authorization': "Bearer " + wTokenLocal,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data.objetoRetorno;

                if (senha === data[0].SenhaAssinatura) {
                    //setSenhaVerificada(true);
                    setModalDuploCheck(false);
                    setAvisoDuploCheck(false);
                    setAvisoSenhaOk(true);
                    setSenha('');
                    if (enviaTexto) {
                        GravaTextoComSenha();
                    } else if (enviaArquivo) {
                        GravaNovoArquivoComSenha();
                    } else if (encaminharProcesso) {
                        GravaEncaminhamentoComSenha();
                    } else if (aprovar) {
                        GravaAprovarComSenha();
                    }
                    else {
                        //console.log(nomeArquivoSelecionado, arquivoSelecionado, descricao);
                        ConfirmarExclusaoComSenha(nomeArquivoSelecionado, arquivoSelecionado, descricao);
                    }


                } else {
                    setModalDuploCheck(true);
                    setAvisoDuploCheck(true);
                    setAvisoSenhaOk(false);
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);

            });
    };

    const closeAndResetModalDuploCheck = () => {
        setModalDuploCheck(false);
        setAvisoDuploCheck(false);
        setAvisoSenhaOk(false);
        setSenha('');
        //setSenhaVerificada(false);
        setBotaoDesabilitado(false);
    };

    function GravaTextoComSenha() {

        // Remove quebras de linha e substitui aspas
        const escapedHtml = mensagem.replace(/(\r\n|\n|\r)/g, '').replace(/"/g, "'");

        var wtokenBearer = "Bearer " + localStorage.getItem("token");
        var datHoje = new Date();
        var wNomeUsuario = localStorage.getItem("NomeUsuario");
        var wNomeSetor = localStorage.getItem("NomePerfil");
        //trata a mensagem com a divisão
        //console.log(processo.Detalhes);
        //console.log(textoProcesso);
        var wMensagemNova = textoProcesso + '<br><strong>Informação inserida por: </strong>' + wNomeUsuario + ' <strong>Setor:</strong> ' + wNomeSetor + '<br>' + escapedHtml + '<br> ________________________________ ';
        var wMensagemNovaStatus = '<strong>Informação inserida por: </strong>' + wNomeUsuario + ' <strong>Setor:</strong> ' + wNomeSetor + '<br>' + escapedHtml; var dataStatusProc = JSON.stringify({
            "CodigoStatusProcessoInterno": 0,
            "CodigoProcessoInterno": CodigoProcessoInterno,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": wMensagemNovaStatus,
            "CodigoPerfil": wUsuario,
            "Doc": "",
            "PerfilDestino": wUsuario,
            "CodCadastro": localStorage.getItem("CodigoCadastro")
        });
        var configStatusProc = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/StatusProcessoInterno',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json',
            },
            data: dataStatusProc
        };
        //console.log(configProc);
        axios(configStatusProc)
            .then(function (responseStatusProc) {
                if (responseStatusProc.data.isOk === true) {
                    //console.log(wMensagemNova);
                    //grava novo detalhes no processo
                    //console.log(wPerfil);
                    var datHoje = new Date();
                    var wtokenBearer = "Bearer " + localStorage.getItem("token");
                    var dataProc = JSON.stringify({
                        "CodigoProcessoInterno": processo.CodigoProcessoInterno,
                        "Titulo": processo.Titulo,
                        "Detalhes": wMensagemNova,
                        "DataInicio": moment(processo.DataInicio).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                        "DataUltimoMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                        "PerfilDestinoAtual": wPerfil,
                        "CodigoConvenio": processo.CodigoConvenio,
                    });
                    //console.log('passou aqui <br>' + dataProc)
                    var configProc = {
                        method: 'PUT',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/ProcessoInterno',
                        headers: {
                            'Authorization': wtokenBearer,
                            'Content-Type': 'application/json'
                        },
                        data: dataProc
                    };
                    //console.log(data);
                    //console.log(configProc);
                    axios(configProc)
                        .then(function (responseProc) {
                            //console.log(responseProc.data)
                            if (responseProc.data.isOk === true) {
                                // fez ao consumo sem erro
                                setLoading(false);
                                //console.log(processo.Detalhes + '<br>____________________________________________________<br>' + mensagemremetente);
                                setTextoProcesso(responseProc.data.objetoRetorno.Detalhes);
                                //console.log(responseProc.data.objetoRetorno.Detalhes)
                                //GravaPerfilAtual(CodigoProcessoInterno, setor);
                                setMensagem('');
                                setEnviaTexto(false);
                                setLoading(false);
                                getProcesso();
                                setTextoProcesso(processo.Detalhes);
                                setTextoEnviado(true);
                                setSenha('');
                                setAvisoSenhaOk(false);
                                //getProcesso();

                            } else {
                                //deu algum erro tratar , colocar algum aviso
                                setLoading(false);
                                console.log(responseProc.data)
                            }
                        })
                        .catch(error => {
                            //setGravando(false);
                            console.error(error);
                            setLoading(false);
                            //setShowModalErro(true);
                        });

                }
            })

            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }

    const handleSubmitTexto = async (event) => {
        event.preventDefault();
        setLoading(true);

        setBotaoDesabilitado(true);
        //console.log(mensagemremetente)

        //verifica senha antes de prosseguir
        if (mensagem !== '') {
            setLoading(false);
            setTextoRequired(false);

            if (avisoSenhaOk === true) {
                GravaTextoComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setLoading(false);
            setTextoRequired(true);
        }
    }

    function gerarCodigoUnico() {

        let codigo = '';
        for (let i = 0; i < 4; i++) {
            codigo += Math.floor(Math.random() * 10); // Adiciona um número aleatório de 0 a 9 ao código
        }
        return codigo;
    }


    function GravaNovoArquivoComSenha() {
        setLoading(true);
        //var Retorno = false;
        //var datHoje = new Date()
        //trata nome arquivo, tira acentos, espaços
        let wNomeArquivoLimpo = NovoFile.name
        let wCodigoUnico = gerarCodigoUnico();
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
        wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        wNomeArquivoLimpo = CodigoProcessoInterno + "_" + wCodigoUnico + "_" + wNomeArquivoLimpo

        //wDatSituacao = moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        var datFiles = JSON.stringify({
            "CodigoArquivoProcessoInterno": 0,
            "CodigoProcessoInterno": CodigoProcessoInterno,
            "descricao": descricaoarquivo,
            "arquivo": wNomeArquivoLimpo, //CodCadastro + "_" + ArqCadastro.name,
            "status": 0
        })
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcessoInterno',
            headers: {
                'Authorization': "Bearer " + wTokenLocal,
                'Content-Type': 'application/json'
            },
            data: datFiles
        };
        //console.log(datFiles)
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    //faz upload do arquivo
                    const file = NovoFile;
                    const novoNome = wNomeArquivoLimpo;
                    const novoArquivo = new File([file], novoNome, { type: file.type });
                    const formData = new FormData();
                    formData.append('arquivo', novoArquivo);

                    var config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/UploadArquivo',
                        headers: {
                            'Authorization': 'Bearer ' + wTokenLocal,
                            'Content-Type': 'multipart/form-dataa',
                            'pasta': 'ArquivosProcessoInterno'
                        },
                        data: formData
                    };

                    axios(config)

                        .then(response => {
                            //console.log(response.data);
                            //Grava registro na tblStatusProcessoInterno
                            var datHoje = new Date();
                            var dataStatusProc = JSON.stringify({
                                "CodigoStatusProcessoInterno": 0,
                                "CodigoProcessoInterno": CodigoProcessoInterno,
                                "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                                "DataMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                "status": false,
                                "Motivo": '<strong>Arquivo Inserido Por:</strong> ' + wNomePerfil + '<br>Arquivo: ' + wNomeArquivoLimpo + '<br>' + descricaoarquivo,
                                "CodigoPerfil": wUsuario,
                                "Doc": "",
                                "PerfilDestino": wUsuario,
                                "CodCadastro": localStorage.getItem("CodigoCadastro")
                            });
                            var configStatusProc = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/StatusProcessoInterno',
                                headers: {
                                    'Authorization': "Bearer " + wTokenLocal,
                                    'Content-Type': 'application/json',
                                },
                                data: dataStatusProc
                            };
                            //console.log(configProc);
                            axios(configStatusProc)
                                .then(function (responseStatusProc) {
                                    if (responseStatusProc.data.isOk === true) {
                                        // faça algo com a resposta da API
                                        //Retorno = false;
                                        setEnviaArquivo(false);
                                        getProcesso();
                                        setNovoFile(null);
                                        setDescricaoArquivo('');
                                        fileInputRef.current.value = '';
                                        setAvisoSenhaOk(false);
                                        setSenha('');
                                        setLoading(false);
                                        setArquivoEnviado(true);
                                        getArquivo();
                                    }
                                })

                                .catch(error => {
                                    console.error(error);
                                    setLoading(false);
                                    setShowModalErro(true);
                                });




                        })
                        .catch(error => {
                            console.error(error);
                            setLoading(false);
                            //Retorno = true;

                        });
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //Retorno = true

                }

            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
    }

    const handleSubmitEnviaArquivo = async (event) => {
        event.preventDefault();
        setLoading(true);
        setBotaoDesabilitado(true);
        //console.log(NovoFile);
        //setSelecioneArquivo(false);

        //if (NovoFile !== null && avisoSenhaOk === true) {

        if (NovoFile !== null) {
            setLoading(false);
            setSelecioneArquivo(false);

            if (avisoSenhaOk === true) {

                GravaNovoArquivoComSenha();

                //return Retorno;
            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setSelecioneArquivo(true);
        }
    }
    const getUsuarios = async (event) => {

        //const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios?IsInclude=true',
            headers: {
                'Authorization': "Bearer " + wTokenLocal,
                'Content-Type': 'application/json'
            },
        };

        axios(config)

            .then(function (response) {
                const data = response.data;
                const resultadosFiltrados = data.objetoRetorno.filter((user) => user.CodigoPerfil !== 1);
                setUsuarios(resultadosFiltrados);
                //setLoading(false);
                //console.log(resultadosFiltrados);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getUsuarios();
        // eslint-disable-next-line
    }, []);

    const handleSelectSetor = (event) => {
        setSetor(event.target.value);

        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedOptionText = selectedOption.text;
        //console.log(selectedOptionText)
        setNomeSetor(selectedOptionText);
    }

    function GravaEncaminhamentoComSenha() {
        setLoading(true);
        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var datHoje = new Date()
        var data = JSON.stringify({
            "CodigoStatusProcessoInterno": 0,
            "CodigoProcessoInterno": CodigoProcessoInterno,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": 'Processo encaminhado de: <strong>' + wNomePerfil + '</strong> para: <strong>' + nomeSetor + '</strong><br>Mensagem: ' + mensagemencaminhamento,
            "CodigoPerfil": wUsuario,
            "Doc": "",
            "PerfilDestino": setor,
            "CodCadastro": localStorage.getItem("CodigoCadastro")
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/StatusProcessoInterno',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json',
            },
            data: data
        };
        console.log(config);
        axios(config)
            .then(response => {
                //console.log(response);
                //grava novo detalhes no processo
                //GravaDetalhesProcesso();
                getProcesso();
                GravaPerfilAtual(CodigoProcessoInterno, setor);
                setEncaminhado(true);
                setSetor(0);
                setMensagemEncaminhamento('');
                setSelecioneSetor(false);
                setSenha('');
                setAvisoSenhaOk(false);
                setLoading(false);
                setEncaminharProcesso(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }

    const handleSubmitEncaminhamento = async (event) => {
        event.preventDefault();
        setLoading(true);
        setBotaoDesabilitado(true);

        //se nao selecionou setor nao deixa encaminhar
        //console.log(setor);

        if (setor !== 0) {
            setLoading(false);
            setSelecioneSetor(false);

            if (avisoSenhaOk === true) {
                GravaEncaminhamentoComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setSelecioneSetor(true);
        }
    };

    const handleRadioChange = (event) => {
        const codigoArquivoInterno = parseInt(event.target.value, 10);
        const arquivoSelecionadoInfo = arquivo.find(
            (arq) => arq.CodigoArquivoProcessoInterno === codigoArquivoInterno
        );

        if (arquivoSelecionadoInfo) {
            setNomeArquivoSelecionado(arquivoSelecionadoInfo.Arquivo);
            setArquivoSelecionado(arquivoSelecionadoInfo.CodigoArquivoProcessoInterno);
            setDescricao(arquivoSelecionadoInfo.Descricao);
            //console.log(arquivoSelecionadoInfo.Arquivo)
            //console.log(arquivoSelecionadoInfo.CodigoArquivoProcessoInterno)
            //console.log(arquivoSelecionadoInfo.Descricao)
        }
    };


    // const handleSubmitEncaminharExclusao = async (event) => {
    //     event.preventDefault();
    //     setLoading(true);

    //     if (motivoExclusao !== '') {
    //         setLoading(false);
    //         setMotivoRequired(false);

    //         if (avisoSenhaOk === true) {

    //             handleSubmitEncaminharExclusaoComSenha();

    //         } else {
    //             setLoading(false);
    //             setModalDuploCheck(true);
    //         }
    //     } else {
    //         setMotivoRequired(false);
    //     }
    // };

    // function handleSubmitEncaminharExclusaoComSenha() {
    //     setLoading(true);
    //     var wtokenBearer = "Bearer " + localStorage.getItem("token")
    //     var datHoje = new Date()
    //     var data = JSON.stringify({
    //         "CodigoStatusProcessoInterno": 0,
    //         "CodigoProcessoInterno": CodigoProcessoInterno,
    //         "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
    //         "DataMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    //         "status": false,
    //         "Motivo": 'Solicitação de exclusão do arquivo: <strong>' + nomeArquivoSelecionado + '<br></strong>Motivo: <strong>' + motivoExclusao,
    //         "CodigoPerfil": wPerfil,
    //         "Doc": "",
    //         "PerfilDestino": '33',
    //         "CodCadastro": localStorage.getItem("CodigoCadastro")
    //     });

    //     var config = {
    //         method: 'post',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/StatusProcessoInterno',
    //         headers: {
    //             'Authorization': wtokenBearer,
    //             'Content-Type': 'application/json',
    //         },
    //         data: data
    //     };
    //     //console.log(config);
    //     axios(config)
    //         .then(response => {
    //             //console.log(response);
    //             //grava novo detalhes no processo
    //             //GravaDetalhesProcesso();
    //             getProcesso();
    //             GravaPerfilAtual(CodigoProcessoInterno, '33');
    //             setEncaminhadoExclusao(true);
    //             setArquivoSelecionado(null);
    //             setMotivoExclusao('');
    //             setSenha('');
    //             setAvisoSenhaOk(false);
    //             setSolicitarExclusao(false);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             setLoading(false);
    //             setShowModalErro(true);
    //         });
    // }

    function handleCloseErro() {
        setShowModalErro(false);
    };

    const handlePrintClick = () => {
        window.open(`/PrintProcessoInterno/${CodigoProcessoInterno}`, '_blank');
    };

    const encontrarArquivo = (motivo) => {
        // Certifica-se de que arquivo existe e é uma array
        if (arquivo && Array.isArray(arquivo)) {
            const nomeArquivo = motivo;
            return arquivo.find(item => item.Arquivo === nomeArquivo);
        }
        return null; // Retorna null se arquivo não existe ou não é uma array
    };

    const [showModalArquivo, setShowModalArquivo] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const openModalArquivo = (nomeArquivo) => {
        setFullscreen(true);
        setModalContent(nomeArquivo);
        setShowModalArquivo(true);
    };

    const closeModal = () => {
        setShowModalArquivo(false);
    };

    const handleMensagemChange = (value) => {
        setMensagem(value);
    };

    function removerPrefixoSeExistir(string, prefixo) {
        if (string.startsWith(prefixo)) {
            return string.substring(prefixo.length);
        } else {
            return string;
        }
    }

    const handleSubmitAprovar = async (event) => {
        event.preventDefault();
        setAprovar(true);
        setEncaminharProcesso(false);
        setEnviaArquivo(false);
        setEnviaTexto(false);
        setTextoEnviado(false);
        setArquivoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
        // setBotaoDesabilitado(false);

        // gravar status novo
        setBotaoDesabilitado(true);
        setLoading(false);

        //verifica senha antes de prosseguir
        //console.log(avisoSenhaOk);
        if (avisoSenhaOk === true) {
            GravaAprovarComSenha();

        } else {
            setLoading(false);
            setModalDuploCheck(true);
        }

    }

    function GravaAprovarComSenha() {
        setLoading(true);

        //const wHtmlMensagem = ReactDOMServer.renderToStaticMarkup(mensagem);
        // Remove quebras de linha e substitui aspas
        //const escapedHtml = mensagem.replace(/(\r\n|\n|\r)/g, '').replace(/"/g, "'");
        //console.log(mensagem);
        //console.log(escapedHtml);
        var wNomeUsuario = localStorage.getItem("NomeUsuario");
        var wPerfilAnterior = status[0].CodigoPerfil;
        var wNomePerfilAnterior = status[0].Perfil.Nome;
        //console.log(wPerfilAnterior);

        var wMensagemNovaStatus = '<br><br><br><strong>Informação inserida por: </strong>' + wNomeUsuario + '<br>Confirmo que estou ciente do processo<br><strong>Processo Encaminhado para:</strong> ' + wNomePerfilAnterior;
        //var wMensagemNova = (TextoSolicitacao ? TextoSolicitacao : '') + ' <br>' + wMensagemNovaStatus;


        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var datHoje = new Date()
        var data = JSON.stringify({
            "CodigoStatusProcessoInterno": 0,
            "CodigoProcessoInterno": CodigoProcessoInterno,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataMovimento": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": wMensagemNovaStatus, //'Processo encaminhado de: <strong>' + wNomePerfil + '</strong> para: <strong>' + nomeSetor + '</strong><br>Mensagem: ' + mensagemencaminhamento,
            "CodigoPerfil": wPerfil,
            "Doc": "",
            "PerfilDestino": wPerfilAnterior,
            "CodCadastro": localStorage.getItem("CodigoCadastro")
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/StatusProcessoInterno',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json',
            },
            data: data
        };
        console.log(config);
        axios(config)
            .then(function (responseStatus) {
                if (responseStatus.data.isOk === true) {
                    //console.log(wMensagemNova);

                    // fez ao consumo sem erro
                    setLoading(false);
                    setTextoProcesso(responseStatus.data.objetoRetorno.Motivo);
                    setAprovar(false);
                    GravaPerfilAtual(CodigoProcessoInterno, wPerfilAnterior);
                    //GravaDPCC(CodigoSolicitacao, 1);
                    //setMensagem('');
                    setLoading(false);
                    getProcesso();
                    //setTextoSolicitacao(solicitacao.Tramitacao);
                    setAprovado(true);
                    setSenha('');
                    setAvisoSenhaOk(false);
                    setSenhaVerificada(false);
                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }

    return (
        <div className={Style.page}>
            <Menu />

            {/* <div> <button className={Style.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={Style.voltar}><Link to={`/ProcInterno`}><IoMdArrowBack /> Voltar</Link></button> </div>
            <div className={Style.box_container}>

                {processo && (

                    <Box className={Style.box_processo}>
                        <Row>
                            <Col>
                                <h1>PA n° {processo.CodigoProcessoInterno}/{moment(processo.DataInicio).format('YYYY')}</h1>
                            </Col>
                            <Col>
                                <p align="right">Data inicial: {moment(processo.DataInicio).format('DD-MM-YYYY')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <h3>{processo.Titulo}</h3>
                            <h2>Interessado: {textoProcesso.match(/<br>(.*?)</)?.[1]}</h2>
                            <span align="right"><FaFileInvoice /></span>
                        </Row>
                    </Box>
                )}
            </div>
            <div className={Style.box_container}>
                <Form className={Style.form} onSubmit={handleSubmit}>
                    <div className={Style.gerenciar}>
                        <Box className={Style.box_gerenciar}>
                            {/* <Form> */}
                            <FormGroup>
                                <div><p></p></div>
                                <div>
                                    <h4 align="center">GERENCIAR PROCESSO</h4>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2em' }}>
                                        <button
                                            className={botaoAtivo === 'info' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                            onClick={(e) => { handleSubmitInfo(e); setBotaoAtivo('info'); }}
                                        >
                                            Inserir Novas Informações (texto)
                                        </button>
                                        <button
                                            className={botaoAtivo === 'arquivo' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                            onClick={(e) => { handleSubmitArquivo(e); setBotaoAtivo('arquivo'); }}
                                        >
                                            Inserir Arquivo(s)
                                        </button>
                                        <button
                                            className={botaoAtivo === 'encaminhar' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                            onClick={(e) => { handleSubmitEncaminhar(e); setBotaoAtivo('encaminhar'); }}
                                        >
                                            Encaminhar Processo <IoIosSend />
                                        </button>
                                        <button
                                            className={botaoAtivo === 'excluir' ? `${Style.btn_excluir} ${Style.btn_ativoExcluir}` : Style.btn_excluir}
                                            onClick={(e) => { handleExcluirArquivo(e); setBotaoAtivo('excluir'); }}
                                        >
                                            Excluir Arquivo
                                        </button>
                                        <button className={Style.btn_print} onClick={handlePrintClick}><IoMdPrint /></button>
                                    </div>
                                    {wPerfil === '28' && (
                                        <div align='center'>
                                            <br></br>
                                            <br></br>
                                            <button
                                                className={botaoAtivo === 'Aprovar' ? `${Style.btn_aprov} ${Style.btn_ativo2}` : Style.btn_aprov}
                                                onClick={(e) => { handleSubmitAprovar(e); setBotaoAtivo('Aprovar'); }}
                                            >
                                                Estou Ciente<IoIosSend />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <p><br></br></p>
                                {enviaTexto && (
                                    <div>
                                        <p></p>
                                        <p></p>
                                        <Form.Label>Novas Informações no Processo: </Form.Label>
                                        <ReactQuill
                                            theme="snow"
                                            value={mensagem}
                                            onChange={handleMensagemChange}
                                            required
                                            style={{ height: '400px', color: '#707070', marginBottom: '2em' }}
                                        />
                                        {/* <Form.Control
                                            as="textarea"
                                            name="mensagemRementente"
                                            value={mensagemremetente}
                                            onChange={(event) => setMensagemRemetente(event.target.value)}
                                            required
                                            style={{ height: '100px', color: '#707070' }} /> */}
                                        <p></p>
                                        {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                        <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitTexto} disabled={botaoDesabilitado} >Atualizar Processo <IoIosSend /></button></p>
                                        {textoRequired && (
                                            <p align="center">
                                                <strong>Digite um texto para enviar!</strong>
                                            </p>
                                        )}
                                    </div>
                                )}
                                {enviaArquivo && (
                                    <div>
                                        <p></p>
                                        <p></p>
                                        <Form.Label>Descrição Arquivo: </Form.Label>
                                        <Form.Control name="descricaoarquivo" value={descricaoarquivo} onChange={(event) => setDescricaoArquivo(event.target.value)} style={{ color: '#707070' }} />

                                        <Form.Label style={{ color: '#fff' }}>.</Form.Label>
                                        <Form.Control name="NovoFile" type="file" accept="application/pdf" onChange={handleNovoFile} ref={fileInputRef} />
                                        <p></p>
                                        {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                        <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitEnviaArquivo} disabled={botaoDesabilitado} >Enviar Arquivo<IoIosSend /></button></p>
                                        {selecioneArquivo && (
                                            <p align="center">
                                                <strong>escolha um arquivo!</strong>
                                            </p>
                                        )}
                                    </div>
                                )}
                                {encaminharProcesso && (
                                    <div>
                                        <p></p>
                                        <p></p>
                                        <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Para:</Form.Label>
                                        <Form.Select name="setor" id="setor" value={setor} onChange={handleSelectSetor} style={{ width: '300px', color: '#707070' }} required>
                                            <option value="0">Selecione um Destinatário</option>
                                            {usuarios && (
                                                usuarios.map((user) => (
                                                    <option key={user.CodigoUsuario} value={user.CodigoUsuario}>
                                                        {user.Nome} - {user.Perfil.Nome}
                                                    </option>
                                                )))}
                                        </Form.Select>
                                        {selecioneSetor && (
                                            <p align="center">
                                                <strong>Selecione um destinatário para encaminhar!</strong>
                                            </p>
                                        )}
                                        <p><br></br></p>
                                        <Form.Label>Mensagem de Encaminhamento: </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="mensagemEncaminhamento"
                                            value={mensagemencaminhamento}
                                            onChange={(event) => setMensagemEncaminhamento(event.target.value)}
                                            style={{ height: '100px', color: '#707070' }} />
                                        <p></p>
                                        {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                        <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitEncaminhamento} disabled={botaoDesabilitado} >Encaminhar Processo <IoIosSend /></button></p>
                                    </div>
                                )}

                                {solicitarExclusao && (
                                    <div>
                                        <p></p>
                                        <p></p>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '55vw', flexWrap: 'wrap', margin: '0 auto' }}>
                                            {arquivo &&
                                                arquivo.length > 0 &&
                                                arquivo
                                                    .filter((ArquivoProcesso) => ArquivoProcesso.Status === 0)
                                                    .map((ArquivoProcesso) => (
                                                        <div
                                                            key={ArquivoProcesso.CodigoArquivoProcessoInterno}
                                                            style={{
                                                                border: '1px solid #ccc',
                                                                borderRadius: '8px',
                                                                padding: '10px',
                                                                textAlign: 'left',
                                                                margin: '10px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="arquivoRadio"
                                                                    id={ArquivoProcesso.CodigoArquivoProcessoInterno}
                                                                    value={ArquivoProcesso.CodigoArquivoProcessoInterno}
                                                                    onChange={handleRadioChange}
                                                                />
                                                                <label htmlFor={ArquivoProcesso.CodigoArquivoProcessoInterno} style={{ margin: '10px', fontSize: '12px' }}>
                                                                    {(() => {
                                                                        const nomeArquivoGrande = ArquivoProcesso.Arquivo;
                                                                        const maxLength = 30;
                                                                        const truncatedNome = nomeArquivoGrande.length > maxLength ? `${nomeArquivoGrande.substring(0, maxLength)}...` : nomeArquivoGrande;
                                                                        return truncatedNome;
                                                                    })()}
                                                                </label>
                                                            </div>

                                                            <embed
                                                                title={ArquivoProcesso.Arquivo}
                                                                style={{ width: '10vw', height: '30vh', margin: '0 auto' }}
                                                                src={LinkArquivo + ArquivoProcesso.Arquivo}
                                                            />
                                                        </div>
                                                    ))}
                                        </div>

                                        <Form.Label>Motivo da exclusão: </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="motivoExclusao"
                                            value={motivoExclusao}
                                            onChange={(event) => setMotivoExclusao(event.target.value)}
                                            style={{ height: '100px', color: '#707070' }}
                                            required />
                                        <p></p>
                                        {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída, clique no botão abaixo para concluir a ação.</p><p></p></>) : (<p></p>)}
                                        <p align='center'><button className={Style.btn_atualizar} onClick={ConfirmarExclusao}>Confirmar Exclusão do Arquivo</button></p>
                                    </div>
                                )}


                                <div>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    {textoEnviado && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Processo Atualizado com Sucesso!</strong>
                                        </p>
                                    )}
                                    {arquivoEnviado && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Arquivo Enviados com Sucesso!</strong>
                                        </p>
                                    )}
                                    {encaminhado && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Processo Encaminhado com Sucesso!</strong>
                                        </p>
                                    )}
                                    {encaminhadoExclusao && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Solicitação de Exclusão de Arquivo Feita Com Sucesso!</strong>
                                        </p>
                                    )}
                                    {aprovado && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Ciente Gravado com Sucesso!</strong>
                                        </p>
                                    )}
                                </div>
                            </FormGroup>
                            {/* </Form> */}

                        </Box>
                    </div>
                </Form>

            </div >
            <div id="htmlProcessoNovo" className={Style.box_container2}>
                <FormGroup className={Style.form_group}>
                    <Table>
                        <Row>
                            <Col>
                                <Box id='boxlista'>
                                    <h4 style={{ textAlign: 'center' }}>INFORMAÇÕES DO PROCESSO</h4>
                                    <div className={Style.boxlista}>
                                        <VerticalTimeline layout="1-column">
                                            {status
                                                ? status
                                                    .sort((a, b) => new Date(b.DataMovimento) - new Date(a.DataMovimento)) // Ordena por DataMovimento de forma decrescente
                                                    .map((detalhes) => {
                                                        //console.log(detalhes.Motivo);
                                                        let wMotivoEstilizado = detalhes.Motivo;
                                                        wMotivoEstilizado = wMotivoEstilizado.replace(/<p>/g, '<p style={{fontSize: \'12px\'}}>');

                                                        //console.log(wMotivoEstilizado);
                                                        const nomeArquivo = detalhes.Motivo.match(/arquivo: <strong>(.*?)</)?.[1];
                                                        const arquivoEncontrado = encontrarArquivo(nomeArquivo);
                                                        // console.log(arquivoEncontrado);
                                                        // Condicional para aplicar classe de estilo se 'excluído do processo' estiver presente
                                                        const estiloTexto = detalhes.Motivo.includes('excluído do processo') ? { color: 'red' } : {};
                                                        return (
                                                            <VerticalTimelineElement
                                                                key={detalhes.CodigoStatusProcessoInterno}
                                                                // date={detalhes.DataMovimento}
                                                                iconStyle={{ background: '#F1F5F9', color: '#1b4f72' }}
                                                                icon={<AiFillEdit />}
                                                                style={{ margin: '0', borderBottom: '2px solid #fff' }}
                                                            >
                                                                {detalhes.Motivo.includes('Arquivo Inserido Por:') && (
                                                                    <>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '13px' }}>
                                                                            <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }}></span>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', fontSize: '13px' }}>
                                                                                <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataMovimento).format("DD/MM/yyyy")}</span>
                                                                                <button onClick={() => openModalArquivo(detalhes.Motivo.match(/Arquivo: (.*?)</)?.[1])} className={Style.VerAquivo_btn}>
                                                                                    Ver Arquivo Inserido<RiPhoneFindLine />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {!detalhes.Motivo.includes('Arquivo Inserido Por:') && (
                                                                    !detalhes.Motivo.includes('Solicitação de exclusão do arquivo:') && (
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '13px' }}>
                                                                            <span dangerouslySetInnerHTML={{ __html: wMotivoEstilizado }} style={estiloTexto}></span>
                                                                            <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataMovimento).format("DD/MM/yyyy")}</span>
                                                                        </div>
                                                                    )
                                                                )}
                                                                {/* {detalhes.Motivo.includes('Solicitação de exclusão do arquivo:') && (
                                                                    <>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: '12px' }}>
                                                                            <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }} style={estiloTexto}></span>
                                                                            <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataMovimento).format("DD/MM/yyyy")}</span>
                                                                        </div>
                                                                        {wPerfil === '33' && arquivoEncontrado && arquivoEncontrado.Status === 0 && (
                                                                            <div style={{ textAlign: 'center', marginTop: '1em' }}>
                                                                                <button
                                                                                    onClick={() => showModalExcluirArquivo(arquivoEncontrado.Arquivo, arquivoEncontrado.CodigoArquivoProcessoInterno, detalhes.CodigoPerfil, arquivoEncontrado.Descricao)}
                                                                                    className={Style.VerAquivo_btn}
                                                                                >
                                                                                    Ver Arquivo <RiPhoneFindLine />
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )} */}
                                                            </VerticalTimelineElement>
                                                        );
                                                    })
                                                : ''}
                                        </VerticalTimeline>
                                    </div>
                                </Box>
                            </Col>
                            <Col className={Style.customCol}>
                                <Box id='boxarquivo'>
                                    <h4 style={{ textAlign: 'center' }}>ARQUIVOS DO PROCESSO</h4>
                                    <div className={Style.boxarquivo}>

                                        {showModalArquivo && modalContent && (
                                            <iframe
                                                title="Visualização do Arquivo"
                                                //src={LinkArquivo + modalContent}
                                                src={`${LinkArquivo}${modalContent}#zoom=80`}
                                                width="100%"
                                                height="750px"
                                            ></iframe>
                                        )}
                                    </div>
                                </Box>
                            </Col>
                        </Row>
                    </Table>


                </FormGroup>
            </div>


            <Modal show={loading} className={Style.Modal} centered >
                <Modal.Body className={Style.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={modalDuploCheck} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <h3>Confirmação de Identidade </h3><PiIdentificationBadge />
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Por favor, confirme sua senha para prosseguir</p>
                    <Form onSubmit={DuploCheck}>
                        {avisoDuploCheck ? (<p style={{ color: '#E74C3C' }}>A senha digitada não confere com a senha de usuário</p>) : (null)}
                        <Row>
                            <Col>
                                <Form.Label className={Style.label_modal}>Sua senha:</Form.Label>
                            </Col>
                            <Col md={9}>
                                <Form.Control className={Style.form_control} type="password" name="senha" value={senha} onChange={(event) => setSenha(event.target.value)} />
                            </Col>
                        </Row>

                        <button type="submit" className={Style.btn_salvar}> Salvar </button>
                        <button type="button" className={Style.btn_cancelar} onClick={closeAndResetModalDuploCheck}> Cancelar </button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={Style.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={Style.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

            {/* <Modal show={modalExcluirArquivo} className={Style.Modal} centered>
                <Modal.Header closeButton onClick={handleCloseExcluirArquivo} className={Style.Modal_header}>
                    <h3>Confirmação de Exclusão de Arquivo</h3>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <div>
                        <embed
                            title='Documento em Análise'
                            style={{ width: '15vw', height: '50vh', margin: '0 auto' }}
                            src={LinkArquivo + arquivoAnalise}
                        />
                    </div>
                    <Form>
                        {formNaoAutorizarExclusao ? (
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '.5em' }}>

                                <Form.Label className={Style.label_modal} style={{ textAlign: 'left', marginLeft: '1em', marginTop: '1em' }}>Motivo da recusa:</Form.Label>
                                <textarea className={Style.form_control_textarea} style={{ margin: '0 auto' }} name="motivo" value={motivoNaoExclusao} onChange={(event) => setMotivoNaoExclusao(event.target.value)} />

                                <Row>
                                    <Col>
                                        <button
                                            type="button"
                                            onClick={handleNaoAutorizarExclusao}
                                            style={{
                                                backgroundColor: '#CB4335',
                                                color: '#ffffff',
                                                fontWeight: '600',
                                                borderRadius: '5px',
                                                border: '0',
                                                fontSize: '14px',
                                                marginTop: '1em',
                                                transition: 'box-shadow 0.3s ease',  // Adiciona uma transição suave
                                                boxShadow: 'none',  // Sombra inicial
                                            }}
                                            onMouseOver={(e) => (e.currentTarget.style.boxShadow = 'rgba(0, 0, 0, 0.24) 0px 3px 8px')}  // Adiciona sombra no hover
                                            onMouseOut={(e) => (e.currentTarget.style.boxShadow = 'none')}  // Remove sombra quando o mouse sai
                                        >
                                            Não Autorizar Exclusão
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <FormGroup>
                                <button className={Style.btn_send} onClick={ConfirmarExclusao}>
                                    Confirmar Exclusão
                                </button>
                                <button
                                    className={Style.btn_excluir}
                                    onClick={NaoAutorizarExclusao}
                                >
                                    Não Autorizar Exclusão
                                </button>
                            </FormGroup>
                        )}
                    </Form>
                </Modal.Body>
            </Modal> */}

            {/* <Modal show={showModalArquivo} onHide={closeModal} fullscreen={fullscreen}>
                <Modal.Header closeButton className={Style.Modal_header}>
                    <Modal.Title >Visualização do Arquivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent && <iframe title="Visualização do Arquivo" src={LinkArquivo + modalContent} width="100%" height="850px"></iframe>}
                </Modal.Body>
            </Modal> */}
            {login && (<Navigate to="/" replace={true} />)}
        </div >


    )


}
export default VerProcessoNew