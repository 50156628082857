import React from "react"
//import Form from 'react-bootstrap/Form'
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Col, Table } from "react-bootstrap";
//import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import Row from 'react-bootstrap/Row';
import moment from "moment";
import Menu from "../Layout/Menu";
import MenuArea from "../Layout/MenuArea";
import StyleForm from "./Solicitacoes.module.css";
import { IoMdArrowBack, IoMdPrint } from "react-icons/io"
import { BsFillArrowUpCircleFill, BsFillArrowDownCircleFill } from 'react-icons/bs';
//import { useNavigate } from "react-router-dom";
//import MinhaImagem from '../img/tela.jpg'; // Importe a imagem
import { Box } from "@mui/material";
import ReactApexCharts from 'react-apexcharts';
import { differenceInDays } from 'date-fns';
import { Navigate } from "react-router";
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';

let seriesFisica = [''];
let seriesFinanceira = [''];

function Solicitacoes() {

    const [loading, setLoading] = useState(true);
    var wTokenLocal = localStorage.getItem("token");
    var wPerfil = localStorage.getItem("CodigoPerfil");
    const [login, setLogin] = useState(false);
    const wCodConvenio = useParams();
    //const navigate = useNavigate();
    const [pt, setPt] = useState([]);
    const [ptConsolidadoElementos, setPtConsolidadoElementos] = useState([]);
    const [solicitacaoFinalizadas, setSolicitacaoFinalizadas] = useState([]);
    const [solicitacaoNaoFinalizadas, setSolicitacaoNaoFinalizadas] = useState([]);
    const [financeiroConvenio, setFinanceiroConvenio] = useState([]);
    const [valoresFinanceiro, setValoresFinanceiro] = useState([]);

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    // }

    // function handleClick() {
    //     navigate(-1);
    // }

    // const [showChart, setShowChart] = useState(false);

    // useEffect(() => {
    //   // Função para verificar e atualizar a visibilidade do gráfico
    //   const handleWindowResize = () => {
    //     const screenWidth = window.innerWidth;
    //     const screenHeight = window.innerHeight;
    //     const minWidth = 1300;
    //     const minHeight = 1000;
    //     const mostraGrafico = screenWidth > minWidth && screenHeight > minHeight;
    //     setShowChart(mostraGrafico);
    //   };

    //   // Verifica o tamanho da tela quando o componente é montado
    //   handleWindowResize();

    //   // Adiciona um listener para verificar o tamanho da tela quando a janela for redimensionada
    //   window.addEventListener("resize", handleWindowResize);

    //   // Remove o listener quando o componente é desmontado
    //   return () => {
    //     window.removeEventListener("resize", handleWindowResize);
    //   };
    // }, []);

    //GRÁFICO EXECUÇÃO FÍSICA
    const optionsFisica = {
        chart: {
            height: 150,
            type: "radialBar"
        },

        series: [seriesFisica],

        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 15,
                    size: "70%"
                },

                dataLabels: {
                    showOn: "always",
                    name: {
                        offsetY: -10,
                        show: false,
                        color: "#888",
                        fontSize: "12px"
                    },
                    value: {
                        color: "#808B96",
                        fontSize: "20px",
                        show: true
                    }
                }
            }
        },

        stroke: {
            lineCap: "round",
        }
    };

    //GRÁFICO EXECUÇÃO FINANCEIRA
    const optionsFinanceira = {
        chart: {
            height: 100,
            type: "radialBar"

        },

        colors: ['#27AE60'],

        series: [seriesFinanceira],

        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 15,
                    size: "70%"
                },

                dataLabels: {
                    showOn: "always",
                    name: {
                        offsetY: -10,
                        show: false,
                        color: "#888",
                        fontSize: "12px"
                    },
                    value: {
                        color: "#808B96",
                        fontSize: "25px",
                        show: true
                    }
                }
            }
        },

        stroke: {
            lineCap: "round",
        }
    };

    function CarregaFinanceiroConvenio() {

        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var data = JSON.stringify({
            "CodigoConvenio": wCodConvenio.CodigoConvenio,
            "Data inicial": localStorage.getItem('DataInicioProjeto'),
            "Data final": localStorage.getItem('DataExpiracaoProjeto')
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Convenio/Financeiro?IsInclude=true',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro
                    // pega o codigo cadastrado

                    setFinanceiroConvenio(response.data.objetoRetorno);
                    setValoresFinanceiro(response.data.objetoRetorno.Financeiro);
                    //console.log(response.data.objetoRetorno.Financeiro);
                    //mostra percentagem de execução até hoje
                    const startDate = new Date(moment(response.data.objetoRetorno.DataInicio).format("yyyy-MM-DD"));
                    const endDate = new Date(moment(response.data.objetoRetorno.DataExpiracao).format("yyyy-MM-DD"));
                    const today = new Date();
                    const totalDays = differenceInDays(endDate, startDate);
                    const elapsedDays = differenceInDays(today, startDate);
                    const percentage = (elapsedDays / totalDays) * 100;
                    seriesFisica = Math.round(percentage);
                    //console.log(seriesFisica);

                    //mostra porcentagem do recurso executado até hoje
                    const valorTotalProgramado = response.data.objetoRetorno.Financeiro.ValorPrevisto; // Valor total programado
                    //console.log(valorTotalProgramado);
                    const valorExecutado = response.data.objetoRetorno.Financeiro.ValorExecutado; // Valor executado
                    //console.log(valorExecutado);
                    const percentualExecutado = (valorExecutado / valorTotalProgramado) * 100;
                    //console.log(percentualExecutado);
                    seriesFinanceira = Math.round(percentualExecutado);
                    //console.log(seriesFinanceira);
                    setLoading(false);

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    //setShowModalErro(true);
                    console.log('erro');
                }
            })
            .catch(error => {
                console.error(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //setShowModalErro(true);
            });
    }
    useEffect(() => {
        CarregaFinanceiroConvenio();
        // eslint-disable-next-line
    }, []);

    function AlimentaConsolidadoElementos() {
        setLoading(true);

        //console.log(wCodConvenio.CodigoConvenio);
        const wtoken = "Bearer " + wTokenLocal;

        const config = {
            method: "GET",
            url:
                urlAPIlocal + "/api/ApiPlanoTrabalho/" + wCodConvenio.CodigoConvenio,
            headers: {
                Authorization: wtoken,
                "Content-Type": "application/json",
            },
        };
        //console.group(config);
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    var dados = response.data.objetoRetorno;
                    //console.log(dados);
                    setPt(dados);
                    setPtConsolidadoElementos(dados.ConsolidadoDespesas.Elementos);
                    setLoading(false);

                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                }
            })
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //Retorno = true
            });
    }
    useEffect(() => {
        AlimentaConsolidadoElementos();
        // eslint-disable-next-line
    }, []);


    const getSolicitacoesFinalizadas = async () => {
        setLoading(true);

        const wtoken = "Bearer " + wTokenLocal;
        //console.log(wCodConvenio)

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoConvenio=' + wCodConvenio.CodigoConvenio + '&IndFinalizada=true&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                setSolicitacaoFinalizadas(data.objetoRetorno);
                setLoading(false);

                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacoesFinalizadas();
        //eslint-disable-next-line
    }, []);

    const getSolicitacoesNaoFinalizadas = async () => {
        setLoading(true);

        const wtoken = "Bearer " + wTokenLocal;
        //console.log(wCodConvenio)

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoConvenio=' + wCodConvenio.CodigoConvenio + '&IndFinalizada=false&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                setSolicitacaoNaoFinalizadas(data.objetoRetorno);
                setLoading(false);

                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacoesNaoFinalizadas();
        //eslint-disable-next-line
    }, []);


    return (
        <div className={StyleForm.page}>
            {wPerfil !== '37' ? <Menu /> : <MenuArea />}
            {/* <div> <button className={StyleForm.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}

            <div> <button className={StyleForm.btn}>
                <Link to={wPerfil !== '37' ? `/VerProjeto/${wCodConvenio.CodigoConvenio}` : '/index'}>
                    <IoMdArrowBack /> Voltar
                </Link>
            </button> </div>


            <div style={{ textAlign: 'center', color: '#2C3E50', marginBottom: '0', fontWeight: '700' }}><h3>{pt.Nome}</h3></div>

            <div className={StyleForm.box_container}>

                <Box className={StyleForm.box_execFisica}>

                    <h1>Progresso da Execução Física</h1>
                    <Row>

                        <Col>
                            <p>Início da execução: <strong>{moment(financeiroConvenio.DataInicio).format("DD/MM/yyyy")}</strong></p>
                            <p>Fim da execução: <strong>{moment(financeiroConvenio.DataExpiracao).format("DD/MM/yyyy")}</strong></p>
                        </Col>

                        <Col>
                            {/* {showChart && ( */}
                            <div className={StyleForm.grafico_execFisica}>
                                <ReactApexCharts
                                    options={optionsFisica}
                                    series={optionsFisica.series} //optionsFisica.series
                                    type="radialBar"
                                    height={150}
                                />
                            </div>
                            {/* )} */}
                        </Col>
                    </Row>
                </Box>

                <Box className={StyleForm.box_execFinanceira}>

                    <h1>Execução Financeira</h1>
                    <Row>

                        <Col>
                            <p>Previsto: <strong>{valoresFinanceiro.ValorPrevisto ? valoresFinanceiro.ValorPrevisto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</strong></p>
                            <p>Executado: <strong>{valoresFinanceiro.ValorExecutado ? valoresFinanceiro.ValorExecutado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</strong></p>
                        </Col>

                        <Col>

                            <div className={StyleForm.grafico_execFinanceira}>
                                <ReactApexCharts
                                    options={optionsFinanceira}
                                    series={optionsFinanceira.series}
                                    type="radialBar"
                                    height={150}
                                />
                            </div>

                        </Col>
                    </Row>
                </Box>

                <Box className={StyleForm.box_saldo}>
                    <h1>Saldo Disponível</h1>
                    <p>
                        {
                            (() => {
                                const wTotalCreditosProjeto = valoresFinanceiro.TotAportes + valoresFinanceiro.TotRepasses + valoresFinanceiro.TotRendimentos;
                                const wTotalDebitosProjeto = valoresFinanceiro.TotPagamentosBruto + valoresFinanceiro.SolicAPagar + valoresFinanceiro.TotDevAportes;
                                const wSaldoBancario = wTotalCreditosProjeto - wTotalDebitosProjeto;
                                return wSaldoBancario ? wSaldoBancario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00';
                            })()
                        }
                    </p>
                </Box>
            </div>


            <div className={StyleForm.Creditos_Debitos_Container}>
                <Box className={StyleForm.Creditos}>
                    <h4 align="center">Créditos <BsFillArrowUpCircleFill /></h4>

                    <Row>

                        <div className={StyleForm.Creditos_Row1}>
                            <p align="center">Respasses do Concedente: <br /> <h6>{valoresFinanceiro.TotRepasses ? valoresFinanceiro.TotRepasses.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</h6>  <Link to="RelatorioRepasses" target="_blank">Ver Detalhes</Link></p>

                            <p align="center">Rendimentos Financeiros: <br /> <h6>{valoresFinanceiro.TotRendimentos ? valoresFinanceiro.TotRendimentos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</h6> <Link to="RelatorioRendimentos" target="_blank">Ver Detalhes</Link></p>
                            <p align="center" style={{ marginBottom: '2.5em' }}>Aportes Financeiros: <br /> <h6>{valoresFinanceiro.TotAportes ? valoresFinanceiro.TotAportes.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</h6></p>
                        </div>

                    </Row>
                    <Row>

                        <div className={StyleForm.Creditos_Row2}>

                            <p align="center">Total de Recursos Recebidos: <br />
                                <h6>
                                    {
                                        (() => {
                                            const wTotalCreditosProjeto = valoresFinanceiro.TotAportes + valoresFinanceiro.TotRepasses + valoresFinanceiro.TotRendimentos;
                                            return wTotalCreditosProjeto ? wTotalCreditosProjeto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00';
                                        })()
                                    }
                                </h6>
                            </p>
                        </div>

                    </Row>
                </Box>

                <Box className={StyleForm.Debitos}>
                    <h4 align="center">Débitos <BsFillArrowDownCircleFill /></h4>

                    <Row>

                        <div className={StyleForm.Debitos_Row1}>
                            <p align="center">Despesas Pagas: <h6>{valoresFinanceiro.TotPagamentosBruto ? valoresFinanceiro.TotPagamentosBruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</h6> <Link to={`/RelatPagtos/${wCodConvenio.CodigoConvenio}`} target="_blank">Ver Detalhes</Link></p>
                            <p align="center">Despesas Solicitadas à pagar: <h6>{valoresFinanceiro.SolicAPagar ? valoresFinanceiro.SolicAPagar.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</h6> <Link to={`/RelatRestosAPagar/${wCodConvenio.CodigoConvenio}`} target="_blank">Ver Detalhes</Link></p>
                            <p style={{ marginBottom: '2.5em' }} align="center">Aportes Devolvidos: <h6>{valoresFinanceiro.TotDevAportes ? valoresFinanceiro.TotDevAportes.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</h6></p>
                        </div>

                    </Row>
                    <Row>

                        <div className={StyleForm.Debitos_Row2}>

                            <p align="center">Total Recursos utilizados no Projeto:
                                <h6>
                                    {
                                        (() => {
                                            const wTotalDebitosProjeto = valoresFinanceiro.TotPagamentosBruto + valoresFinanceiro.SolicAPagar + valoresFinanceiro.TotDevAportes;
                                            return wTotalDebitosProjeto ? wTotalDebitosProjeto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00';
                                        })()

                                    }
                                </h6>
                            </p>
                        </div>

                    </Row>

                </Box>
            </div>

            <div align="center" className={StyleForm.table_container}>

                <h4>RECURSOS CONSOLIDADOS POR ELEMENTO ( PLANEJADOS )</h4>

                <Table align="center">

                    <thead>
                        <tr>
                            <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D4E6F1', color: '#154360', textAlign: "left" }}>Tipo de Despesa</th>
                            <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D4E6F1', color: '#154360', textAlign: "right" }}>Valor Previsto</th>
                            <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D4E6F1', color: '#154360', textAlign: "right" }}>Valor Gasto</th>
                            <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D4E6F1', color: '#154360', textAlign: "center" }}></th>
                            <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D4E6F1', color: '#154360', textAlign: "center" }}></th>
                            <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D4E6F1', color: '#154360', textAlign: "right" }}>Saldo Disponível</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(ptConsolidadoElementos.map((ConsolidadoElementos, indexConsolidado) => (
                            <tr key={indexConsolidado}>
                                <td style={{ padding: '5px', fontSize: '14px' }} align="left">{ConsolidadoElementos.Nome}</td>
                                <td style={{ padding: '5px', fontSize: '14px' }} align="right">{ConsolidadoElementos.Total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                <td style={{ padding: '5px', fontSize: '14px' }} align="right">
                                    {
                                        (() => {
                                            const totalGastoTodasFontes =
                                                ConsolidadoElementos.TotalGastoFonte1 +
                                                ConsolidadoElementos.TotalGastoFonte2 +
                                                ConsolidadoElementos.TotalGastoFonte3 +
                                                ConsolidadoElementos.TotalGastoFonte4 +
                                                ConsolidadoElementos.TotalGastoFonte5;
                                            return totalGastoTodasFontes.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                                        })()
                                    }
                                </td>
                                <td></td>
                                <td style={{ padding: '5px', fontSize: '14px', textAlign: 'right' }} align="center">
                                    {(() => {
                                        const totalGastoTodasFontes =
                                            ConsolidadoElementos.TotalGastoFonte1 +
                                            ConsolidadoElementos.TotalGastoFonte2 +
                                            ConsolidadoElementos.TotalGastoFonte3 +
                                            ConsolidadoElementos.TotalGastoFonte4 +
                                            ConsolidadoElementos.TotalGastoFonte5;

                                        const percentagemsaldo = ((totalGastoTodasFontes / ConsolidadoElementos.Total) * 100).toFixed(2);

                                        const barWidth = percentagemsaldo >= 100 ? '100%' : `${percentagemsaldo}%`;

                                        return (
                                            <div style={{ display: 'flex', alignItems: 'center', width: '150px', justifyContent: 'space-between' }}>
                                                <div className={StyleForm.progress_valor} style={{ height: '15px', width: barWidth }}>
                                                    {percentagemsaldo >= 100 ? '100 %' : percentagemsaldo + ' %'}
                                                </div>
                                                <span style={{ marginLeft: '5px', whiteSpace: 'nowrap' }}>{percentagemsaldo >= 100 ? '100 %' : percentagemsaldo + ' %'}</span>
                                            </div>
                                        );
                                    })()}
                                </td>

                                <td style={{ padding: '5px', fontSize: '14px' }} align="right">
                                    {
                                        (() => {
                                            const totalGastoTodasFontes =
                                                ConsolidadoElementos.TotalGastoFonte1 +
                                                ConsolidadoElementos.TotalGastoFonte2 +
                                                ConsolidadoElementos.TotalGastoFonte3 +
                                                ConsolidadoElementos.TotalGastoFonte4 +
                                                ConsolidadoElementos.TotalGastoFonte5;
                                            const totalSaldoElemento = ConsolidadoElementos.Total - totalGastoTodasFontes;
                                            return totalSaldoElemento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                                        })()
                                    }
                                </td>
                            </tr>
                        )))}
                    </tbody>
                </Table>
            </div>

            {wPerfil !== '37' && solicitacaoFinalizadas && (
                <div align="center" className={StyleForm.table_container}>
                    <h4>SOLICITAÇÕES CONFIRMADAS</h4>
                    <Table striped>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "left" }}>N° Solicitação</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "left" }}>Elemento</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "left" }}>Credor</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "left" }}>Data</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "right" }}>Valor</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {solicitacaoFinalizadas.length > 0 ? (
                                solicitacaoFinalizadas.map((item) => (
                                    <tr key={item.CodigoSolicitacao}>
                                        <td style={{ padding: '5px', fontSize: '14px' }}>{item.CodigoSolicitacao}</td>
                                        <td style={{ padding: '5px', fontSize: '14px' }}>{item.ConvenioElemento.Elemento.Nome}</td>
                                        <td style={{ padding: '5px', fontSize: '14px' }}>{item.Cadastro.Nome}</td>
                                        <td style={{ padding: '5px', fontSize: '14px' }}>{moment(item.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                        <td style={{ padding: '5px', fontSize: '14px', textAlign: "right" }}>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td align="center">
                                            <button className={StyleForm.print_btn}>
                                                <Link to={`/PrintSolic/${item.CodigoSolicitacao}`} target="_blank">
                                                    <IoMdPrint />
                                                </Link>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td style={{ padding: '5px', fontSize: '14px' }}>Não há Solicitações</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}

            {wPerfil !== '37' && solicitacaoNaoFinalizadas && (
                <div align="center" className={StyleForm.table_container}>

                    <h4>SOLICITAÇÕES NÃO CONFIRMADAS</h4>

                    <Table striped >
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "left" }}>N° Solicitação</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "left" }}>Elemento</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "left" }}>Credor</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "left" }}>Data</th>
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "right" }}>Valor</th>
                                {/* <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "center" }}>Status</th> */}
                                <th style={{ padding: '.3em', fontSize: '18px', fontWeight: 'bold', backgroundColor: '#D6DBDF', color: '#154360', textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {solicitacaoNaoFinalizadas &&
                                (solicitacaoNaoFinalizadas.length > 0 ?
                                    (solicitacaoNaoFinalizadas.map((item2) => (
                                        <tr key={item2.CodigoSolicitacao}>
                                            <td style={{ padding: '5px', fontSize: '14px' }}>{item2.CodigoSolicitacao}</td>
                                            <td style={{ padding: '5px', fontSize: '14px' }}>{item2.ConvenioElemento.Elemento.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '14px' }}>{item2.Cadastro.Nome}</td>
                                            <td style={{ padding: '5px', fontSize: '14px' }}>{moment(item2.DataSolicitacao).format("DD/MM/yyyy")}</td>
                                            <td style={{ padding: '5px', fontSize: '14px', textAlign: "right" }}>{item2.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td align="center">
                                                <button className={StyleForm.print_btn}><Link to={`/PrintSolic/${item2.CodigoSolicitacao}`} target="_blank"><IoMdPrint /></Link>
                                                </button>
                                            </td>
                                            {/* <td style={{ padding: '5px', fontSize: '14px', textAlign: "center" }}>status</td> */}
                                        </tr>
                                    ))
                                    ) : (<tr><td style={{ padding: '5px', fontSize: '14px' }}>Não há Solicitações</td></tr>))}


                        </tbody>
                    </Table>
                </div>
            )}

            <Modal show={loading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            {login && (<Navigate to="/" replace={true} />)}

            {/* <div align="center"><img src={MinhaImagem} alt="Modelo atual" /></div> */}
        </div>
    )
}
export default Solicitacoes