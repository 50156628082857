import { Table } from 'react-bootstrap';
import cabecalho from '../img/Cabecalho.png';
import { useEffect, useState } from 'react';
import axios from 'axios';
import urlAPIlocal from '../../global';
import { FaRegSquareCheck } from "react-icons/fa6";
import { Navigate } from "react-router-dom";

import Styles from './Index.module.css';

function EscolhaProjeto() {

    var wtokenBearer = "Bearer " + localStorage.getItem("token");
    var wCodCadastro = localStorage.getItem("CodigoCadastro");

    const [projetos, setProjetos] = useState([]);
    const [redirect, setRedirect] = useState(false);

    const getProjetos = async (event) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio?CodigoCoordenacaoPedagogia=' + wCodCadastro,
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno);
                setProjetos(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getProjetos();
        // eslint-disable-next-line
    }, []);

    function handleRedirect(wCodConvenio) {
        localStorage.setItem("CodigoProjeto", wCodConvenio);
        setRedirect(true);
    }

    return (
        <div>
            <header style={{ height: '10vh', textAlign: 'center', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                <img style={{ width: '15vw' }} src={cabecalho} alt='FAUSCS' />
            </header>
            <div style={{ width: '100%', height: '95vh', display: 'flex', justifyContent: 'center', paddingTop: '8vh', backgroundColor: '#f8f9f9' }}>

                <div style={{ width: '60%', height: 'fit-content', borderRadius: '8px', border: '1px solid #eee', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', padding: '1em', backgroundColor: '#fff' }}>
                    <h6 style={{ color: '#5D6D7E', textAlign: 'center' }}>ESCOLHA O PROJETO QUE DESEJA GERENCIAR</h6>
                    <br></br>
                    <Table hover>
                        <thead>
                            <tr>
                                <th style={{ padding: '.3em', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#FFFFFF', color: '#154360', textAlign: "left" }}>Projetos</th>
                                <th style={{ padding: '.3em', fontSize: '16px', fontWeight: 'bold', backgroundColor: '#ffffff', color: '#154360', textAlign: "right" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {projetos &&

                                (projetos.map((projeto) => (
                                    <tr key={projeto.CodigoConvenio}>
                                        <td style={{ padding: '5px', fontSize: '14px' }}>{projeto.Nome}</td>
                                        <td align="right">
                                            <button className={Styles.btn_escolherProjeto} type="button" onClick={() => handleRedirect(projeto.CodigoConvenio)} title='Gerenciar esse projeto'>
                                                <FaRegSquareCheck />
                                            </button>
                                        </td>
                                    </tr>
                                )))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            {redirect && (<Navigate to="/index" replace={true} />)}
        </div>
    )
}
export default EscolhaProjeto