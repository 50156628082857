import { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router";
import React, { useEffect } from 'react';
import Menu from "../Layout/Menu"
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";

import { AiOutlineSearch, AiOutlineEdit } from "react-icons/ai";
import { FaFilter, FaSearch } from "react-icons/fa"

import Style from "./ListCadastro.module.css";
//import Form from 'react-bootstrap/Form';
import { Pagination } from 'react-bootstrap';
import moment from "moment";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';

//var now = new Date();
//var startDate = new Date('2023-04-04');



function ListCadastro() {

    const wPerfil = localStorage.getItem("CodigoPerfil");
    const [loading, setLoading] = useState(true);
    const [cadastro, setCadastro] = useState([]);
    const [busca, setBusca] = useState('');
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [login, setLogin] = useState(false);
    const [optFiltro, setOptFiltro] = useState('10');

    var wTokenLocal = localStorage.getItem("token");


    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    const getCadastro = async (event) => {
        setLoading(true);
        const wtoken = "Bearer " + wTokenLocal;

        var wParametros = '';

        // { wPerfil === '24' && (wParametros = '&Aprovado=3&IsInclude=true') }

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/cadastro?CodigoTipoCadastro=33' + wParametros,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setCadastro(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno)
            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getCadastro();
        // eslint-disable-next-line
    }, []);

    // Calculate the total number of pages
    const items = [];
    if (cadastro && cadastro !== null) {

        var totalPages = 0;
        if (cadastro.length > 0 && (totalPages = Math.ceil(cadastro.length / itemsPerPage)));

        if (itensFiltrados.length > 0 && (totalPages = Math.ceil(itensFiltrados.length / itemsPerPage)));



        // Calculate the indexes of the first and last items of the current page
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Get the items of the current page
        var currentItems = cadastro.slice(indexOfFirstItem, indexOfLastItem);


        if (itensFiltrados.length > 0 && (currentItems = itensFiltrados.slice(indexOfFirstItem, indexOfLastItem)));


        // Generate the list of items of pagination
        //const items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => setCurrentPage(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
    }


    const handleSearch = (event) => {
        const value = event.target.value;
        //getCadastro();
        const filteredItems = cadastro.filter((item) =>
            item.Nome.toLowerCase().includes(value.toLowerCase())
        );
        setItensFiltrados(filteredItems);
        setBusca(value);
        //console.log(filteredItems);
    };

    const handleFiltro = (event) => {
        setOptFiltro(event.target.value);
        //console.log(event.target.value);
        setLoading(true);
        var wValorSelect = event.target.value;
        var wParametros = '';

        if (wValorSelect === '10') {
            wParametros = '&IsInclude=false'
        } else if (wValorSelect === '0') {
            wParametros = '&Aprovado=0&IsInclude=false'
        } else if (wValorSelect === '1') {
            wParametros = '&Aprovado=1&IsInclude=false'
        } else if (wValorSelect === '2') {
            wParametros = '&Aprovado=2&IsInclude=false'
        }
        // else if (wValorSelect === '3') {
        //     wParametros = '&Aprovado=3&IsInclude=true'
        // }
        //console.log(wParametros);

        const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/cadastro?CodigoTipoCadastro=33' + wParametros,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setCadastro(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                if (error.response.Status === 401) {
                    setLogin(true);
                }

            });


        // useEffect(() => {
        //     getCadastroFiltrado();
        //     // eslint-disable-next-line
        // }, []);

    };

    return (

        <div className={Style.page}>

            <Menu />


            {/* <div align="center">
                <span>Filtrar Lista </span>
                <select value={optFiltro} onChange={handleFiltro}>
                    <option value="10">Todos</option>
                    <option value="1">Aprovados</option>
                    <option value="0">Em Análise</option>
                    <option value="2">Reprovados</option>
                </select>
            </div> */}

            <div className={Style.container}>
                <div className={Style.header}>

                    <h1>Fornecedores Cadastrados</h1>

                    <label><FaFilter /></label>
                    <select value={optFiltro} onChange={handleFiltro} className={Style.select_filter}>
                        <option value="10">Todos</option>
                        <option value="1">Aprovados</option>
                        <option value="0">Em Análise</option>
                        {/* <option value="3">Enviado para Aprovação</option> */}
                        <option value="2">Reprovados</option>
                    </select>

                    <label><FaSearch /></label>
                    <input type="text" value={busca} onChange={handleSearch} />

                </div>

                <div className={Style.table_container}>



                    <Table bordered className={Style.table}>
                        <thead>
                            <tr>
                                <th className={Style.th}>Número</th>
                                <th className={Style.th}>Nome</th>
                                <th className={Style.th}>CNPJ</th>
                                <th className={Style.th_prazo}>Prazo para aprovação</th>
                                <th></th>
                                <th className={Style.th_btn}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* cadastro.length > 0 ? */}

                            {cadastro &&
                                (itensFiltrados.length > 0 ?
                                    (
                                        currentItems
                                            .sort((a, b) => new Date(b.DataAprovacao) - new Date(a.DataAprovacao)) // Ordena por DataAprovacao de forma decrescente
                                            .map((cadastrado) => (
                                                cadastrado.CodigoCadastro === 151 ? null : (
                                                    <tr key={cadastrado.CodigoCadastro}>
                                                        <td>{cadastrado.CodigoCadastro}</td>
                                                        <td>{cadastrado.Nome}</td>
                                                        <td>{cadastrado.NumeroCnpj}</td>
                                                        <td className={Style.td_prazo}>
                                                            {cadastrado.aprovado ? <p></p> :
                                                                (Math.max(0, 15 - Math.floor((new Date() - new Date(moment(cadastrado.dataEmissao).format("YYYY-MM-DD"))) / (1000 * 60 * 60 * 24))) === 0 ? <p className={Style.prazo_expirado}>Prazo Expirado</p> :
                                                                    <p className={Style.prazo}>{Math.max(0, 15 - Math.floor((new Date() - new Date(moment(cadastrado.DataEmissao).format("YYYY-MM-DD"))) / (1000 * 60 * 60 * 24)))} dias restantes</p>)}

                                                        </td>
                                                        {/* <td style={{ width: `${progress}%` }}>{expired ? <div>Expirado</div> : <div>{15 - Math.floor(progress / 6.66667)} dias restantes</div>}</td> */}

                                                        <td align="center" >{cadastrado.Aprovado ? <p className={Style.aprovado}>Aprovado</p> : <p className={Style.nao_aprovado}>Não Aprovado</p>}</td>
                                                        <td className={Style.td_btn}>
                                                            <button className={Style.btn_look}>
                                                                <Link to={`/VerCadastro/${cadastrado.CodigoCadastro}`}>
                                                                    <AiOutlineSearch title="Abrir" />
                                                                </Link>
                                                            </button>
                                                            {wPerfil === '17' && (<button className={Style.btn_edit}><Link to={`/editCadastro/${cadastrado.CodigoCadastro}`}><AiOutlineEdit title="Editar" /></Link></button>)}
                                                            {/* <button className={Style.btn_edit}>
                                                        <Link to={`/editCadastro/${cadastrado.CodigoCadastro}`}>
                                                            <AiOutlineEdit title="Editar" />
                                                        </Link>
                                                    </button> */}
                                                        </td>
                                                    </tr>
                                                )))
                                    ) :
                                    (
                                        currentItems
                                            .sort((a, b) => new Date(b.DataAprovacao) - new Date(a.DataAprovacao)) // Ordena por DataAprovacao de forma decrescente
                                            .map((cadastrado) => (
                                                cadastrado.CodigoCadastro === 151 ? null : (
                                                    <tr key={cadastrado.CodigoCadastro}>
                                                        <td>{cadastrado.CodigoCadastro}</td>
                                                        <td>{cadastrado.Nome}</td>
                                                        <td>{cadastrado.NumeroCnpj}</td>
                                                        <td className={Style.td_prazo}>
                                                            {cadastrado.aprovado ? <p></p> :
                                                                (Math.max(0, 15 - Math.floor((new Date() - new Date(moment(cadastrado.dataEmissao).format("YYYY-MM-DD"))) / (1000 * 60 * 60 * 24))) === 0 ? <p className={Style.prazo_expirado}>Prazo Expirado</p> :
                                                                    <p className={Style.prazo}>{Math.max(0, 15 - Math.floor((new Date() - new Date(moment(cadastrado.DataEmissao).format("YYYY-MM-DD"))) / (1000 * 60 * 60 * 24)))} dias restantes</p>)}

                                                        </td>
                                                        {/* <td style={{ width: `${progress}%` }}>{expired ? <div>Expirado</div> : <div>{15 - Math.floor(progress / 6.66667)} dias restantes</div>}</td> */}

                                                        <td align="center" >
                                                            {
                                                                cadastrado.Aprovado === 0 ? <p className={Style.analise}>Em Análise</p> :
                                                                    cadastrado.Aprovado === 1 ? <p className={Style.aprovado}>Aprovado</p> :
                                                                        cadastrado.Aprovado === 2 ? <p className={Style.nao_aprovado}>Reprovado</p> :
                                                                            cadastrado.Aprovado === 3 ? <p className={Style.nao_aprovado}>Enviado para Aprovação</p> :
                                                                                ''
                                                            }</td>
                                                        <td className={Style.td_btn}>
                                                            <button className={Style.btn_look}>
                                                                <Link to={`/VerCadastro/${cadastrado.CodigoCadastro}`}>
                                                                    <AiOutlineSearch title="Abrir" />
                                                                </Link>
                                                            </button>
                                                            {(wPerfil === '1' || wPerfil === '17') && (<button className={Style.btn_edit}><Link to={`/editCadastro/${cadastrado.CodigoCadastro}`}><AiOutlineEdit title="Editar" /></Link></button>)}

                                                        </td>
                                                    </tr>
                                                )))
                                    ))
                                // ) : (<p>nenhum cadastro encontrado</p>)
                            }
                        </tbody>
                    </Table>

                    {/* Display the pagination */}
                    <Pagination>{items}</Pagination>

                    {/*  <div>
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      <div>{Math.max(0, 15 - Math.floor((new Date() - startDate) / (1000 * 60 * 60 * 24)))} dias restantes</div>
    </div>  */}
                    <Modal show={loading} className={Style.Modal} centered >
                        <Modal.Body className={Style.Modal_body}>
                            <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                        </Modal.Body>
                    </Modal>

                    {login && (<Navigate to="/" replace={true} />)}
                </div>
            </div>
        </div>



    )
}




export default ListCadastro;