import { useState, useEffect } from "react";
import React from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import urlAPIlocal from "../../global";
import Select from "react-select";
import Style from './EnviaAF.module.css'
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import moment from "moment";
import ReactDOMServer from 'react-dom/server';
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
import loadingGif from '../img/icons8-spinning-circle.gif';
import { ImSad2 } from 'react-icons/im';
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importe o estilo do editor

function EnviaAF() {

    const [login, setLogin] = useState(false);
    var wTokenLocal = localStorage.getItem("token");

    const wtoken = "Bearer " + localStorage.getItem("token");
    const NomeUsuario = localStorage.getItem('NomeUsuario');
    const username = localStorage.getItem('login');

    const { CodigoSolicitacao } = useParams();
    const [fornecedores, setFornecedores] = useState([]);
    const [fornecedoresSelecionado, setFornecedoresSelecionado] = useState(0);
    //const [registrosComCadastro, setRegistrosComCadastro] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const [anoSolic, setAnoSolic] = useState('');
    const [observacao, setObservacao] = useState('');
    const [infoAdicionais, setInfoAdicionais] = useState('1. Mencionar na Nota Fiscal o número desta Autorização de Fornecimento e do Processo de Compras;<br>' +
        '2. A mercadoria poderá ser recusada na entrega nas seguintes hipóteses:<br>' +
        'a) Caso a referida Nota Fiscal seja faturada por CNPJ diferente do informado na proposta comercial, a mesma poderá ser devolvida no ato da entrega juntamente com a mercadoria;<br>' +
        'b) O valor total da Nota Fiscal seja diferente do valor constante nesta autorização de fornecimento;<br>' +
        '3. A Contratada, recebendo esta Autorização de Fornecimento declara sob as penas da lei não possuir débitos com a seguridade social;<br>' +
        '4. A Contratada deverá entregar a documentação fiscal, acompanhada de boleto bancário e/ou dados bancários para pagamento, sendo de total responsabilidade o envio da conta bancária corretamente.<br>' +
        '5. Os pagamentos da FAUSCS se dão todas as sextas-feiras, o boleto bancário poderá ser recusado caso a data de vencimento se dê em outro dia da semana.<br>');
    const [assunto, setAssunto] = useState('');
    const [emailAdicional, setEmailAdicional] = useState('');
    // const mensagemInicial =
    //     'Prezados,<br><br>Solicitamos de V.Sᵃ. que apresente estimativa de preços para aquisição dos itens anexo especificados em anexo.<br><br><strong>Informações para envio de orçamento:</strong>' +
    //     '<br>1) Enviar sua proposta em papel timbrado, contendo o CNPJ, endereço e telefone;<br>2) Validade da proposta nunca inferior a 30 (trinta) dias a partir de sua emissão, para que sejam realizados os trâmites internos da FAUSCS para a compra;' +
    //     '<br><br>Pedimos gentilmente que nos envie sua melhor proposta <strong> até o prazo máximo de 5 dias </strong>';

    const [mensagem, setMensagem] = useState('');
    const [itens, setItens] = useState([]);
    const [itensSelecionados, setItensSelecionados] = useState(null);
    const [itensFornecedor, setItensFornecedor] = useState(false);
    const [infoFornecedor, setInfoFornecedor] = useState([]);
    const [itemSelecionado, setItemSelecionado] = useState(false);

    const [loading, setLoading] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);
    const [enviado, setEnviado] = useState(false);
    const dataHoraAtualAssinatura = moment().format('DD/MM/YYYY HH:mm:ss');
    const dataHoraAtual = moment().format('YYYYMMDD_HHmmss');
    const [avisoSenhaOk, setAvisoSenhaOk] = useState(false);
    const [modalAssinatura, setModalAssinatura] = useState(false);
    const [senha, setSenha] = useState('');
    const [avisoAssinatura, setAvisoAssinatura] = useState(false);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    //const [senhaVerificada, setSenhaVerificada] = useState(false);

    // const [modalAssinatura, setModalAssinatura] = useState(false);
    // const [senha, setSenha] = useState('');
    // const [avisoSenhaOk, setAvisoSenhaOk] = useState(false);
    // const [avisoDuploCheck, setAvisoDuploCheck] = useState(false);

    // const AssinaturaDigital = async (event) => {
    //     event.preventDefault();
    //     setLoading(true);

    //     var CodigoUsuario = localStorage.getItem('CodigoUsuario');
    //     var config = {
    //         method: 'GET',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + `/api/Usuarios?CodigoUsuario=${CodigoUsuario}&IsInclude=false`,
    //         headers: {
    //             'Authorization': wtoken,
    //             'Content-Type': 'application/json'
    //         },

    //     };

    //     axios(config)

    //         .then(function (response) {

    //             const data = response.data.objetoRetorno;

    //             //console.log(data)

    //             if (senha === data[0].Senha) {
    //                 setAvisoSenhaOk(true);
    //                 setAvisoDuploCheck(false);
    //                 setSenha('');
    //                 MontaAF();


    //             } else {
    //                 setModalAssinatura(true);
    //                 setAvisoDuploCheck(true);
    //                 setAvisoSenhaOk(false);
    //             }

    //             setLoading(false);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //             setLoading(false);

    //         });
    // };

    // const closeAndResetModalDuploCheck = () => {
    //     setModalAssinatura(false);
    //     setAvisoDuploCheck(false);
    //     setAvisoSenhaOk(false);
    //     setSenha('');
    // };


    // const abrirModalAssinatura = () => {
    //     setModalAssinatura(true);
    //   };

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function handleClose() {
        setTimeout(() => {
            setEnviado(false);
        }, 3000);

    }

    function handleCloseErro() {
        setShowModalErro(false);
    };


    const getFornecedoresSolic = async () => {
        setLoading(true);
        try {
            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/SolicitacaoItem?CodigoSolicitacao=" + CodigoSolicitacao + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;
            //console.log(data);

            // Filtrando e mapeando os registros desejados
            const registrosMapeados = data
                .filter(item => item.Cadastro && item.Cadastro.Nome !== "Definir") // Filtra apenas registros com a ramificação "Cadastro"
                .map(item => ({
                    CodCadastro: item.Cadastro.CodigoCadastro,
                    Nome: item.Cadastro.Nome
                }));

            // Definindo o novo estado com os registros mapeados
            //setRegistrosComCadastro(registrosMapeados);
            const registrosFiltrados = registrosMapeados.filter((registro, index, self) => {
                // Verifica se o CodCadastro já foi visto em registros anteriores
                const isUnique = self.findIndex((r) => r.CodCadastro === registro.CodCadastro) === index;
                return isUnique;

            });
            //console.log(registrosFiltrados);
            setLoading(false);
            setFornecedores(registrosFiltrados);

        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    };

    useEffect(() => {
        getFornecedoresSolic();
        // eslint-disable-next-line
    }, []);

    //Requisição SOLICITAÇÃO
    const getSolicitacao = async () => {
        //setLoading(true);
        //const wtoken = wtoken;
        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(configSolic);
        axios(configSolic)
            .then(function (responseSolic) {
                const data = responseSolic.data;
                //console.log(data.objetoRetorno[0].Convenio.Nome);
                //console.log(data.objetoRetorno[0].DataSolicitacao);
                setSolicitacao(data.objetoRetorno[0]);
                setObservacao(data.objetoRetorno[0].Observacao)
                const dataCompleta = new Date(data.objetoRetorno[0].DataSolicitacao);
                const ano = dataCompleta.getFullYear();
                setAnoSolic(ano);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    //setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacao();
        //eslint-disable-next-line
    }, []);

    const handleSelectFornecedor = (event) => {

        //limpa dados fornecedor escolhido anteriormente
        //setItens([]);
        //console.log(itensSelecionados);
        setItensSelecionados(null);
        //setItensFornecedor([]);
        setItemSelecionado(false);
        setFornecedoresSelecionado(event.target.value);
        //setFornecedoresSelecionado(0);
        getInfoFornecedor(event.target.value);
        getItens(event.target.value);


    };


    const getItens = async (wCodigoCadastro) => {

        setLoading(true);

        var configItem = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem?CodigoSolicitacao=' + CodigoSolicitacao + '&CodCadastro=' + wCodigoCadastro,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configItem);
        axios(configItem)

            .then(function (responseItem) {

                const data = responseItem.data;
                // Ordenar o array antes de atribuí-lo ao estado
                //console.log(data.objetoRetorno);
                const sortedData = data.objetoRetorno.sort((a, b) => {
                    if (a.NumeroItem < b.NumeroItem) {
                        return -1;
                    }
                    if (a.NumeroItem > b.NumeroItem) {
                        return 1;
                    }
                    return 0;
                });

                setItens(sortedData);
                setLoading(false);
                setItensFornecedor(true);
                //console.log(sortedData);
            }
            )
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    // useEffect(() => {
    //     getItens();
    //     // eslint-disable-next-line
    // }, []);

    const handleSelectItens = (selectedOptions) => {
        // Mapeie os valores selecionados para um array
        //const selectedFornecedores = selectedOptions.map(option => option.email);

        //const NomeFornedoresSelecionados = selectedOptions.map(option => option.label);
        //const nomesSeparadosPorPontoEVirgula = NomeFornedoresSelecionados.join(', ');
        //setNomeFornecedoresSelecionados(nomesSeparadosPorPontoEVirgula);

        // Use o método join para unir os emails com ';'
        //const emailsSeparadosPorPontoEVirgula = selectedFornecedores.join(', ');
        //console.log(emailsSeparadosPorPontoEVirgula);
        //console.log(selectedOptions);
        setItensSelecionados(selectedOptions);
        setItemSelecionado(true);
        //setSelecioneAtividade(false);
    };

    // Calcular o valor total dos itens
    let valorTotalDosItens = 0
    let valorTotalDoFrete = 0
    //console.log(itensSelecionados);
    if (itensSelecionados !== null) {
        valorTotalDosItens = itensSelecionados.reduce(
            (total, infoItem) => total + infoItem.valorTotal,
            0
        );

        // Calcular o valor total do frete
        valorTotalDoFrete = itensSelecionados.reduce(
            (total, infoItem) => total + infoItem.frete,
            0
        );

    }



    const getInfoFornecedor = async (wCodigoCadastro) => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Cadastro?CodigoCadastro=' + wCodigoCadastro + '&IsInclude=true',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data.objetoRetorno[0];
                setInfoFornecedor(data);
                //console.log(data);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleObservacaoChange = (value) => {
        setObservacao(value);
    };
    const handleInfoAdicionaisChange = (value) => {
        setInfoAdicionais(value);
    };

    const handleMensagemChange = (value) => {
        setMensagem(value);
    };

    // const handleMontaAF = async (event) => {
    //     event.preventDefault();
    //     setLoading(true);

    //     if (avisoSenhaOk === true) {

    //         MontaAF();

    //     } else {
    //         setLoading(false);
    //         setModalAssinatura(true);
    //     }

    // };


    function MontaAF(wEnvia) {
        //event.preventDefault();
        //console.log(wEnvia);
        setLoading(true);
        setBotaoDesabilitado(true);

        //so envia se colocou senha do usuario ( assinatura eletronica )
        if (wEnvia === 'enviaok') {
            setModalAssinatura(false);
            //console.log('entrou enviar');
            // Criando uma div
            const ArquivoMontado = (
                <div>

                    <table>
                        <thead></thead>
                        <tbody>
                            <tr>
                                <td >
                                    <div>
                                        <img src='https://fauscs.sistemacontroll.com.br/UploadsFauscs/Cabecalho.png' style={{ width: '350px', display: 'inline-block' }} alt="Header Image" />
                                    </div>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <div>
                                        <p style={{ fontSize: '24px', color: '#222', textAlign: 'center', marginBottom: '.5em' }}>Autorização de Fornecimento</p>
                                        <p style={{ fontSize: '20px', color: '#BA4A00', textAlign: 'center' }}>Processo de Compras n° {CodigoSolicitacao} / {anoSolic}</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <hr style={{ color: '#bbb' }}></hr>

                    <p style={{ fontSize: '20px', textAlign: 'center', color: '#626567', fontWeight: '600' }}>Dados do Fornecedor</p>

                    <table style={{ marginBottom: '.3em', borderCollapse: 'collapse', width: '100%', margin: '0 auto' }}>
                        <thead style={{ borderBottom: '2px solid #154360', borderTop: '0', borderRight: '0', borderLeft: '0', fontWeight: '600', fontSize: '14px' }}>
                            <tr style={{ color: '#2C3E50' }}>
                                <th style={{ padding: '0.3em' }}>Razão Social</th>
                                <th style={{ padding: '.3em' }}>CNPJ/CPF</th>
                                <th style={{ padding: '.3em' }}>Código</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '14px' }}>
                            {infoFornecedor && (
                                <tr>
                                    <td style={{ padding: '.3em' }}>{infoFornecedor.Nome}</td>
                                    <td style={{ padding: '.3em', textAlign: 'center' }}>{infoFornecedor.NumeroCnpj}</td>
                                    <td style={{ padding: '.3em', textAlign: 'center' }}>{infoFornecedor.CodigoCadastro}</td>
                                </tr>
                            )}

                        </tbody>

                        <thead style={{ borderBottom: '2px solid #154360', borderTop: '0', borderRight: '0', borderLeft: '0', fontWeight: '600', fontSize: '14px' }}>
                            <tr style={{ color: '#2C3E50' }}>
                                <th style={{ padding: '0.3em' }}>Endereço</th>
                                <th style={{ padding: '.3em' }}>CEP</th>
                                <th style={{ padding: '.3em' }}>Contato</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '14px' }}>
                            {infoFornecedor && (
                                <tr>
                                    <td style={{ padding: '.3em' }}>{infoFornecedor.Endereco}</td>
                                    <td style={{ padding: '.3em', textAlign: 'center' }}>{infoFornecedor.NumeroCep}</td>
                                    <td style={{ padding: '.3em', textAlign: 'center' }}>{infoFornecedor.Email.Endereco} <br></br> ({infoFornecedor.NumeroDdd}) {infoFornecedor.NumeroTelefone}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <p style={{ fontSize: '20px', textAlign: 'center', color: '#626567', fontWeight: '600' }}>Item(s)</p>

                    <table style={{ marginBottom: '.3em', borderCollapse: 'collapse', width: '100%', margin: '0 auto' }}>
                        <thead style={{ borderBottom: '2px solid #154360', borderTop: '0', borderRight: '0', borderLeft: '0', fontWeight: '600', fontSize: '14px' }}>
                            <tr style={{ color: '#2C3E50' }}>
                                <th style={{ padding: '0.3em' }}>Descrição</th>
                                <th style={{ padding: '0.3em', textAlign: 'center' }}>Quant.</th>
                                <th style={{ padding: '0.3em', textAlign: 'center' }}>Unid.</th>
                                <th style={{ padding: '0.3em', textAlign: 'right' }}>Valor Unitário</th>
                                <th style={{ padding: '0.3em', textAlign: 'right' }}>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '14px' }}>
                            {(itensSelecionados.map((infoItem) => (
                                <tr>
                                    <td style={{ padding: '.3em' }}>{infoItem.label}</td>
                                    <td style={{ padding: '0.3em', textAlign: 'center' }}>{infoItem.quantidade}</td>
                                    <td style={{ padding: '0.3em', textAlign: 'center' }}>{infoItem.unidade}</td>
                                    <td style={{ padding: '0.3em', textAlign: 'right' }}>{infoItem.valorUnit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    <td style={{ padding: '0.3em', textAlign: 'right' }}>{infoItem.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                </tr>
                            )))}
                        </tbody>

                        <thead style={{ textAlign: 'right', color: '#BA4A00', fontSize: '16px' }}>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Valor Total dos Itens</th>
                                <th>{valorTotalDosItens.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Frete</th>
                                <th>{valorTotalDoFrete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Valor Total</th>
                                <th>{((valorTotalDosItens) + (valorTotalDoFrete)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                            </tr>
                        </thead>
                    </table>

                    <div style={{ fontSize: '14px', color: '#767676', padding: '1em' }} dangerouslySetInnerHTML={{ __html: observacao }}></div>
                    <hr style={{ color: '#bbb' }}></hr>
                    <p style={{ fontSize: '14px', textAlign: 'center', color: '#626567', fontWeight: '600' }}>Informações Adicionais</p>
                    <div style={{ fontSize: '12px', color: '#767676', padding: '1em' }} dangerouslySetInnerHTML={{ __html: infoAdicionais }}></div>

                    <div style={{ textAlign: 'center', color: '#222000', fontSize: '14px' }}>
                        <p>Documento assinado eletronicamente em {dataHoraAtualAssinatura}</p>
                        <p>{NomeUsuario}</p>
                    </div>

                </div>
            );

            // Chama a função para enviar e-mail
            EnviaEmailAF(ArquivoMontado);
            setBotaoDesabilitado(false);

            // Retorna o HTML criado
            return ArquivoMontado;


        } else {
            setLoading(false);
            setModalAssinatura(true);
            setBotaoDesabilitado(false);
        }
    }

    function EnviaEmailAF(wArquivoMontado) {
        //console.log(itensSelecionados);
        //const Anexos = arquivosSelecionados

        const wHtmlArquivoMontado = ReactDOMServer.renderToStaticMarkup(wArquivoMontado);

        // Agora você pode usar a string HTML conforme necessário
        //console.log(wHtmlArquivoMontado);

        // Remove quebras de linha e substitui aspas
        const escapedHtml = wHtmlArquivoMontado.replace(/(\r\n|\n|\r)/g, '').replace(/"/g, "'");
        //console.log(escapedHtml);

        // Nome do arquivo
        var wNomeArquivoNovo = `AutorizacaoCompra_${infoFornecedor.CodigoCadastro}_${dataHoraAtual}.pdf`;

        var dataConverter = JSON.stringify({
            "pasta": 'ArquivosProcesso',
            "arquivo": wNomeArquivoNovo,
            "html": escapedHtml,
            "template": ''
        });
        //console.log(escapedHtml);
        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ApiPdf/ConverterHtmlParaPdf',
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
            data: dataConverter
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const dataConverter = response.data;
                if (dataConverter.isOk === true) {
                    //GRAVA ARQUIVO NA BASE

                    var datFiles = JSON.stringify({
                        "CodigoArquivo": 0,
                        "CodigoSolicitacao": CodigoSolicitacao,
                        "descricao": 'Autorização de Compra',
                        "arquivo": wNomeArquivoNovo,
                        "status": 0
                    })
                    var configFiles = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/ArquivoProcesso',
                        headers: {
                            'Authorization': wtoken,
                            'Content-Type': 'application/json'
                        },
                        data: datFiles
                    };
                    //console.log(datFiles)
                    axios(configFiles)
                        .then(function (responseFiles) {

                            if (responseFiles.data.isOk === true) {
                                //REGISTRA STATUS
                                var datHoje = new Date();
                                var dataStatus = JSON.stringify({
                                    "CodigoStatus": 0,
                                    "CodigoSolicitacao": CodigoSolicitacao,
                                    "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                                    "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                    "status": false,
                                    "Motivo": '<strong>Autorização de Compra feita por:</strong> ' + localStorage.getItem("NomeUsuario") + '<br>Enviado para: ' + infoFornecedor.Nome + '<br>' + '<strong>Mensagem: </strong>' + mensagem,
                                    "CodigoPerfil": localStorage.getItem("CodigoPerfil"),
                                    "Doc": "",
                                    "PerfilDestino": localStorage.getItem("CodigoPerfil"),
                                    "CodCadastro": 155,
                                });
                                var configStatus = {
                                    method: 'post',
                                    maxBodyLength: Infinity,
                                    url: urlAPIlocal + '/api/Status',
                                    headers: {
                                        'Authorization': wtoken,
                                        'Content-Type': 'application/json',
                                    },
                                    data: dataStatus
                                };
                                //console.log(config);
                                axios(configStatus)
                                    .then(function (responseStatus) {
                                        if (responseStatus.data.isOk === true) {
                                            //grava o pdf AF gerado no status
                                            var dataStatus = JSON.stringify({
                                                "CodigoStatus": 0,
                                                "CodigoSolicitacao": CodigoSolicitacao,
                                                "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                                                "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                                "status": false,
                                                "Motivo": '<strong>Arquivo Inserido Por:</strong><br> ' + localStorage.getItem("NomeUsuario") + '<br>Arquivo: ' + wNomeArquivoNovo + '<br>Autorização de Compra (AF) para: </strong> ' + infoFornecedor.Nome + '<br>',
                                                "CodigoPerfil": localStorage.getItem("CodigoPerfil"),
                                                "Doc": "",
                                                "PerfilDestino": localStorage.getItem("CodigoPerfil"),
                                                "CodCadastro": 155,
                                            });
                                            var configStatus = {
                                                method: 'post',
                                                maxBodyLength: Infinity,
                                                url: urlAPIlocal + '/api/Status',
                                                headers: {
                                                    'Authorization': wtoken,
                                                    'Content-Type': 'application/json',
                                                },
                                                data: dataStatus
                                            };
                                            axios(configStatus)
                                                .then(function (responseStatus) {
                                                    if (responseStatus.data.isOk === true) {
                                                        // ENVIA EMAIL AOS FORNECEDORES 
                                                        let wEmailOculto = '';
                                                        let wEmailDestino = '';
                                                        if (emailAdicional === '') {
                                                            wEmailOculto = 'renato.vieira@fauscs.org.br';
                                                            wEmailDestino = infoFornecedor.Email.Endereco;
                                                        } else {
                                                            wEmailOculto = 'renato.vieira@fauscs.org.br, ' + infoFornecedor.Email.Endereco;
                                                            wEmailDestino = emailAdicional;
                                                        }
                                                        //console.log(wEmailDestino);
                                                        //console.log(wEmailOculto);
                                                        //console.log(emailAdicional);

                                                        var dataEmail = JSON.stringify({
                                                            "RemetenteEmail": "",
                                                            "RemetenteNome": "",
                                                            "CopiaOculta": [wEmailOculto],
                                                            "destinatario": [wEmailDestino],
                                                            "assunto": assunto,
                                                            "corpo": mensagem,
                                                            "Anexos": [{
                                                                "Pasta": "ArquivosProcesso",
                                                                "Arquivos": wNomeArquivoNovo,
                                                            }]
                                                        });

                                                        var configEmail = {
                                                            method: 'post',
                                                            maxBodyLength: Infinity,
                                                            url: urlAPIlocal + '/api/Email',
                                                            headers: {
                                                                'Authorization': wtoken,
                                                                'Content-Type': 'application/json',
                                                            },
                                                            data: dataEmail
                                                        };

                                                        //console.log(dataEmail);
                                                        //console.log(configEmail);

                                                        axios(configEmail)
                                                            .then(response => {
                                                                setLoading(false);
                                                                setEnviado(true);
                                                                window.history.go(-1); // Volta para a página anterior
                                                                //window.location.reload();
                                                            })
                                                    } else {
                                                        //faca algo aqui se nao der isOk =false
                                                    }
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });



                                        }
                                    })
                                    .catch(error => {
                                        console.error(error);
                                        setLoading(false);
                                        setShowModalErro(true);
                                    });
                            } else {
                                setLoading(false);
                                setShowModalErro(true);
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            setLoading(false);
                            setShowModalErro(true);
                        });
                } else {
                    setLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });

    }

    const AssinaturaEletronica = async (event) => {
        event.preventDefault();
        setLoading(true);

        //var CodigoUsuario = localStorage.getItem('CodigoUsuario');
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios?login=' + username + '&senha=' + senha + '&IsInclude=true',
            headers: {
                'Authorization': wtoken, //"Bearer " + wTokenLocal,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                //console.log(response.data);
                if (response.data.isOk === true) {
                    //console.log('entrou aqui 697');
                    setLoading(true);
                    setModalAssinatura(false);
                    setAvisoSenhaOk(true);
                    //console.log(avisoSenhaOk);
                    setAvisoAssinatura(false);
                    setSenha('');
                    MontaAF('enviaok');


                } else {
                    console.log('entrou aqui 708');
                    setModalAssinatura(true);
                    setAvisoAssinatura(true);
                    setAvisoSenhaOk(false);
                    setLoading(false);
                }


                //setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);

            });
    };

    const closeAndResetModalAssinatura = () => {
        //console.log('entrou aqui 725 closeandresetmodalassinatura')
        setModalAssinatura(false);
        setAvisoAssinatura(false);
        setAvisoSenhaOk(false);
        setSenha('');
        //setSenhaVerificada(false);
        setBotaoDesabilitado(false);
    };

    const MontaAF2 = async (event) => {
        event.preventDefault();
        MontaAF();
    }

    return (
        <div className={Style.page}>
            <div>
                <button className={Style.voltar}>
                    <Link to={`/EditSolic/${CodigoSolicitacao}`} ><IoMdArrowBack /> Voltar</Link>
                </button>
            </div>
            <div
                style={{
                    backgroundColor: '#ffffff',
                    borderTop: '5px solid #1B4F72',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    padding: '2em',
                    width: '60vw',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
                }}>

                <div id="CabecalhoHtml">
                    <h4 style={{ fontSize: '24px', color: '#222', textAlign: 'center', marginBottom: '.5em' }}>Autorização de Fornecimento</h4>
                    <h4 style={{ fontSize: '20px', color: '#BA4A00', textAlign: 'center' }}>Processo de Compras n° {CodigoSolicitacao} / {anoSolic}</h4>
                    <hr style={{ color: '#bbb' }}></hr>
                </div>

                <Form.Label className={Style.form_label}>Selecione o Fornecedor</Form.Label>
                <Form.Select className={Style.form_control} name="credor" id="credor" value={fornecedoresSelecionado} onChange={handleSelectFornecedor}>
                    <option value="0"></option>
                    {fornecedores.length > 0 &&
                        fornecedores.map((opcao) => (
                            <option key={opcao.CodigoCadastro} value={opcao.CodCadastro}>{opcao.Nome}</option>
                        ))}
                </Form.Select>

                {itensFornecedor ? (
                    <div>
                        <br></br>
                        <Form.Label className={Style.form_label}>Selecione os Itens</Form.Label>
                        <Select
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            closeMenuOnSelect={false}
                            options={itens.map((itens) => ({
                                label: itens.Nome,
                                value: itens.CodigoSolicitacaoItem,
                                quantidade: itens.Quantidade,
                                unidade: itens.Unidade,
                                valorUnit: itens.ValorUnitario,
                                valorTotal: itens.ValorTotal,
                                frete: itens.Frete
                            }))}
                            // value={fornecedoresSelecionados}
                            onChange={handleSelectItens}
                            value={itensSelecionados}
                        />
                    </div>
                ) : (null)}

                {itemSelecionado ? (
                    <div>
                        <h6 style={{ fontSize: '18px', textAlign: 'center', color: '#626567', marginTop: '1.5em' }}>Dados do Fornecedor</h6>
                        <Table className={Style.table}>
                            <thead>
                                <tr>
                                    <th>Razão Social</th>
                                    <th>CNPJ/CPF</th>
                                    <th>Código</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoFornecedor && (
                                    <tr>
                                        <td>{infoFornecedor.Nome}</td>
                                        <td>{infoFornecedor.NumeroCnpj}</td>
                                        <td>{infoFornecedor.CodigoCadastro}</td>
                                    </tr>
                                )}

                            </tbody>

                            <thead>
                                <tr>
                                    <th>Endereço</th>
                                    <th>CEP</th>
                                    <th>Contato</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoFornecedor && (
                                    <tr>
                                        <td>{infoFornecedor.Endereco}</td>
                                        <td>{infoFornecedor.NumeroCep}</td>
                                        <td>{infoFornecedor.Email.Endereco} <br></br>  ({infoFornecedor.NumeroDdd}) {infoFornecedor.NumeroTelefone}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        <h6 style={{ fontSize: '18px', textAlign: 'center', color: '#626567', marginTop: '.5em' }}>Item(s)</h6>

                        <Table className={Style.table}>
                            <thead>
                                <tr>
                                    <th>Descrição</th>
                                    <th style={{ textAlign: 'center' }}>Quant.</th>
                                    <th style={{ textAlign: 'center' }}>Unid.</th>
                                    <th style={{ textAlign: 'right' }}>Valor Unitário</th>
                                    {/* <th style={{ textAlign: 'right' }}>Frete</th> */}
                                    <th style={{ textAlign: 'right' }}>Valor Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(itensSelecionados.map((infoItem) => (
                                    <tr>
                                        <td >{infoItem.label}</td>
                                        <td style={{ textAlign: 'center' }}>{infoItem.quantidade}</td>
                                        <td style={{ textAlign: 'center' }}>{infoItem.unidade}</td>
                                        <td style={{ textAlign: 'right' }}>{infoItem.valorUnit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        {/* <td style={{ textAlign: 'right' }}>{infoItem.frete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td> */}
                                        <td style={{ textAlign: 'right' }}>{infoItem.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    </tr>
                                )))}
                            </tbody>

                            <thead style={{ textAlign: 'right', color: '#BA4A00', fontSize: '16px' }}>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Valor Total dos Itens</th>
                                    <th>{valorTotalDosItens.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Frete</th>
                                    <th>{valorTotalDoFrete.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Valor Total</th>
                                    <th>{((valorTotalDosItens) + (valorTotalDoFrete)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</th>
                                </tr>
                            </thead>

                        </Table>

                        <div>
                            <Form.Label style={{ fontSize: '16px', textAlign: 'center', color: '#626567', margin: '1em', fontWeight: '600' }}>Observações</Form.Label>
                            <ReactQuill
                                theme="snow"
                                value={observacao}
                                onChange={handleObservacaoChange}
                                className={Style.textarea}
                            />
                        </div>
                        <br></br>
                        <div>
                            <Form.Label style={{ fontSize: '16px', textAlign: 'center', color: '#626567', margin: '1em', fontWeight: '600' }}>Informações Adicionais</Form.Label>
                            <ReactQuill
                                theme="snow"
                                value={infoAdicionais}
                                onChange={handleInfoAdicionaisChange}
                                className={Style.textarea}
                            />
                        </div>
                        <br></br>
                        <br></br>
                        <Form onSubmit={MontaAF2}>
                            <Row style={{ marginBottom: '.8em' }}>
                                <Col>
                                    <Form.Label className={Style.form_label}>Email Destino:</Form.Label>
                                </Col>
                                <Col md={11}>
                                    <Form.Control className={Style.form_control} type="text" name="Email" value={emailAdicional} onChange={(event) => setEmailAdicional(event.target.value)} />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: '.8em' }}>
                                <Col>
                                    <Form.Label className={Style.form_label}>Assunto:</Form.Label>
                                </Col>
                                <Col md={11}>
                                    <Form.Control className={Style.form_control} type="text" name="Assunto" value={assunto} onChange={(event) => setAssunto(event.target.value)} required />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Label className={Style.form_label}>Mensagem:</Form.Label>
                                </Col>
                                <Col md={11}>
                                    {/* <Form.Control as='textarea' className={Style.textarea} type="text" name="Mensagem" value={mensagem} onChange={(event) => setMensagem(event.target.value)} /> */}
                                    <ReactQuill
                                        theme="snow"
                                        value={mensagem}
                                        onChange={handleMensagemChange}
                                        className={Style.textarea}
                                    />
                                </Col>
                            </Row>

                            <hr style={{ marginTop: '2em', color: '#bbb' }}></hr>

                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <button type='submit' className={Style.enviar_btn} disabled={botaoDesabilitado}>Enviar Autorização de Compra</button>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                ) : (null)}
            </div>

            <Modal show={loading} className={Style.Modal} centered >
                <Modal.Body className={Style.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            <Modal show={enviado} onLoad={handleClose} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p><strong>A Solicitação de Pedido de Compra foi Enviada aos Fornecedores!</strong></p>
                </Modal.Body>
            </Modal>

            <Modal show={showModalErro} className={Style.Modal} centered >
                <Modal.Header closeButton onClick={handleCloseErro} className={Style.Modal_header}>
                    <h3>Ops!</h3>
                    <ImSad2 />
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Algo deu errado, cheque os campos e tente novamente</p>
                </Modal.Body>
            </Modal>

            <Modal show={modalAssinatura} className={Style.Modal} centered >
                <Modal.Header className={Style.Modal_header}>
                    <h3>Assinatura Digital </h3>
                </Modal.Header>
                <Modal.Body className={Style.Modal_body}>
                    <p>Por favor, confirme sua senha para assinar o documento</p>
                    <Form onSubmit={AssinaturaEletronica}>
                        {avisoAssinatura ? (<p style={{ color: '#E74C3C' }}>A senha digitada não confere com a senha de usuário</p>) : (null)}
                        <Row>
                            <Col>
                                <Form.Label className={Style.label_modal}>Sua senha:</Form.Label>
                            </Col>
                            <Col md={9}>
                                <Form.Control className={Style.form_control} type="password" name="senha" value={senha} onChange={(event) => setSenha(event.target.value)} />
                            </Col>
                        </Row>

                        <button type="submit" className={Style.btn_salvar}> Salvar </button>
                        <button type="button" className={Style.btn_cancelar} onClick={closeAndResetModalAssinatura}> Cancelar </button>
                    </Form>
                </Modal.Body>
            </Modal>

            {login && (<Navigate to="/" replace={true} />)}
        </div >
    )

}
export default EnviaAF 