import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, FormGroup, Row } from "react-bootstrap"
import { FormLabel, FormControl, FormSelect } from 'react-bootstrap';
import Menu from "../Layout/Menu"
import StyleForm from './EditUsuario.module.css'
import axios from 'axios';
import { MdDone } from 'react-icons/md';
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
import { Navigate } from "react-router-dom";
//import moment from 'moment';
import urlAPIlocal from '../../global.js';

function EditUsuario() {

    const { CodigoUsuario } = useParams();

    const [nome, setNome] = useState('');
    const [loginUsuario, setLoginUsuario] = useState('');
    const [senhaUsuario, setSenhaUsuario] = useState('');
    const [senhaAssinatura, setSenhaAssinatura] = useState('');
    const [dataExpiracao, setDataExpiracao] = useState('');
    const [dataUltimoAcesso, setDataUltimoAcesso] = useState('');
    const [projeto, setProjeto] = useState(999);
    const [projetoArea, setProjetoArea] = useState('');
    const [codigoPerfil, setCodigoPerfil] = useState(0);
    const [perfil, setPerfil] = useState('');
    const [codigoCadastro, setCodigoCadastro] = useState(0);
    const [ativo, setAtivo] = useState(false);

    // const [isValidLength, setValidLength] = useState(false);
    // const [hasUppercase, setHasUppercase] = useState(false);
    // const [hasLowercase, setHasLowercase] = useState(false);
    // const [hasDigit, setHasDigit] = useState(false);
    // const [hasSpecialChar, setHasSpecialChar] = useState(false);

    const [isValidLengthAcesso, setValidLengthAcesso] = useState(false);
    const [hasUppercaseAcesso, setHasUppercaseAcesso] = useState(false);
    const [hasLowercaseAcesso, setHasLowercaseAcesso] = useState(false);
    const [hasDigitAcesso, setHasDigitAcesso] = useState(false);
    const [hasSpecialCharAcesso, setHasSpecialCharAcesso] = useState(false);

    const [isValidLengthAssinatura, setValidLengthAssinatura] = useState(false);
    const [hasUppercaseAssinatura, setHasUppercaseAssinatura] = useState(false);
    const [hasLowercaseAssinatura, setHasLowercaseAssinatura] = useState(false);
    const [hasDigitAssinatura, setHasDigitAssinatura] = useState(false);
    const [hasSpecialCharAssinatura, setHasSpecialCharAssinatura] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [redir, setRedir] = useState(false);

    const [login, setLogin] = useState(false);
    var wtokenBearer = "Bearer " + localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        var wTokenLocal = localStorage.getItem("token");
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true);
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    function handleClose() {
        setTimeout(() => {
            setShowModal(false);
            setRedir(true);
        }, 2000);

    }

    const GetUsuario = () => {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios?CodigoUsuario=' + CodigoUsuario + '&IsInclude=false',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data.objetoRetorno;
                //console.log(data);
                setNome(data[0].Nome);
                setLoginUsuario(data[0].Login);
                setSenhaUsuario(data[0].Senha);
                setCodigoPerfil(data[0].CodigoPerfil);
                setAtivo(data[0].Ativo);
                setDataExpiracao(data[0].DataExpira);
                setDataUltimoAcesso(data[0].DataUltimoAcesso);
                setProjeto(data[0].CodigoConvenio);
                setCodigoCadastro(data[0].CodigoCadastro);
                //console.log(data[0].CodigoConvenio);

                getPerfil(data[0].CodigoPerfil);
                getProjeto(data[0].CodigoConvenio);

            }
            )
            .catch(function (error) {
                console.log(error);

            });

    };

    useEffect(() => {
        GetUsuario();
        // eslint-disable-next-line
    }, []);

    function getPerfil(wCodigoPerfil) {

        //console.log(wCodigoPerfil)
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Perfil?CodigoPerfil=' + wCodigoPerfil,
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                //console.log(response);
                const data = response.data;
                setPerfil(data.objetoRetorno[0].Nome);

            }
            )
            .catch(function (error) {
                console.log(error);

            });
    }

    function getProjeto(wCodigoConvenio) {

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio?CodigoConvenio=' + wCodigoConvenio,
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                setProjetoArea(response.data.objetoRetorno[0].Nome);
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSenhaAcessoChange = (event) => {
        const novaSenha = event.target.value;
        setSenhaUsuario(novaSenha);
        const { isValidLength, hasUppercase, hasLowercase, hasDigit, hasSpecialChar } = validateSenhaAcesso(novaSenha);
        setValidLengthAcesso(isValidLength);
        setHasUppercaseAcesso(hasUppercase);
        setHasLowercaseAcesso(hasLowercase);
        setHasDigitAcesso(hasDigit);
        setHasSpecialCharAcesso(hasSpecialChar);
    };

    const validateSenhaAcesso = (senhaUsuario) => {
        const lengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        const isValidLength = lengthRegex.test(senhaUsuario);
        const hasUppercase = uppercaseRegex.test(senhaUsuario);
        const hasLowercase = lowercaseRegex.test(senhaUsuario);
        const hasDigit = digitRegex.test(senhaUsuario);
        const hasSpecialChar = specialCharRegex.test(senhaUsuario);

        return { isValidLength, hasUppercase, hasLowercase, hasDigit, hasSpecialChar };
    };

    const handleSenhaAssinaturaChange = (event) => {
        const novaSenhaAssinatura = event.target.value;
        setSenhaUsuario(novaSenhaAssinatura);
        const { isValidLength, hasUppercase, hasLowercase, hasDigit, hasSpecialChar } = validateSenhaAssinatura(novaSenhaAssinatura);
        setValidLengthAssinatura(isValidLength);
        setHasUppercaseAssinatura(hasUppercase);
        setHasLowercaseAssinatura(hasLowercase);
        setHasDigitAssinatura(hasDigit);
        setHasSpecialCharAssinatura(hasSpecialChar);
    };

    const validateSenhaAssinatura = (senhaAssinatura) => {
        const lengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const digitRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        const isValidLength = lengthRegex.test(senhaAssinatura);
        const hasUppercase = uppercaseRegex.test(senhaAssinatura);
        const hasLowercase = lowercaseRegex.test(senhaAssinatura);
        const hasDigit = digitRegex.test(senhaAssinatura);
        const hasSpecialChar = specialCharRegex.test(senhaAssinatura);

        return { isValidLength, hasUppercase, hasLowercase, hasDigit, hasSpecialChar };
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        var data = JSON.stringify({
            "CodigoUsuario": CodigoUsuario,
            "Nome": nome,
            "Login": loginUsuario,
            "Senha": senhaUsuario,
            "CodigoCadastro": codigoCadastro,
            "CodigoPerfil": codigoPerfil,
            "DataExpira": dataExpiracao,
            "DataUltimoAcesso": dataUltimoAcesso,
            "CodigoConvenio": projeto,
            "Ativo": ativo,
            "IgnorarRateLimit": true,
            "SenhaAssinatura": senhaAssinatura,
            "NumeroAcesso": 1
        });

        var config = {
            method: 'PUT',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Usuarios',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                if (response.data.isOk === true) {

                    //console.log('deu certo');
                    setShowModal(true);
                    //setRedir(true);

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    //setCadastroOk(false);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className={StyleForm.page}>
            <Menu />
            <div className={StyleForm.container}>

                <div className={StyleForm.form}>

                    <div className={StyleForm.header}>
                        <h1>Edição de Usuário</h1>
                    </div>

                    <Form onSubmit={handleSubmit}>
                        <FormGroup className={StyleForm.form_group}>
                            <Row>
                                <Col>
                                    <FormLabel className={StyleForm.form_group_label}>Nome</FormLabel>
                                    <FormControl className={StyleForm.form_control} type="text" name="nome" value={nome} disabled />
                                </Col>
                            </Row>
                            {codigoPerfil === 37 && (
                                <Row>
                                    <Col>
                                        <FormLabel className={StyleForm.form_group_label}>Projeto</FormLabel>
                                        <FormControl className={StyleForm.form_control} value={projetoArea} disabled />
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col>
                                    <FormLabel className={StyleForm.form_group_label}>Login</FormLabel>
                                    <FormControl className={StyleForm.form_control} type="text" name="loginUsuario" autoComplete="new-password" value={loginUsuario} onChange={(event) => setLoginUsuario(event.target.value)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormLabel className={StyleForm.form_group_label}>Senha de Acesso</FormLabel>
                                    <FormControl className={StyleForm.form_control} type="password" name="senhaUsuario" autoComplete="new-password" value={senhaUsuario} onChange={handleSenhaAcessoChange} required />

                                    <ul className={StyleForm.validacao_senha}>
                                        {isValidLengthAcesso ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos 8 caracteres</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos 8 caracteres</li>)}
                                        {hasUppercaseAcesso ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos uma letra maiúscula</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos uma letra maiúscula</li>)}
                                        {hasLowercaseAcesso ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos uma letra minúscula</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos uma letra minúscula</li>)}
                                        {hasDigitAcesso ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos um dígito</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos um dígito</li>)}
                                        {hasSpecialCharAcesso ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos um caractere especial</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos um caractere especial</li>)}
                                    </ul>

                                </Col>

                                <Col>
                                    <FormLabel className={StyleForm.form_group_label}>Senha de Assinatura</FormLabel>
                                    <FormControl className={StyleForm.form_control} type="password" name="senhaAssinatura" autoComplete="new-password" value={senhaAssinatura} onChange={handleSenhaAssinaturaChange} required />

                                    <ul className={StyleForm.validacao_senha}>
                                        {isValidLengthAssinatura ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos 8 caracteres</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos 8 caracteres</li>)}
                                        {hasUppercaseAssinatura ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos uma letra maiúscula</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos uma letra maiúscula</li>)}
                                        {hasLowercaseAssinatura ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos uma letra minúscula</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos uma letra minúscula</li>)}
                                        {hasDigitAssinatura ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos um dígito</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos um dígito</li>)}
                                        {hasSpecialCharAssinatura ? (<li style={{ color: '#0DCC1F' }}> <MdDone /> pelo menos um caractere especial</li>) : (<li style={{ color: '#aaaaaa' }}>pelo menos um caractere especial</li>)}
                                    </ul>

                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <FormLabel className={StyleForm.form_group_label}>Perfil</FormLabel>
                                    <FormControl className={StyleForm.form_control} type="text" name="perfil" value={perfil} disabled />
                                </Col>
                                <Col>
                                    <label className={StyleForm.form_group_label}>Perfil Ativo?</label> <br />
                                    <input className={StyleForm.form_check} type="checkbox" checked={ativo} name="ativo" onChange={(event) => setAtivo(event.target.checked)} />
                                </Col>
                            </Row>
                            <div>
                                <button className={StyleForm.btn} type="submit"> Salvar Alterações </button>
                            </div>
                        </FormGroup>
                    </Form>
                </div>
            </div>

            <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
                <Modal.Header className={StyleForm.Modal_header}>
                    <img src={check_circle} alt="ok"></img>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <h3>Tudo certo!</h3>
                    <p>Usuário editado com sucesso.</p>
                </Modal.Body>
            </Modal>

            {redir && (<Navigate to="/Usuarios" replace={true} />)}
            {login && (<Navigate to="/login" replace={true} />)}

        </div>
    )
}
export default EditUsuario