import { useParams } from "react-router";
import { useState, useRef } from "react";
import React, { useEffect } from 'react';
import axios from "axios";
import urlAPIlocal from '../../global';
import logobola from "../img/logobola.png";
import moment from "moment";
import Table from 'react-bootstrap/Table';
import { GrStatusGoodSmall } from 'react-icons/gr';
import { Card, Col, FormGroup, Modal } from "react-bootstrap";
import Box from '@mui/material/Box';
import Style from './VerProcesso.module.css';
import loadingPdfGif from "../img/LoadingPdfGif.gif";
import { Navigate } from "react-router";
//import PDFViewerContainer from './PDFViewerContainer';
//import PDFViewer from './PDFViewNovo'


function PrintProcessoInterno() {

    const { CodigoProcessoInterno } = useParams();
    var wTokenLocal = localStorage.getItem("token");
    //var wPerfil = localStorage.getItem("CodigoPerfil");
    const [login, setLogin] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);
    // const [processo, setProcesso] = useState([]);
    // const [status, setStatus] = useState([]);
    // const [arquivosProcessoInterno, setArquivosProcessoInterno] = useState([]);
    // const [arquivo, setArquivo] = useState([]);
    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosProcesso/"
    const [returnFinal, setReturnFinal] = useState(false);
    const [semProcesso, setSemProcesso] = useState(false);
    const [mensagem, setMensagem] = useState('');


    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);


    const getProcesso = async (event) => {

        setLoadingPDF(true);

        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/UploadArquivo/DownloadProcessoInterno?id=' + CodigoProcessoInterno,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {
                const data = response.data;
                //let wpdfstring = data;
                //console.log(data);
                if (typeof data === 'string' && data.includes('%PDF-1.4')) {
                    // Se há dados na resposta e isOk é true, trata-se de um PDF ou outra informação
                    setReturnFinal(true);
                    setLoadingPDF(false);
                    setSemProcesso(false);
                    console.log(data);
                } else if (data && data.isOk === false && data.mensagem) {
                    // Se há dados na resposta, mas isOk é false, trata-se de um erro conhecido
                    setReturnFinal(false);
                    setLoadingPDF(false);
                    setSemProcesso(true);
                    setMensagem(data.mensagem);
                } else {
                    // Se não há dados na resposta, considera-se que o processo não foi encontrado
                    setReturnFinal(false);
                    setLoadingPDF(false);
                    setSemProcesso(true);
                    setMensagem('Desculpe, mas o Processo Interno não foi encontrado.');
                }
            })
            .catch(function (error) {
                console.log(error);

                // Tratar erro de rede ou outros erros não específicos
                //setReturnFinal(false);
                setLoadingPDF(false);
                //setSemProcesso(true);
                //setMensagem('Desculpe, ocorreu um erro inesperado.');
            });

    }

    useEffect(() => {
        getProcesso();
        // eslint-disable-next-line
    }, []);


    return (
        // <div aling='center'>{returnFinal ? (
        <>
            {semProcesso &&
                <div align='center'>{mensagem}</div>
            } : {<div>
                {returnFinal && (
                    <div style={{ width: '850px', height: '100vh', align: 'center', width: '100%' }}>
                        <embed src={LinkArquivo + 'ProcessoInterno-' + CodigoProcessoInterno + '.pdf'} type="application/pdf" width="100%" height="100%" />
                    </div>
                )}


                {login && (<Navigate to="/" replace={true} />)}

            </div>}
            <Modal show={loadingPDF} style={{ display: 'flex', justifyContent: 'center' }} centered >
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '0 auto', color: '#34495E' }}>
                    <img style={{ width: '50px', margin: '0 auto', marginBottom: '.5em' }} src={loadingPdfGif} alt="Carregando PDF"></img>
                    <h5>Carregando Processo</h5>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default PrintProcessoInterno