import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import ListProjetos from './Componentes/Paginas/ListProjetos'
import React from 'react'
import './App.css'
import Index from './Componentes/Paginas/Index'
import PrintSolic from './Componentes/Paginas/PrintSolic'
import CadPF from './Componentes/Paginas/CadPF'
import CadPJ from './Componentes/Paginas/CadPJ'
import LoginForm from './Componentes/Paginas/Login'
import ConsultaCNPJ from './Componentes/Paginas/FormCadastroFornecedor/ConsultaCNPJ'
import CadFornecedor from './Componentes/Paginas/FormCadastroFornecedor/CadFornecedor'
import CadastroRealizado from './Componentes/Paginas/CadastroRealizado'
import ListCadastro from './Componentes/Paginas/ListCadastro'
import VerCadastro from './Componentes/Paginas/VerCadastro'
import EditCadastro from './Componentes/Paginas/EditCadastro'
import CadastroGeral from './Componentes/Paginas/CadastroGeral'
import CadProjeto from './Componentes/Paginas/CadProjeto'
import Concedente from './Componentes/Paginas/Concedente'
import VerProjeto from './Componentes/Paginas/VerProjeto'
//import TesteArray from './Componentes/Paginas/TesteArray'
import Elementos from './Componentes/Paginas/Elementos'
import Creditos from './Componentes/Paginas/Creditos'
import PtFinanceiro from './Componentes/Paginas/PtFinanceiro'
import OutrosVal from './Componentes/Paginas/OutrosVal'
import Metas from './Componentes/Paginas/Metas'
import Fases from './Componentes/Paginas/Fases'
import FaseValor from './Componentes/Paginas/FaseValor'
import Cronograma from './Componentes/Paginas/Cronograma'
import EditProjeto from './Componentes/Paginas/EditProjeto'
import Relatorios from './Componentes/Paginas/Relatorios'
import Solicitacoes from './Componentes/Paginas/Solicitacoes'
import CadSolicitacao from './Componentes/Paginas/CadSolicitacao'
import EditSolic from './Componentes/Paginas/EditSolic'
import VerSolicitacao from './Componentes/Paginas/VerSolicitacao'
import VerSolicitacaoNew from './Componentes/Paginas/VerSolicitacaoNew'
import Usuarios from './Componentes/Paginas/Usuarios'
import CadUsuario from './Componentes/Paginas/CadUsuario'
import EditUsuario from './Componentes/Paginas/EditUsuario'
import SolicFinanceiro from './Componentes/Paginas/SolicFinanceiro'
import TesteRelatorio from './Componentes/Paginas/TesteRelatorio'
import RelatorioRendimentos from './Componentes/Paginas/RelatorioRendimentos'
import RelatorioRepasses from './Componentes/Paginas/RelatorioRepasses'
import PrintProcesso from './Componentes/Paginas/PrintProcesso'
import PagamentosSemSolic from './Componentes/Paginas/PagamentosSemSolic'
import ListPagamentos from './Componentes/Paginas/ListPagamentos'
import ProcInterno from './Componentes/Paginas/ProcInterno'
import CadProcessoInterno from './Componentes/Paginas/CadProcessoInterno'
import PagamentosComSolic from './Componentes/Paginas/PagamentosComSolic'
import PagamentoSolicForm from './Componentes/Paginas/PagamentoSolicForm'
import VerProcessoNew from './Componentes/Paginas/VerProcessoNew'
import VerProcesso from './Componentes/Paginas/VerProcesso'
import PrintProcessoInterno from './Componentes/Paginas/PrintProcessoInterno'
import RelatPagtos from './Componentes/Paginas/RelatPagtos'
import RelatRestosAPagar from './Componentes/Paginas/RelatRestosAPagar'
import SolicOrcamento from './Componentes/Paginas/SolicOrcamento'
import SolicComparativo from './Componentes/Paginas/SolicComparativo'
import PrintCredenciamento from './Componentes/Paginas/PrintCredenciamento'
import FluxoDoa from './Componentes/Paginas/FluxoDoa'
import EnviaAF from './Componentes/Paginas/EnviaAF'
import PCAdiantamento from './Componentes/Paginas/PCAdiantamento'
import EscolhaProjeto from './Componentes/Paginas/EscolhaProjeto'

// Componente que envolve a lógica de verificação do perfil
const PrivateRoute = ({ children, allowedProfiles }) => {
  const codigoPerfil = localStorage.getItem('CodigoPerfil');
  const isAllowed = allowedProfiles.includes(codigoPerfil);

  if (!isAllowed) {
    return <Navigate to="/index" />;
  }

  return children;
};



function App() {

  return (
    <Router>
      <Routes>
        <Route path="/index" element={<Index />}> </Route>
        <Route
          path="/EscolhaProjeto"
          element={
            <PrivateRoute allowedProfiles={['37']}>
              <EscolhaProjeto />
            </PrivateRoute>
          } />
        <Route
          path="/ProcInterno"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <ProcInterno />
            </PrivateRoute>
          } />
        <Route
          path="/CadProcessoInterno"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <CadProcessoInterno />
            </PrivateRoute>
          } />
        <Route
          path="/VerProcessoNew/:CodigoProcessoInterno"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <VerProcessoNew />
            </PrivateRoute>
          } />
        {/* <Route 
        path="/VerProcesso/:CodigoProcessoInterno" 
        element={
          <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
            <VerProcesso />
          </PrivateRoute>
        } /> */}
        <Route
          path="/TesteRelatorio"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <TesteRelatorio />
            </PrivateRoute>
          } />
        <Route
          path="/PrintSolic/:CodigoSolicitacao"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '37', '38', '42']}>
              <PrintSolic />
            </PrivateRoute>
          } />
        <Route
          path="/ListProjetos"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <ListProjetos />
            </PrivateRoute>
          } />
        <Route
          path="/VerProjeto/:CodigoConvenio"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <VerProjeto />
            </PrivateRoute>
          } />
        <Route
          path="/Relatorios/:CodigoConvenio"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <Relatorios />
            </PrivateRoute>
          } />
        <Route path="/Solicitacoes/:CodigoConvenio" element={<Solicitacoes />}> </Route>
        <Route path="/CadSolicitacao" element={<CadSolicitacao />}> </Route>
        {/* <Route path="/printsolic/:CodSolicitacao" element={<PrintSolic />}> </Route> */}

        <Route
          path="/cadGeral"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <CadastroGeral />
            </PrivateRoute>
          } />
        <Route
          path="/cadPF/:Tipo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <CadPF />
            </PrivateRoute>
          } />
        <Route
          path="/cadPJ/:Tipo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <CadPJ />
            </PrivateRoute>
          } />
        <Route
          path="/CadProjeto"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <CadProjeto />
            </PrivateRoute>
          } />
        <Route
          path="/EditProjeto/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <EditProjeto />
            </PrivateRoute>
          } />
        <Route
          path="/Concedente/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <Concedente />
            </PrivateRoute>
          } />
        <Route
          path="/Elementos/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <Elementos />
            </PrivateRoute>
          } />
        <Route
          path="/Creditos/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <Creditos />
            </PrivateRoute>
          } />
        <Route
          path="/PtFinanceiro/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '37', '38', '42']}>
              <PtFinanceiro />
            </PrivateRoute>
          } />
        <Route
          path="/OutrosVal/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <OutrosVal />
            </PrivateRoute>
          } />
        <Route
          path="/Metas/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <Metas />
            </PrivateRoute>
          } />
        <Route
          path="/Fases/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <Fases />
            </PrivateRoute>
          } />
        <Route
          path="/FaseValor/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <FaseValor />
            </PrivateRoute>
          } />
        <Route
          path="/Cronograma/:wCodigoConvenioNovo"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <Cronograma />
            </PrivateRoute>
          } />

        <Route exact path="/" element={<LoginForm />}> </Route>
        <Route path="/consultaCNPJ" element={<ConsultaCNPJ />}> </Route>
        <Route path="/cadfornecedor" element={<CadFornecedor />}> </Route>
        <Route path="/cadrealizado" element={<CadastroRealizado />}> </Route>

        <Route
          path="/listcadastro"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <ListCadastro />
            </PrivateRoute>
          } />
        <Route
          path="/VerCadastro/:CodigoCadastro"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <VerCadastro />
            </PrivateRoute>
          } />

        <Route
          path="/PrintCredenciamento/:CodigoCadastro"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <PrintCredenciamento />
            </PrivateRoute>
          } />
        <Route
          path="/editCadastro/:CodigoCadastro"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <EditCadastro />
            </PrivateRoute>
          } />
        <Route
          path="/editSolic/:CodigoSolicitacao"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <EditSolic />
            </PrivateRoute>
          } />

        <Route
          path="/SolicOrcamento/:CodigoSolicitacao"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <SolicOrcamento />
            </PrivateRoute>
          } />
        <Route
          path="/SolicComparativo/:CodigoSolicitacao"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <SolicComparativo />
            </PrivateRoute>
          } />
        <Route
          path="/EnviaAF/:CodigoSolicitacao"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <EnviaAF />
            </PrivateRoute>
          } />
        {/* <Route 
        path="/VerSolicitacao/:CodigoSolicitacao" 
        element={
          <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '37', '38', '42']}>
            <VerSolicitacao />
          </PrivateRoute>
        }/> */}
        <Route
          path="/VerSolicitacaoNew/:CodigoSolicitacao"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '37', '38', '42']}>
              <VerSolicitacaoNew />
            </PrivateRoute>
          } />
        <Route
          path="/usuarios"
          element={
            <PrivateRoute allowedProfiles={['1']}>
              <Usuarios />
            </PrivateRoute>
          } />
        <Route
          path="/CadUsuario"
          element={
            <PrivateRoute allowedProfiles={['1']}>
              <CadUsuario />
            </PrivateRoute>
          } />
        <Route
          path="/EditUsuario/:CodigoUsuario"
          element={
            <PrivateRoute allowedProfiles={['1']}>
              <EditUsuario />
            </PrivateRoute>
          } />
        <Route
          path="/SolicFinanceiro"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <SolicFinanceiro />
            </PrivateRoute>
          } />

        <Route path="/Solicitacoes/:CodigoConvenio/RelatorioRendimentos" element={<RelatorioRendimentos />}> </Route>
        <Route path="/Solicitacoes/:CodigoConvenio/RelatorioRepasses" element={<RelatorioRepasses />}> </Route>

        <Route
          path="/PrintProcesso/:CodigoSolicitacao"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <PrintProcesso />
            </PrivateRoute>
          } />
        <Route
          path="/PagamentosSemSolic"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <PagamentosSemSolic />
            </PrivateRoute>
          } />
        <Route
          path="/PagamentosComSolic/:CodigoConvenio"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <PagamentosComSolic />
            </PrivateRoute>
          } />
        <Route
          path="/ListPagamentos/:CodigoConvenio"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <ListPagamentos />
            </PrivateRoute>
          } />
        <Route
          path="/PagamentoSolicForm/:CodigoSolicitacao"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <PagamentoSolicForm />
            </PrivateRoute>
          } />
        <Route
          path="/PrintProcessoInterno/:CodigoProcessoInterno"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <PrintProcessoInterno />
            </PrivateRoute>
          } />
        <Route
          path="/RelatPagtos/:CodigoConvenio"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <RelatPagtos />
            </PrivateRoute>
          } />

        <Route path="/RelatRestosAPagar/:CodigoConvenio" element={<RelatRestosAPagar />}> </Route>

        <Route
          path="/FluxoDoa"
          element={
            <PrivateRoute allowedProfiles={['1', '17', '23', '24', '25', '26', '28', '31', '32', '33', '34', '35', '36', '38', '42']}>
              <FluxoDoa />
            </PrivateRoute>
          } />
        <Route path="/PCAdiantamento/:CodigoSolicitacao" element={<PCAdiantamento />}> </Route>

      </Routes>
    </Router>
  )
}

export default App;
