import Menu from "../Layout/Menu";
import StyleForm from "./CadPJ.module.css";
import React from "react";
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from "react";
import InputMask from 'react-input-mask';
import { cnpj } from 'cpf-cnpj-validator';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Navigate } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import { Modal } from 'react-bootstrap';
import urlAPIlocal from "../../global";
import loading from "../img/icons8-spinning-circle.gif"
import { useParams } from "react-router";
import { Link } from "react-router-dom"
import { IoMdArrowBack } from "react-icons/io"
import { ImSad2 } from 'react-icons/im';


function GravaArquivo(CodCadastro, ArqCadastro, DescricaoArq, tokenBear, wDatSituacao) {

  var Retorno = false;
  //trata nome arquivo, tira acentos, espaços
  let wNomeArquivoLimpo = ArqCadastro.name
  wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
  wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
  wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  wNomeArquivoLimpo = CodCadastro + "_" + wNomeArquivoLimpo

  wDatSituacao = moment(wDatSituacao).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  var datFiles = JSON.stringify({
    "CodigoArquivoCadastro": 0,
    "CodigoCadastro": CodCadastro,
    "descricao": DescricaoArq,
    "arquivo": wNomeArquivoLimpo, //CodCadastro + "_" + ArqCadastro.name,
    "status": 0,
    "DatSituacao": wDatSituacao
  })
  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: urlAPIlocal + '/api/ArquivoCadastro',
    headers: {
      'Authorization': tokenBear,
      'Content-Type': 'application/json'
    },
    data: datFiles
  };
  //console.log(datFiles)
  axios(config)
    .then(function (response) {

      //console.log(response.data)
      if (response.data.isOk === true) {
        //faz upload do arquivo
        const file = ArqCadastro;
        const novoNome = wNomeArquivoLimpo;
        const novoArquivo = new File([file], novoNome, { type: file.type });
        const formData = new FormData();
        formData.append('arquivo', novoArquivo);

        var config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: urlAPIlocal + '/api/UploadArquivo',
          headers: {
            'Authorization': tokenBear,
            'Content-Type': 'multipart/form-dataa',
            'pasta': 'ArquivosCadastro'
          },
          data: formData
        };

        axios(config)

          .then(response => {
            //console.log(response.data);
            // faça algo com a resposta da API
            Retorno = false;
          })
          .catch(error => {
            console.error(error);
            Retorno = true;

          });
      } else {
        //tratar erro , mensagem ou algo na tela quando consumo retornar falha
        Retorno = true

      }

    })
    .catch(function (error) {
      console.log(error);
      Retorno = true
    });
  return Retorno;
}

function CadPJ() {

  const [empresaEstrangeira, setEmpresaEstrangeira] = useState(false);
  const [NumeroCnpj, setNumeroCnpj] = useState('');
  const [nome, setNome] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [NomeContato, setNomeContato] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [ComplementoEnd, setComplementoEnd] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [pais, setPais] = useState('');
  const [cep, setCep] = useState('');
  const [ddd, setDdd] = useState('');
  const [telefone, setTelefone] = useState('');
  const [ddd2, setDdd2] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [nomeBanco, setNomeBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [NumeroConta, setNumeroConta] = useState('');
  const [cnpjCpfConta, setcnpjCpfConta] = useState('');
  const [titularConta, settitularConta] = useState('');
  const [inscricaoEstadual, setinscricaoEstadual] = useState('');
  const [inscricaoMunicipal, setinscricaoMunicipal] = useState('');
  const [Atividade, setAtividade] = useState('');
  const [servicos, setServicos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [cnae, setCnae] = useState('');
  const [simplesNacional, setSimplesNacional] = useState(false);
  const [observacao, setobservacao] = useState('');
  const [dataCnd, setDataCnd] = useState('');
  const [avisodataCnd, setAvisoDataCnd] = useState(false);
  const [dataFgts, setDataFgts] = useState('');
  const [avisodataFgts, setAvisoDataFgts] = useState(false);
  const [dataTrabalhista, setDataTrabalhista] = useState('');
  const [avisodataTrabalhista, setAvisoDataTrabalhista] = useState(false);
  const [pix, setPix] = useState('');

  const date = new Date();
  const dataFormatada = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  const [isValid, setIsValid] = useState(true);
  const [fileregularidadeJuridica, setFileregularidadeJuridica] = useState(null);
  const [fileregularidadeFiscal, setFileregularidadeFiscal] = useState(null);
  const [fileRegistroCivil, setFileRegistroCivil] = useState(null);
  const [filecartaoCNPJ, setFilecartaoCNPJ] = useState(null);
  const [filecertidaoFGTS, setFilecertidaoFGTS] = useState(null);
  const [filecertidaoTrabalhista, setFilecertidaoTrabalhista] = useState(null);
  const [inputFilled, setInputFilled] = useState(false);
  const [cnpjExiste, setCnpjExiste] = useState(false);
  const [wCNPJA, setwCNPJA] = useState(false);
  const [CadastrOk, setCadastroOk] = useState(false);
  const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
  const [selecioneAtividade, setSelecioneAtividade] = useState(false);
  const [gravando, setGravando] = useState(false);
  const [showModalErro, setShowModalErro] = useState(false);

  var wTokenLocal = localStorage.getItem("token")
  const [login, setLogin] = useState(false);

  const { Tipo } = useParams();

  function handleCloseErro() {

    setShowModalErro(false);
    setCadastroOk(false);

  };

  const selectServicos = [
    { value: "Informática e congêneres", label: "Informática e congêneres" },
    { value: "Pesquisas e desenvolvimento de qualquer natureza", label: "Pesquisas e desenvolvimento de qualquer natureza" },
    { value: "Locação, cessão de direito de uso e congêneres", label: "Locação, cessão de direito de uso e congêneres" },
    { value: "Saúde, assistência médica e congêneres", label: "Saúde, assistência médica e congêneres" },
    { value: "Medicina e assistêcia veterinária e congêneres", label: "Medicina e assistêcia veterinária e congêneres" },
    { value: "Cuidados pessoais, estéticos, atividades físicas e congêneres", label: "Cuidados pessoais, estéticos, atividades físicas e congêneres" },
    { value: "Engenharia, arquitetura, geologia, urbanismo, construção civil, manutenção, limpeza, meio ambiente, saneamento e congêneres", label: "Engenharia, arquitetura, geologia, urbanismo, construção civil, manutenção, limpeza, meio ambiente, saneamento e congêneres" },
    { value: "Educação, ensino, orientação pedagógica, instruçao, treinamento e avaliação pessoal de qualquer grau ou natureza", label: "Educação, ensino, orientação pedagógica, instruçao, treinamento e avaliação pessoal de qualquer grau ou natureza" },
    { value: "Hospedagem, turismo, viagens e congêneres", label: "Hospedagem, turismo, viagens e congêneres" },
    { value: "Intermediação e congêneres", label: "Intermediação e congêneres" },
    { value: "Guarda, estacionamento, armazenamento, vigilâcia e congêneres", label: "Guarda, estacionamento, armazenamento, vigilâcia e congêneres" },
    { value: "Diversões, lazer, entreterimento e congêneres", label: "Diversões, lazer, entreterimento e congêneres" },
    { value: "Fonografia, fotografia, cinematografia e reprografia", label: "Fonografia, fotografia, cinematografia e reprografia" },
    { value: "Serviços relativos a bens de terceiros", label: "Serviços relativos a bens de terceiros" },
    { value: "Setor bancário ou financeiro, inclusive aqueles prestados por instituições financeiras autorizadas a funcionar pela União ou por quem de direito", label: "Setor bancário ou financeiro, inclusive aqueles prestados por instituições financeiras autorizadas a funcionar pela União ou por quem de direito" },
    { value: "Regulação de sinistro vinculados a contratos de seguros: inspeção e avaliação de risco para cobertura de contratos de seguros;prevenção de gerência de riscos seguráveis e congêneres", label: "Regulação de sinistro vinculados a contratos de seguros: inspeção e avaliação de risco para cobertura de contratos de seguros;prevenção de gerência de riscos seguráveis e congêneres" },
    { value: "Distribuição e venda de bilhetes e demais produtos de loteria, bingos,cartões, pules ou cupons de apostas, sorteios, prêmios, inclusive os decorrentes de títulos de capitalização e congêneres", label: "Distribuição e venda de bilhetes e demais produtos de loteria, bingos,cartões, pules ou cupons de apostas, sorteios, prêmios, inclusive os decorrentes de títulos de capitalização e congêneres" },
    { value: "Portuários, aeroportuários, ferroportuários, de terminais rodoviários, ferroviários e metroviários", label: "Portuários, aeroportuários, ferroportuários, de terminais rodoviários, ferroviários e metroviários" },
    { value: "Registros públicos, cartorários e notariais", label: "Registros públicos, cartorários e notariais" },
    { value: "Exploração de rodovia", label: "Exploração de rodovia" },
    { value: "Programação e comunicação visual, desenho industrial e congêneres", label: "Programação e comunicação visual, desenho industrial e congêneres" },
    { value: "Chaveiros, confecção de carimbos, placas, sinalização visual, banners,adesivos e congêneres", label: "Chaveiros, confecção de carimbos, placas, sinalização visual, banners,adesivos e congêneres" },
    { value: "Serviços funerários", label: "Serviços funerários" },
    { value: "Coleta, remessa ou entrega de correspondências, documentos, objetos,bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres", label: "Coleta, remessa ou entrega de correspondências, documentos, objetos,bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres" },
    { value: "Assistência social", label: "Assistência social" },
    { value: "Avaliação de bens e serviços de qualquer natureza", label: "Avaliação de bens e serviços de qualquer natureza" },
    { value: "Serviços de biblioteconomia", label: "Serviços de biblioteconomia" },
    { value: "Biologia, biotecnologia e química", label: "Biologia, biotecnologia e química" },
    { value: "Serviços técnicos em edificações, eletrônica, eletrotécnica, mecânica,telecomunicações e congêneres", label: "Serviços técnicos em edificações, eletrônica, eletrotécnica, mecânica,telecomunicações e congêneres" },
    { value: "Desenhos técnicos", label: "Desenhos técnicos" },
    { value: "Desembaraço aduaneiro, comissários, despachantes e congêneres", label: "Desembaraço aduaneiro, comissários, despachantes e congêneres" },
    { value: "Investigações particulares, detetives e congêneres", label: "Investigações particulares, detetives e congêneres" },
    { value: "Reportagem, assessoria de imprensa, jornalismo e relações públicas", label: "Reportagem, assessoria de imprensa, jornalismo e relações públicas" },
    { value: "Serviços de meteorologia", label: "Serviços de meteorologia" },
    { value: "Artistas, atletas, modelos e manequins", label: "Artistas, atletas, modelos e manequins" },
    { value: "Serviços de museologia", label: "Serviços de museologia" },
    { value: "Serviços de ourivesaria e lapidação", label: "Serviços de ourivesaria e lapidação" },
    { value: "Serviços relativos a obras de arte sob encomenda", label: "Serviços relativos a obras de arte sob encomenda" },
  ];

  const selectProdutos = [
    { value: "BENS PATRIMONIAIS", label: "BENS PATRIMONIAIS" },
    { value: "GASES MEDICINAIS E INDUSTRIAIS", label: "GASES MEDICINAIS E INDUSTRIAIS" },
    { value: "GENEROS ALIMENTICIOS", label: "GENEROS ALIMENTICIOS" },
    { value: "MATERIAIS/EQUIPAMENTOS DE INFORMATICA", label: "MATERIAIS/EQUIPAMENTOS DE INFORMATICA" },
    { value: "MATERIAL DE E.P.I. e EPC", label: "MATERIAL DE E.P.I. e EPC" },
    { value: "MATERIAL DE ESCRITORIO", label: "MATERIAL DE ESCRITORIO" },
    { value: "MATERIAL DE LIMPEZA/HIGIENE PE", label: "MATERIAL DE LIMPEZA/HIGIENE PE" },
    { value: "MATERIAL DE MANUTENÇÃO", label: "MATERIAL DE MANUTENÇÃO" },
    { value: "MATERIAL LABORATORIO/REAGENTES", label: "MATERIAL LABORATORIO/REAGENTES" },
    { value: "MATERIAL LABORATORIO/REAGENTES", label: "MATERIAL MEDICO HOSPITALAR" },
    { value: "MATERIAL MEDICO HOSPITALAR", label: "MEDICAMENTOS" },
    { value: "UNIFORMES E ROUPARIAS", label: "UNIFORMES E ROUPARIAS" },
    { value: "ORTESE E PROTESE", label: "ORTESE E PROTESE" },
    { value: "UTENSILIOS DE COPA", label: "UTENSILIOS DE COPA" },

  ]

  function ConsultaToken() {
    //verifica se tem o token no localstorage
    if (wTokenLocal === undefined || wTokenLocal === null) {
      setLogin(true)
    }
  }

  useEffect(() => {
    ConsultaToken();
    // eslint-disable-next-line
  }, []);


  // function handleClose() {
  //   setTimeout(() => {
  //     setShowModal(false);
  //     setCadastroOk(true);

  //   }, 3000);

  // }

  const gravarAtividade = (array, wCodCadastro, wtoken, wtipo) => {
    for (let i = 0; i < array.length; i++) {

      // grava atividade na tblTipoAtividadeCadastro
      var dataSelect = JSON.stringify({
        "CodigoTipoAtividadeCadastro": 0,
        "CodigoCadastro": wCodCadastro,
        "Nome": array[i].label,
        "servico": wtipo
      });

      var configSelect = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlAPIlocal + '/api/TipoAtividadeCadastro',
        headers: {
          'Authorization': wtoken,
          'Content-Type': 'application/json'
        },
        data: dataSelect
      };
      //console.log(configSelect)
      axios(configSelect)

        .then(function (response) {
          //console.log(response.data)
          setCadastroOk(true);
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleSelectProduto = (itemprod) => {
    setProdutos(itemprod);
    setSelecioneAtividade(false);
  };

  const handleSelectServicos = (itemserv) => {
    setServicos(itemserv);
    setSelecioneAtividade(false);
    //setSelectedOptions(itemserv);
    //.log(itemserv);
  };


  const handleCNPJ = (event) => {
    //console.log(NumeroCnpj);
    //console.log(event.target.value);
    console.log(event.target.value)

    // eslint-disable-next-line
    setNumeroCnpj(event.target.value);

    // Aqui você pode fazer o que precisar com o valor do input
    //Consulta CNPJ
    var wCnpjLimpo = event.target.value;
    var wCnpj = event.target.value;
    wCnpjLimpo = wCnpjLimpo.replace(/\D+/g, '');

    // eslint-disable-next-line
    var data = '';

    if (wCnpjLimpo.length === 14) {

      if (cnpj.isValid(wCnpjLimpo)) { // colocar setisvalid true quando nao entrar aqui

        var configCnpj = {
          method: 'GET',
          maxBodyLength: Infinity,
          url: urlAPIlocal + '/api/Cadastro?NumeroCnpj=' + wCnpj + "&CodTipoCadastro=" + Tipo,
          headers: {
            'Authorization': "Bearer " + localStorage.getItem("token"),
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(configCnpj)
          .then(function (response) {
            //console.log(response)
            // verifica se a propriedade NumeroCnpj existe e é igual a numeroCnpj definido acima
            if (response.data.codigoRetorno > 0) {
              setCnpjExiste(true);
              setIsValid(true);
              setNome('');
              setNomeFantasia('');
              setEndereco('');
              setCidade('');
              setEstado('');
              setCep('');
              setNumero('');
              setBairro('');
              setComplementoEnd('');
              setPais('');
              setDdd('');
              setTelefone('');
              setEmail('');
              setWebsite('');
              setAtividade('');

              setBotaoDesabilitado(true)

            } else {
              setBotaoDesabilitado(false)
              setCnpjExiste(false);
              //verifica se o cnpj é valido antes de consultar no cnpja
              if (!cnpj.isValid(wCnpjLimpo)) {
                setIsValid(false);
                setwCNPJA(false)
                setNome('');
                setNomeFantasia('');
                setEndereco('');
                setCidade('');
                setEstado('');
                setCep('');
                setNumero('');
                setBairro('');
                setComplementoEnd('');
                setPais('');
                setDdd('');
                setTelefone('');
                setEmail('');
                setWebsite('');
                setAtividade('');

                return;
              } else {
                setIsValid(true);
                if (wCNPJA === false) {
                  setwCNPJA(true)
                  //pega os dados do cnpja api e coloca nos imputs
                  const configCnpja = {
                    method: 'get',
                    url: 'https://api.cnpja.com/office/' + wCnpjLimpo + '?strategy=CACHE',
                    headers: {
                      'Authorization': 'c60ee439-9d06-4cf8-938f-cb308b79d69c-69726b11-6db8-494c-8188-b27c39994c66'
                    }
                  };

                  axios(configCnpja)
                    .then(function (response) {
                      //console.log(response)
                      setNome(response.data.company.name);
                      setNomeFantasia(response.data.company.alias);
                      setEndereco(response.data.address.street);
                      setCidade(response.data.address.city);
                      setEstado(response.data.address.state);
                      setCep(response.data.address.zip);
                      setNumero(response.data.address.number);
                      setBairro(response.data.address.district);
                      setComplementoEnd(response.data.address.details);
                      setPais(response.data.address.country.name);

                      if (response.data.phones[0] && response.data.phones[0].area !== undefined) {
                        setDdd(response.data.phones[0].area);
                      }
                      if (response.data.phones[0] && response.data.phones[0].number !== undefined) {
                        setTelefone(response.data.phones[0].number);
                      }
                      if (response.data.emails[0] && response.data.emails[0].address !== undefined) {
                        setEmail(response.data.emails[0].address);
                      }
                      if (response.data.emails[0] && response.data.emails[0].domain !== undefined) {
                        setWebsite(response.data.emails[0].domain);
                      }


                      setAtividade(response.data.mainActivity.text);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      else {
        setIsValid(false)
        setCnpjExiste(false)
      }
    }

  }


  const handleFileregularidadeJuridica = (event) => {
    if (event.target.files) {
      setFileregularidadeJuridica(event.target.files[0]);
    }
  };


  const handleFileregularidadeFiscal = (event) => {
    if (event.target.files) {
      setFileregularidadeFiscal(event.target.files[0]);
    }
  };

  const handleRegistroCivil = (event) => {
    if (event.target.files) {
      setFileRegistroCivil(event.target.files[0]);
    }
  };


  const handleFilecartaoCNPJ = (event) => {
    if (event.target.files) {
      setFilecartaoCNPJ(event.target.files[0]);
    }
  };

  const handleFilecertidaoFGTS = (event) => {
    if (event.target.files) {
      setFilecertidaoFGTS(event.target.files[0]);
    }
  };


  const handleFilecertidaoTrabalhista = (event) => {
    if (event.target.files) {
      setFilecertidaoTrabalhista(event.target.files[0]);
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    // caso nao tenho nenhuma atividade ou produto selecionado nao pode submitar
    if (selecioneAtividade === false) {
      setBotaoDesabilitado(true);
      setGravando(true);

      // Verifica se a opção "Empresa Estrangeira" está marcada
      /* if (!empresaEstrangeira) {
        // Se a opção "Empresa Estrangeira" não estiver marcada, chama a função handleCNPJ para lidar com o CNPJ
        handleCNPJ();
      } */

      handleCNPJ({ target: { value: NumeroCnpj } });


      //se for tipo=32 concedente marca como aprovado
      let wAprovado = "0"
      if (Tipo === '32') { wAprovado = "1" }

      var wtokenBearer = "Bearer " + localStorage.getItem("token")
      var data = JSON.stringify({
        "CodigoCadastro": 0,
        "NumeroCnpj": NumeroCnpj,
        "nome": nome,
        "TipoPessoa": "J",
        "sigla": "",
        "esfera": "",
        "nomeFantasia": nomeFantasia,
        "NomeContato": NomeContato,
        "endereco": endereco + ', ' + numero,
        "bairro": bairro,
        "NomeCidade": cidade,
        "NomeEstado": estado,
        "NumeroCep": cep,
        "complementoEnd": ComplementoEnd,
        "pais": pais,
        "NumeroDdd": ddd,
        "NumeroTelefone": telefone,
        "ddd2": ddd2,
        "telefone2": telefone2,
        "NumeroCelular": "",
        "email": {
          "endereco": email
        },
        "website": website,
        "fax": "",
        "NumeroRg": "",
        "OrgaoExpedidor": "",
        "DataExpedicao": "1900-01-01T00:00:00.000Z",
        "cargo": "",
        "funcao": "",
        "NumeroMatricula": "",
        "CodigoDirigente": 0,
        "CodigoTipoCadastro": Tipo,
        "dataEmissao": dataFormatada,
        "nomeBanco": nomeBanco,
        "NumeroBanco": "",
        "Pix": pix,
        "NumeroAgencia": agencia,
        "NumeroConta": NumeroConta,
        "nomeConta": "",
        "imagem": "",
        "NumeroPis": "0",
        "nacionalidade": "",
        "Aprovado": wAprovado,
        "tipoConta": "Conta Corrente",
        "estadoCivil": "",
        "servidor": false,
        "DataNascimento": "1900-01-01T00:00:00.000Z",
        "cnpjCpfConta": cnpjCpfConta,
        "titularConta": titularConta,
        "NumeroInscricaoEstadual": inscricaoEstadual,
        "NumeroInscricaoMunicipal": inscricaoMunicipal,
        "Atividade": Atividade,
        "cnae": cnae,
        "simplesNacional": simplesNacional,
        "observacao": observacao,
        "PerfilDestinoAtual": 0,

      });

      var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlAPIlocal + '/api/cadastro',
        headers: {
          'Authorization': wtokenBearer,
          'Content-Type': 'application/json'
        },
        data: data
      };
      //console.log(data)
      axios(config)

        .then(function (response) {

          //console.log(response.data)
          if (response.data.isOk === true) {
            // fez ao consumo sem erro
            // pega o codigo cadastrado
            var wCodCadastroNovo = response.data.objetoRetorno.CodigoCadastro



            if (produtos.length > 0) {
              gravarAtividade(produtos, wCodCadastroNovo, wtokenBearer, false);
            }

            if (servicos.length > 0) {
              gravarAtividade(servicos, wCodCadastroNovo, wtokenBearer, true);
            }

            if (!empresaEstrangeira) {

              //grava os arquivos e registro do arquivo no tblarquivoCadastro
              var Resultado = false
              if (fileregularidadeJuridica !== null) {
                Resultado = GravaArquivo(wCodCadastroNovo, fileregularidadeJuridica, "Regularização Jurídica", wtokenBearer, '01/01/1900')
                if (Resultado === true) { setCadastroOk(false); setInputFilled(true) }
              } else { setCadastroOk(true); setInputFilled(false) }

              if (fileregularidadeFiscal !== null) {
                Resultado = GravaArquivo(wCodCadastroNovo, fileregularidadeFiscal, "Certidão Conjunta Receita Federal", wtokenBearer, dataCnd)
                if (Resultado === true) { setCadastroOk(false); setInputFilled(true) }
              }
              if (fileRegistroCivil !== null) {
                Resultado = GravaArquivo(wCodCadastroNovo, fileRegistroCivil, "Registro Civíl", wtokenBearer, '01/01/1900')
                if (Resultado === true) { setCadastroOk(false); setInputFilled(true) }
              }
              if (filecartaoCNPJ !== null) {
                Resultado = GravaArquivo(wCodCadastroNovo, filecartaoCNPJ, "Cartão CNPJ", wtokenBearer, '01/01/1900')
                if (Resultado === true) { setCadastroOk(false); setInputFilled(true) }
              }
              if (filecertidaoFGTS !== null) {
                Resultado = GravaArquivo(wCodCadastroNovo, filecertidaoFGTS, "Certidão FGTS", wtokenBearer, dataFgts)
                if (Resultado === true) { setCadastroOk(false); setInputFilled(true) }
              }
              if (filecertidaoTrabalhista !== null) {
                Resultado = GravaArquivo(wCodCadastroNovo, filecertidaoTrabalhista, "Certidão Trabalhista", wtokenBearer, dataTrabalhista)
                if (Resultado === true) { setCadastroOk(false); setInputFilled(true) }
              }

            }

            setBotaoDesabilitado(false);
            setGravando(false);

          } else {
            //deu algum erro tratar , colocar algum aviso
            setShowModalErro(true);
            setGravando(false);
            //setCadastroOk(false);
          }
        })
        .catch(error => {
          console.error(error);
          setGravando(false);
          setShowModalErro(true);
        });
    }
  }


  const handleFechaGravando = (event) => {
    if (CadastrOk === true) { setGravando(false) };
  }

  return (
    <>
      <Menu />
      <div className={StyleForm.page}>
        <div> <button className={StyleForm.voltar}>  <Link to="/CadGeral" ><IoMdArrowBack /> Voltar</Link> </button> </div>
        <div className={StyleForm.letreiro}>
          {Tipo === '33' ? <h1>Cadastro de Fornecedor - Pessoa Jurídica</h1> : Tipo === '32' && <h1>Cadastro de Concedente</h1>}
        </div>

        <Form className={StyleForm.container} onSubmit={handleSubmit}>
          <header className={StyleForm.container_header}>Ficha Técnica</header>
          <FormGroup className={StyleForm.form_group_cnpj}>
            <Row>
              <Col>
                <Form.Label>Empresa Estrangeira</Form.Label>
                <Form.Check className={StyleForm.form_check} type="switch" value={empresaEstrangeira}
                  onChange={(event) => {
                    //console.log("Empresa Estrangeira marcada:", event.target.checked);
                    setEmpresaEstrangeira(event.target.checked);

                    // Se a opção "Empresa Estrangeira" for marcada, defina o CNPJ como "00.000.000/0000-00"
                    if (event.target.checked) {
                      setNumeroCnpj("00.000.000/0000-00");
                    }
                  }} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label} > CNPJ*</Form.Label>
                <InputMask className={StyleForm.form_control_cnpj} mask="99.999.999/9999-99" value={NumeroCnpj} onChange={handleCNPJ} required={!empresaEstrangeira} />
                {cnpjExiste && <p className={StyleForm.alert}>CNPJ já cadastrado, entre em contato com a Fundação.</p>}
                <div align="center">{!isValid && <p className={StyleForm.alert}>CNPJ inválido</p>}</div>
              </Col>
              <Col xs={8}>
                <Form.Label className={StyleForm.form_group_label} >Razão Social*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="nome" value={nome} onChange={(event) => setNome(event.target.value)} required />

              </Col>
            </Row>

            <Row>
              <Col xs={8}>
                <Form.Label className={StyleForm.form_group_label}>Nome Fantasia</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="NomeFantasia" value={nomeFantasia} onChange={(event) => setNomeFantasia(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Nome para Contato</Form.Label>
                <Form.Control className={StyleForm.form_control} name="NomeContato" value={NomeContato} onChange={(event) => setNomeContato(event.target.value)} />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>CEP*</Form.Label>
                <InputMask className={StyleForm.form_control_cnpj} type="text" name="cep" mask="99999-999" value={cep} onChange={(event) => setCep(event.target.value)} required={!empresaEstrangeira} />
                {/* {!isValidCep && <p className={StyleForm.alert}>CEP inválido</p>} */}
              </Col>
              <Col xs={7}>
                <Form.Label className={StyleForm.form_group_label}>Endereço*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="endereco" value={endereco} onChange={(event) => setEndereco(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Numero*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="numero" value={numero} onChange={(event) => setNumero(event.target.value)} required />
              </Col>

            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Complemento</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="ComplementoEnd" value={ComplementoEnd} onChange={(event) => setComplementoEnd(event.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Bairro*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="bairro" value={bairro} onChange={(event) => setBairro(event.target.value)} required={!empresaEstrangeira} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Cidade*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="cidade" value={cidade} onChange={(event) => setCidade(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Estado*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="estado" value={estado} onChange={(event) => setEstado(event.target.value)} required={!empresaEstrangeira} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>País*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="Pais" value={pais} onChange={(event) => setPais(event.target.value)} required />
              </Col>

            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>DDD*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="ddd" value={ddd} onChange={(event) => setDdd(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Telefone*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="tel" name="telefone" value={telefone} onChange={(event) => setTelefone(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>DDD 2</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="ddd2" value={ddd2} onChange={(event) => setDdd2(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Telefone 2</Form.Label>
                <Form.Control className={StyleForm.form_control} type="tel" name="telefone2" value={telefone2} onChange={(event) => setTelefone2(event.target.value)} />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Email*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} required />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Website</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="website" value={website} onChange={(event) => setWebsite(event.target.value)} />
              </Col>
            </Row>
          </FormGroup>



          <header className={StyleForm.container_header}>Informações Bancárias</header>


          <FormGroup className={StyleForm.form_group}>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Nome do Banco</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="NomeBanco" value={nomeBanco} onChange={(event) => setNomeBanco(event.target.value)} />
              </Col>
              <Col xs={3}>
                <Form.Label className={StyleForm.form_group_label}>Agencia</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="NumAgencia" value={agencia} onChange={(event) => setAgencia(event.target.value)} />
              </Col>
              <Col xs={3}>
                <Form.Label className={StyleForm.form_group_label}>Numero da Conta e Dígito</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="NumeroConta" value={NumeroConta} onChange={(event) => setNumeroConta(event.target.value)} />
              </Col>
            </Row>

            <Row>

              <Col>
                <Form.Label className={StyleForm.form_group_label}>CNPJ do Titular</Form.Label>
                <InputMask className={StyleForm.form_control_cnpj} mask="99.999.999/9999-99" value={cnpjCpfConta} onChange={(event) => setcnpjCpfConta(event.target.value)} />
              </Col>
              <Col xs={8}>
                <Form.Label className={StyleForm.form_group_label}>Nome do Titular</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="titularConta" value={titularConta} onChange={(event) => settitularConta(event.target.value)} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Chave Pix</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="pix" value={pix} onChange={(event) => setPix(event.target.value)} />
              </Col>
            </Row>
          </FormGroup>


          <header className={StyleForm.container_header}>Informações da Empresa</header>


          <FormGroup className={StyleForm.form_group}>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Inscrição Estadual</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="inscricaoEstadual" value={inscricaoEstadual} onChange={(event) => setinscricaoEstadual(event.target.value)} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Inscrição Municipal*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="inscricaoMunicipal" value={inscricaoMunicipal} onChange={(event) => setinscricaoMunicipal(event.target.value)} required={empresaEstrangeira ? Tipo !== '32' : false} />
              </Col>
              <Col xs={5}>
                <Form.Label className={StyleForm.form_group_label}>Tipo de Atividade*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="Atividade" value={Atividade} onChange={(event) => setAtividade(event.target.value)} required={empresaEstrangeira ? Tipo !== '32' : false} />
              </Col>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>CNAE Principal*</Form.Label>
                <Form.Control className={StyleForm.form_control} type="text" name="Cnae" value={cnae}
                  onChange={(event) => {
                    setCnae(event.target.value)
                    if (produtos.length === 0 || servicos.length === 0) {
                      setSelecioneAtividade(true);
                    }
                  }
                  } required={empresaEstrangeira ? Tipo !== '32' : false} />
              </Col>

            </Row>

            <Row>
              {<div align="center">{selecioneAtividade && <span style={{ color: '#ff0303d0', fontSize: '14px' }}>Selecione pelo menos uma opção de serviço ou produto.</span>}</div>}
              <Form.Label className={StyleForm.form_group_label}>Serviços Oferecidos</Form.Label>
              <Select isMulti={true} isClearable={true} isSearchable={true} closeMenuOnSelect={false} options={selectServicos} value={servicos} onChange={handleSelectServicos} className={StyleForm.form_control_select}></Select>
            </Row>
            <Row>
              <Form.Label className={StyleForm.form_group_label}>Produtos Oferecidos</Form.Label>
              <Select isMulti={true} isClearable={true} isSearchable={true} closeMenuOnSelect={false} options={selectProdutos} value={produtos} onChange={handleSelectProduto} className={StyleForm.form_control_select}></Select>

            </Row>
            <Row>
              <Col>
                <Form.Label className={StyleForm.form_group_label}>Optante do Simples Nacional?</Form.Label>
                <Form.Check className={StyleForm.form_check} type="switch" name="simplesNacional" value={simplesNacional} onChange={(event) => setSimplesNacional(event.target.checked)} />
              </Col>
              <Col xs={9}>
                <Form.Label className={StyleForm.form_group_label}>Observações</Form.Label>
                <Form.Control className={StyleForm.form_control} name="observacao" id="observacao" value={observacao} onChange={(event) => setobservacao(event.target.value)}></Form.Control>
              </Col>
            </Row>
          </FormGroup>

          <header className={StyleForm.container_header}>Documentos para habilitação <p>Todos os documentos anexados devem estar em formato PDF</p></header>

          <FormGroup className={StyleForm.form_group}>

            {/* <div className={StyleForm.title_container}>
              <h6>COMPROVAÇÃO DA REGULARIDADE JURÍDICA</h6>
            </div> */}

            {Tipo === '32' || empresaEstrangeira ? (
              <h1 style={{ fontSize: '14px', color: '#CD6155' }}>Para cadastro de "Concedente" ou "Empresa Estrangeira" os documentos não são obrigatórios.</h1>
            ) : null}


            <Form.Label className={StyleForm.label_doc}>Contrato Social ou Requerimento de Abertura</Form.Label>
            <Form.Control className={StyleForm.input} name="regularidadeJuridica" type="file" accept="application/pdf" onChange={handleFileregularidadeJuridica} required={!empresaEstrangeira && Tipo !== '32'} />
            <p>Contrato social, ato constitutivo ou estatuto em vigor registrado em cartório, acompanhado da ata de comprovação da eleição de sua atual diretoria</p>

            <Form.Label className={StyleForm.label_doc}>Cartão CNPJ</Form.Label>
            <Form.Control className={StyleForm.input} name="cartaoCNPJ" type="file" accept="application/pdf" onChange={handleFilecartaoCNPJ} required={!empresaEstrangeira && Tipo !== '32'} />

            <Form.Label className={StyleForm.label_doc}>Registro Civil de Pessoas Jurídicas</Form.Label>
            <Form.Control className={StyleForm.input} name="registroCivil" type="file" accept="application/pdf" onChange={handleRegistroCivil} />
            <p>*Para Sociedades Simples</p>
            {/* <Form.Label className={StyleForm.label_doc}>Alteração do Contrato Social*</Form.Label>
            <Form.Control className={StyleForm.input} name="AlteracaoRegularidadeJuridica" type="file" accept="application/pdf" onChange={handleFileAlteracaoRegularidadeJuridica} /> */}

            {/* <Form.Label className={StyleForm.label_doc}>Atestado de Capacidade*</Form.Label>
            <Form.Control className={StyleForm.input} name="AtestadoCapacidade" type="file" accept="application/pdf" onChange={handleFileAtestadoCapacidade} /> */}
            <Row>
              <Col>
                <Form.Label className={StyleForm.label_doc}>Certidão Conjunta Negativa de Débitos Relativos</Form.Label>
                <Form.Control className={StyleForm.input} name="regularidadeFiscal" type="file" accept="application/pdf" onChange={handleFileregularidadeFiscal} required={!empresaEstrangeira && Tipo !== '32'} />
              </Col>
              <Col md={5}>
                <Form.Label className={StyleForm.label_doc}>Data da Certidão
                  {avisodataCnd ? (
                    <span style={{ color: '#ff0303d0', fontSize: '14px' }}> ( A certidão tem que estar válida para o cadastro.) </span>
                  ) : (
                    <span style={{ color: '#8b8a8a', fontSize: '14px' }}> ( Insira a data de validade da certidão anexada) </span>
                  )}
                </Form.Label>
                <Form.Control className={StyleForm.input} type="date" value={dataCnd}
                  onChange={(event) => {
                    const selectedDate = event.target.value;
                    const currentDate = moment().format('YYYY-MM-DD');

                    if (selectedDate >= currentDate) {
                      setDataCnd(selectedDate);
                      setAvisoDataCnd(false);
                    } else {
                      setAvisoDataCnd(true);
                    }
                  }} required={!empresaEstrangeira && Tipo !== '32'} />
              </Col>
            </Row>
            {/* <Form.Label className={StyleForm.label_doc}>Certidão Municipal*</Form.Label>
            <Form.Control className={StyleForm.input} name="certidaoMunicipal" type="file" accept="application/pdf" onChange={handleFilecertidaoMunicipal} /> */}
            <Row>
              <Col>
                <Form.Label className={StyleForm.label_doc}>Certidão FGTS</Form.Label>
                <Form.Control className={StyleForm.input} name="certidaoFGTS" type="file" accept="application/pdf" onChange={handleFilecertidaoFGTS} required={!empresaEstrangeira && Tipo !== '32'} />
              </Col>
              <Col md={5}>
                <Form.Label className={StyleForm.label_doc}>Data da Certidão
                  {avisodataFgts ? (
                    <span style={{ color: '#ff0303d0', fontSize: '14px' }}> ( A certidão tem que estar válida para o cadastro.) </span>
                  ) : (
                    <span style={{ color: '#8b8a8a', fontSize: '14px' }}> ( Insira a data de validade da certidão anexada) </span>
                  )}
                </Form.Label>
                <Form.Control className={StyleForm.input} type="date" value={dataFgts}
                  onChange={(event) => {
                    const selectedDate = event.target.value;
                    const currentDate = moment().format('YYYY-MM-DD');

                    if (selectedDate >= currentDate) {
                      setDataFgts(selectedDate);
                      setAvisoDataFgts(false);
                    } else {
                      setAvisoDataFgts(true);
                    }
                  }} required={!empresaEstrangeira && Tipo !== '32'} />
              </Col>
            </Row>
            {/* <Form.Label className={StyleForm.label_doc}>Certidão PGE SEFAZ*</Form.Label>
            <Form.Control className={StyleForm.input} name="certidaoPGE" type="file" accept="application/pdf" onChange={handleFilecertidaoPGE} /> */}

            {/* <Form.Label className={StyleForm.label_doc}>Proposta*</Form.Label>
            <Form.Control className={StyleForm.input} name="Proposta" type="file" accept="application/pdf" onChange={handleFileProposta} /> */}
            <Row>
              <Col>
                <Form.Label className={StyleForm.label_doc}>Certidão Trabalhista</Form.Label>
                <Form.Control className={StyleForm.input} name="certidaoTrabalhista" type="file" accept="application/pdf" onChange={handleFilecertidaoTrabalhista} required={!empresaEstrangeira && Tipo !== '32'} />
              </Col>
              <Col md={5}>
                <Form.Label className={StyleForm.label_doc}>Data da Certidão
                  {avisodataTrabalhista ? (
                    <span style={{ color: '#ff0303d0', fontSize: '14px' }}> ( A certidão tem que estar válida para o cadastro.) </span>
                  ) : (
                    <span style={{ color: '#8b8a8a', fontSize: '14px' }}> ( Insira a data de validade da certidão anexada) </span>
                  )}
                </Form.Label>
                <Form.Control className={StyleForm.input} type="date" value={dataTrabalhista}
                  onChange={(event) => {
                    const selectedDate = event.target.value;
                    const currentDate = moment().format('YYYY-MM-DD');

                    if (selectedDate >= currentDate) {
                      setDataTrabalhista(selectedDate);
                      setAvisoDataTrabalhista(false);
                    } else {
                      setAvisoDataTrabalhista(true);
                    }
                  }} required={!empresaEstrangeira && Tipo !== '32'} />
              </Col>
            </Row>
          </FormGroup>

          {<div align="center">{inputFilled && <span className={StyleForm.alert}>Falha na rede, Por favor clique em "Cadastrar" novamente.</span>}</div>}

          <Row>
            <Col>
              <button className={StyleForm.btn} type="submit" disabled={botaoDesabilitado ? true : false}>
                {/* disabled={botaoDesabilitado ? true : false} */}
                Gravar
                {CadastrOk && (<Navigate to="/cadGeral?ok" replace={true} />)}
                {/* {botaoDesabilitado ? 'Aguarde...' : 'Cadastrar'} */}
              </button>
              {/* <button onClick={handleEnviaEmail} type="button" className={StyleForm.termos}>enviar email</button> */}
            </Col>

          </Row>

        </Form>

        <Modal show={gravando} onLoad={handleFechaGravando} className={StyleForm.Modal} centered>
          <Modal.Header className={StyleForm.Modal_header}>
            <h3>Gravando...</h3>
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <img src={loading}></img>
          </Modal.Body>
        </Modal>

        <Modal show={showModalErro} className={StyleForm.Modal} centered >
          <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
            <h3>Ops!</h3>
            <ImSad2 />
          </Modal.Header>
          <Modal.Body className={StyleForm.Modal_body}>
            <p>Algo deu errado, cheque os campos e tente novamente</p>
          </Modal.Body>
        </Modal>

        {login && (<Navigate to="/" replace={true} />)}
      </div>

    </>
  )

}

export default CadPJ