import { Box } from "@mui/material";
import Menu from "../Layout/Menu";
import MenuArea from "../Layout/MenuArea";
import Style from "./VerSolicitacao.module.css"
import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react"
import axios from "axios";
import urlAPIlocal from '../../global';
import Table from 'react-bootstrap/Table';
import moment from "moment";
import { GrStatusGoodSmall } from 'react-icons/gr';
import { AiFillFilePdf, AiFillCheckCircle, AiFillEdit } from "react-icons/ai";
import { PiIdentificationBadge } from 'react-icons/pi';
import { BiSolidError } from 'react-icons/bi';
import { RiPhoneFindLine } from "react-icons/ri";
import React from 'react';
import { IoMdArrowBack, IoIosSend } from "react-icons/io";
import { IoMdPrint } from "react-icons/io"
import { BsArrowRight } from 'react-icons/bs'
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";
import loadingGif from '../img/icons8-spinning-circle.gif';
import { Modal } from 'react-bootstrap';
import { ImSad2 } from 'react-icons/im';
import { Card, Col, Form, FormGroup, Row } from "react-bootstrap";

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function GravaPerfilAtual(wCodSolic, wCodPerfil) {

    const wtoken = "Bearer " + localStorage.getItem("token");
    var Retorno = false;
    var config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: urlAPIlocal + '/api/Solicitacao/PerfilDestino/' + wCodSolic + '/' + wCodPerfil,
        headers: {
            'Authorization': wtoken,
            'Content-Type': 'application/json'
        },
    };
    //console.log(config);
    axios(config)
        .then(function (response) {

            //console.log(response.data)
            if (response.data.isOk === true) {
                Retorno = true;
            } else {
                //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                Retorno = false
            }
        })
        .catch(error => {
            console.error(error);
        });
}

function GravaArquivo(wCodSolic, ArqCadastro, DescricaoArq, tokenBear, wDatSituacao) {

    var Retorno = false;
    //trata nome arquivo, tira acentos, espaços
    let wNomeArquivoLimpo = ArqCadastro.name
    wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
    wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
    wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    wNomeArquivoLimpo = wCodSolic + "_" + wNomeArquivoLimpo

    wDatSituacao = moment(wDatSituacao).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    var datFiles = JSON.stringify({
        "CodigoArquivo": 0,
        "CodigoSolicitacao": wCodSolic,
        "descricao": DescricaoArq,
        "arquivo": wNomeArquivoLimpo, //CodCadastro + "_" + ArqCadastro.name,
        "status": 0
    })
    var config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlAPIlocal + '/api/ArquivoProcesso',
        headers: {
            'Authorization': tokenBear,
            'Content-Type': 'application/json'
        },
        data: datFiles
    };
    //console.log(datFiles)
    axios(config)
        .then(function (response) {

            //console.log(response.data)
            if (response.data.isOk === true) {
                //faz upload do arquivo
                const file = ArqCadastro;
                const novoNome = wNomeArquivoLimpo;
                const novoArquivo = new File([file], novoNome, { type: file.type });
                const formData = new FormData();
                formData.append('arquivo', novoArquivo);

                var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/UploadArquivo',
                    headers: {
                        'Authorization': tokenBear,
                        'Content-Type': 'multipart/form-dataa',
                        'pasta': 'ArquivosProcesso'
                    },
                    data: formData
                };

                axios(config)

                    .then(response => {
                        //console.log(response.data);
                        // faça algo com a resposta da API
                        Retorno = false;
                    })
                    .catch(error => {
                        console.error(error);
                        Retorno = true;

                    });
            } else {
                //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                Retorno = true

            }

        })
        .catch(function (error) {
            console.log(error);
            Retorno = true
        });
    return Retorno;
}


function VerSolicitacao() {

    const { CodigoSolicitacao } = useParams();

    var wPerfil = localStorage.getItem("CodigoPerfil");
    var wNomePerfil = localStorage.getItem("NomePerfil");
    var wTokenBearer = 'Bearer ' + localStorage.getItem("token");
    const [login, setLogin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [solicitacao, setSolicitacao] = useState([]);
    const [fase, setFase] = useState([]);

    const [metas, setMetas] = useState([]);
    const [metaEscolhida, setMetaEscolhida] = useState(0);
    const [fases, setFases] = useState([]);
    const [faseEscolhida, setFaseEscolhida] = useState(0);
    const [fonte, setFonte] = useState(0);

    const [item, setItem] = useState([]);
    const [status, setStatus] = useState([]);
    const [valorTotalSolic, setValorTotalSolic] = useState(0);
    const [arquivo, setArquivo] = useState([]);
    var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosProcesso/"
    //var LinkArquivo = "C:\\Users\\Alison\\source\\repos\\Projeto.Control\\UploadsFauscs\\ArquivosProcesso\\";
    const [setor, setSetor] = useState('');
    const [nomeSetor, setNomeSetor] = useState('');
    const [setorRegularizacao, setSetorRegularizacao] = useState('');
    const [selecioneSetor, setSelecioneSetor] = useState(false);
    const [mensagemRemetente, setMensagemRemetente] = useState('');
    const [encaminharSolicitacao, setEncaminharSolicitacao] = useState(false);
    const [mensagemEncaminhamento, setMensagemEncaminhamento] = useState('');
    const [encaminhado, setEncaminhado] = useState(false);
    const fileInputRef = useRef(null);
    const [NovoFile, setNovoFile] = useState(null);
    const [descricaoarquivo, setDescricaoArquivo] = useState('');

    const [solicitarExclusao, setSolicitarExclusao] = useState(false);
    const [encaminhadoExclusao, setEncaminhadoExclusao] = useState(false);
    const [motivoExclusao, setMotivoExclusao] = useState('');
    const [arquivoSelecionado, setArquivoSelecionado] = useState(null);
    const [nomeArquivoSelecionado, setNomeArquivoSelecionado] = useState('');
    const [motivoRequired, setMotivoRequired] = useState(false);
    const [arquivoAnalise, setArquivoAnalise] = useState('');
    const [perfilDestinoFinal, setPerfilDestinoFinal] = useState('');
    const [descricao, setDescricao] = useState('');
    const [arquivoExclusao, setArquivoExclusao] = useState([]);

    const [modalExcluirArquivo, setModalExcluirArquivo] = useState(false);
    const [formConfirmarExclusao, setFormConfirmarExclusao] = useState(false);
    const [formNaoAutorizarExclusao, setFormNaoAutorizarExclusao] = useState(false);
    const [motivoNaoExclusao, setMotivoNaoExclusao] = useState('');

    const [enviaTexto, setEnviaTexto] = useState(false);
    const [textoRequired, setTextoRequired] = useState(false);
    const [textoEnviado, setTextoEnviado] = useState(false);
    const [enviaArquivo, setEnviaArquivo] = useState(false);
    const [TextoSolicitacao, setTextoSolicitacao] = useState('');
    const [selecioneArquivo, setSelecioneArquivo] = useState(false);

    const [arquivoEnviado, setArquivoEnviado] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);
    const [codConvenio, setCodConvenio] = useState('');
    const [alertaSaldo, setAlertaSaldo] = useState(false);
    const [avisoSaldo, setAvisoSaldo] = useState('');
    const [pt, setPt] = useState([]);
    const [numFaseSelecionada, setNumFaseSelecionada] = useState('');
    const [btAtualiza, setBtAtualiza] = useState(false);
    const [precisaTipoContratacao, setPrecisaTipoContratacao] = useState(false);
    const [tipoContratacao, setTipoContratacao] = useState('');
    const [numElemento, setNumElemento] = useState('');

    const [modalDuploCheck, setModalDuploCheck] = useState(false);
    const [senha, setSenha] = useState('');
    const [avisoDuploCheck, setAvisoDuploCheck] = useState(false);
    const [avisoSenhaOk, setAvisoSenhaOk] = useState(false);
    const [senhaVerificada, setSenhaVerificada] = useState(false);

    const [botaoAtivo, setBotaoAtivo] = useState(null);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [redir, setRedir] = useState(false);
    const [anoSolic, setAnoSolic] = useState('');
    const [showAviso, setShowAviso] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    const navigate = useNavigate();
    function handleClick() {
        navigate(-1);
    }

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenBearer === undefined || wTokenBearer === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    function handleCloseErro() {
        setShowModalErro(false);
    };

    const [showModalArquivo, setShowModalArquivo] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const openModalArquivo = (nomeArquivo) => {
        //console.log(nomeArquivo)
        setFullscreen(true);
        setModalContent(nomeArquivo);
        setShowModalArquivo(true);
    };
    const closeModal = () => {
        setShowModalArquivo(false);
    };

    function showModalExcluirArquivo(wNomeArquivo, wCodigoArquivo, wPerfilRemetente, wDescricao) {
        setModalExcluirArquivo(true);
        setArquivoAnalise(wNomeArquivo);
        setArquivoExclusao(wCodigoArquivo)
        setPerfilDestinoFinal(wPerfilRemetente);
        setDescricao(wDescricao);
    }
    function handleCloseExcluirArquivo() {
        setModalExcluirArquivo(false);
    };

    const ConfirmarExclusao = async (event) => {
        event.preventDefault();

        if (senhaVerificada) {
            ConfirmarExclusaoComSenha();
        } else {
            setModalDuploCheck(true);

        }
    }

    function ConfirmarExclusaoComSenha() {
        setLoading(true);

        var datHoje = new Date();

        var data = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": CodigoSolicitacao,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": true,
            "Motivo": 'Arquivo <strong>' + arquivoAnalise + '</strong> excluído da solicitação',
            "CodigoPerfil": wPerfil,
            "Doc": "",
            "PerfilDestino": perfilDestinoFinal,
            "CodCadastro": localStorage.getItem("CodigoCadastro")
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json',
            },
            data: data
        };
        //console.log(config);

        axios(config)

            .then(function (response) {
                const data = response.data;

                //grava status do arquivo como true
                var dataPUT = JSON.stringify({
                    "CodigoArquivo": arquivoExclusao,
                    "CodigoSolicitacao": CodigoSolicitacao,
                    "Descricao": descricao + " (excluído do processo)",
                    "Arquivo": arquivoAnalise,
                    "Status": 1
                });

                var configPUT = {
                    method: 'PUT',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/ArquivoProcesso',
                    headers: {
                        'Authorization': wTokenBearer,
                        'Content-Type': 'application/json',
                    },
                    data: dataPUT
                };
                //console.log(configPUT);
                axios(configPUT)

                    .then(function (response) {

                        var configGET = {
                            method: 'GET',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/UploadArquivo/ArquivoExcluido',
                            headers: {
                                'pasta': 'ArquivosProcesso',
                                'arquivo': arquivoAnalise,
                                'Authorization': wTokenBearer,
                                'Content-Type': 'application/json'
                            },
                        };
                        //console.log(configGET);
                        axios(configGET)
                            .then(function (response) {
                                const dataGet = response.data;
                                // console.log(dataGet);
                                if (dataGet.isOk === true) {
                                    PegaStatus();
                                    setSenhaVerificada(false); // Reinicia o estado para futuras verificações
                                    setLoading(false);
                                    setModalExcluirArquivo(false);
                                    // Recarrega a página
                                    window.location.reload();
                                } else {
                                    setShowModalErro(true);
                                    setModalExcluirArquivo(true);
                                }
                            }
                            )
                            .catch(function (error) {
                                setLoading(false);
                                console.log(error);
                                if (error.response.status === 401) {
                                    setLogin(true);
                                }
                                //console.log(JSON.stringify(response.data.mensagemRetorno));
                            });

                    })
                    .catch(function (error) {
                        setLoading(false);
                        console.log(error);
                        //console.log(JSON.stringify(response.data.mensagemRetorno));
                    });


            }
            )
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    const NaoAutorizarExclusao = async (event) => {
        event.preventDefault();
        setFormNaoAutorizarExclusao(true);
    }

    const handleNaoAutorizarExclusao = (event) => {
        event.preventDefault();

        if (senhaVerificada) {
            handleNaoAutorizarExclusaoComSenha();
        } else {
            setModalDuploCheck(true);
        }
    }

    function handleNaoAutorizarExclusaoComSenha() {
        setLoading(true);

        var datHoje = new Date();

        var data = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": CodigoSolicitacao,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": 'Exclusão do arquivo <strong>' + arquivoAnalise + '</strong> não autorizada.<br><strong>Motivo:</strong> ' + motivoNaoExclusao,
            "CodigoPerfil": wPerfil,
            "Doc": "",
            "PerfilDestino": perfilDestinoFinal,
            "CodCadastro": localStorage.getItem("CodigoCadastro")
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json',
            },
            data: data
        };

        axios(config)

            .then(function (response) {
                const data = response.data;
                //grava status do arquivo como true
                var dataPUT = JSON.stringify({
                    "CodigoArquivo": arquivoExclusao,
                    "CodigoSolicitacao": CodigoSolicitacao,
                    "Descricao": descricao,
                    "Arquivo": arquivoAnalise,
                    "Status": 0
                });

                var configPUT = {
                    method: 'PUT',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/ArquivoProcesso',
                    headers: {
                        'Authorization': wTokenBearer,
                        'Content-Type': 'application/json',
                    },
                    data: dataPUT
                };
                //console.log(configPUT);
                axios(configPUT)

                    .then(function (response) {
                        //console.log(response.data);
                        if (response.data.isOk === true) {
                            PegaStatus();
                            setSenhaVerificada(false); // Reinicia o estado para futuras verificações
                            setLoading(false);
                            setModalExcluirArquivo(false);
                            // Recarrega a página
                            window.location.reload();
                        } else {
                            setShowModalErro(true);
                            setModalExcluirArquivo(true);
                        }

                    })
                    .catch(function (error) {
                        setLoading(false);
                        console.log(error);
                        //console.log(JSON.stringify(response.data.mensagemRetorno));
                    });


            }
            )
            .catch(function (error) {
                setLoading(false);
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    const getSolicitacao = async () => {
        setLoading(true);
        //const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao/' + CodigoSolicitacao + '?IsInclude=true',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {

                const data = responseSolic.data;
                //console.log(data.objetoRetorno);
                setSolicitacao(data.objetoRetorno);
                setCodConvenio(data.objetoRetorno.CodigoConvenio);
                setTextoSolicitacao(data.objetoRetorno.Tramitacao);
                setValorTotalSolic(data.objetoRetorno.ValorTotal);
                PegaMetaFase(data.objetoRetorno.CodigoFase);
                PegaItem();
                PegaStatus();
                PegaNumElemento(data.objetoRetorno.CodigoElemento)
                setLoading(false);

                const dataCompleta = new Date(data.objetoRetorno.DataSolicitacao);
                const ano = dataCompleta.getFullYear();
                setAnoSolic(ano);
            }
            )
            .catch(function (error) {
                console.log(error);
                // if (error.response.status === 401) {
                //     setLogin(true);
                // }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getSolicitacao();
        //eslint-disable-next-line
    }, []);

    //console.log(solicitacao.ValorTotal);

    function PegaMetaFase(CodFase) {

        //const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Fase?CodigoConvenioFase=' + CodFase + '&IsInclude=true',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                //console.log(response)
                const data = response.data;
                if (response.data.isOk === true) {
                    setFase(data.objetoRetorno);
                    //console.log(data.objetoRetorno)
                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setFase('');
                }


            }
            )
            .catch(function (error) {
                console.log(error);

            });

    }

    function PegaNumElemento(CodElemento) {

        //const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ConvenioElemento?CodigoConvenioElemento=' + CodElemento + '&IsInclude=false',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                //console.log(response)
                const data = response.data;
                if (response.data.isOk === true) {
                    setNumElemento(data.objetoRetorno[0].CodigoElemento);
                    //console.log(data.objetoRetorno[0].CodigoElemento)
                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setNumElemento('');
                }
            }
            )
            .catch(function (error) {
                console.log(error);

            });

    }

    function PegaItem() {

        // const wtoken = "Bearer " + wTokenLocal;

        var configItem = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/SolicitacaoItem?CodigoSolicitacao=' + CodigoSolicitacao,
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(configItem)

            .then(function (responseItem) {

                const data = responseItem.data;
                setItem(data.objetoRetorno);
                //console.log(data.objetoRetorno)
            }
            )
            .catch(function (error) {
                console.log(error);
            });
    }

    function PegaStatus() {
        // const wtoken = "Bearer " + wTokenLocal;

        var configStatus = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },
        };

        axios(configStatus)
            .then(function (responseStatus) {
                const data = responseStatus.data;
                setStatus(data.objetoRetorno);
                //console.log(data.objetoRetorno);

                getIconColor(data.objetoRetorno[0].Perfil_Destino.Nome);

            })
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    function getIconColor(nomePerfil) {
        if (nomePerfil === 'Compras') {
            return '#2980B9';
        } else if (nomePerfil === 'Financeiro') {
            return '#27AE60';
        } else if (nomePerfil === 'Assessoria Jurídica') {
            return '#CB4335';
        } else if (nomePerfil === 'Diretoria Administrativa') {
            return '#F4D03F';
        } else if (nomePerfil === 'Recursos Humanos') {
            return '#8E44AD';
        } else if (nomePerfil === 'Secretário Executivo') {
            return '#0B5345';
        } else if (nomePerfil === 'Consultor') {
            return '#626567';
        } else if (nomePerfil === 'Gestão de Projetos') {
            return '#E67E22';
        } else if (nomePerfil === 'Equipe Area') {
            return '#85C1E9';
        } else {
            return '#34495E';
        }
    };

    const getArquivo = async (event) => {

        // const wtoken = "Bearer " + wTokenLocal;

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcesso?CodigoSolicitacao=' + CodigoSolicitacao,
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {
                const data = response.data;
                setArquivo(data.objetoRetorno);
                //console.log(data.objetoRetorno);
            }
            )
            .catch(function (error) {
                //console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    useEffect(() => {
        getArquivo();
        // eslint-disable-next-line
    }, []);

    const handleNovoFile = (event) => {
        if (event.target.files) {
            setNovoFile(event.target.files[0]);
        }
    };

    // const handleSubmitSolic = async (event) => {
    //     event.preventDefault();

    //     if (setor !== '0' && mensagemRemetente !== '') {
    //         setLoading(true);

    //         var wtokenBearer = "Bearer " + localStorage.getItem("token")
    //         var datHoje = new Date()

    //         var data = JSON.stringify({
    //             "CodigoStatus": 0,
    //             "CodigoSolicitacao": CodigoSolicitacao,
    //             "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
    //             "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    //             "status": false,
    //             "Motivo": mensagemremetente,
    //             "CodigoPerfil": wPerfil,
    //             "Doc": "",
    //             "PerfilDestino": setor,
    //             "CodCadastro": 155,
    //         });
    //         //console.log(data);
    //         var config = {
    //             method: 'post',
    //             maxBodyLength: Infinity,
    //             url: urlAPIlocal + '/api/Status',
    //             headers: {
    //                 'Authorization': wtokenBearer,
    //                 'Content-Type': 'application/json',
    //             },
    //             data: data
    //         };
    //         axios(config)
    //             .then(response => {
    //                 //console.log(response);
    //                 // faça algo com a resposta da API
    //                 //grava arquivo se tiver
    //                 //console.log(NovoFile);
    //                 if (NovoFile !== null) {
    //                     console.log('entrouaquigravaarquivo');
    //                     var Resultado = false
    //                     Resultado = GravaArquivo(CodigoSolicitacao, NovoFile, descricaoarquivo, wtokenBearer, moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"))
    //                     if (Resultado === true) { setArquivoEnviado(true); }
    //                     getArquivo();
    //                 }

    //                 //grava o PerfilDestinoAtual na tblSolicitacao
    //                 GravaPerfilAtual(CodigoSolicitacao, setor);

    //                 setEncaminhado(true);
    //                 setSetor('0');
    //                 setMensagemRemetente('');
    //                 setNovoFile(null);
    //                 setSelecioneSetor(false);
    //                 setDescricaoArquivo('');
    //                 fileInputRef.current.value = '';
    //                 setLoading(false);

    //             })
    //             .catch(error => {
    //                 console.error(error);
    //                 setLoading(false);
    //                 setShowModalErro(true);
    //             });
    //     } else {
    //         setLoading(false);
    //         setSelecioneSetor(true);
    //         setShowModalErro(true);
    //     };
    // }

    const handleSelectFonte = (event) => {
        setFonte(event.target.value);
        setAvisoSaldo(false);
        setBtAtualiza(false);
    };

    const getMetas = async () => {
        try {
            //const wtoken = "Bearer " + wTokenLocal;
            const config = {
                method: "GET",
                url: urlAPIlocal + "/api/Meta?CodigoConvenio=" + solicitacao?.Convenio?.CodigoConvenio,
                headers: {
                    Authorization: wTokenBearer,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);
            const data = response.data.objetoRetorno;
            setMetas(data);
            //console.log(data);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (solicitacao?.Convenio?.CodigoConvenio) {
            getMetas();
        }
    }, [solicitacao?.Convenio?.CodigoConvenio, metaEscolhida]);

    const handleSelectMeta = (event) => {
        setMetaEscolhida(event.target.value);
        getFases(event.target.value);
        setAlertaSaldo(false);
        setBtAtualiza(false);
    };

    const getFases = async (wCodigoMeta) => {
        try {
            setLoading(true);
            // const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url: urlAPIlocal + "/api/Fase?CodigoMeta=" + wCodigoMeta + "&IsInclude=true",
                headers: {
                    Authorization: wTokenBearer,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);
            const data = response.data.objetoRetorno;
            //console.log(data);
            setFases(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelectFase = (event) => {
        setFaseEscolhida(event.target.value);
        AlimentaPT();
        //console.log(fases);
        const fasesArray = Object.values(fases);
        const wNumfase = fasesArray.find(fase => fase.CodigoConvenioFase === parseInt(event.target.value));
        setNumFaseSelecionada(wNumfase.NumeroFase);

        //console.log(wNumfase.NumeroFase);

    };

    const ConsultaSaldo = () => {
        //pega as variaveis para consultar o saldo
        //CodMeta | CodFonte | CodFase | Valor total Solic
        //moment(financeiroConvenio.DataInicio).format("DD/MM/yyyy")

        //var wtokenBearer = "Bearer " + localStorage.getItem("token")
        // so consultar saldo se fonte meta fase estiver selecionado
        if (fonte !== 0 && metaEscolhida !== 0 && faseEscolhida !== 0) {
            setShowAviso(false);
            var data = JSON.stringify({
                "CodigoConvenio": codConvenio,
                "Data inicial": localStorage.getItem('DataInicioProjeto'),
                "Data final": localStorage.getItem('DataExpiracaoProjeto')
            });
            console.log(data);
            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Convenio/Financeiro?IsInclude=true',
                headers: {
                    'Authorization': wTokenBearer,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(config)

                .then(function (response) {

                    console.log(response.data)
                    if (response.data.isOk === true) {
                        // fez ao consumo sem erro
                        const wTotalCreditosProjeto = response.data.objetoRetorno.Financeiro.TotAportes + response.data.objetoRetorno.Financeiro.TotRepasses + response.data.objetoRetorno.Financeiro.TotRendimentos;
                        const wTotalDebitosProjeto = response.data.objetoRetorno.Financeiro.TotPagamentosBruto + response.data.objetoRetorno.Financeiro.SolicAPagar + response.data.objetoRetorno.Financeiro.TotDevAportes;
                        const SaldoProjeto = wTotalCreditosProjeto - wTotalDebitosProjeto;
                        //se o saldoprojeto for maior que o toal da solic, continua a consulta com as variaveis meta fase fonte valor total
                        //console.log(SaldoProjeto);
                        //console.log(valorTotalSolic);
                        if (SaldoProjeto < valorTotalSolic) {
                            // nao tem saldo financeiro no projeto para essa solicitação
                            setAvisoSaldo('Projeto sem Saldo financeiro para essa Solicitação');
                            setAlertaSaldo(true);
                        } else {
                            // tem saldo, agora enviar as variaveis e consultar se tem saldo na meta fase fonte elemento
                            const wFonte = parseInt(fonte);
                            const wElemento = parseInt(numElemento);
                            const wMeta = parseInt(metaEscolhida);
                            const wFase = numFaseSelecionada;
                            //console.log(wFase);
                            console.log('Fonte: ' + wFonte + '| CodMeta: ' + wMeta + '| NumFase: ' + wFase + '| CodFase: ' + faseEscolhida + '| NumElemento: ' + wElemento + '| CodElemento: ' + solicitacao[0].CodigoElemento);
                            //console.log(pt);
                            //busca a meta que foi escolhida, para depois buscar as fases elemento e valroes gastos e previstos
                            const metasArray = Object.values(pt.Metas);
                            //console.log(metasArray);
                            const metaEncontrada = metasArray.find(meta => meta.CodigoMeta === wMeta);

                            // agora busca a fase dentro da meta
                            const faseArray = Object.values(metaEncontrada.MetaFases);
                            //console.log(faseArray);
                            const faseEncontrada = faseArray.find(fase => fase.NumeroFase === wFase);

                            //agora verifica se tem o elemento solicitado na meta fase fonte
                            const MetaFaseElementoArray = Object.values(faseEncontrada.MetaFasePrevistos);
                            //console.log(MetaFaseElementoArray);
                            const ElementoEncontrado = MetaFaseElementoArray.find(Elemento => Elemento.CodigoElemento === wElemento && Elemento.CodigoFonte === wFonte);

                            //console.log(ElementoEncontrado); // Verifique se o objeto é encontrado corretamente.

                            if (ElementoEncontrado) {
                                //tem elemento, verifica se tem saldo na meta fase elemento fonte
                                const wValorSolic = valorTotalSolic;
                                const wVprevisto = ElementoEncontrado.Total;
                                const wGasto = ElementoEncontrado.ValGasto + wValorSolic;
                                //console.log(wGasto);
                                //console.log(wVprevisto);
                                if (wGasto > wVprevisto) {
                                    //sem saldo para essa solicitação
                                    setAvisoSaldo('Sem saldo para essa solicitação nessa Fonte | Meta | Fase | Elemento.');
                                    setAlertaSaldo(true);
                                    setBtAtualiza(false);
                                } else {
                                    //tem saldo prossegue gravando a meta fase e fonte na solicitação
                                    setAvisoSaldo('Saldo disponível para esse esse pedido, para confirmar a Meta, Fase e Fonte dessa solicitação, clicar em Atualizar Processo.');
                                    setAlertaSaldo(true);
                                    setBtAtualiza(true);
                                }
                            } else {
                                //não tem previsao do elemento e fonte na meta fase
                                setAvisoSaldo('Não existe no planejamento da Meta Fase esse Elemento de despesa.');
                                setAlertaSaldo(true);
                                setBtAtualiza(false);
                                console.log('entrou aqui');
                            }
                            //setAlertaSaldo(false);
                            //setBtAtualiza(false);
                        }

                    } else {
                        //deu algum erro tratar , colocar algum aviso
                        //setShowModalErro(true);
                        console.log('erro');
                    }
                })
                .catch(error => {
                    console.error(error);
                    if (error.response.status === 401) {
                        setLogin(true);
                    }
                    //setShowModalErro(true);
                });

        } else { setShowAviso(true) }

    }

    function AtualizaSolic() {

        setLoading(true);

        //primeiro busca solicitação para pegar todos os dados atuais dela
        //const wtoken = "Bearer " + wTokenLocal;
        //var wCodCredor = 0

        var configSolic = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },

        };
        //console.log(configSolic);
        axios(configSolic)

            .then(function (responseSolic) {
                const data = responseSolic.data;
                console.log(data.objetoRetorno);

                //console.log("TipoLicitacao:", data.objetoRetorno[0].TipoLicitacao);
                if (data.objetoRetorno[0].TipoLicitacao.trim() === '') {
                    setLoading(false);
                    setPrecisaTipoContratacao(true);
                } else {

                    //grava na solicitação - Fonte, Meta e Fase que acabou de ser definida e marca ela como finalizada
                    var dataEditSolic = JSON.stringify({

                        "CodigoSolicitacao": CodigoSolicitacao,
                        "DataSolicitacao": data.objetoRetorno[0].DataSolicitacao,
                        "NumeroSolicitacao": data.objetoRetorno[0].NumeroSolic,
                        "CodigoFonte": fonte,
                        "CodigoConvenio": data.objetoRetorno[0].CodigoConvenio,
                        "CodigoCredor": data.objetoRetorno[0].CodigoCredor,
                        "CodigoElemento": data.objetoRetorno[0].CodigoElemento,
                        "CodigoMeta": metaEscolhida,
                        "CodigoFase": faseEscolhida,
                        "ValorTotal": data.objetoRetorno[0].ValorTotal,
                        "IndFinalizada": data.objetoRetorno[0].IndFinalizada,
                        "Pago": data.objetoRetorno[0].Pago,
                        "Observacao": data.objetoRetorno[0].Observacao,
                        "CodigoAprovacaoCampus": data.objetoRetorno[0].CodigoAprovacaoCampus,
                        "CodigoAprovacaoDARDC": data.objetoRetorno[0].CodigoAprovacaoDARDC,
                        "CodigoAprovacaoConvenente": data.objetoRetorno[0].CodigoAprovacaoConvenente,
                        "CodigoAprovacaoDPCC": 1,//data.objetoRetorno[0].CodigoAprovacaoDPCC,
                        "NumeroParecer": data.objetoRetorno[0].NumeroParecer,
                        "DataParecer": data.objetoRetorno[0].DataParecer,
                        "ValorLiquido": data.objetoRetorno[0].ValorLiquido,
                        "ValorISS": data.objetoRetorno[0].ValorISS,
                        "ValorINSS": data.objetoRetorno[0].ValorINSS,
                        "ValorIRRF": data.objetoRetorno[0].ValorIRRF,
                        "ValorINSS20": data.objetoRetorno[0].ValorINSS20,
                        "CodigoSolicitacaoMae": data.objetoRetorno[0].CodigoSolicitacaoMae,
                        "ValorDesconto": data.objetoRetorno[0].ValorDesconto,
                        "DataPagamento": data.objetoRetorno[0].DataPagamento,
                        "Issp": data.objetoRetorno[0].Issp,
                        "Inssp": data.objetoRetorno[0].Inssp,
                        "Irrfp": data.objetoRetorno[0].Irrfp,
                        "Inss20p": data.objetoRetorno[0].Inss20p,
                        "DataISS": data.objetoRetorno[0].DataISS,
                        "DataINSS": data.objetoRetorno[0].DataINSS,
                        "DataIRRF": data.objetoRetorno[0].DataIRRF,
                        "DataINSS20": data.objetoRetorno[0].DataINSS20,
                        "NomeISS": data.objetoRetorno[0].NomeISS,
                        "NomeINSS": data.objetoRetorno[0].NomeINSS,
                        "NomeIRRF": data.objetoRetorno[0].NomeIRRF,
                        "NomeINSS20": data.objetoRetorno[0].NomeINSS20,
                        "ReciboDet": data.objetoRetorno[0].ReciboDet,
                        "ReciboDatDe": data.objetoRetorno[0].ReciboDatDe,
                        "ReciboDataTe": data.objetoRetorno[0].ReciboDataTe,
                        "ReciboHoras": data.objetoRetorno[0].ReciboHoras,
                        "ReciboBaseInss": data.objetoRetorno[0].ReciboBaseInss,
                        "ReciboBaseIrrf": data.objetoRetorno[0].ReciboBaseIrrf,
                        "NumeroContrato": data.objetoRetorno[0].NumeroContrato,
                        "Indeferida": data.objetoRetorno[0].Indeferida,
                        "Regularizada": data.objetoRetorno[0].Regularizada,
                        "Excluida": data.objetoRetorno[0].Excluida,
                        "Justificativa": data.objetoRetorno[0].Justificativa,
                        "Setor": data.objetoRetorno[0].setor,
                        "ValorOutrosImpostos": data.objetoRetorno[0].ValorOutrosImpostos,
                        "OutrosImpostos": data.objetoRetorno[0].OutrosImpostos,
                        "DataOutrosImpostos": data.objetoRetorno[0].DataOutrosImpostos,
                        "NomeOutrosImpostos": data.objetoRetorno[0].NomeOutrosImpostos,
                        "DataRPA": data.objetoRetorno[0].DataRPA,
                        "MeioTransporte": data.objetoRetorno[0].MeioTransporte,
                        "TipoLicitacao": data.objetoRetorno[0].TipoLicitacao,
                        "PerfilDestinoAtual": data.objetoRetorno[0].PerfilDestinoAtual,
                        "CodigoProcessoInterno": data.objetoRetorno[0].CodProcessoInterno,

                    });

                    var config = {
                        method: 'put',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/Solicitacao',
                        headers: {
                            'Authorization': wTokenBearer,
                            'Content-Type': 'application/json'
                        },
                        data: dataEditSolic
                    };
                    //console.log(data)
                    axios(config)

                        .then(function (response) {

                            //console.log(response.data)
                            if (response.data.isOk === true) {

                                //grava status da solicitacao no tblstatus
                                gravaRegistroStatus(response.data.objetoRetorno.CodigoSolicitacao, '');

                                setAvisoSaldo('Solicitação Atualizada com Sucesso! - Encaminhe agora para o setor responsável dar andamento.');
                                setBtAtualiza(false);
                                setAlertaSaldo(true);
                                getSolicitacao();
                                setLoading(false);


                            } else {
                                //setShowModalErro(true);

                                setLoading(false);
                                setAvisoSaldo('Algo deu errado, tente atualziar novamente.');
                                setAlertaSaldo(true);
                            }
                        })
                        .catch(error => {
                            console.error(error);

                            setLoading(false);
                            setShowModalErro(true);


                        });
                }
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
                //console.log(JSON.stringify(response.data.mensagemRetorno));
            });
    }

    // useEffect(() => {
    //     getSolicitacao();
    //     //eslint-disable-next-line
    // }, []);

    function gravaRegistroStatus(wCodigoSolicitacao, wMensagemRegularizacao) {

        //antes de gravar novo status, pega o ultimo status da solicitacao

        var wCodigoAprovador = localStorage.getItem("CodigoUsuario");
        var wCodigoPerfil = localStorage.getItem("CodigoPerfil");

        var wDataSolicitacao;
        wDataSolicitacao = new Date();
        wDataSolicitacao = moment(wDataSolicitacao).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

        //pega ultimo status
        var wUltimoStatus = status.length - 1
        var wMotivo;
        if (wMensagemRegularizacao) {
            wMotivo = wMensagemRegularizacao;
        } else {
            wMotivo = "Processo Aprovado Financeiramente por: " + localStorage.getItem("NomeUsuario");
        }
        var wCodPerfil = status[wUltimoStatus].CodigoPerfil
        var wCodPerfilDestino;
        if (wMensagemRegularizacao) {
            wCodPerfilDestino = setorRegularizacao;
        } else {
            wCodPerfilDestino = status[wUltimoStatus].PerfilDestino;
        }
        //var wCodPerfilDestino = status[wUltimoStatus].PerfilDestino

        var data = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": wCodigoSolicitacao,
            "CodigoAprovador": wCodigoAprovador,
            "DataAprovacao": wDataSolicitacao,
            "status": true,
            "Motivo": wMotivo,
            "CodigoPerfil": wCodigoPerfil,
            "Doc": "",
            "PerfilDestino": wCodPerfilDestino,
            "CodCadastro": 155,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {
                //console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleSelectTipoContratacao = (event) => {
        setTipoContratacao(event.target.value);
        //console.log(event.target.value);

        if (event.target.value.trim() !== '') {
            // Se não estiver vazio, indica que o Tipo de Contratação foi selecionado
            var config = {
                method: 'GET',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + CodigoSolicitacao + '&IsInclude=true',
                headers: {
                    'Authorization': wTokenBearer,
                    'Content-Type': 'application/json'
                },

            };
            //console.log(configSolic);
            axios(config)

                .then(function (responseSolic) {
                    const dataGet = responseSolic.data;
                    //console.log(dataGet.objetoRetorno);

                    //grava na solicitação - TipoLicitacao
                    var data = JSON.stringify({

                        "CodigoSolicitacao": CodigoSolicitacao,
                        "DataSolicitacao": dataGet.objetoRetorno[0].DataSolicitacao,
                        "NumeroSolicitacao": dataGet.objetoRetorno[0].NumeroSolic,
                        "CodigoFonte": fonte,
                        "CodigoConvenio": dataGet.objetoRetorno[0].CodigoConvenio,
                        "CodigoCredor": dataGet.objetoRetorno[0].CodigoCredor,
                        "CodigoElemento": dataGet.objetoRetorno[0].CodigoElemento,
                        "CodigoMeta": dataGet.objetoRetorno[0].CodigoMeta,
                        "CodigoFase": dataGet.objetoRetorno[0].CodigoFase,
                        "ValorTotal": dataGet.objetoRetorno[0].ValorTotal,
                        "IndFinalizada": dataGet.objetoRetorno[0].IndFinalizada,
                        "Pago": dataGet.objetoRetorno[0].Pago,
                        "Observacao": dataGet.objetoRetorno[0].Observacao,
                        "CodigoAprovacaoCampus": dataGet.objetoRetorno[0].CodigoAprovacaoCampus,
                        "CodigoAprovacaoDARDC": dataGet.objetoRetorno[0].CodigoAprovacaoDARDC,
                        "CodigoAprovacaoConvenente": dataGet.objetoRetorno[0].CodigoAprovacaoConvenente,
                        "CodigoAprovacaoDPCC": dataGet.objetoRetorno[0].CodigoAprovacaoDPCC,
                        "NumeroParecer": dataGet.objetoRetorno[0].NumeroParecer,
                        "DataParecer": dataGet.objetoRetorno[0].DataParecer,
                        "ValorLiquido": dataGet.objetoRetorno[0].ValorLiquido,
                        "ValorISS": dataGet.objetoRetorno[0].ValorISS,
                        "ValorINSS": dataGet.objetoRetorno[0].ValorINSS,
                        "ValorIRRF": dataGet.objetoRetorno[0].ValorIRRF,
                        "ValorINSS20": dataGet.objetoRetorno[0].ValorINSS20,
                        "CodigoSolicitacaoMae": dataGet.objetoRetorno[0].CodigoSolicitacaoMae,
                        "ValorDesconto": dataGet.objetoRetorno[0].ValorDesconto,
                        "DataPagamento": dataGet.objetoRetorno[0].DataPagamento,
                        "Issp": dataGet.objetoRetorno[0].Issp,
                        "Inssp": dataGet.objetoRetorno[0].Inssp,
                        "Irrfp": dataGet.objetoRetorno[0].Irrfp,
                        "Inss20p": dataGet.objetoRetorno[0].Inss20p,
                        "DataISS": dataGet.objetoRetorno[0].DataISS,
                        "DataINSS": dataGet.objetoRetorno[0].DataINSS,
                        "DataIRRF": dataGet.objetoRetorno[0].DataIRRF,
                        "DataINSS20": dataGet.objetoRetorno[0].DataINSS20,
                        "NomeISS": dataGet.objetoRetorno[0].NomeISS,
                        "NomeINSS": dataGet.objetoRetorno[0].NomeINSS,
                        "NomeIRRF": dataGet.objetoRetorno[0].NomeIRRF,
                        "NomeINSS20": dataGet.objetoRetorno[0].NomeINSS20,
                        "ReciboDet": dataGet.objetoRetorno[0].ReciboDet,
                        "ReciboDatDe": dataGet.objetoRetorno[0].ReciboDatDe,
                        "ReciboDataTe": dataGet.objetoRetorno[0].ReciboDataTe,
                        "ReciboHoras": dataGet.objetoRetorno[0].ReciboHoras,
                        "ReciboBaseInss": dataGet.objetoRetorno[0].ReciboBaseInss,
                        "ReciboBaseIrrf": dataGet.objetoRetorno[0].ReciboBaseIrrf,
                        "NumeroContrato": dataGet.objetoRetorno[0].NumeroContrato,
                        "Indeferida": dataGet.objetoRetorno[0].Indeferida,
                        "Regularizada": dataGet.objetoRetorno[0].Regularizada,
                        "Excluida": dataGet.objetoRetorno[0].Excluida,
                        "Justificativa": dataGet.objetoRetorno[0].Justificativa,
                        "Setor": dataGet.objetoRetorno[0].setor,
                        "ValorOutrosImpostos": dataGet.objetoRetorno[0].ValorOutrosImpostos,
                        "OutrosImpostos": dataGet.objetoRetorno[0].OutrosImpostos,
                        "DataOutrosImpostos": dataGet.objetoRetorno[0].DataOutrosImpostos,
                        "NomeOutrosImpostos": dataGet.objetoRetorno[0].NomeOutrosImpostos,
                        "DataRPA": dataGet.objetoRetorno[0].DataRPA,
                        "MeioTransporte": dataGet.objetoRetorno[0].MeioTransporte,
                        "TipoLicitacao": event.target.value,
                        "PerfilDestinoAtual": dataGet.objetoRetorno[0].PerfilDestinoAtual,
                        "CodigoProcessoInterno": dataGet.objetoRetorno[0].CodProcessoInterno,

                    });

                    var config = {
                        method: 'put',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/Solicitacao',
                        headers: {
                            'Authorization': wTokenBearer,
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    //console.log(data)
                    axios(config)

                        .then(function (response) {

                            //console.log(response.data)
                            if (response.data.isOk === true) {

                                setPrecisaTipoContratacao(false);
                                setLoading(false);

                            } else {
                                //setShowModalErro(true);
                                setLoading(false);
                            }
                        })
                        .catch(error => {
                            console.error(error);
                            setLoading(false);
                            setShowModalErro(true);
                        });
                })
        }
    };

    function AlimentaPT() {

        //console.log(dados);
        //const wtoken = "Bearer " + wTokenLocal;

        const config = {
            method: "GET",
            url:
                urlAPIlocal + "/api/ApiPlanoTrabalho/" + codConvenio,
            headers: {
                Authorization: wTokenBearer,
                "Content-Type": "application/json",
            },
        };
        //console.log(config);
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    var dados = response.data.objetoRetorno;
                    //console.log(dados);
                    setPt(dados);
                    //setPtConsolidadoDespesas(dados.ConsolidadoDespesas);
                    //setPtConsolidadoElementos(dados.ConsolidadoDespesas.Elementos);
                    //setPtMetas(dados.Metas);
                    //setLoading(false);
                    //setPtMetasElementos(dados.Metas[0].Elementos);
                    //setPtMetasElementosItens(dados.Metas[0].Elementos.Itens);
                    //setPtMetasFases(dados.Metas[0].MetaFases);
                    //console.log(dados.Metas);
                    //setPtMetasFasesPrevistos(dados.Metas[0].MetaFases[0].MetaFasePrevistos);
                    //console.log(dados.Metas[0].MetaFases[0].MetaFasePrevistos);
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //Retorno = true
                }

            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
        //return Retorno;
    }
    useEffect(() => {
        AlimentaPT();
        // eslint-disable-next-line
    }, []);


    const handleSubmitInfo = async (event) => {
        event.preventDefault();
        setEnviaTexto(true);
        setTextoEnviado(false);
        setEnviaArquivo(false);
        setEncaminharSolicitacao(false);
        setArquivoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
        setBotaoDesabilitado(false);

    }

    const handleSubmitArquivo = async (event) => {
        event.preventDefault();
        setEnviaArquivo(true);
        setArquivoEnviado(false);
        setEnviaTexto(false);
        setEncaminharSolicitacao(false);
        setTextoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
        setBotaoDesabilitado(false);

    }

    const handleSubmitEncaminhar = async (event) => {
        event.preventDefault();
        setEncaminharSolicitacao(true);
        setEnviaArquivo(false);
        setEnviaTexto(false);
        setTextoEnviado(false);
        setArquivoEnviado(false);
        setEncaminhado(false);
        setSolicitarExclusao(false);
        setBotaoDesabilitado(false);

    }

    const handleExcluirArquivo = async (event) => {
        event.preventDefault();
        setSolicitarExclusao(true);
        setBotaoDesabilitado(false);
        setEncaminhadoExclusao(false);
        setEnviaArquivo(false);
        setEnviaTexto(false);
        setTextoEnviado(false);
        setArquivoEnviado(false);
        setEncaminharSolicitacao(false);
        setEncaminhado(false);
    }

    const handlePrintClick = () => {
        window.open(`/PrintSolic/${CodigoSolicitacao}`, '_blank');
    };

    const DuploCheck = async (event) => {
        event.preventDefault();
        setLoading(true);

        var CodigoUsuario = localStorage.getItem('CodigoUsuario');
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + `/api/Usuarios?CodigoUsuario=${CodigoUsuario}&IsInclude=false`,
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data.objetoRetorno;

                if (senha === data[0].SenhaAssinatura) {
                    setSenhaVerificada(true);
                    setModalDuploCheck(false);
                    setAvisoDuploCheck(false);
                    setAvisoSenhaOk(true);
                    setSenha('');
                    if (enviaTexto) {
                        GravaTextoComSenha();
                    } else if (enviaArquivo) {
                        GravaNovoArquivoComSenha();
                    } else if (encaminharSolicitacao) {
                        GravaEncaminhamentoComSenha();
                    } else if (solicitarExclusao) {
                        handleSubmitEncaminharExclusaoComSenha();
                    } else if (formNaoAutorizarExclusao) {
                        handleNaoAutorizarExclusaoComSenha();
                    } else {
                        ConfirmarExclusaoComSenha();
                    }

                } else {
                    setModalDuploCheck(true);
                    setAvisoDuploCheck(true);
                    setAvisoSenhaOk(false);
                }

                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);

            });
    };

    const closeAndResetModalDuploCheck = () => {
        setModalDuploCheck(false);
        setAvisoDuploCheck(false);
        setAvisoSenhaOk(false);
        setSenha('');
        setSenhaVerificada(false);
    };

    const handleSubmitTexto = async (event) => {
        event.preventDefault();

        setBotaoDesabilitado(true);

        if (mensagemRemetente !== '') {
            setLoading(false);
            setTextoRequired(false);

            //verifica senha antes de prosseguir
            if (avisoSenhaOk === true) {
                GravaTextoComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setLoading(false);
            setTextoRequired(true);
        }
    }

    function GravaTextoComSenha() {
        setLoading(true);

        var wNomeUsuario = localStorage.getItem("NomeUsuario");

        var wMensagemNova = (TextoSolicitacao ? TextoSolicitacao : '') + '<br><strong>Informação inserida por: </strong>' + wNomeUsuario + '<br>' + mensagemRemetente;

        var wMensagemNovaStatus = '<strong>Informação inserida por: </strong>' + wNomeUsuario + '<br>' + mensagemRemetente;

        var datHoje = new Date();

        var dataStatus = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": CodigoSolicitacao,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": wMensagemNovaStatus,
            "CodigoPerfil": wPerfil,
            "Doc": "",
            "PerfilDestino": wPerfil,
            "CodCadastro": localStorage.getItem("CodigoCadastro")
        });
        var configStatus = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': 'Bearer ' + wTokenBearer,
                'Content-Type': 'application/json',
            },
            data: dataStatus
        };
        //console.log(configProc);
        axios(configStatus)
            .then(function (responseStatus) {
                if (responseStatus.data.isOk === true) {
                    //console.log(wMensagemNova);
                    var dataSolic = JSON.stringify({

                        "Tramitacao": wMensagemNova,
                    });
                    var config = {
                        method: 'put',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/Solicitacao/GravaTramitacao/' + CodigoSolicitacao,
                        headers: {
                            'Authorization': wTokenBearer,
                            'Content-Type': 'application/json'
                        },
                        data: dataSolic
                    };
                    //console.log(config);
                    axios(config)
                        .then(function (response) {
                            if (response.data.isOk === true) {
                                // fez ao consumo sem erro
                                setLoading(false);
                                setTextoSolicitacao(response.data.objetoRetorno.Tramitacao);
                                setEnviaTexto(false);
                                GravaPerfilAtual(CodigoSolicitacao, setor);
                                setMensagemRemetente('');
                                setLoading(false);
                                getSolicitacao();
                                setTextoSolicitacao(solicitacao.Tramitacao);
                                setTextoEnviado(true);
                                setSenha('');
                                setAvisoSenhaOk(false);
                            } else {
                                //deu algum erro tratar , colocar algum aviso
                                setLoading(false);
                            }
                        })
                        .catch(error => {
                            //setGravando(false);
                            console.error(error);
                            setLoading(false);
                            //setShowModalErro(true);
                        });
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }

    const handleSubmitEnviaArquivo = async (event) => {
        event.preventDefault();
        setLoading(true);
        setBotaoDesabilitado(true);

        if (NovoFile !== null) {
            setLoading(false);
            setSelecioneArquivo(false);

            if (avisoSenhaOk === true) {

                GravaNovoArquivoComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setSelecioneArquivo(true);
        }
    }
    function gerarCodigoUnico() {

        let codigo = '';
        for (let i = 0; i < 4; i++) {
            codigo += Math.floor(Math.random() * 10); // Adiciona um número aleatório de 0 a 9 ao código
        }
        return codigo;
    }

    function GravaNovoArquivoComSenha() {
        setLoading(true);

        //trata nome arquivo, tira acentos, espaços
        let wNomeArquivoLimpo = NovoFile.name
        let wCodigoUnico = gerarCodigoUnico();
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
        wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
        wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        wNomeArquivoLimpo = CodigoSolicitacao + "_" + wCodigoUnico + "_" + wNomeArquivoLimpo

        var datFiles = JSON.stringify({
            "CodigoArquivo": 0,
            "CodigoSolicitacao": CodigoSolicitacao,
            "descricao": descricaoarquivo,
            "arquivo": wNomeArquivoLimpo, //CodCadastro + "_" + ArqCadastro.name,
            "status": 0
        })
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ArquivoProcesso',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },
            data: datFiles
        };
        //console.log(datFiles)
        axios(config)
            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    //faz upload do arquivo
                    const file = NovoFile;
                    const novoNome = wNomeArquivoLimpo;
                    const novoArquivo = new File([file], novoNome, { type: file.type });
                    const formData = new FormData();
                    formData.append('arquivo', novoArquivo);

                    var config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: urlAPIlocal + '/api/UploadArquivo',
                        headers: {
                            'Authorization': wTokenBearer,
                            'Content-Type': 'multipart/form-dataa',
                            'pasta': 'ArquivosProcesso'
                        },
                        data: formData
                    };

                    axios(config)

                        .then(response => {
                            //console.log(response.data);

                            //Grava registro na tblStatus
                            var datHoje = new Date();
                            var dataStatus = JSON.stringify({
                                "CodigoStatus": 0,
                                "CodigoSolicitacao": CodigoSolicitacao,
                                "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
                                "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                                "status": false,
                                "Motivo": '<strong>Arquivo Inserido Por:</strong><br> ' + localStorage.getItem("NomeUsuario") + '<br>Arquivo: ' + wNomeArquivoLimpo + '<br>' + descricaoarquivo,
                                "CodigoPerfil": wPerfil,
                                "Doc": "",
                                "PerfilDestino": wPerfil,
                                "CodCadastro": localStorage.getItem("CodigoCadastro")
                            });
                            var configStatus = {
                                method: 'post',
                                maxBodyLength: Infinity,
                                url: urlAPIlocal + '/api/Status',
                                headers: {
                                    'Authorization': wTokenBearer,
                                    'Content-Type': 'application/json',
                                },
                                data: dataStatus
                            };
                            //console.log(config);
                            axios(configStatus)
                                .then(function (responseStatus) {
                                    if (responseStatus.data.isOk === true) {

                                        setEnviaArquivo(false);
                                        getSolicitacao();
                                        setNovoFile(null);
                                        setDescricaoArquivo('');
                                        fileInputRef.current.value = '';
                                        setLoading(false);
                                        setArquivoEnviado(true);
                                        setAvisoSenhaOk(false);
                                        setSenha('');
                                        getArquivo();
                                    }
                                })

                                .catch(error => {
                                    console.error(error);
                                    setLoading(false);
                                    setShowModalErro(true);
                                });




                        })
                        .catch(error => {
                            console.error(error);
                            setLoading(false);
                            //Retorno = true;

                        });
                } else {
                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                    //Retorno = true

                }

            })
            .catch(function (error) {
                console.log(error);
                //Retorno = true
            });
    }
    const handleSelectSetor = (event) => {
        setSetor(event.target.value);

        const selectedOption = event.target.options[event.target.selectedIndex];
        const selectedOptionText = selectedOption.text;
        setNomeSetor(selectedOptionText);
    }

    const handleSubmitEncaminhamento = async (event) => {
        event.preventDefault();
        setLoading(true);
        setBotaoDesabilitado(true);

        //se nao selecionou setor nao deixa encaminhar
        //console.log(setor);

        if (setor !== 0) {
            setLoading(false);
            setSelecioneSetor(false);

            if (avisoSenhaOk === true) {
                GravaEncaminhamentoComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setSelecioneSetor(true);
        }
    };

    function GravaEncaminhamentoComSenha() {
        setLoading(true);

        var datHoje = new Date()
        var data = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": CodigoSolicitacao,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": 'Solicitação encaminhada de: <strong>' + wNomePerfil + '</strong> para: <strong>' + nomeSetor + '</strong><br>Mensagem: ' + mensagemEncaminhamento,
            "CodigoPerfil": wPerfil,
            "Doc": "",
            "PerfilDestino": setor,
            "CodCadastro": 155,
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json',
            },
            data: data
        };
        //console.log(config);
        axios(config)
            .then(response => {
                //console.log(response);
                //grava novo detalhes no processo
                //GravaDetalhesProcesso();
                getSolicitacao();
                GravaPerfilAtual(CodigoSolicitacao, setor);
                setEncaminhado(true);
                setSetor(0);
                setMensagemEncaminhamento('');
                setSenha('');
                setAvisoSenhaOk(false);
                setSelecioneSetor(false);
                setLoading(false);
                setEncaminharSolicitacao(false);

            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }

    const handleRadioChange = (event) => {
        setArquivoSelecionado(event.target.value);
        //console.log(event.target.value);

        const codigoArquivo = parseInt(event.target.value, 10);
        const arquivoSelecionadoInfo = arquivo.find((arq) => arq.CodigoArquivo === codigoArquivo);
        //console.log(arquivoSelecionadoInfo);

        if (arquivoSelecionadoInfo) {
            setNomeArquivoSelecionado(arquivoSelecionadoInfo.Arquivo);
        }
    };

    const handleSubmitEncaminharExclusao = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (motivoExclusao !== '') {
            setLoading(false);
            setMotivoRequired(false);

            if (avisoSenhaOk === true) {

                handleSubmitEncaminharExclusaoComSenha();

            } else {
                setLoading(false);
                setModalDuploCheck(true);
            }
        } else {
            setMotivoRequired(false);
        }
    };

    function handleSubmitEncaminharExclusaoComSenha() {
        setLoading(true);
        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var datHoje = new Date()
        var data = JSON.stringify({
            "CodigoStatus": 0,
            "CodigoSolicitacao": CodigoSolicitacao,
            "CodigoAprovador": localStorage.getItem("CodigoUsuario"),
            "DataAprovacao": moment(datHoje).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            "status": false,
            "Motivo": 'Solicitação de exclusão do arquivo: <strong>' + nomeArquivoSelecionado + '<br></strong>Motivo: <strong>' + motivoExclusao,
            "CodigoPerfil": wPerfil,
            "Doc": "",
            "PerfilDestino": '33',
            "CodCadastro": localStorage.getItem("CodigoCadastro")
        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Status',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json',
            },
            data: data
        };
        //console.log(config);
        axios(config)
            .then(response => {
                //console.log(response);
                //grava novo detalhes no processo
                //GravaDetalhesProcesso();
                getSolicitacao();
                GravaPerfilAtual(CodigoSolicitacao, '33');
                setEncaminhadoExclusao(true);
                setArquivoSelecionado(null);
                setMotivoExclusao('');
                setSenha('');
                setAvisoSenhaOk(false);
                setSolicitarExclusao(false);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }

    //Volta Solicitação para Regularização
    function VoltaSolicitacao() {

        setLoading(true);

        var dataVoltaSolic = JSON.stringify({

            "CodigoSolicitacao": CodigoSolicitacao,
            "DataSolicitacao": solicitacao.DataSolicitacao,
            "NumeroSolicitacao": solicitacao.NumeroSolic,
            "CodigoFonte": fonte,
            "CodigoConvenio": solicitacao.CodigoConvenio,
            "CodigoCredor": solicitacao.CodigoCredor,
            "CodigoElemento": solicitacao.CodigoElemento,
            "CodigoMeta": 0,
            "CodigoFase": 0,
            "ValorTotal": solicitacao.ValorTotal,
            "IndFinalizada": solicitacao.IndFinalizada,
            "Pago": solicitacao.Pago,
            "Observacao": solicitacao.Observacao,
            "CodigoAprovacaoCampus": solicitacao.CodigoAprovacaoCampus,
            "CodigoAprovacaoDARDC": solicitacao.CodigoAprovacaoDARDC,
            "CodigoAprovacaoConvenente": solicitacao.CodigoAprovacaoConvenente,
            "CodigoAprovacaoDPCC": 0,//solicitacao.CodigoAprovacaoDPCC,
            "NumeroParecer": solicitacao.NumeroParecer,
            "DataParecer": solicitacao.DataParecer,
            "ValorLiquido": solicitacao.ValorLiquido,
            "ValorISS": solicitacao.ValorISS,
            "ValorINSS": solicitacao.ValorINSS,
            "ValorIRRF": solicitacao.ValorIRRF,
            "ValorINSS20": solicitacao.ValorINSS20,
            "CodigoSolicitacaoMae": solicitacao.CodigoSolicitacaoMae,
            "ValorDesconto": solicitacao.ValorDesconto,
            "DataPagamento": solicitacao.DataPagamento,
            "Issp": solicitacao.Issp,
            "Inssp": solicitacao.Inssp,
            "Irrfp": solicitacao.Irrfp,
            "Inss20p": solicitacao.Inss20p,
            "DataISS": solicitacao.DataISS,
            "DataINSS": solicitacao.DataINSS,
            "DataIRRF": solicitacao.DataIRRF,
            "DataINSS20": solicitacao.DataINSS20,
            "NomeISS": solicitacao.NomeISS,
            "NomeINSS": solicitacao.NomeINSS,
            "NomeIRRF": solicitacao.NomeIRRF,
            "NomeINSS20": solicitacao.NomeINSS20,
            "ReciboDet": solicitacao.ReciboDet,
            "ReciboDatDe": solicitacao.ReciboDatDe,
            "ReciboDataTe": solicitacao.ReciboDataTe,
            "ReciboHoras": solicitacao.ReciboHoras,
            "ReciboBaseInss": solicitacao.ReciboBaseInss,
            "ReciboBaseIrrf": solicitacao.ReciboBaseIrrf,
            "NumeroContrato": solicitacao.NumeroContrato,
            "Indeferida": solicitacao.Indeferida,
            "Regularizada": solicitacao.Regularizada,
            "Excluida": solicitacao.Excluida,
            "Justificativa": solicitacao.Justificativa,
            "Setor": solicitacao.setor,
            "ValorOutrosImpostos": solicitacao.ValorOutrosImpostos,
            "OutrosImpostos": solicitacao.OutrosImpostos,
            "DataOutrosImpostos": solicitacao.DataOutrosImpostos,
            "NomeOutrosImpostos": solicitacao.NomeOutrosImpostos,
            "DataRPA": solicitacao.DataRPA,
            "MeioTransporte": solicitacao.MeioTransporte,
            "TipoLicitacao": solicitacao.TipoLicitacao,
            "PerfilDestinoAtual": setorRegularizacao,
            "CodigoProcessoInterno": solicitacao.CodProcessoInterno,
        });

        var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Solicitacao',
            headers: {
                'Authorization': wTokenBearer,
                'Content-Type': 'application/json'
            },
            data: dataVoltaSolic
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {

                    //grava status da solicitacao no tblstatus
                    gravaRegistroStatus(response.data.objetoRetorno.CodigoSolicitacao, 'Solicitação encaminhada para regularização');
                    setLoading(false);
                    setRedir(true);

                } else {
                    //setShowModalErro(true);

                    setLoading(false);
                }
            })
            .catch(error => {
                console.error(error);

                setLoading(false);
                setShowModalErro(true);
            });
    }

    const encontrarArquivo = (motivo) => {
        // Certifica-se de que arquivo existe e é uma array
        if (arquivo && Array.isArray(arquivo)) {
            const nomeArquivo = motivo;
            return arquivo.find(item => item.Arquivo === nomeArquivo);
        }
        return null; // Retorna null se arquivo não existe ou não é uma array
    };




    return (
        <>
            {wPerfil !== '37' ? <Menu /> : <MenuArea />}

            <div align='left' style={{ backgroundColor: '#f3f3f4ab' }}> <button className={Style.btn_voltar} onClick={handleClick}><IoMdArrowBack /> Voltar </button> </div>

            <div className={Style.page}>

                <div className={Style.box_container}>
                    <Box className={Style.box_solic}>
                        <h2>Solicitação n° {CodigoSolicitacao} / {anoSolic}</h2>

                        <div style={{ textAlign: 'right' }}>
                            {/* <p><strong>Meta</strong> {fase.Meta && fase.Meta.NumMeta} - <strong>Fase</strong> {fase.NumeroFase}</p> */}

                            {/* {console.log(fase[0].Meta)} */}
                            {fase && fase.length > 0 ? (
                                <p>
                                    <strong>Meta</strong> {fase[0].Meta.NumMeta} - <strong>Fase</strong> {fase[0].NumeroFase}
                                </p>
                            ) : (
                                <p>
                                    <strong>Meta</strong> não selecionada - <strong>Fase</strong> não selecionada
                                </p>
                            )}
                            <p><strong>Elemento de Despesa:</strong> {solicitacao.ConvenioElemento && solicitacao.ConvenioElemento.Elemento.Nome} </p>

                            <p><strong>Fonte de Recurso:</strong> {solicitacao.CodigoFonte === 0 ? "Não definida"
                                : solicitacao.CodigoFonte === 1
                                    ? "1 - Concedente"
                                    : solicitacao.CodigoFonte === 2
                                        ? "2 - Convenente"
                                        : solicitacao.CodigoFonte === 3
                                            ? "3 - Executor"
                                            : solicitacao.CodigoFonte === 4
                                                ? "4 - Interveniente"
                                                : "5 - Rendimentos"}
                            </p>
                        </div>

                    </Box>
                </div>

                {wPerfil !== "37" &&
                    <div className={Style.box_container} style={{ marginBottom: '1em' }}>
                        {/* <Form className={Style.form}> */}

                        <Box className={Style.box_gerenciar}>
                            <FormGroup>
                                <div><p></p></div>
                                <div>
                                    <h4 align="center">GERENCIAR SOLICITAÇÃO</h4>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '2em' }}>

                                        <button
                                            className={botaoAtivo === 'info' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                            onClick={(e) => { handleSubmitInfo(e); setBotaoAtivo('info'); }}
                                        >
                                            Inserir Novas Informações (texto)
                                        </button>
                                        <button
                                            className={botaoAtivo === 'arquivo' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                            onClick={(e) => { handleSubmitArquivo(e); setBotaoAtivo('arquivo'); }}
                                        >
                                            Inserir Arquivo(s)
                                        </button>
                                        <button
                                            className={botaoAtivo === 'encaminhar' ? `${Style.btn_send} ${Style.btn_ativo}` : Style.btn_send}
                                            onClick={(e) => { handleSubmitEncaminhar(e); setBotaoAtivo('encaminhar'); }}
                                        >
                                            Encaminhar Solicitação<IoIosSend />
                                        </button>
                                        <button
                                            className={botaoAtivo === 'excluir' ? `${Style.btn_excluir} ${Style.btn_ativoExcluir}` : Style.btn_excluir}
                                            onClick={(e) => { handleExcluirArquivo(e); setBotaoAtivo('excluir'); }}
                                        >
                                            Solicitar Exclusão de Arquivo
                                        </button>
                                        <button className={Style.btn_print} onClick={handlePrintClick}><IoMdPrint /></button>
                                    </div>
                                </div>
                                <p><br></br></p>
                                {enviaTexto && (
                                    <div>
                                        <p></p>
                                        <p></p>
                                        <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Novas Informações: </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="mensagemRementente"
                                            value={mensagemRemetente}
                                            onChange={(event) => setMensagemRemetente(event.target.value)}
                                            required
                                            style={{ height: '100px', color: '#707070' }} />
                                        <p></p>
                                        {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                        <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitTexto} disabled={botaoDesabilitado}>Atualizar Solicitação <IoIosSend /></button></p>
                                    </div>
                                )}
                                {enviaArquivo && (
                                    <div>
                                        <p></p>
                                        <p></p>
                                        <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Descrição Arquivo: </Form.Label>
                                        <Form.Control name="descricaoarquivo" value={descricaoarquivo} onChange={(event) => setDescricaoArquivo(event.target.value)} style={{ color: '#707070' }} />

                                        <Form.Label style={{ color: '#fff' }}>.</Form.Label>
                                        <Form.Control name="NovoFile" type="file" accept="application/pdf" onChange={handleNovoFile} ref={fileInputRef} />
                                        <p></p>
                                        {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                        <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitEnviaArquivo} disabled={botaoDesabilitado}>Enviar Arquivo<IoIosSend /></button></p>
                                        {selecioneArquivo && (
                                            <p align="center">
                                                <AiFillCheckCircle /><strong>escolha um arquivo!</strong>
                                            </p>
                                        )}
                                    </div>
                                )}
                                {encaminharSolicitacao && (
                                    <div>
                                        <p></p>
                                        <p></p>
                                        <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Para:</Form.Label>
                                        <Form.Select name="setor" id="setor" value={setor} onChange={handleSelectSetor} style={{ width: '300px', color: '#707070' }} required>
                                            <option value="0">Selecione um Setor</option>
                                            <option value="26">Recepção</option>
                                            <option value="17">Compras</option>
                                            <option value="23">Prestação de Contas</option>
                                            <option value="31">Assessoria Jurídica</option>
                                            <option value="25">Assessoria Administrativa</option>
                                            <option value="24">Diretoria Administrativa</option>
                                            <option value="28">Diretor Presidente</option>
                                            <option value="32">Recursos Humanos</option>
                                            <option value="33">Secretário Executivo</option>
                                            <option value="34">Consultor</option>
                                            <option value="35">TI</option>
                                            <option value="36">Gestao de Projetos</option>
                                            <option value="37">Equipe Área</option>
                                            <option value="38">Financeiro</option>
                                        </Form.Select>
                                        {selecioneSetor && (
                                            <p align="center">
                                                <AiFillCheckCircle /><strong>Selecione um Setor para encaminhar!</strong>
                                            </p>
                                        )}
                                        <p><br></br></p>
                                        <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Mensagem de Encaminhamento: </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="mensagemEncaminhamento"
                                            value={mensagemEncaminhamento}
                                            onChange={(event) => setMensagemEncaminhamento(event.target.value)}
                                            style={{ height: '100px', color: '#707070' }} />
                                        <p></p>
                                        {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída</p><p></p></>) : (<p></p>)}
                                        <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitEncaminhamento} disabled={botaoDesabilitado}>Encaminhar Solicitação <IoIosSend /></button></p>
                                    </div>
                                )}
                                {solicitarExclusao && (
                                    <div>
                                        <p></p>
                                        <p></p>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '55vw', flexWrap: 'wrap', margin: '0 auto' }}>
                                            {arquivo &&
                                                arquivo.length > 0 &&
                                                arquivo
                                                    .filter((ArquivoProcesso) => ArquivoProcesso.Status === 0 && !ArquivoProcesso.Arquivo.startsWith('SolicitacaoOrcamento_'))
                                                    .map((ArquivoProcesso) => (
                                                        <div
                                                            key={ArquivoProcesso.CodigoArquivo}
                                                            style={{
                                                                border: '1px solid #ccc',
                                                                borderRadius: '8px',
                                                                padding: '10px',
                                                                textAlign: 'left',
                                                                width: 'fit-content',
                                                                margin: '10px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="arquivoRadio"
                                                                    id={ArquivoProcesso.CodigoArquivo}
                                                                    value={ArquivoProcesso.CodigoArquivo}
                                                                    onChange={handleRadioChange}
                                                                />
                                                                <label htmlFor={ArquivoProcesso.CodigoArquivo} style={{ margin: '10px' }}>
                                                                    {ArquivoProcesso.Arquivo}
                                                                </label>
                                                            </div>

                                                            <embed
                                                                title={ArquivoProcesso.Arquivo}
                                                                style={{ width: '15vw', height: '50vh', margin: '0 auto' }}
                                                                src={LinkArquivo + ArquivoProcesso.Arquivo}
                                                            />
                                                        </div>
                                                    ))}
                                        </div>

                                        <Form.Label>Motivo da exclusão: </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="motivoExclusao"
                                            value={motivoExclusao}
                                            onChange={(event) => setMotivoExclusao(event.target.value)}
                                            style={{ height: '100px', color: '#707070' }}
                                            required />
                                        <p></p>
                                        {avisoSenhaOk ? (<><p style={{ backgroundColor: '#EAFAF1', color: '#229954', fontWeight: '600', fontSize: '14px', padding: '.3em', textAlign: 'center', margin: '0 auto', borderRadius: '8px', width: '100%' }}>Verificação Concluída, clique no botão abaixo para concluir a ação.</p><p></p></>) : (<p></p>)}
                                        <p align='center'><button className={Style.btn_atualizar} onClick={handleSubmitEncaminharExclusao}>Solicitar Exclusão do Arquivo</button></p>
                                    </div>
                                )}

                                <div>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                    {textoEnviado && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Solicitação Atualizada com Sucesso!</strong>
                                        </p>
                                    )}
                                    {arquivoEnviado && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Arquivo(s) Enviado(s) com Sucesso!</strong>
                                        </p>
                                    )}
                                    {encaminhado && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Solicitação Encaminhada com Sucesso!</strong>
                                        </p>
                                    )}
                                    {encaminhadoExclusao && (
                                        <p align="center">
                                            <AiFillCheckCircle /><strong>Solicitação de Exclusão de Arquivo Feita Com Sucesso!</strong>
                                        </p>
                                    )}
                                </div>
                            </FormGroup>

                        </Box>
                    </div>

                }

                {wPerfil === '38' && solicitacao && solicitacao.Convenio && solicitacao.Pago === false && (
                    <div className={Style.box_container}>
                        <Box className={Style.box_saldo}>

                            {solicitacao.CodigoAprovacaoDPCC === 0 ? (
                                <>
                                    <button className={Style.pt_btn}><Link to={`/PtFinanceiro/${solicitacao.Convenio.CodigoConvenio}`} target="_blank">Ver Plano de Trabalho <BsArrowRight /></Link></button>
                                    <div style={{ display: "flex", flexDirection: "row", margin: "0 auto" }}>
                                        <Form>
                                            <FormGroup >
                                                {precisaTipoContratacao === true ?
                                                    (<>
                                                        <div style={{ display: 'flex', justifyContent: 'center', width: 'fit-content', margin: '0 auto', borderRadius: '8px', backgroundColor: '#FDEDEC', color: '#E74C3C', fontSize: '14px', fontWeight: '600', padding: '.3em', marginBottom: '1em', marginTop: '1em' }}>
                                                            <p style={{ margin: '.1em' }}><BiSolidError style={{ fontSize: '16px', marginBottom: '.2em' }} /> A Solicitação está sem Tipo de Contratação cadastrado, selecione um para prosseguir.</p>
                                                        </div>
                                                        <Row style={{ marginBottom: '.1em' }}>
                                                            <Col></Col>
                                                            <Col md={4}>
                                                                <Form.Label className={Style.form_group_label}>Tipo de Contratação</Form.Label>
                                                                <Form.Select className={Style.form_control} name="tipoContratacao" value={tipoContratacao} onChange={handleSelectTipoContratacao} required >
                                                                    <option value="">Selecione o Tipo de Contratação</option>
                                                                    <option value="Dispensa">Dispensa</option>
                                                                    <option value="Convite">Convite</option>
                                                                    <option value="Tomada de Preços">Tomada de Preços</option>
                                                                    <option value="Concorrência">Concorrência</option>
                                                                    <option value="Pregão Eletrônico">Pregão Eletrônico</option>
                                                                    <option value="Pregão Presencial">Pregão Presencial</option>
                                                                    <option value="Inexigível">Inexigível</option>
                                                                </Form.Select>
                                                            </Col>
                                                            <Col></Col>
                                                        </Row>
                                                    </>) : (null)}
                                                <Row>
                                                    <Col>
                                                        <Form.Label className={Style.form_group_label}>Fonte do Recurso</Form.Label>
                                                        <Form.Select className={Style.form_control} name="fonte" id="fonte" value={fonte} onChange={handleSelectFonte} >
                                                            <option value="0">Selecione a Fonte</option>
                                                            <option value="1">1 - Concedente</option>
                                                            <option value="2">2 - Convenente</option>
                                                            <option value="3">3 - Executor</option>
                                                            <option value="4">4 - Interveniente</option>
                                                            <option value="5">5 - Rendimentos</option>
                                                        </Form.Select>
                                                    </Col>

                                                    <Col>
                                                        <Form.Label className={Style.form_group_label}>Meta</Form.Label>
                                                        <Form.Select className={Style.form_control} name="Meta" id="Meta" value={metaEscolhida} onChange={handleSelectMeta} >
                                                            <option value="">Selecione a Meta</option>
                                                            {metas.length > 0 &&
                                                                metas.map((opcao) => (
                                                                    <option key={opcao.CodigoMeta} value={opcao.CodigoMeta}>{opcao.NumMeta} - {opcao.Nome}</option>
                                                                ))}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col>
                                                        <Form.Label className={Style.form_group_label}>Fase</Form.Label>
                                                        <Form.Select className={Style.form_control} name="Fase" id="Fase" value={faseEscolhida} onChange={handleSelectFase}
                                                            disabled={!metaEscolhida} // Desabilita o select se metaEscolhida for null
                                                        >
                                                            <option value="">Selecione a Fase</option>
                                                            {fases.length > 0 &&
                                                                fases.map((opcao) => (
                                                                    <option key={opcao.CodigoConvenioFase} value={opcao.CodigoConvenioFase}>{opcao.NumeroFase} - {opcao.Nome}</option>
                                                                ))}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col>

                                                        <br></br>
                                                        <button className={Style.saldo_btn} type="button" onClick={(event) => ConsultaSaldo()}>Consultar Saldo</button>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {/* {console.log(alertaSaldo)}
                                                {console.log(avisoSaldo)} */}
                                                        {showAviso && (
                                                            <div className={Style.avisoSaldo}>
                                                                <p>Selecione Fonte, Meta e Fase</p>
                                                            </div>
                                                        )}
                                                        {alertaSaldo && (
                                                            <div className={Style.avisoSaldo}>
                                                                <p>{avisoSaldo}</p>
                                                            </div>
                                                        )}
                                                        {btAtualiza && (
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '0 auto' }}>
                                                                <p></p>
                                                                <p align="center"><button className={Style.VerAquivo_btn} type="button" onClick={(event) => AtualizaSolic()}>Atualizar Solicitação</button></p>
                                                            </div>
                                                        )}

                                                    </Col>
                                                </Row>

                                            </FormGroup>
                                        </Form>
                                    </div>
                                </>) : (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                                    <p style={{ fontSize: '16px', color: '#707070', fontWeight: '600', textAlign: 'center', backgroundColor: '#F8F9F9', marginBottom: '1em' }}>Caso necessário, encaminhe a solicitação a um setor para regularização</p>

                                    <Form.Select name="setorRegularizacao" id="setorRegularizacao" value={setorRegularizacao} onChange={(event) => setSetorRegularizacao(event.target.value)} style={{ width: '300px', color: '#707070', alignSelf: 'center' }} required>
                                        <option value="">Selecione um Setor</option>
                                        <option value="26">Recepção</option>
                                        <option value="17">Compras</option>
                                        <option value="23">Prestação de Contas</option>
                                        <option value="38">Financeiro</option>
                                        <option value="31">Assessoria Jurídica</option>
                                        <option value="25">Assessoria Administrativa</option>
                                        <option value="24">Diretoria Administrativa</option>
                                        <option value="28">Diretor Presidente</option>
                                        <option value="32">Recursos Humanos</option>
                                        <option value="33">Secretário Executivo</option>
                                        <option value="34">Consultor</option>
                                        <option value="35">TI</option>
                                        <option value="36">Gestao de Projetos</option>
                                    </Form.Select>
                                    {/* <button type="button" onClick={(event) => VoltaSolicitacao(setorRegularizacao)} className={Style.btn_regularizacao}>Encaminhar Solicitacao Para Regularização</button> */}
                                    <button
                                        type="button"
                                        onClick={(event) => {
                                            if (setorRegularizacao) {
                                                VoltaSolicitacao(setorRegularizacao);
                                            } else {
                                                // Aqui você pode adicionar lógica para tratar o caso em que nenhum setor foi selecionado
                                                alert('Por favor, selecione um setor antes de encaminhar a solicitação para regularização.');
                                            }
                                        }}
                                        className={Style.btn_regularizacao}
                                    >
                                        Encaminhar Solicitacao Para Regularização
                                    </button>
                                </div>

                            )}
                        </Box>
                    </div>
                )}


                <div className={Style.box_container}>
                    <Box className={Style.box_itens}>
                        <Row>
                            <Col>
                                <h4>DETALHES E ESPECIFICAÇÕES</h4>
                                <p dangerouslySetInnerHTML={{ __html: solicitacao.Observacao }}></p>
                                {/* <p style={{ padding: '1em' }}>
                                    {solicitacao && solicitacao.Observacao && (solicitacao.Observacao.split('<br>').map((linha, index) => (
                                        <React.Fragment key={index}>
                                            {linha.split('*').map((parte, index2) => {
                                                if (parte.endsWith(':')) {
                                                    return (
                                                        <React.Fragment key={index2}>
                                                            <strong>{parte} </strong>
                                                        </React.Fragment>
                                                    );
                                                } else {
                                                    return (
                                                        <React.Fragment key={index2}>
                                                            {parte}
                                                        </React.Fragment>
                                                    );
                                                }
                                            })}
                                            <br />
                                        </React.Fragment>
                                    )))}
                                </p> */}
                            </Col>

                            <Col>
                                <h4>JUSTIFICATIVA</h4>
                                <p style={{ padding: '1em', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: solicitacao.Justificativa }}></p>
                            </Col>
                        </Row>

                        <h4 style={{ textAlign: 'center', marginBottom: '.5em' }}>ITENS DA SOLICITAÇÃO</h4>
                        <Table className={Style.table}>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Descrição</th>
                                    <th>Unidade</th>
                                    <th>Quantidade</th>
                                    <th align='right'>Valor Unitário</th>
                                    <th align='right'>Valor Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {item.map((item) => (
                                    <tr key={item.CodigoSolicitacaoItem}>
                                        <td>{item.NumeroItem}</td>
                                        <td>{item.Nome}</td>
                                        <td>{item.Unidade}</td>
                                        <td>{item.Quantidade}</td>
                                        <td align='right'>{item.ValorUnitario.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td align='right'>{item.ValorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>

                                    </tr>
                                ))}


                                <tr>
                                    <td style={{ backgroundColor: '#D5D8DC' }}></td>
                                    <td style={{ backgroundColor: '#D5D8DC' }}></td>
                                    <td style={{ backgroundColor: '#D5D8DC' }}></td>
                                    <td colSpan={2} style={{ backgroundColor: '#D5D8DC' }}><strong>Total da Solicitação</strong></td>
                                    <td align='right' style={{ backgroundColor: '#D5D8DC' }}>
                                        <strong>{valorTotalSolic.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</strong>
                                    </td>
                                </tr>

                            </tbody>
                        </Table>

                    </Box>

                    <br></br>

                    <div id="htmlSolicitacao">
                        <FormGroup className={Style.form_group}>
                            <Box className={Style.box_gerenciar}>
                                <h4 style={{ textAlign: 'center' }}>MOVIMENTOS DA SOLICITAÇÃO</h4>
                                <div className={Style.table}>
                                    <VerticalTimeline layout="1-column">
                                        {status
                                            ? status
                                                .sort((a, b) => new Date(b.DataAprovacao) - new Date(a.DataAprovacao)) // Ordena por DataAprovacao de forma decrescente
                                                .map((detalhes) => {
                                                    //console.log(detalhes);
                                                    const nomeArquivo = detalhes.Motivo.match(/arquivo: <strong>(.*?)</)?.[1];
                                                    const arquivoEncontrado = encontrarArquivo(nomeArquivo);
                                                    // console.log(arquivoEncontrado);
                                                    const estiloTexto = detalhes.Motivo.includes('excluído do processo') ? { color: 'red' } : {};

                                                    return (
                                                        wPerfil !== '37' ? (
                                                            <VerticalTimelineElement
                                                                key={status.CodigoStatus}
                                                                iconStyle={{ background: '#F1F5F9', color: '#1b4f72' }}
                                                                icon={<AiFillEdit />}
                                                                style={{ margin: '0', borderBottom: '2px solid #fff' }}
                                                            >
                                                                {detalhes.Motivo.includes('Arquivo Inserido Por:') && (
                                                                    <>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                            <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }}></span>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                                <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                                <button onClick={() => openModalArquivo(detalhes.Motivo.match(/Arquivo: (.*?)</)?.[1])} className={Style.VerAquivo_btn}>
                                                                                    Ver Arquivo Inserido<RiPhoneFindLine />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {!detalhes.Motivo.includes('Arquivo Inserido Por:') && (
                                                                    !detalhes.Motivo.includes('Solicitação de exclusão do arquivo:') && (
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                            <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }} style={estiloTexto}></span>
                                                                            <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                        </div>
                                                                    )
                                                                )}
                                                                {detalhes.Motivo.includes('Solicitação de exclusão do arquivo:') && (
                                                                    <>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                            <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }} style={estiloTexto}></span>
                                                                            <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                        </div>
                                                                        {wPerfil === '33' && arquivoEncontrado && arquivoEncontrado.Status === 0 && (
                                                                            <div style={{ textAlign: 'center', marginTop: '1em' }}>
                                                                                <button
                                                                                    onClick={() => showModalExcluirArquivo(arquivoEncontrado.Arquivo, arquivoEncontrado.CodigoArquivoProcessoInterno, detalhes.CodigoPerfil, arquivoEncontrado.Descricao)}
                                                                                    className={Style.VerAquivo_btn}
                                                                                >
                                                                                    Ver Arquivo <RiPhoneFindLine />
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </VerticalTimelineElement>
                                                        ) : (
                                                            <VerticalTimelineElement
                                                                key={status.CodigoStatus}
                                                                iconStyle={{ background: '#F1F5F9', color: '#1b4f72' }}
                                                                icon={<AiFillEdit />}
                                                                style={{ margin: '0', borderBottom: '2px solid #fff' }}
                                                            >
                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                    <span dangerouslySetInnerHTML={{ __html: detalhes.Motivo }} style={estiloTexto}></span>
                                                                    <span style={{ color: '#808B96' }}>Movimento: {moment(detalhes.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                                </div>
                                                            </VerticalTimelineElement>
                                                        )
                                                    )
                                                })
                                            : ''}
                                    </VerticalTimeline>
                                </div>
                            </Box>
                        </FormGroup>
                    </div>



























                    {/* 
                    <Box className={Style.box_historico}>

                        <p>{TextoSolicitacao
                            ? TextoSolicitacao
                                .split('\n') // Dividir o texto em parágrafos
                                .filter(paragrafo => paragrafo.trim()) // Filtrar parágrafos vazios
                                .map((paragrafo, index) => (
                                    <div key={index}>
                                        
                                        <VerticalTimeline layout="1-column">
                                            <VerticalTimelineElement
                                                key={status.CodigoStatus}
                                                iconStyle={{ background: '#F1F5F9', color: '#1b4f72' }}
                                                icon={<AiFillEdit />}
                                                style={{ margin: '0', borderBottom: '2px solid #fff' }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <span dangerouslySetInnerHTML={{ __html: paragrafo }}></span>
                                                    <span style={{ color: '#808B96' }}>Data do Movimento: {moment(status.DataAprovacao).format("DD/MM/yyyy")}</span>
                                                </div>
                                            </VerticalTimelineElement>
                                        </VerticalTimeline>
                                    </div>

                                ))
                            : ''}</p>


                        <h4 style={{ textAlign: 'center' }}>HISTÓRICO</h4>
                        <Table className={Style.table}>
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: '#D4E6F1', color: '#154360' }}></th>
                                    <th style={{ backgroundColor: '#D4E6F1', color: '#154360' }}>Setor/Nome</th>
                                    <th style={{ backgroundColor: '#D4E6F1', color: '#154360' }}>Destino</th>
                                    <th style={{ backgroundColor: '#D4E6F1', color: '#154360' }}>Detalhes</th>
                                    <th style={{ textAlign: 'center', backgroundColor: '#D4E6F1', color: '#154360' }}>Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {status &&
                                    status.map((solic) => {
                                        const solicMotivo = solic.Motivo;
                                        const maxLength = 50; // Defina o comprimento máximo desejado
                                        const truncatedMotivo = solicMotivo.length > maxLength ? `${solicMotivo.substring(0, maxLength)}...` : solicMotivo;
                                        return (
                                            <tr key={solic.CodigoStatus}>
                                                <td style={{ color: getIconColor(solic.Perfil_Destino.Nome) }}><GrStatusGoodSmall /></td>
                                                <td>{solic.PerfilRemetente.Nome}</td>
                                                <td>{solic.Perfil_Destino.Nome}</td>
                                                <td>{truncatedMotivo}</td>
                                                <td align="center">{moment(solic.DataAprovacao).format("DD/MM/yyyy")}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </Box> */}
                </div>
                {/* {wPerfil !== "37" &&
                    <div className={Style.mapList}>

                        {arquivo &&
                            (arquivo.length > 0 ?
                                (
                                    arquivo.map((ArquivoCadastro) => (
                                        <Card className={Style.Card} key={ArquivoCadastro.CodigoArquivoCadastro}>
                                            <strong><AiFillFilePdf /></strong>
                                            <a href={LinkArquivo + ArquivoCadastro.Arquivo} target="_blank" rel="noreferrer">{ArquivoCadastro.Descricao === '' ? (ArquivoCadastro.Arquivo) : (ArquivoCadastro.Descricao)}</a>
                                            <iframe title={ArquivoCadastro.Descricao} style={{ width: '100%', height: '100%' }} src={LinkArquivo + ArquivoCadastro.Arquivo}
                                            />
                                        </Card>

                                    ))
                                ) :
                                (<p>Nenhum arquivo adicionado</p>)

                            )}
                    </div>
                } */}

                {/* {wPerfil !== "37" &&
                    <div className={Style.encaminhamento}>
                        <Box className={Style.box_encaminhamento}>
                            <Form>
                                <FormGroup>
                                    <div className={Style.title_encaminhamento}>

                                        <h5>Encaminhar Processo</h5>
                                        <div style={{ width: '40%' }}></div>

                                        <Form.Label style={{ color: '#808080', fontSize: '18px', display: 'flex', alignItems: 'center' }}>Para:</Form.Label>
                                        <Form.Select name="setor" id="setor" value={setor} onChange={(event) => setSetor(event.target.value)} style={{ width: '300px', color: '#707070' }} required>
                                            <option value="0">Selecione um Setor</option>
                                            <option value="26">Recepção</option>
                                            <option value="17">Compras</option>
                                            <option value="23">Prestação de Contas</option>
                                            <option value="38">Financeiro</option>
                                            <option value="31">Assessoria Jurídica</option>
                                            <option value="25">Assessoria Administrativa</option>
                                            <option value="24">Diretoria Administrativa</option>
                                            <option value="28">Diretor Presidente</option>
                                            <option value="32">Recursos Humanos</option>
                                            <option value="33">Secretário Executivo</option>
                                            <option value="34">Consultor</option>
                                            <option value="35">TI</option>
                                            <option value="36">Gestao de Projetos</option>
                                        </Form.Select>
                                    </div>

                                   
                                    <Form.Label className={Style.form_label_encaminhamento}>Mensagem</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="mensagemRementente"
                                        value={mensagemremetente}
                                        onChange={(event) => setMensagemRemetente(event.target.value)}
                                        required
                                        style={{ height: '100px', color: '#707070' }} />
                                  
                                    <br></br>
                                    <Row>
                                        <Col>
                                            <Form.Label className={Style.form_label_encaminhamento}>Anexar Arquivo</Form.Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={8}>
                                            <Form.Label>Descrição Arquivo: </Form.Label>
                                            <Form.Control name="descricaoarquivo" value={descricaoarquivo} onChange={(event) => setDescricaoArquivo(event.target.value)} style={{ color: '#707070' }} />
                                        </Col>
                                        <Col>
                                            <Form.Label style={{ color: '#fff' }}>.</Form.Label>
                                            <Form.Control name="NovoFile" type="file" accept="application/pdf" onChange={handleNovoFile} ref={fileInputRef} />
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col>
                                            <button className={Style.btn_send} onClick={handleSubmitSolic}>Encaminhar Solicitação <IoIosSend /></button>
                                            {encaminhado && (<p align="center"> <AiFillCheckCircle /><strong>Solicitação Encaminhada com Sucesso!</strong></p>)}
                                            {selecioneSetor && (<p align="center"> <AiFillCheckCircle /><strong>Escolha o setor para Encaminhar!</strong></p>)}

                                        </Col>
                                    </Row>
                                </FormGroup>


                            </Form>
                        </Box>
                    </div>
                } */}

                <Modal show={loading} className={Style.Modal} centered >
                    <Modal.Body className={Style.Modal_body}>
                        <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalErro} className={Style.Modal} centered >
                    <Modal.Header closeButton onClick={handleCloseErro} className={Style.Modal_header}>
                        <h3>Ops!</h3>
                        <ImSad2 />
                    </Modal.Header>
                    <Modal.Body className={Style.Modal_body}>
                        <p>Algo deu errado, cheque os campos e tente novamente</p>
                    </Modal.Body>
                </Modal>

                <Modal show={modalDuploCheck} className={Style.Modal} centered >
                    <Modal.Header className={Style.Modal_header}>
                        <h3>Confirmação de Identidade </h3><PiIdentificationBadge />
                    </Modal.Header>
                    <Modal.Body className={Style.Modal_body}>
                        <p>Por favor, confirme sua senha para prosseguir</p>
                        <Form onSubmit={DuploCheck}>
                            {avisoDuploCheck ? (<p style={{ color: '#E74C3C' }}>A senha digitada não confere com a senha de usuário</p>) : (null)}
                            <Row>
                                <Col>
                                    <Form.Label className={Style.label_modal}>Sua senha:</Form.Label>
                                </Col>
                                <Col md={9}>
                                    <Form.Control className={Style.form_control} type="text" name="senha" value={senha} onChange={(event) => setSenha(event.target.value)} />
                                </Col>
                            </Row>

                            <button type="submit" className={Style.btn_salvar}> Salvar </button>
                            <button type="button" className={Style.btn_cancelar} onClick={closeAndResetModalDuploCheck}> Cancelar </button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={modalExcluirArquivo} className={Style.Modal} centered>
                    <Modal.Header closeButton onClick={handleCloseExcluirArquivo} className={Style.Modal_header}>
                        <h3>Confirmação de Exclusão de Arquivo</h3>
                    </Modal.Header>
                    <Modal.Body className={Style.Modal_body}>
                        <div>
                            <embed
                                title='Documento em Análise'
                                style={{ width: '15vw', height: '50vh', margin: '0 auto' }}
                                src={LinkArquivo + arquivoAnalise}
                            />
                        </div>
                        <Form>
                            {formNaoAutorizarExclusao ? (
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '.5em' }}>

                                    <Form.Label className={Style.label_modal} style={{ textAlign: 'left', marginLeft: '1em', marginTop: '1em' }}>Motivo da recusa:</Form.Label>
                                    <textarea className={Style.form_control_textarea} style={{ margin: '0 auto' }} name="motivo" value={motivoNaoExclusao} onChange={(event) => setMotivoNaoExclusao(event.target.value)} />

                                    <Row>
                                        <Col>
                                            <button
                                                type="button"
                                                onClick={handleNaoAutorizarExclusao}
                                                style={{
                                                    backgroundColor: '#CB4335',
                                                    color: '#ffffff',
                                                    fontWeight: '600',
                                                    borderRadius: '5px',
                                                    border: '0',
                                                    fontSize: '14px',
                                                    marginTop: '1em',
                                                    transition: 'box-shadow 0.3s ease',  // Adiciona uma transição suave
                                                    boxShadow: 'none',  // Sombra inicial
                                                }}
                                                onMouseOver={(e) => (e.currentTarget.style.boxShadow = 'rgba(0, 0, 0, 0.24) 0px 3px 8px')}  // Adiciona sombra no hover
                                                onMouseOut={(e) => (e.currentTarget.style.boxShadow = 'none')}  // Remove sombra quando o mouse sai
                                            >
                                                Não Autorizar Exclusão
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <FormGroup>
                                    <button className={Style.btn_send} onClick={ConfirmarExclusao}>
                                        Confirmar Exclusão
                                    </button>
                                    <button
                                        className={Style.btn_excluir}
                                        onClick={NaoAutorizarExclusao}
                                    >
                                        Não Autorizar Exclusão
                                    </button>
                                </FormGroup>
                            )}
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalArquivo} onHide={closeModal} fullscreen={fullscreen}>
                    <Modal.Header closeButton className={Style.Modal_header}>
                        <Modal.Title >Visualização do Arquivo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalContent && <iframe title="Visualização do Arquivo" src={LinkArquivo + modalContent} width="100%" height="850px"></iframe>}
                    </Modal.Body>
                </Modal>

                {redir && (<Navigate to="/index" replace={true} />)}
                {login && (<Navigate to="/" replace={true} />)}
            </div>
        </>
    )
}
export default VerSolicitacao;