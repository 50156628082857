import { Link } from 'react-router-dom'
import Styles from './Menu.module.css'
import logobola from '../img/logobola.png'
import LogoutBtn from './LogoutBtn.js'
import { useState, useEffect } from 'react'
import { FaBars, FaTimes } from "react-icons/fa";

function MenuArea() {
    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    var wCodigoProjeto = localStorage.getItem('CodigoProjeto');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 800 || window.innerHeight < 900) {
                setIsMobile(true);
                setIsOpen(false);
            } else {
                setIsMobile(false);
            }
        };

        const handleOrientationChange = () => {
            if (window.innerWidth < 800 || window.innerHeight < 800) {
                setIsMobile(true);
                setIsOpen(false);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    const handleMenu = () => {

        setIsOpen(!isOpen);

    };

    return (
        <nav className={isOpen ? Styles.navbar_mobile : Styles.navbar}>
            <img className={Styles.img_logo} src={logobola} alt='FAUSCS' style={{ display: isOpen ? "none" : (isMobile ? "grid" : "flex") }} />

            {isMobile && (
                <button className={Styles.icon} onClick={handleMenu}>
                    {isOpen ? <FaTimes /> : <FaBars />}
                </button>
            )}

            <ul className={Styles.ul} style={{ display: isOpen ? "grid" : (isMobile ? "none" : "flex"), backgroundColor: isOpen ? '#062654' : '', width: isOpen ? '100%' : '', borderRadius: isOpen ? "5px" : '' }}>
                <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="/index">Início</Link></li>
                <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="/CadSolicitacao">Nova Solicitacação</Link></li>
                <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to={`/PtFinanceiro/${wCodigoProjeto}`} target="_blank">Plano de Trabalho</Link></li>
                <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to={`/EscolhaProjeto`}>Projetos</Link></li>
                {/* <li className={isOpen ? Styles.li_mobile : Styles.li}>bem vindo <strong>{localStorage.getItem("NomeUsuario")}</strong></li> */}
                <div><LogoutBtn /></div>
            </ul>




        </nav>
    )
}

export default MenuArea;
