import StyleForm from "./CadastroRealizado.module.css"
import CadastroRealizadoImg from '../img/CadastroRealizadoImg.png'
import { Link } from "react-router-dom"

function CadastroRealizado() {

    localStorage.clear();


    return (
        <div className={StyleForm.page}>
            <div className={StyleForm.container}>
                <img className={StyleForm.img} src={CadastroRealizadoImg} alt='Cadastro Realizado'></img>
                <h1>Tudo Certo!</h1>
                <p>Cadastro Realizado com sucesso</p>

                <button className={StyleForm.btn}><Link to="https://www.fauscs.org.br/">Voltar para o site</Link></button>
            </div>
        </div>

    )
}
export default CadastroRealizado