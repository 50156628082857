import React from "react";
import StyleForm from './EditCadastro.module.css';
import Form from 'react-bootstrap/Form';
import { Card, Col, FormGroup, Table } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import InputMask from 'react-input-mask';
//import { cnpj } from 'cpf-cnpj-validator';
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { AiFillFilePdf } from "react-icons/ai";
import { IoMdArrowBack } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import Menu from "../Layout/Menu";
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif"
import IconCheckMark from "../img/IconCheckMark.svg"
import Select from "react-select";
import urlAPIlocal from '../../global';
import loadingGif from '../img/icons8-spinning-circle.gif';


var wtokenPublico = ''

function EditCadastro() {

  const { CodigoCadastro } = useParams();
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  //const [cadastro, setCadastro] = useState([]);
  const [arquivo, setArquivo] = useState([]);
  const [NovoFile, setNovoFile] = useState(null);
  const fileInputRef = useRef(null);

  const [codigoTipoCadastro, setCodigoTipoCadastro] = useState(0);
  const [aprovado, setAprovado] = useState(0);
  const [NumeroCnpj, setNumeroCnpj] = useState('');
  const [nome, setNome] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [NomeContato, setNomeContato] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [ComplementoEnd, setComplementoEnd] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [pais, setPais] = useState('');
  const [cep, setCep] = useState('');
  const [ddd, setDdd] = useState('');
  const [telefone, setTelefone] = useState('');
  const [ddd2, setDdd2] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');

  const [nomeBanco, setNomeBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [NumeroConta, setNumeroConta] = useState('');
  const [cnpjCpfConta, setcnpjCpfConta] = useState('');
  const [titularConta, settitularConta] = useState('');

  const [inscricaoEstadual, setinscricaoEstadual] = useState('');
  const [inscricaoMunicipal, setinscricaoMunicipal] = useState('');
  const [Atividade, setAtividade] = useState('');
  const [servicos, setServicos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [servicosGravados, setServicosGravados] = useState([]);
  const [produtosGravados, setProdutosGravados] = useState([]);
  const [cnae, setCnae] = useState('');
  const [simplesNacional, setSimplesNacional] = useState(false);
  const [observacao, setobservacao] = useState('');
  const date = new Date();
  const dataFormatada = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  //const [selectedContratoSocial, setSelectedContratoSocial] = useState(null);

  const [CadastrOk, setCadastroOk] = useState(false);
  // eslint-disable-next-line
  const [inputFilled, setInputFilled] = useState(false);
  const [botaoDesabilitadoArquivo, setbotaoDesabilitadoArquivo] = useState(false);
  const [botaoDesabilitado, setbotaoDesabilitado] = useState(false);

  const [perfilDestinoAtual, setPerfilDestinoAtual] = useState(0);

  const selectServicos = [
    { value: "Informática e congêneres", label: "Informática e congêneres" },
    { value: "Pesquisas e desenvolvimento de qualquer natureza", label: "Pesquisas e desenvolvimento de qualquer natureza" },
    { value: "Locação, cessão de direito de uso e congêneres", label: "Locação, cessão de direito de uso e congêneres" },
    { value: "Saúde, assistência médica e congêneres", label: "Saúde, assistência médica e congêneres" },
    { value: "Medicina e assistêcia veterinária e congêneres", label: "Medicina e assistêcia veterinária e congêneres" },
    { value: "Cuidados pessoais, estéticos, atividades físicas e congêneres", label: "Cuidados pessoais, estéticos, atividades físicas e congêneres" },
    { value: "Engenharia, arquitetura, geologia, urbanismo, construção civil, manutenção, limpeza, meio ambiente, saneamento e congêneres", label: "Engenharia, arquitetura, geologia, urbanismo, construção civil, manutenção, limpeza, meio ambiente, saneamento e congêneres" },
    { value: "Educação, ensino, orientação pedagógica, instruçao, treinamento e avaliação pessoal de qualquer grau ou natureza", label: "Educação, ensino, orientação pedagógica, instruçao, treinamento e avaliação pessoal de qualquer grau ou natureza" },
    { value: "Hospedagem, turismo, viagens e congêneres", label: "Hospedagem, turismo, viagens e congêneres" },
    { value: "Intermediação e congêneres", label: "Intermediação e congêneres" },
    { value: "Guarda, estacionamento, armazenamento, vigilâcia e congêneres", label: "Guarda, estacionamento, armazenamento, vigilâcia e congêneres" },
    { value: "Diversões, lazer, entreterimento e congêneres", label: "Diversões, lazer, entreterimento e congêneres" },
    { value: "Fonografia, fotografia, cinematografia e reprografia", label: "Fonografia, fotografia, cinematografia e reprografia" },
    { value: "Serviços relativos a bens de terceiros", label: "Serviços relativos a bens de terceiros" },
    { value: "Setor bancário ou financeiro, inclusive aqueles prestados por instituições financeiras autorizadas a funcionar pela União ou por quem de direito", label: "Setor bancário ou financeiro, inclusive aqueles prestados por instituições financeiras autorizadas a funcionar pela União ou por quem de direito" },
    { value: "Regulação de sinistro vinculados a contratos de seguros: inspeção e avaliação de risco para cobertura de contratos de seguros;prevenção de gerência de riscos seguráveis e congêneres", label: "Regulação de sinistro vinculados a contratos de seguros: inspeção e avaliação de risco para cobertura de contratos de seguros;prevenção de gerência de riscos seguráveis e congêneres" },
    { value: "Distribuição e venda de bilhetes e demais produtos de loteria, bingos,cartões, pules ou cupons de apostas, sorteios, prêmios, inclusive os decorrentes de títulos de capitalização e congêneres", label: "Distribuição e venda de bilhetes e demais produtos de loteria, bingos,cartões, pules ou cupons de apostas, sorteios, prêmios, inclusive os decorrentes de títulos de capitalização e congêneres" },
    { value: "Portuários, aeroportuários, ferroportuários, de terminais rodoviários, ferroviários e metroviários", label: "Portuários, aeroportuários, ferroportuários, de terminais rodoviários, ferroviários e metroviários" },
    { value: "Registros públicos, cartorários e notariais", label: "Registros públicos, cartorários e notariais" },
    { value: "Exploração de rodovia", label: "Exploração de rodovia" },
    { value: "Programação e comunicação visual, desenho industrial e congêneres", label: "Programação e comunicação visual, desenho industrial e congêneres" },
    { value: "Chaveiros, confecção de carimbos, placas, sinalização visual, banners,adesivos e congêneres", label: "Chaveiros, confecção de carimbos, placas, sinalização visual, banners,adesivos e congêneres" },
    { value: "Serviços funerários", label: "Serviços funerários" },
    { value: "Coleta, remessa ou entrega de correspondências, documentos, objetos,bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres", label: "Coleta, remessa ou entrega de correspondências, documentos, objetos,bens ou valores, inclusive pelos correios e suas agências franqueadas; courrier e congêneres" },
    { value: "Assistência social", label: "Assistência social" },
    { value: "Avaliação de bens e serviços de qualquer natureza", label: "Avaliação de bens e serviços de qualquer natureza" },
    { value: "Serviços de biblioteconomia", label: "Serviços de biblioteconomia" },
    { value: "Biologia, biotecnologia e química", label: "Biologia, biotecnologia e química" },
    { value: "Serviços técnicos em edificações, eletrônica, eletrotécnica, mecânica,telecomunicações e congêneres", label: "Serviços técnicos em edificações, eletrônica, eletrotécnica, mecânica,telecomunicações e congêneres" },
    { value: "Desenhos técnicos", label: "Desenhos técnicos" },
    { value: "Desembaraço aduaneiro, comissários, despachantes e congêneres", label: "Desembaraço aduaneiro, comissários, despachantes e congêneres" },
    { value: "Investigações particulares, detetives e congêneres", label: "Investigações particulares, detetives e congêneres" },
    { value: "Reportagem, assessoria de imprensa, jornalismo e relações públicas", label: "Reportagem, assessoria de imprensa, jornalismo e relações públicas" },
    { value: "Serviços de meteorologia", label: "Serviços de meteorologia" },
    { value: "Artistas, atletas, modelos e manequins", label: "Artistas, atletas, modelos e manequins" },
    { value: "Serviços de museologia", label: "Serviços de museologia" },
    { value: "Serviços de ourivesaria e lapidação", label: "Serviços de ourivesaria e lapidação" },
    { value: "Serviços relativos a obras de arte sob encomenda", label: "Serviços relativos a obras de arte sob encomenda" },
  ];

  const selectProdutos = [
    { value: "BENS PATRIMONIAIS", label: "BENS PATRIMONIAIS" },
    { value: "GASES MEDICINAIS E INDUSTRIAIS", label: "GASES MEDICINAIS E INDUSTRIAIS" },
    { value: "GENEROS ALIMENTICIOS", label: "GENEROS ALIMENTICIOS" },
    { value: "MATERIAIS/EQUIPAMENTOS DE INFORMATICA", label: "MATERIAIS/EQUIPAMENTOS DE INFORMATICA" },
    { value: "MATERIAL DE E.P.I. e EPC", label: "MATERIAL DE E.P.I. e EPC" },
    { value: "MATERIAL DE ESCRITORIO", label: "MATERIAL DE ESCRITORIO" },
    { value: "MATERIAL DE LIMPEZA/HIGIENE PE", label: "MATERIAL DE LIMPEZA/HIGIENE PE" },
    { value: "MATERIAL DE MANUTENÇÃO", label: "MATERIAL DE MANUTENÇÃO" },
    { value: "MATERIAL LABORATORIO/REAGENTES", label: "MATERIAL LABORATORIO/REAGENTES" },
    { value: "MATERIAL LABORATORIO/REAGENTES", label: "MATERIAL MEDICO HOSPITALAR" },
    { value: "MATERIAL MEDICO HOSPITALAR", label: "MEDICAMENTOS" },
    { value: "UNIFORMES E ROUPARIAS", label: "UNIFORMES E ROUPARIAS" },
    { value: "ORTESE E PROTESE", label: "ORTESE E PROTESE" },
    { value: "UTENSILIOS DE COPA", label: "UTENSILIOS DE COPA" },

  ]

  function getServico() {


    const wtoken = "Bearer " + wTokenLocal;

    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/TipoAtividadeCadastro?CodigoCadastro=' + CodigoCadastro + '&servico=true',
      headers: {
        'Authorization': wtoken,
        'Content-Type': 'application/json'
      },

    };

    axios(config)

      .then(function (response) {

        const data = response.data;
        // for (let i = 0; i < data.objetoRetorno.length; i++) {
        setServicosGravados(data.objetoRetorno);
        //console.log(data.objetoRetorno[i].nome);
        //console.log(atividade);
        //console.log(atividade[1].nome);
      }
        //}
      )
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          setLogin(true);
        }
        //console.log(JSON.stringify(response.data.mensagemRetorno));
      });
  }

  useEffect(() => {
    getServico();
    // eslint-disable-next-line
  }, []);

  function getProduto() {

    const wtoken = "Bearer " + wTokenLocal;

    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/TipoAtividadeCadastro?CodigoCadastro=' + CodigoCadastro + '&servico=false',
      headers: {
        'Authorization': wtoken,
        'Content-Type': 'application/json'
      },

    };

    axios(config)

      .then(function (response) {

        const data = response.data;
        // const prodcadastrados = []
        // for (let i = 0; i < data.objetoRetorno.length; i++) {
        //   console.log(data.objetoRetorno[i].nome)

        //   prodcadastrados = [
        //   {value:data.objetoRetorno[i].nome, label:data.objetoRetorno[i].nome},
        //                     ]

        // }          

        //for (let i = 0; i < data.objetoRetorno.length; i++) {
        setProdutosGravados(data.objetoRetorno);

        //console.log(atividade);
        //console.log(atividade[1].nome);
        //}
        //console.log(produtos);
      }
      )
      .catch(function (error) {
        //console.log(error);
        /* if (error.response.status === 401) {
            setLogin(true);
        } */
        //console.log(JSON.stringify(response.data.mensagemRetorno));
      });
  }

  useEffect(() => {
    getProduto();
    // eslint-disable-next-line
  }, []);

  var wTokenLocal = localStorage.getItem("token");
  var LinkArquivo = "https://fauscs.sistemacontroll.com.br/UploadsFauscs/ArquivosCadastro/";
  wtokenPublico = "Bearer " + wTokenLocal;

  function ConsultaToken() {
    //verifica se tem o token no localstorage
    if (wTokenLocal === undefined || wTokenLocal === null) {
      setLogin(true)
    }
  }

  useEffect(() => {
    ConsultaToken();
    // eslint-disable-next-line
  }, []);

  const [showModal, setShowModal] = useState(false);

  const [showIcon, setShowIcon] = useState(false);

  function handleClose() {
    setTimeout(() => {
      setShowModal(false);
      setCadastroOk(true);

    }, 3000);

  }

  /* function handleShow() {

  } */


  function GravaArquivo(wCodigoCadastroArquivo, CodCadastro, ArqCadastro, DescricaoArq, DeleteArquivo) {

    setbotaoDesabilitadoArquivo(true);

    var Retorno = false;
    //trata nome arquivo, tira acentos, espaços
    //console.log(ArqCadastro)
    let wNomeArquivoLimpo = ArqCadastro.name
    wNomeArquivoLimpo = wNomeArquivoLimpo.replace(/\s+/g, '');
    wNomeArquivoLimpo = wNomeArquivoLimpo.replace('+', '_');
    wNomeArquivoLimpo = wNomeArquivoLimpo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    wNomeArquivoLimpo = CodCadastro + "_" + wNomeArquivoLimpo

    var datFiles = JSON.stringify({
      "CodigoArquivoCadastro": wCodigoCadastroArquivo,
      "CodigoCadastro": CodCadastro,
      "descricao": DescricaoArq,
      "arquivo": wNomeArquivoLimpo, //CodCadastro + "_" + ArqCadastro.name,
      "status": 0
    })
    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/ArquivoCadastro',
      headers: {
        'Authorization': wtokenPublico,
        'Content-Type': 'application/json'
      },
      data: datFiles
    };
    //console.log(datFiles)
    axios(config)
      .then(function (response) {

        //console.log(response.data)
        if (response.data.isOk === true) {
          //faz upload do arquivo
          const file = ArqCadastro;
          const novoNome = wNomeArquivoLimpo;
          const novoArquivo = new File([file], novoNome, { type: file.type });
          const formData = new FormData();
          formData.append('arquivo', novoArquivo);

          var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/UploadArquivo',
            headers: {
              'Authorization': wtokenPublico,
              'Content-Type': 'multipart/form-dataa',
              'pasta': 'ArquivosCadastro'
            },
            data: formData
          };
          //console.log(formData)
          axios(config)

            .then(response => {
              //console.log(response.data);
              // faça algo com a resposta da API
              Retorno = false;
              if (ArqCadastro === NovoFile) {
                setShowIcon(true);
                setTimeout(() => {
                  setShowIcon(false);
                  setbotaoDesabilitadoArquivo(false);
                }, 4000);


              } else {
                setShowIcon(false);
              }


              //deletar arquivo antigo





              fileInputRef.current.value = '';
            })
            .catch(error => {
              console.error(error);
              Retorno = true;

            });
        } else {
          //tratar erro , mensagem ou algo na tela quando consumo retornar falha
          Retorno = true

        }

      })
      .catch(function (error) {
        console.log(error);
        Retorno = true
      });
    return Retorno;
  }


  const getCadastro = async (event) => {

    setLoading(true);

    const wtoken = "Bearer " + wTokenLocal;

    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/cadastro/' + CodigoCadastro,
      headers: {
        'Authorization': wtoken,
        'Content-Type': 'application/json'
      },

    };

    axios(config)

      .then(function (response) {

        // se for PJ continua nao faz nada
        // se for PF redireiciona para editcadastroPF
        //setRedirPF(true)

        // const data = response.data;
        //     setCadastro(data.objetoRetorno); 
        setCodigoTipoCadastro(response.data.objetoRetorno.CodigoTipoCadastro)
        setAprovado(response.data.objetoRetorno.Aprovado)
        setNumeroCnpj(response.data.objetoRetorno.NumeroCnpj);
        setNome(response.data.objetoRetorno.Nome);
        setNomeFantasia(response.data.objetoRetorno.NomeFantasia);
        setNomeContato(response.data.objetoRetorno.NomeContato);
        setEndereco(response.data.objetoRetorno.Endereco);
        setCidade(response.data.objetoRetorno.NomeCidade);
        setEstado(response.data.objetoRetorno.NomeEstado);
        setCep(response.data.objetoRetorno.NumeroCep);
        setBairro(response.data.objetoRetorno.Bairro);
        setComplementoEnd(response.data.objetoRetorno.ComplementoEnd);
        setPais(response.data.objetoRetorno.Pais);
        setDdd(response.data.objetoRetorno.NumeroDdd);
        setTelefone(response.data.objetoRetorno.NumeroTelefone);
        setDdd2(response.data.objetoRetorno.Ddd2);
        setTelefone2(response.data.objetoRetorno.Telefone2);
        setEmail(response.data.objetoRetorno.Email.Endereco);
        setWebsite(response.data.objetoRetorno.Website);
        setNomeBanco(response.data.objetoRetorno.NomeBanco);
        setAgencia(response.data.objetoRetorno.NumeroAgencia);
        setNumeroConta(response.data.objetoRetorno.NumeroConta);
        setcnpjCpfConta(response.data.objetoRetorno.CnpjCpfConta);
        settitularConta(response.data.objetoRetorno.TitularConta);
        setinscricaoEstadual(response.data.objetoRetorno.NumeroInscricaoEstadual);
        setinscricaoMunicipal(response.data.objetoRetorno.NumeroInscricaoMunicipal);
        setAtividade(response.data.objetoRetorno.Atividade);
        setCnae(response.data.objetoRetorno.Cnae);
        setSimplesNacional(response.data.objetoRetorno.SimplesNacional);
        setobservacao(response.data.objetoRetorno.observacao);
        setPerfilDestinoAtual(response.data.objetoRetorno.PerfilDestinoAtual);

        getProduto();
        getServico();

        setLoading(false);
      }
      )
      .catch(function (error) {
        console.log(error);
        //console.log(JSON.stringify(response.data.mensagemRetorno));
        if (error.response.status === 401) {
          setLogin(true);
        }
      });
  }

  useEffect(() => {
    getCadastro();
    // eslint-disable-next-line
  }, []);

  const gravarAtividade = (array, wCodCadastro, wtoken, wtipo) => {
    for (let i = 0; i < array.length; i++) {



      // grava atividade na tblTipoAtividadeCadastro
      var dataSelect = JSON.stringify({
        "CodigoTipoAtividadeCadastro": 0,
        "CodigoCadastro": wCodCadastro,
        "Nome": array[i].label,
        "servico": wtipo

      });

      var configSelect = {
        method: 'post',
        maxBodyLength: Infinity,
        url: urlAPIlocal + '/api/TipoAtividadeCadastro',
        headers: {
          'Authorization': wtoken,
          'Content-Type': 'application/json'
        },
        data: dataSelect
      };
      //console.log(dataSelect)
      axios(configSelect)

        .then(function (response) {
          setCadastroOk(true);
          //console.log(response.data);
        })
        .catch(error => {
          console.error(error);
        });




      //console.log(array[i].label);

    }
  };

  const ExcluirAtividade = (wTipoAtividade) => {


    var configSelect = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/TipoAtividadeCadastro/' + wTipoAtividade,
      headers: {
        'Authorization': "Bearer " + localStorage.getItem("token"),
        'Content-Type': 'application/json'
      },
      //data: dataSelect
    };
    //console.log(dataSelect)
    axios(configSelect)

      .then(function (response) {
        getProduto();
        getServico();

        //setCadastroOk(true);
        //console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });




    //console.log(array[i].label);


  };

  const getArquivo = async (event) => {

    const wtoken = "Bearer " + wTokenLocal;

    var config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/ArquivoCadastro?CodigoCadastro=' + CodigoCadastro,
      headers: {
        'Authorization': wtoken,
        'Content-Type': 'application/json'
      },

    };

    axios(config)

      .then(function (response) {

        const data = response.data;
        setArquivo(data.objetoRetorno);
        //console.log(arquivo);
      }
      )
      .catch(function (error) {
        console.log(error);
        //console.log(JSON.stringify(response.data.mensagemRetorno));
      });
  }

  useEffect(() => {
    getArquivo();
    // eslint-disable-next-line
  }, []);

  const handleNovoFile = (event) => {
    if (event.target.files) {
      setNovoFile(event.target.files[0]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setbotaoDesabilitado(true);
    setLoading(true);

    var wtokenBearer = "Bearer " + localStorage.getItem("token")

    let wPerfilDestinoAtual = 0
    if (perfilDestinoAtual !== 0) {
      wPerfilDestinoAtual = perfilDestinoAtual
    }

    var data = JSON.stringify({
      "CodigoCadastro": CodigoCadastro,
      "NumeroCnpj": NumeroCnpj,
      "Nome": nome,
      "TipoPessoa": "J",
      "Sigla": "",
      "Esfera": "",
      "NomeFantasia": nomeFantasia,
      "NomeContato": NomeContato,
      "Endereco": endereco + ', ' + numero,
      "Bairro": bairro,
      "NomeCidade": cidade,
      "NomeEstado": estado,
      "NumeroCep": cep,
      "ComplementoEnd": ComplementoEnd,
      "Pais": pais,
      "NumeroDdd": ddd,
      "NumeroTelefone": telefone,
      "Ddd2": ddd2,
      "Telefone2": telefone2,
      "NumeroCelular": "",
      "Email": {
        "Endereco": email
      },
      "Website": website,
      "Fax": "",
      "NumeroRg": "",
      "OrgaoExpedidor": "",
      "DataExpedicao": "1900-01-01T00:00:00.000Z",
      "cargo": "",
      "Funcao": "",
      "NumeroMatricula": "",
      "CodigoDirigente": 0,
      "CodigoTipoCadastro": codigoTipoCadastro,
      "DataEmissao": dataFormatada,
      "NomeBanco": nomeBanco,
      "NumeroBanco": "",
      "Pix": "",
      "NumeroAgencia": agencia,
      "NumeroConta": NumeroConta,
      "NomeConta": "",
      "Imagem": "",
      "NumeroPis": "0",
      "Nacionalidade": "",
      "Aprovado": aprovado,
      "TipoConta": "Conta Corrente",
      "EstadoCivil": "",
      "Servidor": false,
      "DataNascimento": "1900-01-01T00:00:00.000Z",
      "CnpjCpfConta": cnpjCpfConta,
      "TitularConta": titularConta,
      "NumeroInscricaoEstadual": inscricaoEstadual,
      "NumeroInscricaoMunicipal": inscricaoMunicipal,
      "Atividade": Atividade,
      "Cnae": cnae,
      "SimplesNacional": simplesNacional,
      "observacao": observacao,
      "PerfilDestinoAtual": wPerfilDestinoAtual,

    });

    var config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: urlAPIlocal + '/api/cadastro',
      headers: {
        'Authorization': wtokenBearer,
        'Content-Type': 'application/json'
      },
      data: data
    };
    //console.log(data)
    axios(config)

      .then(function (response) {

        //console.log(response.data)
        if (response.data.isOk === true) {
          // fez ao consumo sem erro       
          //setCadastroOk(true)

          //console.log(arquivo)
          // eslint-disable-next-line
          var wCodCadastroNovo = response.data.objetoRetorno.CodigoCadastro
          if (produtos.length > 0) {
            gravarAtividade(produtos, wCodCadastroNovo, wtokenBearer, false);
          }

          if (servicos.length > 0) {
            gravarAtividade(servicos, wCodCadastroNovo, wtokenBearer, true);
          }
          setLoading(false);
          setShowModal(true);

          //setCadastroOk(true)
        } else {
          //deu algum erro tratar , colocar algum aviso
          setCadastroOk(false)
        }
      })
      .catch(error => {
        console.error(error);

      });
  }

  const handleSelectProduto = (itemprod) => {
    setProdutos(itemprod);
    //setSelectedOptions(itemprod);
    //console.log(itemprod);
  };


  const handleSelectServicos = (itemserv) => {
    setServicos(itemserv);
    //setSelectedOptions(itemserv);
    //.log(itemserv);
  };

  return (

    <div className={StyleForm.page}>

      <Menu />

      <div> <button className={StyleForm.btn_voltar}><Link to="/ListCadastro"> <IoMdArrowBack /> Voltar</Link></button> </div>

      <div className={StyleForm.letreiro}>
        <h1>Edição de Cadastro</h1>

      </div>

      <Form className={StyleForm.container} onLoad={getCadastro} onSubmit={handleSubmit}>
        <header className={StyleForm.container_header}>Ficha Técnica</header>
        <FormGroup className={StyleForm.form_group_cnpj}>
          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label} > CNPJ*</Form.Label>
              <InputMask className={StyleForm.form_control_cnpj} mask="99.999.999/9999-99" value={NumeroCnpj} onChange={(event) => setNome(event.target.value)} disabled />
            </Col>
            <Col xs={8}>
              <Form.Label className={StyleForm.form_group_label} >Razão Social*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="nome" value={nome} onChange={(event) => setNome(event.target.value)} required />

            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              <Form.Label className={StyleForm.form_group_label}>Nome Fantasia</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="NomeFantasia" value={nomeFantasia} onChange={(event) => setNomeFantasia(event.target.value)} />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Nome para Contato</Form.Label>
              <Form.Control className={StyleForm.form_control} name="NomeContato" value={NomeContato} onChange={(event) => setNomeContato(event.target.value)} />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>CEP*</Form.Label>
              <InputMask className={StyleForm.form_control_cnpj} type="text" name="cep" mask="99999-999" value={cep} onChange={(event) => setCep(event.target.value)} required />
              {/* {!isValidCep && <p className={StyleForm.alert}>CEP inválido</p>} */}
            </Col>
            <Col xs={7}>
              <Form.Label className={StyleForm.form_group_label}>Endereço*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="endereco" value={endereco} onChange={(event) => setEndereco(event.target.value)} required />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Numero*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="numero" value={numero} onChange={(event) => setNumero(event.target.value)} disabled />
            </Col>

          </Row>

          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Complemento</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="ComplementoEnd" value={ComplementoEnd} onChange={(event) => setComplementoEnd(event.target.value)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Bairro*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="bairro" value={bairro} onChange={(event) => setBairro(event.target.value)} required />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Cidade*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="cidade" value={cidade} onChange={(event) => setCidade(event.target.value)} required />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Estado*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="estado" value={estado} onChange={(event) => setEstado(event.target.value)} required />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>País*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="Pais" value={pais} onChange={(event) => setPais(event.target.value)} required />
            </Col>

          </Row>

          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>DDD*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="ddd" value={ddd} onChange={(event) => setDdd(event.target.value)} required />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Telefone*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="tel" name="telefone" value={telefone} onChange={(event) => setTelefone(event.target.value)} required />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>DDD 2</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="ddd2" value={ddd2} onChange={(event) => setDdd2(event.target.value)} />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Telefone 2</Form.Label>
              <Form.Control className={StyleForm.form_control} type="tel" name="telefone2" value={telefone2} onChange={(event) => setTelefone2(event.target.value)} />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Email*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} required />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Website*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="website" value={website} onChange={(event) => setWebsite(event.target.value)} required />
            </Col>
          </Row>
        </FormGroup>



        <header className={StyleForm.container_header}>Informações Bancárias</header>


        <FormGroup className={StyleForm.form_group}>
          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Nome do Banco</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="NomeBanco" value={nomeBanco} onChange={(event) => setNomeBanco(event.target.value)} />
            </Col>
            <Col xs={3}>
              <Form.Label className={StyleForm.form_group_label}>Agencia</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="NumAgencia" value={agencia} onChange={(event) => setAgencia(event.target.value)} />
            </Col>
            <Col xs={3}>
              <Form.Label className={StyleForm.form_group_label}>Numero da Conta e Dígito</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="NumeroConta" value={NumeroConta} onChange={(event) => setNumeroConta(event.target.value)} />
            </Col>
          </Row>

          <Row>

            <Col>
              <Form.Label className={StyleForm.form_group_label}>CNPJ do Titular</Form.Label>
              <InputMask className={StyleForm.form_control_cnpj} mask="99.999.999/9999-99" value={cnpjCpfConta} onChange={(event) => setcnpjCpfConta(event.target.value)} />
            </Col>
            <Col xs={8}>
              <Form.Label className={StyleForm.form_group_label}>Nome do Titular</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="titularConta" value={titularConta} onChange={(event) => settitularConta(event.target.value)} />
            </Col>
          </Row>
        </FormGroup>


        <header className={StyleForm.container_header}>Informações da Empresa</header>


        <FormGroup className={StyleForm.form_group}>
          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Inscrição Estadual</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="inscricaoEstadual" value={inscricaoEstadual} onChange={(event) => setinscricaoEstadual(event.target.value)} />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Inscrição Municipal*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="inscricaoMunicipal" value={inscricaoMunicipal} onChange={(event) => setinscricaoMunicipal(event.target.value)} required />
            </Col>
            <Col xs={5}>
              <Form.Label className={StyleForm.form_group_label}>Tipo de Atividade*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="Atividade" value={Atividade} onChange={(event) => setAtividade(event.target.value)} required />
            </Col>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>CNAE Principal*</Form.Label>
              <Form.Control className={StyleForm.form_control} type="text" name="Cnae" value={cnae} onChange={(event) => setCnae(event.target.value)} required />
            </Col>

          </Row>

          <Row>
            <Table striped className={StyleForm.Table}>
              <thead>
                <tr>
                  <th className={StyleForm.th}>Serviços Cadastrados</th>
                  <th className={StyleForm.th_btn}></th>
                </tr>
              </thead>
              <tbody>

                {servicosGravados &&
                  (servicosGravados.length > 0 ?
                    (
                      servicosGravados.map((servicosCadastro) => (
                        <tr key={servicosCadastro.CodigoTipoAtividadeCadastro}>

                          <td>{servicosCadastro.Nome}</td>
                          <td className={StyleForm.td_btn}>
                            <button type="button" className={StyleForm.btn_delete} onClick={(event) => ExcluirAtividade(servicosCadastro.CodigoTipoAtividadeCadastro)}>
                              <TiDelete />
                            </button></td>
                        </tr>

                      ))
                    ) :
                    (<td>Nenhum Serviço cadastrado</td>)

                  )}
              </tbody>
            </Table>
            <Form.Label className={StyleForm.form_group_label}>Serviços Oferecidos</Form.Label>
            <Select isMulti={true} isClearable={true} isSearchable={true} closeMenuOnSelect={false} options={selectServicos} value={servicos} onChange={handleSelectServicos} className={StyleForm.form_control_select}></Select>
          </Row>

          <Row>
            <Table striped className={StyleForm.Table}>
              <thead>
                <tr>
                  <th className={StyleForm.th}>Produtos Cadastrados</th>
                  <th className={StyleForm.th_btn}></th>
                </tr>
              </thead>
              <tbody>

                {produtosGravados &&
                  (produtosGravados.length > 0 ?
                    (
                      produtosGravados.map((produtosCadastro) => (
                        <tr key={produtosCadastro.CodigoTipoAtividadeCadastro}>

                          <td>{produtosCadastro.Nome}</td>
                          <td className={StyleForm.td_btn}>
                            <button type="button" className={StyleForm.btn_delete} onClick={(event) => ExcluirAtividade(produtosCadastro.CodigoTipoAtividadeCadastro)}>
                              <TiDelete />
                            </button></td>

                        </tr>

                      ))
                    ) :
                    (<td>Nenhum Produto cadastrado</td>)

                  )}
              </tbody>
            </Table>
            <Form.Label className={StyleForm.form_group_label}>Produtos Oferecidos</Form.Label>
            <Select isMulti={true} isClearable={true} isSearchable={true} closeMenuOnSelect={false} options={selectProdutos} value={produtos} onChange={handleSelectProduto} className={StyleForm.form_control_select}></Select>

          </Row>


          <Row>
            <Col>
              <Form.Label className={StyleForm.form_group_label}>Optante do Simples Nacional?</Form.Label>
              <Form.Check className={StyleForm.form_check} type="switch" name="simplesNacional" value={simplesNacional} onChange={(event) => setSimplesNacional(event.target.checked)} />
            </Col>
            <Col xs={9}>
              <Form.Label className={StyleForm.form_group_label}>Observações</Form.Label>
              <Form.Control className={StyleForm.form_control} name="observacao" id="observacao" value={observacao} onChange={(event) => setobservacao(event.target.value)}></Form.Control>
            </Col>
          </Row>

        </FormGroup>

        <header className={StyleForm.container_header}>Documentos para habilitação <p>Todos os documentos anexados devem estar em formato PDF</p></header>

        <FormGroup className={StyleForm.form_group}>
          <div className={StyleForm.mapList}>

            <div className={StyleForm.img}>{showIcon && (<p align="center"><img src={IconCheckMark} alt="arquivo salvo" />Arquivo Alterado com Sucesso</p>)}</div>
            {arquivo &&

              (
                arquivo.map((ArquivoCadastro) => (

                  <Card key={ArquivoCadastro.CodigoArquivoCadastro} className={StyleForm.Card}>
                    <Row>
                      <Col>
                        <strong><AiFillFilePdf /></strong>
                        <a href={LinkArquivo + ArquivoCadastro.Arquivo} target="_blank" rel="noreferrer">{ArquivoCadastro.Descricao}</a>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={10}>
                        <Form.Control className={StyleForm.input} name="NovoFile" type="file" accept="application/pdf" onChange={handleNovoFile} ref={fileInputRef} />
                      </Col>
                      <Col>
                        <button className={StyleForm.btn_atualizar} type="button" onClick={() => GravaArquivo(ArquivoCadastro.CodigoArquivoCadastro, ArquivoCadastro.CodigoCadastro, NovoFile, ArquivoCadastro.Descricao, ArquivoCadastro.Arquivo)}>
                          {botaoDesabilitadoArquivo ? 'Aguarde....' : 'Alterar Arquivo'}
                        </button>

                      </Col>
                    </Row>
                  </Card>
                ))
              )}
          </div>

        </FormGroup>

        {<div align="center">{inputFilled && <span className={StyleForm.alert}>Falha na rede, Por favor clique em "Cadastrar" novamente.</span>}</div>}

        <Row>
          <Col >
            <button className={StyleForm.btn} type="submit" >
              {/* //onClick={handleShow} */}
              {botaoDesabilitado ? 'Aguarde...' : 'Atualizar Cadastro'}
            </button>

          </Col>
        </Row>

      </Form>

      <Modal show={loading} className={StyleForm.Modal} centered >
        <Modal.Body className={StyleForm.Modal_body}>
          <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
        <Modal.Header className={StyleForm.Modal_header}>
          <img src={check_circle} alt="ok"></img>
        </Modal.Header>
        <Modal.Body className={StyleForm.Modal_body}>
          Cadastro atualizado com sucesso!
        </Modal.Body>
      </Modal>
      {CadastrOk && (<Navigate to="/ListCadastro" replace={true} />)}
      {login && (<Navigate to="/" replace={true} />)}

    </div>

  )
}
export default EditCadastro;