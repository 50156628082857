import Menu from "../Layout/Menu";
import StyleForm from "./CadPJ.module.css";
import Form from 'react-bootstrap/Form';
import { Col, FormGroup } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from "react";
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css'
import InputMask from 'react-input-mask';
import { cpf } from 'cpf-cnpj-validator';
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import check_circle from "../img/check_circle.gif";
import urlAPIlocal from '../../global';
import { IoMdArrowBack } from "react-icons/io"
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import loading from "../img/icons8-spinning-circle.gif"



function CadPF() {

    var wTokenLocal = localStorage.getItem("token");
    const [login, setLogin] = useState(false);
    const [gravando, setGravando] = useState(false);

    const [NumeroCpf, setNumeroCpf] = useState('');
    const [nome, setNome] = useState('');
    const [NumeroRg, setNumeroRg] = useState('');
    const [NumeroPis, setNumeroPis] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [estadoCivil, setEstadoCivil] = useState('');
    const [nacionalidade, setNacionalidade] = useState('');

    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [ComplementoEnd, setComplementoEnd] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [pais, setPais] = useState('');
    const [cep, setCep] = useState('');
    const [ddd, setDdd] = useState('');
    const [telefone, setTelefone] = useState('');
    const [ddd2, setDdd2] = useState('');
    const [telefone2, setTelefone2] = useState('');
    const [email, setEmail] = useState('');

    const [nomeBanco, setNomeBanco] = useState('');
    const [agencia, setAgencia] = useState('');
    const [NumeroConta, setNumeroConta] = useState('');
    const [tipoConta, setTipoConta] = useState('');

    const [observacao, setobservacao] = useState('');
    const [pix, setPix] = useState('');

    const date = new Date();
    const dataFormatada = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    const [isValid, setIsValid] = useState(true);
    // eslint-disable-next-line
    const [inputFilled, setInputFilled] = useState(false);
    const [cpfExiste, setCpfExiste] = useState(false);
    const [CadastrOk, setCadastroOk] = useState(false);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [avisoTipoConta, setAvisoTipoConta] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [tokenXSRF, setTokenXSRF] = useState('');
    const [xsrfAspNetCore, setXsrfAspNetCore] = useState('');

    const { Tipo } = useParams();


    //.split('=')[1];

    // const GetTokenXSRF = () => {

    //     var config = {
    //         method: 'GET',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/ObterTokenXSRF',
    //         headers: {
    //             'Authorization': "Bearer " + localStorage.getItem("token"), 'Content-Type': 'application/json'
    //         },
    //     };
    //     //console.log(urlAPIlocal)
    //     axios(config)
    //         .then(function (response) {

    //             const data = response.data;
    //             console.log(data.objetoRetorno.XsrfToken);
    //             setTokenXSRF(data.objetoRetorno.XsrfToken);

    //             const xsrfAspNetCore = document.cookie
    //                 .split('; ')
    //                 .find(cookie => cookie.startsWith('.AspNetCore.Antiforgery.1jibS6xmX7I'))  // Verifique se o nome do cookie está correto
    //             //.split('=')[1];
    //             console.log(xsrfAspNetCore);
    //             setXsrfAspNetCore(xsrfAspNetCore)
    //         }
    //         )
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }
    // useEffect(() => {
    //     GetTokenXSRF();
    //     // eslint-disable-next-line
    // }, []);
    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);



    function handleClose() {
        setTimeout(() => {
            setShowModal(false);
            setCadastroOk(true);

        }, 2000);

    }

    const handleCPF = (event) => {

        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true);
        }
        setNumeroCpf(event.target.value);

        //ConsultaCNPJ(event.target.value);
        // Aqui você pode fazer o que precisar com o valor do input
        //Consulta CPF
        var wCpfLimpo = event.target.value;
        var wCpf = event.target.value;
        wCpfLimpo = wCpfLimpo.replace(/\D+/g, '');

        // eslint-disable-next-line
        var data = '';

        if (wCpfLimpo.length === 11) {

            if (cpf.isValid(wCpfLimpo)) { // colocar setisvalid true quando nao entrar aqui

                var configCpf = {
                    method: 'GET',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Cadastro?NumeroCnpj=' + wCpf + "&CodigoTipoCadastro=" + Tipo,
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem("token"),
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                //console.log(data)
                //console.log(configCpf)
                axios(configCpf)
                    .then(function (response) {
                        //console.log(response)
                        // verifica se a propriedade NumeroCnpj existe e é igual a numeroCnpj definido acima
                        if (response.data.codigoRetorno > 0) {
                            setCpfExiste(true);
                            setIsValid(true);
                            setBotaoDesabilitado(true)

                        } else {

                            setCpfExiste(false);
                            setBotaoDesabilitado(false)
                            //verifica se o cnpj é valido antes de consultar no cnpja
                            if (!cpf.isValid(wCpfLimpo)) {
                                setIsValid(false);
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            else {
                setIsValid(false);
                setCpfExiste(false);
            }
        }

    }

    const handleSubmit = async (event) => {

        event.preventDefault();

        //verifica se selecionar tipo da conta que é obrigatorio
        if (tipoConta === "") {
            setAvisoTipoConta(true);
        } else {
            setAvisoTipoConta(false);
            setBotaoDesabilitado(true)
            setGravando(true);

            var wtokenBearer = "Bearer " + localStorage.getItem("token")
            var data = JSON.stringify({
                "CodigoCadastro": 0,
                "NumeroCnpj": NumeroCpf,
                "Nome": nome,
                "TipoPessoa": "F",
                "Sigla": "",
                "Esfera": "",
                "NomeFantasia": "",
                "NomeContato": "",
                "Endereco": endereco + ', ' + numero,
                "Bairro": bairro,
                "NomeCidade": cidade,
                "NomeEstado": estado,
                "NumeroCep": cep,
                "ComplementoEnd": ComplementoEnd,
                "Pais": pais,
                "NumeroDdd": ddd,
                "NumeroTelefone": telefone,
                "Ddd2": "",
                "Telefone2": "",
                "NumeroCelular": "",
                "Email": {
                    "Endereco": email
                },
                "Website": "",
                "Fax": "",
                "NumeroRg": NumeroRg,
                "OrgaoExpedidor": "",
                "DataExpedicao": "1900-01-01T00:00:00.000Z",
                "Cargo": "",
                "Funcao": "",
                "NumeroMatricula": "",
                "CodigoDirigente": 0,
                "CodigoTipoCadastro": Tipo,
                "DataEmissao": dataFormatada,
                "NomeBanco": nomeBanco,
                "NumeroBanco": "",
                "Pix": pix,
                "NumeroAgencia": agencia,
                "NumeroConta": NumeroConta,
                "NomeConta": "",
                "Imagem": "",
                "NumeroPis": NumeroPis,
                "Nacionalidade": nacionalidade,
                "Aprovado": 1,
                "TipoConta": tipoConta,
                "EstadoCivil": estadoCivil,
                "Servidor": false,
                "DataNascimento": moment(dataNascimento).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                "CnpjCpfConta": "",
                "TitularConta": "",
                "NumeroInscricaoEstadual": "",
                "NumeroInscricaoMunicipal": "",
                "Atividade": "",
                "cnae": "",
                "SimplesNacional": false,
                "observacao": observacao,
                "PerfilDestinoAtual": 0,

            });

            var config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: urlAPIlocal + '/api/cadastro',
                headers: {
                    'Content-Type': 'application/json',
                    'RequestVerificationToken': 'token',
                    'X-XSRF-TOKEN': tokenXSRF,
                    'Authorization': wtokenBearer,

                },
                data: data
            };
            console.log(config)
            axios(config)

                .then(function (response) {

                    //console.log(response.data)
                    if (response.data.isOk === true) {
                        // fez ao consumo sem erro
                        // pega o codigo cadastrado
                        //var wCodCadastroNovo = response.data.objetoRetorno.CodigoCadastro
                        setShowModal(true);
                        setBotaoDesabilitado(false);
                        setGravando(false);


                    } else {
                        //deu algum erro tratar , colocar algum aviso
                        setBotaoDesabilitado(false);
                        setCadastroOk(false);
                    }
                })
                .catch(error => {
                    setBotaoDesabilitado(false);
                    setGravando(false);
                    console.error(error);
                });
        }
    }
    const handleFechaGravando = (event) => {
        if (CadastrOk === true) { setGravando(false) };
    }

    const handleCEP = async (event) => {
        try {
            setCep(event.target.value);
            var wCepLimpo = event.target.value;
            var wCep = event.target.value;
            wCepLimpo = wCepLimpo.replace(/\D+/g, '');

            if (wCepLimpo.length === 8) {
                const response = await axios.get(`https://viacep.com.br/ws/${wCep}/json`);
                setEndereco(response.data.logradouro);
                setComplementoEnd(response.data.complemento);
                setBairro(response.data.bairro);
                setCidade(response.data.localidade);
                setEstado(response.data.uf);
                setDdd(response.data.ddd);
            }


        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Menu />

            <div className={StyleForm.page}>

                <div> <button className={StyleForm.voltar}>  <Link to="/CadGeral" ><IoMdArrowBack /> Voltar</Link> </button> </div>

                <div className={StyleForm.letreiro}>
                    {Tipo === '90' ? <h1>Cadastro de Usuários do Sistema - Pessoa Física</h1> : Tipo === '43' && <h1>Cadastro de Credor - Pessoa Física</h1>}

                </div>

                <Form onSubmit={handleSubmit}>

                    <FormGroup className={StyleForm.container}>
                        <header className={StyleForm.container_header}>Informações da Empresa</header>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label} >CPF*</Form.Label>
                                <InputMask className={StyleForm.form_control_cnpj} mask="999.999.999-99" value={NumeroCpf} onChange={handleCPF} required />
                                {cpfExiste && <p style={{ color: '#ff0303d0', fontSize: '14px' }}>CPF já cadastrado</p>}
                                <div align="center">{!isValid && <p style={{ color: '#ff0303d0', fontSize: '14px' }}>CPF inválido</p>}</div>
                            </Col>
                            <Col xs={8}>
                                <Form.Label className={StyleForm.form_group_label} >Nome*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="nome" value={nome} onChange={(event) => setNome(event.target.value)} required />

                            </Col>
                        </Row>

                        <Row>

                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>RG</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" value={NumeroRg} onChange={(event) => setNumeroRg(event.target.value)} />
                            </Col>
                            <Col xs={8}>
                                <Form.Label className={StyleForm.form_group_label}>PIS*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="NumeroPis" value={NumeroPis} onChange={(event) => setNumeroPis(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Data de Nascimento*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="date" value={dataNascimento} onChange={(event) => setDataNascimento(event.target.value)} required />
                            </Col>
                        </Row>

                        <Row>

                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Nacionalidade</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" value={nacionalidade} onChange={(event) => setNacionalidade(event.target.value)} />
                            </Col>
                            <Col xs={8}>
                                <Form.Label className={StyleForm.form_group_label}>Estado Civíl</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="EstadoCivil" value={estadoCivil} onChange={(event) => setEstadoCivil(event.target.value)}>
                                    <option>Selecione uma opção</option>
                                    <option>Solteiro(a)</option>
                                    <option>Casado(a)</option>
                                    <option>Separado(a)</option>
                                    <option>Divorciado(a)</option>
                                    <option>Viúvo(a)</option>
                                </Form.Select>
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>CEP*</Form.Label>
                                <InputMask className={StyleForm.form_control_cnpj} mask="99999-999" type="text" name="cep" value={cep} onChange={handleCEP} required />
                                {/* {!isValidCep && <p className={StyleForm.alert}>CEP inválido</p>} */}
                            </Col>
                            <Col xs={7}>
                                <Form.Label className={StyleForm.form_group_label}>Endereço*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="endereco" value={endereco} onChange={(event) => setEndereco(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Numero*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="numero" value={numero} onChange={(event) => setNumero(event.target.value)} required />
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Complemento</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="ComplementoEnd" value={ComplementoEnd} onChange={(event) => setComplementoEnd(event.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Bairro*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="bairro" value={bairro} onChange={(event) => setBairro(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Cidade*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="cidade" value={cidade} onChange={(event) => setCidade(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Estado*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="estado" value={estado} onChange={(event) => setEstado(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>País*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="Pais" value={pais} onChange={(event) => setPais(event.target.value)} required />
                            </Col>

                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>DDD*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="ddd" value={ddd} onChange={(event) => setDdd(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Telefone*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="tel" name="telefone" value={telefone} onChange={(event) => setTelefone(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>DDD 2</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="ddd2" value={ddd2} onChange={(event) => setDdd2(event.target.value)} />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Telefone 2</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="tel" name="telefone2" value={telefone2} onChange={(event) => setTelefone2(event.target.value)} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Email*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} required />
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup className={StyleForm.container}>
                        <header className={StyleForm.container_header}>Informações Bancárias</header>

                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Nome do Banco*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="NomeBanco" value={nomeBanco} onChange={(event) => setNomeBanco(event.target.value)} required />
                            </Col>
                            <Col xs={3}>
                                <Form.Label className={StyleForm.form_group_label}>Agencia*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="NumAgencia" value={agencia} onChange={(event) => setAgencia(event.target.value)} required />
                            </Col>
                            <Col xs={3}>
                                <Form.Label className={StyleForm.form_group_label}>Numero da Conta e Dígito*</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="NumeroConta" value={NumeroConta} onChange={(event) => setNumeroConta(event.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Tipo da Conta* {avisoTipoConta && <span style={{ color: '#ff0303d0', fontSize: '14px' }}>Selecione o tipo da conta</span>}</Form.Label>
                                <Form.Select className={StyleForm.form_control} name="tipoConta" value={tipoConta}
                                    onChange={(event) => {
                                        setTipoConta(event.target.value);
                                        if (event.target.value === "") { setAvisoTipoConta(true); } else { setAvisoTipoConta(false); };
                                    }} required>
                                    <option>Selecione uma opção</option>
                                    <option>Conta Corrente</option>
                                    <option>Poupança</option>
                                </Form.Select>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label className={StyleForm.form_group_label}>Chave Pix</Form.Label>
                                <Form.Control className={StyleForm.form_control} type="text" name="pix" value={pix} onChange={(event) => setPix(event.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label className={StyleForm.form_group_label}>Observação</Form.Label>
                            <textarea className={StyleForm.form_control} type="text" name="Observacao" value={observacao} onChange={(event) => setobservacao(event.target.value)} />
                        </Row>

                        <div align="right">
                            <button className={StyleForm.btn} type="submit" disabled={botaoDesabilitado ? true : false}>
                                Gravar
                                {/* {botaoDesabilitado ? 'Aguarde...' : 'Cadastrar'} */}
                            </button>
                        </div>
                    </FormGroup>

                    {<div align="center">{inputFilled && <span className={StyleForm.alert}>Falha na rede, Por favor clique em "Cadastrar" novamente.</span>}</div>}

                </Form>

                <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
                    <Modal.Header className={StyleForm.Modal_header}>
                        <img src={check_circle} alt="ok"></img>
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <p>Cadastro realizado com sucesso</p>
                    </Modal.Body>
                </Modal>
                {CadastrOk && (<Navigate to="/cadGeral" replace={true} />)}

                {login && (<Navigate to="/" replace={true} />)}

            </div >

            <footer className={StyleForm.footer}></footer>

            <Modal show={gravando} onLoad={handleFechaGravando} className={StyleForm.Modal} centered>
                <Modal.Header className={StyleForm.Modal_header}>
                    <h3>Gravando...</h3>
                </Modal.Header>
                <Modal.Body className={StyleForm.Modal_body}>
                    <img src={loading}></img>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default CadPF