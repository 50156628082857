import { Link } from 'react-router-dom'
import Styles from './Menu.module.css'
import logobola from '../img/logobola.png'
import LogoutBtn from './LogoutBtn.js'
import { useState, useEffect } from 'react'
import {FaBars, FaTimes}from "react-icons/fa";

function Menu() {

  var wPerfil = localStorage.getItem("CodigoPerfil");

    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 800 || window.innerHeight < 900) {
          setIsMobile(true);
          setIsOpen(false);
        } else {
          setIsMobile(false);
        }
      };
  
      const handleOrientationChange = () => {
        if (window.innerWidth < 800 || window.innerHeight < 800) {
          setIsMobile(true);
          setIsOpen(false);
        } else {
          setIsMobile(false);
        }
      };
  
      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleOrientationChange);
  
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleOrientationChange);
      };
    }, []);
  
    const handleMenu = () => {
       
        setIsOpen(!isOpen);
       
    };
  
    return (
      <nav className={isOpen ? Styles.navbar_mobile : Styles.navbar}>
        <img className={Styles.img_logo} src={logobola} alt='FAUSCS' style={{ display: isOpen ? "none" : (isMobile ? "grid" : "flex")}} />
        
          {isMobile && (
            <button className={Styles.icon} onClick={handleMenu}>
              {isOpen ? <FaTimes/> : <FaBars/>}
            </button>
          )}
          
            <ul className={Styles.ul} style={{ display: isOpen ? "grid" : (isMobile ? "none" : "flex"), backgroundColor: isOpen ? '#062654' : '', width: isOpen ? '100%' : '', borderRadius: isOpen ? "5px" : ''}}>
              <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="/index">Início</Link></li>
              <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="/ProcInterno">Processos Internos</Link></li>
              <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="/listcadastro">Credenciamento</Link></li>
              <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="/cadGeral">Cadastro Geral</Link></li>
              {wPerfil === '1' &&
              <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="/usuarios">Usuários</Link></li>
              }
              {/* <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="">Emails</Link></li> */}
              <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="/ListProjetos">Projetos</Link></li>
              {/* <li className={isOpen ? Styles.li_mobile : Styles.li}><Link to="">Impostos</Link></li> */}
              <div><LogoutBtn/></div>
            </ul>
            
            
          
        
      </nav>
    )
  }
  
  export default Menu;
// function Menu() {

//     const [isOpen, SetIsOpen] = useState(false);
//     const [showMenu, setShowMenu] = useState(false);
//     const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       if (window.innerWidth < 800 || window.innerHeight < 800) {
//         setIsMobile(true);
//       } else {
//         setIsMobile(false);
//       }
//     };

//     const handleOrientationChange = () => {
//         if (window.innerWidth < 800 || window.innerHeight < 800) {
//             setIsMobile(true);
//           } else {
//             setIsMobile(false);
//           }
//     };

//     // Registra os event listeners para "resize" e "orientationchange" na janela
//     window.addEventListener('resize', handleResize);
//     window.addEventListener('orientationchange', handleOrientationChange);

//     // Remove os event listeners quando o componente for desmontado
//     return () => {
//       window.removeEventListener('resize', handleResize);
//       window.removeEventListener('orientationchange', handleOrientationChange);
//     };
//   }, []);

    
// //   const handleMenu = () => {
// //     SetIsOpen(!isOpen);
// //     if (isMobile === true) {
// //       setShowMenu(!showMenu);
// //     }
// //   };


// const handleMenu = () => {
//     setShowMenu(!showMenu);
//   };
  


//     return (
//         <nav className={Styles.navbar}>

//             <img className={Styles.img_logo} src={logobola} alt='FAUSCS' />

//             <div>
//                 {isMobile && (
//                 <button className={Styles.icon} onClick={handleMenu}>
//                 {showMenu ? <FaTimes/> : <FaBars/>}
//                 </button>
//             )} 

//             {!isMobile && (            
//             <ul className={Styles.ul} style={{ display: showMenu ? "grid" : "flex" }}>
//                 <li className={Styles.li}>
//                     <Link to="/index">Início</Link></li>
//                 <li className={Styles.li}>
//                     <Link to="/listcadastro">Credenciamento</Link></li>
//                 <li className={Styles.li}>
//                     <Link to="/cadGeral">Cadastro Geral</Link></li>
//                 <li className={Styles.li}>
//                     <Link to="">Usuários</Link></li>
//                 <li className={Styles.li}>
//                     <Link to="">Emails</Link></li>
//                 <li className={Styles.li}>
//                     <Link to="/ListProjetos">Projetos</Link></li>
//                 <li className={Styles.li}>
//                     <Link to="">Impostos</Link></li>
//                 <LogoutBtn />
//             </ul>
//             )}
//             </div> 
//         </nav>
//     )
// }
// export default Menu