
import React from "react"
import Form from 'react-bootstrap/Form'
import StyleForm from './/Elementos.module.css'
import { AiFillPlusCircle } from 'react-icons/ai'
import { TiDelete } from "react-icons/ti";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Menu from "../Layout/Menu";
import { Col, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import done from "../img/done.svg";
import { ImSad2 } from 'react-icons/im';
import loadingGif from "../img/icons8-spinning-circle.gif";


function Elementos() {
    const { wCodigoConvenioNovo } = useParams();
    const [login, setLogin] = useState(false);
    const [Elemento, setElemento] = useState([]);
    const [ElementoEscolhido, setElementoEscolhido] = useState('');
    const [ElementosGravados, setElementosGravados] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    var wTokenLocal = localStorage.getItem("token");
    const [projetoatual, setProjetoAtual] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);

    function handleClose() {
        setTimeout(() => {
            setShowModal(false);

        }, 2000);

    }

    function handleCloseErro() {

        setShowModalErro(false);

    };


    // const navigate = useNavigate();

    // function handleClick() {
    //     navigate(-1);
    // }

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    const gravaElemento100 = async () => {
        const wtoken = "Bearer " + wTokenLocal;

        const config = {
            method: "GET",
            url: `${urlAPIlocal}/api/ConvenioElemento?CodigoElemento=100&CodigoConvenio=${wCodigoConvenioNovo}&IsInclude=false`,
            headers: {
                Authorization: wtoken,
                "Content-Type": "application/json",
            },
        };

        try {
            const response = await axios(config);
            const data = response.data;

            if (data.codigoRetorno === 0) {
                const wtokenBearer = "Bearer " + localStorage.getItem("token");
                const postData = JSON.stringify({
                    "CodigoConvenioElemento": 0,
                    "CodigoElemento": 100,
                    "ValorConcedido": 0,
                    "CodigoConvenio": wCodigoConvenioNovo,
                    "ValorFonte1": 0,
                    "ValorFonte2": 0,
                    "ValorFonte3": 0,
                });

                const postConfig = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${urlAPIlocal}/api/ConvenioElemento`,
                    headers: {
                        'Authorization': wtokenBearer,
                        'Content-Type': 'application/json',
                    },
                    data: postData,
                };

                const postResponse = await axios(postConfig);

                if (postResponse.data.isOk === true) {
                    //console.log('Elemento a Definir gravado');
                } else {
                    //console.log('Elemento a Definir erro');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        gravaElemento100();
        // eslint-disable-next-line
    }, []);

    const getProjeto = async (event) => {
        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + wCodigoConvenioNovo,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjetoAtual(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);

    const getElemento = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url: urlAPIlocal + "/api/Elemento?Status=true&IsInclude=false",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setElemento(data);
            //console.log(data)
            //muda a ordem dos itens no array antes de mostrar na tela
            const sortedList = data.sort((a, b) => a.CodigoElemento - b.CodigoElemento);
            setElemento(sortedList);

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getElemento();
        // eslint-disable-next-line
    }, []);

    const handleSelectElemento = (event) => {
        setElementoEscolhido(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var data = JSON.stringify({
            "CodigoConvenioElemento": 0,
            "CodigoElemento": ElementoEscolhido,
            "ValorConcedido": 0,
            "CodigoConvenio": wCodigoConvenioNovo,
            "ValorFonte1": 0,
            "ValorFonte2": 0,
            "ValorFonte3": 0

        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ConvenioElemento',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data

        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro
                    //console.log(response);
                    setIsLoading(false);
                    getElementosGravados();
                    setShowModal(true);
                    //console.log(ElementosGravados);

                    //verifica o processo do projeto, se for 2 marcar processo = 3
                    //console.log(projetoatual.Processo);
                    const wProcessoAtual = projetoatual.Processo
                    if (wProcessoAtual === 2) {
                        //gravar o processo
                        const wtoken = "Bearer " + localStorage.getItem("token");
                        var Retorno = false;
                        var config = {
                            method: 'put',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/Convenio/MudarProcesso/' + wCodigoConvenioNovo + '/3',
                            headers: {
                                'Authorization': wtoken,
                                'Content-Type': 'application/json'
                            },
                        };
                        axios(config)
                            .then(function (response) {
                                //console.log(response.data)
                                if (response.data.isOk === true) {
                                    Retorno = true;
                                } else {
                                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                                    Retorno = false
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    };

                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setIsLoading(false);
                    setShowModalErro(true);
                }
            })
            .catch(error => {
                setIsLoading(false);
                setShowModalErro(true);
                console.error(error);
            });
    }

    const getElementosGravados = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/ConvenioElemento?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };
            //console.log(config)
            const response = await axios(config);


            const data = response.data.objetoRetorno;

            setElementosGravados(data);
            //console.log(data[0].CodigoElemento)
            //muda a ordem dos itens no array antes de mostrar na tela
            const sortedList = data.sort((c, d) => c.CodigoElemento - d.CodigoElemento);
            setElementosGravados(sortedList);

            // if (data.CodigoElemento !== 100) {
            //     gravaElemento100();
            // }


        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getElementosGravados();
        // eslint-disable-next-line
    }, []);


    const ExcluirElemento = (wElemento) => {
        setIsLoading(true);
        var configSelect = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ConvenioElemento/' + wElemento,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataSelect
        };
        //console.log(dataSelect)
        axios(configSelect)

            .then(function (response) {
                setIsLoading(false);
                getElementosGravados();

                //setCadastroOk(true);
                //console.log(wElemento);
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);
            });




        //console.log(array[i].label);


    }



    return (
        <div className={StyleForm.page}>
            <Menu />

            {/* <div> <button className={StyleForm.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={StyleForm.voltar}>  <Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            <div className={StyleForm.header}>
                <h1>Cadastro de Elementos</h1>
            </div>

            <div className={StyleForm.container}>

                <form className={StyleForm.select} onSubmit={handleSubmit} >

                    <Col md={2}>
                        <Form.Label className={StyleForm.select_lable}>Selecione os Elementos</Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Select className={StyleForm.form_control} name="elementos" id="elementos" value={ElementoEscolhido} onChange={handleSelectElemento}>
                            <option>Selecione uma opção</option>
                            {Elemento.length > 0 &&
                                Elemento.map((opcao) => (
                                    opcao.CodigoElemento === 100 ? null : (
                                        <option key={opcao.CodigoElemento} value={opcao.CodigoElemento}>{opcao.CodigoElemento} - {opcao.Nome}</option>)
                                ))}
                        </Form.Select>
                    </Col>
                    <Col >
                        <button className={StyleForm.inserir_btn} type="submit">Inserir<AiFillPlusCircle /></button>
                    </Col>

                </form>

                <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
                    <Modal.Body className={StyleForm.Modal_body}>
                        <img src={done} alt="ok"></img>
                        <p>Elemento Inserido!</p>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalErro} className={StyleForm.Modal} centered >
                    <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                        <h3>Ops!</h3>
                        <ImSad2 />
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <p>Algo deu errado, cheque os campos e tente novamente</p>
                    </Modal.Body>
                </Modal>

                <div>
                    <Table hover className={StyleForm.table}>
                        <thead>
                            <tr>
                                <th>Elementos Cadastrados</th>
                                <th className={StyleForm.th_btn}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {ElementosGravados && ElementosGravados.length > 0 ? (
                                ElementosGravados.map((Elemento) => (
                                    Elemento.CodigoElemento === 100 ? null : (
                                        <tr key={Elemento.CodigoElemento}>
                                            <td>{Elemento.CodigoElemento} - {Elemento.Elemento.Nome}</td>
                                            <td>
                                                <button type="button" className={StyleForm.btn_delete} onClick={(event) => ExcluirElemento(Elemento.CodigoConvenioElemento)}>
                                                    <TiDelete />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                ))
                            ) : (
                                <tr key="no-elements"><td colSpan="2">Nenhum elemento cadastrado</td></tr>
                            )}
                        </tbody>
                    </Table>
                </div>

                <button className={StyleForm.edit}>
                    <Link to={`/Metas/${wCodigoConvenioNovo}`}>
                        Cadastrar Metas <IoMdArrowForward />
                    </Link>
                </button>

            </div>
            <Modal show={isLoading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>

            {login && (<Navigate to="/" replace={true} />)}

        </div>
    )
}
export default Elementos