import styles from './LogoutBtn.module.css'
import { HiOutlineLogout } from "react-icons/hi"; 
import { Navigate } from "react-router-dom";
import React, { useState } from "react";

function LoginBtn () {
 
    const [logout, setLogout] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            localStorage.clear()
            setLogout(true);
          
        }
     catch (error) {
        console.error(error);
      }
    }
    
    return (
<form onSubmit={handleSubmit} className={styles.btn_container}>
<div className={styles.btn}>         
   <button type="submit" className={styles.btn}>Sair<HiOutlineLogout className={styles.icon}/> </button> 
</div>
        {logout && (<Navigate to="/" replace={true} />)}
 </form>
    )
}
export default LoginBtn