import { useState } from "react";
import axios from "axios";
import { Navigate } from "react-router";
import React, { useEffect } from 'react';
import Menu from "../Layout/Menu"
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import { IoMdArrowBack } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFileEarmarkCheck, BsFileEarmarkExcel } from 'react-icons/bs'
import { TiDelete } from 'react-icons/ti'

import Style from "./ListPagamentos.module.css";

import { Pagination } from 'react-bootstrap';
import moment from "moment";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import loadingGif from '../img/icons8-spinning-circle.gif';

function ListPagamentos() {

    const { CodigoConvenio } = useParams();

    const [loading, setLoading] = useState(true);
    const [pagamentos, setPagamentos] = useState([]);
    const [solicitacao, setSolicitacao] = useState([]);
    const [busca, setBusca] = useState('');
    const [itensFiltrados, setItensFiltrados] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [login, setLogin] = useState(false);

    var wToken = 'Bearer ' + localStorage.getItem("token");

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wToken === undefined || wToken === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    const getPagamentos = async () => {
        setLoading(true);

        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/Financeiro?Tipo=false&IsInclude=true',
            headers: {
                'Authorization': wToken,
                'Content-Type': 'application/json'
            },

        };

        axios(config)

            .then(function (response) {

                const data = response.data;
                setPagamentos(data.objetoRetorno);
                setLoading(false);
                //console.log(data.objetoRetorno)
            }
            )
            .catch(function (error) {
                console.log(error);
                //console.log(JSON.stringify(response.data.mensagemRetorno));
                // se token inválido redir para login
                if (error.response.status === 401) {
                    setLogin(true);
                }

            });
    }

    useEffect(() => {
        getPagamentos();
        // eslint-disable-next-line
    }, []);



    if (pagamentos !== null) {
        // Calculate the total number of pages
        var totalPages = Math.ceil(pagamentos.length / itemsPerPage);
    }

    if (itensFiltrados.length > 0 && (totalPages = Math.ceil(itensFiltrados.length / itemsPerPage)));



    // Calculate the indexes of the first and last items of the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    if (pagamentos !== null) {
        // Get the items of the current page
        var currentItems = pagamentos.slice(indexOfFirstItem, indexOfLastItem);
    }

    if (itensFiltrados.length > 0 && (currentItems = itensFiltrados.slice(indexOfFirstItem, indexOfLastItem)));


    // Generate the list of items of pagination
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === currentPage}
                onClick={() => setCurrentPage(number)}
            >
                {number}
            </Pagination.Item>
        );
    }


    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        const filteredItems = pagamentos.filter((item) =>
            item.Cadastro.Nome.toLowerCase().includes(value) ||
            item.NumeroDoc.toLowerCase().includes(value) ||
            item.NumeroNotaFiscal.toLowerCase().includes(value)
            // item.DataPagamento.toLowerCase().includes(value) ||
            //item.ValorTotal.toLowerCase().includes(value)
        );
        setItensFiltrados(filteredItems);
        setBusca(value);
    };

    const handleExcluirPagamento = (wCodigoFinanceiro, wCodigoSolicitacao) => {
        setLoading(true);
        //console.log(wCodigoSolicitacao);
        if (wCodigoSolicitacao > 0) {
            axios
                .get(
                    urlAPIlocal + '/api/Solicitacao?CodigoSolicitacao=' + wCodigoSolicitacao + '&IsInclude=true',
                    {
                        headers: {
                            'Authorization': wToken,
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then((responseSolic) => {
                    const data = responseSolic.data;
                    //console.log(data.objetoRetorno);
                    const solicitacaoData = data.objetoRetorno[0];

                    // Atualize o estado solicitacao
                    setSolicitacao(solicitacaoData);

                    const dataPut = {
                        "CodigoSolicitacao": wCodigoSolicitacao,
                        "DataSolicitacao": solicitacaoData.DataSolicitacao, // Corrigido aqui
                        "NumeroSolicitacao": 0,
                        "CodigoFonte": solicitacaoData.CodigoFonte,
                        "CodigoConvenio": solicitacaoData.CodigoConvenio,
                        "CodigoCredor": solicitacaoData.CodigoCredor,
                        "CodigoElemento": solicitacaoData.CodigoElemento,
                        "CodigoMeta": solicitacaoData.CodigoMeta,
                        "CodigoFase": solicitacaoData.CodigoFase,
                        "ValorTotal": solicitacaoData.ValorTotal,
                        "IndFinalizada": false,
                        "Pago": false,
                        "Observacao": solicitacaoData.Observacao,
                        "CodigoAprovacaoCampus": 0,
                        "CodigoAprovacaoDARDC": 0,
                        "CodigoAprovacaoConvenente": 0,
                        "CodigoAprovacaoDPCC": solicitacaoData.CodigoAprovacaoDPCC,
                        "NumeroParecer": 0,
                        "DataParecer": "1900-01-01T00:00:00.000Z",
                        "ValorLiquido": solicitacaoData.ValorLiquido,
                        "ValorISS": 0,
                        "ValorINSS": 0,
                        "ValorIRRF": 0,
                        "ValorINSS20": 0,
                        "CodigoSolicitacaoMae": 0,
                        "ValorDesconto": 0,
                        "DataPagamento": "1900-01-01T00:00:00.000Z",
                        "Issp": false,
                        "Inssp": false,
                        "Irrfp": false,
                        "Inss20p": false,
                        "DataISS": "1900-01-01T00:00:00.000Z",
                        "DataINSS": "1900-01-01T00:00:00.000Z",
                        "DataIRRF": "1900-01-01T00:00:00.000Z",
                        "DataINSS20": "1900-01-01T00:00:00.000Z",
                        "NomeISS": "",
                        "NomeINSS": "",
                        "NomeIRRF": "",
                        "NomeINSS20": "",
                        "ReciboDet": "",
                        "ReciboDatDe": "",
                        "ReciboDataTe": "",
                        "ReciboHoras": "",
                        "ReciboBaseInss": "",
                        "ReciboBaseIrrf": "",
                        "NumeroContrato": "",
                        "Indeferida": false,
                        "Regularizada": false,
                        "Excluida": false,
                        "Justificativa": solicitacaoData.Justificativa,
                        "Setor": 0,
                        "ValorOutrosImpostos": 0,
                        "OutrosImpostos": false,
                        "DataOutrosImpostos": "1900-01-01T00:00:00.000Z",
                        "NomeOutrosImpostos": "",
                        "DataRPA": "1900-01-01T00:00:00.000Z",
                        "MeioTransporte": "",
                        "TipoLicitacao": solicitacaoData.TipoLicitacao,
                        "PerfilDestinoAtual": solicitacaoData.PerfilDestinoAtual,
                        "CodigoProcessoInterno": solicitacaoData.CodProcessoInterno,

                    };

                    return axios.put(
                        urlAPIlocal + '/api/Solicitacao',
                        JSON.stringify(dataPut),
                        {
                            headers: {
                                'Authorization': wToken,
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                })
                .then((responsePut) => {
                    //console.log('Editou a solicitação');
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        axios
            .delete(
                urlAPIlocal + '/api/Financeiro/' + wCodigoFinanceiro,
                {
                    headers: {
                        'Authorization': wToken,
                        'Content-Type': 'application/json'
                    }
                }
            )
            .then((responseDelete) => {
                getPagamentos();
                setLoading(false);
                //console.log(responseDelete);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    return (
        <div className={Style.page}>
            <Menu />

            <div> <button className={Style.voltar}>  <Link to={`/VerProjeto/${CodigoConvenio}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            {/* <div className={Style.btn_container}>
                
            </div> */}

            <div className={Style.header}>

                {/* <h1>Relação Total dos Pagamentos</h1> */}

                <div className={Style.input_container}>
                    <AiOutlineSearch />
                    <input type="text" value={busca} onChange={handleSearch} placeholder="Pesquisar..." />
                </div>

                <div className={Style.btn_container}>
                    <button className={Style.lancar_btn}>
                        <Link to='/PagamentosSemSolic'> <BsFileEarmarkExcel /> Lançar Pagamentos Sem Solicitação</Link>
                    </button>
                    <button className={Style.lancar_btn}>
                        <Link to={`/PagamentosComSolic/${CodigoConvenio}`}> <BsFileEarmarkCheck /> Lançar Pagamentos Com Solicitação</Link>
                    </button>
                </div>

            </div>

            <div className={Style.table_container}>

                <Table bordered className={Style.table}>
                    <thead>
                        <tr>
                            <th className={Style.th_numero}>Meta</th>
                            <th className={Style.th}>Fase</th>
                            <th className={Style.th}>Beneficiário</th>
                            <th className={Style.th}>Elemento</th>
                            <th className={Style.th} align="center">Ordem Bancária</th>
                            <th className={Style.th} align="center">N° Documento</th>
                            <th className={Style.th}>Data</th>
                            <th className={Style.th} align="right">Valor</th>
                            {/* <th className={Style.th_btn}></th> */}
                            <th className={Style.th_btn}></th>
                        </tr>
                    </thead>
                    <tbody>

                        {pagamentos !== null ? (
                            (itensFiltrados.length > 0 ?
                                (
                                    currentItems.map((pagto) => (
                                        <tr key={pagto.CodigoFinanceiro}>
                                            <td>{pagto.Fase.Meta.NumMeta}</td>
                                            <td>{pagto.Fase.NumeroFase}</td>
                                            <td>{pagto.Cadastro.Nome}</td>
                                            <td>{pagto.ConvenioElemento.Elemento.CodigoElemento} - {pagto.ConvenioElemento.Elemento.Nome}</td>
                                            <td align="center">{pagto.NumeroDoc}</td>
                                            <td align="center">{pagto.NumeroNotaFiscal}</td>
                                            <td>{moment(pagto.DataPagamento).format("DD/MM/yyyy")}</td>
                                            <td align="right">{pagto.ValorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            {/* <td className={Style.td_btn}><button>Editar</button></td> */}
                                            <td className={Style.td_delete}><button type="button" onClick={() => handleExcluirPagamento(pagto.CodigoFinanceiro, pagto.CodigoSolicitacao)}><TiDelete /></button></td>
                                        </tr>
                                    ))
                                ) :
                                (
                                    currentItems.map((pagto) => (
                                        <tr key={pagto.CodigoFinanceiro}>
                                            <td>{pagto.Fase.Meta.NumMeta}</td>
                                            <td>{pagto.Fase.NumeroFase}</td>
                                            <td>{pagto.Cadastro.Nome}</td>
                                            <td>{pagto.ConvenioElemento.Elemento.CodigoElemento} - {pagto.ConvenioElemento.Elemento.Nome}</td>
                                            <td align="center">{pagto.NumeroDoc}</td>
                                            <td align="center">{pagto.NumeroNotaFiscal}</td>
                                            <td>{moment(pagto.DataPagamento).format("DD/MM/yyyy")}</td>
                                            <td align="right">{pagto.ValorPago.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            {/* <td className={Style.td_btn}><button>Editar</button></td> */}
                                            <td className={Style.td_delete}><button type="button" onClick={() => handleExcluirPagamento(pagto.CodigoFinanceiro, pagto.CodigoSolicitacao)}><TiDelete /></button></td>
                                        </tr>
                                    ))
                                ))) : (<tr>
                                    <td>Não há pagamentos nesse projeto</td>
                                </tr>)}
                    </tbody>
                </Table>


                <Pagination>{items}</Pagination>

                <Modal show={loading} className={Style.Modal} centered >
                    <Modal.Body className={Style.Modal_body}>
                        <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                    </Modal.Body>
                </Modal>

                {login && (<Navigate to="/" replace={true} />)}
            </div>

        </div>
    )
}

export default ListPagamentos