import React from "react"
import Form from 'react-bootstrap/Form'
import StyleForm from './/Concedente.module.css'
import { AiFillPlusCircle } from 'react-icons/ai'
import { TiDelete } from "react-icons/ti";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Menu from "../Layout/Menu";
import { Col, Table } from "react-bootstrap";
import { Navigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";
import urlAPIlocal from '../../global';
import { Modal } from 'react-bootstrap';
import done from "../img/done.svg";
import { ImSad2 } from 'react-icons/im';
import loadingGif from '../img/icons8-spinning-circle.gif';

//var Retorno = '';
//var Arraycomcolunanova = [];

const Concedente = () => {

    const { wCodigoConvenioNovo } = useParams();
    //const [codigoConvenioConcedente, setCodigoConvenioConcedente] = useState(0);
    const [login, setLogin] = useState(false);
    const [concedente, setConcedente] = useState([]);
    const [concedenteEscolhido, setConcedenteEscolhido] = useState('');
    const [concedentesGravados, setConcedentesGravados] = useState([]);
    const [excluirConcedente, setExcluirConcedente] = useState([]);
    var wTokenLocal = localStorage.getItem("token");

    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModalErro, setShowModalErro] = useState(false);
    const [projetoatual, setProjetoAtual] = useState([]);


    function handleClose() {
        setTimeout(() => {
            setShowModal(false);

        }, 2000);

    }

    function handleCloseErro() {

        setShowModalErro(false);

    };

    // const navigate = useNavigate();

    // function handleClick() {
    //     navigate(-1);
    // }

    function ConsultaToken() {
        //verifica se tem o token no localstorage
        if (wTokenLocal === undefined || wTokenLocal === null) {
            setLogin(true)
        }
    }

    useEffect(() => {
        ConsultaToken();
        // eslint-disable-next-line
    }, []);

    const getProjeto = async (event) => {
        const wtoken = "Bearer " + wTokenLocal;
        var config = {
            method: 'GET',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/convenio/' + wCodigoConvenioNovo,
            headers: {
                'Authorization': wtoken,
                'Content-Type': 'application/json'
            },
        };
        axios(config)
            .then(function (response) {
                //console.log(response.data.objetoRetorno)
                setProjetoAtual(response.data.objetoRetorno);
            }
            )
            .catch(function (error) {
                console.log(error);
                if (error.response.status === 401) {
                    setLogin(true);
                }
            });
    }

    useEffect(() => {
        getProjeto();
    }, []);

    const getConcedente = async () => {

        try {
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/cadastro?CodigoTipoCadastro=32",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setConcedente(data);
            //console.log(data)



        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getConcedente();
        // eslint-disable-next-line
    }, []);

    const handleSelectConcedente = (event) => {
        setConcedenteEscolhido(event.target.value);
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();

        var wtokenBearer = "Bearer " + localStorage.getItem("token")
        var data = JSON.stringify({
            "CodigoConvenioConcedente": 0,
            "CodigoConcedente": concedenteEscolhido,
            "ValorConcedido": 0,
            "CodigoConvenio": wCodigoConvenioNovo,
            "Fonte": 1

        });

        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ConvenioConcedente',
            headers: {
                'Authorization': wtokenBearer,
                'Content-Type': 'application/json'
            },
            data: data
        };
        //console.log(data)
        axios(config)

            .then(function (response) {

                //console.log(response.data)
                if (response.data.isOk === true) {
                    // fez ao consumo sem erro
                    //setCodigoConvenioConcedente(response.data.objetoRetorno.CodigoConvenioConcedente);
                    //console.log(response.data);
                    setLoading(false);
                    setShowModal(true);
                    getConcedentesGravados();

                    //verifica o processo do projeto, se for 1 marcar processo = 2, se for = ou maior que 2, marcar processo = processo atual
                    //console.log(projetoatual.Processo);
                    const wProcessoAtual = projetoatual.Processo
                    if (wProcessoAtual === 1) {
                        //wProcessoAtual = 2
                        //gravar o processo
                        const wtoken = "Bearer " + localStorage.getItem("token");
                        var Retorno = false;
                        var config = {
                            method: 'put',
                            maxBodyLength: Infinity,
                            url: urlAPIlocal + '/api/Convenio/MudarProcesso/' + wCodigoConvenioNovo + '/2',
                            headers: {
                                'Authorization': wtoken,
                                'Content-Type': 'application/json'
                            },
                        };
                        axios(config)
                            .then(function (response) {
                                //console.log(response.data)
                                if (response.data.isOk === true) {
                                    Retorno = true;
                                } else {
                                    //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                                    Retorno = false
                                }
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    };
                } else {
                    //deu algum erro tratar , colocar algum aviso
                    setLoading(false);
                    setShowModalErro(true);
                    //console.log("passou aqui")
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
                setShowModalErro(true);
            });
    }

    const getConcedentesGravados = async () => {

        try {
            setLoading(true);
            const wtoken = "Bearer " + wTokenLocal;

            const config = {
                method: "GET",
                url:
                    urlAPIlocal + "/api/ConvenioConcedente?CodigoConvenio=" + wCodigoConvenioNovo + "&IsInclude=true",
                headers: {
                    Authorization: wtoken,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios(config);

            const data = response.data.objetoRetorno;

            setConcedentesGravados(data);
            setLoading(false);
            //console.log(data)


            // Consulta para cada CodigoConvenioFase
            const consultaFinanceiro = async () => {
                try {
                    const concedentesComCodigoRetornoZero = [];

                    for (const concedente of data) {
                        const configFinanceiro = {
                            method: "GET",
                            url:
                                urlAPIlocal + "/api/Financeiro?CodigoConvenio=" + wCodigoConvenioNovo + "&CodigoCadastro=" + concedente.CodigoConcedente + "&Tipo=true",
                            headers: {
                                Authorization: wtoken,
                                "Content-Type": "application/json",
                            },
                        };

                        const responseFinanceiro = await axios(configFinanceiro);
                        const dataFinanceiro = responseFinanceiro.data;

                        //console.log(dataFinanceiro);

                        if (dataFinanceiro.codigoRetorno === '0') {
                            concedentesComCodigoRetornoZero.push(concedente.CodigoConcedente);
                        }
                    }

                    // Agora fasesComCodigoRetornoZero contém os CodigoConvenioFase com código de retorno zero
                    // Defina o estado para forçar a atualização da renderização
                    setExcluirConcedente(concedentesComCodigoRetornoZero);
                } catch (error) {
                    console.log(error);
                }
            };

            consultaFinanceiro(); // Chama a função de consulta

            //console.log(concedentesGravados);
            //se nao existir nenhum concedente no projeto, colocar processo = 1
            if (concedentesGravados.length === 0) {
                const wtoken = "Bearer " + localStorage.getItem("token");
                var Retorno = false;
                var configProcesso = {
                    method: 'put',
                    maxBodyLength: Infinity,
                    url: urlAPIlocal + '/api/Convenio/MudarProcesso/' + wCodigoConvenioNovo + '/1',
                    headers: {
                        'Authorization': wtoken,
                        'Content-Type': 'application/json'
                    },
                };
                axios(configProcesso)
                    .then(function (response) {
                        // console.log(response.data)
                        if (response.data.isOk === true) {
                            Retorno = true;
                        } else {
                            //tratar erro , mensagem ou algo na tela quando consumo retornar falha
                            Retorno = false
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }


        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getConcedentesGravados();
        // eslint-disable-next-line
    }, []);

    const handleExcluirConcedente = (wConcedente) => {
        setLoading(true);
        var configSelect = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: urlAPIlocal + '/api/ConvenioConcedente/' + wConcedente,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token"),
                'Content-Type': 'application/json'
            },
            //data: dataSelect
        };
        //console.log(configSelect)
        axios(configSelect)

            .then(function (response) {
                getConcedentesGravados();
                setLoading(false);
                //setCadastroOk(true);
                //console.log(concedentesGravados);
            })
            .catch(error => {
                setLoading(false);
                console.error(error);
            });

    }



    //   const ExcluirConcedente = (wConcedente) => {
    //     var configSelect = {
    //         method: 'delete',
    //         maxBodyLength: Infinity,
    //         url: urlAPIlocal + '/api/ConvenioConcedente/' + wConcedente,
    //         headers: {
    //             'Authorization': "Bearer " + localStorage.getItem("token"),
    //             'Content-Type': 'application/json'
    //         },
    //         //data: dataSelect
    //     };
    //     //console.log(dataSelect)
    //     axios(configSelect)

    //         .then(function (response) {

    //             console.log(wConcedente)
    //             getConcedentesGravados();
    //             //console.log(response);

    //             //setCadastroOk(true);
    //             //console.log(response.data);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });


    // }


    return (
        <div className={StyleForm.page}>
            <Menu />

            {/* <div> <button className={StyleForm.btn} onClick={handleClick}><IoMdArrowBack /> Voltar</button> </div> */}
            <div> <button className={StyleForm.voltar}>  <Link to={`/VerProjeto/${wCodigoConvenioNovo}`}><IoMdArrowBack /> Voltar</Link> </button> </div>

            <div className={StyleForm.header}>
                <h1>Cadastro de Concedentes</h1>
            </div>

            <div className={StyleForm.container}>

                <form className={StyleForm.select} onSubmit={handleSubmit} >

                    <Col md={2}>
                        <Form.Label className={StyleForm.select_lable}>Selecione os Concedentes</Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Select className={StyleForm.form_control} name="concedente" id="concedente" value={concedenteEscolhido} onChange={handleSelectConcedente}>
                            <option value="">Selecione uma opção</option>
                            {concedente.length > 0 &&
                                concedente.map((opcao) => (
                                    <option key={opcao.CodigoCadastro} value={opcao.CodigoCadastro}>{opcao.Nome}</option>
                                ))}
                        </Form.Select>
                    </Col>
                    <Col >
                        <button className={StyleForm.inserir_btn} type="submit" onClick={handleSubmit}>Inserir <AiFillPlusCircle /></button>
                    </Col>

                </form>

                <Modal show={showModal} onLoad={handleClose} className={StyleForm.Modal} centered >
                    <Modal.Body className={StyleForm.Modal_body}>
                        <img src={done} alt="ok"></img>
                        <p>Concedente Inserido!</p>
                    </Modal.Body>
                </Modal>

                <Modal show={showModalErro} className={StyleForm.Modal} centered >
                    <Modal.Header closeButton onClick={handleCloseErro} className={StyleForm.Modal_header}>
                        <h3>Ops!</h3>
                        <ImSad2 />
                    </Modal.Header>
                    <Modal.Body className={StyleForm.Modal_body}>
                        <p>Algo deu errado, cheque os campos e tente novamente</p>
                    </Modal.Body>
                </Modal>

                <div>
                    <Table hover className={StyleForm.table}>
                        <thead>
                            <tr>
                                <th>Concedente do Projeto - Financiador</th>
                                <th></th>
                                {/* <th className={StyleForm.th_btn}></th> */}
                            </tr>
                        </thead>
                        <tbody>

                            {concedentesGravados &&

                                (concedentesGravados.length > 0 ?
                                    (concedentesGravados.map((concedente) => (
                                        <tr key={concedente.CodigoConvenioConcedente}>
                                            <td>{concedente.CodigoConcedente} - {concedente.Cadastro.Nome}</td>
                                            <td>{excluirConcedente.includes(concedente.CodigoConcedente) ? ( // Verifica se CodigoCadastro está presente em Fianceiro
                                                <button
                                                    type="button"
                                                    className={StyleForm.btn_delete}
                                                    onClick={() => handleExcluirConcedente(concedente.CodigoConvenioConcedente)}
                                                >
                                                    <TiDelete />
                                                </button>
                                            ) : null}
                                            </td>
                                        </tr>
                                    ))
                                    ) : (<tr><td>Nenhum concedente cadastrado</td></tr>))}



                        </tbody>
                    </Table>
                </div>

                <button className={StyleForm.edit_elementos}>
                    <Link to={`/Elementos/${wCodigoConvenioNovo}`}>
                        Cadastrar Elementos <IoMdArrowForward />
                    </Link>
                </button>

            </div>
            <Modal show={loading} className={StyleForm.Modal} centered >
                <Modal.Body className={StyleForm.Modal_body}>
                    <img style={{ width: '50px', margin: '0 auto' }} src={loadingGif} alt="loading"></img>
                </Modal.Body>
            </Modal>
            {login && (<Navigate to="/" replace={true} />)}

        </div>
    )
}
export default Concedente